export const originalState = {
  rows: [],
};

export function reducer(draft, action) {
  switch (action.type) {
    case "addRow":
      draft.rows.push(action.value);

      return;

    case "removeRow":
      let removedKey = draft.rows.findIndex((x) => x.id == action.value);

      if (removedKey >= 0) {
        draft.rows.splice(removedKey, 1);
      }

      return;

    case "setRows":
      draft.rows = action.value;
      return;
  }
}
