export const originalState = {
  messages: [],
  formData: {
    messageText: "",
    lastMessageId: 0,
  },
  jpId: "",
};

export function reducer(draft, action) {
  switch (action.type) {
    case "setMessages":
      draft.messages = action.value;
      break;
    case "setMessage":
      draft.formData.messageText = action.value;
      break;

    case "sendMessage":
      draft.messages.push(action.value);
      break;

    case "setLastMessageId":
      draft.formData.lastMessageId = action.value;
      break;

    // Start Pagination  -----
    case "setPage":
      draft.pagination.currentPage = action.value;
      break;
    case "setRowsPerPage":
      draft.pagination.currentPageSize = action.value;
      break;
    case "setPaginationTotalCount":
      draft.pagination.totalCount = action.value;
      break;
    case "setPaginationTotalPageCount":
      draft.pagination.totalPageCount = action.value;
      break;
  }
}
