import * as Constants from "../../helpers/Constants";
import * as MyCvHelper from "../MyCvPage/MyCvHelper";
import * as DreamAutocompleteHelper from "../../ui_components/DreamAutocomplete/DreamAutocompleteHelper";
import * as SalaryRangeHelper from "../../containers_components/SalaryRangeComponent/SalaryRangeHelper";
import * as AdditionalSkillsHelper from "../../containers_components/AdditionalSkillsComponent/AdditionalSkillsHelper";
import * as FileUploaderHelper from "../../ui_components/DreamFileUploaderMultiple/DreamFileUploader/FileUploaderHelper";
import * as LocationsHelper from "../../containers_components/LocationsComponent/LocationsHelper";
import * as AdditionalBenefitsHelper from "../../containers_components/AdditionalBenefitsComponent/AdditionalBenefitsHelper";

export async function handleSave(data, dispatch, appDispatch, Axios, jpId) {
  // Validate JP (without CV)
  if (!validateForm(data, appDispatch)) {
    return false;
  }

  // Validate CV
  if (!MyCvHelper.validateForm(data.cv, appDispatch)) {
    return false;
  }

  // Save JP
  save(data, dispatch, appDispatch, Axios, jpId);
}

function validateForm(data, appDispatch) {
  let errors = [];
  if (!isValidLocations(data.locations)) {
    errors.push("jp.locationsObj.invalidLocations");
  }
  if (!isValidPosition(data.position)) {
    errors.push("jp.positionObj.invalidPosition");
  }
  if (!isValidSalaryRange(data.salaryRange)) {
    errors.push("jp.salaryRangeObj.invalidSalaryRange");
  }

  if (Boolean(errors.length)) {
    appDispatch({
      type: "showMsg",
      value: { status: Constants.SNACKBAR_ERROR, msg: errors },
    });
    return false;
  }

  return true;
}

function isValidLocations(locations) {
  let isValid = true;
  if (locations.any.isChecked === false && locations.locations.length === 0) {
    return false;
  }

  locations.locations.every(function (element, index) {
    if (!element.value || !element.value.id) {
      isValid = false;
      return false;
    } else {
      return true;
    }
  });

  return isValid;
}

function isValidPosition(position) {
  if (!position.industryId || !position.positionId) {
    return false;
  }

  return true;
}

function isValidSalaryRange(salaryRange) {
  if (salaryRange.salary === 0 || !salaryRange.currency) {
    return false;
  }

  return true;
}

/**
 *
 * @param {Save Job Preference} data
 * @param {*} dispatch
 * @param {*} appDispatch
 * @param {*} Axios
 */
export async function save(data, dispatch, appDispatch, Axios, jpId) {
  let errMsgs;
  let authenticated = true;
  let responseData;
  dispatch({ type: "disableSaveButton", value: true });
  appDispatch({ type: "showBackdrop", value: true });

  try {
    // Save JP
    let formData = new FormData();
    // Attach Locations
    if (
      data.locations &&
      data.locations.any.isChecked === false &&
      data.locations.locations.length > 0
    ) {
      jpId == null
        ? formatLocations(data.locations.locations, formData)
        : formData.append("locations", data.locations.locations.length);
    }

    // Attach Position data
    formData.append("position", data.position.positionId);
    formData.append("industry", data.position.industryId);

    // Attach Workplace type
    formData.append("wp_type", data.workPlaceType.workPlaceType);

    // Attach Employment type
    formData.append("e_type", data.employmentType.employmentType);

    // Attach Salary Range data
    if (data.salaryRange.type == SalaryRangeHelper.HOURLY) {
      formData.append("salary_hourly", data.salaryRange.salary); // Hourly renumenation
    } else {
      formData.append("salary_monthly", data.salaryRange.salary); // Monthly renumenation
    }
    formData.append("salary_currency", data.salaryRange.currency);

    // Attach Work Schedule
    formData.append("work_schedule", data.workScheduleType.workScheduleType);

    // Attach Preferred start
    formData.append("start", data.preferredStartType.preferredStartType);

    // Attach Additional Benefits
    data.additionalBenefits.forEach((benefit) => {
      if (benefit.isChecked) {
        formData.append(benefit.id, true);
      }
    });

    // // If it's provided jpId, this mean we are doing Update not Create
    let jpUrl = `/dreamjob/jp`;
    let actionType = "post";
    let headers = {
      "content-type": `multipart/form-data; boundary=${formData._boundary}`,
    };

    if (jpId) {
      jpUrl += `/${jpId}`;
      actionType = "put";
      headers = {};
      let object = {};

      formData.forEach(function (value, key) {
        if (key !== "locations") {
          object[key] = value;
        } else {
          object["locations"] = [];

          if (value > 0) {
            data.locations.locations.forEach((location) => {
              if (
                location.value.location_type ==
                DreamAutocompleteHelper.SEARCH_TYPE_COUNTRY
              ) {
                object["locations"].push({ country_id: location.value.id });
              } else if (
                location.value.location_type ==
                DreamAutocompleteHelper.SEARCH_TYPE_STATE
              ) {
                object["locations"].push({ state_id: location.value.id });
              } else {
                object["locations"].push({ city_id: location.value.id });
              }
            });
          }
        }
      });
      formData = JSON.stringify(object);
    }

    const response = await Axios[actionType](jpUrl, formData, {
      headers,
    }).catch(function (error) {
      // Errors to be sent to snackbar
      errMsgs = "basic.serverError";
      if (
        error.response &&
        error.response.status &&
        error.response.status == Constants.ERROR_RESPONSE_STATUS
      ) {
        authenticated = false;
        errMsgs = "basic.unauthenticated";
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
      throw errMsgs;
    });

    responseData = response.data.data;
    let errsArr = [];
    if (!responseData.success && responseData.messages.length) {
      responseData.messages.forEach((msg) => {
        errsArr.push(`serverErrors.${msg.code}`);
      });
      throw errsArr;
    }

    // Check if the CV has been changed
    if (data.cv.isCvChanged) {
      await saveCV(data.cv, dispatch, appDispatch, Axios, responseData.id);
    }

    dispatch({ type: "disableSaveButton", value: false });
    dispatch({ type: "formSuccessfullySubmitted" });
    appDispatch({ type: "showBackdrop", value: false });
    appDispatch({
      type: "showMsg",
      value: { status: Constants.SNACKBAR_SUCCESS, msg: "basic.saved" },
    });
  } catch (error) {
    appDispatch({ type: "showBackdrop", value: false });
    appDispatch({
      type: "showMsg",
      value: { status: Constants.SNACKBAR_ERROR, msg: error },
    });
    dispatch({ type: "disableSaveButton", value: false });

    if (!authenticated) {
      appDispatch({
        type: "logout",
      });

      appDispatch({ type: "openLoginModal", value: true });
    } else {
      dispatch({ type: "disableSaveButton", value: false });
    }
  }

  return responseData;
}

/**
 * Format Locations
 * @param {Array} locations
 */
function formatLocations(locations, formData) {
  locations.forEach((location, index) => {
    if (
      location.value.location_type == DreamAutocompleteHelper.SEARCH_TYPE_CITY
    ) {
      formData.append(`locations[${index}][city_id]`, location.value.id);
    } else if (
      location.value.location_type == DreamAutocompleteHelper.SEARCH_TYPE_STATE
    ) {
      formData.append(`locations[${index}][state_id]`, location.value.id);
    } else {
      formData.append(`locations[${index}][country_id]`, location.value.id);
    }
  });
}

export async function saveCV(data, dispatch, appDispatch, Axios, jpID) {
  let errMsgs;
  let authenticated = true;
  try {
    let formData = new FormData();

    formData.append("jpID", jpID);

    formData.append("location", JSON.stringify(data.location));
    formData.append(
      "education",
      JSON.stringify(MyCvHelper.formatEducationData(data.education))
    );
    formData.append("languages", JSON.stringify(data.languages));
    formData.append(
      "experience",
      JSON.stringify(MyCvHelper.formatExperienceData(data.experience))
    );
    formData.append(
      "generalSkills",
      JSON.stringify(MyCvHelper.getOnlyChecked(data.generalSkills))
    );
    formData.append(
      "additionalSkills",
      JSON.stringify(
        AdditionalSkillsHelper.getOnlyChecked(data.additionalSkills)
      )
    );
    formData.append(
      "hobbies",
      JSON.stringify(MyCvHelper.getOnlyChecked(data.hobbies))
    );

    FileUploaderHelper.formatRequestData(
      data.certificates.uploadedFiles,
      formData
    );
    const response = await Axios.post(`/dreamjob/cv`, formData, {
      headers: {
        "content-type": `multipart/form-data; boundary=${formData._boundary}`,
      },
    }).catch(function (error) {
      // Errors to be sent to snackbar
      errMsgs = "basic.serverError";
      if (
        error.response &&
        error.response.status &&
        error.response.status == Constants.ERROR_RESPONSE_STATUS
      ) {
        authenticated = false;
        errMsgs = "basic.unauthenticated";
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
      throw errMsgs;
    });

    const responseData = response.data.data;
    let errsArr = [];
    if (!responseData.success && responseData.messages.length) {
      responseData.messages.forEach((msg) => {
        errsArr.push(`serverErrors.${msg.code}`);
      });
      throw errsArr;
    }
  } catch (error) {
    console.log("CV is NOT saved. Error:");
    console.log(error);

    if (!authenticated) {
      appDispatch({
        type: "logout",
      });

      appDispatch({ type: "openLoginModal", value: true });
    } else {
      dispatch({ type: "disableSaveButton", value: false });
    }
    throw error;
  }
}

export async function getJPdata(
  jpId,
  Axios,
  ourRequest,
  appDispatch,
  dispatch
) {
  appDispatch({ type: "showBackdrop", value: true });

  let errMsgs;
  let authenticated = true;
  try {
    const response = await Axios.get(`/dreamjob/jp/${jpId}`, {
      cancelToken: ourRequest.token,
    }).catch(function (error) {
      // Errors to be sent to snackbar
      errMsgs = "basic.serverError";
      if (
        error.response &&
        error.response.status &&
        error.response.status === Constants.ERROR_RESPONSE_STATUS
      ) {
        authenticated = false;
        errMsgs = "basic.unauthenticated";
      }
      throw errMsgs;
    });

    const responseData = response.data.data;

    handleSuccessfulGetData(responseData, appDispatch, dispatch);
  } catch (error) {
    appDispatch({ type: "showBackdrop", value: false });
    appDispatch({
      type: "showMsg",
      value: { status: Constants.SNACKBAR_ERROR, msg: error },
    });
    if (!authenticated) {
      appDispatch({
        type: "logout",
      });
      appDispatch({ type: "openLoginModal", value: true });
    } else {
      dispatch({ type: "disableSubmitButton", value: false });
    }
  }
}

/**
 * Successful got a data from the API
 * @param {Object} data
 * @param {appDispatch} appDispatch
 * @param {dispatch} dispatch
 */
export function handleSuccessfulGetData(data, appDispatch, dispatch) {
  appDispatch({ type: "showBackdrop", value: false });
  dispatch({ type: "setInitialDataLoaded" });
  // Locations data
  if (data.locations && data.locations.length > 0) {
    dispatch({
      type: "locationsAnyChanged",
      value: false,
    });
    dispatch({
      type: "locationsAddFullData",
      value: LocationsHelper.formatLocations(data.locations, dispatch),
    });
  }
  // Position data
  dispatch({
    type: "positionIndustryChanged",
    value: data.industry,
  });
  dispatch({
    type: "positionPositionChanged",
    value: { id: data.position, name: data.position_name },
  });
  // Workplace type
  dispatch({
    type: "workPlaceTypeChanged",
    value: data.wp_type,
  });
  // Employment type
  dispatch({
    type: "employmentTypeChanged",
    value: data.e_type,
  });
  // Work Schedule
  dispatch({
    type: "workScheduleChanged",
    value: data.work_schedule,
  });
  // Preferred start
  dispatch({
    type: "preferredStartChanged",
    value: data.start,
  });
  // Salary range events
  dispatch({
    type: "salaryRangeTypeChanged",
    value: data.salary_hourly
      ? SalaryRangeHelper.HOURLY
      : SalaryRangeHelper.MONTHLY,
  });
  dispatch({
    type: "salaryRangeValueChanged",
    value: data.salary_hourly ? data.salary_hourly : data.salary_monthly,
  });
  dispatch({
    type: "salaryRangeCurrencyChanged",
    value: data.salary_currency,
  });
  // Additional benefits checkboxes
  AdditionalBenefitsHelper.additionalBenefitsCheckboxes.forEach((benefit) => {
    if (data[benefit.id]) {
      dispatch({
        type: "additionalBenefitsChanged",
        value: benefit.id,
      });
    }
  });
}
