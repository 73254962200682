import React, { useContext } from "react";

import { Link, withRouter, useHistory } from "react-router-dom";
import StateContext from "../../Context/StateContext";
import DispatchContext from "../../Context/DispatchContext";
import * as AppRoutes from "../../AppComponent/AppRoutes";
import { withStyles, useTheme, makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";

import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputBase from "@material-ui/core/InputBase";
import Divider from "@material-ui/core/Divider";

import DreamSettingsMenu from "../../ui_components/DreamSettingsMenu/DreamSettingsMenu";
import DreamAvatar from "../../ui_components/DreamAvatarComponent/DreamAvatarComponent";

import * as Constants from "../../helpers/Constants";
import * as Colors from "../../styles/01.settings/01-settings-colors.module.scss";
import * as HeaderHelper from "./HeaderHelper";
import DreamButton from "../../ui_components/DreamButton/DreamButton";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import AppBarLogo from "../DreamAppBarLogoComponent/DreamAppBarLogo";
import { supportedLanguages } from "../../helpers/BaseHelper";
import { useEffect } from "react";

// import {
//   Root,
//   Title,
//   UserAvatar,
//   loginSignUpButtonsWrapper,
//   logoWrapper,
//   buttonsWrapper,
// } from "./HeaderComponent.module.scss";

// const DRAWER_WIDTH = 240;

function HeaderComponent(props) {
  const appContext = useContext(StateContext);
  const appDispatch = useContext(DispatchContext);
  const [t] = useTranslation(Constants.TRANSLATION_COMMON);
  const history = useHistory();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const matchesSMup = useMediaQuery(theme.breakpoints.up("sm"));
  const matchesXSdown = useMediaQuery(theme.breakpoints.down("xs"));

  const useStyles = makeStyles((theme) => ({
    appBar: {
      display: "flex",
      flexFlow: "row nowrap",
      justifyContent: "space-between",
      alignItems: "center",
      background: Colors.primary,
      width: appContext.appState.loggedIn
        ? `calc(100% - ${HeaderHelper.DRAWER_WIDTH}px)`
        : "100%",
      marginLeft: HeaderHelper.DRAWER_WIDTH,
      height: 64,
      paddingLeft: 10,
      paddingRight: 10,
    },
    appBarLow: {
      display: "flex",
      flexFlow: "row nowrap",
      justifyContent: "center",
      alignItems: "center",
      background: Colors.primary,
      width: "100%",
      height: 56,
      paddingLeft: 5,
      paddingRight: 5,
    },
    appBarToolbar: {
      width: "100%",
      display: "flex",
      flexFlow: "row nowrap",
      justifyContent:
        (!matches && !appContext.appState.loggedIn) ||
        (matches && !appContext.appState.loggedIn) ||
        (!matches && appContext.appState.loggedIn)
          ? "space-between"
          : "flex-end",
      alignItems: "center",
      padding: 0,
    },
    appBarLogoWrapper: {
      display: "flex",
      flexDirection: "row nowrap",
      justifyContent: "space-between",
      alignItems: "center",
    },
    appBarButtonsWrapper: {
      display: "flex",
      flexDirection: "row nowrap",
      justifyContent: matchesXSdown ? "center" : "space-between",
      alignItems: "center",
      // paddingLeft: 10,
      // paddingRight: 10,
    },
    link: {
      fontSize: 20,
      marginLeft: 10,
      color: Colors.white,
    },
    loginSignInButtonsWrapper: {
      display: "flex",
      justifyContent: "space-between",
      marginLeft: matchesXSdown ? 5 : 20,
      minWidth: matchesXSdown ? 100 : 180,
    },
    btnLogin: {
      fontSize: matchesXSdown ? 10 : 14,
      minWidth: matchesXSdown ? 60 : 80,
      height: matchesXSdown ? 30 : 40,
      border: `1px solid ${Colors.white} !important`,
    },
    btnSignUp: {
      fontSize: matchesXSdown ? 10 : 14,
      minWidth: matchesXSdown ? 60 : 80,
      height: matchesXSdown ? 30 : 40,
      marginRight: 5,
    },
    select: {
      "&:before": {
        borderColor: Colors.primary,
      },
      "&:after": {
        borderColor: Colors.primary,
      },
      color: Colors.white,
    },
    icon: {
      color: Colors.white,
    },
    menuItem: {
      backgroundColor: Colors.white,
      color: Colors.dark,
    },
    menuItemSelected: {
      backgroundColor: `${Colors.primaryLighten} !important`,
      color: Colors.white,
    },
  }));

  const classes = useStyles();

  const BootstrapInput = withStyles((theme) => ({
    root: {
      "label + &": {
        marginTop: matchesXSdown ? theme.spacing(2) : theme.spacing(3),
      },
    },
    input: {
      borderRadius: 4,
      position: "relative",
      backgroundColor: Colors.primary,
      color: Colors.white,
      border: "none",
      fontSize: matchesXSdown ? 14 : 16,
      padding: matchesXSdown ? "5px 5px 5px 5px" : "10px 26px 10px 12px",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
    },
  }))(InputBase);

  let user_pictureUrl = appContext.appState.user_avatarId
    ? `${Constants.BASE_URL}upload/${appContext.appState.user_avatarId}`
    : "";
  //------------------
  let menuItems = [
    { link: "/user-profile", translation: "profile.userProfile" },
    { link: "/company-profile", translation: "profile.companyProfile" },
    { link: "/invoices", translation: "invoices.invoiceDetails" },
    {
      handleClick: (e) => HeaderHelper.startTourHandler(e, appDispatch),
      translation: "header.getStarted",
    },
    {
      link: AppRoutes.ROUTE_ACCOUNT_SETTINGS,
      translation: "header.settings",
    },
    {
      handleClick: (e) => {
        HeaderHelper.logOutHandler(appDispatch, t);
        history.replace("/");
      },
      translation: "header.logout",
    },
  ];
  //------------------

  const loginLogout = appContext.appState.loggedIn ? (
    <DreamSettingsMenu
      label={<DreamAvatar className={"step1"} imageSource={user_pictureUrl} />}
      menuItems={menuItems}
    />
  ) : (
    ""
  );
  // (
  //   <div className={classes.loginSignInButtonsWrapper}>
  //     <DreamButton
  //       className={classes.btnSignUp}
  //       handleClick={(e) =>
  //         appDispatch({ type: "openSignUpModal", value: true })
  //       }
  //       kind="outlined"
  //       color="primary"
  //       buttonLabel={t("header.signUp")}
  //     ></DreamButton>
  //     <DreamButton
  //       className={classes.btnLogin}
  //       handleClick={(e) =>
  //         appDispatch({ type: "openLoginModal", value: true })
  //       }
  //       kind="contained"
  //       color="primary"
  //       buttonLabel={t("header.login")}
  //     ></DreamButton>
  //   </div>
  // )
  const handleDrawerOpen = () => {
    appDispatch({ type: "setDrawerOpen", value: true });
  };

  return (
    <AppBar
      position={"fixed"}
      className={matches ? classes.appBar : classes.appBarLow}
      style={props.appBarStyle}
    >
      <Toolbar className={classes.appBarToolbar}>
        {!appContext.appState.loggedIn ||
        (appContext.appState.loggedIn && !matches) ? (
          <AppBarLogo toggleDrawer={props.toggleDrawer} anchor={props.anchor} />
        ) : (
          <div />
        )}
        <div className={classes.appBarButtonsWrapper}>
          <FormControl className={classes.margin}>
            <Select
              classes={{
                select: classes.select,
                icon: classes.icon,
              }}
              variant="standard"
              labelId="demo-customized-select-label"
              id="demo-customized-select"
              value={appContext.appState.lang}
              onChange={(e) =>
                HeaderHelper.langChanged(e.target.value, appDispatch)
              }
              input={<BootstrapInput />}
            >
              {supportedLanguages.map((lng, index) => (
                <MenuItem
                  key={index}
                  classes={{
                    root: classes.menuItem,
                    selected: classes.menuItemSelected,
                  }}
                  value={lng}
                >
                  <em>{lng.toUpperCase()}</em>
                </MenuItem>
              ))}
              {/* <MenuItem
                classes={{
                  root: classes.menuItem,
                  selected: classes.menuItemSelected,
                }}
                value="en"
              >
                <em>EN</em>
              </MenuItem>
              <Divider />
              <MenuItem
                classes={{
                  root: classes.menuItem,
                  selected: classes.menuItemSelected,
                }}
                value={"bg"}
              >
                <em>BG</em>
              </MenuItem> */}
            </Select>
          </FormControl>
          {loginLogout}
        </div>
      </Toolbar>
    </AppBar>
  );
}

export default withRouter(HeaderComponent);
