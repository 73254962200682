export const originalState = {
  open: false,
  options: [],
  requestCount: 0,
  searchTerm: "",
  isLoading: false,
  initialValue: {},
};

export function reducer(draft, action) {
  switch (action.type) {
    case "changeOpen":
      draft.open = action.value;
      return;

    case "setOptions":
      draft.options = action.value;
      return;

    case "setRequestCount":
      draft.requestCount++;
      return;

    case "setSearchTerm":
      draft.searchTerm = action.value;
      return;

    case "setIsLoading":
      draft.isLoading = action.value;
      return;

    case "setInitialValue":
      draft.initialValue = action.value;
      return;
  }
}
