import React from "react";
import Avatar from "@material-ui/core/Avatar";

const DreamAvatar = (props) => {
  const { className, alt, imageSource, sizes, style, classes } = props;

  return (
    <Avatar
      className={className}
      alt={alt}
      src={imageSource}
      sizes={sizes}
      style={style}
      classes={classes}
    />
  );
};

export default DreamAvatar;
