export const MONTHLY = 0;
export const HOURLY = 1;

export const salaryTypeRadioButtons = [
  {
    label: "jo.monthly",
    value: MONTHLY,
  },
  {
    label: "jo.hourly",
    value: HOURLY,
  },
];

export const currencyOptions = {
  USD: "USD",
  EUR: "EUR",
  BGN: "BGN",
};
