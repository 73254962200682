module.exports = `

<html>

<head>
<meta http-equiv=Content-Type content="text/html; charset=windows-1251">
<meta name=Generator content="Microsoft Word 15 (filtered)">
<style>
<!--
 /* Font Definitions */
 @font-face
	{font-family:SimSun;
	panose-1:2 1 6 0 3 1 1 1 1 1;}
@font-face
	{font-family:"Cambria Math";
	panose-1:2 4 5 3 5 4 6 3 2 4;}
@font-face
	{font-family:OpenSymbol;}
@font-face
	{font-family:"Microsoft YaHei";
	panose-1:2 11 5 3 2 2 4 2 2 4;}
@font-face
	{font-family:"\@SimSun";
	panose-1:2 1 6 0 3 1 1 1 1 1;}
@font-face
	{font-family:"\@Microsoft YaHei";}
@font-face
	{font-family:"\@OpenSymbol";}
 /* Style Definitions */
 p.MsoNormal, li.MsoNormal, div.MsoNormal
	{margin:0cm;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;}
p.MsoBodyText, li.MsoBodyText, div.MsoBodyText
	{margin-top:0cm;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:0cm;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;}
.terms a:link, span.MsoHyperlink
	{color:navy;
	text-decoration:underline;}
p.-, li.-, div.-
	{mso-style-name:"Таблица - съдържание";
	margin:0cm;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;}
@page WordSection1
	{size:595.3pt 841.9pt;
	margin:2.0cm 2.0cm 2.0cm 2.0cm;}
div.WordSection1
	{page:WordSection1;}
 /* List Definitions */
 ol
	{margin-bottom:0cm;}
ul
	{margin-bottom:0cm;}
-->
</style>

</head>

<body lang=BG link=navy vlink="#954F72" style='word-wrap:break-word;line-break:
strict'>

<div class=WordSection1>

<p class=MsoNormal><b><span style='font-size:16.0pt;color:black'>GENERAL TERMS
OF SERVICE FOR EMPLOYERS</span></b></p>

<p class=MsoNormal>&nbsp;</p>

<p class=MsoNormal><span style='color:black'>Please read carefully this
document. It contains the terms (“Terms”) for access to and use of the website
www.</span><span lang=EN-US style='color:black'>jobpreference.com</span><span
style='color:black'> (“Website”). If You do not accept the Terms set forth
below, it is not possible to use the Services!</span><br>
<br>
<span style='color:black'>In relation to the provided Services </span><span
lang=EN-US style='color:black'>Jobpreference.com</span><span style='color:black'>
processes personal data of natural persons – business users according to the
Privacy Policy for Employers (“Policy”). The Policy is an integral part of the
Terms.</span></p>

<p class=MsoNormal><br>
<b><span style='color:black'>GENERAL TERMS OF SERVICE FOR EMPLOYERS</span></b><br>
<br>
<b><span style='color:black'>1. Registration</span></b><br>
<br>
<span style='color:black'>1.1. </span><span lang=EN-US style='color:black'>Sun
Land Capital OOD</span><span style='color:black'> (“</span><span lang=EN-US
style='color:black'>J</span><span lang=EN-US style='color:#333333'>obpreference.com</span><span
style='color:black'>”/ “We”), with registered address: 4 </span><span
lang=EN-US style='color:black'>Georgi</span><span lang=EN-US style='color:black'>
</span><span lang=EN-US style='color:black'>Boev</span><span style='color:black'>
Str., </span><span lang=EN-US style='color:black'>9000</span><span lang=EN-US
style='color:black'> </span><span lang=EN-US style='color:black'>Varna</span><span
style='color:black'>, UIC: </span><span style='color:#333333'>206166575</span><span
style='color:black'>, by means of the website www. </span><span lang=EN-US
style='color:black'>jobpreference.com</span><span style='color:black'>
(“Website”) provides to legal entities and natural persons with a valid
registration in the Trade register/BULSTAT register – Employers (“Employer/s”)
– free and paid information society services (“Services”).</span><br>
<br>
<span style='color:black'>1.2. The use of the Services is possible only after
registration and activation of a company account.</span><br>
<br>
<span style='color:black'>1.3. A company account can be registered by a capable
person who is authorized to represent the Employer, to accept the Terms and
perform the activities related to the use and management of the company account
on behalf of the Employer. By registering an account, You declare that You meet
these conditions and the information You have filled in to identify yourself
and the Employer is accurate and complete.</span><br>
<br>
<span style='color:black'>1.</span><span lang=EN-US style='color:black'>4</span><span
style='color:black'>. In relation to its obligations under the General Data
Protection Regulation (“Regulation (EU) 2016/679”), the Employer undertakes to
provide contact details that Applicants can use in relation to personal data
protection matters. As requests could arise after expiry of the job offers, the
Employer undertakes to keep the contact details up-to-date at any time,
regardless of whether there are current job offers on the Website. These
contact details, as well as the data identifying the Employer, are publicly
available on the Website.</span><br>
<br>
<span style='color:black'>1.</span><span lang=EN-US style='color:black'>5</span><span
style='color:black'>. The Employer and each of its Representatives shall provide
correct, complete and current data upon registration, when using the Services
and keep it up to date thereafter. </span><br>
<br>
<span style='color:black'>1.</span><span lang=EN-US style='color:black'>6</span><span
style='color:black'>. In addition to the information provided during the
registration, </span><span lang=EN-US style='color:black'>J</span><span
lang=EN-US style='color:#333333'>obpreference.com</span><span style='color:
black'> reserves the right to request additional documents, including signed
documents (with physical or electronic signature) for extended identification
of the Employer and/or its Representatives before activating the account or at
any time thereafter if there are reasons to believe that the provided information
is inaccurate or incomplete.</span><br>
<br>
<span style='color:black'>1.7. You are responsible for all actions carried out
through your account accessed via your username and password. You shall
immediately notify </span><span lang=EN-US style='color:black'>J</span><span
lang=EN-US style='color:#333333'>obpreference.com</span><span style='color:
black'> in case of an actual or possible unauthorized access.</span> </p>

<p class=MsoNormal>&nbsp;</p>

<p class=MsoNormal><b><span style='color:black'>2. Contract Conclusion</span></b><br>
<br>
<span style='color:black'>2.1. By clicking the ‘Registration’ button, the
person representing the Employer makes an electronic statement by which the
Employer and the Representative conclude a contract with </span><span
lang=EN-US style='color:black'>J</span><span lang=EN-US style='color:#333333'>obpreference.com</span><span
style='color:black'> under these Terms (“Contract”) and undertake to abide by
them. The information provided in the registration form can be modified before
clicking the “Registration” button.</span><br>
<br>
<span style='color:black'>2.2. The Contract is deemed concluded as of the time
of activation of the account by </span><span lang=EN-US style='color:black'>J</span><span
lang=EN-US style='color:#333333'>obpreference.com</span><span style='color:
black'>.</span><br>
<br>
<span style='color:black'>2.3. When using paid Services, a separate order shall
be placed for each Service. The provision of the Services is based on this
Contract and Terms.</span><br>
<br>
<span style='color:black'>2.4. By accepting these Terms You agree to be
addressed and to receive communication in electronic form in relation to the
Contract and the provided Services from </span><span lang=EN-US
style='color:black'>J</span><span lang=EN-US style='color:#333333'>obpreference.com</span><span
style='color:black'>.</span></p>

<p class=MsoNormal>&nbsp;</p>

<p class=MsoNormal><b><span style='color:black'>3. Publication of Information</span></b><br>
<br>
<span style='color:black'>3.1. You undertake to use the Website in compliance
with these Terms, the additional terms and instructions applicable to
particular Services published on the respective pages of the Website, and the
Bulgarian legislation in force.</span><br>
<br>
<span style='color:black'>3.2. You undertake to only </span><span lang=EN-US
style='color:black'>offer</span><span style='color:black'>  actual and open
positions.<span style='background:transparent'><br>
<br>
</span>3.3. It is not allowed to promote business opportunities, partnerships,
products, services or websites, or to include requirements for joining social
media websites, registrations, participation in games, pyramid schemes, etc.
You agree that the Applicants are not required in any manner to buy trainings
or any other services.<span style='background:transparent'><br>
<br>
</span>3.4. Your offer shall not contain:</span><br>
<span style='color:black'>(a) requirements of discriminatory nature;</span><br>
<span style='color:black'>(b) content violating or not respecting third
persons’ rights or legal interests, including fundamental human rights,
intellectual property rights, etc.;</span><br>
<span style='color:black'>(c) or any content of illegal, discriminatory,
uncensored, offending, threatening, defamatory, hateful or indecent nature, or
such that might insult or put a third person in an undesired position.<span
style='background:transparent'><br>
<br>
</span>3.5. </span><span lang=EN-US style='color:black'>J</span><span
lang=EN-US style='color:#333333'>obpreference.com </span><span
style='color:black'>reserves the right to delete or require editing of content
published by You which is not in compliance with the Terms or the additional
terms and instructions published on the Website.<span style='background:transparent'><br>
<br>
</span>3.6. You guarantee that You have the right to use any content You
publish on the Website. Otherwise, You shall be liable for all related damages
caused to </span><span lang=EN-US style='color:black'>J</span><span lang=EN-US
style='color:#333333'>obpreference.com</span><span style='color:black'> or any third
parties.<span><br>
<br>
3.7. You give the right to </span></span><span lang=EN-US style='color:black;
'>J</span><span lang=EN-US style='color:#333333;'>obpreference.com</span><span style='color:black;'> to
make publicly accessible (both in Bulgaria and abroad) the offers You publish
and other public content for the purposes of providing the Services, and </span><span
lang=EN-US style='color:black;'>J</span><span lang=EN-US
style='color:#333333;'>obpreference.com</span><span
style='color:black;'> shall owe no remuneration therefor.<br>
<br>
3.8. By publishing content on the Website You grant its users the right to
access and use it in compliance with the Terms of service and the law.<br>
<br>
</span><span style='color:black'>3.9. </span><span lang=EN-US style='color:
black'>J</span><span lang=EN-US style='color:#333333'>obpreference.com</span><span
style='color:black'> reserves the right to refuse, to temporarily suspend or
terminate the provision of a Service:</span><br>
<span style='color:black'>(a) in case of unfair behavior of the Employer (e.g.
publication of inaccurate or misleading offers; references to competitors’
websites, etc.);</span><br>
<span style='color:black'>(b) in case the Employer has similar business
activities to the activities of the Website;</span><br>
<span style='color:black'>(c) in case of suspected violations of these Terms or
the law.<span style='background:transparent'><br>
<br>
<br>
</span>3.10. To guarantee the reliability and security of the Service, </span><span
lang=EN-US style='color:black'>J</span><span lang=EN-US style='color:#333333'>obpreference.com</span><span
style='color:black'> reserves the right to contact the competent authorities in
case of gross violations of these Terms.</span> </p>

<p class=MsoNormal>&nbsp;</p>

<p class=MsoNormal><b><span style='color:black'>4. Use of Content</span></b><br>
<br>
<span style='color:black'>4.1. The Website and the content are owned by </span><span
lang=EN-US style='color:black'>J</span><span lang=EN-US style='color:#333333'>obpreference.com</span><span
style='color:black'> and/or its partners, suppliers and clients. You are not
allowed to copy, modify, disseminate or use in any manner content which is not
yours for purposes other than or incompatible with the use of the Services and
the Website, without prior written consent of </span><span lang=EN-US
style='color:black'>J</span><span lang=EN-US style='color:#333333'>obpreference.com</span><span
style='color:black'> or the respective third parties.</span><br>
<br>
<span style='color:black'>4.2. Contact details of Employers and their
Representatives may be used only for the intended purpose. You undertake not to
use these contact details for any other purpose, including but not limited to,
for marketing and promotional purposes.</span><br>
<br>
<span style='color:black'>4.3. You undertake not to act in a manner which might
impede the normal operation of the technical or software applications and
computer systems of </span><span lang=EN-US style='color:black'>J</span><span
lang=EN-US style='color:#333333'>obpreference.com</span><span style='color:
black'> or the Website users.</span><br>
<br>
<b><span style='color:black'>5. Personal Data Processing</span></b><br>
<br>
<span style='color:black'>Matters related to personal data processing in
relation to the provision of the Services are regulated by the Data Processing
Agreement, which is an integral part of this Contract.</span></p>

<p class=MsoNormal>&nbsp;</p>

<p class=MsoNormal><b><span style='color:black'>6. Financial Terms and
Conditions</span></b><br>
<br>
<span style='color:black'>6.1. The prices of the paid Services are calculated
automatically, depending on the selected parameters and according to the
prices  published on the Website.</span><br>
<br>
<span style='color:black'>6.2. The financial terms and prices may be unilaterally
amended by </span><span lang=EN-US style='color:black'>J</span><span
lang=EN-US style='color:#333333'>obpreference.com</span><span style='color:
black'>. Such changes do not affect the provision of Services already ordered
and confirmed by </span><span lang=EN-US style='color:black'>J</span><span
lang=EN-US style='color:#333333'>obpreference.com</span><span style='color:
black'>.</span><br>
<br>
<span style='color:black'>6.3. The payment should be made using available at
the Website payment methods. All invoices and other financial documents are
accessible in the Employer’s account on the Website. </span><br>
<br>
<span style='color:black'>6.4. After a Service order has been confirmed, the
latter may not be terminated by the Employer. In such cases, the ordered
Service shall be paid in full, and paid amounts are not subject to
reimbursement.</span><br>
<br>
<span style='color:black'>6.5. </span><span lang=EN-US style='color:black'>J</span><span
lang=EN-US style='color:#333333'>obpreference.com</span><span style='color:
black'> reserves the right to refuse the provision of a Service in case of a
non-compliance with the Terms and/or at its own discretion.</span><br>
<br>
<span style='color:black'>6.6. Credits packages. A credits package may be used
in full or in parts for ordering Services on the Website. In case a credits
package has not been used in full, the unused part is not subject to
reimbursement. Upon ordering a Service with credits from a credits package,
credits equal to the Service price (in credits) specified in the price list at
the time of purchase of the respective Service shall be deducted from the
credits available.</span></p>

<p class=MsoNormal>&nbsp;</p>

<p class=MsoNormal>&nbsp;</p>

<p class=MsoNormal><b><span style='color:black'>7. Liability and Termination</span></b><br>
<br>
<span style='color:black'>7.1. </span><span lang=EN-US style='color:black'>J</span><span
lang=EN-US style='color:#333333'>obpreference.com</span><span style='color:
black'> may block your account:</span><br>
<span style='color:black'>(a) in case of suspected violations of law, of these
Terms or other terms published on the Website;</span><br>
<span style='color:black'>(b) at your request.</span><br>
<br>
<span style='color:black'>7.2. </span><span lang=EN-US style='color:black'>J</span><span
lang=EN-US style='color:#333333'>obpreference.com</span><span style='color:
black'> may terminate the Contract and your account:</span><br>
<span style='color:black'>(a) in case the account has not been used for a long
period of time – more than 3 /three/ years;</span><br>
<span style='color:black'>(b) with a reasonable notice in case of termination
of the Services provided by the Website;</span><br>
<span style='color:black'>(c) in other cases as provided for in the Terms or by
law.</span><br>
<br>
<span style='color:black'>7.3. </span><span lang=EN-US style='color:black'>J</span><span
lang=EN-US style='color:#333333'>obpreference.com</span><span style='color:
black'> shall not be liable for any damages or lost profits incurred by You or
by third parties resulting from execution of orders issued by competent state
authorities.</span><br>
<br>
<span style='color:black'>7.4. Except in case of intent or gross negligence, </span><span
lang=EN-US style='color:black'>J</span><span lang=EN-US style='color:#333333'>obpreference.com</span><span
style='color:black'> shall not be liable for damages and lost profits caused:</span><br>
<span style='color:black'>(a) as a result of use or inability to use the
Website and/or the Services;</span><br>
<span style='color:black'>(b) due to restriction, termination or blocking of
your account and/or your content in compliance with the Terms.</span><br>
<br>
<span style='color:black'>7.5. In any case, </span><span lang=EN-US
style='color:black'>J</span><span lang=EN-US style='color:#333333'>obpreference.com</span><span
style='color:black'>’s liability in relation to a provided Service on the
Website is limited to the amount You have paid for the specific Service.</span></p>

<p class=MsoNormal>&nbsp;</p>

<p class=MsoNormal><b><span style='color:black'>8. Changes of the Terms and the
Services</span></b><br>
<br>
<span style='color:black'>8.1. As far as the Services provided by </span><span
lang=EN-US style='color:black'>J</span><span lang=EN-US style='color:#333333'>obpreference.com</span><span
style='color:black'> are diverse and continuously supplemented and upgraded, as
well as in connection with legislative changes, </span><span lang=EN-US
style='color:black'>J</span><span lang=EN-US style='color:#333333'>obpreference.com</span><span
style='color:black'> reserves the right to add new Services, modify or remove
existing Services, without prior notification of such changes.</span><br>
<br>
<span style='color:black'>8.2. Upon making changes in the Terms, </span><span
lang=EN-US style='color:black'>J</span><span lang=EN-US style='color:#333333'>obpreference.com</span><span
style='color:black'> brings such changes to your knowledge by posting them at a
visible place on the Website or in your account. Changes to the Terms will not
affect the provision of Services ordered and paid before the change.</span><br>
<br>
<span style='color:black'>8.3. In case You use the Services after the changes
of the Terms enter into force, the changes will be deemed binding on You.</span><br>
<br>
<span style='color:black'>8.4. In case of a merger, acquisition or sale of
assets, we reserve the right to transfer our rights and obligations in relation
to these Terms. In such a case, You will be given an advance notice.</span></p>

<p class=MsoNormal>&nbsp;</p>

<p class=MsoNormal><b><span style='color:black'>9. Other Provisions</span></b><br>
<br>
<span style='color:black'>9.1. These Terms govern the relationship between the
parties. Additional terms can be concluded only via the functionalities
provided for in the Website or by mutually signed explicit agreement provided
by </span><span lang=EN-US style='color:black'>J</span><span lang=EN-US
style='color:#333333'>obpreference.com</span><span style='color:black'>.</span><br>
<br>
<span style='color:black'>9.2. In case any of the clauses under these Terms
proves to be void, this will not make void concluded contracts or other
clauses.</span><br>
<br>
<span style='color:black'>9.3. </span><span lang=EN-US style='color:black;
background:transparent'>The contract is concluded in English.</span><span
style='color:black'>Any disputes under these Terms will be settled by the
competent court in </span><span lang=EN-US style='color:black'>Varna</span><span
style='color:black'>.</span><br>
<br>
<span style='color:black'>9.4. For any unsettled issues the legislation of the
Republic of Bulgaria shall apply.</span></p>

<p class=MsoNormal>&nbsp;</p>

<p class=MsoNormal>&nbsp;</p>

<table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
 style='border-collapse:collapse'>
 <tr>
  <td width=643 style='width:481.9pt;padding:1.4pt 1.4pt 1.4pt 1.4pt'>
  <p class=-><b><span style='font-size:16.0pt'>DATA PROCESSING AGREEMENT</span></b></p>
  </td>
 </tr>
 <tr>
  <td width=643 style='width:481.9pt;padding:1.4pt 1.4pt 1.4pt 1.4pt'>
  <div style='border:none;border-bottom:solid silver 1.0pt;padding:0cm 0cm 1.0pt 0cm'>
  <p class=- style='line-height:150%;border:none;padding:0cm'><em><span
  style='font-style:normal'>This agreement is an integral part of the Terms.
  Job ads with external application links to an ATS or company site are outside
  of </span></em><em><span lang=EN-US style='color:black;font-style:normal'>J</span></em><em><span
  lang=EN-US style='color:#333333;font-style:normal'>obpreference.com</span></em><em><span
  style='font-style:normal'>’s scope of responsibilities.</span></em><br>
  <br>
  <b>1. Personal data processing</b><br>
  <br>
  Personal data processing (“Processing”) refers to any operation or set of
  operations which is performed on personal data, whether or not by automated
  means, such as collection, recording, organization, structuring, storage,
  adaptation or alteration, retrieval, consultation, use, disclosure by
  transmission, dissemination or otherwise making available, alignment or
  combination, restriction, erasure or destruction.</p>
  <p class=- style='line-height:150%;border:none;padding:0cm'>&nbsp;</p>
  <p class=- style='line-height:150%;border:none;padding:0cm'><b><span
  style='color:black'>2. Employer Representatives</span></b><br>
  <br>
  <span style='color:black'>2.1. To allow access and to provide the Services, </span><em><span
  lang=EN-US style='color:black;font-style:normal'>J</span></em><em><span
  lang=EN-US style='color:#333333;font-style:normal'>obpreference.com</span></em><span
  style='color:black'> processes data regarding the persons representing the
  Employer (&quot;Representatives&quot;) in relation to the use of the
  Services.</span><br>
  <br>
  <span style='color:black'>2.2. </span><em><span lang=EN-US style='color:black;
  font-style:normal'>J</span></em><em><span lang=EN-US style='color:#333333;
  font-style:normal'>obpreference.com</span></em><span style='color:black'>
  processes personal data of the Employer Representatives as a Data Controller
  in correspondence with the Privacy Policy for Employers,  which is an
  integral part of the Terms.</span><br>
  <br>
  <span style='color:black'>2.3. By adding users and contact persons to the
  Employer's account, You declare that You have the necessary authorization and
  that the persons have been informed of and are acquainted with the Privacy
  Policy for Employers of </span><em><span lang=EN-US style='color:black;
  font-style:normal'>J</span></em><em><span lang=EN-US style='color:#333333;
  font-style:normal'>obpreference.com</span></em><span style='color:black'>.</span>
  </p>
  <p class=- style='line-height:150%;border:none;padding:0cm'>&nbsp;</p>
  <p class=- style='line-height:150%;border:none;padding:0cm'><b><span
  style='color:black'>3. Job Applications</span></b><br>
  <br>
  <span style='color:black'>When </span><span lang=EN-US style='color:black'>unlocking</span><span
  style='color:black'> a job </span><span lang=EN-US style='color:black'>preference</span><span
  style='color:black'>:</span><br>
  <br>
  <span style='color:black'>3.1. The Employer is a Data Controller with respect
  to the applications received in its account.</span><br>
  <br>
  <span style='color:black'>3.2. The Website is a technological platform that
  allows Employers to receive and store </span><span lang=EN-US
  style='color:black'>job preferences</span><span style='color:black'> . As a
  provider of this Service, </span><em><span lang=EN-US style='color:black;
  font-style:normal'>J</span></em><em><span lang=EN-US style='color:#333333;
  font-style:normal'>obpreference.com</span></em><span style='color:black'>
  acts in the role of a Data Processor.</span><br>
  <br>
  <b><span style='color:black'>4. Obligations of the Employer</span></b><br>
  <br>
  <span style='color:black'>As part of these Terms, the Employer:</span><br>
  <br>
  <span style='color:black'>4</span><span lang=EN-US style='color:black'>.1.</span><span
  style='color:black'> as a Controller is responsible to choose appropriate
  legal basis for processing the Applicants data in accordance with the
  Regulation (EC) 2016/679;</span><br>
  <br>
  <span style='color:black'>4.</span><span lang=EN-US style='color:black'>2</span><span
  style='color:black'>. undertakes to ensure and bear full responsibility that
  the requirements of the Regulation (EU) 2016/679 and these Terms will be
  respected and complied with by its Representatives, personnel and all other
  persons to whom it might provide data regarding the Applicants;</span><br>
  <br>
  <span style='color:black'>4</span><span lang=EN-US style='color:black'>.3.</span><span
  style='color:black'> should Applicants request to exercise their data
  protection rights, the Employer shall fulfill these requests and/or provide
  the Applicants with confirmation or reasoning for rejecting the request
  within the terms set by the Regulation (EU) 2016/679;</span><br>
  <br>
  <span style='color:black'>4.</span><span lang=EN-US style='color:black'>4</span><span
  style='color:black'>. undertakes to provide up-to-date contact details to
  allow Applicants to contact the Employer regarding personal data protection
  matters, including contact details of its Data Protection Officer (if
  applicable);</span><br>
  <br>
  <span style='color:black'>4.</span><span lang=EN-US style='color:black'>5</span><span
  style='color:black'>. shall not require in any way from Applicants to send
  special categories of personal data within the meaning of Art. 9 and Art. 10
  of Regulation (EC) 2016/679 through the Website. The Website is not intended
  for collecting, processing and storing of such data.</span> </p>
  <p class=- style='line-height:150%;border:none;padding:0cm'>&nbsp;</p>
  <p class=- style='line-height:150%;border:none;padding:0cm'><b><span
  style='color:black'>5.Data Processing for the purpose of providing the
  Services</span></b><br>
  <br>
  <em><span lang=EN-US style='font-style:normal'>J<span style='color:#333333'>obpreference.com</span></span></em><span
  style='color:black'> provides technical solution that allows You to receive
  and store job applications in your account, with regards to this Service </span><em><span
  lang=EN-US style='color:black;font-style:normal'>J</span></em><em><span
  lang=EN-US style='color:#333333;font-style:normal'>obpreference.com</span></em><span
  style='color:black'> processes data on your behalf as a Data Processor.</span><br>
  <br>
  <span style='color:black'>5.1 By accepting these Terms, You assign to </span><em><span
  lang=EN-US style='color:black;font-style:normal'>J</span></em><em><span
  lang=EN-US style='color:#333333;font-style:normal'>obpreference.com</span></em><span
  style='color:black'> to process personal data for the purposes of providing
  the Services subject to this Agreement on your behalf, strictly complying
  with these Terms and Your Instructions (i.5.3).</span><br>
  <br>
  <span style='color:black'>5.2. </span><em><span lang=EN-US style='color:black;
  font-style:normal'>J</span></em><em><span lang=EN-US style='color:#333333;
  font-style:normal'>obpreference.com</span></em><span style='color:black'>
  undertakes not to process information regarding Applicants  for purposes
  other than those specified in these Terms, except when required to do so
  under applicable law or in cases where it acts as a Controller.</span><br>
  <br>
  <span style='color:black'>5.3. Instructions for data processing. These are
  the instructions outlined in this Agreement, as well the instructions made
  through the functionalities available in the Website. You agree that You will
  submit your instructions in the manner provided for in the Website and that
  your instructions shall be in compliance with the Regulation (EC) 2016/679.</span><br>
  <br>
  <span style='color:black'>5.4. By accepting these Terms You declare that You
  have been informed that in some occasions set forth by law, </span><em><span
  lang=EN-US style='color:black;font-style:normal'>J</span></em><em><span
  lang=EN-US style='color:#333333;font-style:normal'>obpreference.com</span></em><span
  style='color:black'> may be required to keep and disclose certain data that
  it has processed on your behalf to the competent authorities. </span><em><span
  lang=EN-US style='color:black;font-style:normal'>J</span></em><em><span
  lang=EN-US style='color:#333333;font-style:normal'>obpreference.com</span></em><span
  style='color:black'> undertakes to inform You of such orders, except in the
  cases when it is prohibited by law.</span><br>
  <br>
  <span style='color:black;background:transparent'>5.5. The Data Processing, related
  to the provision of the Services, takes place in Bulgaria. </span><em><span
  lang=EN-US style='color:black;background:transparent;font-style:normal'>J</span></em><em><span
  lang=EN-US style='color:#333333;background:transparent;font-style:normal'>obpreference.com</span></em><span
  style='color:black;background:transparent'> shall not use any equipment located
  outside the EU to process personal data of Applicants to your job offers.</span><span
  style='color:black;background:transparent'><br>
  <br>
  </span><span style='color:black;background:transparent'>5.6. Subcontractors. </span><em><span
  lang=EN-US style='color:black;background:transparent;font-style:normal'>J</span></em><em><span
  lang=EN-US style='color:#333333;background:transparent;font-style:normal'>obpreference.com</span></em><span
  style='color:black;background:transparent'> works with subcontractors (such as
  data centers for the collocation of servers and network equipment, etc.). If
  applicable, should a subcontractor process Employer’s personal data, it will
  be required to commit to similar to </span><em><span lang=EN-US
  style='color:black;background:transparent;font-style:normal'>J</span></em><em><span
  lang=EN-US style='color:#333333;background:transparent;font-style:normal'>obpreference.com</span></em><span
  style='color:black;background:transparent'>’s contractual obligations and level of
  protection. Subcontractors will be required not to process such data for any
  other purpose than in relation to the Services provided by </span><em><span
  lang=EN-US style='color:black;background:transparent;font-style:normal'>J</span></em><em><span
  lang=EN-US style='color:#333333;background:transparent;font-style:normal'>obpreference.com</span></em><span
  style='color:black;background:transparent'> to the Employer. You agree to the use
  of subcontractors by </span><em><span lang=EN-US style='color:black;
  background:transparent;font-style:normal'>J</span></em><em><span lang=EN-US
  style='color:#333333;background:transparent;font-style:normal'>obpreference.com</span></em><span
  style='color:black;background:transparent'> for the provision of the Services. </span><em><span
  lang=EN-US style='color:black;background:transparent;font-style:normal'>J</span></em><em><span
  lang=EN-US style='color:#333333;background:transparent;font-style:normal'>obpreference.com</span></em><span
  style='color:black;background:transparent'> maintains an up-to-date list of the
  subcontractors&nbsp;</span><a href="https://www.jobs.bg/suppliers.php"><span
  style='color:#003399;background:transparent'>here</span></a><span
  style='color:black;background:transparent'>. In the event of any change, You will
  be explicitly notified prior to the change. </span></p>
  <p class=- style='line-height:150%;border:none;padding:0cm'>&nbsp;</p>
  <p class=- style='line-height:150%;border:none;padding:0cm'><b><span
  style='color:black'>6. Storage, erasure and export of data</span></b><br>
  <br>
  <span style='color:black'>By accepting these Terms, You assign and </span><em><span
  lang=EN-US style='color:black;font-style:normal'>J</span></em><em><span
  lang=EN-US style='color:#333333;font-style:normal'>obpreference.com</span></em><span
  style='color:black'> undertakes to:</span><br>
  <br>
  <span style='color:black'>6.1. provide technical solution to enable the
  storage of </span><span lang=EN-US style='color:black'>unlock preferences</span><span
  style='color:black'>  in your account until deleted by You (by your
  Representative), but no longer than </span><span lang=EN-US style='color:
  black'>1</span><span style='color:black'> /</span><span lang=EN-US
  style='color:black'>one</span><span style='color:black'>/ month from the date
  </span><span lang=EN-US style='color:black'>of the unlock</span><span
  style='color:black'> of the job </span><span lang=EN-US style='color:black'>preference</span><span
  style='color:black'>;</span><br>
  <br>
  <span style='color:black'>6.2. ensure automatic erasure of job </span><span
  lang=EN-US style='color:black'>preferences</span><span style='color:black'>
  in your account after the technical storage period listed in p. 6.1. expires
  (unless the applications have been deleted earlier by You, using the
  functionalities provided in the Website);</span><br>
  <br>
  <span style='color:black'>6.</span><span lang=EN-US style='color:black'>3</span><span
  style='color:black'>. to automatically erase all applications in your account
  upon termination of your registration in the Website, except when this is
  prohibited by law. </span><br>
  <br>
  </p>
  <p class=- style='line-height:150%;border:none;padding:0cm'><b><span
  lang=EN-US>7</span>. Security</b><br>
  <br>
  <em><span lang=EN-US style='font-style:normal'>J<span style='color:#333333'>obpreference.com</span></span></em>
  undertakes to:<br>
  <br>
  <span lang=EN-US>7</span>.1. apply technical and organizational measures
  (with regard to personnel, buildings, software, hardware, networks, servers,
  encryption, control, reporting and monitoring, vulnerability testing, etc.)
  to ensure level of protection against unauthorized or incidental access,
  loss, change, disclosure or erasure of data, that takes into consideration
  the relevant risks. <br>
  <br>
<br>
<div class=WordSection1>

<p class=MsoNormal><b><span lang=EN-US style='color:black'>GENERAL TERMS OF
SERVICE FOR NATURAL PERSONS</span></b></p>

<p class=MsoNormal><b><span lang=EN-US style='color:black'>1. Registration<br>
<br>
</span></b><span lang=EN-US style='color:black'>1.1. Sun Land Capital OOD (“J</span><span
lang=EN-US style='color:#333333'>obpreference.com</span><span lang=EN-US
style='color:black'>”/ “We”), with registered address: 4 Georgi Boev Str.,
Varna, UIC: </span><span lang=EN-US style='color:#333333'>206166575</span><span
lang=EN-US style='color:black'>, by means of the website www. jobpreference.com
(“Website”) provides to natural persons free and paid information society
services (“Services”).</span></p>

<p class=MsoNormal><span lang=EN-US style='color:black'>1.2. In order to use
the Services, you guarantee that you have reached the age of 18 and are capable
of concluding a contract. J</span><span lang=EN-US style='color:#333333'>obpreference.com</span><span
lang=EN-US style='color:black'> is not responsible in case of use of the
Services by a person who does not meet the specified age and legal capacity
restrictions.</span></p>

<p class=MsoNormal><span lang=EN-US style='color:black'>1.3. The use of the
Services is possible only with registration. Registration is done in person.
Upon registration you are required to fill in the required fields in the
registration form (name, email address, etc.). You declare that you meet the
requirements of item 1.2.</span></p>

<p class=MsoNormal><span style='color:black'>1.4. </span><span lang=EN-US
style='color:black'>You shall provide correct, complete and current data upon
registration, when using the Services and keep it up to date thereafter.</span></p>

<p class=MsoNormal><span lang=EN-US style='color:black'>1.5.You are responsible
for all actions carried out through your account accessed via your username and
password. You shall immediately notify J</span><span lang=EN-US
style='color:#333333'>obpreference.com</span><span lang=EN-US style='color:
black'> in case of an actual or possible unauthorized access. </span></p>

<p class=MsoNormal>&nbsp;</p>

<p class=MsoNormal><b><span lang=EN-US style='color:black'>2. Concluding a
contract</span></b><span lang=EN-US style='color:black'><br>
<br>
2.1. By clicking on the &quot;Registration&quot; button and confirming the
registration email you have received, you make an electronic statement by which
you enter into a contract with Us under these Terms and undertake to comply
with them. Before clicking the &quot;Register&quot; button, you have the
opportunity to view and edit the information you provided. You accept that the
electronic statements to Jobpreference.com and the electronic statements from
Jobpreference.com to you will be considered signed with a simple electronic
signature in accordance with Art. 3 (10) of Regulation (EU) № 910/2014, by hand
signature. The authorship of these statements will be established by the order
of art. 13, para. 1 in connection with art. 13, para. 4, assoc. 2nd of the
Electronic Document and Electronic Certification Services Act (“ZEDEUU”).</span></p>

<p class=MsoNormal><span lang=EN-US style='color:black'>2.2. By accepting these
Terms You agree to be addressed and to receive communication in electronic form
in relation to the Contract and the provided Services from J</span><span
lang=EN-US style='color:#333333'>obpreference.com</span><span lang=EN-US
style='color:black'>.</span></p>

<p class=MsoNormal>&nbsp;</p>

<p class=MsoNormal><b><span lang=EN-US style='color:black'>3. Use of the
Services and the content on the Site</span></b><span lang=EN-US
style='color:black'><br>
<br>
3.1. The site allows for the publication of advertisements for preferred jobs
by individuals. Ads and their content are the sole responsibility of the people
who posted them. Upon unlocking, your job preference automatically arrives in
the account of the Employer or another user in Jobpreference.com.You decide
which proposals and which Employers to respond to. For this purpose, we
recommend that you seek more information about the Employer both on the Site
and from other sources.</span></p>

<p class=MsoNormal><span lang=EN-US style='color:black'>3.2. Jobpreference.com
is not an intermediary and does not participate in any way in the selection of
candidates or subsequent communication and negotiation of terms between the
parties. Decisions about what information to send, what contractual
relationships you wish to enter into and under what conditions are entirely your
responsibility. Jobpreference.com is not a party to this relationship and
therefore, in case you believe that the Employer does not comply with the
agreed conditions and / or his actions are not in accordance with the law, it
is necessary to notify the competent state authorities in whose competences and
powers is to carry out the relevant inspections and take action against the
Employer. Use of the Services is at your own risk and responsibility.</span></p>

<p class=MsoNormal><span style='color:black'>3.3.The Website and the content
are owned by </span><span lang=EN-US style='color:black'>J</span><span
lang=EN-US style='color:#333333'>obpreference.com</span><span style='color:
black'> and/or its partners, suppliers and clients. You are not allowed to
copy, modify, disseminate or use in any manner content which is not yours for
purposes other than or incompatible with the use of the Services and the
Website, without prior written consent of </span><span lang=EN-US
style='color:black'>J</span><span lang=EN-US style='color:#333333'>obpreference.com</span><span
style='color:black'> or the respective third parties.</span></p>

<p class=MsoNormal><span style='color:black'>3.4.Contact details of Employers
and their Representatives may be used only for the intended purpose. You
undertake not to use these contact details for any other purpose, including but
not limited to, for marketing and promotional purposes.</span></p>

<p class=MsoNormal><span style='color:black'>3.5.You undertake not to act in a
manner which might impede the normal operation of the technical or software
applications and computer systems of </span><span lang=EN-US style='color:black'>J</span><span
lang=EN-US style='color:#333333'>obpreference.com</span><span style='color:
black'> or the Website users.<br>
<br>
</span></p>

<p class=MsoNormal><b><span style='color:black'>4. Creating and storing CVs,
files, etc. information</span></b><span style='color:black'><br>
<br>
4.1. The </span><span lang=EN-US style='color:black'>web</span><span
style='color:black'>site allows you to create and / or save CVs, files, etc.
information that you can post at your discretion in your ads.</span></p>

<p class=MsoNormal><span style='color:black'>4.2. The documents stored on the
Site are available to all users. When communicating, you decide what
information and to which Employers / users to send it. When your  published job
preference is unlocked, it automatically reaches the account of the Employer or
another user.</span></p>

<p class=MsoNormal><span style='color:black'>4.3.You guarantee that You have
the right to use any content You publish on the Website. Otherwise, You shall
be liable for all related damages caused to </span><span lang=EN-US
style='color:black'>J</span><span lang=EN-US style='color:#333333'>obpreference.com</span><span
style='color:black'> or any third parties.</span></p>

<p class=MsoNormal><span style='color:black'>4.4.Your </span><span lang=EN-US
style='color:black'>ad</span><span style='color:black'> shall not contain:<br>
(a) requirements of discriminatory nature;<br>
(b) content violating or not respecting third persons’ rights or legal
interests, including fundamental human rights, intellectual property rights,
etc.;<br>
(c) or any content of illegal, discriminatory, uncensored, offending,
threatening, defamatory, hateful or indecent nature, or such that might insult
or put a third person in an undesired position.</span></p>

<p class=MsoNormal>&nbsp;</p>

<p class=MsoNormal><b><span style='color:black'>5. </span></b><b><span
style='color:black'>Termination</span></b></p>

<p class=MsoNormal><span style='color:black'>5.1.</span><span lang=EN-US
style='color:black'>J</span><span lang=EN-US style='color:#333333'>obpreference.com</span><span
style='color:black'> may terminate your account:</span></p>

<p class=MsoNormal><span style='color:black'>(a) at any time, at your request
(through the functionality on the Site);</span></p>

<p class=MsoNormal><span style='color:black'>(b) in case the account has not
been used for a long period of time – more than 3 /three/ years;</span></p>

<p class=MsoNormal><span style='color:black'>(</span><span lang=EN-US
style='color:black'>c</span><span style='color:black'>) in case of suspected
violations of law, of these Terms or other terms published on the Website;</span></p>

<p class=MsoNormal><span style='color:black'>(</span><span lang=EN-US
style='color:black'>d</span><span style='color:black'>) in other cases, at the
discretion of Jobpreference.com, with prior notice.</span></p>

<p class=MsoNormal><span style='color:black'>5.2.</span><span lang=EN-US
style='color:black'>J</span><span lang=EN-US style='color:#333333'>obpreference.com</span><span
style='color:black'> may block your account:</span></p>

<p class=MsoNormal><span style='color:black'>(a) in case of suspected
violations of law, of these Terms or other terms published on the Website;<br>
(b) upon a request from a competent state body;</span></p>

<p class=MsoNormal><span lang=EN-US style='color:black'>(c)</span><span
style='color:black'> at your request.</span></p>

<p class=MsoNormal><span style='color:black'>5.3.Upon closing an account and
terminating the contract, the data stored on the Site </span><span lang=EN-US
style='color:black'>is</span><span style='color:black'> deleted. This includes
both the data in your account and your unlocked preferences (at the time of
closing) located in the accounts of Employers or other users on the Site.</span></p>

<p class=MsoNormal><span style='color:black'>After closing the account, all
data, part of it, is deleted. In connection with our obligations, responsibilities
and requirements of the law for a period of up to 1 / one / year are stored:<br>
<br>
    In order to resolve possible disputes that have arisen or become known
after the termination of the agreement for the use of the Services, for a
period of 1 / one / year after the termination of the account, information
about its conclusion is stored.<br>
<br>
   The data shall be deleted completely after the expiry of the specified
period. In the meantime, they may be provided only in due course to the
competent state authorities in the exercise of their supervisory powers or to a
competent court in the event of legal proceedings in which they are relevant.
In the event of a legal dispute or proceedings requiring the retention of data
and / or a request from a competent state authority, it is possible to retain
data for longer than the specified periods until the final conclusion of the
dispute or proceedings before all instances.</span></p>

<p class=MsoNormal>&nbsp;</p>

<p class=MsoNormal><b><span style='color:black'>6. Changes of the Terms and the
Services</span></b><span style='color:black'><br>
<br>
6.1. As far as the Services provided by </span><span lang=EN-US
style='color:black'>J</span><span lang=EN-US style='color:#333333'>obpreference.com</span><span
style='color:black'> are diverse and continuously supplemented and upgraded, as
well as in connection with legislative changes, </span><span lang=EN-US
style='color:black'>J</span><span lang=EN-US style='color:#333333'>obpreference.com</span><span
style='color:black'> reserves the right to add new Services, modify or remove
existing Services, without prior notification of such changes.</span></p>

<p class=MsoNormal><span style='color:black'>6.2. Upon making changes in the
Terms, </span><span lang=EN-US style='color:black'>J</span><span lang=EN-US
style='color:#333333'>obpreference.com</span><span style='color:black'> brings
such changes to your knowledge by posting them at a visible place on the
Website or in your account. Changes to the Terms will not affect the provision
of Services ordered and paid before the change.</span></p>

<p class=MsoNormal>&nbsp;</p>

<p class=MsoNormal><b><span style='color:black'>7. Other Provisions</span></b><span
style='color:black'><br>
<br>
7.1. These Terms govern the relationship between the parties. Additional terms
can be concluded only via the functionalities provided for in the Website or by
mutually signed explicit agreement provided by </span><span lang=EN-US
style='color:black'>J</span><span lang=EN-US style='color:#333333'>obpreference.com</span><span
style='color:black'>.<br>
7.2. In case any of the clauses under these Terms proves to be void, this will
not make void concluded contracts or other clauses.<br>
7.3. </span><span lang=EN-US style='color:black;background:transparent'>The contract
is concluded in English.</span><span style='color:black'>Any disputes under
these Terms will be settled by the competent court in </span><span lang=EN-US
style='color:black'>Varna</span><span style='color:black'>.<br>
7.4. For any unsettled issues the legislation of the Republic of Bulgaria shall
apply.</span></p>
</div>

</body>

</html>
`;
