export function handleChange(isChecked, educationID, dispatch, myCvDispatch) {
  dispatch({
    type: "changePresentEducation",
    value: isChecked,
  });
  dispatch({
    type: "setTo",
    value: "",
  });
  myCvDispatch({
    type: "educationChangePresentEducation",
    value: {
      educationID: educationID,
      presentEducation: isChecked,
    },
  });
}
