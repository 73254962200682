import React, { useEffect, useContext, useRef } from "react";
import { useImmerReducer } from "use-immer";
import uuid from "react-uuid";
import Grid from "@material-ui/core/Grid";
import DreamSelectComponent from "../../../ui_components/DreamSelect/DreamSelectComponent";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import MyCvDispatchContext from "../../../Pages/MyCvPage/Context/MyCvDispatchContext";
import * as LanguagesSingleItemReducer from "./LanguagesSingleItemReducer";
import { LanguagesRoot } from "./LanguagesSingleItemComponent.module.scss";
import * as DreamSelectHelper from "../../../ui_components/DreamSelect/DreamSelectHelper";

import JobPreferenceDispatchContext from "../../../Pages/CreateJobPreferencePage/Context/JobPreferenceDispatchContext";

function LanguagesSingleItemComponent(props) {
  const myCvDispatch = useContext(MyCvDispatchContext);

  const jobPreferenceDispatchContext = useContext(JobPreferenceDispatchContext);

  const dispatchContext = props.dispatchContextName
    ? eval(props.dispatchContextName)
    : myCvDispatch;
  const rel = useRef();

  const [state, dispatch] = useImmerReducer(
    LanguagesSingleItemReducer.reducer,
    LanguagesSingleItemReducer.originalState
  );

  const theme = useTheme();
  const matchesXSdown = useMediaQuery(theme.breakpoints.down("xs"));

  function languageChanged(value) {
    dispatchContext({
      type: "languagesLanguageChanged",
      value: {
        languageID: state.id,
        language: value,
      },
    });
  }

  function levelChanged(value) {
    dispatchContext({
      type: "languagesLevelChanged",
      value: {
        languageID: state.id,
        level: value,
      },
    });
  }

  function languageManualyChanged(value) {
    dispatchContext({
      type: "cvLanguagesManualyChanged",
    });
  }

  useEffect(() => {
    let myUuid;
    if (props.languageData && props.languageData.id != null) {
      // In this case, Language is loaded from the DB,
      // so we don't need to generate ID and trigger an event for creation
      myUuid = props.languageData.id;
      dispatch({
        type: "setLevel",
        value: props.languageData.level,
      });
      dispatch({
        type: "setLanguage",
        value: props.languageData.language,
      });
    } else {
      myUuid = uuid();
      dispatchContext({
        type: "languageAdded",
        value: myUuid,
      });
    }

    rel.current = myUuid;

    dispatch({
      type: "setId",
      value: myUuid,
    });

    return () => {
      // on Unmount let's dispatch event
      dispatchContext({
        type: "languageRemoved",
        value: rel.current,
      });
    };
  }, []);

  return (
    <Grid container justify={"space-between"}>
      <DreamSelectComponent
        sort
        style={{ paddingBottom: 10, width: matchesXSdown ? "100%" : "40%" }}
        optionsData={props.optionsData.lang}
        label={"cv.language"}
        dispatchChangeHandler={languageChanged}
        isMandatory={true}
        value={state.language}
        manualyChangedHandler={languageManualyChanged}
      />
      <DreamSelectComponent
        style={{ paddingBottom: 20, width: matchesXSdown ? "100%" : "40%" }}
        optionsData={props.optionsData.level}
        label={"cv.level"}
        dispatchChangeHandler={levelChanged}
        isMandatory={true}
        value={state.level}
        dataType={DreamSelectHelper.DATA_TYPE_LANG_LEVEL}
        manualyChangedHandler={languageManualyChanged}
      />
    </Grid>
  );
}

export default LanguagesSingleItemComponent;
