import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import Colors from "../../styles/01.settings/01-settings-colors.module.scss";

const CustomCheckbox = withStyles({
  root: {
    color: Colors.primary,
    "&$checked": {
      color: Colors.primary,
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const DreamCustomCheckbox = (props) => {
  return (
    <CustomCheckbox
      checked={props.checked}
      onChange={props.onChange}
      name={props.name}
      disabled={props.disabled}
    />
  );
};

export default DreamCustomCheckbox;
