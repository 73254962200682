import * as Constants from "../../helpers/Constants";

// Handle user agree cookies

export async function handleAgreeCookies(Axios, ourRequest, appDispatch) {
  let errMsgs;
  let authenticated = true;

  try {
    const response = await Axios.get(`/user/cookies`, {
      cancelToken: ourRequest.token,
    }).catch(function (error) {
      // Errors to be sent to snackbar
      errMsgs = "basic.serverError";
      if (
        error.response &&
        error.response.status &&
        error.response.status === Constants.ERROR_RESPONSE_STATUS
      ) {
        authenticated = false;
        errMsgs = "basic.unauthenticated";
      }
      appDispatch({ type: "setCookiesDialogOpen", value: false });
      throw errMsgs;
    });

    const responseData = response.data.data;
    let errsArr = [];

    if (!responseData.success && responseData.messages.length) {
      responseData.messages.forEach((msg) => {
        errsArr.push(`serverErrors.${msg.code}`);
      });
      throw errsArr;
    }

    // Successful get data
  } catch (error) {
    appDispatch({ type: "setCookiesDialogOpen", value: false });
    appDispatch({
      type: "showMsg",
      value: { status: Constants.SNACKBAR_ERROR, msg: error },
    });
    if (!authenticated) {
      appDispatch({
        type: "logout",
      });
    }
  }
}
