import { getDroppedOrSelectedFiles } from "html5-file-selector";
import * as Constants from "../../../helpers/Constants";

export const UPLOAD_API_URL = "https://httpbin.org/post";

export const UPLOAD_STATUS_REJECTED_FILE_TYPE = "rejected_file_type";
export const UPLOAD_STATUS_REJECTED_MAX_FILE = "rejected_max_files";
export const UPLOAD_STATUS_PREPARING = "preparing";
export const UPLOAD_STATUS_ERROROR_FILE_SIZE = "error_file_size";
export const UPLOAD_STATUS_ERROR_VALIDATION = "error_validation";
export const UPLOAD_STATUS_READY = "ready";
export const UPLOAD_STATUS_STARTED = "started";
export const UPLOAD_STATUS_GETTING_UPLOADED_PARAMS = "getting_upload_params";
export const UPLOAD_STATUS_ERROR_UPLOAD_PARAMS = "error_upload_params";
export const UPLOAD_STATUS_UPLOADING = "uploading";
export const UPLOAD_STATUS_EXCEPTION_UPLOADED = "exception_upload";
export const UPLOAD_STATUS_ABORTED = "aborted";
export const UPLOAD_STATUS_RESTARTED = "restarted";
export const UPLOAD_STATUS_REMOVED = "removed";
export const UPLOAD_STATUS_ERROR_UPLOAD = "error_upload";
export const UPLOAD_STATUS_HEADERS_RECEIVED = "headers_received";
export const UPLOAD_STATUS_DONE = "done";

/**
 * Convert an image
 * to a base64 url
 * @param  {String}   url
 * @param  {Function} callback
 * @param  {String}   [outputFormat=image/png]
 */
export function convertImgToBase64URL(url, callback, outputFormat) {
  var img = new Image();
  img.crossOrigin = "Anonymous";
  img.onload = function () {
    var canvas = document.createElement("CANVAS"),
      ctx = canvas.getContext("2d"),
      dataURL;
    canvas.height = img.height;
    canvas.width = img.width;
    ctx.drawImage(img, 0, 0);
    dataURL = canvas.toDataURL(outputFormat);
    callback(dataURL);
    canvas = null;
  };
  img.src = url;
}

export function getBase64(file) {
  var reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {};
  reader.onerror = function (error) {
    console.log("Error: ", error);
  };
}

export function getInitialFiles(initialFiles, dispatch) {
  initialFiles.forEach((item, index) => {
    fetch(item.fileUrl).then((res) => {
      res.arrayBuffer().then((buf) => {
        let type = "";
        let name = item.fileName;
        if (item["mime-type"] && !name) {
          let mimeTypeArr = item["mime-type"].split("/");
          type = mimeTypeArr[1] ? mimeTypeArr[1] : "";
          if (!item.fileName) {
            name = `${index}.${type}`;
          }
        }
        const file = new File([buf], `${name}`, {
          type,
        });
        file.id = item.fileId;
        if (item.isImg) {
          file.previewUrl = item.fileUrl;
        }
        if (item["mime-type"]) {
          file["mime-type"] = item["mime-type"];
        }
        if (item.isInitialUpload) {
          file.isInitialUpload = item.isInitialUpload;
        }
        if (item.skipDbDeletion) {
          file.skipDbDeletion = item.skipDbDeletion;
        }
        if (item.downloadUrl) {
          file.downloadUrl = item.downloadUrl;
        }
        file.fileDescription = item.fileDescription;
        file.status = "done";
        dispatch({
          type: "initialFileUpload",
          value: file,
        });
      });
    });
  });
}

export function formatData(uploadedFiles) {
  let newArr = [];
  uploadedFiles.forEach((element) => {
    if (element.file) {
      element.file.fileDescription = element.fileDescription;
      newArr.push(element.file);
    } else {
      newArr.push(element);
    }
  });
  return newArr;
}

export function getFilesFromEvent(e) {
  return new Promise((resolve) => {
    getDroppedOrSelectedFiles(e).then((chosenFiles) => {
      resolve(chosenFiles.map((f) => f.fileObject));
    });
  });
}

export function handleChangeStatus(
  meta,
  remove,
  status,
  fileWithMeta,
  dispatch,
  appDispatch,
  availableUploads,
  somethingManualyChangedHandler
) {
  switch (status) {
    case UPLOAD_STATUS_PREPARING:
      if (availableUploads > 0) {
        dispatch({ type: "uploadStarted", value: { ...meta, remove } });
      }
      break;

    case UPLOAD_STATUS_EXCEPTION_UPLOADED:
      remove();
      dispatch({ type: "uploadFailed", value: meta.id });
      appDispatch({
        type: "showMsg",
        value: {
          status: Constants.SNACKBAR_ERROR,
          msg: "basic.errorUploadingFile",
        },
      });
      break;

    case UPLOAD_STATUS_DONE:
      fileWithMeta.forEach((element) => {
        dispatch({
          type: "uploadFinished",
          value: { ...meta, file: element.file },
        });
        if (somethingManualyChangedHandler) {
          somethingManualyChangedHandler();
        }
      });
      break;

    case UPLOAD_STATUS_REMOVED:
      dispatch({ type: "fileRemoved", value: meta.id });
      break;

    default:
      break;
  }
}

/**
 * Add to form the data for uploaded files
 * @param {Array} uploadedFiles
 * @param {FormData} formData
 */
export function formatRequestData(
  uploadedFiles,
  formData,
  includeUploadedFiles = false
) {
  uploadedFiles.map((item, index) => {
    if (!item.isInitialUpload || includeUploadedFiles) {
      formData.append(
        `file_details[${index}]`,
        JSON.stringify({
          desc: item.fileDescription,
          name: item.name,
        })
      );
      formData.append(`${index}`, item);
    } else {
      formData.append(
        "file_description[]",
        JSON.stringify({
          id: item.id,
          desc: item.fileDescription,
        })
      );
    }
  });
}
