export function formatResponse(data) {
  data.map((element, index) => {
    element.from = element.from ? new Date(element.from) : null;
    element.to = element.to ? new Date(element.to) : null;
    element.id = index;
    element.industry = element.industry;
    element.position = element.position;
    element.presentJob = !Boolean(element.to);
  });

  return data;
}
