import React, { useContext } from "react";

import DreamRadioButtonsComponent from "../../ui_components/DreamRadioButtons/DreamRadioButtonsComponent";
import JobOfferStateContext from "../../Pages/CreateJobOfferPage/Context/JobOfferStateContext";
import JobOfferDispatchContext from "../../Pages/CreateJobOfferPage/Context/JobOfferDispatchContext";
import * as WorkScheduleHelper from "./WorkScheduleHelperJo";

function WorkScheduleComponentJo() {
  const jobOfferDispatchContext = useContext(JobOfferDispatchContext);
  const jobOfferStateContext = useContext(JobOfferStateContext);

  function workScheduleTypeChanged(value) {
    jobOfferDispatchContext({ type: "workScheduleTypeChanged", value: value });
  }

  return (
    <DreamRadioButtonsComponent
      name={"jp.workSchedule"}
      value={jobOfferStateContext.formData.workScheduleType}
      data={WorkScheduleHelper.workScheduleRadioButtons}
      changeHandler={workScheduleTypeChanged}
    />
  );
}

export default WorkScheduleComponentJo;
