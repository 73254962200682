import FormValidator from "../../helpers/FormValidator";
import * as Constants from "../../helpers/Constants";

export const allInputs = {
  email: {
    value: "",
    isValid: true,
    errorMessage: "",
  },
  password: {
    value: "",
    isValid: false,
    errorMessage: "",
  },
  rememberMe: {
    value: false,
    isValid: true,
    errorMessage: "",
  },
};

export const originalState = {
  fields: { ...allInputs },
  hasValidationErrs: true,
  formSubmittedCount: 0,
};

export function reducer(draft, action) {
  switch (action.type) {
    case "emailChange":
      draft.fields.email.value = action.value.value;
      draft.fields.email.errorMessage = FormValidator.validateField(
        action.value.value,
        { required: true, email: true },
        action.value.t
      );
      draft.fields.email.isValid = !Boolean(draft.fields.email.errorMessage);

      // Check if Form has validation errors
      draft.hasValidationErrs = FormValidator.hasFormValidationErrors(
        draft,
        allInputs
      );
      return;

    case "passwordChange":
      draft.fields.password.value = action.value.value;
      draft.fields.password.errorMessage = FormValidator.validateField(
        action.value.value,
        { required: true },
        action.value.t
      );
      draft.fields.password.isValid = !Boolean(
        draft.fields.password.errorMessage
      );

      // Check if Form has validation errors
      draft.hasValidationErrs = FormValidator.hasFormValidationErrors(
        draft,
        allInputs
      );

      return;

    // Remember Me
    case "rememberMeChange":
      draft.fields.rememberMe.value = action.value;
      return;

    // End Remember ME

    case "resetForm":
      draft.hasValidationErrs = true;
      Object.keys(allInputs).forEach(function (item) {
        draft.fields[item].value = "";
      });
      return;

    case "formSubmitted":
      draft.hasValidationErrs = FormValidator.hasFormValidationErrors(
        draft,
        allInputs
      );
      if (!draft.hasValidationErrs) {
        draft.formSubmittedCount++;
      }
      return;
  }
}
