import React, { useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import DreamPage from "../../ui_components/DreamPage";
import StateContext from "../../Context/StateContext";
import DispatchContext from "../../Context/DispatchContext";
import { useImmerReducer } from "use-immer";
import Axios from "axios";
import CompanyProfileStateContex from "./Context/StateContext";
import CompanyProfileDispatchContex from "./Context/DispatchContext";

import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import DreamButton from "../../ui_components/DreamButton/DreamButton";
import Alert from "@material-ui/lab/Alert";
import * as ProfileHelper from "../UserProfilePage/ProfileHelper";
import * as ProfileReducer from "./CompanyProfilePageReducer";
import DreamSelectComponent from "../../ui_components/DreamSelect/DreamSelectComponent";
import CompanyLocationComponent from "../../containers_components/CompanyLocationComponent/CompanyLocationComponent";
import * as DreamSelectHelper from "../../ui_components/DreamSelect/DreamSelectHelper";
import ProfilePaper from "../../containers_components/ProfilePaperComponent/ProfilePaperComponent";
import * as Constants from "../../helpers/Constants";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  formRoot: {
    display: "flex",
    flexFlow: "wrap",
    justifyContent: "center",
    marginTop: 50,
  },
  companyNameInput: {
    margin: 0,
  },
  companyWebsiteInput: {
    margin: 0,
  },
}));

const CompanyProfilePage = () => {
  const appContext = useContext(StateContext);
  const appDispatch = useContext(DispatchContext);
  const [t] = useTranslation(Constants.TRANSLATION_COMMON);
  const history = useHistory();
  const userId = appContext.appState.user_id;
  const currentLang = appContext.appState.lang;

  const classes = useStyles();
  const [state, dispatch] = useImmerReducer(
    ProfileReducer.reducer,
    ProfileReducer.originalState
  );

  useEffect(() => {
    if (
      !appContext.appState.isCompanyProfileDataFilled &&
      history.location.state &&
      history.location.state.fromJOs
    ) {
      appDispatch({
        type: "showMsg",
        value: {
          status: Constants.SNACKBAR_INFO,
          msg: "profile.noCompanyProfile",
        },
      });
    }
  }, []);

  useEffect(() => {
    appDispatch({ type: "showBackdrop", value: true });
    const ourRequest = Axios.CancelToken.source();

    ProfileHelper.getProfileData(
      userId,
      Axios,
      ourRequest,
      appDispatch,
      dispatch,
      t
    );

    return () => {
      ourRequest.cancel();
    };
  }, []);

  /**
   * The Form has been submitted
   */
  useEffect(() => {
    if (state.formSubmittedCount) {
      appDispatch({ type: "showBackdrop", value: true });
      const ourRequest = Axios.CancelToken.source();

      ProfileHelper.updateProfile(
        userId,
        state,
        ProfileHelper.COMPANY_PROFILE_LOGO, // image type 1 (profile picture), 2 company logo
        Axios,
        ourRequest,
        appDispatch,
        dispatch,
        t
      );

      return () => {
        ourRequest.cancel();
      };
    }
  }, [state.formSubmittedCount]);

  /**
   * The Form has been successfully submitted (updated)
   */
  useEffect(() => {
    if (state.formSuccessfullySubmittedCount) {
      appDispatch({ type: "showBackdrop", value: true });
      const ourRequest = Axios.CancelToken.source();

      ProfileHelper.getProfileData(
        userId,
        Axios,
        ourRequest,
        appDispatch,
        dispatch,
        t
      );

      return () => {
        ourRequest.cancel();
      };
    }
  }, [state.formSuccessfullySubmittedCount]);

  function industryChanged(value) {
    dispatch({
      type: "setIndustryId",
      value,
    });
  }

  return (
    <CompanyProfileStateContex.Provider value={state}>
      <CompanyProfileDispatchContex.Provider value={dispatch}>
        <DreamPage title={t("profile.companyProfile")}>
          <form
            className={classes.formRoot}
            onSubmit={(e) => ProfileHelper.handleSubmit(e, dispatch)}
            noValidate
            autoComplete="off"
          >
            <ProfilePaper
              padding={40}
              maxWidth={480}
              height={560}
              actionUrl={"https://httpbin.org/post"}
              upDispatch={dispatch}
              maxFiles={1}
              accept={"image/*"}
              initialFiles={state.initialFileUploads}
              loadedDataBe={state.loadedDataBe}
              useDescription={false}
            >
              {/*Company name */}
              <TextField
                className={classes.companyNameInput}
                autoComplete="off"
                margin="dense"
                id="companyname"
                name="companyname"
                fullWidth
                size="small"
                label={t("profile.companyName")}
                InputProps={{
                  classes: {
                    input: classes.resize,
                  },
                }}
                value={state.fields.companyName.value}
                onChange={(e) =>
                  dispatch({
                    type: "companyNameChange",
                    value: { value: e.target.value, t },
                  })
                }
              />
              {state.fields.companyName.errorMessage && (
                <Alert severity="error">
                  {state.fields.companyName.errorMessage}
                </Alert>
              )}
              {/*Location */}
              <CompanyLocationComponent tooltipVisible={currentLang === "bg"} />
              {/*Industry */}
              <DreamSelectComponent
                fullWidth
                shrink
                displayEmpty
                dataType={DreamSelectHelper.DATA_TYPE_INDUSTRY}
                urlAPI={`dreamjob/industry`}
                label={"profile.industry"}
                inputProps={{ "aria-label": "Without label" }}
                dispatchChangeHandler={industryChanged}
                isMandatory={true}
                value={state.industryId}
              />
              {/*Company website */}
              <TextField
                className={classes.companyWebsiteInput}
                autoComplete="off"
                margin="dense"
                id="companyWebsite"
                name="companyWebsite"
                fullWidth
                size="small"
                label={t("profile.companyWebsite")}
                InputProps={{
                  classes: {
                    input: classes.resize,
                  },
                }}
                value={state.fields.companyWebsite.value}
                onChange={(e) =>
                  dispatch({
                    type: "companyWebsiteChange",
                    value: { value: e.target.value, t },
                  })
                }
              />
              {state.fields.companyWebsite.errorMessage && (
                <Alert severity="error">
                  {state.fields.companyWebsite.errorMessage}
                </Alert>
              )}
              <TextField
                className={classes.companyNameInput}
                id="description"
                name="Description"
                multiline
                rows={3}
                InputProps={{
                  classes: {
                    input: classes.resize,
                  },
                }}
                // variant="outlined"
                fullWidth
                autoComplete="off"
                margin="dense"
                size="small"
                label={t("profile.companyDescription")}
                value={
                  state.fields.companyDescription.value
                    ? state.fields.companyDescription.value
                    : ""
                }
                onChange={(e) =>
                  dispatch({
                    type: "companyDescriptionChange",
                    value: { value: e.target.value, t },
                  })
                }
              />
              {state.fields.companyDescription.errorMessage && (
                <Alert severity="error">
                  {state.fields.companyDescription.errorMessage}
                </Alert>
              )}
            </ProfilePaper>
            <DreamButton
              buttonType="submit"
              buttonDisabled={
                Boolean(state.hasValidationErrs) ||
                (state.uploadedFiles.uploadingFiles &&
                  Boolean(state.uploadedFiles.uploadingFiles.length))
              }
              color="primary"
              kind="contained"
              buttonLabel={"basic.save"}
            ></DreamButton>
          </form>
        </DreamPage>
      </CompanyProfileDispatchContex.Provider>
    </CompanyProfileStateContex.Provider>
  );
};

export default CompanyProfilePage;
