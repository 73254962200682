export function handleChange(id, isChecked, dispatch, changeHandler) {
  dispatch({
    type: "changeChecked",
    value: {
      id,
      isChecked,
    },
  });
  if (changeHandler) {
    changeHandler({ id, isChecked });
  }
}
