import React, { useEffect } from "react";
import { useImmerReducer } from "use-immer";
import IconButton from "@material-ui/core/IconButton";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import * as DreamDynamicGridHelper from "./DreamDynamicGridHelper";
import * as DreamDynamicGridReducer from "./DreamDynamicGridReducer";
import DeleteIcon from "@material-ui/icons/Delete";

import { AddRowButton } from "./DreamDynamicGridComponent.module.scss";

const useStyles = makeStyles({
  root: { width: "100% !important" },
  wrapper: {
    justifyContent: "center",
  },
  paperItem: {
    marginTop: 20,
    marginBottom: 10,

    maxWidth: "90%",
    display: "flex",
    flexDirection: "column",
    border: "1px solid rgba(0, 0, 0, 0.1)",
    boxShadow: "0px 5px 13px rgba(0, 0, 0, 0.25)",
    borderRadius: 15,
  },
  item: {},
  singleItem: {
    width: "85%",
    marginLeft: 20,
    border: "none",
    boxShadow: "none",
  },
  removeRowButton: {
    justifySelf: "flex-end",
  },
});

function DreamDynamicGridComponent(props) {
  const [state, dispatch] = useImmerReducer(
    DreamDynamicGridReducer.reducer,
    DreamDynamicGridReducer.originalState
  );

  const classes = useStyles();

  useEffect(() => {
    if (props.initialDataLoaded) {
      dispatch({
        type: "setRows",
        value: props.initialData,
      });
    }
  }, [props.initialDataLoaded, props.initialData]);

  return (
    <div className={classes.root}>
      <Grid container spacing={3} className={classes.wrapper}>
        {state.rows.map((item, index) => (
          <Grid
            key={item.id}
            item
            xs={12}
            className={classes.paperItem}
            style={props.style}
          >
            <Grid container justify={"flex-end"}>
              <IconButton
                className={classes.removeRowButton}
                onClick={() =>
                  DreamDynamicGridHelper.removeRow(item.id, dispatch)
                }
                aria-label="delete"
              >
                <DeleteIcon />
              </IconButton>
            </Grid>
            <Grid container>
              <Paper className={classes.singleItem}>{item.singleItem}</Paper>
            </Grid>
          </Grid>
        ))}
      </Grid>
      {(!props.maxItems || state.rows.length < props.maxItems) && (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <IconButton
              className={AddRowButton}
              onClick={(e) =>
                DreamDynamicGridHelper.addRow(props.singleItem, dispatch)
              }
              aria-label="add"
            >
              <AddCircleIcon />
            </IconButton>
          </Grid>
        </Grid>
      )}
    </div>
  );
}

export default DreamDynamicGridComponent;
