import React, { useContext } from "react";
import DreamCheckboxComponent from "../../ui_components/DreamCheckbox/DreamCheckboxComponent";
import JobOfferStateContext from "../../Pages/CreateJobOfferPage/Context/JobOfferStateContext";
import JobOfferDispatchContext from "../../Pages/CreateJobOfferPage/Context/JobOfferDispatchContext";

function AdditionalBenefitsComponentJo() {
  const jobOfferStateContext = useContext(JobOfferStateContext);
  const jobOfferDispatchContext = useContext(JobOfferDispatchContext);

  function checkboxChangeHandler(checkbox) {
    jobOfferDispatchContext({
      type: "benefitsChanged",
      value: { id: checkbox.id, isChecked: checkbox.isChecked },
    });
  }

  return (
    <DreamCheckboxComponent
      data={jobOfferStateContext.formData.benefits}
      changeHandler={checkboxChangeHandler}
    />
  );
}

export default AdditionalBenefitsComponentJo;
