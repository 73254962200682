export const originalState = {
  jobOffers: [],
  pagination: {
    currentPage: 0,
    currentPageSize: 10,
    hasMoreData: false,
    cursor: null,
    totalPageCount: 0,
    totalCount: 0,
    isLoading: false,
  },
  sorting: {
    byNameDesc: false,
    byIndustryDesc: false,
    byPositionDesc: false,
    bySalaryDesc: false,
  },
  lastDeletedId: null,
  isModalOpen: false,
  hasCompanyProfileDataFilled: false,
};

export function reducer(draft, action) {
  switch (action.type) {
    case "getJobOffers":
      draft.jobOffers = action.value;

      break;
    // Start Pagination  -----
    case "setPage":
      draft.pagination.currentPage = action.value;
      break;
    case "setRowsPerPage":
      draft.pagination.currentPageSize = action.value;
      break;
    case "setPaginationTotalCount":
      draft.pagination.totalCount = action.value;
      break;
    case "setPaginationTotalPageCount":
      draft.pagination.totalPageCount = action.value;
      break;
    case "setIsLoading":
      draft.pagination.isLoading = action.value;
      break;
    // End Pagination  -----

    case "confirmDelete":
      let deleteIndex = draft.jobOffers.findIndex(
        (x) => x.id == draft.lastDeletedId
      );
      draft.jobOffers.splice(deleteIndex, 1);
      break;
    case "setDeleteId":
      draft.lastDeletedId = action.value;
      break;
    case "setModalOpen":
      draft.isModalOpen = action.value;
      break;
    case "setCheckCreate":
      draft.hasCompanyProfileDataFilled = action.value;
      break;
  }
}
