import * as JoHelper from "./JobOfferViewPageHelper";
import * as EmploymentHelperJo from "../../containers_components/EmploymentComponentJo/EmploymentHelperJo";
import * as WorkplaceHelperJo from "../../containers_components/WorkplaceComponentJo/WorkplaceHelperJo";
import * as WorkScheduleHelperJo from "../../containers_components/WorkScheduleComponentJo/WorkScheduleHelperJo";
import * as PreferredStartHelperJo from "../../containers_components/PreferredStartComponentJo/PreferredStartHelperJo";
import * as AdditionalBenefitsHelperJo from "../../containers_components/AdditionalBenefitsComponentJo/AdditionalBenefitsHelperJo";

const workplaceTypes = WorkplaceHelperJo.workplaceRadioButtons;
const employmentTypes = EmploymentHelperJo.employmentRadioButtons;
const workScheduleTypes = WorkScheduleHelperJo.workScheduleRadioButtons;
const preferredStartTypes = PreferredStartHelperJo.preferredStartRadioButtons;
const additionalBenefits = AdditionalBenefitsHelperJo.additionalBenefits;

const filterOptions = [
  { id: "0", name: "basic.all" },
  { id: "1", name: "jov.locked" },
  { id: "2", name: "jov.unlocked" },
];

export const originalState = {
  jobOffer: [],
  jobOfferTitle: "",

  candidates: [],
  pagination: {
    page: 0,
    page_size: 10,
    hasMoreData: false,
    cursor: null,
    currentPage: 1,
    currentPageSize: 10, // ????? it was 2 -> now set to 10
    totalPageCount: 0,
    totalCount: 0,
  },
  filter: {
    selected: filterOptions.findIndex((x) => x.id === "0"),
    options: filterOptions,
  },
  sorting: {
    byNameDesc: false,
    byIndustryDesc: false,
    byPositionDesc: false,
    bySalaryDesc: false,
  },
  offerDataLoaded: false,
  candidatesDataLoaded: false,
};

export function reducer(draft, action) {
  switch (action.type) {
    case "getCandidates":
      draft.candidates = action.value;
      break;

    case "getJobOffer":
      draft.jobOffer = action.value;
      break;

    // Start Pagination  -----
    case "setPage":
      draft.pagination.page = action.value;
      break;
    case "setRowsPerPage":
      draft.pagination.page_size = action.value;
      break;
    case "setPaginationTotalPageCount":
      draft.pagination.totalPageCount = action.value;
      break;
    // End Pagination  -----

    case "offerDataLoaded":
      draft.offerDataLoaded = true;
      const offerData = action.value.data;
      const t = action.value.t;

      draft.jobOfferTitle = offerData.name;

      let formattedOfferData = [];

      formattedOfferData.push(JoHelper.formatLocations(offerData.locations));
      formattedOfferData.push(JoHelper.formatIndustryPosition(offerData, t));
      formattedOfferData.push(
        JoHelper.setTypeLabel(workplaceTypes, offerData.wp_type)
      );
      formattedOfferData.push(
        JoHelper.setTypeLabel(employmentTypes, offerData.e_type)
      );
      formattedOfferData.push(JoHelper.formatSalary(offerData));

      formattedOfferData.push(
        JoHelper.setTypeLabel(workScheduleTypes, offerData.work_schedule)
      );
      formattedOfferData.push(
        JoHelper.setTypeLabel(preferredStartTypes, offerData.start)
      );
      formattedOfferData.push(
        JoHelper.formatBenefits(additionalBenefits, offerData)
      );

      draft.jobOffer = formattedOfferData;
      break;

    case "candidatesDataLoaded":
      draft.candidatesDataLoaded = true;
      const candidatesData = action.value;

      let candidates = [];
      candidatesData.items.forEach((candidate) => {
        let cand = {
          education: candidate.education,
          experience: JoHelper.splitCandidateExperience(candidate.experience),
          id: candidate.id,
          jpID: candidate.jpID,
          jps: candidate.jps,
          industryName: candidate.industry_name,
          industryId: candidate.industry_id,
          positionName: candidate.position_name,
          positionId: candidate.position_id,
          salary: candidate.salary,
          education: candidate.education,
          location: candidate.location,
          locked: candidate.locked,
          name: candidate.name,
          picture: candidate.picture,
          promoted: candidate.promoted,
        };
        candidates.push(cand);
      });

      draft.candidates = candidates;
      draft.pagination.totalPageCount = candidatesData.total_page_count;
      draft.pagination.totalCount = candidatesData.total_count;
      break;
    case "setFilterSelected":
      draft.filter.selected = action.value;
      break;
  }
}
