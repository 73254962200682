import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Typography from "@material-ui/core/Typography";
import { red } from "@material-ui/core/colors";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import DreamMenu from "../DreamSettingsMenu/DreamSettingsMenu";
import Grid from "@material-ui/core/Grid";
import DreamButton from "../../ui_components/DreamButton/DreamButton";
import ReceiptIcon from "@material-ui/icons/Receipt";
import * as Constants from "../../helpers/Constants";

export default function RecipeReviewCard(props) {
  const [t] = useTranslation(Constants.TRANSLATION_COMMON);

  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      border: "1px solid rgba(0, 0, 0, 0.1)",
      boxShadow: "0px 5px 13px rgba(0, 0, 0, 0.25)",
      borderRadius: 15,
      overflow: "visible",
    },
    cardHeader: {
      textAlign: "left",
      paddingBottom: 8,
    },
    headerTitle: {
      display: "flex",
      flexDirection: "column",
      fontSize: 18,
      width: "80%",
    },
    subHeader: {
      fontSize: 18,
    },
    action: {
      position: "relative",
      zIndex: 1,
    },
    media: {
      height: 0,
    },
    cardActions: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      paddingLeft: 15,
      paddingRight: 30,
      paddingBottom: 16,
    },
    expand: {
      transform: "rotate(0deg)",
      marginLeft: "auto",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: "rotate(180deg)",
    },
    avatar: {
      backgroundColor: red[500],
    },
    iconLabelWrapper: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
    },
    iconWrapper: {
      paddingRight: 10,
    },
  }));

  const classes = useStyles();

  return (
    <Card className={classes.root}>
      {props.cardHeader ? (
        <CardHeader
          style={props.cardHeaderStyle}
          className={classes.cardHeader}
          classes={{
            title: classes.headerTitle,
            subheader: props.subHeaderStyle
              ? props.subHeaderStyle
              : classes.subHeader,
            action: props.cardHeaderAction
              ? props.cardHeaderAction
              : classes.action,
          }}
          avatar={props.avatar ? props.avatar : ""}
          action={
            props.headerAction ? (
              <DreamMenu
                label={<MoreVertIcon />}
                placement={"left-start"}
                menuItems={props.menuItems}
                menuStyle={props.menuStyle}
              />
            ) : props.buttonAction ? (
              <div style={props.buttonActionWrapperStyle}>
                {props.buttonAction}
              </div>
            ) : (
              ""
            )
          }
          title={props.cardHeaderTitle}
          subheader={props.subheader}
        />
      ) : (
        ""
      )}
      {props.cardContent ? (
        <CardContent style={props.cardContentStyle}>
          {props.cardContent.map((item, index) => (
            <Typography
              key={index}
              variant="body2"
              color="textSecondary"
              component="p"
              align={
                props.cardContentTextAlign ? props.cardContentTextAlign : "left"
              }
            >
              {item}
            </Typography>
          ))}
        </CardContent>
      ) : (
        ""
      )}
      {props.cardContentObject ? (
        <CardContent style={props.cardContentObjectStyle}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
            }}
          >
            {props.cardContentObject.map((item, index) => (
              <div key={index} style={{ display: "flex" }}>
                <Typography
                  style={{
                    fontWeight: 600,
                    color: "black",
                    marginRight: 5,
                  }}
                  key={index}
                  variant="body2"
                  color="textSecondary"
                  component="p"
                >
                  {item[0]}
                </Typography>

                <Typography variant="body2" color="textSecondary" component="p">
                  {item[1]}
                </Typography>
              </div>
            ))}
          </div>
        </CardContent>
      ) : (
        ""
      )}
      {props.cardContentCreditObject ? (
        <CardContent style={props.cardContentCreditStyle}>
          {props.cardContentCreditObject.map((item, index) => (
            <Grid key={index}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div className={classes.iconLabelWrapper}>
                  <div className={classes.iconWrapper}>
                    {item.typeIcon ? item.typeIcon : ""}
                  </div>
                  <Typography
                    style={{
                      fontWeight: 600,
                      color: "black",
                      marginRight: 5,
                    }}
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    {item.typeLabel}
                  </Typography>
                </div>

                <Typography variant="body2" color="textSecondary" component="p">
                  {item.type}
                </Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div className={classes.iconLabelWrapper}>
                  <div className={classes.iconWrapper}>
                    {item.nameIcon ? item.nameIcon : ""}
                  </div>
                  <Typography
                    style={{
                      fontWeight: 600,
                      color: "black",
                      marginRight: 5,
                    }}
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    {item.nameLabel}
                  </Typography>
                </div>

                <Typography variant="body2" color="textSecondary" component="p">
                  {item.name}
                </Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div className={classes.iconLabelWrapper}>
                  <div className={classes.iconWrapper}>
                    {item.positionIcon ? item.positionIcon : ""}
                  </div>
                  <Typography
                    style={{
                      fontWeight: 600,
                      color: "black",
                      marginRight: 5,
                    }}
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    {item.positionLabel}
                  </Typography>
                </div>
                <Typography variant="body2" color="textSecondary" component="p">
                  {item.position}
                </Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div className={classes.iconLabelWrapper}>
                  <div className={classes.iconWrapper}>
                    {item.dateIcon ? item.dateIcon : ""}
                  </div>
                  <Typography
                    style={{
                      fontWeight: 600,
                      color: "black",
                      marginRight: 5,
                    }}
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    {item.dateLabel}
                  </Typography>
                </div>

                <Typography variant="body2" color="textSecondary" component="p">
                  {item.date}
                </Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div className={classes.iconLabelWrapper}>
                  <div className={classes.iconWrapper}>
                    {item.creditsIcon ? item.creditsIcon : ""}
                  </div>
                  <Typography
                    style={{
                      fontWeight: 600,
                      color: "black",
                      marginRight: 5,
                    }}
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    {item.creditsLabel}
                  </Typography>
                </div>

                <Typography variant="body2" color="textSecondary" component="p">
                  {item.credits}
                </Typography>
              </div>
            </Grid>
          ))}
        </CardContent>
      ) : (
        ""
      )}
      {props.cardContentDebitObject ? (
        <CardContent style={props.cardContentDebitStyle}>
          {props.cardContentDebitObject.map((item, index) => (
            <Grid key={index}>
              <div
                style={{
                  display: "flex",

                  justifyContent: "space-between",
                }}
              >
                <div className={classes.iconLabelWrapper}>
                  <div className={classes.iconWrapper}>
                    {item.dateIcon ? item.dateIcon : ""}
                  </div>
                  <Typography
                    style={{
                      fontWeight: 600,
                      color: "black",
                      marginRight: 5,
                    }}
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    {item.dateLabel}
                  </Typography>
                </div>

                <Typography variant="body2" color="textSecondary" component="p">
                  {item.date}
                </Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div className={classes.iconLabelWrapper}>
                  <div className={classes.iconWrapper}>
                    {item.amountIcon ? item.amountIcon : ""}
                  </div>
                  <Typography
                    style={{
                      fontWeight: 600,
                      color: "black",
                      marginRight: 5,
                    }}
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    {item.amountLabel}
                  </Typography>
                </div>

                <Typography variant="body2" color="textSecondary" component="p">
                  {item.amount}
                </Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div className={classes.iconLabelWrapper}>
                  <div className={classes.iconWrapper}>
                    {item.creditsIcon ? item.creditsIcon : ""}
                  </div>
                  <Typography
                    style={{
                      fontWeight: 600,
                      color: "black",
                      marginRight: 5,
                    }}
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    {item.creditsLabel}
                  </Typography>
                </div>
                <Typography variant="body2" color="textSecondary" component="p">
                  {item.credits}
                </Typography>
              </div>
            </Grid>
          ))}
          {props.invNumber && (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div className={classes.iconLabelWrapper}>
                <div className={classes.iconWrapper}>
                  <ReceiptIcon />
                </div>
                <Typography
                  style={{
                    fontWeight: 600,
                    color: "black",
                    marginRight: 5,
                  }}
                  variant="body2"
                  color="textSecondary"
                  component="p"
                >
                  {t("wallet.invoice")}
                </Typography>
              </div>
              <DreamButton
                color={"primary"}
                kind={"contained"}
                buttonLabel={t("wallet.download")}
                handleClick={props.handleDownload}
              />
            </div>
          )}
        </CardContent>
      ) : (
        ""
      )}
      {props.cardActions ? (
        <CardActions
          className={classes.cardActions}
          style={props.cardActionsStyle}
          disableSpacing
          children={props.cardActionsChildren}
        ></CardActions>
      ) : (
        ""
      )}
    </Card>
  );
}
