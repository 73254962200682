export const originalState = {
  value: "",
  radioButtons: [],
};

export function reducer(draft, action) {
  switch (action.type) {
    case "setValue":
      draft.value = parseInt(action.value);
      return;
    case "gotRadioButtonsData":
      draft.radioButtons = action.value;
      return;
  }
}
