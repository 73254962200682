import React, { useContext, useEffect } from "react";
import { useImmerReducer } from "use-immer";
import DreamDynamicGridComponent from "../../ui_components/DreamDynamicGrid/DreamDynamicGridComponent";
import EducationSingleItemComponent from "./EducationSingleItemComponent/EducationSingleItemComponent";
import MyCvStateContext from "../../Pages/MyCvPage/Context/MyCvStateContext";
import * as EducationReducer from "./EducationReducer";
import JobPreferenceStateContext from "../../Pages/CreateJobPreferencePage/Context/JobPreferenceStateContext";
import JobPreferenceDispatchContext from "../../Pages/CreateJobPreferencePage/Context/JobPreferenceDispatchContext";

function EducationComponent(props) {
  const myCvContext = useContext(MyCvStateContext);
  const [state, dispatch] = useImmerReducer(
    EducationReducer.reducer,
    EducationReducer.originalState
  );

  const jobPreferenceStateContext = useContext(JobPreferenceStateContext);
  const jobPreferenceDispatchContext = useContext(JobPreferenceDispatchContext);

  const stateContext = props.stateContextName
    ? eval(props.stateContextName)
    : myCvContext;

  const initialDataLoaded =
    props.stateContextName === "jobPreferenceStateContext"
      ? stateContext.data.cv.initialDataLoaded
      : stateContext.initialDataLoaded;

  const levelIsLoaded =
    props.stateContextName === "jobPreferenceStateContext"
      ? stateContext.data.cv.selectsData.education.level.isLoaded
      : stateContext.selectsData.education.level.isLoaded;

  const broadTypeIsLoaded =
    props.stateContextName === "jobPreferenceStateContext"
      ? stateContext.data.cv.selectsData.education.broadType.isLoaded
      : stateContext.selectsData.education.broadType.isLoaded;

  const level =
    props.stateContextName === "jobPreferenceStateContext"
      ? stateContext.data.cv.selectsData.education.level
      : stateContext.selectsData.education.level;

  const broadType =
    props.stateContextName === "jobPreferenceStateContext"
      ? stateContext.data.cv.selectsData.education.broadType
      : stateContext.selectsData.education.broadType;

  const formData =
    props.stateContextName === "jobPreferenceStateContext"
      ? stateContext.formData.cv
      : stateContext.formData;

  useEffect(() => {
    if (initialDataLoaded && levelIsLoaded && broadTypeIsLoaded) {
      let formattedData = [];
      formData.education.forEach((education) => {
        formattedData.push({
          id: education.id,
          singleItem: (
            <EducationSingleItemComponent
              optionsData={{
                level,
                broadType,
              }}
              educationData={education}
              stateContextName={props.stateContextName}
              dispatchContextName={props.dispatchContextName}
            />
          ),
        });
      });
      dispatch({
        type: "setFormattedData",
        value: formattedData,
      });
    }
  }, [initialDataLoaded, levelIsLoaded, broadTypeIsLoaded]);

  return (
    <DreamDynamicGridComponent
      singleItem={
        <EducationSingleItemComponent
          optionsData={{
            level,
            broadType,
          }}
          stateContextName={props.stateContextName}
          dispatchContextName={props.dispatchContextName}
        />
      }
      initialDataLoaded={state.isDataFormatted}
      initialData={state.formattedData}
    />
  );
}

export default EducationComponent;
