import React, { useState, useContext, useEffect } from "react";
import DispatchContext from "../../Context/DispatchContext";
import StateContext from "../../Context/StateContext";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import { useTranslation } from "react-i18next";
import * as Constants from "../../helpers/Constants";
import DreamPage from "../../ui_components/DreamPage";
import TextField from "@material-ui/core/TextField";
import DreamButton from "../../ui_components/DreamButton/DreamButton";
import * as Colors from "../../styles/01.settings/01-settings-colors.module.scss";
import {
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  ViberIcon,
  VKIcon,
  WhatsappIcon,
  TelegramIcon,
  OKIcon,
  RedditIcon,
  TumblrIcon,
  MailruIcon,
  LineIcon,
  WeiboIcon,
} from "react-share";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappShareButton,
  TelegramShareButton,
  OKShareButton,
  RedditShareButton,
  TumblrShareButton,
  MailruShareButton,
  LineShareButton,
  WeiboShareButton,
} from "react-share";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(1),
      width: theme.spacing(16),
      height: theme.spacing(16),
    },
  },
  paper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: 450,
    width: "100%",
    height: 540,
    boxShadow: "0px 10px 10px rgba(0, 0, 0, 0.25)",
    borderRadius: 15,
  },
  wrapper: {
    paddingTop: 30,
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 20,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    maxWidth: 420,
    width: "100%",
    minHeight: 480,
    height: "100%",
  },
  stepper: {
    padding: 0,
  },
  inputField: {
    marginTop: 20,
    marginBottom: 20,
    height: 50,
  },
  copyButton: {
    marginTop: 10,
    marginBottom: 10,
    width: "10em",
    alignSelf: "center",
  },
  socialWrapper: {
    display: "flex",
    flexFlow: "row wrap",
    justifyContent: "space-between",
    width: 180,
    alignSelf: "center",
    marginTop: 10,
    marginBottom: 10,
  },
}));

const steps = [
  { label: "referal.step_01" },
  { label: "referal.step_02" },
  { label: "referal.step_03" },
];

const ReferalPage = () => {
  const classes = useStyles();
  const appDispatch = useContext(DispatchContext);
  const appContext = useContext(StateContext);

  const [t] = useTranslation(Constants.TRANSLATION_COMMON);
  const [state, setState] = useState({ url: "" });

  // const host = HOST_URL;
  const host = window.location.host;

  useEffect(() => {
    setState({
      url: `${host}?reghash=${appContext.appState.user_Reghash}`,
    });
  }, []);

  const returnMsg = () => {
    appDispatch({
      type: "showMsg",
      value: {
        status: Constants.SNACKBAR_SUCCESS,
        msg: t("referal.linkCopied"),
      },
    });
  };

  // return a promise
  function copyToClipboard(textToCopy) {
    // navigator clipboard api needs a secure context (https)
    if (navigator.clipboard && window.isSecureContext) {
      returnMsg(textToCopy);
      // navigator clipboard api method'
      return navigator.clipboard.writeText(textToCopy);
    } else {
      returnMsg(textToCopy);
      // text area method
      let textArea = document.createElement("textarea");
      textArea.value = textToCopy;
      // make the textarea out of viewport
      textArea.style.position = "fixed";
      textArea.style.left = "-999999px";
      textArea.style.top = "-999999px";
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      return new Promise((res, rej) => {
        // here the magic happens
        document.execCommand("copy") ? res() : rej();
        textArea.remove();
      });
    }
  }

  // used react-share
  return (
    <DreamPage title={t("referal.refer")}>
      <Paper elevation={3} className={classes.paper}>
        <div className={classes.wrapper}>
          <Stepper orientation="vertical" className={classes.stepper}>
            {steps.map((step) => (
              <Step key={step.label} active>
                <StepLabel>{t(step.label)}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <TextField
            classes={{ root: classes.inputField }}
            id="url"
            name="url"
            fullWidth
            variant="outlined"
            value={state.url}
          />
          <DreamButton
            className={classes.copyButton}
            kind="contained"
            buttonLabel={t("referal.copyLink")}
            color={"primary"}
            buttonLabelColor={Colors.white}
            handleClick={(e) => copyToClipboard(state.url)}
          />
          <div className={classes.socialWrapper}>
            <FacebookShareButton url={state.url}>
              <FacebookIcon size={40} round />
            </FacebookShareButton>
            <TwitterShareButton url={state.url}>
              <TwitterIcon size={40} round />
            </TwitterShareButton>
            <LinkedinShareButton url={state.url}>
              <LinkedinIcon size={40} round />
            </LinkedinShareButton>
            <WhatsappShareButton url={state.url}>
              <WhatsappIcon size={40} round />
            </WhatsappShareButton>
            <VKShareButton url={state.url}>
              <VKIcon size={40} round />
            </VKShareButton>
            <ViberShareButton url={state.url}>
              <ViberIcon size={40} round />
            </ViberShareButton>
            <EmailShareButton url={state.url}>
              <EmailIcon size={40} round />
            </EmailShareButton>
            <TelegramShareButton url={state.url}>
              <TelegramIcon size={40} round />
            </TelegramShareButton>
            <OKShareButton url={state.url}>
              <OKIcon size={40} round />
            </OKShareButton>
            <RedditShareButton url={state.url}>
              <RedditIcon size={40} round />
            </RedditShareButton>
            <TumblrShareButton url={state.url}>
              <TumblrIcon size={40} round />
            </TumblrShareButton>
            <MailruShareButton url={state.url}>
              <MailruIcon size={40} round />
            </MailruShareButton>
            <LineShareButton url={state.url}>
              <LineIcon size={40} round />
            </LineShareButton>
            <WeiboShareButton url={state.url}>
              <WeiboIcon size={40} round />
            </WeiboShareButton>
          </div>
        </div>
      </Paper>
    </DreamPage>
  );
};
export default ReferalPage;
