import * as JoHelper from "./JobOfferCandidateViewHelper";
import * as Constants from "../../helpers/Constants";
import * as EmploymentHelperJo from "../../containers_components/EmploymentComponentJo/EmploymentHelperJo";
import * as WorkplaceHelperJo from "../../containers_components/WorkplaceComponentJo/WorkplaceHelperJo";
import * as WorkScheduleHelperJo from "../../containers_components/WorkScheduleComponentJo/WorkScheduleHelperJo";
import * as PreferredStartHelperJo from "../../containers_components/PreferredStartComponentJo/PreferredStartHelperJo";
import * as AdditionalBenefitsHelperJo from "../../containers_components/AdditionalBenefitsComponentJo/AdditionalBenefitsHelperJo";
import * as EducationHelper from "../../containers_components/EducationComponent/EducationHelper";
import * as LanguagesHelper from "../../containers_components/LanguagesComponent/LanguagesHelper";
import * as CertificatesHelper from "../../containers_components/CertificatesComponent/CertificatesHelper";

const workplaceTypes = WorkplaceHelperJo.workplaceRadioButtons;
const employmentTypes = EmploymentHelperJo.employmentRadioButtons;
const workScheduleTypes = WorkScheduleHelperJo.workScheduleRadioButtons;
const preferredStartTypes = PreferredStartHelperJo.preferredStartRadioButtons;
const additionalBenefits = AdditionalBenefitsHelperJo.additionalBenefits;

export const originalState = {
  candidatePreferencesIds: [],
  candidatePreferences: [],
  preferencesDataLoaded: false,
  preferenceId: 0,
  candidateProfile: {
    pictureUrl: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
  },
  isModalOpen: false,
  isCandidateUnlocked: false,
  isCandidateNewlyUnlocked: false,
  expandedAccordion: "panel2",
  cv: {
    location: {},
    education: {
      education: [],
      educationNames: [],
    },
    languages: {
      languages: [],
      languagesNames: [],
    },
    experience: [],
    generalSkills: {
      generalSkills: [],
      generalSkillsNames: [],
    },
    additionalSkills: {
      additionalSkills: [],
      additionalSkillsNames: [],
    },
    hobbies: {
      hobbies: [],
      hobbiesNames: [],
    },
    initialFileUploads: [],
    cvDataLoaded: false,
  },
};

export function reducer(draft, action) {
  switch (action.type) {
    case "candidatePreferenceDataLoaded":
      const preferenceData = action.value.data;

      let formattedPreferenceData = [];

      formattedPreferenceData.push(
        JoHelper.formatLocations(preferenceData.locations)
      );
      formattedPreferenceData.push(preferenceData.industry);
      formattedPreferenceData.push({
        id: preferenceData.position,
        name: preferenceData.position_name,
      });
      formattedPreferenceData.push(
        JoHelper.setTypeLabel(workplaceTypes, preferenceData.wp_type)
      );
      formattedPreferenceData.push(
        JoHelper.setTypeLabel(employmentTypes, preferenceData.e_type)
      );
      formattedPreferenceData.push(JoHelper.formatSalaryData(preferenceData));

      formattedPreferenceData.push(
        JoHelper.setTypeLabel(workScheduleTypes, preferenceData.work_schedule)
      );
      formattedPreferenceData.push(
        JoHelper.setTypeLabel(preferredStartTypes, preferenceData.start)
      );
      formattedPreferenceData.push(
        JoHelper.formatBenefits(additionalBenefits, preferenceData)
      );

      if (draft.candidatePreferences.length == 0) {
        draft.candidatePreferences.push({
          preferenceId: preferenceData.id,
          candidateId: preferenceData.userID,
          cvId: preferenceData.cv,
          preference: formattedPreferenceData,
        });
      } else {
        draft.candidatePreferences[0] = {
          preferenceId: preferenceData.id,
          candidateId: preferenceData.userID,
          cvId: preferenceData.cv,
          preference: formattedPreferenceData,
        };
      }
      draft.preferenceId = preferenceData.id;
      draft.preferenceDataLoaded = true;
      break;

    case "profileDataLoaded":
      const profileData = action.value;
      draft.candidateProfile.pictureUrl = `${Constants.BASE_URL}upload/${profileData.picture}`;
      draft.candidateProfile.firstName =
        profileData.fname != "null" ? profileData.fname : "Unknown";
      draft.candidateProfile.lastName =
        profileData.lname != "null" ? profileData.lname : "Unknown";
      draft.candidateProfile.email = profileData.email;
      draft.candidateProfile.phone = profileData.phone;
      break;

    // Unlock user
    case "setModalOpen":
      draft.isModalOpen = action.value;
      break;
    case "setCandidateVisibility":
      draft.isCandidateUnlocked = action.value;
      break;

    // CV related actions
    case "cvDataLoaded":
      draft.cv.cvDataLoaded = true;
      const cvData = action.value;

      // Location
      let location = cvData.location ? cvData.location : {};
      if (location.name) {
        location.name = `${location.name}, ${location.state_name}, ${location.country_name}`;
      }
      draft.cv.location = location;

      // Education
      draft.cv.education.education = cvData.education
        ? EducationHelper.formatResponse(cvData.education)
        : [];
      draft.cv.education.educationNames = cvData.education_names
        ? formatEducatonNames(cvData.education_names)
        : [];
      // Languages
      draft.cv.languages = cvData.languages
        ? LanguagesHelper.formatResponse(cvData.languages)
        : [];
      draft.cv.languages.languagesNames = cvData.languages_names
        ? formatLanguagesNames(cvData.languages_names)
        : [];
      // Experience
      draft.cv.experience = cvData.experience
        ? formatExperiences(cvData.experience)
        : [];

      // General skills
      draft.cv.generalSkills.generalSkills = cvData.general_skills
        ? cvData.general_skills
        : [];
      draft.cv.generalSkills.generalSkillsNames = cvData.general_skills_names
        ? formatGeneralSkillsNames(cvData.general_skills_names)
        : [];

      // Additional skills
      draft.cv.additionalSkills.additionalSkills = cvData.skills
        ? cvData.skills
        : [];
      draft.cv.additionalSkills.additionalSkillsNames = cvData.skills_names
        ? formatAdditionalSkillsNames(cvData.skills_names)
        : [];
      // Hobbies
      draft.cv.hobbies.hobbies = cvData.hobbies ? cvData.hobbies : [];
      draft.cv.hobbies.hobbiesNames = cvData.hobbies_names
        ? formatHobbiesNames(cvData.hobbies_names)
        : [];

      // Certificates/Recognitions
      draft.cv.initialFileUploads = cvData.attachements
        ? CertificatesHelper.formatResponse(cvData.attachements, false)
        : [];

      return;

    case "getExpandedPanel":
      draft.expandedAccordion = action.value;
      return;
  }
}

const formatExperiences = (experiences) =>
  experiences.map((experience) => ({
    company: experience.company,
    from: experience.from,
    to: experience.to,
    industry: experience.industry,
    industry_name: `industries.${experience.industry}`,
    position: {
      id: experience.position.id,
      name: `positions.${experience.position.id}`,
    },
  }));

const formatEducatonNames = (education_names) =>
  education_names.map((education) => ({
    educationLevel: `educationLevel.${education.educationLevel}`,
    broadEducation: `educationBroad.${education.broadEducation}`,
    detailedEducation: `educationDetailed.${education.detailedEducation}`,
    major: education.major,
    from: education.from,
    to: education.to,
  }));

const formatLanguagesNames = (languages_names) =>
  languages_names.map((language_name) => ({
    language: `languages.${language_name.language}`,
    level: `languagesLevels.${language_name.level}`,
  }));

const formatGeneralSkillsNames = (generalSkillsNames) =>
  generalSkillsNames.map(
    (generalSkillName) => `generalSkills.${generalSkillName}`
  );

const formatAdditionalSkillsNames = (additionalSkillsNames) =>
  additionalSkillsNames.map(
    (additionalSkillName) => `additionalSkillsElements.${additionalSkillName}`
  );

const formatHobbiesNames = (hobbiesNames) =>
  hobbiesNames.map((hobbieName) => `hobbies.${hobbieName}`);
