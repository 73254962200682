import * as EducationHelper from "../../containers_components/EducationComponent/EducationHelper";
import * as LanguagesHelper from "../../containers_components/LanguagesComponent/LanguagesHelper";
import * as ExperienceHelper from "../../containers_components/ExperienceComponent/ExperienceHelper";
import * as CertificatesHelper from "../../containers_components/CertificatesComponent/CertificatesHelper";

export const originalState = {
  formData: {
    location: {},
    education: [],
    languages: [],
    experience: [],
    generalSkills: [],
    additionalSkills: [],
    hobbies: [],
    certificates: [],
  },
  disabledSaveButton: false,
  initialDataLoaded: false,
  generalSkillsGotSkills: false,
  generalSkillsInitiallyChecked: [],
  additionalSkillsGotSkills: false,
  additionalSkillsInitiallyChecked: [],
  hobbiesGotHobbies: false,
  hobbiesInitiallyChecked: [],
  initialFileUploads: [],
  formSuccessfullySubmittedCount: 0,
  selectsData: {
    education: {
      level: {
        data: {},
        isLoaded: false,
      },
      broadType: {
        data: {},
        isLoaded: false,
      },
    },
    languages: {
      lang: {
        data: {},
        isLoaded: false,
      },
      level: {
        extra_data: {},
        isLoaded: false,
      },
    },
  },
  jobPreferencesGotCount: false,
  jobPreferencesCount: 0,
};

export function reducer(draft, action) {
  let foundKey;

  switch (action.type) {
    case "setSelectsDataLanguagesLang":
      draft.selectsData.languages.lang.data = action.value.data;
      draft.selectsData.languages.lang.isLoaded = true;
      return;

    case "setSelectsDataLanguagesLevel":
      draft.selectsData.languages.level.extra_data = action.value.extra_data;
      draft.selectsData.languages.level.isLoaded = true;
      return;

    case "setSelectsDataEducationLevel":
      draft.selectsData.education.level.data = action.value.data;
      draft.selectsData.education.level.isLoaded = true;
      return;

    case "setSelectsDataEducationBroadType":
      draft.selectsData.education.broadType.data = action.value.data;
      draft.selectsData.education.broadType.isLoaded = true;
      return;

    case "educationAdded":
      draft.formData.education.push({
        id: action.value,
      });
      return;

    case "educationRemoved":
      foundKey = draft.formData.education.findIndex(
        (x) => x.id == action.value
      );
      if (foundKey >= 0) {
        draft.formData.education.splice(foundKey, 1);
      }
      return;

    case "educationLevelChanged":
      foundKey = draft.formData.education.findIndex(
        (x) => x.id == action.value.educationID
      );
      // Change the education level
      if (foundKey >= 0) {
        draft.formData.education[foundKey].educationLevel =
          action.value.educationLevel;
      }
      return;

    case "educationBroadTypeChanged":
      foundKey = draft.formData.education.findIndex(
        (x) => x.id == action.value.educationID
      );
      // Change the Broad education type
      if (foundKey >= 0) {
        draft.formData.education[foundKey].broadEducation =
          action.value.broadEducationType;
      }
      return;

    case "educationDetailedTypeChanged":
      foundKey = draft.formData.education.findIndex(
        (x) => x.id == action.value.educationID
      );
      // Change the detailed education type
      if (foundKey >= 0) {
        draft.formData.education[foundKey].detailedEducation =
          action.value.detailedEducationType;
      }
      return;

    case "educationMajorChanged":
      foundKey = draft.formData.education.findIndex(
        (x) => x.id == action.value.educationID
      );
      // Change the major
      if (foundKey >= 0) {
        draft.formData.education[foundKey].major = action.value.major;
      }
      return;

    case "educationFromChanged":
      foundKey = draft.formData.education.findIndex(
        (x) => x.id == action.value.educationID
      );
      // Change the From
      if (foundKey >= 0) {
        draft.formData.education[foundKey].from = action.value.from;
      }
      return;

    case "educationToChanged":
      foundKey = draft.formData.education.findIndex(
        (x) => x.id == action.value.educationID
      );
      // Change the To
      if (foundKey >= 0) {
        draft.formData.education[foundKey].to = action.value.to;
      }
      return;

    case "educationChangePresentEducation":
      foundKey = draft.formData.education.findIndex(
        (x) => x.id == action.value.educationID
      );
      // Change the Education presentEducation
      if (foundKey >= 0) {
        draft.formData.education[foundKey].presentEducation =
          action.value.presentEducation;
        if (action.value.presentEducation) {
          draft.formData.education[foundKey].to = null;
        }
      }
      return;

    case "experienceAdded":
      draft.formData.experience.push({
        id: action.value,
      });
      return;

    case "experienceRemoved":
      foundKey = draft.formData.experience.findIndex(
        (x) => x.id == action.value
      );
      if (foundKey >= 0) {
        draft.formData.experience.splice(foundKey, 1);
      }
      return;

    case "experienceIndustryChanged":
      foundKey = draft.formData.experience.findIndex(
        (x) => x.id == action.value.experienceID
      );
      // Change the Experience Industry
      if (foundKey >= 0) {
        draft.formData.experience[foundKey].industry = action.value.industry;
      }
      return;

    case "experiencePositionChanged":
      foundKey = draft.formData.experience.findIndex(
        (x) => x.id == action.value.experienceID
      );

      // Change the Experience Position
      if (foundKey >= 0) {
        draft.formData.experience[foundKey].position = action.value.position;
      }
      return;

    case "experienceFromChanged":
      foundKey = draft.formData.experience.findIndex(
        (x) => x.id == action.value.experienceID
      );
      // Change the Experience From
      if (foundKey >= 0) {
        draft.formData.experience[foundKey].from = action.value.from;
      }
      return;

    case "experienceToChanged":
      foundKey = draft.formData.experience.findIndex(
        (x) => x.id == action.value.experienceID
      );
      // Change the Experience To
      if (foundKey >= 0) {
        draft.formData.experience[foundKey].to = action.value.to;
      }
      return;

    case "experienceCompanyChanged":
      foundKey = draft.formData.experience.findIndex(
        (x) => x.id == action.value.experienceID
      );
      // Change the Experience Company
      if (foundKey >= 0) {
        draft.formData.experience[foundKey].company = action.value.company;
      }
      return;

    case "experienceChangePresentJob":
      foundKey = draft.formData.experience.findIndex(
        (x) => x.id == action.value.experienceID
      );
      // Change the Experience presentJob
      if (foundKey >= 0) {
        draft.formData.experience[foundKey].presentJob =
          action.value.presentJob;
        if (action.value.presentJob) {
          draft.formData.experience[foundKey].to = null;
        }
      }
      return;

    case "languageAdded":
      draft.formData.languages.push({
        id: action.value,
      });
      return;

    case "languageRemoved":
      foundKey = draft.formData.languages.findIndex(
        (x) => x.id == action.value
      );
      if (foundKey >= 0) {
        draft.formData.languages.splice(foundKey, 1);
      }
      return;

    case "languagesLanguageChanged":
      let language = action.value.language;
      foundKey = draft.formData.languages.findIndex(
        (x) => x.id == action.value.languageID
      );
      // Change the Languages Language
      if (foundKey >= 0) {
        draft.formData.languages[foundKey].language = language;
      }
      return;

    case "languagesLevelChanged":
      foundKey = draft.formData.languages.findIndex(
        (x) => x.id == action.value.languageID
      );
      // Change the Languages Language
      if (foundKey >= 0) {
        draft.formData.languages[foundKey].level = action.value.level;
      }
      return;

    case "generalSkillsSkillChanged":
      foundKey = draft.formData.generalSkills.findIndex(
        (x) => x.id == action.value.id
      );
      // Change the Skill checked / not checked
      if (foundKey >= 0) {
        draft.formData.generalSkills[foundKey].isChecked =
          action.value.isChecked;
      }
      return;

    case "generalSkillsGotSkills":
      draft.formData.generalSkills = action.value;
      draft.generalSkillsGotSkills = true;
      return;

    case "preselectGeneralSkills":
      draft.generalSkillsInitiallyChecked.forEach((id) => {
        foundKey = draft.formData.generalSkills.findIndex((x) => x.id == id);
        // Make a General Skill checked
        if (foundKey >= 0) {
          draft.formData.generalSkills[foundKey].isChecked = true;
        }
      });
      return;

    case "preselectAdditionalSkills":
      for (const industry in draft.formData.additionalSkills) {
        draft.formData.additionalSkills[industry].forEach((element, index) => {
          draft.additionalSkillsInitiallyChecked.forEach((id) => {
            if (id == element.id) {
              draft.formData.additionalSkills[industry][index].isChecked = true;
            }
          });
        });
      }
      return;

    case "additionalSkillsGotSkills":
      draft.formData.additionalSkills = action.value;
      draft.additionalSkillsGotSkills = true;
      return;

    case "additionalSkillsSkillChanged":
      for (const industry in draft.formData.additionalSkills) {
        draft.formData.additionalSkills[industry].forEach((element, index) => {
          if (element.id == parseInt(action.value)) {
            draft.formData.additionalSkills[industry][index].isChecked =
              !draft.formData.additionalSkills[industry][index].isChecked;
          }
        });
      }
      return;

    case "locationLocationChanged":
      draft.formData.location = action.value;
      return;

    case "hobbiesGotHobbies":
      draft.formData.hobbies = action.value;
      draft.hobbiesGotHobbies = true;
      return;

    case "preselectHobbies":
      draft.hobbiesInitiallyChecked.forEach((id) => {
        foundKey = draft.formData.hobbies.findIndex((x) => x.id == id);
        // Make a Hobby checked
        if (foundKey >= 0) {
          draft.formData.hobbies[foundKey].isChecked = true;
        }
      });
      return;

    case "hobbiesChanged":
      foundKey = draft.formData.hobbies.findIndex(
        (x) => x.id == action.value.id
      );
      // Change the Hobby checked / not checked
      if (foundKey >= 0) {
        draft.formData.hobbies[foundKey].isChecked = action.value.isChecked;
      }
      return;

    case "certificatesCertificatesChanged":
      draft.formData.certificates = action.value;
      return;

    case "disableSaveButton":
      draft.disabledSaveButton = action.value;
      return;

    case "cvDataLoaded":
      draft.initialDataLoaded = true;
      const cvData = action.value;

      draft.generalSkillsInitiallyChecked = cvData.general_skills
        ? cvData.general_skills
        : [];
      draft.additionalSkillsInitiallyChecked = cvData.skills
        ? cvData.skills
        : [];
      draft.hobbiesInitiallyChecked = cvData.hobbies ? cvData.hobbies : [];

      let location = cvData.location ? cvData.location : {};
      if (location.name) {
        location.name = `${location.name}, ${location.state_name}, ${location.country_name}`;
      }
      draft.formData.location = location;

      draft.formData.languages = cvData.languages
        ? LanguagesHelper.formatResponse(cvData.languages)
        : [];

      draft.formData.education = cvData.education
        ? EducationHelper.formatResponse(cvData.education)
        : [];
      draft.formData.experience = cvData.experience
        ? ExperienceHelper.formatResponse(cvData.experience)
        : [];

      draft.initialFileUploads = cvData.attachements
        ? CertificatesHelper.formatResponse(cvData.attachements, false)
        : [];

      return;

    case "getJobPreferences":
      draft.jobPreferencesCount = action.value.length;
      draft.jobPreferencesGotCount = true;
      return;

    case "formSuccessfullySubmitted":
      draft.initialFileUploads = [];
      draft.formData.certificates = [];
      draft.initialDataLoaded = false;
      draft.formSuccessfullySubmittedCount++;
      return;
  }
}
