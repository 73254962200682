import React, { useEffect, useContext, useRef } from "react";
import { useImmerReducer } from "use-immer";
import uuid from "react-uuid";

import DreamSelectComponent from "../../../ui_components/DreamSelect/DreamSelectComponent";
import * as DreamSelectHelper from "../../../ui_components/DreamSelect/DreamSelectHelper";
import StateContext from "../../../Context/StateContext";

import MyCvDispatchContext from "../../../Pages/MyCvPage/Context/MyCvDispatchContext";
import * as ExperienceSingleItemReducer from "./ExperienceSingleItemReducer";
import DreamDatepickerComponent from "../../../ui_components/DreamDatepicker/DreamDatepickerComponent";
import * as Constants from "../../../helpers/Constants";
import DreamAutocomplete from "../../../ui_components/DreamAutocomplete/DreamAutocomplete";
import * as DreamAutocompleteHelper from "../../../ui_components/DreamAutocomplete/DreamAutocompleteHelper";
import { ExperienceRoot } from "./ExperinceSingleItemComponent.module.scss";
import DreamCustomCheckbox from "../../../ui_components/DreamCustomCheckboxComponent/DreamCustomCheckboxComponent";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import * as ExperienceSingleItemHelper from "./ExperienceSingleItemHelper";
import { useTranslation } from "react-i18next";
import DreamTextfieldComponent from "../../../ui_components/DreamTextfield/DreamTextfieldComponent";
import Grid from "@material-ui/core/Grid";

import JobPreferenceDispatchContext from "../../../Pages/CreateJobPreferencePage/Context/JobPreferenceDispatchContext";

function ExperinceSingleItemComponent(props) {
  const appContext = useContext(StateContext);
  const myCvDispatch = useContext(MyCvDispatchContext);
  const jobPreferenceDispatchContext = useContext(JobPreferenceDispatchContext);

  const dispatchContext =
    props.dispatchContextName === "jobPreferenceDispatchContext"
      ? jobPreferenceDispatchContext /*eval(props.dispatchContextName)*/
      : myCvDispatch;

  const rel = useRef();

  const currentLang = appContext.appState.lang;
  const [t] = useTranslation(Constants.TRANSLATION_COMMON);

  function getAdditionalParams() {
    let additionalParams = {};
    additionalParams.lang = appContext.appState.lang;
    if (state.industryId) {
      additionalParams.industry = state.industryId;
    }

    return additionalParams;
  }

  const [state, dispatch] = useImmerReducer(
    ExperienceSingleItemReducer.reducer,
    ExperienceSingleItemReducer.originalState
  );

  function industryChanged(value) {
    dispatchContext({
      type: "experienceIndustryChanged",
      value: {
        experienceID: state.id,
        industry: value,
      },
    });
    dispatch({
      type: "setIndustryId",
      value,
    });
    dispatch({
      type: "setIndustryChangedCounter",
    });
  }

  function positionChanged(value) {
    dispatchContext({
      type: "experiencePositionChanged",
      value: {
        experienceID: state.id,
        position: value,
      },
    });
    dispatch({
      type: "setPosition",
      value,
    });
  }

  function fromChanged(value) {
    dispatchContext({
      type: "experienceFromChanged",
      value: {
        experienceID: state.id,
        from: value,
      },
    });
  }

  function toChanged(value) {
    dispatchContext({
      type: "experienceToChanged",
      value: {
        experienceID: state.id,
        to: value,
      },
    });
  }

  function companyChanged(value) {
    dispatchContext({
      type: "experienceCompanyChanged",
      value: {
        experienceID: state.id,
        company: value,
      },
    });
  }

  function experienceManualyChanged(value) {
    dispatchContext({
      type: "cvExperienceManualyChanged",
    });
  }

  useEffect(() => {
    let myUuid;
    if (props.experienceData && props.experienceData.id != null) {
      // In this case, Experience is loaded from the DB,
      // so we don't need to generate ID and trigger an event for creation
      myUuid = props.experienceData.id;

      dispatch({
        type: "setIndustryId",
        value: props.experienceData.industry,
      });
      dispatch({
        type: "setPosition",
        value: props.experienceData.position,
      });
      dispatch({
        type: "setFrom",
        value: props.experienceData.from,
      });
      dispatch({
        type: "setTo",
        value: props.experienceData.to,
      });
      dispatch({
        type: "changePresentJob",
        value: props.experienceData.presentJob,
      });
      dispatch({
        type: "setCompany",
        value: props.experienceData.company,
      });
    } else {
      myUuid = uuid();
      dispatchContext({
        type: "experienceAdded",
        value: myUuid,
      });
    }

    rel.current = myUuid;

    dispatch({
      type: "setId",
      value: myUuid,
    });

    return () => {
      // on Unmount let's dispatch event
      dispatchContext({
        type: "experienceRemoved",
        value: rel.current,
      });
    };
  }, []);

  useEffect(() => {
    if (state.industryChangedCounter > 1) {
      dispatchContext({
        type: "experiencePositionChanged",
        value: {
          experienceID: state.id,
          position: { id: "", name: "" },
        },
      });
      dispatch({
        type: "setPosition",
        value: { id: "", name: "" },
      });
    }
  }, [state.industryChangedCounter]);

  return (
    <div className={ExperienceRoot}>
      <DreamSelectComponent
        fullWidth
        shrink
        displayEmpty
        dataType={DreamSelectHelper.DATA_TYPE_INDUSTRY}
        urlAPI={`dreamjob/industry`}
        label={"profile.industry"}
        dispatchChangeHandler={industryChanged}
        isMandatory={true}
        value={state.industryId}
        manualyChangedHandler={experienceManualyChanged}
      />
      <DreamAutocomplete
        fullWidth
        acDisabled={!state.industryId}
        label={"cv.position"}
        searchType={DreamAutocompleteHelper.SEARCH_TYPE_POSITION}
        apiUrl={`${Constants.BASE_URL}dreamjob/position`}
        searchParamName={"search_string"}
        changeHandler={positionChanged}
        lang={currentLang}
        additionalApiParams={getAdditionalParams()}
        isMandatory={true}
        preselectedValue={state.position}
      />
      <DreamTextfieldComponent
        label={"cv.company"}
        fullWidth
        dispatchChangeHandler={companyChanged}
        isMandatory={false}
        value={state.company}
      />
      <Grid container justify="space-between">
        <DreamDatepickerComponent
          label={"basic.from"}
          dispatchChangeHandler={fromChanged}
          disableFuture
          isMandatory={true}
          value={state.from}
        />

        {!state.presentJob && (
          <DreamDatepickerComponent
            label={"basic.to"}
            dispatchChangeHandler={toChanged}
            disableFuture
            isMandatory={true}
            value={state.to}
          />
        )}
      </Grid>
      <FormGroup row>
        <FormControlLabel
          control={
            <DreamCustomCheckbox
              checked={state.presentJob}
              onChange={(e) =>
                ExperienceSingleItemHelper.handleChange(
                  e.target.checked,
                  state.id,
                  dispatch,
                  dispatchContext
                )
              }
              name={"presentJob"}
            />
          }
          label={t("cv.presentJob")}
        />
      </FormGroup>
    </div>
  );
}

export default ExperinceSingleItemComponent;
