import * as Constants from "../../helpers/Constants";

export const currencyOptions = { USD: "USD", EUR: "EUR" };

export const quantityOptions = [1];

export function formatPackagesButtons(selectedCurrency, packages, t) {
  let buttons = [];
  packages.forEach((pack) => {
    if (selectedCurrency === pack.id) {
      pack.package.forEach((innerPack) => {
        buttons.push({
          label: `${innerPack.item.amount} ${t("bc.credits")} ${
            innerPack.item.price / 100
          } ${pack.id}`,
          value: parseInt(innerPack.id),
          price: innerPack.item.price / 100,
        });
      });
    }
  });
  return buttons;
}

export function calculateSum(quantity, selectedPackage) {
  return quantity * selectedPackage;
}

export function validateForm(data, appDispatch) {
  let errors = [];

  if (!isValidCurrency(data.currency)) {
    errors.push("bc.invalidCurrency");
  }

  if (!isValidQuantity(data.quantity)) {
    errors.push("bc.invalidQuantity");
  }

  if (Boolean(errors.length)) {
    appDispatch({
      type: "showMsg",
      value: { status: Constants.SNACKBAR_ERROR, msg: errors },
    });
    return false;
  }

  return true;
}

export function isValidCurrency(currency) {
  if (currency === "") {
    return false;
  }
  return true;
}

export function isValidQuantity(quantity) {
  if (quantity === "") {
    return false;
  }
  return true;
}

// Packages
export async function getPackages(Axios, ourRequest, appDispatch, dispatch, t) {
  appDispatch({ type: "showBackdrop", value: true });

  let errMsgs;
  let authenticated = true;
  try {
    const response = await Axios.get(`/transactions/packages`, {
      headers: {
        "content-type": `application/json;`,
      },
      cancelToken: ourRequest.token,
    }).catch(function (error) {
      // Errors to be sent to snackbar
      errMsgs = t("basic.serverError");
      if (
        error.response &&
        error.response.status &&
        error.response.status === Constants.ERROR_RESPONSE_STATUS
      ) {
        authenticated = false;
        errMsgs = t("basic.unauthenticated");
      }
      throw errMsgs;
    });

    const responseData = response.data.data;

    handleSuccessfulGetPackagesData(responseData, appDispatch, dispatch, t);
  } catch (error) {
    appDispatch({ type: "showBackdrop", value: false });
    appDispatch({
      type: "showMsg",
      value: { status: Constants.SNACKBAR_ERROR, msg: error },
    });
    if (!authenticated) {
      appDispatch({
        type: "logout",
      });
      appDispatch({ type: "openLoginModal", value: true });
    } else {
      dispatch({ type: "disableSubmitButton", value: false });
    }
  }
}

export function handleSuccessfulGetPackagesData(
  data,
  appDispatch,
  dispatch,
  t
) {
  appDispatch({ type: "showBackdrop", value: false });
  dispatch({ type: "packagesDataLoaded", value: { data: data, t: t } });
  dispatch({ type: "packagesDataSuccessfullyLoaded" });
}

// User Balance
export async function getBalance(Axios, ourRequest, appDispatch, dispatch) {
  appDispatch({ type: "showBackdrop", value: true });

  let errMsgs;
  let authenticated = true;
  try {
    const response = await Axios.get(`/user/balance`, {
      headers: {
        "content-type": `application/json;`,
      },
      cancelToken: ourRequest.token,
    }).catch(function (error) {
      // Errors to be sent to snackbar
      errMsgs = "basic.serverError";
      if (
        error.response &&
        error.response.status &&
        error.response.status === Constants.ERROR_RESPONSE_STATUS
      ) {
        authenticated = false;
        errMsgs = "basic.unauthenticated";
      }
      throw errMsgs;
    });

    const responseData = response.data.data;

    handleSuccessfulGetBalanceData(responseData, appDispatch, dispatch);
  } catch (error) {
    appDispatch({ type: "showBackdrop", value: false });
    appDispatch({
      type: "showMsg",
      value: { status: Constants.SNACKBAR_ERROR, msg: error },
    });
    if (!authenticated) {
      appDispatch({
        type: "logout",
      });
      appDispatch({ type: "openLoginModal", value: true });
    } else {
      dispatch({ type: "disableSubmitButton", value: false });
    }
  }
}

export function handleSuccessfulGetBalanceData(data, appDispatch, dispatch) {
  appDispatch({ type: "showBackdrop", value: false });
  dispatch({
    type: "balanceDataLoaded",
    value: data.success ? data.balance : null,
  });
}
