import React from "react";
import DreamButton from "../../ui_components/DreamButton/DreamButton";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import * as Constants from "../../helpers/Constants";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: 10,
    borderRadius: 15,
  },
}));

const DreamConfirmModal = (props) => {
  const [t] = useTranslation(Constants.TRANSLATION_COMMON);
  const classes = useStyles();
  return (
    <Dialog
      classes={{ paper: classes.paper }}
      open={props.isOpen}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{t(props.title)}</DialogTitle>
      <DialogContent>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            fontSize: 14,
          }}
          id="alert-dialog-description"
        >
          <span style={{ fontSize: 14 }}>{t(props.firstRow)}</span>
          <span style={{ fontSize: 14 }}>{t(props.secondRow)}</span>
          <span style={{ fontSize: 14 }}>{t(props.thirdRow)}</span>
        </div>
      </DialogContent>
      <div style={{ height: props.delimeterHeight }} />
      <DialogActions>
        <DreamButton
          dreamButtonStyle={props.buttonCancelStyle}
          handleClick={props.handleCancel}
          color={props.buttonCancelColor ? props.buttonCancelColor : "primary"}
          kind={props.buttonCancelKind ? props.buttonCancelKind : "text"}
          buttonLabel={props.buttonCancelLabel}
        ></DreamButton>
        <DreamButton
          dreamButtonStyle={props.buttonConfirmStyle}
          handleClick={props.handleConfirm}
          color={
            props.buttonConfirmColor ? props.buttonConfirmColor : "primary"
          }
          kind={props.buttonConfirmKind ? props.buttonConfirmKind : "text"}
          buttonLabel={props.buttonConfirmLabel}
          autoFocus
        ></DreamButton>
      </DialogActions>
    </Dialog>
  );
};

export default DreamConfirmModal;
