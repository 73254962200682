import * as Constants from "../../../helpers/Constants";

export function handleDelete(
  data,
  dispatchFileUpload,
  Axios,
  appDispatch,
  deleteType
) {
  let deletedFile;
  if (data.isInitialUpload) {
    deletedFile = deleteFileRequest(data.id, Axios, deleteType);
    if (!deletedFile) {
      appDispatch({
        type: "showMsg",
        value: {
          status: Constants.SNACKBAR_ERROR,
          msg: "basic.notDeletedFile",
        },
      });
    }
  }

  if (!data.isInitialUpload || deletedFile || data.skipDbDeletion) {
    dispatchFileUpload({
      type: "fileDeleted",
      value: data.id,
    });
  }
}

export async function deleteFileRequest(fileId, Axios, deleteType) {
  let success = true;
  try {
    const response = await Axios.delete(`/upload/${fileId}`).catch(function (
      error
    ) {
      // Errors to be sent to scanckbar
      let errMsgs = "Server error";
      if (error.response) {
        // Request made and server responded
        if (error.response.data.message) {
          errMsgs = JSON.parse(error.response.data.message);
        }
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
      throw errMsgs;
    });
  } catch (error) {
    console.log("Error delete file:", error);
    success = false;
  }
  if (deleteType === "avatar") {
    localStorage.setItem(
      `${Constants.LOCAL_STORAGE_SITE_NAME}UserAvatarId`,
      null
    );
  }

  return success;
}
