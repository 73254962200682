import FormValidator from "../../helpers/FormValidator";

const allInputs = {
  email: {
    value: "",
    isValid: true,
    errorMessage: "",
  },
};

export const originalState = {
  fields: { ...allInputs },
  hasValidationErrs: true,
  successfullySentCounter: 0,
};

export function reducer(draft, action) {
  switch (action.type) {
    case "emailChange":
      draft.fields.email.value = action.value.value;
      draft.fields.email.errorMessage = FormValidator.validateField(
        action.value.value,
        { required: true, email: true },
        action.value.t
      );
      draft.fields.email.isValid = !Boolean(draft.fields.email.errorMessage);

      // Check if Form has validation errors
      draft.hasValidationErrs = FormValidator.hasFormValidationErrors(
        draft,
        allInputs
      );
      return;
    case "setSuccessfullySentCounter":
      draft.successfullySentCounter++;
      return;
  }
}
