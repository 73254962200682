import _ from "lodash/fp";
import * as Constants from "./Constants";

export function getErrorKey(inputName, errors, maxLength = 30, minLength = 3) {
  const errType = _.get(`${inputName}.type`, errors);

  let errorKey;
  let param;
  switch (errType) {
    case "required":
      errorKey = "validationError.requiredField";
      break;
    case "maxLength":
      errorKey = "validationError.maxLength";
      param = maxLength;
      break;
    case "minLength":
      errorKey = "validationError.minLength";
      param = minLength;
      break;
    default:
      return null;
      break;
  }

  return { errorKey, param: { param } };
}

export function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

export function getDateFromDateObject(dateObject, delimiter = "-") {
  return `${dateObject.getFullYear()}${delimiter}${
    dateObject.getMonth() + 1
  }${delimiter}${dateObject.getDate()}`;
}

export const supportedLanguages = ["en", "en-US", "bg"];

export const locationTooltipBG =
  "Р.България, всички области и градове в Р.България могат да се изписват на Българска кирилица и латиница. Всички държави с изключение на България, всички области и градове извън територията на България могат да се изписват само на латиница!";
