export const additionalBenefitsCheckboxes = [
  {
    id: "paid_leave",
    label: "jp.additionalBenefits.additionalPaidLeave",
    isChecked: false,
  },
  {
    id: "food_vouchers",
    label: "jp.additionalBenefits.foodVouchers",
    isChecked: false,
  },
  {
    id: "housing",
    label: "jp.additionalBenefits.housing",
    isChecked: false,
  },
  {
    id: "car",
    label: "jp.additionalBenefits.car",
    isChecked: false,
  },
  {
    id: "phone",
    label: "jp.additionalBenefits.phone",
    isChecked: false,
  },
];
