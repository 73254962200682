import * as Constants from "../../Constants";

/**
 * Get the profile data
 * @param {IterationCompositeOperation} userId
 * @param {Axios} Axios
 * @param {Axios} ourRequest
 * @param {appDispatch} appDispatch
 * @param {dispatch} dispatch
 * @param {t} t
 */
export async function getProfileData(
  profileId,
  Axios,
  ourRequest,
  appDispatch,
  dispatch,
  t
) {
  dispatch({ type: "disableSubmitButton", value: true });
  let errMsgs;
  let authenticated = true;
  try {
    const response = await Axios.get(`/user/${profileId}`, {
      cancelToken: ourRequest.token,
    }).catch(function (error) {
      // Errors to be sent to scanckbar
      errMsgs = "basic.serverError";
      if (
        error.response &&
        error.response.status &&
        error.response.status === Constants.ERROR_RESPONSE_STATUS
      ) {
        authenticated = false;
        errMsgs = "basic.unauthenticated";
      }
      throw errMsgs;
    });

    const responseData = response.data.data;
    dispatch({ type: "disableSubmitButton", value: false });
    handleSuccessfulGetData(responseData, appDispatch, dispatch);
  } catch (error) {
    appDispatch({ type: "showBackdrop", value: false });
    appDispatch({
      type: "showMsg",
      value: { status: Constants.SNACKBAR_ERROR, msg: error },
    });
    if (!authenticated) {
      appDispatch({
        type: "logout",
      });
      appDispatch({ type: "openLoginModal", value: true });
    } else {
      dispatch({ type: "disableSubmitButton", value: false });
    }
  }
}

/**
 * Successful got a data from the API
 * @param {Object} data
 * @param {appDispatch} appDispatch
 * @param {dispatch} dispatch
 */
export function handleSuccessfulGetData(data, appDispatch, dispatch) {
  appDispatch({ type: "showBackdrop", value: false });
  dispatch({ type: "profileDataLoaded", value: data });
}
