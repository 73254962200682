import React, { useContext } from "react";
import DreamAutocomplete from "../../ui_components/DreamAutocomplete/DreamAutocomplete";
import * as DreamAutocompleteHelper from "../../ui_components/DreamAutocomplete/DreamAutocompleteHelper";
import * as Constants from "../../helpers/Constants";
import { useTranslation } from "react-i18next";
// Context
import MyCvDispatchContext from "../../Pages/MyCvPage/Context/MyCvDispatchContext";
import MyCvStateContext from "../../Pages/MyCvPage/Context/MyCvStateContext";
import JobPreferenceStateContext from "../../Pages/CreateJobPreferencePage/Context/JobPreferenceStateContext";
import JobPreferenceDispatchContext from "../../Pages/CreateJobPreferencePage/Context/JobPreferenceDispatchContext";
import DreamOnClickTooltip from "../../ui_components/DreamOnClickTooltip/DreamOnClickTooltip";
import * as BaseHelper from "../../helpers/BaseHelper";

function LocationComponent(props) {
  const myCvDispatch = useContext(MyCvDispatchContext);
  const myCvContext = useContext(MyCvStateContext);

  const jobPreferenceStateContext = useContext(JobPreferenceStateContext);
  const jobPreferenceDispatchContext = useContext(JobPreferenceDispatchContext);

  const [t] = useTranslation(Constants.TRANSLATION_COMMON);

  function changeHandler(value) {
    if (props.dispatchContextName === "jobPreferenceDispatchContext") {
      jobPreferenceDispatchContext({
        type: "locationLocationChanged",
        value,
      });
    } else {
      myCvDispatch({
        type: "locationLocationChanged",
        value,
      });
    }
  }

  return (
    <div style={{ display: "flex", flexWrap: "noWrap", width: "100%" }}>
      <DreamAutocomplete
        fullWidth
        style={{ paddingLeft: "2%", paddingRight: "2%" }}
        label={t("cv.locationChoose")}
        usage={"location"}
        searchType={DreamAutocompleteHelper.SEARCH_TYPE_CITY}
        apiUrl={`${Constants.BASE_URL}location`}
        searchParamName={"search_string"}
        additionalApiParams={{ type: DreamAutocompleteHelper.SEARCH_TYPE_CITY }}
        changeHandler={changeHandler}
        isMandatory={true}
        preselectedValue={
          props.stateContextName === "jobPreferenceStateContext"
            ? jobPreferenceStateContext.formData.cv.location
            : myCvContext.formData.location
        }
      />
      {props.tooltipVisible ? (
        <DreamOnClickTooltip tooltipContent={BaseHelper.locationTooltipBG} />
      ) : (
        ""
      )}
    </div>
  );
}

export default LocationComponent;
