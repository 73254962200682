import React, { useEffect, useContext } from "react";
import StateContext from "../../Context/StateContext";
import { useImmerReducer } from "use-immer";
import Grid from "@material-ui/core/Grid";
import DreamSelectComponent from "../../ui_components/DreamSelect/DreamSelectComponent";
import * as DreamSelectHelper from "../../ui_components/DreamSelect/DreamSelectHelper";
import * as Constants from "../../helpers/Constants";
import DreamAutocomplete from "../../ui_components/DreamAutocomplete/DreamAutocomplete";
import * as DreamAutocompleteHelper from "../../ui_components/DreamAutocomplete/DreamAutocompleteHelper";
import * as PositionReducer from "./PositionReducer";
import { ExperienceRoot } from "../../containers_components/ExperienceComponent/ExperinceSingleItem/ExperinceSingleItemComponent.module.scss";
import JobPreferenceDispatchContext from "../../Pages/CreateJobPreferencePage/Context/JobPreferenceDispatchContext";
import JobPreferenceStateContext from "../../Pages/CreateJobPreferencePage/Context/JobPreferenceStateContext";

function PositionComponent(props) {
  const jobPreferenceDispatchContext = useContext(JobPreferenceDispatchContext);
  const jobPreferenceStateContext = useContext(JobPreferenceStateContext);
  const appContext = useContext(StateContext);
  const currentLang = appContext.appState.lang;

  const [state, dispatch] = useImmerReducer(
    PositionReducer.reducer,
    PositionReducer.originalState
  );

  function getAdditionalParams() {
    let additionalParams = {};
    additionalParams.lang = appContext.appState.lang
      .split("-")
      .join("_")
      .toLowerCase();
    if (state.industryId) {
      additionalParams.industry = state.industryId;
    }
    return additionalParams;
  }

  function industryChanged(value) {
    jobPreferenceDispatchContext({
      type: "positionIndustryChanged",
      value,
    });
    dispatch({
      type: "setIndustryId",
      value,
    });
    jobPreferenceDispatchContext({
      type: "setIndustryChangedCounter",
    });
  }

  function positionChanged(value) {
    jobPreferenceDispatchContext({
      type: "positionPositionChanged",
      value: value ? value : "",
    });
  }

  return (
    <Grid
      container
      style={{ marginTop: 10, paddingLeft: 20, paddingRight: 20 }}
    >
      <DreamSelectComponent
        fullWidth
        shrink
        displayEmpty
        dataType={DreamSelectHelper.DATA_TYPE_INDUSTRY}
        urlAPI={`dreamjob/industry`}
        label={"profile.industry"}
        dispatchChangeHandler={industryChanged}
        isMandatory={Boolean(props.isIndustryMandatory)}
        value={jobPreferenceStateContext.formData.position.industryId}
      />
      <DreamAutocomplete
        fullWidth
        acDisabled={!jobPreferenceStateContext.formData.position.industryId}
        label={"cv.position"}
        searchType={DreamAutocompleteHelper.SEARCH_TYPE_POSITION}
        apiUrl={`${Constants.BASE_URL}dreamjob/position`}
        searchParamName={"search_string"}
        changeHandler={positionChanged}
        lang={currentLang}
        additionalApiParams={getAdditionalParams()}
        isMandatory={Boolean(props.isPositionMandatory)}
        preselectedValue={{
          id: jobPreferenceStateContext.formData.position.positionId,
          name: jobPreferenceStateContext.formData.position.positionName,
        }}
      />
    </Grid>
  );
}

export default PositionComponent;
