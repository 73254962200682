import React, { useContext } from "react";
import { useImmerReducer } from "use-immer";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import JocvDispatchContext from "../../Pages/JobOfferCandidateView/Context/DispatchContext";
import JpevDispatchContext from "../../Pages/JobPreferenceEmployerView/Context/DispatchContext";
import CreateJoDispatchContext from "../../Pages/CreateJobOfferPage/Context/JobOfferDispatchContext";
import * as DreamAccordionComponentReducer from "./DreamAccordionComponentReducer";
import { useTranslation } from "react-i18next";
import * as Constants from "../../helpers/Constants";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "55em",
  },
  summary: {
    maxHeight: 60,
    margin: 0,
    display: "flex",
    border: "1px solid rgba(0, 0, 0, 0.1)",
    boxShadow: "0px 5px 13px rgba(0, 0, 0, 0.25)",
    borderRadius: 15,
  },
  mainIconWrapper: {
    marginRight: "auto",
    alignSelf: "center",
  },
  headingsWrapper: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "row",
    justifyContent: "left",
  },
  heading: {
    paddingLeft: 10,
    fontSize: 16,
    fontWeight: "bold",
  },
  expandIcon: {},
}));

const DreamAccordionList = (props) => {
  const { accordionItems } = props;
  const jocvDispatch = useContext(JocvDispatchContext);
  const jpevDispatch = useContext(JpevDispatchContext);
  const cjoDispatch = useContext(CreateJoDispatchContext);

  const [t] = useTranslation(Constants.TRANSLATION_COMMON);

  const classes = useStyles();

  const [state, dispatch] = useImmerReducer(
    DreamAccordionComponentReducer.reducer,
    DreamAccordionComponentReducer.initialState
  );

  let dispatchContext = null;
  if (props.dispatchContexName) {
    if (props.dispatchContexName == "jocvDispatch") {
      dispatchContext = jocvDispatch;
    } else if (props.dispatchContexName == "jpevDispatch") {
      dispatchContext = jpevDispatch;
    } else if (props.dispatchContexName == "cjoDispatch") {
      dispatchContext = cjoDispatch;
    }
  }

  const handleChange = (panel) => (event, isAccordionExpanded) => {
    dispatch({
      type: "setAccordionExpanded",
      value: isAccordionExpanded ? panel : false,
    });
    if (props.dispatchContexName) {
      dispatchContext({
        type: "getExpandedPanel",
        value: isAccordionExpanded ? panel : false,
      });
    }
  };

  const Mandatory = (props) => {
    return (
      <React.Fragment>
        {props.heading}
        <span className={props.symbolClassName}>{props.symbol}</span>
      </React.Fragment>
    );
  };

  return (
    <Grid
      container
      spacing={1}
      className={classes.root}
      style={props.accordionWrapperStyle}
    >
      {accordionItems.map((accordionItem, index) =>
        accordionItem.hidden ? (
          ""
        ) : (
          <Grid key={index} item xs={12}>
            <Accordion
              style={{ borderRadius: 15 }}
              classes={classes.expanded}
              key={index}
              expanded={
                accordionItem.itemExpanded
                  ? accordionItem.itemExpanded
                  : state.isAccordionExpanded === `panel${index + 1}`
              }
              onChange={handleChange(`panel${index + 1}`)}
              disabled={accordionItem.itemDisabled}
              hidden={accordionItem.hidden}
            >
              <AccordionSummary
                className={classes.summary}
                style={props.accordionSummaryStyle}
                expandIcon={<ExpandMoreIcon className={classes.expandIcon} />}
                aria-controls={`panel${index + 1}bh-content`}
                id={`panel${index + 1}bh-header`}
              >
                <div className={classes.mainIconWrapper}>
                  {accordionItem.mainIcon}
                </div>
                <div className={classes.headingsWrapper}>
                  <p className={classes.heading}>
                    {accordionItem.heading ? (
                      accordionItem.isMandatory ? (
                        <Mandatory
                          heading={t(accordionItem.heading)}
                          symbol={"*"}
                        />
                      ) : (
                        t(accordionItem.heading)
                      )
                    ) : (
                      t("basic.heading")
                    )}
                  </p>
                  <p>{t(accordionItem.secondaryHeading)}</p>
                </div>
              </AccordionSummary>
              <AccordionDetails>{accordionItem.mainContent}</AccordionDetails>
            </Accordion>
          </Grid>
        )
      )}
    </Grid>
  );
};

export default DreamAccordionList;
