import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./AppComponent/App";
import * as serviceWorker from "./serviceWorker";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import * as Constants from "./helpers/Constants";
import common_en from "./translations/en/common.json";
import common_en_us from "./translations/en-us/common.json";
import common_bg from "./translations/bg/common.json";
import common_de from "./translations/de/common.json";

const i18nextOptions = {
  order: ["navigator", "querystring"],
  lookupQuerystring: "lng",
};

const i18nextResources = {
  en: {
    [Constants.TRANSLATION_COMMON]: common_en, // 'common' is our custom namespace
  },
  ["en-US"]: {
    [Constants.TRANSLATION_COMMON]: common_en_us,
  },
  bg: {
    [Constants.TRANSLATION_COMMON]: common_bg,
  },
  de: {
    [Constants.TRANSLATION_COMMON]: common_de,
  },
};

i18next.use(LanguageDetector).init({
  detection: i18nextOptions,
  interpolation: { escapeValue: false }, // React already does escaping

  fallbackLng: "en",
  supportedLngs: ["en", "en-US", "bg"],
  nsSeparator: false, // allow keys to be phrases having `:`,

  resources: i18nextResources,
});

ReactDOM.render(
  <I18nextProvider i18n={i18next}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </I18nextProvider>,
  document.getElementById("root")
);

serviceWorker.unregister();
