import React, { useContext, useEffect } from "react";
import DispatchContext from "../../Context/DispatchContext";
import StateContext from "../../Context/StateContext";
import { useImmerReducer } from "use-immer";
import { useTranslation } from "react-i18next";
import * as Constants from "../../helpers/Constants";
import Axios from "axios";
import * as LoginHelper from "../../containers_components/LoginModalComponent/LoginHelper";
import { useParams, useHistory } from "react-router-dom";
import DreamButton from "../../ui_components/DreamButton/DreamButton";
import TextField from "@material-ui/core/TextField";
import DreamPage from "../../ui_components/DreamPage";
import * as ChangePwdHelper from "./ChangePasswordHelper";
import * as ChangePwdReducer from "./ChangePasswordReducer";
import { Root } from "./ChangePassword.module.scss";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: 380,
    width: "100%",
    marginTop: "4em",
    boxShadow: "0px 10px 10px rgba(0, 0, 0, 0.25)",
    borderRadius: 15,
  },
  dialogTitle: {
    width: "100%",
    paddingLeft: 50,
    paddingTop: 20,
    paddingBottom: 20,
    textAlign: "left",
    fontSize: 18,
    fontWeight: "bold",
    borderBottom: "2px solid lightgrey",
  },
  sendEmailMsg: {
    marginBottom: "3em",
  },
  contentWrapper: {
    padding: "2em",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

function ChangePasswordPage() {
  const appContext = useContext(StateContext);
  const appDispatch = useContext(DispatchContext);
  const [t] = useTranslation(Constants.TRANSLATION_COMMON);
  const classes = useStyles();
  let history = useHistory();
  const [state, dispatch] = useImmerReducer(
    ChangePwdReducer.reducer,
    ChangePwdReducer.originalState
  );
  const { fpHash } = useParams();
  /**
   * The Form has been submitted
   */
  //   useEffect(() => {
  //     if (state.formSubmittedCount) {
  //       appDispatch({ type: "showBackdrop", value: true });
  //       const ourRequest = Axios.CancelToken.source();
  //       LoginHelper.login(state, Axios, ourRequest, appDispatch, dispatch, t);
  //       return () => {
  //         ourRequest.cancel();
  //       };
  //     }
  //   }, [state.formSubmittedCount]);

  useEffect(() => {
    if (appContext.appState.loggedIn)
      appDispatch({
        type: "logout",
      });
  }, []);

  useEffect(() => {
    if (state.fields.password.value === "") {
      dispatch({ type: "resetPassword" });
      dispatch({ type: "resetPasswordConfirm" });
    }
  }, [state.fields.password.value]);

  useEffect(() => {
    return () => {
      history.push("/");
    };
  }, [state.serverResponsedCounter > 0]);

  const content = (
    <Paper className={classes.paper}>
      <span className={classes.dialogTitle}>
        {t("forgottenPassword.reset")}
      </span>
      <div className={classes.contentWrapper}>
        <p className={classes.sendEmailMsg}>
          {t("forgottenPassword.sendNewPasswordMsg")}
        </p>
        <TextField
          autoComplete="off"
          margin="dense"
          id="password"
          name="password"
          label={t("registerForm.password")}
          type="password"
          fullWidth
          value={state.fields.password.value ? state.fields.password.value : ""}
          onChange={(e) =>
            dispatch({
              type: "passwordChange",
              value: { value: e.target.value, t },
            })
          }
        />
        {state.fields.password.errorMessage && (
          <Alert severity="error">{state.fields.password.errorMessage}</Alert>
        )}
        <TextField
          autoComplete="off"
          margin="dense"
          id="passwordConfirm"
          name="passwordConfirm"
          label={t("registerForm.passwordConfirm")}
          type="password"
          fullWidth
          value={
            state.fields.passwordConfirm.value
              ? state.fields.passwordConfirm.value
              : ""
          }
          onChange={(e) =>
            dispatch({
              type: "passwordConfirmChange",
              value: { value: e.target.value, t },
            })
          }
        />
        {state.fields.passwordConfirm.errorMessage && (
          <Alert severity="error">
            {state.fields.passwordConfirm.errorMessage}
          </Alert>
        )}
        <DreamButton
          buttonType="submit"
          dreamButtonStyle={{
            width: 80,
            marginTop: "2em",
          }}
          buttonDisabled={Boolean(state.hasValidationErrs)}
          handleClick={(e) =>
            ChangePwdHelper.handleSubmit(
              e,
              fpHash,
              state,
              Axios,
              appDispatch,
              dispatch,
              t
            )
          }
          color="primary"
          kind="contained"
          buttonLabel="basic.submit"
        ></DreamButton>
      </div>
    </Paper>
  );

  return (
    <DreamPage
      className={Root}
      title={t("forgottenPassword.reset")}
      submitHandler={(e) => LoginHelper.handleSubmit(e, state, dispatch)}
    >
      {content}
    </DreamPage>
  );
}

export default ChangePasswordPage;
