import React, { useContext } from "react";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

import StateContext from "../../Context/StateContext";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 199999,
    color: "#00BFFF",
    backgroundColor: "transparent",
  },
}));

export default function DreamBackdrop() {
  const classes = useStyles();
  const appContext = useContext(StateContext);

  return (
    <div>
      <Backdrop
        className={classes.backdrop}
        open={appContext.appState.isBackdropOpen}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}
