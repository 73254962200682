import React, { useContext } from "react";

import MyCvDispatchContext from "../../Pages/MyCvPage/Context/MyCvDispatchContext";
import MyCvStateContext from "../../Pages/MyCvPage/Context/MyCvStateContext";
import JobPreferenceStateContext from "../../Pages/CreateJobPreferencePage/Context/JobPreferenceStateContext";
import JobPreferenceDispatchContext from "../../Pages/CreateJobPreferencePage/Context/JobPreferenceDispatchContext";

import DreamCheckboxGroupedComponent from "../../ui_components/DreamCheckboxGrouped/DreamCheckboxGroupedComponent";

function AdditionalSkillsComponent(props) {
  const myCvDispatch = useContext(MyCvDispatchContext);
  const myCvContext = useContext(MyCvStateContext);

  const jobPreferenceStateContext = useContext(JobPreferenceStateContext);
  const jobPreferenceDispatchContext = useContext(JobPreferenceDispatchContext);
  const dispatchContext =
    props.dispatchContextName === "jobPreferenceDispatchContext"
      ? jobPreferenceDispatchContext
      : myCvDispatch;

  const stateContext =
    props.stateContextName === "jobPreferenceStateContext"
      ? jobPreferenceStateContext
      : myCvContext;

  const formData =
    props.stateContextName === "jobPreferenceStateContext"
      ? stateContext.formData.cv.additionalSkills
      : stateContext.formData.additionalSkills;

  return (
    <DreamCheckboxGroupedComponent
      data={formData}
      dispatchContext={dispatchContext}
      stateContext={stateContext}
    />
  );
}

export default AdditionalSkillsComponent;
