import * as DreamAutocompleteHelper from "../../ui_components/DreamAutocomplete/DreamAutocompleteHelper";

export const locationsRadioButtons = [
  {
    label: "jp.city",
    value: DreamAutocompleteHelper.SEARCH_TYPE_CITY,
  },
  {
    label: "jp.state",
    value: DreamAutocompleteHelper.SEARCH_TYPE_STATE,
  },
  {
    label: "jp.country",
    value: DreamAutocompleteHelper.SEARCH_TYPE_COUNTRY,
  },
];

export function formatLocations(locations, dispatch) {
  let formattedLocation = [];
  locations.forEach((element, index) => {
    let locationType;
    let id;
    if (element.location_type == "country") {
      id = element.country_id;
      locationType = DreamAutocompleteHelper.SEARCH_TYPE_COUNTRY;
    } else if (element.location_type == "state") {
      id = element.state_id;
      locationType = DreamAutocompleteHelper.SEARCH_TYPE_STATE;
    } else {
      id = element.city_id;
      locationType = DreamAutocompleteHelper.SEARCH_TYPE_CITY;
    }

    let location = {
      id: index,
      value: {
        id: id,
        location_type: locationType,
        name: element.location_name,
      },
    };
    formattedLocation.push(location);
  });

  return formattedLocation;
}
