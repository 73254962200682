import React, { useContext } from "react";
import DreamRadioButtonsComponent from "../../ui_components/DreamRadioButtons/DreamRadioButtonsComponent";
import JobOfferStateContext from "../../Pages/CreateJobOfferPage/Context/JobOfferStateContext";
import JobOfferDispatchContext from "../../Pages/CreateJobOfferPage/Context/JobOfferDispatchContext";
import * as EmploymentHelper from "./EmploymentHelperJo";

function EmploymentComponent() {
  const jobOfferDispatchContext = useContext(JobOfferDispatchContext);
  const jobOfferStateContext = useContext(JobOfferStateContext);

  function employmentTypeChanged(value) {
    jobOfferDispatchContext({ type: "employmentTypeChanged", value: value });
  }

  return (
    <React.Fragment>
      <DreamRadioButtonsComponent
        name={"jp.employment"}
        value={jobOfferStateContext.formData.employmentType}
        data={EmploymentHelper.employmentRadioButtons}
        changeHandler={employmentTypeChanged}
      />
    </React.Fragment>
  );
}

export default EmploymentComponent;
