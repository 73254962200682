export const originalState = {
  jobPreferences: [],
  lastPromotedId: null,
  lastDeletedId: null,
  isModalOpen: false,
  isPromoteModalOpen: false,
  hasCvFnameLnameBdate: false,
};

export function reducer(draft, action) {
  switch (action.type) {
    case "getJobPreferences":
      draft.jobPreferences = action.value;
      break;
    case "changeStatus":
      let foundIndex = draft.jobPreferences.findIndex(
        (x) => x.id == action.value.id
      );
      draft.jobPreferences[foundIndex].disabled = action.value.disabled;
      break;
    case "confirmDelete":
      let deleteIndex = draft.jobPreferences.findIndex(
        (x) => x.id == draft.lastDeletedId
      );
      draft.jobPreferences.splice(deleteIndex, 1);
      break;
    case "setDeleteId":
      draft.lastDeletedId = action.value;
      break;
    case "setModalOpen":
      draft.isModalOpen = action.value;
      break;
    case "setCheckCreate":
      draft.hasCvFnameLnameBdate = action.value;
      break;

    // Promote
    case "confirmPromote":
      let promoteIndex = draft.jobPreferences.findIndex(
        (x) => x.id == draft.lastPromotedId
      );
      draft.jobPreferences[promoteIndex].isPromoted = true;
      draft.jobPreferences[promoteIndex].promotionEndDate =
        action.value.promotionEndDate;
      break;
    case "setPromoteId":
      draft.lastPromotedId = action.value;
      break;
    case "setPromoteModalOpen":
      draft.isPromoteModalOpen = action.value;
      break;
  }
}
