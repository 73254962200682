import React, { useContext } from "react";
import DreamRadioButtonsComponent from "../../ui_components/DreamRadioButtons/DreamRadioButtonsComponent";
import JobPreferenceDispatchContext from "../../Pages/CreateJobPreferencePage/Context/JobPreferenceDispatchContext";
import JobPreferenceStateContext from "../../Pages/CreateJobPreferencePage/Context/JobPreferenceStateContext";
import * as PreferredStartHelper from "./PreferredStartHelper";

function PreferredStartComponent() {
  const jobPreferenceDispatchContext = useContext(JobPreferenceDispatchContext);
  const jobPreferenceStateContext = useContext(JobPreferenceStateContext);

  function preferredStartChanged(value) {
    jobPreferenceDispatchContext({
      type: "preferredStartChanged",
      value: parseInt(value),
    });
  }

  return (
    <DreamRadioButtonsComponent
      name={"jp.start"}
      value={
        jobPreferenceStateContext.formData.preferredStartType.preferredStartType
      }
      data={PreferredStartHelper.preferredStartRadioButtons}
      changeHandler={preferredStartChanged}
    />
  );
}

export default PreferredStartComponent;
