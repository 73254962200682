import * as DreamJobOfferHelper from "../../Pages/CreateJobOfferPage/CreateJobOfferHelper";

export const preferredStartRadioButtons = [
  {
    label: "jo.any",
    value: DreamJobOfferHelper.SEARCH_TYPE_PREFERRED_START_ANY,
  },
  {
    label: "jo.immediate",
    value: DreamJobOfferHelper.SEARCH_TYPE_PREFERRED_START_IMMEDIATE,
  },
  {
    label: "jo.afterThirtyDays",
    value: DreamJobOfferHelper.SEARCH_TYPE_PREFERRED_START_AFTER_THIRTY_DAYS,
  },
  {
    label: "jo.moreThanThirtyDays",
    value:
      DreamJobOfferHelper.SEARCH_TYPE_PREFERRED_START_MORE_THAN_THIRTY_DAYS,
  },
];
