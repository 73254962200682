export const originalState = {
  checkboxes: [],
};

export function reducer(draft, action) {
  switch (action.type) {
    case "gotCheckboxesData":
      draft.checkboxes = action.value;
      return;

    case "changeChecked":
      let id = action.value.id;
      let isChecked = action.value.isChecked;
      let foundKey = draft.checkboxes.findIndex((x) => x.id == id);
      if (foundKey >= 0) {
        draft.checkboxes[foundKey].isChecked = isChecked;
      }
  }
}
