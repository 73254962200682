import * as JoHelper from "./JobPreferenceEmployerViewHelper";
import * as Constants from "../../helpers/Constants";
import * as EmploymentHelperJo from "../../containers_components/EmploymentComponentJo/EmploymentHelperJo";
import * as WorkplaceHelperJo from "../../containers_components/WorkplaceComponentJo/WorkplaceHelperJo";
import * as WorkScheduleHelperJo from "../../containers_components/WorkScheduleComponentJo/WorkScheduleHelperJo";
import * as PreferredStartHelperJo from "../../containers_components/PreferredStartComponentJo/PreferredStartHelperJo";
import * as AdditionalBenefitsHelperJo from "../../containers_components/AdditionalBenefitsComponentJo/AdditionalBenefitsHelperJo";

const workplaceTypes = WorkplaceHelperJo.workplaceRadioButtons;
const employmentTypes = EmploymentHelperJo.employmentRadioButtons;
const workScheduleTypes = WorkScheduleHelperJo.workScheduleRadioButtons;
const preferredStartTypes = PreferredStartHelperJo.preferredStartRadioButtons;
const additionalBenefits = AdditionalBenefitsHelperJo.additionalBenefits;

export const originalState = {
  candidatePreferencesIds: [],
  candidatePreferences: [],
  preferencesDataLoaded: false,
  preferenceId: 0,
  isModalOpen: false,
  isCandidateUnlocked: false,
  employerProfile: {
    pictureUrl: "",
    firstName: "",
    lastName: "",
  },
  panelExpanded: "",
};

export function reducer(draft, action) {
  switch (action.type) {
    case "candidatePreferenceDataLoaded":
      draft.preferencesDataLoaded = true;
      const preferenceData = action.value;

      let formattedPreferenceData = [];

      formattedPreferenceData.push(
        JoHelper.formatLocations(preferenceData.locations)
      );
      formattedPreferenceData.push(preferenceData.industry_name);
      formattedPreferenceData.push(preferenceData.position_name);
      formattedPreferenceData.push(
        JoHelper.setTypeLabel(workplaceTypes, preferenceData.wp_type)
      );
      formattedPreferenceData.push(
        JoHelper.setTypeLabel(employmentTypes, preferenceData.e_type)
      );
      formattedPreferenceData.push(JoHelper.formatSalaryData(preferenceData));

      formattedPreferenceData.push(
        JoHelper.setTypeLabel(workScheduleTypes, preferenceData.work_schedule)
      );
      formattedPreferenceData.push(
        JoHelper.setTypeLabel(preferredStartTypes, preferenceData.start)
      );
      formattedPreferenceData.push(
        JoHelper.formatBenefits(additionalBenefits, preferenceData)
      );

      draft.candidatePreferences.push({
        preferenceId: preferenceData.id,
        candidateId: preferenceData.userID,
        cvId: preferenceData.cv,
        preference: formattedPreferenceData,
      });
      draft.preferenceId = preferenceData.id;

      break;

    case "profileDataLoaded":
      const profileData = action.value;
      draft.employerProfile.pictureUrl = `${Constants.BASE_URL}upload/${profileData.picture}`;
      draft.employerProfile.firstName =
        profileData.fname != "null" ? profileData.fname : "Unknown";
      draft.employerProfile.lastName =
        profileData.lname != "null" ? profileData.lname : "Unknown";
      break;

    case "getExpandedPanel":
      draft.panelExpanded = action.value;
      return;
  }
}
