import React, { useEffect, useContext, useState, useRef } from "react";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";
import { useImmerReducer } from "use-immer";
import PreviewFileComponent from "../PreviewFileComponent/PreviewFileComponent";
import PreviewAvatarComponent from "../PreviewFileComponent/PreviewAvatarComponent";

import Grid from "@material-ui/core/Grid";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme, makeStyles } from "@material-ui/core/styles";

import * as FileUploaderReducer from "./FileUploaderReducer";
import * as FileUploaderHelper from "./FileUploaderHelper";

import DispatchContext from "../../../Context/DispatchContext";
import CloudUploadOutlinedIcon from "@material-ui/icons/CloudUploadOutlined";
import Colors from "../../../styles/01.settings/01-settings-colors.module.scss";
import { useTranslation } from "react-i18next";
import * as Constants from "../../../helpers/Constants";

import {
  DropzoneRootWrapper,
  DropzoneAreaWrapper,
  DropzonePreviewWrapper,
  DropzonePreviewWrapperOneFile,
  PreviewComponent,
  Wrapper,
  UploadIcon,
  HeadingContainer,
  UpperWrapper,
  IconBtn,
} from "./DreamFileUploader.module.scss";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";

const Input = ({ accept, onFiles, files, getFilesFromEvent }) => {
  const [t] = useTranslation(Constants.TRANSLATION_COMMON);
  const inputEl = useRef(null);

  return (
    <label
      style={{
        backgroundColor: Colors.white,
        color: "#fff",
        cursor: "pointer",
        padding: 0,
        borderRadius: "50%",
      }}
    >
      {/* {t("basic.selectFile")} */}
      <AddCircleOutlineIcon htmlColor={Colors.text} />
      <input
        style={{ display: "none" }}
        type="file"
        accept={accept}
        ref={inputEl}
        multiple
        onChange={(e) => {
          getFilesFromEvent(e).then((chosenFiles) => {
            onFiles(chosenFiles);
            // Fix
            if (inputEl && inputEl.current && inputEl.current.value) {
              inputEl.current.value = null;
            }
            //
          });
        }}
      />
    </label>
  );
};

const useStyles = makeStyles((theme) => ({
  wrapperAvatar: {
    height: 20,
  },
  root: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    position: "relative",
  },
  rootAvatar: {
    display: "flex",
    position: "relative",
    justifyContent: "center",
    top: -90,
  },
}));

function DreamFileUploader(props) {
  const [state, dispatch] = useImmerReducer(
    FileUploaderReducer.reducer,
    FileUploaderReducer.originalState
  );
  const [t] = useTranslation(Constants.TRANSLATION_COMMON);
  const classes = useStyles();
  const appDispatch = useContext(DispatchContext);
  /**
   * Get Initialialy pre-uploaded files
   */
  useEffect(() => {
    FileUploaderHelper.getInitialFiles(props.initialFiles, dispatch);
    dispatch({
      type: "resetInitialFiles",
    });
  }, [props.loadedDataBe]);

  /**
   * Dispatch uploaded files outside
   */
  useEffect(() => {
    props.upDispatch({
      type: "uploadedFiles",
      value: {
        uploadedFiles: FileUploaderHelper.formatData(state.uploadedFiles),
        uploadingFiles: FileUploaderHelper.formatData(state.uploadingFiles),
      },
    });
  }, [state.uploadedFiles]);

  let availableUploads = props.maxFiles
    ? props.maxFiles - state.uploadedFiles.length
    : 10 - state.uploadedFiles.length;

  const theme = useTheme();
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));
  const matchesSMandUp = useMediaQuery(theme.breakpoints.up("sm"));

  let uploadedFilesCount = state.uploadedFiles.length;

  const HeadingLine = (props) => {
    return (
      <div className={HeadingContainer}>
        <h2>{props.heading}</h2>
      </div>
    );
  };

  return (
    <Grid
      container
      className={!props.previewAvatar ? classes.wrapper : classes.wrapperAvatar}
    >
      <HeadingLine heading={props.heading ? props.heading : ""} />
      <Grid
        className={!props.previewAvatar ? classes.root : classes.rootAvatar}
        container
        spacing={1}
      >
        {!props.previewAvatar ? (
          <Grid className={DropzoneAreaWrapper} item>
            <Dropzone
              disabled={!(availableUploads > 0)}
              initialFiles={state.initialFileUploads}
              accept={props.accept ? props.accept : "image/*,.pdf"}
              InputComponent={availableUploads > 0 ? Input : null}
              PreviewComponent={null}
              SubmitComponent={null}
              getFilesFromEvent={(e) => FileUploaderHelper.getFilesFromEvent(e)}
              onChangeStatus={({ meta, remove }, status, fileWithMeta) =>
                FileUploaderHelper.handleChangeStatus(
                  meta,
                  remove,
                  status,
                  fileWithMeta,
                  dispatch,
                  appDispatch,
                  availableUploads,
                  props.somethingManualyChangedHandler
                )
              }
            />
          </Grid>
        ) : (
          ""
        )}

        {props.previewAvatar ? (
          <PreviewAvatarComponent
            badgeContent={
              <Grid className={DropzoneAreaWrapper} item>
                <Dropzone
                  disabled={!(availableUploads > 0)}
                  initialFiles={state.initialFileUploads}
                  accept={props.accept ? props.accept : "image/*,.pdf"}
                  InputComponent={availableUploads > 0 ? Input : null}
                  PreviewComponent={null}
                  SubmitComponent={null}
                  getFilesFromEvent={(e) =>
                    FileUploaderHelper.getFilesFromEvent(e)
                  }
                  onChangeStatus={({ meta, remove }, status, fileWithMeta) =>
                    FileUploaderHelper.handleChangeStatus(
                      meta,
                      remove,
                      status,
                      fileWithMeta,
                      dispatch,
                      appDispatch,
                      availableUploads,
                      props.somethingManualyChangedHandler
                    )
                  }
                />
              </Grid>
            }
            uploadedFiles={state.uploadedFiles}
            dispatchFileUpload={dispatch}
            useDescription={props.useDescription}
            somethingManualyChangedHandler={
              props.somethingManualyChangedHandler
            }
          />
        ) : (
          <Grid item>
            <PreviewFileComponent
              uploadedFiles={state.uploadedFiles}
              dispatchFileUpload={dispatch}
              useDescription={props.useDescription}
              somethingManualyChangedHandler={
                props.somethingManualyChangedHandler
              }
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

export default DreamFileUploader;
