export const originalState = {
  employers: [],
  employersDataLoaded: false,
  pagination: {
    page: 0,
    page_size: 10,
    hasMoreData: false,
    cursor: null,
    currentPage: 1,
    currentPageSize: 2,
    totalPageCount: 0,
    totalCount: 0,
  },
};

export function reducer(draft, action) {
  switch (action.type) {
    case "getEmployers":
      draft.employers = action.value;
      break;

    // Start Pagination  -----
    case "setPage":
      draft.pagination.page = action.value;
      break;
    case "setRowsPerPage":
      draft.pagination.page_size = action.value;
      break;
    case "setPaginationTotalPageCount":
      draft.pagination.totalPageCount = action.value;
      break;
    // End Pagination  -----

    case "employersDataLoaded":
      draft.employersDataLoaded = true;
      const employersData = action.value;
      draft.employers = employersData.items ? employersData.items : [];
      draft.pagination.totalPageCount = employersData.total_page_count;
      draft.pagination.totalCount = employersData.total_count;

      break;
  }
}
