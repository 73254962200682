import React from "react";
import Button from "@material-ui/core/Button";
import { useTranslation } from "react-i18next";
import * as Constants from "../../helpers/Constants";

const DreamButton = (props) => {
  const [t] = useTranslation(Constants.TRANSLATION_COMMON);

  const classNameBase = "DreamButton";
  let className = classNameBase;
  let classes = [classNameBase];

  switch (props.kind) {
    case "text":
      className += "-text";
      classes.push(className);
      break;
    case "contained":
      className += "-contained";
      classes.push(className);
      break;

    default:
      className += "-outlined";
      classes.push(className);
      break;
  }

  className = classNameBase;

  switch (props.color) {
    case "primary":
      className += "-primary";
      classes.push(className);
      break;
    case "info":
      className += "-info";
      classes.push(className);
      break;
    case "success":
      className += "-success";
      classes.push(className);
      break;
    case "danger":
      className += "-danger";
      classes.push(className);
      break;
    case "warning":
      className += "-warning";
      classes.push(className);
      break;
    case "secondary":
      className += "-secondary";
      classes.push(className);
      break;
    default:
      className += "-default";
      classes.push(className);
      break;
  }

  classes.push("Dream");
  classes.push(props.className);

  return (
    <Button
      style={props.dreamButtonStyle}
      className={classes.join(" ")}
      type={props.buttonType}
      onClick={props.handleClick}
      onSubmit={props.handleClick}
      disabled={props.buttonDisabled}
      size={props.buttonSize}
      autoFocus={props.autoFocus}
      component={props.btnComponent}
      to={props.to}
      href={props.btnHref}
      variant={props.variant}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: props.icon ? "space-between" : "center",
          color: props.buttonLabelColor,
        }}
      >
        {props.icon}
        {t(props.buttonLabel)}
      </div>
    </Button>
  );
};

export default DreamButton;
