import * as Constants from "../../../helpers/Constants"

// DELETE jo
export function handleDelete(userId, Axios, appDispatch, dispatch) {
  dispatch({ type: "setModalOpen", value: false });
  // dispatch({ type: "confirmDelete" });
  let deleteAccount = deleteRequest(userId, Axios,appDispatch);

  if (!deleteAccount) {
    appDispatch({
      type: "showMsg",
      value: {
        status: Constants.SNACKBAR_ERROR,
        msg: "accountSettings.notDeletedAccount",
      },
    });
  }
}

export async function deleteRequest(userId, Axios,appDispatch) {
  let success = true;
  try {
    const response = await Axios.delete(
      `/user/${userId}`
    ).catch(function (error) {
      // Errors to be sent to scanckbar
      let errMsgs = "Server error";
      if (error.response) {
        // Request made and server responded
        if (error.response.data.message) {
          errMsgs = JSON.parse(error.response.data.message);
        }
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
      throw errMsgs;
    });

  } catch (error) {
    console.log("Error delete user Account:", error);
    success = false;
  }

  appDispatch({type:"logout"});
  appDispatch({
    type: "showMsg",
    value: {
      status: Constants.SNACKBAR_SUCCESS,
      msg: "accountSettings.deletedAccount",
    },
  });
  return success;
}
