import * as Constants from "../../helpers/Constants";

export async function getGeneralSkills(
  Axios,
  ourRequest,
  dispatch,
  appDispatch
) {
  try {
    const response = await Axios.get(`dreamjob/gskills`, {
      cancelToken: ourRequest.token,
    }).catch(function (error) {
      throw error;
    });

    let formattedSkills = formatSkills(response.data.data);

    dispatch({
      type: "generalSkillsGotSkills",
      value: formattedSkills,
    });
  } catch (error) {
    appDispatch({
      type: "showMsg",
      value: {
        status: Constants.SNACKBAR_ERROR,
        msg: "basic.errorGeneralSkills",
      },
    });
  }
}

function formatSkills(data, t) {
  let formattedSkills = [];
  for (const property in data) {
    formattedSkills.push({
      id: property,
      label: `generalSkills.${data[property]}`,
      isChecked: false,
    });
  }

  return formattedSkills;
}
