import React, { useContext, useEffect } from "react";
import Axios from "axios";
import Grid from "@material-ui/core/Grid";
import DispatchContext from "../../../Context/DispatchContext";
import StateContext from "../../../Context/StateContext";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import * as AppRoutes from "../../../AppComponent/AppRoutes";
import { useTranslation } from "react-i18next";
import { useImmerReducer } from "use-immer";
import * as Constants from "../../../helpers/Constants";
import DreamPage from "../../../ui_components/DreamPage";
import DreamButton from "../../../ui_components/DreamButton/DreamButton";
import Alert from "@material-ui/lab/Alert";
import * as ChangeEmailHelper from "./ChangeEmailHelper";
import * as ChangeEmailReducer from "./ChangeEmailReducer";

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    marginTop: "5em",
  },
  gridItem: {
    minWidth: 450,
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: 450,
    width: "100%",
    minHeight: 135,
    paddingLeft: 10,
    paddingRight: 10,
    boxShadow: "0px 10px 10px rgba(0, 0, 0, 0.25)",
    borderRadius: 15,
  },
  inputField: {
    maxWidth: 330,
    width: "100%",
  },
}));

const ChangeEmail = () => {
  const classes = useStyles();
  const appDispatch = useContext(DispatchContext);
  const appContext = useContext(StateContext);
  const [t] = useTranslation(Constants.TRANSLATION_COMMON);
  const userId = appContext.appState.user_id;

  const [state, dispatch] = useImmerReducer(
    ChangeEmailReducer.reducer,
    ChangeEmailReducer.originalState
  );

  useEffect(() => {
    appDispatch({ type: "showBackdrop", value: true });
    const ourRequest = Axios.CancelToken.source();

    ChangeEmailHelper.getProfileData(
      userId,
      Axios,
      ourRequest,
      appDispatch,
      dispatch,
      t
    );

    return () => {
      ourRequest.cancel();
    };
  }, []);

  /**
   * The Form has been submitted
   */
  useEffect(() => {
    if (state.formSubmittedCount) {
      appDispatch({ type: "showBackdrop", value: true });
      const ourRequest = Axios.CancelToken.source();

      ChangeEmailHelper.updateProfileEmail(
        userId,
        state,
        ChangeEmailHelper.USER_PROFILE_PICTURE, // image type 1 (profile picture)
        Axios,
        ourRequest,
        appDispatch,
        dispatch,
        t
      );

      return () => {
        ourRequest.cancel();
      };
    }
  }, [state.formSubmittedCount]);

  /**
   * The Form has been successfully submitted (updated)
   */
  useEffect(() => {
    if (state.formSuccessfullySubmittedCount) {
      appDispatch({ type: "showBackdrop", value: true });

      const ourRequest = Axios.CancelToken.source();

      ChangeEmailHelper.getProfileData(
        userId,
        Axios,
        ourRequest,
        appDispatch,
        dispatch,
        t
      );

      return () => {
        ourRequest.cancel();
      };
    }
  }, [state.formSuccessfullySubmittedCount]);

  return (
    <DreamPage
      title={t("accountSettings.changeEmail")}
      backButton
      backButtonDisabled={!state.loadedDataBe}
      backButtonTo={AppRoutes.ROUTE_ACCOUNT_SETTINGS}
    >
      <Grid
        container
        spacing={3}
        direction="column"
        classes={{ root: classes.gridContainer }}
      >
        <Grid item classes={{ root: classes.gridItem }}>
          <Paper elevation={3} className={classes.paper}>
            {/* Email */}
            <TextField
              className={classes.inputField}
              autoComplete="email"
              margin="dense"
              id="email"
              name="email"
              label={t("loginForm.username")}
              fullWidth
              value={state.fields.email.value ? state.fields.email.value : ""}
              onChange={(e) =>
                dispatch({
                  type: "emailChange",
                  value: { value: e.target.value, t },
                })
              }
            />
            {state.fields.email.errorMessage && (
              <Alert severity="error">{state.fields.email.errorMessage}</Alert>
            )}
          </Paper>
        </Grid>
        <Grid item classes={{ root: classes.gridItem }}>
          <DreamButton
            buttonType="button"
            dreamButtonStyle={{
              minWidth: 130,
              marginTop: "2em",
              boxShadow: "0px 10px 10px rgba(0, 0, 0, 0.25)",
            }}
            disabled={Boolean(state.hasValidationErrs)}
            handleClick={(e) => ChangeEmailHelper.handleSubmit(e, dispatch)}
            color="primary"
            kind="contained"
            buttonLabel="basic.save"
          ></DreamButton>
        </Grid>
      </Grid>
    </DreamPage>
  );
};

export default ChangeEmail;
