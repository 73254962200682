import React, { useContext, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import { useImmerReducer } from "use-immer";
import { useTranslation } from "react-i18next";
import * as Constants from "../../helpers/Constants";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import MailIcon from "@material-ui/icons/Mail";
import * as Colors from "../../styles/01.settings/01-settings-colors.module.scss";
import * as JPReducer from "./JobPreferencesPageReducer";
import * as JPHelper from "./JobPreferencesPageHelper";
import DreamPage from "../../ui_components/DreamPage";
import DreamCardMedia from "../../ui_components/DreamCardMediaComponent/DreamCardMedia";
import Axios from "axios";
import * as AppRoutes from "../../AppComponent/AppRoutes";
import DispatchContext from "../../Context/DispatchContext";
import StateContext from "../../Context/StateContext";
import { Link as RouterLink, Redirect, withRouter } from "react-router-dom";
import DreamConfirmModal from "../../ui_components/DreamConfirmModalComponent/DreamConfirmModalComponent";
import * as RouteConstants from "../../AppComponent/AppRoutes";
import DreamButton from "../../ui_components/DreamButton/DreamButton";

const JobPreferences = () => {
  const [t] = useTranslation(Constants.TRANSLATION_COMMON);
  const appDispatch = useContext(DispatchContext);
  const appContext = useContext(StateContext);
  const pageTitle = t("drawer.jobPreferences");

  const [state, dispatch] = useImmerReducer(
    JPReducer.reducer,
    JPReducer.originalState
  );

  const useStyles = makeStyles({
    table: {
      maxWidth: 770,
    },
    title: {
      fontWeight: "bold",
    },
    tableCell: {
      textAlign: "center",
      borderBottom: "none",
      padding: 8,
    },
    tblContainer: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      overflowX: "hidden",
    },
    tblRow: {},
    actionsButton: {},
    menuButton: {
      width: "100%",
    },
    subHeader: {
      color: Colors.success,
    },
    subHeaderDanger: {
      color: Colors.danger,
    },
    addButton: {
      width: 137,
    },
  });

  const classes = useStyles();

  // Promote modal
  const handlePromoteModalOpen = (preference) => {
    dispatch({ type: "setPromoteModalOpen", value: true });
    dispatch({ type: "setPromoteId", value: preference.id });
  };

  const handlePromoteModalClose = () => {
    dispatch({ type: "setPromoteModalOpen", value: false });
  };
  // Promote modal

  // Delete modal
  const handleClickOpen = (preference) => {
    dispatch({ type: "setModalOpen", value: true });
    dispatch({ type: "setDeleteId", value: preference.id });
  };

  const handleClose = () => {
    dispatch({ type: "setModalOpen", value: false });
  };
  // End Delete modal

  const isProfileDataFilled = JSON.parse(
    localStorage.getItem("UserProfileDataFilled")
  );
  const isCvDataFilled = JSON.parse(localStorage.getItem("dreamJobUserCvId"));

  useEffect(() => {
    if (isProfileDataFilled && isCvDataFilled) {
      const ourRequest = Axios.CancelToken.source();

      JPHelper.getJobPreferences(Axios, ourRequest, appDispatch, dispatch);

      return () => {
        ourRequest.cancel();
      };
    }
  }, [isProfileDataFilled, isCvDataFilled]);

  const userHasAll =
    state.jobPreferences.length < 3 ? (
      <TableRow className={classes.tblRow}>
        <TableCell align="center" colSpan={4} className={classes.tableCell}>
          <DreamButton
            className={classes.addButton}
            color="primary"
            kind="contained"
            buttonLabel={t("jos.add")}
            btnComponent={RouterLink}
            to={`${AppRoutes.ROUTE_CREATE_JOB_PREFERENCE}`}
            icon={<AddCircleOutlineOutlinedIcon htmlColor={Colors.white} />}
          />
        </TableCell>
      </TableRow>
    ) : (
      <React.Fragment></React.Fragment>
    );

  if (isProfileDataFilled && isCvDataFilled) {
    return (
      <DreamPage
        title={pageTitle}
        dreamPageWrapperStyle={{ paddingBottom: 50 }}
      >
        <DreamConfirmModal
          isOpen={state.isModalOpen}
          handleClose={handleClose}
          title={t("jps.confirmDelete")}
          firstRow={t("jps.deleteModalText")}
          handleCancel={handleClose}
          buttonCancelLabel={"basic.cancel"}
          handleConfirm={(e) =>
            JPHelper.handleDelete(
              state.lastDeletedId,
              Axios,
              appDispatch,
              dispatch
            )
          }
          buttonConfirmLabel={"basic.confirm"}
        />
        <DreamConfirmModal
          isOpen={state.isPromoteModalOpen}
          handleClose={handlePromoteModalClose}
          title={t("jps.confirmPromote")}
          firstRow={t("jps.promoteModalTextFirstRow")}
          secondRow={t("jps.promoteModalTextSecondRow")}
          thirdRow={t("jps.promoteModalTextThirdRow")}
          handleCancel={handlePromoteModalClose}
          buttonCancelLabel={"basic.cancel"}
          handleConfirm={(e) =>
            JPHelper.handlePromote(
              JPHelper.PROMOTE_PREFERENCE,
              state.lastPromotedId,
              Axios,
              appDispatch,
              dispatch,
              t
            )
          }
          buttonConfirmLabel={"basic.confirm"}
        />
        <TableContainer className={classes.tblContainer}>
          <Table className={classes.table} aria-label="simple table">
            <TableBody>
              {state.jobPreferences.map((preference) => (
                <TableRow
                  className={classes.tblRow}
                  key={preference.id}
                  // hover={true}
                >
                  <TableCell className={classes.tableCell} align="center">
                    <DreamCardMedia
                      cardHeader
                      cardHeaderTitle={t(`positions.${preference.position_id}`)}
                      headerAction
                      cardActions
                      subheader={
                        !parseInt(preference.disabled)
                          ? t("jos.active")
                          : t("jos.inactive")
                      }
                      subHeaderStyle={
                        preference.disabled == "0"
                          ? classes.subHeader
                          : classes.subHeaderDanger
                      }
                      cardActionsChildren={
                        <React.Fragment>
                          <DreamButton
                            className={classes.actionsButton}
                            kind="contained"
                            buttonLabel={
                              !preference.isPromoted
                                ? t("jps.promote")
                                : t("jps.promoted")
                            }
                            buttonDisabled={preference.disabled != "0"}
                            color={
                              !preference.isPromoted ? "primary" : "warning"
                            }
                            handleClick={(e) =>
                              handlePromoteModalOpen(preference)
                            }
                          />
                          <DreamButton
                            className={classes.actionsButton}
                            kind="contained"
                            buttonLabel={t("jos.messages")}
                            color={"secondary"}
                            btnComponent={RouterLink}
                            to={`/job-preference-employers-with-messages/${preference.id}`}
                            icon={<MailIcon style={{ paddingRight: 5 }} />}
                          />
                        </React.Fragment>
                      }
                      menuStyle={{
                        position: "absolute",
                        right: 0,
                      }}
                      menuItems={[
                        {
                          child: (
                            <DreamButton
                              className={classes.menuButton}
                              kind="contained"
                              buttonLabel={t("jos.edit")}
                              color={"secondary"}
                              btnComponent={RouterLink}
                              to={`${RouteConstants.ROUTE_VIEW_JOB_PREFERENCE.replace(
                                ":jpId",
                                preference.id
                              )}`}
                              icon={
                                <CreateOutlinedIcon
                                  htmlColor={Colors.warning}
                                  style={{
                                    paddingRight: 5,
                                    justifySelf: "flex-start",
                                  }}
                                />
                              }
                            />
                          ),
                        },
                        {
                          child: (
                            <DreamButton
                              className={classes.menuButton}
                              kind="contained"
                              buttonLabel={t("jos.delete")}
                              color={"secondary"}
                              handleClick={(e) => handleClickOpen(preference)}
                              icon={
                                <DeleteOutlinedIcon htmlColor={Colors.danger} />
                              }
                            />
                          ),
                        },
                        {
                          child: (
                            <DreamButton
                              className={classes.menuButton}
                              kind="contained"
                              buttonLabel={
                                preference.disabled != "0"
                                  ? t("jos.activate")
                                  : t("jos.deactivate")
                              }
                              color={"secondary"}
                              handleClick={(e) =>
                                JPHelper.changeStatus(
                                  preference,
                                  Axios,
                                  appDispatch,
                                  dispatch,
                                  t
                                )
                              }
                              icon={
                                <CheckCircleOutlinedIcon
                                  htmlColor={
                                    preference.disabled == "0"
                                      ? Colors.success
                                      : Colors.light
                                  }
                                  style={{ paddingRight: 5 }}
                                />
                              }
                            />
                          ),
                        },
                      ]}
                      cardContentStyle={{ paddingTop: 0, paddingBottom: 0 }}
                      cardContent={[
                        `${t("jp.promotedUntil")} : ${
                          preference.isPromoted
                            ? preference.promotionEndDate
                            : t("jp.notPromoted")
                        }`,
                      ]}
                    />
                  </TableCell>
                </TableRow>
              ))}
              {userHasAll}
            </TableBody>
          </Table>
        </TableContainer>
      </DreamPage>
    );
  } else {
    if (!isProfileDataFilled) {
      return (
        <Redirect
          to={{
            pathname: "/user-profile",
            state: { fromJPs: "/job-peferences" },
          }}
        />
      );
    } else if (!isCvDataFilled) {
      return (
        <Redirect
          to={{
            pathname: "/my-cv",
            state: { fromJPs: "/job-peferences" },
          }}
        />
      );
    }
  }
};

export default withRouter(JobPreferences);
