import React from "react";
import DreamPage from "../../ui_components/DreamPage";

const MyMessages = (props) => {
  return (
    <DreamPage title={"My messages"}>
      <h1>{"My messages"}</h1>
    </DreamPage>
  );
};

export default MyMessages;
