import * as Constants from "../../helpers/Constants";
import _ from "lodash";

export function handleSuccessfulRegistered(data, appDispatch, dispatch, t) {
  appDispatch({ type: "showBackdrop", value: false });
  dispatch({ type: "resetForm" });
  dispatch({ type: "setTermsCheckbox", value: false });
  appDispatch({
    type: "showMsg",
    value: {
      status: Constants.SNACKBAR_SUCCESS,
      msg: t("registerForm.successfulRegistered"),
    },
  });

  appDispatch({
    type: "openSignUpModal",
    value: false,
  });
}

export async function register(
  state,
  Axios,
  ourRequest,
  appDispatch,
  dispatch,
  t,
  reghash
) {
  dispatch({ type: "disableSubmitButton", value: true });

  // if (state.fields.password.value !== state.fields.passwordConfirm.value) {
  //   appDispatch({
  //     type: "showMsg",
  //     value: {
  //       status: Constants.SNACKBAR_ERROR,
  //       msg: t("registerForm.differentPasswords"),
  //     },
  //   });
  //   dispatch({ type: "disableSubmitButton", value: false });
  //   return;
  // }
  let headers = {
    email: state.fields.email.value,
    password: state.fields.password.value,
    password2: state.fields.passwordConfirm.value,
    agreed_tc: state.agreed_tc ? JSON.stringify(1) : JSON.stringify(0),
    opt_in: state.opt_in ? JSON.stringify(1) : JSON.stringify(0),
    // phone: state.fields.phone.value,
  };

  if (!_.isEmpty(reghash)) {
    headers.reghash = reghash.reghash;
  }
  let errMsgs;
  try {
    const response = await Axios.post("/user", headers, {
      cancelToken: ourRequest.token,
    }).catch(function (error) {
      // Errors to be sent to scanckbar
      errMsgs = t("basic.serverError");
      if (error.response) {
        // Request made and server responded
        if (error.response.data.message) {
          errMsgs = JSON.parse(error.response.data.message);
        }
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
      throw errMsgs;
    });

    const responseData = response.data.data;
    let errsArr = [];
    if (!responseData.success && responseData.messages.length) {
      responseData.messages.forEach((msg) => {
        errsArr.push(`serverErrors.${msg.code}`);
      });
      throw errsArr;
    }

    handleSuccessfulRegistered(responseData, appDispatch, dispatch, t);
  } catch (error) {
    appDispatch({ type: "showBackdrop", value: false });
    appDispatch({
      type: "showMsg",
      value: { status: Constants.SNACKBAR_ERROR, msg: error },
    });
    dispatch({ type: "disableSubmitButton", value: false });
  }
}

export function handleClose(dispatch, appDispatch) {
  dispatch({ type: "resetForm" });
  appDispatch({ type: "openSignUpModal", value: false });
}

export function handleSubmit(e, dispatch) {
  e.preventDefault();
  dispatch({ type: "formSubmitted" });
}
