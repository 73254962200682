import React, { useContext } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as Constants from "../../helpers/Constants";
import queryString from "query-string";
import DreamPage from "../../ui_components/DreamPage";
import * as RouteConstants from "../../AppComponent/AppRoutes";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppContext from "../../Context/StateContext";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import SugnUpComponent from "../SignUpComponent/SignUpComponent";
import LoginComponent from "../LoginModalComponent/LoginModalComponent";
import * as Colors from "../../styles/01.settings/01-settings-colors.module.scss";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const HomeComponent = (props) => {
  const appContext = useContext(AppContext);
  const theme = useTheme();
  const matchesXSdown = useMediaQuery(theme.breakpoints.down("xs"));
  const [t] = useTranslation(Constants.TRANSLATION_COMMON);

  // Get users location (URL)
  const location = useLocation();

  // const parsedUrl = queryString.parse(window.location.search);
  const parsedUrl = queryString.parse(location.search);
  const token = parsedUrl;

  // useEffect(() => {

  //   dispatch({ type: "setLocation", value: location.pathname });
  // }, []);
  const useStyles = makeStyles((theme) => ({
    page: {
      height: "100%",
      display: "flex",
      alignItems: "center",
    },
    contentContainer: {
      width: "100%",
      // height: "100%",
      height: "max-content",
      display: "flex",
      // alignItems: "center",
      justifyContent: matchesXSdown
        ? "center"
        : appContext.appState.loggedIn
        ? "center"
        : "space-around",
      paddingLeft: 10,
      paddingRight: 10,
    },
    contentItem: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      paddingLeft: 10,
      paddingRight: 10,
      // maxWidth: 350,
      // height: "min-content",
      // paddingBottom: 60,
      // paddingTop: 60,
    },
    contentTitle: {
      width: "100%",
      fontSize: matchesXSdown ? 24 : 36,
      color: Colors.hpTitleOrange,
      textAlign: "left",
    },
    mainContent: {
      display: "flex",
      maxWidth: 600,
      width: "100%",
      fontSize: matchesXSdown ? 36 : 48,
      color: Colors.primary,
    },
    formItem: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      // paddingTop: 60,
      // paddingBottom: 60,
    },
  }));

  const classes = useStyles();

  const pageTitle = "basic.home";
  return (
    <DreamPage
      route={RouteConstants.ROUTE_HOME}
      className={classes.page}
      dreamPageWrapperStyle={{ height: "100%" }}
    >
      <Grid container className={classes.contentContainer}>
        <Grid item className={classes.contentItem}>
          <h2 className={classes.contentTitle}>JobPreference.com</h2>
          <h1 className={classes.mainContent}>{t("mainContent.text")}</h1>
        </Grid>
        <Grid item classes={{ root: classes.formItem }}>
          {!appContext.appState.loggedIn ? (
            <LoginComponent
              // signUpFormWrapperStyle={{
              //   padding: 20,
              //   display: "flex",
              //   flexDirection: "column",
              //   justifyContent: "flex-start",
              //   alignItems: "flex-start",
              //   maxWidth: 420,
              //   width: "100%",
              //   height: 480,
              // }}
              loginButtonStyle={{ minWidth: 130 }}
              signUpButtonStyle={{ minWidth: 130, marginTop: 10 }}
              contentWrapperStyle={{ maxWidth: 450 }}
              buttonWrapperStyle={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
              }}
            />
          ) : null}
          {/* {!appContext.appState.loggedIn ? (
            <SugnUpComponent
              // signUpFormWrapperStyle={{
              //   padding: 20,
              //   display: "flex",
              //   flexDirection: "column",
              //   justifyContent: "flex-start",
              //   alignItems: "flex-start",
              //   maxWidth: 420,
              //   width: "100%",
              //   height: 480,
              // }}
              signUpButtonStyle={{ alignSelf: "center", width: 175 }}
              contentWrapperStyle={{ maxWidth: 500 }}
              buttonWrapperStyle={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
              }}
              reghash={token}
            />
          ) : null} */}
        </Grid>
      </Grid>
    </DreamPage>
  );
};

export default HomeComponent;
