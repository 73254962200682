import React, { useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useImmerReducer } from "use-immer";
import { useTranslation } from "react-i18next";
import * as Constants from "../../helpers/Constants";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import InfoIcon from "@material-ui/icons/Info";
import * as JpEvHelper from "./JobPreferenceEmployerViewHelper";
import * as JpEvReducer from "./JobPreferenceEmployerViewReducer";
import DreamPage from "../../ui_components/DreamPage";
import DreamAccordion from "../../ui_components/DreamAccordionComponent/DreamAccordionComponent";
import Axios from "axios";
import DispatchContext from "../../Context/DispatchContext";
import DreamChat from "../../ui_components/DreamChat/DreamChat";
import { useParams } from "react-router-dom";
import * as ProfileHelper from "../../helpers/Functions/GetProfileData/GetProfileData";
import JpevDispatchContext from "./Context/DispatchContext";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    borderBottom: "none" + "!important",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  tableContainer: {
    maxWidth: 450,
    width: "100%",
    // height: 500,
  },
  table: { tableLayout: "fixed" },
  title: {
    fontSize: 18,
    fontWeight: "bold",
    flexGrow: 1,
  },
  tableRow: {
    display: "flex",
    flexWrap: "wrap",
  },
  tableCellLabel: {
    textAlign: "right",
    borderBottom: "none",
    fontWeight: "bold",
    padding: 8,
  },
  tableCell: {
    textAlign: "left",
    borderBottom: "none",
    padding: 8,
  },
}));

export default function JobPreferenceEmployerView() {
  const classes = useStyles();
  const [t] = useTranslation(Constants.TRANSLATION_COMMON);
  const appDispatch = useContext(DispatchContext);

  const { eID, jpID } = useParams();

  const [state, dispatch] = useImmerReducer(
    JpEvReducer.reducer,
    JpEvReducer.originalState
  );

  const accordionItems = [
    {
      heading: "jocv.summary",
      secondaryHeading: "",
      mainIcon: <InfoIcon />,
      mainContent: <PreferencesComponent />,
    },
  ];

  function PreferencesComponent() {
    return state.candidatePreferences.map((preference, index) => (
      <TableContainer key={index} className={classes.tableContainer}>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          aria-label="enhanced table"
        >
          <TableHead>
            <TableRow className={classes.tableRow}>
              <TableCell className={classes.title} align="center" colSpan={12}>
                {`${preference.preference[2]}`}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {JpEvHelper.preferenceLabels.map((label, index) => (
              <TableRow key={index} className={classes.tableRow}>
                <TableCell
                  align="right"
                  colSpan={12}
                  className={classes.tableCellLabel}
                >
                  <span>{t(label)}</span>
                </TableCell>
                <TableCell
                  align="left"
                  colSpan={12}
                  className={classes.tableCell}
                >
                  {JpEvHelper.populatePreferenceData(
                    index,
                    preference.preference,
                    t
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    ));
  }

  useEffect(() => {
    const ourRequest = Axios.CancelToken.source();

    JpEvHelper.getCandidatePreference(
      jpID,
      Axios,
      ourRequest,
      appDispatch,
      dispatch
    );

    ProfileHelper.getProfileData(
      eID,
      Axios,
      ourRequest,
      appDispatch,
      dispatch,
      t
    );

    return () => {
      ourRequest.cancel();
    };
  }, []);

  return (
    <JpevDispatchContext.Provider value={dispatch}>
      <DreamPage
        title={"jocv.conversationWithEmployer"}
        backButton
        backButtonDisabled={!state.preferencesDataLoaded}
        // backButtonStyle={{ position: "absolute", left: 300, top: 0 }}
        backButtonTo={`/job-preference-employers-with-messages/${jpID}`}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            flexFlow: "row wrap",
            justifyContent: "space-around",
            paddingBottom: 10,
          }}
        >
          <DreamAccordion
            accordionWrapperStyle={{
              maxWidth: 450,
              height: "fit-content",
              paddingBottom: 10,
            }}
            dispatchContexName={"jpevDispatch"}
            accordionItems={accordionItems}
          />
          <DreamChat
            dataLoaded={state.preferencesDataLoaded}
            jpId={state.preferenceId}
            eId={eID}
            image={state.employerProfile.pictureUrl}
            name={`${state.employerProfile.firstName} ${state.employerProfile.lastName}`}
          />
        </div>
      </DreamPage>
    </JpevDispatchContext.Provider>
  );
}
