import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";
import { useImmerReducer } from "use-immer";
import * as RangeSliderReducer from "./DreamRangeSliderReducer";
import { useTranslation } from "react-i18next";
import * as Constatnts from "../../helpers/Constants";

export default function RangeSlider(props) {
  const [t] = useTranslation(Constatnts.TRANSLATION_COMMON);

  const [state, dispatch] = useImmerReducer(
    RangeSliderReducer.reducer,
    RangeSliderReducer.originalState
  );

  function getValueText(value) {
    if (props.getValueText) {
      return props.getValueText(value);
    }
    return `${value}`;
  }

  const handleChange = (event, newValue) => {
    dispatch({ type: "setRange", value: newValue });
    if (props.onSlideChange) {
      props.onSlideChange(newValue);
    }
  };

  return (
    <Grid container style={{ width: "100%", paddingRight: 20 }}>
      <Typography id="range-slider" gutterBottom>
        {t(props.label)}
      </Typography>
      <Slider
        value={props.rangeValue}
        min={props.minValue}
        max={props.maxValue}
        onChange={handleChange}
        valueLabelDisplay="auto"
        aria-labelledby="range-slider"
        getAriaValueText={getValueText}
        disabled={props.sliderDisabled}
        step={props.sliderStep}
      />
    </Grid>
  );
}
