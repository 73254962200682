import React, { useContext } from "react";
import { useHistory } from "react-router-dom";

import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import StateContext from "../../Context/StateContext";
import { useTranslation } from "react-i18next";
import * as Constants from "../../helpers/Constants";
import Link from "@material-ui/core/Link";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import * as Colors from "../../styles/01.settings/01-settings-colors.module.scss";
import Feedback from "../../ui_components/FeedbackComponent/FeedbackComponent";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";

function FooterComponent(props) {
  const { naviItems } = props;
  const appContext = useContext(StateContext);

  const [t] = useTranslation(Constants.TRANSLATION_COMMON);
  const history = useHistory();
  const theme = useTheme();
  const matchesSMdown = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesXSdown = useMediaQuery(theme.breakpoints.down("xs"));

  const useStyles = makeStyles((theme) => ({
    rootXsUp: {
      width:
        appContext.appState.loggedIn && !matchesSMdown
          ? "calc(100% - 240px)"
          : "100%",
      flexWrap: "wrap",
      position: "fixed",
      zIndex: 100,
      right: "0",
      bottom: "0",
      backgroundColor: Colors.primary,
      height: 64,
    },
    rootXsDown: {
      width: "100%",
      justifySelf: "flex-end",
      flexWrap: "wrap",
      marginTop: "auto", // footer moves down
      right: "0",
      backgroundColor: Colors.primary,
      height: 171,
    },
    phantom: {
      width: "100%",
      display: "block",
      padding: "20px",
      height: 64,
    },
    actionRoot: {
      // display: "flex",
      // flexGrow: matchesXSdown ? 1 : 0,
      // justifyContent: "center",
      padding: matchesXSdown ? "0px 2px 0px" : "6px 12px 8px",
      minWidth: matchesXSdown ? "100%" : 80,
    },
    wrapper: {
      display: "flex",
      flexDirection: "column",
      // alignItems: matchesXSdown ? "flex-start" : "center",
      alignItems: "center",
      justifyContent: "center",
      paddingLeft: 10,
    },
    label: {
      fontSize: "1em", // matchesXSdown ? 10 : 14,
      color: Colors.white,
    },
    selected: {
      fontSize: "1.2em", //matchesXSdown ? "11px !important" : "15px !important",
    },
    actionSocialButtons: {
      height: 30,
      width: 50,
      alignSelf: "center",
      cursor: "default",
      // marginBottom: matchesXSdown && 5,
    },
    socialIcons: {
      //paddingTop: 0,
    },
  }));

  const classes = useStyles();

  const handleChange = (event, newSelected) => {
    // let route = newSelected.slice(0);
    history.push(newSelected);
  };

  const content = (
    <BottomNavigation
      value={appContext.appState.pageSelected}
      onChange={handleChange}
      className={matchesXSdown ? classes.rootXsDown : classes.rootXsUp}
      style={props.footerStyle}
    >
      {naviItems.map((item, index) => (
        <BottomNavigationAction
          showLabel={item.showLabel}
          classes={{
            label: classes.label,
            root: classes.actionRoot,
            selected: classes.selected,
            wrapper: classes.wrapper,
          }}
          key={index}
          label={t(item.label)}
          icon={item.icon}
          value={item.link}
        />
      ))}
      <BottomNavigationAction
        component="div"
        disableTouchRipple
        classes={{
          root: classes.actionSocialButtons,
          iconOnly: classes.socialIcons,
          wrapper: classes.wrapper,
        }}
        icon={
          <div>
            <Link
              style={{ marginRight: 10, height: 23 }}
              href="https://www.facebook.com/JobPreference-100395031929989"
              target="_blank"
            >
              <FacebookIcon htmlColor={Colors.white} />
            </Link>
            <Link
              style={{ height: 23 }}
              href="https://twitter.com/JobPreference"
              target="_blank"
            >
              <TwitterIcon htmlColor={Colors.white} />
            </Link>
          </div>
        }
      />
      {appContext.appState.loggedIn && <Feedback showImageUpload />}
    </BottomNavigation>
  );

  return !matchesXSdown ? (
    <div>
      <div className={classes.phantom}></div>
      {content}
    </div>
  ) : (
    content
  );
}

export default FooterComponent;
