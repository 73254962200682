import FormValidator from "../../../helpers/FormValidator";

export const allInputs = {
  password: {
    value: "",
    isValid: true,
    errorMessage: "",
  },
  passwordConfirm: {
    value: "",
    isValid: true,
    errorMessage: "",
  },
};

export const originalState = {
  fields: { ...allInputs },
  hasValidationErrs: false,
  formSubmittedCount: 0,
  formSuccessfullySubmittedCount: 0,
};

export function reducer(draft, action) {
  switch (action.type) {
    case "passwordChange":
      draft.fields.password.value = action.value.value;
      draft.fields.password.errorMessage = FormValidator.validateField(
        action.value.value,
        {
          required: false,
          minLength: 8,
          maxLength: 50,
          password: true,
        },
        action.value.t
      );

      draft.fields.password.isValid = !Boolean(
        draft.fields.password.errorMessage
      );

      draft.fields.passwordConfirm.errorMessage = FormValidator.validateField(
        draft.fields.passwordConfirm.value,
        {
          required: Boolean(draft.fields.password.value !== ""),
          confirmPassword: Boolean(draft.fields.password.value !== ""),
        },
        action.value.t,
        action.value.value
      );

      draft.fields.passwordConfirm.isValid = !Boolean(
        draft.fields.passwordConfirm.errorMessage
      );

      // Check if Form has validation errors
      draft.hasValidationErrs = FormValidator.hasFormValidationErrors(
        draft,
        allInputs
      );
      return;

    case "passwordConfirmChange":
      draft.fields.passwordConfirm.value = action.value.value;
      draft.fields.passwordConfirm.errorMessage = FormValidator.validateField(
        action.value.value,
        {
          required: Boolean(draft.fields.password.value !== ""),
          confirmPassword: Boolean(draft.fields.password.value !== ""),
        },
        action.value.t,
        draft.fields.password.value
      );

      draft.fields.passwordConfirm.isValid = !Boolean(
        draft.fields.passwordConfirm.errorMessage
      );
      // Check if Form has validation errors
      draft.hasValidationErrs = FormValidator.hasFormValidationErrors(
        draft,
        allInputs
      );
      return;

    case "setPasswordConfirmIsValid":
      draft.fields.passwordConfirm.isValid = action.value;
      return;

    case "passwordConfirmMessageSetEmpty":
      draft.fields.passwordConfirm.errorMessage = "";
      return;
    case "formSubmitted":
      draft.hasValidationErrs = FormValidator.hasFormValidationErrors(
        draft,
        allInputs
      );
      if (!draft.hasValidationErrs) {
        draft.formSubmittedCount++;
      }
      return;
    case "formSuccessfullySubmitted":
      draft.formSuccessfullySubmittedCount++;
    case "disableSubmitButton":
      draft.hasValidationErrs = action.value;
      return;
    case "resetPassword":
      draft.fields.password.value = "";
      draft.fields.password.isValid = true;
      draft.fields.password.errorMessage = "";
      return;
    case "resetPasswordConfirm":
      draft.fields.passwordConfirm.value = "";
      draft.fields.passwordConfirm.isValid = true;
      draft.fields.passwordConfirm.errorMessage = "";
      return;
  }
}
