import * as Constants from "../../helpers/Constants";
import * as FileUploaderHelper from "../../ui_components/DreamFileUploader/DreamFileUploader/FileUploaderHelper";

export const USER_PROFILE_PICTURE = 1;
export const COMPANY_PROFILE_LOGO = 2;

/**
 * Get the profile data
 * @param {IterationCompositeOperation} userId
 * @param {Axios} Axios
 * @param {Axios} ourRequest
 * @param {appDispatch} appDispatch
 * @param {dispatch} dispatch
 * @param {t} t
 */
export async function getProfileData(
  userId,
  Axios,
  ourRequest,
  appDispatch,
  dispatch,
  t
) {
  dispatch({ type: "disableSubmitButton", value: true });
  let errMsgs;
  let authenticated = true;
  try {
    const response = await Axios.get(`/user/${userId}`, {
      cancelToken: ourRequest.token,
    }).catch(function (error) {
      // Errors to be sent to scanckbar
      errMsgs = "basic.serverError";
      if (
        error.response &&
        error.response.status &&
        error.response.status === Constants.ERROR_RESPONSE_STATUS
      ) {
        authenticated = false;
        errMsgs = "basic.unauthenticated";
      }
      throw errMsgs;
    });

    const responseData = response.data.data;
    dispatch({ type: "disableSubmitButton", value: false });
    handleSuccessfulGetData(responseData, appDispatch, dispatch);
  } catch (error) {
    appDispatch({ type: "showBackdrop", value: false });
    appDispatch({
      type: "showMsg",
      value: { status: Constants.SNACKBAR_ERROR, msg: error },
    });
    if (!authenticated) {
      appDispatch({
        type: "logout",
      });
      appDispatch({ type: "openLoginModal", value: true });
    } else {
      dispatch({ type: "disableSubmitButton", value: false });
    }
  }
}

/**
 * Successful got a data from the API
 * @param {Object} data
 * @param {appDispatch} appDispatch
 * @param {dispatch} dispatch
 */
export function handleSuccessfulGetData(data, appDispatch, dispatch) {
  appDispatch({ type: "showBackdrop", value: false });
  dispatch({ type: "userDataLoaded", value: data });
  appDispatch({
    type: "setUserAvatar",
    value: data.logo ? data.logo : data.picture,
  });
}

/**
 * Update the user profile profile OR company data
 * @param {int} userId
 * @param {Object} state
 * @param {int} imageType 1 profile picture, 2 company logo
 * @param {Axios} Axios
 * @param {Axios} ourRequest
 * @param {appDispatch} appDispatch
 * @param {dispatch} dispatch
 * @param {t} t
 */
export async function updateProfile(
  userId,
  state,
  imageType,
  Axios,
  ourRequest,
  appDispatch,
  dispatch,
  t
) {
  dispatch({ type: "disableSubmitButton", value: true });
  let errMsgs;
  let authenticated = true;

  try {
    let formData = new FormData();

    switch (imageType) {
      case USER_PROFILE_PICTURE:
        const bdate = state.fields.birthDate.value
          ? `${state.fields.birthDate.value.getFullYear()}-${
              state.fields.birthDate.value.getMonth() + 1
            }-${state.fields.birthDate.value.getDate()}`
          : null;

        formData.append("fname", state.fields.firstname.value);
        formData.append("lname", state.fields.lastname.value);
        formData.append("bdate", bdate);
        formData.append(
          "phone",
          state.fields.phone.value !== "" ? state.fields.phone.value : ""
        );
        formData.append("image_type", imageType);
        break;
      case COMPANY_PROFILE_LOGO:
        formData.append("oname", state.fields.companyName.value);
        formData.append("location_id", state.location.id);
        formData.append("location_type", state.location.location_type);
        formData.append("url", state.fields.companyWebsite.value);
        formData.append("description", state.fields.companyDescription.value);
        formData.append("industry", state.industryId);
        formData.append("image_type", imageType);
        break;
      default:
        break;
    }

    FileUploaderHelper.formatRequestData(
      state.uploadedFiles.uploadedFiles,
      formData
    );

    const response = await Axios.post(`/user/${userId}`, formData, {
      headers: {
        "content-type": `multipart/form-data; boundary=${formData._boundary}`,
      },
      cancelToken: ourRequest.token,
    }).catch(function (error) {
      // Errors to be sent to scanckbar
      errMsgs = t("basic.serverError");
      if (
        error.response &&
        error.response.status &&
        error.response.status === Constants.ERROR_RESPONSE_STATUS
      ) {
        authenticated = false;
        errMsgs = t("basic.unauthenticated");
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
      throw errMsgs;
    });

    const responseData = response.data.data;

    let errsArr = [];
    if (!responseData.success && responseData.messages.length) {
      responseData.messages.forEach((msg) => {
        errsArr.push(`serverErrors.${msg.code}`);
      });
      throw errsArr;
    }
    dispatch({ type: "disableSubmitButton", value: false });
    handleSuccessfulUpdated(imageType, appDispatch);
    dispatch({ type: "formSuccessfullySubmitted" });
  } catch (error) {
    appDispatch({ type: "showBackdrop", value: false });
    appDispatch({
      type: "showMsg",
      value: { status: Constants.SNACKBAR_ERROR, msg: error },
    });
    dispatch({ type: "disableSubmitButton", value: false });

    if (!authenticated) {
      appDispatch({
        type: "logout",
      });

      appDispatch({ type: "openLoginModal", value: true });
    } else {
      dispatch({ type: "disableSubmitButton", value: false });
    }
  }
}

/**
 * Successfuly updated
 * @param {appDispatch} appDispatch
 */
function handleSuccessfulUpdated(imageType, appDispatch) {
  appDispatch({ type: "showBackdrop", value: false });
  switch (imageType) {
    case USER_PROFILE_PICTURE:
      appDispatch({ type: "setIsProfileDataFilled", value: true });
      localStorage.setItem("UserProfileDataFilled", true);
      break;
    case COMPANY_PROFILE_LOGO:
      appDispatch({ type: "setIsCompanyProfileDataFilled", value: true });
      localStorage.setItem("CompanyProfileDataFilled", true);
      break;
  }
  appDispatch({
    type: "showMsg",
    value: {
      status: Constants.SNACKBAR_SUCCESS,
      msg: "basic.saved",
    },
  });
}

/**
 * Handle Submit
 * @param {e} e
 * @param {dispatch} dispatch
 */
export function handleSubmit(e, dispatch) {
  e.preventDefault();
  dispatch({ type: "formSubmitted" });
}

// Validate Date of birth
export function dobValidate(fieldValue) {
  let isValid = true;
  let today = new Date();
  let nowyear = today.getFullYear();
  let nowmonth = today.getMonth();
  let nowday = today.getDate();

  let birth = new Date(fieldValue);

  let birthyear = birth.getFullYear();
  let birthmonth = birth.getMonth();
  let birthday = birth.getDate();

  let age = nowyear - birthyear;
  let age_month = nowmonth - birthmonth;
  let age_day = nowday - birthday;

  if (!fieldValue) {
    isValid = false;
    return isValid;
  }
  if (age > 150) {
    isValid = false;
    return isValid;
  }
  if (age_month < 0 || (age_month == 0 && age_day < 0)) {
    age = parseInt(age) - 1;
  }
  if ((age == 18 && age_month <= 0 && age_day <= 0) || age < 18) {
    isValid = false;
    return isValid;
  }
  return isValid;
}
