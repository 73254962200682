export const initialState = {
  isAccordionExpanded: false,
};

export function reducer(draft, action) {
  switch (action.type) {
    case "setAccordionExpanded":
      draft.isAccordionExpanded = action.value;
      break;
  }
}
