import React, { useContext, useEffect } from "react";
import Axios from "axios";
import { useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import DispatchContext from "../../../Context/DispatchContext";
import StateContext from "../../../Context/StateContext";
import * as AppRoutes from "../../../AppComponent/AppRoutes";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import { useImmerReducer } from "use-immer";
import * as Constants from "../../../helpers/Constants";
import DreamPage from "../../../ui_components/DreamPage";
import DreamButton from "../../../ui_components/DreamButton/DreamButton";
import DreamConfirmModal from "../../../ui_components/DreamConfirmModalComponent/DreamConfirmModalComponent";
import * as DeleteAccountHelper from "./DeleteAccountHelper";
import * as DeleteAccountReducer from "./DeleteAccountReducer";

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    marginTop: "5em",
  },
  gridItem: {
    minWidth: 450,
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: 450,
    width: "100%",
    minHeight: 135,
    paddingLeft: 10,
    paddingRight: 10,
    boxShadow: "0px 10px 10px rgba(0, 0, 0, 0.25)",
    borderRadius: 15,
  },
  inputField: {
    maxWidth: 330,
    width: "100%",
  },
}));

const DeleteAccount = () => {
  const classes = useStyles();
  const appDispatch = useContext(DispatchContext);
  const appContext = useContext(StateContext);
  const [t] = useTranslation(Constants.TRANSLATION_COMMON);
  const history = useHistory();
  const [state, dispatch] = useImmerReducer(
    DeleteAccountReducer.reducer,
    DeleteAccountReducer.originalState
  );

  useEffect(() => {
    dispatch({ type: "setDeleteId", value: appContext.appState.user_id });
  }, []);

  const handleClickModalOpen = () => {
    dispatch({ type: "setModalOpen", value: true });
  };

  const handleModalClose = () => {
    dispatch({ type: "setModalOpen", value: false });
  };

  return (
    <DreamPage
      title={t("accountSettings.deleteAccount")}
      backButton
      backButtonTo={AppRoutes.ROUTE_ACCOUNT_SETTINGS}
    >
      <DreamConfirmModal
        isOpen={state.isModalOpen}
        title=""
        handleClose={handleModalClose}
        buttonCancelStyle={{ minWidth: 130 }}
        buttonConfirmStyle={{ minWidth: 130 }}
        buttonConfirmKind="contained"
        buttonConfirmColor="danger"
        delimeterHeight={30}
        firstRow={"accountSettings.deleteModalTextFirstRow"}
        secondRow={"accountSettings.deleteModalTextSecondRow"}
        handleCancel={handleModalClose}
        buttonCancelLabel={"basic.cancel"}
        handleConfirm={(e) => {
          DeleteAccountHelper.handleDelete(
            state.userId,
            Axios,
            appDispatch,
            dispatch
          );
          return history.replace("/");
        }}
        buttonConfirmLabel={"basic.confirm"}
      />
      <Grid
        container
        spacing={3}
        direction="column"
        classes={{ root: classes.gridContainer }}
      >
        <Grid item classes={{ root: classes.gridItem }}>
          <Paper elevation={3} className={classes.paper}>
            <Typography>{t("accountSettings.deletePageMessage")}</Typography>
          </Paper>
        </Grid>
        <Grid item classes={{ root: classes.gridItem }}>
          <DreamButton
            buttonType="button"
            dreamButtonStyle={{
              minWidth: 130,
              marginTop: "2em",
              boxShadow: "0px 10px 10px rgba(0, 0, 0, 0.25)",
            }}
            handleClick={handleClickModalOpen}
            color="danger"
            kind="contained"
            buttonLabel="basic.delete"
          ></DreamButton>
        </Grid>
      </Grid>
    </DreamPage>
  );
};

export default DeleteAccount;
