import React, { useEffect, useContext } from "react";
import DreamRadioButtonsComponent from "../../ui_components/DreamRadioButtons/DreamRadioButtonsComponent";
import * as SalaryRangeHelper from "./SalaryRangeHelper";
import JobPreferenceDispatchContext from "../../Pages/CreateJobPreferencePage/Context/JobPreferenceDispatchContext";
import JobPreferenceStateContext from "../../Pages/CreateJobPreferencePage/Context/JobPreferenceStateContext";
import DreamRangeSlider from "../../ui_components/DreamRangeSlider/DreamRangeSlider";
import DreamSelectComponent from "../../ui_components/DreamSelect/DreamSelectComponent";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import * as Constants from "../../helpers/Constants";
import { useTranslation } from "react-i18next";
import { SliderWrapper } from "./SalaryRangeComponent.module.scss";

function SalaryRangeComponent(props) {
  const jobPreferenceDispatchContext = useContext(JobPreferenceDispatchContext);
  const jobPreferenceStateContext = useContext(JobPreferenceStateContext);
  const [t] = useTranslation(Constants.TRANSLATION_COMMON);

  function salaryRangeTypeChanged(value) {
    jobPreferenceDispatchContext({
      type: "salaryRangeTypeChanged",
      value: parseInt(value),
    });
  }

  const handleSalaryValueChange = (newValue) => {
    jobPreferenceDispatchContext({
      type: "salaryRangeValueChanged",
      value: newValue,
    });
  };

  const handleCurrencyChange = (value) => {
    jobPreferenceDispatchContext({
      type: "salaryRangeCurrencyChanged",
      value,
    });
  };

  return (
    <Grid container style={{ paddingLeft: 10, paddingTop: 10, width: "90%" }}>
      <Grid item xs={12} style={{ paddingLeft: 30 }}>
        <DreamRadioButtonsComponent
          name={"jp.workplace"}
          value={jobPreferenceStateContext.formData.salaryRange.type}
          data={SalaryRangeHelper.salaryTypeRadioButtons}
          changeHandler={salaryRangeTypeChanged}
        />
      </Grid>
      <Grid item xs={12} style={{ paddingTop: 10, paddingBottom: 10 }}>
        <DreamRangeSlider
          label={""}
          maxValue={
            jobPreferenceStateContext.formData.salaryRange.type ==
            SalaryRangeHelper.MONTHLY
              ? 50000
              : 500
          }
          rangeValue={jobPreferenceStateContext.formData.salaryRange.salary}
          onSlideChange={handleSalaryValueChange}
          sliderStep={
            jobPreferenceStateContext.formData.salaryRange.type ==
            SalaryRangeHelper.MONTHLY
              ? 50
              : 1
          }
        />
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <TextField
          label={t("jp.salary")}
          variant={props.variant ? props.variant : "standard"}
          style={{ width: "30%" }}
          // helperText={
          //   !jobPreferenceStateContext.formData.salaryRange.type
          //     ? `${t("jo.exceed")} ${JoHelper.INITIAL_MONTHLY_MAX_SALARY} ${t(
          //         "jo.willBeSet"
          //       )} ${JoHelper.INITIAL_MONTHLY_MAX_SALARY}`
          //     : `${t("jo.exceed")} ${JoHelper.INITIAL_HOURLY_MAX_SALARY} ${t(
          //         "jo.willBeSet"
          //       )} ${JoHelper.INITIAL_HOURLY_MAX_SALARY}`
          // }
          // error={
          //   (Boolean(!jobOfferStateContext.formData.salaryRangeType) &&
          //     Boolean(
          //       [...jobOfferStateContext.formData.monthlySalaryRange][1] <
          //         [...jobOfferStateContext.formData.monthlySalaryRange][0]
          //     )) ||
          //   (Boolean(jobOfferStateContext.formData.salaryRangeType === 1) &&
          //     Boolean(
          //       [...jobOfferStateContext.formData.hourlySalaryRange][1] <
          //         [...jobOfferStateContext.formData.hourlySalaryRange][0]
          //     ))
          // }
          inputProps={{
            maxLength:
              jobPreferenceStateContext.formData.salaryRange.type ===
              SalaryRangeHelper.MONTHLY
                ? 5
                : 3,
          }}
          value={jobPreferenceStateContext.formData.salaryRange.salary}
          onChange={(e) =>
            jobPreferenceDispatchContext({
              type: "salaryRangeValueChanged",
              value: !isNaN(parseInt(e.target.value))
                ? parseInt(e.target.value)
                : 0,
            })
          }
        />
        <DreamSelectComponent
          style={{ width: "30%" }}
          optionsData={{ data: SalaryRangeHelper.currencyOptions }}
          label={"jo.currency"}
          dispatchChangeHandler={handleCurrencyChange}
          isMandatory={true}
          value={jobPreferenceStateContext.formData.salaryRange.currency}
          helperText={t("jp.salaryRangeObj.selectCur")}
        />
      </Grid>
    </Grid>
  );
}

export default SalaryRangeComponent;
