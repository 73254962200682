import * as Constants from "../../helpers/Constants";

export async function getLink(
  e,
  email,
  Axios,
  // ourRequest,
  appDispatch,
  dispatch,
  t
) {
  appDispatch({ type: "showBackdrop", value: true });

  let errMsgs;
  let getParams = {
    email: email,
  };

  e.preventDefault();
  try {
    const response = await Axios.get(
      `/user/fp`,
      { params: getParams }
      // {
      //   cancelToken: ourRequest.token,
      // }
    ).catch(function (error) {
      // Errors to be sent to scanckbar
      errMsgs = t("basic.serverError");
      if (error.response) {
        // Request made and server responded
        if (error.response.data.message) {
          errMsgs = JSON.parse(error.response.data.message);
        }
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
      throw errMsgs;
    });

    const responseData = response.data.data;

    let errsArr = [];
    if (!responseData.success && responseData.messages.length) {
      responseData.messages.forEach((msg) => {
        errsArr.push(`serverErrors.${msg.code}`);
      });

      throw errsArr;
    }

    handleSuccessfulGetData(responseData, appDispatch, dispatch, t);
  } catch (error) {
    appDispatch({ type: "showBackdrop", value: false });

    appDispatch({
      type: "showMsg",
      value: { status: Constants.SNACKBAR_ERROR, msg: error },
    });
  }
}

/**
 * Successful got a data from the API
 * @param {Object} data
 * @param {appDispatch} appDispatch
 * @param {dispatch} dispatch
 */
export function handleSuccessfulGetData(data, appDispatch, dispatch, t) {
  appDispatch({ type: "showBackdrop", value: false });
  dispatch({ type: "setSuccessfullySentCounter" });
  appDispatch({
    type: "showMsg",
    value: {
      status: Constants.SNACKBAR_SUCCESS,
      msg: t("forgottenPassword.checkEmail"),
    },
  });
}
