import * as Constants from "../../helpers/Constants";

export const rowsPerPageOptions = [5, 10, 20];

// Get Offer Data
export async function getOfferData(
  offerId,
  Axios,
  ourRequest,
  appDispatch,
  dispatch,
  t
) {
  appDispatch({ type: "showBackdrop", value: true });

  let errMsgs;
  let authenticated = true;
  try {
    const response = await Axios.get(`/dreamjob/jo/${offerId}`, {
      cancelToken: ourRequest.token,
    }).catch(function (error) {
      // Errors to be sent to snackbar
      errMsgs = "basic.serverError";
      if (
        error.response &&
        error.response.status &&
        error.response.status === Constants.ERROR_RESPONSE_STATUS
      ) {
        authenticated = false;
        errMsgs = "basic.unauthenticated";
      }
      throw errMsgs;
    });

    const responseData = response.data;

    handleSuccessfulGetOfferData(responseData, appDispatch, dispatch, t);
  } catch (error) {
    appDispatch({ type: "showBackdrop", value: false });
    appDispatch({
      type: "showMsg",
      value: { status: Constants.SNACKBAR_ERROR, msg: error },
    });
    if (!authenticated) {
      appDispatch({
        type: "logout",
      });
      appDispatch({ type: "openLoginModal", value: true });
    } else {
      dispatch({ type: "disableSubmitButton", value: false });
    }
  }
}

export function handleSuccessfulGetOfferData(data, appDispatch, dispatch, t) {
  appDispatch({ type: "showBackdrop", value: false });
  dispatch({ type: "offerDataLoaded", value: { data: data.data, t: t } });
}

// Get candidates matching current offer

export async function getCandidates(
  offerId,
  page_size,
  page,
  filter,
  Axios,
  ourRequest,
  appDispatch,
  dispatch,
  t
) {
  appDispatch({ type: "showBackdrop", value: true });

  let errMsgs;
  let authenticated = true;
  const params = { page_size: page_size, page: page + 1, filter: filter };
  try {
    const response = await Axios.get(`/dreamjob/jo/candidates/${offerId}`, {
      cancelToken: ourRequest.token,
      params: params,
    }).catch(function (error) {
      // Errors to be sent to snackbar
      errMsgs = "basic.serverError";
      if (
        error.response &&
        error.response.status &&
        error.response.status === Constants.ERROR_RESPONSE_STATUS
      ) {
        authenticated = false;
        errMsgs = "basic.unauthenticated";
      }
      throw errMsgs;
    });

    const responseData = response.data.data;

    handleSuccessfulGetCandidatesData(responseData, appDispatch, dispatch);
  } catch (error) {
    appDispatch({ type: "showBackdrop", value: false });
    appDispatch({
      type: "showMsg",
      value: { status: Constants.SNACKBAR_ERROR, msg: error },
    });
    if (!authenticated) {
      appDispatch({
        type: "logout",
      });
      appDispatch({ type: "openLoginModal", value: true });
    } else {
      dispatch({ type: "disableSubmitButton", value: false });
    }
  }
}

export function handleSuccessfulGetCandidatesData(data, appDispatch, dispatch) {
  appDispatch({ type: "showBackdrop", value: false });
  dispatch({ type: "candidatesDataLoaded", value: data });
}

export const offerLabels = [
  "jov.locations",
  "jov.industryPosition",
  "jov.workplace",
  "jov.employment",
  "jov.salary",
  "jov.workSchedule",
  "jov.start",
  "jov.benefits",
];

// Offer table

export function formatLocations(locations) {
  let locationNames = [];
  locations.forEach((loc) => {
    locationNames.push(loc.location_name);
  });
  return locationNames.join("; ");
}

export function formatIndustryPosition(data, t) {
  let formatted = [];
  let industry = "basic.all";
  let position = "basic.all";

  if (data.industry > 0) {
    industry = `industries.${data.industry}`;
  }

  if (data.position > 0) {
    position = `positions.${data.position}`;
  }

  formatted.push(industry);
  formatted.push(position);
  return formatted;
}

export function formatSalary(data) {
  let formatted = "";
  let currencySign = "";
  switch (data.salary_currency) {
    case "EUR":
      currencySign = "€";
      break;
    case "USD":
      currencySign = "$";
      break;
    case "BGN":
      currencySign = "BGN";
      break;
  }
  formatted = `${currencySign} ${
    data.salary_from != null ? data.salary_from : data.salary_hourly_from
  }-${data.salary_to != null ? data.salary_to : data.salary_hourly_to} ${
    data.salary_from != null ? "Mth" : "Hou"
  }`;
  return formatted;
}

export function setTypeLabel(types, type) {
  let label = "";
  for (let i = 0; i < types.length; i++) {
    if (types[i].value === type) {
      label = types[i].label;
      break;
    }
  }
  return label;
}

export function formatBenefits(benefits, offerData) {
  let formatted = [];
  if (offerData.paid_leave == 1) {
    formatted.push(benefits[0].label);
  }
  if (offerData.food_vouchers == 1) {
    formatted.push(benefits[1].label);
  }
  if (offerData.housing == 1) {
    formatted.push(benefits[2].label);
  }
  if (offerData.car == 1) {
    formatted.push(benefits[3].label);
  }
  if (offerData.phone == 1) {
    formatted.push(benefits[4].label);
  }
  return formatted;
}

export const populateBenefits = (benefits, t) => {
  let res = benefits.map((benefit) => t(benefit));
  return res.join(", ");
};

export function populateOfferData(index, data, t) {
  let result = "";
  switch (index) {
    case 0:
      data[index] ? (result = data[index]) : (result = t("jov.any"));
      return result;
    case 1:
      let industry = "";
      let position = "";

      if (data[index] && data[index][0] !== undefined) {
        industry = data[index][0];
      }
      if (data[index] && data[index][1] !== undefined) {
        position = data[index][1];
      }

      result = `${t(`${industry}`)} / ${t(`${position}`)}`;
      return result;
    case 2:
    case 3:
    case 4:
    case 5:
    case 6:
      result = t(data[index]);
      return result;
    case 7:
      data[index] ? (result = populateBenefits(data[index], t)) : (result = "");

      return result;
  }
}

export function splitCandidateExperience(experience) {
  let expArr = experience.split(/(?:,| )+/);
  return expArr;
}

export function populateCandidateExperience(experience, t) {
  let formatted = "";
  const yearsValue = experience[0];
  const monthsValue = experience[2];
  const daysValue = experience[4];

  formatted = `${yearsValue} ${t("period.years")}, ${monthsValue} ${t(
    "period.months"
  )}, ${daysValue} ${t("period.days")}`;
  return formatted;
}
