module.exports = `
<html>

<head>
<meta http-equiv=Content-Type content="text/html; charset=windows-1251">
<meta name=Generator content="Microsoft Word 15 (filtered)">
<style>
<!--
 /* Font Definitions */
 @font-face
	{font-family:SimSun;
	panose-1:2 1 6 0 3 1 1 1 1 1;}
@font-face
	{font-family:"Cambria Math";
	panose-1:2 4 5 3 5 4 6 3 2 4;}
@font-face
	{font-family:OpenSymbol;}
@font-face
	{font-family:"Microsoft YaHei";
	panose-1:2 11 5 3 2 2 4 2 2 4;}
@font-face
	{font-family:"\@SimSun";
	panose-1:2 1 6 0 3 1 1 1 1 1;}
@font-face
	{font-family:"\@Microsoft YaHei";}
@font-face
	{font-family:"\@OpenSymbol";}
 /* Style Definitions */
 p.MsoNormal, li.MsoNormal, div.MsoNormal
	{margin:0cm;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;}
p.MsoBodyText, li.MsoBodyText, div.MsoBodyText
	{margin-top:0cm;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:0cm;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;}
.terms a:link, span.MsoHyperlink
	{color:navy;
	text-decoration:underline;}
p.-, li.-, div.-
	{mso-style-name:"Таблица - съдържание";
	margin:0cm;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;}
@page WordSection1
	{size:595.3pt 841.9pt;
	margin:2.0cm 2.0cm 2.0cm 2.0cm;}
div.WordSection1
	{page:WordSection1;}
 /* List Definitions */
 ol
	{margin-bottom:0cm;}
ul
	{margin-bottom:0cm;}
-->
</style>

</head>

<body lang=BG link=navy vlink="#954F72" style='word-wrap:break-word;line-break:
strict'>

<div class=WordSection1>

<p class=MsoNormal><b><span style='font-size:16.0pt'>Общи условия за ползване
от работодатели</span></b><b><span style='font-size:14.0pt'> </span></b></p>

<p class=MsoNormal><b><span style='font-size:14.0pt'>&nbsp;</span></b></p>

<p class=MsoNormal>Моля, прочетете този документ внимателно. Той съдържа
Условията за достъпа до уебсайта www.<span lang=EN-US>j<span style='color:#333333'>obpreference.com</span></span><span
lang=EN-US> </span>и за използването му (&quot;Условията&quot;). Ако не
приемате Условията, посочени тук, няма възможност да използвате Сайта!<br>
<br>
Във връзка с предоставяните Услуги <span lang=EN-US>J<span style='color:#333333'>obpreference.com</span></span><span
lang=EN-US> </span>обработва лични данни на физически лица - бизнес потребители
съгласно Политиката за защита на личните данни („Политика“). Политиката е
неразделна част от тези Условия.</p>

<p class=MsoNormal><br>
<b>ОБЩИ УСЛОВИЯ ЗА ПОЛЗВАНЕ ОТ РАБОТОДАТЕЛИ</b><br>
<br>
<b>1.Регистрация</b><br>
<br>
1.1. <span style='color:#333333'>Сън Ленд Кепитъл</span>“ ООД („<span
lang=EN-US>J<span style='color:#333333'>obpreference.com</span></span>“/„Ние“/„Нас“)
с адрес на управление:<span style='color:#333333'> ул. „кдп Георги Боев” 4,  </span> Варна,
ЕИК: <span style='color:#333333'>206166575</span> предоставя чрез сайта www. <span
lang=EN-US>j<span style='color:#333333'>obpreference.com</span></span>
(“Сайта”) на юридически и физически лица с валидна регистрация в
Търговски/БУЛСТАТ регистър – Работодатели („Работодател/и“), безплатни и
платени услуги на информационното общество („Услугите“). <br>
<br>
1.2. Ползването на Услугите е възможно само след регистрация на фирмен акаунт и
неговото активиране.<br>
<br>
1.3. Регистрацията на фирмен акаунт се извършва от дееспособно лице, което
декларира, че е оторизирано да представлява Работодателя, включително да приеме
тези Условия и да извършва действия, свързани с ползването и управлението на
фирмения акаунт от името на Работодателя. В случай че създаватe регистрация,
Вие декларирате, че отговаряте на посочените условия и попълнената от Вас
информация, идентифицираща Вас и Работодателя, е пълна и точна.<br>
<br>
1.<span lang=EN-US>4</span>. Във връзка със своите задължения по Общия
регламент за защита на личните данни („Регламент (ЕС) 2016/679“) Работодателят
се задължава да попълни контакт за връзка от страна на кандидатите по въпроси,
свързани със защита на техните лични данни. Поради това че такива въпроси могат
да възникнат и след изтичане на обявите, Работодателят се задължава да поддържа
контакта актуален по всяко време, независимо от това дали има активни обяви в
Сайта. Този контакт заедно с данните, идентифициращи Работодателя, се публикува
публично в Сайта.<br>
<br>
<span lang=EN-US>1.5.</span> Работодателят и всеки негов Представител се
задължават да предоставят верни, пълни и актуални данни при регистрацията и при
всяко ползване на Услугите и да ги поддържат такива. <br>
<br>
1.<span lang=EN-US>6</span>. В допълнение на попълнените при регистрация данни,
<span lang=EN-US>J<span style='color:#333333'>obpreference.com</span></span> си
запазва правото да изиска представянето на допълнителни документи, включително
подписани (саморъчно или с електронен подпис), за допълнителна идентификация на
Работодателя и/или Представители при активирането на акаунта или по всяко време
след това при възникване на съмнения за точността и пълнотата на предоставените
данни.<br>
<br>
1.<span lang=EN-US>7</span>. Вие отговаряте за всички действия, извършени чрез
Вашия акаунт с използване на Вашето потребителско име и парола за достъп до
него. Вие се задължавате незабавно да уведомите <span lang=EN-US>J<span
style='color:#333333'>obpreference.com</span></span> в случай на неправомерен
достъп или вероятност за такъв. </p>

<p class=MsoNormal>&nbsp;</p>

<p class=MsoNormal><b>2. Сключване на договор</b><br>
<br>
2.1. С натискането на бутон „Регистрация” лицето, което представлява
Работодателя, извършва електронно изявление, с което Работодателят и
Представителят сключват договор с <span lang=EN-US>J<span style='color:#333333'>obpreference.com</span></span><span
lang=EN-US> </span>при тези Условия (“Договор”) и се задължават да ги спазват.
Въвежданата в регистрационната форма информация може да бъде променяна до
натискането на бутона „Регистрация“.<br>
<br>
2.2. Договорът се счита за сключен от момента на активиране на акаунта от
страна на <span lang=EN-US>J<span style='color:#333333'>obpreference.com.</span></span><br>
<br>
2.3. За ползването на всяка платена Услуга се извършва конкретна заявка на
базата на сключения при тези Условия договор.<br>
<br>
2.4. С приемането на тези Условия се съгласявате да бъдете адресат и да
получавате електронни изявления от <span lang=EN-US>J<span style='color:#333333'>obpreference.com</span></span>
във връзка с настоящия Договор и Услугите в Сайта.</p>

<p class=MsoNormal>&nbsp;</p>

<p class=MsoNormal><b>3. Публикуване на информация</b><br>
<br>
3.1. Вие се задължавате да използвате Сайта в съответствие с настоящите
Условия, допълнителните условия и напътствия, приложими за отделните Услуги,
публикувани на съответните страници в Сайта, и действащото българско
законодателство.<br>
<br>
3.2. Вие се задължавате да предлагате само реални и актуални позиции.<br>
<br>
3.3. Не се допуска  промотиране на бизнес възможности, партньорства, продукти,
услуги или сайтове, изисквания за присъединяване към страници в социални медии,
регистрации, участие в игри, пирамидални схеми и др. Вие се съгласявате, че от
Кандидатите не се изисква под каквато и да е форма закупуването на обучения или
други услуги.<span style='color:black;background:transparent'><br>
<br>
</span>3.4. Вашето предложение не може да съдържа:<br>
(а) изисквания с дискриминативен характер;<br>
(б) материали, които нарушават или не зачитат права или законни интереси на
трети лица, вкл. основни човешки права, права на интелектуална собственост и
др.;<br>
(в) или каквото и да е съдържание с незаконен, дискриминативен, нецензурен,
обиден, заплашителен, клеветнически, омразен или неприличен характер или
такова, което би могло да постави в неудобно положение или да оскърби трето
лице.<span style='color:black;background:transparent'><br>
<br>
</span>3.5. <span lang=EN-US>J<span style='color:#333333'>obpreference.com</span></span>
запазва правото си да отстрани или поиска редактиране на публикувано от Вас
съдържание, неотговарящо на настоящите Условия или на публикуваните в Сайта
допълнителни условия и напътствия.<span style='color:black;background:transparent'><br>
<br>
</span>3.6. Вие гарантирате, че имате право да използвате всяко съдържание,
което публикувате в Сайта. В случай че това не е така, носите отговорност за
всички вреди, които произтекат от това за <span lang=EN-US>J<span
style='color:#333333'>obpreference.com</span></span> или за трети лица.<span
style='color:black;background:transparent'><br>
<br>
3.7.<b> Вие давате право на </b></span><b><span lang=EN-US style='color:black;
background:transparent'>J</span></b><b><span lang=EN-US style='color:#333333;
background:transparent'>obpreference.com</span></b><b><span style='color:black;
background:transparent'> да направи достъпни (както за България, така и извън
България) публикуваните от Вас предложения и друго публично съдържание, като
това е с цел предоставяне на Услугите и </span></b><b><span lang=EN-US
style='color:black;background:transparent'>J</span></b><b><span lang=EN-US
style='color:#333333;background:transparent'>obpreference.com</span></b><b><span
style='color:black;background:transparent'> не дължи възнаграждение.</span></b><br>
<br>
<b><span style='color:black;background:transparent'>3.8. С публикуването на
съдържание в Сайта Вие предоставяте на неговите потребители право да
осъществяват достъп до него и да го ползват съгласно Условията за ползване от
физически лица и съгласно закона.</span></b><span style='color:black;
background:transparent'><br>
<br>
</span>3.9. <span lang=EN-US>J<span style='color:#333333'>obpreference.com</span></span>
си запазва правото да откаже, да спре временно или да прекрати предоставянето
на Услуга:<br>
(а) при нелоялно поведение от страна на Работодателя (напр. публикуване на
неверни или подвеждащи предложения; препращане към конкурентни сайтове и др.
под.);<br>
(б) при установяване на извършване на аналогични на извършваните чрез настоящия
Сайт дейности;<br>
(в) при подозрения за нарушаване на тези Условия или на закона.<br>
<span style='color:black;background:transparent'><br>
<br>
</span>3.10. С цел гарантиране надеждността и сигурността на Услугата, <span
lang=EN-US>J<span style='color:#333333'>obpreference.com</span></span> запазва
правото си да потърси съдействие от компетентните органи при груби нарушения на
тези Условия. </p>

<p class=MsoNormal>&nbsp;</p>

<p class=MsoNormal><b>4. Употреба на съдържанието</b><br>
<br>
4.1. Сайтът и съдържанието в него са собственост на <span lang=EN-US>J<span
style='color:#333333'>obpreference.com</span></span>  и/или на неговите
партньори, доставчици и клиенти. Нямате право да използвате по какъвто и да е
начин, да копирате, модифицирате или разпространявате съдържание, което не Ви
принадлежи за цели, различни от или несъвместими с ползването на Услугите и с
предназначението на Сайта, без изричното предварително писмено съгласие на <span
lang=EN-US>J<span style='color:#333333'>obpreference.com</span></span>  или на
съответните трети лица.<br>
<br>
4.2. Публикуваните контакти на Работодатели и на техни Представители могат да
бъдат използвани единствено по предназначение. Вие се задължавате да не
използвате тези контакти по никакъв друг повод, включително, но не само, за
маркетингови и промоционални цели.<br>
<br>
4.3. Вие се задължавате да не смущавате нормалната работа на техническите или
софтуерни приложения и компютърни системи на <span lang=EN-US>J<span
style='color:#333333'>obpreference.com</span></span> или на потребителите на
Сайта чрез недобросъвестни действия.<br>
<br>
<b>5. Обработване на лични данни</b><br>
<br>
Въпросите, свързани с обработване на лични данни във връзка с предоставяне на
Услугите, са уредени в Споразумението за обработване на лични данни, неразделна
част от настоящия Договор.</p>

<p class=MsoNormal>&nbsp;</p>

<p class=MsoNormal><b>6. Финансови условия</b><br>
<br>
6.1. Цените на платените Услуги се изчисляват автоматично в зависимост от
избраните параметри и съгласно публикуваните на Сайта цени.<br>
<br>
6.2. Финансовите условия и цените могат да бъдат изменяни едностранно от <span
lang=EN-US>J<span style='color:#333333'>obpreference.com</span></span>. Такива
промени не засягат условията за предоставяне на вече заявени и потвърдени от <span
lang=EN-US>J<span style='color:#333333'>obpreference.com</span></span> Услуги.<br>
<br>
6.3. Заплащането на Услугите се извършва по  посочените в Сайта начини . Всички
фактури и други финансови документи са достъпни в акаунта на Работодателя в
Сайта. <br>
<br>
6.4. След потвърждение на поръчка за Услуга от <span lang=EN-US>J<span
style='color:#333333'>obpreference.com</span></span>, същата не може да бъде
прекратена от страна на Работодателя. В тези случаи се дължи заплащане на
цената за заявената Услуга в пълен размер и заплатени суми не се възстановяват.<br>
<br>
6.5. <span lang=EN-US>J<span style='color:#333333'>obpreference.com</span></span>
си запазва правото да откаже предоставянето на  Услуга, при несъответствие с
Условията и/или по своя преценка.<br>
<br>
6.6. Пакети. Пакет с кредити може да бъде използван наведнъж или на части за
поръчка на Услуги в Сайта. В случай че пакетът не е бил използван изцяло,
неизползваната част не се възстановява. При поръчка на Услуга с кредити от
пакет от наличните кредити се изваждат кредити, равняващи се на цената на
Услугата (в кредити), посочена в ценовата листа, към момента на закупуване на
съответната Услуга.</p>

<p class=MsoNormal>&nbsp;</p>

<p class=MsoNormal><b>7. Отговорност и прекратяване</b><br>
<br>
7.1. <span lang=EN-US>J<span style='color:#333333'>obpreference.com</span></span>
може да блокира Вашия акаунт при:<br>
(а) съмнения за нарушаване на закона, на тези Условия или на други условия,
предвидени в Сайта;<br>
(б) при заявка за това от Ваша страна.<br>
<br>
7.2. <span lang=EN-US>J<span style='color:#333333'>obpreference.com</span></span>
може да прекрати настоящия Договор и Вашия акаунт:<br>
(а) при дълъг период на неизползване на акаунта за повече от 3 /три/ години;<br>
(б) с разумно предизвестие в случай на прекратяване дейността на Сайта;<br>
(в) в други предвидени в Условията или закона случаи.<br>
<br>
7.3 <span lang=EN-US>J<span style='color:#333333'>obpreference.com</span></span>
не носи отговорност за претърпени вреди и пропуснати ползи от Вас или от трети
лица вследствие на изпълнение на разпореждания на компетентните държавни
органи.<br>
<br>
7.4. Освен при умисъл или при груба небрежност, <span lang=EN-US>J<span
style='color:#333333'>obpreference.com</span></span> не отговоря за вреди и
пропуснати ползи, настъпили:<br>
(а) в резултат на ползване или невъзможност за ползване на Сайта и/или
Услугите;<br>
(б) поради ограничаване, прекратяване или блокиране на Вашия акаунт и/или на
Ваше съдържание в съответствие с настоящите Условия.<br>
<br>
7.5. Във всички случаи отговорността на <span lang=EN-US>J<span
style='color:#333333'>obpreference.com</span></span> във връзка с предоставена
Услуга в Сайта е ограничена до размера на заплатената от Вас цена за
конкретната Услуга.</p>

<p class=MsoNormal>&nbsp;</p>

<p class=MsoNormal><b>8. Промени в Условията и предлаганите Услуги</b><br>
<br>
8.1. Доколкото предоставяните от <span lang=EN-US>J<span style='color:#333333'>obpreference.com</span></span>
Услуги са разнообразни и постоянно развивани и допълвани, както и във връзка
със законодателни промени, <span lang=EN-US>J<span style='color:#333333'>obpreference.com</span></span>
си запазва правото да добавя нови Услуги, да променя или премахва съществуващи
Услуги, без за това да е необходимо предупреждение.<br>
<br>
8.2. При извършване на промени в Условията, <span lang=EN-US>J<span
style='color:#333333'>obpreference.com</span></span> довежда тези промени до
Ваше знание чрез публикуването им на видно място в Сайта или в акаунта Ви.
Промени в Условията няма да засягат предоставянето на заявени и заплатени преди
промяната Услуги.<br>
<br>
8.3. В случай че използвате Услуги след влизане в сила на промените в
Условията, те се считат за обвързващи за Вас.<br>
<br>
8.4. В случай на сливане, придобиване или продажба на активи си запазваме
правото на прехвърляне на правата и задълженията по тези Условия, като за това
Вие ще бъдете уведомени предварително.</p>

<p class=MsoNormal>&nbsp;</p>

<p class=MsoNormal><b>9. Други условия</b><br>
<br>
9.1. Тези общи Условия уреждат взаимоотношенията между страните. Допълнителни
споразумения могат да се сключват само чрез предвидените функционалности в
Сайта или чрез подписване от двете страни на изрично писмено споразумение,
предоставено от <span lang=EN-US>J<span style='color:#333333'>obpreference.com</span></span>.<br>
<br>
9.2. В случай че някоя от клаузите по настоящите Условия се окаже
недействителна, това няма да влече недействителност на сключените договори или
на други клаузи.<br>
<br>
9.3. <span style='color:black;background:transparent'>Договорът се сключва на
български език</span>. Споровете по тези Условия ще бъдат решавани от
компетентния български съд в град Варна.<br>
<br>
9.4. По неуредени въпроси се прилага законодателството на Република България. <br>
<br>
</p>

<p class=MsoNormal>&nbsp;</p>

<table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
 style='border-collapse:collapse'>
 <tr>
  <td width=643 style='width:481.9pt;padding:1.4pt 1.4pt 1.4pt 1.4pt'>
  <p class=-><b><span style='font-size:16.0pt'>Споразумение за обработване на
  лични данни</span></b></p>
  </td>
 </tr>
 <tr>
  <td width=643 style='width:481.9pt;padding:1.4pt 1.4pt 1.4pt 1.4pt'>
  <div style='border:none;border-bottom:solid silver 1.0pt;padding:0cm 0cm 1.0pt 0cm'>
  <p class=- style='line-height:150%;border:none;padding:0cm'><em><span
  style='font-style:normal'>Това споразумение е неразделна част от Условията. В
  случай че използвате обяви с външно кандидатстване към ATS или фирмен сайт, </span></em><em><span
  lang=EN-US style='font-style:normal'>J<span style='color:#333333'>obpreference.com</span></span></em><em><span
  style='font-style:normal'> не носи отговорност за тях.</span></em><br>
  <br>
  <b>1. Обработване на лични данни</b><br>
  <br>
  Обработване на лични данни (”Обработване”) е всяко действие или съвкупност от
  действия, които могат да се извършат по отношение на личните данни с
  автоматични или други средства, като събиране, записване, организиране,
  съхраняване, адаптиране или изменение, възстановяване, консултиране,
  употреба, разкриване или предаване, разпространяване, предоставяне,
  актуализиране или комбиниране, блокиране, заличаване или унищожаване на
  данните.</p>
  <p class=- style='line-height:150%;border:none;padding:0cm'>&nbsp;</p>
  <p class=- style='line-height:150%;border:none;padding:0cm'><b>2.
  Представители на Работодателя</b></p>
  <p class=- style='line-height:150%;border:none;padding:0cm'><br>
  2.1. С цел предоставяне на Услугите и осигуряване на достъп до тях <em><span
  lang=EN-US style='font-style:normal'>J<span style='color:#333333'>obpreference.com</span></span></em>
  обработва данни относно лицата, представляващи Работодателя при ползване на
  Услугите (“Представители”).<br>
  <br>
  2.2. <em><span lang=EN-US style='font-style:normal'>J<span style='color:#333333'>obpreference.com</span></span></em>
  обработва личните данни на лицата Представители на Работодателя в ролята на
  Администратор на лични данни съобразно Политиката за защита на личните данни
  за Работодатели,  неразделна част от тези Условия.<br>
  <br>
  2.3. При добавяне на допълнителни потребители и лица за контакт в акаунта на
  Работодателя, Вие декларирате, че имате необходимата оторизация и че сте
  информирали и запознали лицата с Политиката за защита на личните данни на <em><span
  lang=EN-US style='font-style:normal'>J<span style='color:#333333'>obpreference.com</span></span></em>,
  преди да направите това. </p>
  <p class=- style='line-height:150%;border:none;padding:0cm'>&nbsp;</p>
  <p class=- style='line-height:150%;border:none;padding:0cm'><b>3. Кандидатури
  по обяви</b><br>
  <br>
  При отключване на обява:<br>
  <br>
  3.1. Работодателят е Администратор на лични данни по отношение на получените
  кандидатури в своя акаунт.<br>
  <br>
  3.2. Сайтът е технологична платформа, осигуряваща възможност за получаване и
  съхранение на кандидатури,  като в ролята си на доставчик на тази услуга <em><span
  lang=EN-US style='font-style:normal'>J<span style='color:#333333'>obpreference.com</span></span></em>
  е Обработващ лични данни.<br>
  <br>
  <b>4. Задължения на Работодателя</b><br>
  <br>
  Като част от тези Условия, Работодателят: <br>
  <br>
  4.<span lang=EN-US>1</span>. като Администратор е отговорен за избора на
  правно основание съгласно Регламент (ЕС) 2016/679, на базата на което
  обработва получените кандидатури;<br>
  <br>
  4.<span lang=EN-US>2</span>. се задължава да осигури и носи пълна отговорност
  за зачитането и спазването на изискванията на Регламент (ЕС) 2016/679 и на
  тези Условия от своите Представители, от своя персонал и от всички други
  лица, на които би могъл да предостави данни относно Кандидатите;<br>
  <br>
  4.<span lang=EN-US>3</span>. при получаване на искане от Кандидати във връзка
  с упражняване на техните права, да изпълни исканията и/или върне мотивиран
  отговор в предвидените съгласно Регламент (ЕС) 2016/679 срокове;<br>
  <br>
  4.<span lang=EN-US>4</span>. се задължава да посочи актуални данни за контакт
  на Кандидатите с него относно защитата на личните им данни, включително за
  контакт с Длъжностно лице по защита на данните (ако е приложимо);<br>
  <br>
  4.<span lang=EN-US>5</span>. да не изисква по никакъв повод от Кандидатите 
  изпращането на специални категории данни по смисъла на чл. 9 и чл. 10 от
  Регламент (ЕС) 2016/679 посредством Сайта. Сайтът не е предназначен за
  събиране, обработване и съхраняване на такива данни. </p>
  <p class=- style='line-height:150%;border:none;padding:0cm'>&nbsp;</p>
  <p class=- style='line-height:150%;border:none;padding:0cm'><b>5. Обработване
  на лични данни с цел предоставяне на Услугите</b><br>
  <br>
  <em><span lang=EN-US style='font-style:normal'>J<span style='color:#333333'>obpreference.com</span></span></em>
  предоставя технологично решение за получаване на кандидатури и съхранението
  им във Вашия акаунт, като във връзка с тази Услуга е Обработващ лични данни
  от Ваше име.<br>
  <br>
  5.1. С приемането на настоящите Условия Вие възлагате на <em><span
  lang=EN-US style='font-style:normal'>J<span style='color:#333333'>obpreference.com</span></span></em>
  да обработва лични данни за целите на предоставяне на Услугите, предмет на
  настоящия Договор, от Ваше име, при стриктно спазване на тези Условия и
  съгласно Вашите Инструкции (т.5.3.).<br>
  <br>
  5.2. <em><span lang=EN-US style='font-style:normal'>J<span style='color:#333333'>obpreference.com</span></span></em>
  се задължава да не обработва информация относно кандидати  за цели, различни
  от определените в тези Условия, освен в случаите, когато е длъжен да направи
  това по силата на действащото законодателство или в случаите, когато е в
  ролята на Администратор.<br>
  <br>
  5.3. Инструкции за обработка на лични данни. Това са инструкциите, съдържащи
  се в настоящия Договор, както и инструкции, направени посредством наличните в
  Сайта функционалности. Вие се съгласявате, че ще подавате Вашите инструкции
  по предвидените за това в Сайта начини и че Вашите инструкции ще са в
  съответствие с Регламент (ЕС) 2016/679.<br>
  <br>
  5.4. С приемането на тези Условия декларирате, че сте информирани, че в
  определени от закона случаи <em><span lang=EN-US style='font-style:normal'>J<span
  style='color:#333333'>obpreference.com</span></span></em> може да бъде
  задължен да запази и да разкрие на компетентните органи информация, която
  обработва от Ваше име. <em><span lang=EN-US style='font-style:normal'>J<span
  style='color:#333333'>obpreference.com</span></span></em> се задължава да Ви
  информира за такива нареждания, освен в случаите, когато това е забранено от
  закона.<br>
  <br>
  <span style='color:black;background:transparent'>5.5. Обработването на личните
  данни от </span><em><span lang=EN-US style='color:black;background:transparent;
  font-style:normal'>J</span></em><em><span lang=EN-US style='color:#333333;
  background:transparent;font-style:normal'>obpreference.com</span></em><span
  style='color:black;background:transparent'>, свързано с предоставянето на
  Услугите, се осъществява на територията на Република България. </span><em><span
  lang=EN-US style='color:black;background:transparent;font-style:normal'>J</span></em><em><span
  lang=EN-US style='color:#333333;background:transparent;font-style:normal'>obpreference.com</span></em><span
  style='color:black;background:transparent'> няма да използва каквото и да е
  оборудване за обработване на информация относно Кандидатите по Ваши обяви,
  разположено извън ЕС.</span><br>
  <br>
  <span style='color:black;background:transparent'>5.6. Подизпълнители. </span><em><span
  lang=EN-US style='color:black;background:transparent;font-style:normal'>J</span></em><em><span
  lang=EN-US style='color:#333333;background:transparent;font-style:normal'>obpreference.com</span></em><span
  style='color:black;background:transparent'> използва подизпълнители (като
  колокационни центрове, в които са разположени сървъри, мрежово оборудване и
  др.). Ако е приложимо, в случай че </span><em><span lang=EN-US
  style='color:black;background:transparent;font-style:normal'>J</span></em><em><span
  lang=EN-US style='color:#333333;background:transparent;font-style:normal'>obpreference.com</span></em><span
  style='color:black;background:transparent'> работи с подизпълнители, обработващи
  лични данни на Работодателя, </span><em><span lang=EN-US style='color:black;
  background:transparent;font-style:normal'>J</span></em><em><span lang=EN-US
  style='color:#333333;background:transparent;font-style:normal'>obpreference.com</span></em><span
  style='color:black;background:transparent'> ще изисква от тях поемането на
  аналогични на поетите от </span><em><span lang=EN-US style='color:black;
  background:transparent;font-style:normal'>J</span></em><em><span lang=EN-US
  style='color:#333333;background:transparent;font-style:normal'>obpreference.com</span></em><span
  style='color:black;background:transparent'> договорни ангажименти и ниво на
  защита. Подизпълнителите ще бъдат задължени да не обработват такива данни за
  каквито и да е други цели, освен във връзка с предоставяните от </span><em><span
  lang=EN-US style='color:black;background:transparent;font-style:normal'>J</span></em><em><span
  lang=EN-US style='color:#333333;background:transparent;font-style:normal'>obpreference.com</span></em><span
  style='color:black;background:transparent'> Услуги към Работодателя. Вие давате
  съгласие за използването от </span><em><span lang=EN-US style='color:black;
  background:transparent;font-style:normal'>J</span></em><em><span lang=EN-US
  style='color:#333333;background:transparent;font-style:normal'>obpreference.com</span></em><span
  style='color:black;background:transparent'> на подизпълнители с цел предоставяне
  на Услугите.</span> <em><span lang=EN-US style='color:black;background:transparent;
  font-style:normal'>J</span></em><em><span lang=EN-US style='color:#333333;
  background:transparent;font-style:normal'>obpreference.com</span></em><span
  style='color:black;background:transparent'> поддържа актуален списък на
  подизпълнителите </span><a href="https://www.jobs.bg/suppliers.php"><span
  style='background:transparent'>тук</span></a><span style='color:black;background:
  transparent'>. В случай на промяна, Вие ще бъдете уведомени изрично преди
  промяната. </span></p>
  <p class=- style='line-height:150%;border:none;padding:0cm'>&nbsp;</p>
  <p class=- style='line-height:150%;border:none;padding:0cm'><b>6. Съхранение,
  изтриване и експорт на данни</b><br>
  <br>
  С приемането на настоящите Условия Вие възлагате, а <em><span lang=EN-US
  style='font-style:normal'>J<span style='color:#333333'>obpreference.com</span></span></em>
  се задължава:<br>
  <br>
  6.1. да осигури в Сайта технологична възможност за съхранение на отключени
  предпочитания в акаунта Ви до изтриването им от Ваша страна (от Ваш
  Представител), но не по-дълго от <span lang=EN-US>1</span> /един/ месец от
  датата на отключване на обявата;<br>
  <br>
  6.2. да подсигури автоматично изтриване на предпочитания в акаунта Ви след
  изтичане на технологичния срок на съхранение посочен в т. 6.1.(ако не са
  изтрити по-рано от Вас чрез използване на функционалностите на Сайта);<br>
  <br>
  6<span lang=EN-US>.3.</span> да подсигури автоматично изтриване на всички
  кандидатури, намиращи се в акаунта Ви, при прекратяване на регистрацията Ви в
  Сайта, освен в случаите, когато това е забранено от закона. <br>
  <br>
  </p>
  <p class=- style='line-height:150%;border:none;padding:0cm'><b><span
  lang=EN-US>7</span>. Сигурност</b><br>
  <br>
  <em><span lang=EN-US style='font-style:normal'>J<span style='color:#333333'>obpreference.com</span></span></em>
  се задължава да:<br>
  <br>
  <span lang=EN-US>7</span>.1. прилага технически и организационни мерки (по
  отношение на персонал, сгради, софтуер, хардуер, мрежи, сървъри, криптиране,
  контрол, отчетност и мониторинг, проверки за уязвимости и др.) за осигуряване
  на ниво на защита срещу неоторизиран или инцидентен достъп, загуба, промяна,
  разкриване или унищожаване на данни, съобразено с рисковете. <br>
  <br>
  <p class=MsoBodyText>&nbsp;</p>
  <p class=MsoBodyText><em><b><span lang=EN-US style='font-size:16.0pt;
  color:#333333;font-style:normal'>Политика за ползване на бисквити </span></b></em></p>
  <p class=MsoBodyText>&nbsp;</p>
  <p class=MsoBodyText><em><span lang=EN-US style='color:#333333;font-style:
  normal'>Тук можете да се запознаете с ползваните бисквити и други технологии
  за съхранение на информация във връзка с предоставяните услуги (“Услугите”) в
  сайта                                www.jobpreference.com и/или мобилните
  версии и приложения (“Сайта”). <br>
  <br>
  <br>
  </span></em><strong><span lang=EN-US style='color:#333333;font-weight:normal'>ПОЛИТИКА
  ЗА ПОЛЗВАНЕ НА БИСКВИТИ</span></strong><em><span lang=EN-US style='color:
  #333333;font-style:normal'><br>
  <br>
  Бисквитите са информация, съхранена във Вашия браузър (или мобилно
  приложение). Те се използват за съхранение на настройки и идентификатори,
  необходими за някои от предоставяните в Сайта услуги.<br>
  <br>
  Jobpreference.com прилага политика в съответствие с изисквания на ЕС.<br>
  <br>
  Бисквити се използват за следните цели:</span></em><em><b><span lang=EN-US
  style='font-size:16.0pt;color:#333333;font-style:normal'><br>
  <br>
  </span></b></em></p>
  <p class=MsoBodyText>&nbsp;</p>
  <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
   style='border-collapse:collapse'>
   <tr>
    <td width=213 valign=top style='width:159.7pt;border:solid black 1.0pt;
    border-right:none;padding:2.75pt 2.75pt 2.75pt 2.75pt'>
    <p class=->Вид </p>
    </td>
    <td width=213 valign=top style='width:159.7pt;border:solid black 1.0pt;
    border-right:none;padding:2.75pt 2.75pt 2.75pt 2.75pt'>
    <p class=->Срок на съхранение </p>
    </td>
    <td width=213 valign=top style='width:159.8pt;border:solid black 1.0pt;
    padding:2.75pt 2.75pt 2.75pt 2.75pt'>
    <p class=->Описание</p>
    </td>
   </tr>
   <tr>
    <td width=213 valign=top style='width:159.7pt;border-top:none;border-left:
    solid black 1.0pt;border-bottom:solid black 1.0pt;border-right:none;
    padding:2.75pt 2.75pt 2.75pt 2.75pt'>
    <p class=->Сесия и управление на навигацията (идентификатор на сесията,
    защита на сигурността, предишна стъпка за бутона “обратно” и др.) </p>
    </td>
    <td width=213 valign=top style='width:159.7pt;border-top:none;border-left:
    solid black 1.0pt;border-bottom:solid black 1.0pt;border-right:none;
    padding:2.75pt 2.75pt 2.75pt 2.75pt'>
    <p class=->Временно (за времето на престоя Ви в Сайта) </p>
    </td>
    <td width=213 valign=top style='width:159.8pt;border:solid black 1.0pt;
    border-top:none;padding:2.75pt 2.75pt 2.75pt 2.75pt'>
    <p class=-><em>Тези бисквити са технически необходими за вход, управление
    на навигацията и ползването на Сайта. Сайтът не може да функционира
    надеждно и нормално без тях.</em><i> </i></p>
    </td>
   </tr>
   <tr>
    <td width=213 valign=top style='width:159.7pt;border-top:none;border-left:
    solid black 1.0pt;border-bottom:solid black 1.0pt;border-right:none;
    padding:2.75pt 2.75pt 2.75pt 2.75pt'>
    <p class=->Сигурност (защита против влизане от неoторизирани устройства и
    др.) </p>
    </td>
    <td width=213 valign=top style='width:159.7pt;border-top:none;border-left:
    solid black 1.0pt;border-bottom:solid black 1.0pt;border-right:none;
    padding:2.75pt 2.75pt 2.75pt 2.75pt'>
    <p class=->До 6 /шест/ месеца от последното влизане </p>
    </td>
    <td width=213 valign=top style='width:159.8pt;border:solid black 1.0pt;
    border-top:none;padding:2.75pt 2.75pt 2.75pt 2.75pt'>
    <p class=-><em>Това са бисквити, които се ползват с цел подсигуряване
    защита на акаунта от неоторизиран достъп.</em> </p>
    </td>
   </tr>
   <tr>
    <td width=213 valign=top style='width:159.7pt;border-top:none;border-left:
    solid black 1.0pt;border-bottom:solid black 1.0pt;border-right:none;
    padding:2.75pt 2.75pt 2.75pt 2.75pt'>
    <p class=->Настройки (език на Сайта<span lang=EN-US>)</span> </p>
    </td>
    <td width=213 valign=top style='width:159.7pt;border-top:none;border-left:
    solid black 1.0pt;border-bottom:solid black 1.0pt;border-right:none;
    padding:2.75pt 2.75pt 2.75pt 2.75pt'>
    <p class=->До 6 /шест/ месеца от последното ползване </p>
    </td>
    <td width=213 valign=top style='width:159.8pt;border:solid black 1.0pt;
    border-top:none;padding:2.75pt 2.75pt 2.75pt 2.75pt'>
    <p class=-><em>Това са бисквити, които се използват за запазване на Вашите
    настройки;</em> </p>
    </td>
   </tr>
  </table>
  <p class=MsoBodyText>&nbsp;</p>
  <p class=MsoBodyText><strong><span lang=EN-US style='color:#333333;
  font-weight:normal'>Изтриване на бисквитите от Вашия браузър</span></strong><em><span
  lang=EN-US style='color:#333333;font-style:normal'><br>
  В Jobpreference.com е предвидена специална функционалност, позволяваща Ви да
  изтриете всички бисквити от Jobpreference.com. В случай че желаете да
  направите това, използвайте този линк: <span style='background:transparent'>”</span></span></em><em><span
  style='color:#333333;background:transparent;font-style:normal'>Изтриване на всички
  бисквити от този браузър“.</span></em></p>
  <p class=MsoBodyText><em><span lang=EN-US style='color:#333333;font-style:
  normal'><br>
  </span></em><strong><span lang=EN-US style='color:#333333;font-weight:normal'>Контрол
  на бисквитите в браузъра</span></strong><em><span lang=EN-US
  style='color:#333333;font-style:normal'><br>
  В случай че желаете, можете да използвате и настройките на Вашия браузър, за
  да изтриете и/или забраните получаването на бисквити от конкретен или от
  всички сайтове. Повече за това можете да научите на aboutcookies.org.<br>
  <br>
  </span></em></p>
  </td>
 </tr>
</table>

<p class=MsoNormal>&nbsp;</p>

</div>

<div class=WordSection1>

<p class=MsoNormal><b><span style='font-size:16.0pt'>УСЛОВИЯ ЗА ПОЛЗВАНЕ ОТ
ФИЗИЧЕСКИ ЛИЦА – КРАЙНИ ПОТРЕБИТЕЛИ </span></b></p>

<p class=MsoNormal><b><span style='font-size:16.0pt'>&nbsp;</span></b></p>

<p class=MsoNormal>Моля, прочетете този документ внимателно. Той съдържа
Условията за достъпа до уебсайта www.<span lang=EN-US>j<span style='color:#333333'>obpreference.com</span></span>
и за използването му (&quot;Условията&quot;). Ако не приемате Условията,
посочени тук, няма възможност да използвате Сайта! </p>

<p class=MsoNormal>Във връзка с предоставяните Услуги <span lang=EN-US>J<span
style='color:#333333'>obpreference.com</span></span> обработва лични данни на 
потребители съгласно Политиката за защита на личните данни („Политика“).
Политиката е неразделна част от тези Условия.</p>

<p class=MsoNormal>&nbsp;</p>

<p class=MsoNormal><b>УСЛОВИЯ ЗА ПОЛЗВАНЕ ОТ ФИЗИЧЕСКИ ЛИЦА</b> </p>

<p class=MsoNormal><b>1.Регистрация</b></p>

<p class=MsoNormal>1.1. <span style='color:#333333'>Сън Ленд Кепитъл</span>“
ООД („<span lang=EN-US>J<span style='color:#333333'>obpreference.com</span></span>“/„Ние“/„Нас“)
с адрес на управление:<span style='color:#333333'> ул. „кдп Георги Боев” 4,  </span> Варна,
ЕИК: <span style='color:#333333'>206166575</span> предоставя чрез сайта www. <span
lang=EN-US>j<span style='color:#333333'>obpreference.com</span></span>
(“Сайта”) на  физически лица  безплатни и платени услуги на информационното
общество („Услугите“). </p>

<p class=MsoNormal>1.2. За да ползвате Услугите, Вие гарантирате, че сте
навършили 18 години и сте дееспособен да сключите договор. <span lang=EN-US>J<span
style='color:#333333'>obpreference.com</span></span> не носи отговорност в
случай на ползване на Услугите от лице, което не отговаря на посочените
ограничения за възраст и дееспособност. </p>

<p class=MsoNormal>1.3. Ползването на  Услугите е възможно само с регистрация.
Регистрацията се извършва лично<span lang=EN-US>.</span> При регистрация се
изисква да попълните задължителните полета в регистрационната форма (име, имейл
адрес и др.). Вие декларирате, че отговаряте на изискванията на т. 1.2. </p>

<p class=MsoNormal>1.4. Вие се задължавате да предоставите верни, пълни и
актуални данни при регистрацията и да ги поддържате такива. </p>

<p class=MsoNormal>1.<span lang=EN-US>5</span>. Вие отговаряте за всички
действия, извършени чрез Вашия акаунт с използване на Вашето потребителско име
и парола за достъп до него. Вие се задължавате незабавно да уведомите <span
lang=EN-US>J<span style='color:#333333'>obpreference.com</span></span> в случай
на неправомерен достъп или вероятност за такъв.</p>

<p class=MsoNormal>&nbsp;</p>

<p class=MsoNormal><b>2. Сключване на договор</b><br>
<br>
2.1. С натискането на бутон „Регистрация” и потвърждавайки получения от Вас
имейл за регистрация, Вие извършвате електронно изявление, с което сключвате
договор с Нас при тези Условия и се задължавате да ги спазвате. Преди
натискането на бутона “Регистрация”, имате възможност да прегледате и
редактирате посочената от Вас информация. Вие приемате, че електронните
изявления към <span lang=EN-US>J<span style='color:#333333'>obpreference.com</span></span>
и електронните изявления от <span lang=EN-US>J<span style='color:#333333'>obpreference.com</span></span>
към Вас ще се считат за подписани с обикновен електронен подпис съобразно чл.
3, параграф 10 от Регламент (ЕС) № 910/2014, със силата на саморъчен подпис.
Авторството на тези изявления ще се установява по реда на чл. 13, ал. 1 във
връзка с чл. 13, ал. 4, изр. 2-ро от Закона за електронния документ и
електронните удостоверителни услуги (“ЗЕДЕУУ”).</p>

<p class=MsoNormal>2.<span lang=EN-US>2</span>. С приемането на тези Условия се
съгласявате да бъдете адресат и да получавате електронни изявления от <span
lang=EN-US>J<span style='color:#333333'>obpreference.com</span></span> във
връзка с настоящия Договор и Услугите в Сайта.</p>

<p class=MsoNormal>&nbsp;</p>

<p class=MsoNormal><b>3. Ползване на Услугите и съдържанието в Сайта</b><br>
<br>
3.1. Сайтът дава възможност за публикуване на обяви за предпочитани работни
места от физически лица. Обявите и тяхното съдържание са изцяло отговорност на
лицата, който са ги  публикували. При отключване Вашата преференция автоматично
стига в акаунта на Работодателя или друг потребител в <span lang=EN-US>J<span
style='color:#333333'>obpreference.com</span></span>. </p>

<p class=MsoNormal>Вие сами решавате по кои предложения и на кои Работодатели
да отговорите . За целта преди това Ви препоръчваме да потърсите повече
информация за Работодателя както в Сайта, така и от други източници. </p>

<p class=MsoNormal>3.2. <span lang=EN-US>J<span style='color:#333333'>obpreference.com</span></span>
не е посредник и не участва по никакъв начин в избора на кандидати или
последваща комуникация и договаряне на условия между страните. Решенията за
това каква информация да изпратите, в какви договорни отношения желаете да
влезете и при какви условия са изцяло Ваша отговорност. <span lang=EN-US>J<span
style='color:#333333'>obpreference.com</span></span> не е страна по тези
взаимоотношения и поради тази причина, в случай че считате, че Работодателят не
спазва договорени условия и/или негови действия не са в съответствие със
закона, е необходимо да уведомите компетентните държавни органи, в чиито компетенции
и правомощия е извършването на съответните проверки и предприемане на действия
спрямо Работодателя. Ползването на Услугите е на Ваш собствен риск и
отговорност. </p>

<p class=MsoNormal>3.3.Сайтът и съдържанието в него са собственост на <span
lang=EN-US>J<span style='color:#333333'>obpreference.com</span></span>  и/или
на неговите партньори, доставчици и клиенти. Нямате право да използвате по
какъвто и да е начин, да копирате, модифицирате или разпространявате
съдържание, което не Ви принадлежи за цели, различни от или несъвместими с
ползването на Услугите и с предназначението на Сайта, без изричното
предварително писмено съгласие на <span lang=EN-US>J<span style='color:#333333'>obpreference.com</span></span> 
или на съответните трети лица.</p>

<p class=MsoNormal>3.4.Публикуваните контакти на Работодатели и на техни
Представители могат да бъдат използвани единствено по предназначение. Вие се
задължавате да не използвате тези контакти по никакъв друг повод, включително,
но не само, за маркетингови и промоционални цели.</p>

<p class=MsoNormal>3.5.Вие се задължавате да не смущавате нормалната работа на
техническите или софтуерни приложения и компютърни системи на <span lang=EN-US>J<span
style='color:#333333'>obpreference.com</span></span> или на потребителите на
Сайта чрез недобросъвестни действия.</p>

<p class=MsoNormal>&nbsp;</p>

<p class=MsoNormal><b>4. Създаване и съхраняване на автобиографии, файлове и
др. информация</b><br>
<br>
4.1. Сайтът Ви дава възможност да създадете и/или съхраните  автобиографии,
файлове и др. информация, която можете да публикувате по Ваша преценка във ваши
обяви. </p>

<p class=MsoNormal>4.2. Съхранените в Сайта документи са достъпни до всички
потребители. При комуникация Вие сами решавате каква информация и на кои
Работодатели/потребители да я изпратите. Когато публикуваната преференция за
работа е отключена, тя автоматично достига до акаунта на Работодателя или друг
потребител.</p>

<p class=MsoNormal><span lang=EN-US>4.</span>3.Вие гарантирате, че имате право
да използвате всяко съдържание, което публикувате в Сайта. В случай че това не
е така, носите отговорност за всички вреди, които произтекат от това за <span
lang=EN-US>J<span style='color:#333333'>obpreference.com</span></span> или за
трети лица.</p>

<p class=MsoNormal>4.4.Вашата обява не може да съдържа:<br>
(а) изисквания с дискриминативен характер;<br>
(б) материали, които нарушават или не зачитат права или законни интереси на
трети лица, вкл. основни човешки права, права на интелектуална собственост и
др.;<br>
(в) или каквото и да е съдържание с незаконен, дискриминативен, нецензурен,
обиден, заплашителен, клеветнически, омразен или неприличен характер или
такова, което би могло да постави в неудобно положение или да оскърби трето
лице.<br>
<br>
</p>

<p class=MsoNormal><b>5. Прекратяване на акаунт</b><br>
<br>
5.1. Вашият акаунт може да бъде закрит в следните случаи:<br>
(а) по всяко време, със заявка от Ваша страна (чрез функционалността в Сайта);<br>
(б) при дълъг период на неизползване на акаунта за повече от 3 /три/ години;<br>
(в) при съмнения за нарушаване от Ваша страна на тези Условия, на други
условия, предвидени в Сайта или в закона;<br>
(г) в други случаи, по преценка на <span lang=EN-US>J<span style='color:#333333'>obpreference.com</span></span>,
с предварително предизвестие. </p>

<p class=MsoNormal>5.2. Вашият акаунт може да бъде блокиран в следните случаи:<br>
(а) при съмнения за нарушаване от Ваша страна на тези Условия, на други
условия, предвидени в Сайта или на закона;<br>
(б) при постъпило искане от компетентен държавен орган;<br>
(в) при заявка от Ваша страна</p>

<p class=MsoNormal>5.3. При закриване на акаунт и прекратяване на договора
данните, съхранявани в Сайта, се изтриват. Това включва както данните във Вашия
акаунт, така и отключени Ваши преференции(към момента на закриване)намиращи се
в акаунти на Работодатели или други потребители в Сайта .</p>

<p class=MsoNormal>След закриване на акаунта всички данни, част от него, се
изтриват. Във връзка с нашите задължения, отговорности и изисквания на закона
за период до 1 /една/ година се съхраняват:</p>

<p class=MsoNormal>&nbsp;</p>

<p class=MsoNormal>    С цел разрешаване на възможни спорове, възникнали или
станали известни след прекратяване на споразумението за ползване на Услугите,
за период от 1 /една/ година след прекратяване на акаунта се съхранява
информация за неговото сключване. </p>

<p class=MsoNormal>&nbsp;</p>

<p class=MsoNormal>   Данните се заличават напълно след изтичане на посочения
срок. Междувременно същите могат да бъдат предоставени само и единствено по
надлежния ред на компетентните държавни органи при упражняване на техните
контролни правомощия или на компетентен съд в случай на възникнало съдебно
производство, по което те имат отношение. В случай на възникнал правен спор или
производство, налагащо запазване на данни и/или искане от компетентен държавен
орган, е възможно запазване на данни за по-дълъг от посочените срокове до
окончателно приключване на възникналия спор или производство пред всички
инстанции.</p>

<p class=MsoNormal>&nbsp;</p>

<p class=MsoNormal><b>6. Промени</b><br>
<br>
6.1. Доколкото предоставяните от <span lang=EN-US>J<span style='color:#333333'>obpreference.com</span></span>
Услуги са разнообразни и постоянно развивани и допълвани, както и във връзка
със законодателни промени, <span lang=EN-US>J<span style='color:#333333'>obpreference.com</span></span>
си запазва правото да добавя нови Услуги, да променя или премахва съществуващи
Услуги, без за това да е необходимо предупреждение.</p>

<p class=MsoNormal>6.2. При извършване на промени в Условията, <span
lang=EN-US>J<span style='color:#333333'>obpreference.com</span></span> довежда
тези промени до Ваше знание чрез публикуването им на видно място в Сайта или в
акаунта Ви. Промени в Условията няма да засягат предоставянето на заявени и
заплатени преди промяната Услуги.</p>

<p class=MsoNormal>&nbsp;</p>

<p class=MsoNormal><b>7. Други условия</b></p>

<p class=MsoNormal>&nbsp;</p>

<p class=MsoNormal>7.1. Тези общи Условия уреждат взаимоотношенията между
страните. Допълнителни споразумения могат да се сключват само чрез предвидените
функционалности в Сайта или чрез подписване от двете страни на изрично писмено
споразумение, предоставено от <span lang=EN-US>J<span style='color:#333333'>obpreference.com</span></span>.<br>
7.2. В случай че някоя от клаузите по настоящите Условия се окаже
недействителна, това няма да влече недействителност на сключените договори или
на други клаузи.<br>
7.3. <span style='color:black;background:transparent'>Договорът се сключва на
български език</span>. Споровете по тези Условия ще бъдат решавани от
компетентния български съд в град Варна.<br>
7.4. По неуредени въпроси се прилага законодателството на Република България.</p>

<p class=MsoNormal>&nbsp;</p>
</div>


</body>

</html>
`;
