export const SITE_NAME = "Job Preference";
export const BETA = "beta";
export const TRANSLATION_COMMON = "common";
export const LOCAL_STORAGE_SITE_NAME = "dreamJob";

export const BASE_URL = process.env.REACT_APP_URL;

export const SNACKBAR_SUCCESS = 1;
export const SNACKBAR_WARNING = 2;
export const SNACKBAR_ERROR = 3;
export const SNACKBAR_INFO = 4;
export const REFRESH_INTERVAL = 30000;

export const ERROR_RESPONSE_STATUS = 401;
