import _ from "lodash";
export const originalState = {
  invoiceDetails: [],
  hasInvoiceDetails: false,
  lastDeletedId: null,
  isModalOpen: false,
};

export const reducer = (draft, action) => {
  switch (action.type) {
    case "invoiceDetailsLoaded":
      if (!_.isEmpty(action.value)) {
        draft.hasInvoiceDetails = true;

        draft.invoiceDetails.push({
          ["type"]: action.value.type,
          ["firstName"]: action.value.first_name,
          ["lastName"]: action.value.last_name,
          ["company"]: action.value.company_name,
          ["countryId"]: action.value.country.id,
          ["countryName"]: action.value.country.name,
          ["cityId"]: action.value.city.id,
          ["cityName"]: action.value.city.name,
          ["address"]: action.value.address,
          ["zip"]: action.value.zip,
          ["responsible"]: action.value.responsible,
          ["idn"]: action.value.idn,
          ["uic"]: action.value.uic,
        });
      }
      break;
    case "setModalOpen":
      draft.isModalOpen = action.value;
      break;
    case "setDeleteId":
      draft.lastDeletedId = action.value;
      break;
  }
};
