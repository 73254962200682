import React, { useContext } from "react";
import StateContext from "../../Context/StateContext";
import DispatchContext from "../../Context/DispatchContext";
import Axios from "axios";
import * as CookiesDialogHelper from "./CookiesDialogHelper";
import { useTranslation } from "react-i18next";
import * as Constants from "../../helpers/Constants";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";

export default function CookiesDialog() {
  const appContext = useContext(StateContext);
  const appDispatch = useContext(DispatchContext);
  const [t] = useTranslation(Constants.TRANSLATION_COMMON);

  const handleDisagree = () => {
    appDispatch({ type: "logout" });
    appDispatch({ type: "setCookiesDialogOpen", value: false });
  };

  const handleAgree = () => {
    appDispatch({ type: "setCookiesDialogOpen", value: false });
    appDispatch({
      type: "setAgreeCookies",
      value: true,
    });
    const ourRequest = Axios.CancelToken.source();

    CookiesDialogHelper.handleAgreeCookies(Axios, ourRequest, appDispatch);
  };

  return (
    <Dialog
      open={appContext.appState.isCookiesDialogOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {t("cookiesDialog.acceptCookiesMessage")}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDisagree} color="primary">
          {t("cookiesDialog.disagree")}
        </Button>
        <Button onClick={handleAgree} color="primary" autoFocus>
          {t("cookiesDialog.agree")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
