import React, { useContext } from "react";

import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

import StateContext from "../../Context/StateContext";
import DispatchContext from "../../Context/DispatchContext";

import * as Constants from "../../helpers/Constants";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function DreamSnackbar() {
  const appContext = useContext(StateContext);
  const appDispatch = useContext(DispatchContext);
  const [t] = useTranslation(Constants.TRANSLATION_COMMON);

  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(2),
      },
    },
    alertAction: {
      alignItems:
        Array.isArray(appContext.appState.snackbar.msg) &&
        appContext.appState.snackbar.msg.length > 1
          ? "flex-start"
          : "center",
    },
  }));
  const classes = useStyles();

  const handleClose = (event, reason) => {
    appDispatch({ type: "hideMsg" });
  };

  function getStatusColor() {
    switch (appContext.appState.snackbar.status) {
      case Constants.SNACKBAR_INFO:
        return "info";
      case Constants.SNACKBAR_ERROR:
        return "error";
      case Constants.SNACKBAR_WARNING:
        return "warning";

      default:
        return "success";
    }
  }

  return (
    <div className={classes.root}>
      <Snackbar
        open={appContext.appState.snackbar.open}
        autoHideDuration={5000}
        onClose={handleClose}
      >
        <Alert
          classes={{ action: classes.alertAction }}
          onClose={handleClose}
          severity={getStatusColor()}
        >
          {Array.isArray(appContext.appState.snackbar.msg) ? (
            appContext.appState.snackbar.msg.map((value, index) => (
              <div key={index}>{t(value)}</div>
            ))
          ) : (
            <div>{t(appContext.appState.snackbar.msg)}</div>
          )}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default DreamSnackbar;
