export const originalState = {
  id: "",
  major: "",
  level: "",
  broadType: "",
  detailedType: "",
  from: null,
  to: null,
  presentEducation: false,
};

export function reducer(draft, action) {
  switch (action.type) {
    case "setId":
      draft.id = action.value;
      return;
    case "setMajor":
      draft.major = action.value;
      return;
    case "setLevel":
      draft.level = action.value;
      return;
    case "setBroadType":
      draft.broadType = action.value;
      return;
    case "setDetailedType":
      draft.detailedType = action.value;
      return;
    case "setFrom":
      draft.from = action.value;
      return;
    case "setTo":
      draft.to = action.value;
      return;
    case "changePresentEducation":
      draft.presentEducation = action.value;
      return;
  }
}
