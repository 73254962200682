import React, { useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import DreamPage from "../../ui_components/DreamPage";
import StateContext from "../../Context/StateContext";
import DispatchContext from "../../Context/DispatchContext";
import { useImmerReducer } from "use-immer";
import Axios from "axios";
import { useTranslation } from "react-i18next";
import * as Constants from "../../helpers/Constants";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import DreamButton from "../../ui_components/DreamButton/DreamButton";
import Alert from "@material-ui/lab/Alert";
import MuiPhoneNumber from "material-ui-phone-number";

import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import * as ProfileHelper from "./ProfileHelper";
import * as ProfileReducer from "./UserProfilePageReducer";
import ProfilePaper from "../../containers_components/ProfilePaperComponent/ProfilePaperComponent";
import localeBg from "date-fns/locale/bg";
import localeEnUS from "date-fns/locale/en-US";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  formRoot: {
    display: "flex",
    flexFlow: "wrap",
    justifyContent: "center",
    marginTop: 50,
    minHeight: 560,
  },
}));

const UserProfilePage = () => {
  const appContext = useContext(StateContext);
  const appDispatch = useContext(DispatchContext);
  const [t] = useTranslation(Constants.TRANSLATION_COMMON);
  const userId = appContext.appState.user_id;
  const history = useHistory();

  const [state, dispatch] = useImmerReducer(
    ProfileReducer.reducer,
    ProfileReducer.originalState
  );

  const classes = useStyles();

  useEffect(() => {
    if (
      !appContext.appState.isProfileDataFilled &&
      history.location.state &&
      history.location.state.fromJPs
    ) {
      appDispatch({
        type: "showMsg",
        value: {
          status: Constants.SNACKBAR_INFO,
          msg: "jps.noUserProfile",
        },
      });
    }
  }, []);

  useEffect(() => {
    appDispatch({ type: "showBackdrop", value: true });
    const ourRequest = Axios.CancelToken.source();

    ProfileHelper.getProfileData(
      userId,
      Axios,
      ourRequest,
      appDispatch,
      dispatch,
      t
    );

    return () => {
      ourRequest.cancel();
    };
  }, []);

  /**
   * The Form has been submitted
   */
  useEffect(() => {
    if (state.formSubmittedCount) {
      appDispatch({ type: "showBackdrop", value: true });
      const ourRequest = Axios.CancelToken.source();

      ProfileHelper.updateProfile(
        userId,
        state,
        ProfileHelper.USER_PROFILE_PICTURE, // image type 1 (profile picture)
        Axios,
        ourRequest,
        appDispatch,
        dispatch,
        t
      );

      return () => {
        ourRequest.cancel();
      };
    }
  }, [state.formSubmittedCount]);

  function handlePhoneChange(value) {
    if (value) {
      dispatch({
        type: "phoneChange",
        value: { value: value, t },
      });
    }
  }
  /**
   * The Form has been successfully submitted (updated)
   */
  useEffect(() => {
    if (state.formSuccessfullySubmittedCount) {
      appDispatch({ type: "showBackdrop", value: true });

      const ourRequest = Axios.CancelToken.source();

      ProfileHelper.getProfileData(
        userId,
        Axios,
        ourRequest,
        appDispatch,
        dispatch,
        t
      );

      return () => {
        ourRequest.cancel();
      };
    }
  }, [state.formSuccessfullySubmittedCount]);

  useEffect(() => {
    switch (appContext.appState.lang) {
      case "en":
        dispatch({ type: "localeChange", value: localeEnUS });
        break;
      case "bg":
        dispatch({ type: "localeChange", value: localeBg });
        break;
    }
  }, [appContext.appState.lang]);

  return (
    <DreamPage title={t("header.profile")}>
      <form className={classes.formRoot} noValidate autoComplete="off">
        <ProfilePaper
          padding={40}
          maxWidth={420}
          minHeight={360}
          actionUrl={"https://httpbin.org/post"}
          upDispatch={dispatch}
          maxFiles={1}
          accept={"image/*"}
          initialFiles={state.initialFileUploads}
          loadedDataBe={state.loadedDataBe}
          useDescription={false}
        >
          {/* First name */}
          <TextField
            autoComplete="off"
            margin="dense"
            id="firstname"
            name="firstname"
            label={t("registerForm.firstName")}
            fullWidth
            value={
              state.fields.firstname.value ? state.fields.firstname.value : ""
            }
            onChange={(e) =>
              dispatch({
                type: "firstnameChange",
                value: { value: e.target.value, t },
              })
            }
          />
          {state.fields.firstname.errorMessage && (
            <Alert severity="error">
              {state.fields.firstname.errorMessage}
            </Alert>
          )}
          {/* Last name */}
          <TextField
            autoComplete="nope"
            margin="dense"
            id="lastname"
            name="lastname"
            label={t("registerForm.lastName")}
            fullWidth
            value={
              state.fields.lastname.value ? state.fields.lastname.value : ""
            }
            onChange={(e) =>
              dispatch({
                type: "lastnameChange",
                value: { value: e.target.value, t },
              })
            }
          />
          {state.fields.lastname.errorMessage && (
            <Alert severity="error">{state.fields.lastname.errorMessage}</Alert>
          )}

          {/* Phone */}
          <MuiPhoneNumber
            fullWidth
            name="phone"
            label={t("registerForm.phone")}
            data-cy="user-phone"
            defaultCountry={"us"}
            value={state.fields.phone.value}
            onChange={handlePhoneChange}
          />
          {state.fields.phone.errorMessage && (
            <Alert severity="error">{state.fields.phone.errorMessage}</Alert>
          )}

          {/* Birth date */}
          <MuiPickersUtilsProvider locale={state.locale} utils={DateFnsUtils}>
            <KeyboardDatePicker
              autoComplete="off"
              fullWidth
              autoOk
              disableFuture
              minDate={"1900-01-01"}
              variant="inline"
              format="yyyy-MM-dd"
              margin="normal"
              id="birthDate"
              label={t("registerForm.birthDate")}
              value={
                state.fields.birthDate.value
                  ? state.fields.birthDate.value
                  : null
              }
              onChange={(e) =>
                dispatch({
                  type: "birthDateChange",
                  value: { value: e, t },
                })
              }
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </MuiPickersUtilsProvider>
          {state.fields.birthDate.errorMessage && (
            <Alert severity="error">
              {state.fields.birthDate.errorMessage}
            </Alert>
          )}
        </ProfilePaper>
        <DreamButton
          dreamButtonStyle={{
            height: 40,
            minWidth: 130,
            boxShadow: "0px 10px 10px rgba(0, 0, 0, 0.25)",
          }}
          buttonType="button"
          buttonDisabled={
            Boolean(state.hasValidationErrs) ||
            (state.uploadedFiles.uploadingFiles &&
              Boolean(state.uploadedFiles.uploadingFiles.length))
          }
          handleClick={(e) => ProfileHelper.handleSubmit(e, dispatch)}
          color="primary"
          kind="contained"
          buttonLabel={"basic.save"}
        ></DreamButton>
      </form>
    </DreamPage>
  );
};

export default UserProfilePage;
