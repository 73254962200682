import React, { useContext } from "react";
import DreamRadioButtonsComponent from "../../ui_components/DreamRadioButtons/DreamRadioButtonsComponent";
import * as WorkPlaceHelper from "./WorkPlaceHelper";
import JobPreferenceDispatchContext from "../../Pages/CreateJobPreferencePage/Context/JobPreferenceDispatchContext";
import JobPreferenceStateContext from "../../Pages/CreateJobPreferencePage/Context/JobPreferenceStateContext";

function WorkPlaceComponent() {
  const jobPreferenceDispatchContext = useContext(JobPreferenceDispatchContext);
  const jobPreferenceStateContext = useContext(JobPreferenceStateContext);

  function workPlaceTypeChanged(value) {
    jobPreferenceDispatchContext({
      type: "workPlaceTypeChanged",
      value: parseInt(value),
    });
  }

  return (
    <DreamRadioButtonsComponent
      name={"jp.workplace"}
      value={jobPreferenceStateContext.formData.workPlaceType.workPlaceType}
      data={WorkPlaceHelper.workPlaceRadioButtons}
      changeHandler={workPlaceTypeChanged}
    />
  );
}

export default WorkPlaceComponent;
