export const SEARCH_TYPE_COUNTRY = 1;
export const SEARCH_TYPE_STATE = 2;
export const SEARCH_TYPE_CITY = 3;
export const SEARCH_TYPE_POSITION = 4;

/**
 * Format option depennds on search type (City, Country or State)
 * @param {Array} items
 * @param {string} searchType
 * @param {t} t
 * @param {string} lang
 *
 */
export function formatOptions(items, searchType, t, lang) {
  let formattedItems = [];

  switch (parseInt(searchType)) {
    case SEARCH_TYPE_CITY:
      formattedItems = formatCities(items, t);
      break;
    case SEARCH_TYPE_STATE:
      formattedItems = formatStates(items, t);
      break;
    case SEARCH_TYPE_COUNTRY:
      formattedItems = formatCountries(items, t);
      break;

    case SEARCH_TYPE_POSITION:
      formattedItems = formatPositions(items, lang, t);
      break;

    default:
      console.log("Undefined Search Type!!!!!!!!!");
      break;
  }

  return formattedItems;
}

function formatCities(items, t) {
  let formattedItems = [];
  items.forEach((element) => {
    let formattedItem = {
      id: element.id,
      country_id: element.country_id,
      country_name: element.country_name,
      just_name: element.name,
      name: `${t(element.name)}, ${t(element.state_name)}, ${t(
        element.country_name
      )}`,
      location_type: element.location_type,
      state_id: element.state_id,
      state_name: element.state_name,
    };
    formattedItems.push(formattedItem);
  });

  return formattedItems;
}

function formatStates(items, t) {
  let formattedItems = [];
  items.forEach((element) => {
    let formattedItem = {
      id: element.id,
      name: `${t(element.name)}, ${t(element.country_name)}`,
      country_id: element.country_id,
      location_type: element.location_type,
    };

    formattedItems.push(formattedItem);
  });

  return formattedItems;
}

function formatCountries(items, t) {
  let formattedItems = [];
  items.forEach((element) => {
    let formattedItem = {
      id: element.id,
      name: `${t(element.name)}`,
      location_type: element.location_type,
    };

    formattedItems.push(formattedItem);
  });

  return formattedItems;
}

function formatPositions(items, lang) {
  let formattedItems = [];
  items.forEach((element) => {
    let formattedItem = {
      id: element.id,
      name: element["name_" + lang.split("-").join("_").toLowerCase()],
    };

    formattedItems.push(formattedItem);
  });

  return formattedItems;
}

export function formatUrlParams(paramsArr) {
  let urlStr = "";
  for (const prop in paramsArr) {
    urlStr += `&${prop}=${paramsArr[prop]}`;
  }

  return urlStr;
}
