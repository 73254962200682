import React, { useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Axios from "axios";
import DispatchContext from "../../Context/DispatchContext";
import { useImmerReducer } from "use-immer";
import { useTranslation } from "react-i18next";
import * as Constants from "../../helpers/Constants";
import * as DreamSelectHelper from "./DreamSelectHelper";
import * as DreamSelectReducer from "./DreamSelectReducer";

import uuid from "react-uuid";

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginBottom: 10,
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

function DreamSelectComponent(props) {
  const appDispatch = useContext(DispatchContext);
  const [t] = useTranslation(Constants.TRANSLATION_COMMON);

  const [state, dispatch] = useImmerReducer(
    DreamSelectReducer.reducer,
    DreamSelectReducer.originalState
  );

  const classes = useStyles();

  useEffect(() => {
    if (props.value) {
      DreamSelectHelper.handleChange(
        props.value,
        dispatch,
        props.dispatchChangeHandler
      );
    }
  }, [props.value]);

  useEffect(() => {
    dispatch({
      type: "setInputID",
      value: props.inputId ? props.inputId : uuid(),
    });

    if (props.optionsData) {
      DreamSelectHelper.handleSuccessfulGetData(
        props.optionsData,
        dispatch,
        props.dataType
      );
    } else {
      const ourRequest = Axios.CancelToken.source();
      DreamSelectHelper.getOptions(
        props.urlAPI,
        Axios,
        ourRequest,
        appDispatch,
        dispatch,
        props.dataType
      );

      return () => {
        ourRequest.cancel();
      };
    }
  }, []);

  useEffect(() => {
    // If API's url is changed, we need to get options again,
    // BUT skip e 1st time, becuase it is on component mounting
    if (state.loadCounter) {
      const ourRequest = Axios.CancelToken.source();
      DreamSelectHelper.getOptions(
        props.urlAPI,
        Axios,
        ourRequest,
        appDispatch,
        dispatch,
        props.dataType
      );

      return () => {
        ourRequest.cancel();
      };
    }
    dispatch({
      type: "setLoadCounter",
    });
  }, [props.urlAPI]);

  let unsorted = state.options.map((item, index) => (
    <MenuItem key={item.id} value={item.id}>
      {t(item.text)}
      {/* @TODO Add it to translate */}
    </MenuItem>
  ));

  if (props.sort) {
    unsorted.sort((el, el1) =>
      el.props.children > el1.props.children ? 1 : -1
    );
  }

  return (
    <FormControl
      className={classes.formControl}
      style={props.style}
      variant={props.variant}
      fullWidth={Boolean(props.fullWidth)}
      disabled={props.disabled}
    >
      <InputLabel shrink={props.shrink} id="demo-simple-select-outlined-label">
        {`${t(props.label)}${props.isMandatory ? "*" : ""}`}
      </InputLabel>
      <Select
        className={classes.selectEmpty}
        value={state.selected} // ID of option to be selected
        inputProps={props.inputProps}
        displayEmpty={props.displayEmpty}
        onChange={(e) => {
          DreamSelectHelper.handleChange(
            e.target.value,
            dispatch,
            props.dispatchChangeHandler
          );
          if (props.manualyChangedHandler) {
            props.manualyChangedHandler(e.target.value);
          }
        }}
        label={t(props.label)}
      >
        {props.zeroElementTypeAll ? (
          <MenuItem value="">{t("basic.all")}</MenuItem>
        ) : (
          <MenuItem value="" disabled>
            {t("basic.pleaseSelect")}
          </MenuItem>
        )}

        {unsorted}
      </Select>
      {props.helperText && (
        <FormHelperText>{t(props.helperText)}</FormHelperText>
      )}
    </FormControl>
  );
}

export default DreamSelectComponent;
