import * as DreamAutocompleteHelper from "../../ui_components/DreamAutocomplete/DreamAutocompleteHelper";

export const locationsRadioButtons = [
  {
    label: "jp.city",
    value: DreamAutocompleteHelper.SEARCH_TYPE_CITY,
  },
  {
    label: "jp.state",
    value: DreamAutocompleteHelper.SEARCH_TYPE_STATE,
  },
  {
    label: "jp.country",
    value: DreamAutocompleteHelper.SEARCH_TYPE_COUNTRY,
  },
];
