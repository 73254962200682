import React, { useContext, useEffect } from "react";
import { useImmerReducer } from "use-immer";
import DreamDynamicGridComponent from "../../ui_components/DreamDynamicGrid/DreamDynamicGridComponent";
import LocationsSingleItemComponent from "./LocationsSingleItemComponent/LocationsSingleItemComponent";
import * as LocationsReducer from "./LocationsReducer";
import JobPreferenceStateContext from "../../Pages/CreateJobPreferencePage/Context/JobPreferenceStateContext";
import JobPreferenceDispatchContext from "../../Pages/CreateJobPreferencePage/Context/JobPreferenceDispatchContext";
import DreamCheckboxComponent from "../../ui_components/DreamCheckbox/DreamCheckboxComponent";
import Grid from "@material-ui/core/Grid";

function LocationsComponent() {
  const [state, dispatch] = useImmerReducer(
    LocationsReducer.reducer,
    LocationsReducer.originalState
  );

  const jobPreferenceStateContext = useContext(JobPreferenceStateContext);
  const jobPreferenceDispatchContext = useContext(JobPreferenceDispatchContext);

  function checkboxChangeHandler(checkbox) {
    jobPreferenceDispatchContext({
      type: "locationsAnyChanged",
      value: checkbox.isChecked,
    });
  }

  useEffect(() => {
    if (jobPreferenceStateContext.initialDataLoaded) {
      let formattedData = [];
      jobPreferenceStateContext.formData.locations.locations.forEach(
        (location) => {
          formattedData.push({
            id: location.id,
            singleItem: (
              <LocationsSingleItemComponent locationData={location} />
            ),
          });
        }
      );
      dispatch({
        type: "setFormattedData",
        value: formattedData,
      });
    }
  }, [jobPreferenceStateContext.locationsLoaded]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <DreamCheckboxComponent
          data={[jobPreferenceStateContext.formData.locations.any]}
          changeHandler={checkboxChangeHandler}
        />
      </Grid>
      <Grid item item xs={12}>
        {!jobPreferenceStateContext.formData.locations.any.isChecked && (
          <DreamDynamicGridComponent
            singleItem={<LocationsSingleItemComponent />}
            maxItems="3"
            initialDataLoaded={state.isDataFormatted}
            initialData={state.formattedData}
          />
        )}
      </Grid>
    </Grid>
  );
}

export default LocationsComponent;
