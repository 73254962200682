export const originalState = {
  userId: null,
  isModalOpen: false,
};

export function reducer(draft, action) {
  switch (action.type) {
    case "setDeleteId":
      draft.userId = action.value;
      break;
    case "setModalOpen":
      draft.isModalOpen = action.value;
      break;
  }
}
