export const originalState = {
  uploadedFiles: [], // Finale uploads
  uploadingFiles: [], // Uploads in progress
  initialFileUploads: [],
};

/**
 * Reducer
 * @param {draft} draft
 * @param {action} action
 */
export function reducer(draft, action) {
  switch (action.type) {
    case "resetInitialFiles":
      draft.uploadedFiles = [];
      return;
    case "initialFileUpload":
      draft.uploadedFiles.push(action.value);
      return;

    case "uploadStarted":
      // Finale Uploads
      let fileName = action.value.name.split(".").slice(0, -1).join(".");
      draft.uploadedFiles.push({
        ...action.value,
        fileDescription: fileName,
      });
      // Uploads in progress
      draft.uploadingFiles.push({
        ...action.value,
        fileDescription: fileName,
      });
      return;

    case "uploadFailed":
      // Finale Uploads
      let uploadedKey = draft.uploadedFiles.findIndex(
        (x) => x.id == action.value
      );
      draft.uploadedFiles.splice(uploadedKey, 1);
      // Uploads in progress
      let uploadingKey = draft.uploadingFiles.findIndex(
        (x) => x.id == action.value
      );
      draft.uploadingFiles.splice(uploadingKey, 1);
      return;

    case "uploadFinished":
      // Finale Uploads
      let uploadFinishedIndex = draft.uploadedFiles.findIndex(
        (x) => x.id == action.value.id
      );
      if (uploadFinishedIndex >= 0) {
        draft.uploadedFiles[uploadFinishedIndex].status = action.value.status;
        draft.uploadedFiles[uploadFinishedIndex].file = action.value.file;

        draft.uploadedFiles[uploadFinishedIndex].previewUrl =
          action.value.previewUrl;
      }

      // Uploads in progress
      let uploadingFinishedIndex = draft.uploadingFiles.findIndex(
        (x) => x.id == action.value.id
      );
      if (uploadingFinishedIndex >= 0) {
        draft.uploadingFiles.splice(uploadingFinishedIndex, 1);
      }

      return;

    case "descriptionChanged":
      let foundIndex = draft.uploadedFiles.findIndex(
        (x) => x.id == action.value.id
      );
      draft.uploadedFiles[foundIndex].fileDescription = action.value.value;
      return;

    case "fileDeleted":
    case "fileRemoved":
      // Finale Uploads
      let foundRemovedKey = draft.uploadedFiles.findIndex(
        (x) => x.id == action.value
      );
      draft.uploadedFiles.splice(foundRemovedKey, 1);
      // Uploads in progress
      let foundRemovedKey2 = draft.uploadingFiles.findIndex(
        (x) => x.id == action.value
      );
      draft.uploadingFiles.splice(foundRemovedKey2, 1);
      return;
  }
}
