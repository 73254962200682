import React, { useContext } from "react";
import DreamRadioButtonsComponent from "../../ui_components/DreamRadioButtons/DreamRadioButtonsComponent";
import * as LocationsHelper from "../LocationsComponent/LocationsHelper";
import * as DreamAutocompleteHelper from "../../ui_components/DreamAutocomplete/DreamAutocompleteHelper";
import CompanyProfileDispatchContext from "../../Pages/CompanyProfilePage/Context/DispatchContext";
import CompanyProfileStateContext from "../../Pages/CompanyProfilePage/Context/StateContext";
import DreamAutocomplete from "../../ui_components/DreamAutocomplete/DreamAutocomplete";
import * as Constants from "../../helpers/Constants";
import * as Colors from "../../styles/01.settings/01-settings-colors.module.scss";
import { makeStyles } from "@material-ui/core/styles";
import DreamOnClickTooltip from "../../ui_components/DreamOnClickTooltip/DreamOnClickTooltip";
import * as BaseHelper from "../../helpers/BaseHelper";

const useStyles = makeStyles(() => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  smallFont: {
    fontSize: 14,
  },
}));

function CompanyLocation(props) {
  const classes = useStyles();

  const companyProfileDispatchContext = useContext(
    CompanyProfileDispatchContext
  );
  const companyProfileStateContext = useContext(CompanyProfileStateContext);

  function locationTypeChanged(value) {
    companyProfileDispatchContext({
      type: "locationTypeChanged",
      value,
    });
    companyProfileDispatchContext({
      type: "setLocation",
      value: {},
    });
  }

  function locationChanged(value) {
    companyProfileDispatchContext({
      type: "setLocation",
      value: value ? value : {},
    });
  }

  const autocompleteComponent = (
    <div style={{ display: "flex", flexWrap: "noWrap" }}>
      <DreamAutocomplete
        freeSolo
        clearOnBlur
        fullWidth
        label={"cv.locationChoose"}
        usage={"location"}
        classes={{ inputRoot: classes.smallFont, listbox: classes.smallFont }}
        searchType={companyProfileStateContext.locationType}
        apiUrl={`${Constants.BASE_URL}location`}
        searchParamName={"search_string"}
        additionalApiParams={{ type: companyProfileStateContext.locationType }}
        changeHandler={locationChanged}
        isMandatory={true}
        preselectedValue={companyProfileStateContext.location}
      />
      {props.tooltipVisible ? (
        <DreamOnClickTooltip tooltipContent={BaseHelper.locationTooltipBG} />
      ) : (
        ""
      )}
    </div>
  );

  return (
    <div className={classes.wrapper}>
      <DreamRadioButtonsComponent
        formLabel={"Location"}
        formLabelColor={Colors.lightGray}
        formLabelFontSize={12}
        formLabelTextAlign={"left"}
        name={"jp.locations"}
        value={companyProfileStateContext.locationType}
        data={LocationsHelper.locationsRadioButtons}
        changeHandler={locationTypeChanged}
      />
      {/* 
        The easiest way to reset Autocomplete value is
        just to unmount and mount it when the location type is changed
       */}
      {companyProfileStateContext.locationType ==
      DreamAutocompleteHelper.SEARCH_TYPE_CITY
        ? autocompleteComponent
        : ""}
      {companyProfileStateContext.locationType ==
      DreamAutocompleteHelper.SEARCH_TYPE_STATE
        ? autocompleteComponent
        : ""}
      {companyProfileStateContext.locationType ==
      DreamAutocompleteHelper.SEARCH_TYPE_COUNTRY
        ? autocompleteComponent
        : ""}
    </div>
  );
}

export default CompanyLocation;
