import React, { useEffect, useContext } from "react";
import DreamFileUploader from "../../ui_components/DreamFileUploaderMultiple/DreamFileUploader/DreamFileUploader";
import * as CertificatesReducer from "./CerificatesReducer";
import MyCvDispatchContext from "../../Pages/MyCvPage/Context/MyCvDispatchContext";
import MyCvStateContext from "../../Pages/MyCvPage/Context/MyCvStateContext";
import JobPreferenceStateContext from "../../Pages/CreateJobPreferencePage/Context/JobPreferenceStateContext";
import JobPreferenceDispatchContext from "../../Pages/CreateJobPreferencePage/Context/JobPreferenceDispatchContext";

function CertificatesComponent(props) {
  const myCvDispatch = useContext(MyCvDispatchContext);
  const myCvContext = useContext(MyCvStateContext);

  const jobPreferenceStateContext = useContext(JobPreferenceStateContext);
  const jobPreferenceDispatchContext = useContext(JobPreferenceDispatchContext);
  const dispatchContext =
    props.dispatchContextName === "jobPreferenceDispatchContext"
      ? jobPreferenceDispatchContext
      : myCvDispatch;

  const stateContext =
    props.stateContextName === "jobPreferenceStateContext"
      ? jobPreferenceStateContext
      : myCvContext;

  const data =
    props.stateContextName === "jobPreferenceStateContext"
      ? stateContext.data.cv
      : stateContext;

  function upDispatchHandler(value) {
    dispatchContext({
      type: "certificatesCertificatesChanged",
      value: value.value,
    });
  }

  function certificateManualyChanged() {
    dispatchContext({
      type: "cvCertificateManualyChanged",
    });
  }

  return (
    <DreamFileUploader
      upDispatch={upDispatchHandler}
      maxFiles={10}
      accept={"image/*, application/pdf, .doc, .docx"}
      initialFiles={data.initialFileUploads}
      loadedDataBe={data.initialDataLoaded}
      useDescription={true}
      somethingManualyChangedHandler={certificateManualyChanged}
    />
  );
}

export default CertificatesComponent;
