import React, { useEffect, useContext } from "react";
import DreamRadioButtonsComponent from "../../ui_components/DreamRadioButtons/DreamRadioButtonsComponent";
import * as EmploymentTypeHelper from "./EmploymentTypeHelper";
import JobPreferenceDispatchContext from "../../Pages/CreateJobPreferencePage/Context/JobPreferenceDispatchContext";
import JobPreferenceStateContext from "../../Pages/CreateJobPreferencePage/Context/JobPreferenceStateContext";

function EmploymentTypeComponent() {
  const jobPreferenceDispatchContext = useContext(JobPreferenceDispatchContext);
  const jobPreferenceStateContext = useContext(JobPreferenceStateContext);

  function employmentTypeChanged(value) {
    jobPreferenceDispatchContext({
      type: "employmentTypeChanged",
      value: parseInt(value),
    });
  }

  return (
    <DreamRadioButtonsComponent
      name={"jp.employment"}
      value={jobPreferenceStateContext.formData.employmentType.employmentType}
      data={EmploymentTypeHelper.employmentTypeRadioButtons}
      changeHandler={employmentTypeChanged}
    />
  );
}

export default EmploymentTypeComponent;
