import * as Constants from "../../helpers/Constants";

export async function getAdditionalSkills(
  Axios,
  ourRequest,
  dispatch,
  appDispatch
) {
  try {
    const response = await Axios.get(`dreamjob/skills`, {
      cancelToken: ourRequest.token,
    }).catch(function (error) {
      throw error;
    });

    let formattedSkills = formatInitialData(response.data.data);

    dispatch({
      type: "additionalSkillsGotSkills",
      value: formattedSkills,
    });
  } catch (error) {
    appDispatch({
      type: "showMsg",
      value: {
        status: Constants.SNACKBAR_ERROR,
        msg: "basic.errorAdditionalSkills",
      },
    });
  }
}

export function formatArrayData(data) {
  let formattedSkills = [];
  for (const industry in data) {
    for (const property in data[industry]) {
      formattedSkills.push({
        id: property,
        label: data[industry][property],
        isChecked: false,
      });
    }
  }

  return formattedSkills;
}

export function formatInitialData(data) {
  let formattedSkills = {};
  for (const industry in data) {
    formattedSkills[industry] = [];
    for (const property in data[industry]) {
      formattedSkills[industry].push({
        id: property,
        label: data[industry][property],
        isChecked: false,
      });
    }
  }

  return formattedSkills;
}

export function getOnlyChecked(data) {
  let checked = [];
  for (const industry in data) {
    data[industry].forEach((element) => {
      if (element.isChecked === true) {
        checked.push(element.id);
      }
    });
  }

  return checked;
}
