import FormValidator from "../../../helpers/FormValidator";

export const allInputs = {
  email: {
    value: "",
    isValid: true,
    errorMessage: "",
  },
};

export const originalState = {
  fields: { ...allInputs },
  hasValidationErrs: false,
  formSubmittedCount: 0,
  loadedDataBe: false,
  formSuccessfullySubmittedCount: 0,
};

export function reducer(draft, action) {
  switch (action.type) {
    case "emailChange":
      draft.fields.email.value = action.value.value;
      draft.fields.email.errorMessage = FormValidator.validateField(
        action.value.value,
        { email: true },
        action.value.t
      );
      draft.fields.email.isValid = !Boolean(draft.fields.email.errorMessage);

      // Check if Form has validation errors
      draft.hasValidationErrs = FormValidator.hasFormValidationErrors(
        draft,
        allInputs
      );
      return;

    case "userDataLoaded":
      const userData = action.value;

      draft.fields.email.value = userData.email === null ? "" : userData.email;
      draft.loadedDataBe = true;

      return;

    case "formSubmitted":
      draft.hasValidationErrs = FormValidator.hasFormValidationErrors(
        draft,
        allInputs
      );
      if (!draft.hasValidationErrs) {
        draft.formSubmittedCount++;
      }
      return;
    case "formSuccessfullySubmitted":
      draft.loadedDataBe = false;
      draft.formSuccessfullySubmittedCount++;
    case "disableSubmitButton":
      draft.hasValidationErrs = action.value;
      return;
  }
}
