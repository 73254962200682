export const originalState = {
  value: "",
};

export function reducer(draft, action) {
  switch (action.type) {
    case "valueChange":
      draft.value = action.value;
      return;
    default:
  }
}
