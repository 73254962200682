import React, { useContext } from "react";
import MyCvDispatchContext from "../../Pages/MyCvPage/Context/MyCvDispatchContext";
import MyCvStateContext from "../../Pages/MyCvPage/Context/MyCvStateContext";
import DreamCheckboxComponent from "../../ui_components/DreamCheckbox/DreamCheckboxComponent";
import JobPreferenceStateContext from "../../Pages/CreateJobPreferencePage/Context/JobPreferenceStateContext";
import JobPreferenceDispatchContext from "../../Pages/CreateJobPreferencePage/Context/JobPreferenceDispatchContext";
import DreamMultipleSelect from "../../ui_components/DreamMultiselectComponent/DreamMultiselectComponent";

function HobbiesComponent(props) {
  const myCvDispatch = useContext(MyCvDispatchContext);
  const myCvContext = useContext(MyCvStateContext);

  const jobPreferenceStateContext = useContext(JobPreferenceStateContext);
  const jobPreferenceDispatchContext = useContext(JobPreferenceDispatchContext);
  const dispatchContext = props.dispatchContextName
    ? eval(props.dispatchContextName)
    : myCvDispatch;

  const stateContext = props.stateContextName
    ? eval(props.stateContextName)
    : myCvContext;

  const formData =
    props.stateContextName === "jobPreferenceStateContext"
      ? stateContext.formData.cv
      : stateContext.formData;

  function checkboxChangeHandler(event) {
    // dispatchContext({
    //   type: "hobbiesChanged",
    //   value: { id: checkbox.id, isChecked: checkbox.isChecked },
    // });
    let length = event.target.value.length;
    let checkbox = event.target.value.filter(
      (x) => x.id == event.target.value[length - 1]
    );

    dispatchContext({
      type: "hobbiesChanged",
      value: {
        id: event.target.value[length - 1],
        isChecked: !checkbox[0].isChecked,
      },
    });
  }

  return (
    // <DreamCheckboxComponent
    //   data={formData.hobbies}
    //   changeHandler={checkboxChangeHandler}
    // />
    <DreamMultipleSelect
      manualyChangeHandler={checkboxChangeHandler}
      value={formData.hobbies}
      dispatchContext={dispatchContext}
      stateContext={stateContext}
    />
  );
}

export default HobbiesComponent;
