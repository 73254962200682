import * as BcHelper from "./BuyCreditsPageHelper";

export const originalState = {
  formData: {
    currency: "",
    quantity: 1,
    packageType: 1,
    method_id: "",
  },
  currencies: [],
  balance: 0,
  packages: [],
  sum: 0,
  packagePrice: 1,
  radioButtons: [],
  packagesDataSuccessfullyLoaded: false,
  succesfullyGotMethodIdCounter: 0,
  languageChangedCounter: 0,
  paymentBeResponse: "",
  isProcessing: false,
  isTransactionSuccessful: false,
};

export function reducer(draft, action) {
  switch (action.type) {
    case "currencyChanged":
      draft.formData.currency = action.value;
      break;
    case "quantityChanged":
      draft.formData.quantity = action.value;
      break;
    case "packageChanged":
      draft.formData.packageType = action.value;
      break;
    case "gotMethodId":
      draft.formData.method_id = action.value;
      break;
    case "succesfullyGotMethodIdCounter":
      draft.succesfullyGotMethodIdCounter++;
      break;
    case "volumeReset":
      draft.formData.volume = "";
      break;
    case "setIsProcessing":
      draft.isProcessing = action.value;
      break;
    case "setTransactionSuccessful":
      draft.isTransactionSuccessful = true;
      break;
    case "packagesDataLoaded":
      let data = action.value.data;
      let arr = Object.keys(data);
      // Set currencies
      draft.currencies = arr;

      //Set initial currency
      draft.formData.currency = arr[0];

      let packages = Object.keys(data).map((key) => ({
        id: key.toString(),
        package: Object.keys(data[key]).map((k) => ({
          id: k.toString(),
          item: data[key][k],
        })),
      }));

      draft.packages = packages;

      // Set initial radio buttons
      draft.radioButtons = BcHelper.formatPackagesButtons(
        arr[0],
        packages,
        action.value.t
      );

      let price = draft.radioButtons.filter((x) => x.value == 1);

      draft.sum = BcHelper.calculateSum(
        draft.formData.quantity,
        price[0].price
      );
      break;
    case "packagesDataSuccessfullyLoaded":
      draft.packagesDataSuccessfullyLoaded = true;
      break;
    case "balanceDataLoaded":
      draft.balance = action.value;
      break;
    case "radioButtonsChanged":
      draft.radioButtons = action.value;
      break;
    case "sumChanged":
      draft.sum = action.value;
      break;
    case "setLanguageChangedCounter":
      draft.languageChangedCounter++;
      break;
    case "languageChanged":
      draft.radioButtons = BcHelper.formatPackagesButtons(
        action.value.currency,
        action.value.packages,
        action.value.t
      );
      break;
  }
}
