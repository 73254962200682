import React, { useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import * as Constants from "../helpers/Constants";
import DispatchContext from "../Context/DispatchContext";
import AppContext from "../Context/StateContext";
import { useTranslation } from "react-i18next";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import BackButton from "../ui_components/DreamBackButton/BackButton";

function DreamPage(props) {
  const appDispatch = useContext(DispatchContext);
  const appContext = useContext(AppContext);

  const theme = useTheme();
  const matchesXSdown = useMediaQuery(theme.breakpoints.down("xs"));
  const location = useLocation();

  const useStyles = makeStyles((theme) => ({
    dreamPageWrapper: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      paddingLeft: appContext.appState.loggedIn ? 20 : 0,
      paddingRight: appContext.appState.loggedIn ? 20 : 0,
      marginBottom: 20,
    },
    headingWrapper: {
      display: "flex",
      width: "100%",
    },
    pageTitle: {
      width: props.backButton ? "60%" : "100%",
      textAlign: "center",
      fontSize: matchesXSdown ? "1.2rem" : "1.875rem",
      marginTop: matchesXSdown ? 5 : "0.67em",
      marginBottom: matchesXSdown ? 5 : "0.67em",
    },
  }));

  const classes = useStyles();

  const [t] = useTranslation(Constants.TRANSLATION_COMMON);

  useEffect(() => {
    document.title = `${t(props.title)} | ${Constants.SITE_NAME}`;
    window.scrollTo(0, 0);
  }, [props.title]);

  useEffect(() => {
    appDispatch({ type: "setSelectedFooterPage", value: props.route });
  }, [location]);

  return (
    <div
      className={classes.dreamPageWrapper}
      style={props.dreamPageWrapperStyle}
    >
      <div className={classes.headingWrapper}>
        {props.backButton ? (
          <div
            style={{
              display: "flex",
              width: "30%",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <BackButton
              backButtonDisabled={props.backButtonDisabled}
              handleClick={props.handleClick}
              backButtonStyle={{ width: 60 }}
              to={props.backButtonTo}
            />
          </div>
        ) : (
          ""
        )}
        <h1 className={classes.pageTitle}>{t(props.title)}</h1>
        {props.backButton ? <div style={{ width: "30%" }}></div> : ""}
      </div>
      {props.children}
    </div>
  );
}

export default DreamPage;
