import * as DreamAutocompleteHelper from "../../../ui_components/DreamAutocomplete/DreamAutocompleteHelper";

export const originalState = {
  id: "",
  locationType: DreamAutocompleteHelper.SEARCH_TYPE_CITY,
  location: {},
};

export function reducer(draft, action) {
  switch (action.type) {
    case "setId":
      draft.id = action.value;
      return;
    case "setLocationType":
      draft.locationType = action.value;
      return;
    case "setLocation":
      draft.location = action.value;
      return;
  }
}
