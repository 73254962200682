export const additionalBenefits = [
  {
    id: "paid_leave",
    label: "jo.additionalPaidLeave",
    isChecked: false,
  },
  {
    id: "food_vouchers",
    label: "jo.foodVouchers",
    isChecked: false,
  },
  {
    id: "housing",
    label: "jo.housing",
    isChecked: false,
  },
  {
    id: "car",
    label: "jo.companyCar",
    isChecked: false,
  },
  {
    id: "phone",
    label: "jo.companyMobilePhone",
    isChecked: false,
  },
];
