import React, { useEffect, useContext, useState } from "react";
import { useImmerReducer } from "use-immer";
import * as Constants from "../../helpers/Constants";
import { useTranslation } from "react-i18next";
import StateContext from "../../Context/StateContext";
import * as DreamAutocompleteHelper from "./DreamAutocompleteHelper";

import * as DreamAutocompleteReducer from "./DreamAutocompleteReducer";

import fetch from "cross-fetch";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";

function DreamAutocomplete(props) {
  const [state, dispatch] = useImmerReducer(
    DreamAutocompleteReducer.reducer,
    DreamAutocompleteReducer.originalState
  );

  // Used to rerender component on change language to render selected option in selected language
  const [autoCompleteKey, setValue] = useState(0); // integer state

  const appContext = useContext(StateContext);
  const [t] = useTranslation(Constants.TRANSLATION_COMMON);

  const loading = state.open && state.options.length === 0;

  useEffect(() => {
    let active = true;

    if (props.usage === "location" ? !Boolean(state.requestCount) : !loading) {
      return undefined;
    }

    if (props.usage === "location") {
      dispatch({ type: "setIsLoading", value: true });
    }

    (async () => {
      let url = `${props.apiUrl}?${props.searchParamName}=${state.searchTerm}`;

      // If we need to append addirtional params to API url
      if (props.additionalApiParams) {
        url += DreamAutocompleteHelper.formatUrlParams(
          props.additionalApiParams
        );
      }

      const response = await fetch(url);
      const items = await response.json();

      if (props.usage === "location") {
        dispatch({ type: "setIsLoading", value: false });
      }

      if (active) {
        let formattedOptions = DreamAutocompleteHelper.formatOptions(
          items.data,
          props.searchType,
          t,
          appContext.appState.lang
        );

        dispatch({
          type: "setOptions",
          value: formattedOptions,
        });
      }
    })();
    // }

    return () => {
      active = false;
    };
  }, [props.usage === "location" ? state.requestCount : loading]);

  useEffect(() => {
    if (!state.open) {
      dispatch({ type: "setOptions", value: [] });
    }
  }, [state.open]);

  // Used to rerender component on change language to render selected option in selected language
  useEffect(() => {
    setValue((autoCompleteKey) => autoCompleteKey + 1);
  }, [appContext.appState.lang]);

  // Trigger search after given delay
  useEffect(() => {
    if (state.searchTerm.trim().length > 0) {
      const delay = setTimeout(() => {
        dispatch({ type: "setRequestCount" });
      }, 750);

      return () => clearTimeout(delay);
    }
  }, [state.searchTerm, loading]);

  const getOptionLabel = (option) => {
    return option.name
      ? option.name
      : props.zeroElementTypeAll
      ? t("basic.all")
      : "";
  };

  useEffect(() => {
    if (props.resetSearchTermCounter > 0) {
      dispatch({ type: "setSearchTerm", value: "" });
    }
  }, [props.resetSearchTermCounter]);

  const mandatorySign = props.isMandatory ? "*" : "";

  return (
    <Autocomplete
      key={autoCompleteKey}
      style={props.style}
      classes={props.classes}
      fullWidth={props.fullWidth}
      noOptionsText={null}
      freeSolo={props.freeSolo}
      value={props.preselectedValue}
      disabled={props.acDisabled}
      autoComplete={props.autoComplete}
      clearOnBlur={props.clearOnBlur}
      open={state.open}
      onOpen={() => {
        dispatch({ type: "changeOpen", value: true });
      }}
      onClose={() => {
        dispatch({ type: "changeOpen", value: false });
      }}
      onChange={(event, newValue) => {
        props.changeHandler(newValue);
      }}
      getOptionSelected={(option, value) => {
        return option.name === value.name;
      }}
      getOptionLabel={getOptionLabel}
      options={state.options}
      loading={state.isLoading}
      renderInput={(params) => (
        <TextField
          onChange={(e) => {
            const value = e.target.value;
            dispatch({ type: "setSearchTerm", value: value });
          }}
          {...params}
          label={t(props.label) + mandatorySign}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {state.isLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}

export default DreamAutocomplete;
