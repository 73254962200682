export const originalState = {
  isDataFormatted: false,
  formattedData: [],
  dataLoadedTimes: 0,
};

export function reducer(draft, action) {
  switch (action.type) {
    case "setFormattedData":
      draft.formattedData = action.value;
      draft.isDataFormatted = true;
      return;
    case "newDataLoaded":
      draft.dataLoadedTimes++;
      return;
  }
}
