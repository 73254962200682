import * as Constants from "../helpers/Constants";

class AppReducer {
  reducer(draft, action) {
    switch (action.type) {
      case "langChanged":
        draft.appState.lang = action.value;
        return;
      case "openLoginModal":
        draft.appState.openLoginModal = action.value;
        return;
      case "setUserAvatar":
        draft.appState.user_avatarId = action.value;
        return;
      case "login":
        draft.appState.loggedIn = true;
        draft.appState.user_token = action.value.access_token;
        draft.appState.user_id = action.value.user_id;
        draft.appState.user_avatarId = action.value.logo
          ? action.value.logo
          : action.value.picture;
        draft.appState.user_CvId = action.value.cv_id;
        draft.appState.user_Reghash = action.value.reghash;
        draft.appState.user_AgreedCookies = action.value.agreed_cookies
          ? true
          : false;
        draft.appState.isDrawerOpen = true;
        draft.appState.tokenExpiresIn = action.value.expires_in;
        return;
      case "logout":
        draft.appState.loggedIn = false;
        draft.appState.isDrawerOpen = false;
        return;
      case "openSignUpModal":
        draft.appState.openSignUpModal = action.value;
        return;
      case "showMsg":
        draft.appState.snackbar.open = true;
        draft.appState.snackbar.status = action.value.status;
        draft.appState.snackbar.msg = action.value.msg;
        return;
      case "hideMsg":
        draft.appState.snackbar.open = false;
        return;
      case "showBackdrop":
        draft.appState.isBackdropOpen = action.value;
        return;
      case "setSelectedFooterPage":
        draft.appState.pageSelected = action.value;
        break;
      case "setUserDefaultCvId":
        draft.appState.user_CvId = action.value;
        localStorage.setItem(
          `${Constants.LOCAL_STORAGE_SITE_NAME}UserCvId`,
          draft.appState.user_CvId
        );
        break;
      case "startTour":
        draft.appState.joyride.run = true;
        break;
      case "stopTour":
        draft.appState.joyride.run = false;
        break;
      case "setTourIndex":
        draft.appState.joyride.stepIndex = action.value;
        break;
      case "setLocation":
        draft.appState.location = action.value;
        break;
      case "setDrawerOpen":
        draft.appState.toggleDrawer.left = action.value;
        break;
      case "setIsProfileDataFilled":
        draft.appState.isProfileDataFilled = action.value;
        break;
      case "setIsCvFilled":
        draft.appState.isCvFilled = action.value;
        break;
      case "setIsCompanyProfileDataFilled":
        draft.appState.isCompanyProfileDataFilled = action.value;
        break;
      case "setCookiesDialogOpen":
        draft.appState.isCookiesDialogOpen = action.value;
        break;
      case "setAgreeCookies":
        draft.appState.user_AgreedCookies = action.value;
        break;
    }
  }
}
export default AppReducer;
