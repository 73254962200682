import * as Constants from "../../helpers/Constants";

export const DATA_TYPE_INDUSTRY = 1;
export const DATA_TYPE_LANG_LEVEL = 2;
export const DATA_TYPE_BROAD_EDUCATION = 3;
export const DATA_TYPE_DETAILED_EDUCATION = 4;

export async function getOptions(
  urlAPI,
  Axios,
  ourRequest,
  appDispatch,
  dispatch,
  dataType
) {
  let errMsgs;
  let authenticated = true;
  try {
    const response = await Axios.get(`${Constants.BASE_URL}${urlAPI}`, {
      cancelToken: ourRequest.token,
    }).catch(function (error) {
      // Errors to be sent to scanckbar
      errMsgs = "basic.serverError";
      if (
        error.response &&
        error.response.status &&
        error.response.status === Constants.ERROR_RESPONSE_STATUS
      ) {
        authenticated = false;
        errMsgs = "basic.unauthenticated";
      }
      throw errMsgs;
    });

    const responseData = response.data;
    handleSuccessfulGetData(responseData, dispatch, dataType);
  } catch (error) {
    appDispatch({
      type: "showMsg",
      value: { status: Constants.SNACKBAR_ERROR, msg: error },
    });
    if (!authenticated) {
      appDispatch({
        type: "logout",
      });
      appDispatch({ type: "openLoginModal", value: true });
    } else {
      dispatch({ type: "disableSubmitButton", value: false });
    }
  }
}

export function handleSuccessfulGetData(data, dispatch, dataType) {
  let formattedData = [];
  switch (dataType) {
    case DATA_TYPE_INDUSTRY:
      formattedData = formatIndustries(data.data);
      break;
    case DATA_TYPE_BROAD_EDUCATION:
      formattedData = formatBroadEducations(data.data);
      break;
    case DATA_TYPE_DETAILED_EDUCATION:
      formattedData = formatEducationDetailedType(data.data);
      break;

    case DATA_TYPE_LANG_LEVEL:
      formattedData = { data: data.extra_data };
      break;
    default:
      formattedData = data;
      break;
  }
  dispatch({ type: "optionsDataLoaded", value: formattedData });
}

export function handleChange(value, dispatch, dispatchChangeHandler) {
  dispatch({ type: "selectedChanged", value });
  if (dispatchChangeHandler) {
    dispatchChangeHandler(value);
  }
}

function formatIndustries(data) {
  let formattedIndustries = { data: {} };

  data.forEach((element) => {
    formattedIndustries.data[element.id] = `industries.${element.id}`;
  });

  return formattedIndustries;
}

function formatBroadEducations(data) {
  let formattedBroadEducations = { data: {} };
  data.forEach((element) => {
    formattedBroadEducations.data[element.id] = element.name;
  });

  return formattedBroadEducations;
}

function formatEducationDetailedType(data) {
  let formattedDetailedType = { data: {} };
  data.forEach((element) => {
    formattedDetailedType.data[
      element.id
    ] = `educationDetailed.${element.name}`;
  });

  return formattedDetailedType;
}
