import React, { useEffect } from "react";
import { useImmer, useImmerReducer } from "use-immer";
import SingleItemFileUploaderComponent from "../SingleItemFileUploaderComponent/SingleItemFileUploaderComponent";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import CloseIcon from "@material-ui/icons/Close";
import { IconBtn } from "./PreviewFileComponent.module.scss";

import * as PreviewFileHelper from "./PreviewFileHelper";

import Grid from "@material-ui/core/Grid";

function PreviewFileComponent(props) {
  const initialState = { fieldValue: [] };

  function reducer(draft, action) {
    switch (action.type) {
      case "filedChanged":
        draft.fieldValue = { value: action.value.value, id: action.value.id };
        break;
    }
  }

  const [state, dispatch] = useImmerReducer(reducer, initialState);

  function handleDescriptionChange(e) {
    dispatch({
      type: "filedChanged",
      value: { value: e.target.value, id: e.target.id },
    });
    props.dispatchFileUpload({
      type: "descriptionChanged",
      value: { value: e.target.value, id: e.target.id },
    });

    if (props.somethingManualyChangedHandler) {
      props.somethingManualyChangedHandler();
    }
  }

  return props.uploadedFiles.map((value, index) => (
    <Grid key={index} item style={{ marginBottom: 20 }}>
      <SingleItemFileUploaderComponent
        data={value}
        state={state}
        handleDescriptionChange={handleDescriptionChange}
        handleDelete={PreviewFileHelper.handleDelete}
        useDescription={props.useDescription}
        dispatchFileUpload={props.dispatchFileUpload}
        isInitialUpload={props.isInitialUpload}
        somethingManualyChangedHandler={props.somethingManualyChangedHandler}
      />
    </Grid>
  ));
}

export default PreviewFileComponent;
