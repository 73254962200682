import React, { useContext } from "react";
import JobPreferenceDispatchContext from "../../Pages/CreateJobPreferencePage/Context/JobPreferenceDispatchContext";
import JobPreferenceStateContext from "../../Pages/CreateJobPreferencePage/Context/JobPreferenceStateContext";
import DreamMultipleSelect from "../../ui_components/DreamMultiselectComponent/DreamMultiselectComponent";

function AdditionalBenefitsComponent() {
  const jobPreferenceDispatchContext = useContext(JobPreferenceDispatchContext);
  const jobPreferenceStateContext = useContext(JobPreferenceStateContext);

  function chipsChangeHandler(event) {
    let length = event.target.value.length;
    jobPreferenceDispatchContext({
      type: "additionalBenefitsChanged",
      value: event.target.value[length - 1],
    });
  }

  return (
    <React.Fragment>
      <DreamMultipleSelect
        value={jobPreferenceStateContext.formData.additionalBenefits}
        manualyChangeHandler={chipsChangeHandler}
        dispatchContext={jobPreferenceDispatchContext}
        stateContext={jobPreferenceStateContext}
      />
    </React.Fragment>
  );
}

export default AdditionalBenefitsComponent;
