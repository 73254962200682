export const DAY_SHIFT = 1;
export const NIGHT_SHIFT = 2;
export const ANY_SHIFT = 0;

export const workScheduleRadioButtons = [
  {
    label: "workPlace.any",
    value: ANY_SHIFT,
  },
  {
    label: "workSchedule.dayShift",
    value: DAY_SHIFT,
  },
  {
    label: "workSchedule.nightShift",
    value: NIGHT_SHIFT,
  },
];
