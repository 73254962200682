import React, { useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { useImmerReducer } from "use-immer";
import { useTranslation } from "react-i18next";
import * as Constants from "../../helpers/Constants";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import MailIcon from "@material-ui/icons/Mail";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import * as Colors from "../../styles/01.settings/01-settings-colors.module.scss";
import * as JoReducer from "./JobOffersPageReducer";
import * as JoHelper from "./JobOffersPageHelper";
import DreamCardMedia from "../../ui_components/DreamCardMediaComponent/DreamCardMedia";
import DreamButton from "../../ui_components/DreamButton/DreamButton";

import DreamPage from "../../ui_components/DreamPage";
import Axios from "axios";
import * as AppRoutes from "../../AppComponent/AppRoutes";
import DispatchContext from "../../Context/DispatchContext";
import StateContext from "../../Context/StateContext";
import { Link as RouterLink, Redirect } from "react-router-dom";
import DreamConfirmModal from "../../ui_components/DreamConfirmModalComponent/DreamConfirmModalComponent";
import PropTypes from "prop-types";
import TablePagination from "@material-ui/core/TablePagination";

const pageTitle = "drawer.jobOffers";

const JobOffers = () => {
  const [t] = useTranslation(Constants.TRANSLATION_COMMON);
  const appDispatch = useContext(DispatchContext);
  const appContext = useContext(StateContext);

  const [state, dispatch] = useImmerReducer(
    JoReducer.reducer,
    JoReducer.originalState
  );

  const useStyles = makeStyles({
    table: {
      maxWidth: 770,
    },
    title: {
      fontSize: 24,
      fontWeight: "bold",
    },
    tableCell: {
      borderBottom: "none",
      padding: 8,
    },
    tblContainer: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      overflowX: "hidden",
    },
    tblRow: { width: "100%" },
    actionsButton: {},
    addButton: {
      width: "10em",
    },
    menuButton: {
      width: "100%",
    },
    subHeader: {
      color: Colors.success,
    },
    subHeaderDanger: {
      color: Colors.danger,
    },
    paginationRoot: {
      display: "flex",
      justifyContent: "center",
    },
    paginationToolbar: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "center",
    },
  });

  const classes = useStyles();

  const handleChangePage = (event, newPage) => {
    dispatch({ type: "setPage", value: newPage });
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch({
      type: "setRowsPerPage",
      value: parseInt(event.target.value, 10),
    });
    dispatch({ type: "setPage", value: 0 });
  };

  const emptyRows =
    state.pagination.currentPageSize -
    Math.min(
      parseInt(state.pagination.currentPageSize, 10),
      parseInt(state.pagination.totalCount, 10).length -
        state.pagination.currentPage *
          parseInt(state.pagination.currentPageSize, 10)
    );

  const isCompanyDataFilled = JSON.parse(
    localStorage.getItem("CompanyProfileDataFilled")
  );

  // On page_size or page change
  useEffect(() => {
    if (isCompanyDataFilled) {
      const ourRequest = Axios.CancelToken.source();

      JoHelper.getJobOffers(
        state.pagination.currentPageSize,
        state.pagination.currentPage + 1,
        Axios,
        ourRequest,
        appDispatch,
        dispatch
      );

      return () => {
        ourRequest.cancel();
      };
    }
  }, [
    isCompanyDataFilled,
    state.pagination.currentPageSize,
    state.pagination.currentPage,
  ]);

  const handleClickModalOpen = (offer) => {
    dispatch({ type: "setModalOpen", value: true });
    dispatch({ type: "setDeleteId", value: offer.id });
  };

  const handleModalClose = () => {
    dispatch({ type: "setModalOpen", value: false });
  };

  const userHasCompanyDataFilled = (
    <Grid container>
      <Grid item xs={12}>
        <TableContainer className={classes.tblContainer}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
          >
            <TableBody>
              {state.jobOffers.map((offer, index) => (
                <TableRow key={offer.id} className={classes.tblRow}>
                  <TableCell className={classes.tableCell}>
                    <DreamCardMedia
                      cardHeader
                      cardHeaderTitle={t(offer.name)}
                      headerAction
                      cardActions
                      cardContentStyle={{ paddingTop: 8, paddingBottom: 8 }}
                      cardContent={[
                        offer.industry_id
                          ? t(`industries.${offer.industry_id}`)
                          : t("jo.allIndustries"),
                        offer.position_id
                          ? t(`positions.${offer.position_id}`)
                          : t("jo.allPositions"),
                        JoHelper.formatSalaryData(offer),
                        offer.locations.length > 0
                          ? offer.locations.map((loc, index) => (
                              <span key={index} style={{ margin: 0 }}>
                                {loc}
                              </span>
                            ))
                          : t("jos.anyLocation"),
                      ]}
                      cardActionsChildren={
                        <React.Fragment>
                          <DreamButton
                            className={classes.actionsButton}
                            kind="contained"
                            buttonLabel={t("jos.search")}
                            color={"primary"}
                            buttonLabelColor={Colors.white}
                            btnComponent={RouterLink}
                            to={`/job-offer-view/${offer.id}`}
                            icon={<SearchOutlinedIcon />}
                          />
                          <DreamButton
                            className={classes.actionsButton}
                            kind="contained"
                            buttonLabel={t("jos.messages")}
                            color={"secondary"}
                            btnComponent={RouterLink}
                            to={`/job-offer-candidates-with-messages/${offer.id}`}
                            icon={<MailIcon />}
                          />
                        </React.Fragment>
                      }
                      menuStyle={{
                        position: "absolute",
                        right: 0,
                      }}
                      menuItems={[
                        {
                          child: (
                            <DreamButton
                              className={classes.menuButton}
                              kind="contained"
                              buttonLabel={t("jos.edit")}
                              color={"secondary"}
                              btnComponent={RouterLink}
                              to={`/job-offer-update/${offer.id}`}
                              icon={
                                <CreateOutlinedIcon
                                  htmlColor={Colors.warning}
                                />
                              }
                            />
                          ),
                        },
                        {
                          child: (
                            <DreamButton
                              className={classes.menuButton}
                              kind="contained"
                              buttonLabel={t("jos.delete")}
                              color={"secondary"}
                              handleClick={(e) => handleClickModalOpen(offer)}
                              icon={
                                <DeleteOutlinedIcon htmlColor={Colors.danger} />
                              }
                            />
                          ),
                        },
                      ]}
                    />
                  </TableCell>
                </TableRow>
              ))}
              {emptyRows > 0 && (
                <TableRow>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
              <TableRow className={classes.tblRow}>
                <TableCell
                  align="center"
                  colSpan={12}
                  style={{ borderBottom: "none" }}
                >
                  <DreamButton
                    className={classes.addButton}
                    kind="contained"
                    buttonLabel={t("jos.add")}
                    color={"primary"}
                    buttonLabelColor={Colors.white}
                    btnComponent={RouterLink}
                    to={`${AppRoutes.ROUTE_CREATE_JOB_OFFER}`}
                    icon={<AddCircleOutlineOutlinedIcon />}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          classes={{
            root: classes.paginationRoot,
            actions: classes.paginationActions,
            input: classes.paginationInput,
            toolbar: classes.paginationToolbar,
            selectIcon: classes.paginationSelectIcon,
            caption: classes.caption,
          }}
          labelRowsPerPage={t("tablePagination.rowsPerPage")}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} ${t("tablePagination.of")} ${
              count !== -1 ? count : `${t("tablePagination.moreThan")} ${to}`
            }`
          }
          rowsPerPageOptions={JoHelper.rowsPerPageOptions}
          component="div"
          count={parseInt(state.pagination.totalCount, 10)}
          rowsPerPage={state.pagination.currentPageSize}
          page={parseInt(state.pagination.currentPage, 10)}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Grid>
    </Grid>
  );

  if (isCompanyDataFilled) {
    return (
      <DreamPage title={t(pageTitle)}>
        <DreamConfirmModal
          isOpen={state.isModalOpen}
          handleClose={handleModalClose}
          title={"jos.confirmDelete"}
          firstRow={"jos.deleteModalText"}
          handleCancel={handleModalClose}
          buttonCancelLabel={"basic.cancel"}
          handleConfirm={(e) =>
            JoHelper.handleDelete(
              state.lastDeletedId,
              Axios,
              appDispatch,
              dispatch
            )
          }
          buttonConfirmLabel={"basic.confirm"}
        />
        {userHasCompanyDataFilled}
      </DreamPage>
    );
  } else {
    return (
      <Redirect
        to={{
          pathname: "/company-profile",
          state: { fromJOs: "/job-offers" },
        }}
      />
    );
  }
};

export default JobOffers;
