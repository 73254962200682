import * as Constants from "../../helpers/Constants";

export const DRAWER_WIDTH = 240;

export function logOutHandler(appDispatch, t) {
  appDispatch({ type: "logout" });
  appDispatch({
    type: "showMsg",
    value: {
      status: Constants.SNACKBAR_SUCCESS,
      msg: t("loginForm.successfulLogOut"),
    },
  });
}

/**
 * Change the Lang
 * @param {string} lang
 * @param {appDispatch} appDispatch
 */
export function langChanged(lang, appDispatch) {
  appDispatch({ type: "langChanged", value: lang });
}

export const startTourHandler = (e, appDispatch) => {
  e.preventDefault();
  appDispatch({ type: "startTour" });
};
