import React, { useState, useContext, useEffect } from "react";
import { Link as RouterLink, useParams } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import DispatchContext from "../../Context/DispatchContext";
import StateContext from "../../Context/StateContext";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { useTranslation } from "react-i18next";
import * as Constants from "../../helpers/Constants";
import DreamPage from "../../ui_components/DreamPage";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import * as AppRoutes from "../../AppComponent/AppRoutes";
import * as Colors from "../../styles/01.settings/01-settings-colors.module.scss";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const AccountSettigs = () => {
  const appDispatch = useContext(DispatchContext);
  const appContext = useContext(StateContext);
  const theme = useTheme();
  const matchesXSdown = useMediaQuery(theme.breakpoints.down("xs"));

  const [t] = useTranslation(Constants.TRANSLATION_COMMON);

  const settingsItems = [
    {
      title: "accountSettings.changeEmail",
      linkTo: AppRoutes.ROUTE_ACCOUNT_SETTINGS_CHANGE_EMAIL,
    },
    {
      title: "accountSettings.changePassword",
      linkTo: AppRoutes.ROUTE_ACCOUNT_SETTINGS_CHANGE_PASSWORD,
    },
    {
      title: "accountSettings.deleteAccount",
      linkTo: AppRoutes.ROUTE_ACCOUNT_SETTINGS_DELETE_ACCOUNT,
    },
  ];

  const useStyles = makeStyles((theme) => ({
    gridContainer: {
      marginTop: matchesXSdown ? "2em" : "5em",
    },
    gridItem: {
      display: "flex",
      justifyContent: "center",
    },
    paper: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      maxWidth: 450,
      width: "100%",
      height: 60,
      paddingLeft: 10,
      paddingRight: 10,
      boxShadow: "0px 10px 10px rgba(0, 0, 0, 0.25)",
      borderRadius: 15,
    },
  }));

  const classes = useStyles();

  return (
    <DreamPage title={t("accountSettings.settings")}>
      <Grid
        container
        spacing={matchesXSdown ? 2 : 3}
        direction="column"
        classes={{ root: classes.gridContainer }}
      >
        {settingsItems.map((item) => (
          <Grid key={item.title} item classes={{ root: classes.gridItem }}>
            <Paper
              elevation={3}
              className={classes.paper}
              component={RouterLink}
              to={item.linkTo}
            >
              <Typography>{t(item.title)}</Typography>
              <KeyboardArrowRightIcon />
            </Paper>
          </Grid>
        ))}
      </Grid>
    </DreamPage>
  );
};

export default AccountSettigs;
