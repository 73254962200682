module.exports = `

<html>

<head>
<meta http-equiv=Content-Type content="text/html; charset=windows-1251">
<meta name=Generator content="Microsoft Word 15 (filtered)">
<style>
<!--
 /* Font Definitions */
 @font-face
	{font-family:SimSun;
	panose-1:2 1 6 0 3 1 1 1 1 1;}
@font-face
	{font-family:"Cambria Math";
	panose-1:2 4 5 3 5 4 6 3 2 4;}
@font-face
	{font-family:OpenSymbol;}
@font-face
	{font-family:"Microsoft YaHei";
	panose-1:2 11 5 3 2 2 4 2 2 4;}
@font-face
	{font-family:"\@SimSun";
	panose-1:2 1 6 0 3 1 1 1 1 1;}
@font-face
	{font-family:"\@Microsoft YaHei";}
@font-face
	{font-family:"\@OpenSymbol";}
 /* Style Definitions */
 p.MsoNormal, li.MsoNormal, div.MsoNormal
	{margin:0cm;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;}
p.MsoBodyText, li.MsoBodyText, div.MsoBodyText
	{margin-top:0cm;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:0cm;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;}
.terms a:link, span.MsoHyperlink
	{color:navy;
	text-decoration:underline;}
p.-, li.-, div.-
	{mso-style-name:"Таблица - съдържание";
	margin:0cm;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;}
@page WordSection1
	{size:595.3pt 841.9pt;
	margin:2.0cm 2.0cm 2.0cm 2.0cm;}
div.WordSection1
	{page:WordSection1;}
 /* List Definitions */
 ol
	{margin-bottom:0cm;}
ul
	{margin-bottom:0cm;}
-->
</style>

</head>

<body lang=BG link=navy vlink="#954F72" style='word-wrap:break-word;line-break:
strict'>

<div class=WordSection1>
  <br>
  <b>PRIVACY POLICY FOR EMPLOYERS</b><br>
  <br>
  For the purpose of providing the services (“Services”) on the website <span
  lang=EN-US>www.jobpreference.com</span> (“Website”), <span lang=EN-US>Sun
  Land Capital</span> OOD (“<em><span lang=EN-US style='font-style:normal'>J<span
  style='color:#333333'>obpreference.com</span></span></em>”/”We”), with
  registered address: <span lang=EN-US>4</span><span lang=EN-US> </span><span
  lang=EN-US>Georgi Boev</span> Str., <span lang=EN-US>Varna</span>, UIC: <span
  style='color:#333333'>206166575</span>, processes personal data of Employer
  Representatives and natural persons – Employers or other natural persons
  specified below (“Data Subjects”/”You”), in compliance with this Policy.<br>
  <br>
  Upon processing of personal data, <em><span lang=EN-US style='font-style:
  normal'>J<span style='color:#333333'>obpreference.com</span></span></em>
  complies with all applicable data protection legislative acts, including, but
  not limited to, the Regulation (EU) 2016/679 (“Regulation”).<br>
  <br>
  According to the Regulation, personal data means any information relating to
  You which makes You identifiable. Personal data processing (“Processing”)
  means any operation or set of operations which is performed on personal data
  by automated or other means. <br>
  <br>
  </p>
  <p class=MsoNormal style='line-height:150%;border:none;padding:0cm'><b>1.
  Data Subjects</b><br>
  <br>
  In connection with the provision of the Services, <em><span lang=EN-US
  style='font-style:normal'>J<span style='color:#333333'>obpreference.com</span></span></em>
  processes information regarding the following Data Subjects:<br>
  (a) Employer Representatives (“Representatives”) – natural persons;<br>
  (b) Employers – natural persons registered in the Website;<br>
  (c) Natural persons specified by the Employer as contact persons (for
  administrative contact, financial contact, data protection officer, etc.)<br>
  <br>
  <b>2. Data processed in connection with the provision of the Services</b><br>
  <br>
  <b>2.1. Information provided by You</b><br>
  <br>
  You are free to decide whether and how to use the Services provided in the
  Website. We clearly indicate in the forms for entry of personal data whether
  the data is mandatory or voluntary. Mandatory data is data without which We
  would be unable to provide the relevant Service or part of it.<br>
  <br>
  <b><span style='color:black;background:transparent'>(a) Registration of a company
  account, users and contact persons.</span></b><span style='color:black;
  background:transparent'> To register and use a company account in the Website
  information regarding the Employer; the Employer Representatives; Contact
  Persons; Data Protection Officer as defined in the Regulation (if applicable)
  is required. In addition, information about the registration and the
  acceptance of the Terms (date, time and IP address) is stored.<br>
  <br>
  The data You provide when registering an account, adding Representatives and
  contact persons may include: </span></p>
  </div>
  <p class=MsoBodyText style='margin-top:0cm;margin-right:0cm;margin-bottom:
  0cm;margin-left:35.35pt;text-indent:-14.15pt'><span style='font-family:Symbol'>·<span
  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
  style='color:black;background:transparent'>Employers – natural persons: name,
  registered address, other contact details (address for correspondence; telephone;
  email address); UIC/BULSTAT; VAT number, etc. </span></p>
  <p class=MsoBodyText style='margin-top:0cm;margin-right:0cm;margin-bottom:
  0cm;margin-left:35.35pt;text-indent:-14.15pt'><span style='font-family:Symbol'>·<span
  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
  style='color:black;background:transparent'>Employer Representatives
  (“Representatives”) – natural persons: name, position, business phone number,
  business email address, etc. </span></p>
  <p class=MsoBodyText style='margin-left:35.35pt;text-indent:-14.15pt'><span
  style='font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  </span></span><span style='color:black;background:transparent'>Contact persons –
  natural persons: name, position, business phone number, business email
  address, etc. </span></p>
  <p class=MsoBodyText><br>
  <b>(</b><b><span lang=EN-US>b</span>) Contracts, declarations, etc.</b>
  Documents exchanged between You and <em><span lang=EN-US style='font-style:
  normal'>J<span style='color:#333333'>obpreference.com</span></span></em>
  relating to the conclusion and performance of the Contract (annexes, powers
  of attorney, declarations, invoices, etc.). Such documents may contain name,
  position, contact details, signature/electronic signature, etc.<br>
  <br>
  <b><span lang=EN-US>(c)</span> Correspondence, complaints and claims.</b> For
  the purpose of handling complaints, claims, disputes, inquiries, requests and
  other issues communicated to us via electronic forms on the Website, by phone
  calls to <em><span lang=EN-US style='font-style:normal'>J<span
  style='color:#333333'>obpreference.com</span></span></em>’s PBX, by post or
  by email, We store and process such information as well as the outcome of the
  processing.<br>
  <br>
  <b>(</b><b><span lang=EN-US>d</span>) Special categories of data (sensitive
  data).</b> The Services provided by <em><span lang=EN-US style='font-style:
  normal'>J<span style='color:#333333'>obpreference.com</span></span></em> and
  the functionalities available on the Website are not intended for collection,
  storage and processing of special categories of data within the meaning of
  Art. 9 and Art. 10 of the Regulation. You undertake not to store such data in
  your company account. Besides, You shall not require from the Applicants to
  send such data through the Website. <em><span lang=EN-US style='font-style:
  normal'>J<span style='color:#333333'>obpreference.com</span></span></em> does
  not and is not obliged to monitor the presence of such data in the Website.<br>
  <br>
  <b>(</b><b><span lang=EN-US>e</span>) Other data.</b> <em><span lang=EN-US
  style='font-style:normal'>J<span style='color:#333333'>obpreference.com</span></span></em>
  may as well process other data related to You in cases You voluntarily
  provide such data by filling in the respective electronic forms on the
  Website, adding preferences, settings, etc.<br>
  <br>
  <b>2.2. Data related to the process of using the Services</b><br>
  <br>
  <b>(a) Contractual and financial information.</b> In relation to the
  conclusion and performance of the contract for the provisioning of the
  Services between You and <em><span lang=EN-US style='font-style:normal'>J<span
  style='color:#333333'>obpreference.com</span></span></em>, the following data
  may be processed: </p>
  <p class=MsoBodyText>Information regarding the contractual arrangements; </p>
  <p class=MsoBodyText style='margin-top:0cm;margin-right:0cm;margin-bottom:
  0cm;margin-left:35.35pt;text-indent:-14.15pt'><span style='font-family:Symbol'>·<span
  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span>Information
  regarding the effected and due payments for Services (for validation of an
  account and use of paid Services on the Website, the information may include
  name, bank, bank account, etc.); </p>
  <p class=MsoBodyText style='margin-top:0cm;margin-right:0cm;margin-bottom:
  0cm;margin-left:35.35pt;text-indent:-14.15pt'><span style='font-family:Symbol'>·<span
  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span>Information
  related to the administration and provision of Services; </p>
  <p class=MsoBodyText style='margin-top:0cm;margin-right:0cm;margin-bottom:
  0cm;margin-left:35.35pt;text-indent:-14.15pt'><span style='font-family:Symbol'>·<span
  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span>History
  and information of ordered Services; </p>
  <p class=MsoBodyText style='margin-top:0cm;margin-right:0cm;margin-bottom:
  0cm;margin-left:35.35pt;text-indent:-14.15pt'><span style='font-family:Symbol'>·<span
  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span>History
  and information regarding the use of Services; </p>
  <p class=MsoBodyText style='margin-left:35.35pt;text-indent:-14.15pt'><span
  style='font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  </span></span>Other information generated or provided in the course of using
  the Services. </p>
  <p class=MsoBodyText>&nbsp;</p>
  <p class=MsoBodyText><b>(b) Settings and notifications.</b> Some of the
  Services provide settings and notifications (including by email) to
  facilitate your usage of the Website. <em><span lang=EN-US style='font-style:
  normal'>J<span style='color:#333333'>obpreference.com</span></span></em>
  processes the relevant information for this purpose.<br>
  <br>
  <b>(c) Instructions and log of the personal data processing operations.</b> <em><span
  lang=EN-US style='font-style:normal'>J<span style='color:#333333'>obpreference.com</span></span></em>
  provides You with a technological solution that allows You to <span
  lang=EN-US>unlock</span><span lang=EN-US> </span><span lang=EN-US>preferences</span>
  and store them in your account. <br>
  <br>
  <b>(d) Logs related to security, technical maintenance, development, etc.</b>
  The Website uses logs for the following purposes: </p>
  <p class=MsoBodyText style='margin-top:0cm;margin-right:0cm;margin-bottom:
  0cm;margin-left:35.35pt;text-indent:-14.15pt'><span style='font-family:Symbol'>·<span
  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span>To
  ensure the reliable functioning of the Services and to detect technical
  problems; </p>
  <p class=MsoBodyText style='margin-top:0cm;margin-right:0cm;margin-bottom:
  0cm;margin-left:35.35pt;text-indent:-14.15pt'><span style='font-family:Symbol'>·<span
  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span>To
  ensure the security of the Services and to detect malicious activities; </p>
  <p class=MsoBodyText style='margin-left:35.35pt;text-indent:-14.15pt'><em><span
  style='font-family:Symbol;font-style:normal'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  </span></span></em>Logs required by law (such as logs of electronic
  statements). </p>
  <p class=MsoBodyText><em>Server logs, logs of Web Application Firewalls and
  other devices falling in this category.</em> Such logs are necessary to
  detect technical problems, malicious activities, etc., as described above.
  The logs are stored for a period of up to 1 /one/ year. They may contain the
  following information: date and time, IP address, URL, browser and device information.
  Some devices may use security technologies based on cookies.<br>
  <br>
  <b>(e) Cookies.</b> The normal functioning of the Website requires the use of
  cookies. </p>
  <p class=MsoBodyText>&nbsp;</p>
  <p class=MsoBodyText><b>3. Purposes of personal data processing</b><br>
  <br>
  <em><span lang=EN-US style='font-style:normal'>J<span style='color:#333333'>obpreference.com</span></span></em>
  collects, uses, and processes the information described earlier for the
  purposes provided for in this Policy and in the Terms of Service. These
  include: </p>
  <p class=MsoBodyText style='margin-top:0cm;margin-right:0cm;margin-bottom:
  0cm;margin-left:35.35pt;text-indent:-14.15pt'><span style='font-family:Symbol'>·<span
  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span>Purposes
  necessary for the conclusion and performance of the contract for provision of
  the Services between You and <em><span lang=EN-US style='font-style:normal'>J<span
  style='color:#333333'>obpreference.com</span></span></em>; </p>
  <p class=MsoBodyText style='margin-top:0cm;margin-right:0cm;margin-bottom:
  0cm;margin-left:35.35pt;text-indent:-14.15pt'><span style='font-family:Symbol'>·<span
  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span>Purposes
  necessary for protecting and exercising the legitimate interests of other
  users of the Services, third parties and <em><span lang=EN-US
  style='font-style:normal'>J<span style='color:#333333'>obpreference.com</span></span></em>;
  </p>
  <p class=MsoBodyText style='margin-top:0cm;margin-right:0cm;margin-bottom:
  0cm;margin-left:35.35pt;text-indent:-14.15pt'><span style='font-family:Symbol'>·<span
  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span>Purposes
  for which You have granted explicit consent; </p>
  <p class=MsoBodyText style='margin-left:35.35pt;text-indent:-14.15pt'><span
  style='font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  </span></span>Purposes necessary for the fulfilment of <em><span lang=EN-US
  style='font-style:normal'>J<span style='color:#333333'>obpreference.com</span></span></em>’s
  legal obligations. </p>
  <p class=MsoBodyText><b>(a) Contract conclusion and performance.</b> These
  are the purposes necessary for the conclusion and performance of the contract
  between You and <em><span lang=EN-US style='font-style:normal'>J<span
  style='color:#333333'>obpreference.com</span></span></em>, including the
  required data processing upon registration, creating an account, and the
  activities related to the provision of the Services available on the Website.
  The purposes include communication with You, including by email, necessary
  for the provision of the Services and/or for notifying You of changes in the
  Services. For this purpose, processing of some or all of the above categories
  of data might be necessary.<br>
  <br>
  <b>(b) Legitimate interest.</b> The purposes of legitimate interests of <em><span
  lang=EN-US style='font-style:normal'>J<span style='color:#333333'>obpreference.com</span></span></em>
  or of third parties (other users of the Website (Applicants, Employers,
  Employer Representatives, administrative contact persons), <em><span
  lang=EN-US style='font-style:normal'>J<span style='color:#333333'>obpreference.com</span></span></em>’s
  employees and subcontractors or service providers) and include: </p>
  <p class=MsoBodyText style='margin-top:0cm;margin-right:0cm;margin-bottom:
  0cm;margin-left:35.35pt;text-indent:-14.15pt'><span style='font-family:Symbol'>·<span
  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span>Ensuring
  the Website’s normal operation and use by You and by the other users,
  maintenance and administration of the Services, dispute resolution, detection
  and prevention of malicious activities; </p>
  <p class=MsoBodyText style='margin-top:0cm;margin-right:0cm;margin-bottom:
  0cm;margin-left:35.35pt;text-indent:-14.15pt'><span style='font-family:Symbol'>·<span
  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span>Detection
  and repair of technical or functionality related issues; </p>
  <p class=MsoBodyText style='margin-top:0cm;margin-right:0cm;margin-bottom:
  0cm;margin-left:35.35pt;text-indent:-14.15pt'><span style='font-family:Symbol'>·<span
  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span>Communication
  with You, including by email, on important issues related to the Services; </p>
  <p class=MsoBodyText style='margin-top:0cm;margin-right:0cm;margin-bottom:
  0cm;margin-left:35.35pt;text-indent:-14.15pt'><span style='font-family:Symbol'>·<span
  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span>Receipt
  and processing of claims, complaints, requests and other correspondence; </p>
  <p class=MsoBodyText style='margin-left:35.35pt;text-indent:-14.15pt'><span
  style='font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  </span></span>Exercise and protect <em><span lang=EN-US style='font-style:
  normal'>J<span style='color:#333333'>obpreference.com</span></span></em>’s
  rights and legal interests, including by legal proceedings, and rendering
  assistance to other users of the Website and/or affected third persons in
  exercising and protecting their rights and legal interests. </p>
  <p class=MsoBodyText>For these purposes, processing of some or all of the
  above categories of data might be necessary.<br>
  <br>
  <b>(c) Legal obligations.</b> These are purposes such as: </p>
  <p class=MsoBodyText style='margin-top:0cm;margin-right:0cm;margin-bottom:
  0cm;margin-left:35.35pt;text-indent:-14.15pt'><span style='font-family:Symbol'>·<span
  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span>Financial
  and accounting activities where processing and storage of information is
  required by law for the purpose of fulfilment of tax, accounting and other
  reporting obligations; </p>
  <p class=MsoBodyText style='margin-top:0cm;margin-right:0cm;margin-bottom:
  0cm;margin-left:35.35pt;text-indent:-14.15pt'><span style='font-family:Symbol'>·<span
  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span>Activities
  related to the performance of statutory obligations for the retention or
  provision of information upon receipt of a relevant order issued by competent
  state or court authorities; to facilitate the competent state authorities in
  exercising their control powers; to perform <br>
  Documenting and reporting activities, and other obligations related to the
  fulfilment of the requirements under Regulation (EU) 2016/679 and the
  applicable legislation, including the obligations assigned under the Data
  Processing Agreement or other applicable obligations. </p>
  <p class=MsoBodyText>For these purposes, processing of some or all of the
  above categories of data might be necessary.<br>
  <br>
  <b>(d) Explicit consent.</b> Your data may be processed on the basis of your
  explicit consent. In such case, the processing is specific, to the extent and
  within the scope provided for in the respective consent. <br>
  <br>
  <b>4. Storage period</b><br>
  <br>
  Below You can find information about the maximum storage periods of the
  different types of data. We apply the general principle for storing data in
  minimal volume and for a period not longer than necessary for the provision
  of the Services, ensuring their security and reliability, and meeting the
  legal requirements. </p>
  <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
   style='border-collapse:collapse'>
   <tr>
    <td width=319 valign=top style='width:239.55pt;border:solid black 1.0pt;
    border-right:none;padding:2.75pt 2.75pt 2.75pt 2.75pt'>
    <p class=-><b>Type of data</b> </p>
    </td>
    <td width=320 valign=top style='width:239.65pt;border:solid black 1.0pt;
    padding:2.75pt 2.75pt 2.75pt 2.75pt'>
    <p class=-><b><span lang=EN-US>Storage period</span></b></p>
    </td>
   </tr>
   <tr>
    <td width=319 valign=top style='width:239.55pt;border-top:none;border-left:
    solid black 1.0pt;border-bottom:solid black 1.0pt;border-right:none;
    padding:2.75pt 2.75pt 2.75pt 2.75pt'>
    <p class=->Data relating to the registration of a company account, users,
    additional users, contact details, and information on acceptance of the
    Terms (date, time, IP address, etc.); contracts, declarations, etc. </p>
    </td>
    <td width=320 valign=top style='width:239.65pt;border:solid black 1.0pt;
    border-top:none;padding:2.75pt 2.75pt 2.75pt 2.75pt'>
    <p class=->For the period of maintaining an account in the Website and up
    to 5 /five/ years from termination of the registration </p>
    </td>
   </tr>
   <tr>
    <td width=319 valign=top style='width:239.55pt;border-top:none;border-left:
    solid black 1.0pt;border-bottom:solid black 1.0pt;border-right:none;
    padding:2.75pt 2.75pt 2.75pt 2.75pt'>
    <p class=->Settings (Website language, hiding of information boxes, etc.) </p>
    </td>
    <td width=320 valign=top style='width:239.65pt;border:solid black 1.0pt;
    border-top:none;padding:2.75pt 2.75pt 2.75pt 2.75pt'>
    <p class=->Until You erase the data or until termination of your
    registration. If stored in a cookie, not longer than 6 /six/ months from
    the last use </p>
    </td>
   </tr>
   <tr>
    <td width=319 valign=top style='width:239.55pt;border-top:none;border-left:
    solid black 1.0pt;border-bottom:solid black 1.0pt;border-right:none;
    padding:2.75pt 2.75pt 2.75pt 2.75pt'>
    <p class=->Invoices and other financial information (including data of
    Employer and Employer Representatives) </p>
    </td>
    <td width=320 valign=top style='width:239.65pt;border:solid black 1.0pt;
    border-top:none;padding:2.75pt 2.75pt 2.75pt 2.75pt'>
    <p class=->For the period of maintaining the account in the Website and up
    to 5 /five/ years from termination of the registration </p>
    </td>
   </tr>
   <tr>
    <td width=319 valign=top style='width:239.55pt;border-top:none;border-left:
    solid black 1.0pt;border-bottom:solid black 1.0pt;border-right:none;
    padding:2.75pt 2.75pt 2.75pt 2.75pt'>
    <p class=->Correspondence, requests, complaints and claims </p>
    </td>
    <td width=320 valign=top style='width:239.65pt;border:solid black 1.0pt;
    border-top:none;padding:2.75pt 2.75pt 2.75pt 2.75pt'>
    <p class=->For the period of maintaining the account in the Website and up
    to 5 /five/ years from termination of the registration </p>
    </td>
   </tr>
   <tr>
    <td width=319 valign=top style='width:239.55pt;border-top:none;border-left:
    solid black 1.0pt;border-bottom:solid black 1.0pt;border-right:none;
    padding:2.75pt 2.75pt 2.75pt 2.75pt'>
    <p class=->Instructions and log of the personal data processing operations,
    log of access to the account (the stored information includes: action,
    Representative performing the action, date and time, IP address, etc.) </p>
    </td>
    <td width=320 valign=top style='width:239.65pt;border:solid black 1.0pt;
    border-top:none;padding:2.75pt 2.75pt 2.75pt 2.75pt'>
    <p class=->For the period of maintaining an account in the Website and up
    to 5 /five/ years from termination of the registration </p>
    </td>
   </tr>
   <tr>
    <td width=319 valign=top style='width:239.55pt;border-top:none;border-left:
    solid black 1.0pt;border-bottom:solid black 1.0pt;border-right:none;
    padding:2.75pt 2.75pt 2.75pt 2.75pt'>
    <p class=->System logs. Security, technical maintenance, development, etc.
    (they may contain information such as: date and time, IP address, URL,
    browser versions and device) </p>
    </td>
    <td width=320 valign=top style='width:239.65pt;border:solid black 1.0pt;
    border-top:none;padding:2.75pt 2.75pt 2.75pt 2.75pt'>
    <p class=->For a period of up to 1 /one/ year </p>
    </td>
   </tr>
   <tr>
    <td width=319 valign=top style='width:239.55pt;border-top:none;border-left:
    solid black 1.0pt;border-bottom:solid black 1.0pt;border-right:none;
    padding:2.75pt 2.75pt 2.75pt 2.75pt'>
    <p class=->Cookies </p>
    </td>
    <td width=320 valign=top style='width:239.65pt;border:solid black 1.0pt;
    border-top:none;padding:2.75pt 2.75pt 2.75pt 2.75pt'>
    <p class=->Description of the used cookies is available in the “Policy for
    use of cookies” </p>
    </td>
   </tr>
  </table>
  <p class=MsoBodyText>&nbsp;</p>
  <p class=MsoBodyText>In case of a legal dispute or proceedings requiring data
  retention and/or at the request of a competent state authority, data may be
  stored for longer than the duration specified above until the final
  settlement of the dispute or the proceedings before all instances. The
  storage duration may be changed according to the applicable legislation.<br>
  <br>
  Any change in or termination of the relations between the Employer and its
  Representatives shall not entail a change in the storage periods specified
  above, due to the fact that the information regarding the orders,
  instructions and activities of the Representatives is necessary as proof and
  for reporting purposes with regard to contractual and legal obligations.<br>
  <br>
  <b><span style='color:black;background:transparent'>Backup</span></b><span
  style='color:black;background:transparent'>. For the purpose of ensuring the
  reliability of the Services and for prevention of data loss in case of
  technical issues, a backup policy is applied. The maximum period for updating
  (erasure of data) from all backup copies is 30 days. </span></p>
  <p class=MsoBodyText><b>5. Accuracy of the data</b><br>
  <em><span lang=EN-US style='font-style:normal'>J<span style='color:#333333'>obpreference.com</span></span></em>
  shall have no liability for the accuracy of the data provided by You, does
  not perform checks in this respect and does not guarantee the actual identity
  of the data subjects. In any case of doubt, identified fraud and/or misuse,
  please notify us immediately. When providing any data on the Website, You
  undertake not to violate other people’s rights in relation to the protection
  of their personal data or their other rights.<br>
  <br>
  <b>6.</b><b> Data Protection Officer</b><br>
  Questions and requests related to exercising your personal data protection
  rights can be addressed to the Data Protection Officer of <em><span
  lang=EN-US style='font-style:normal'>J<span style='color:#333333'>obpreference.com</span></span></em>:<br>
  <br>
  Email address: <a href="mailto:privacy@jobpreference.com">privacy@<span
  lang=EN-US>j</span><em><span lang=EN-US style='color:#333333;font-style:normal;
  text-decoration:none'>obpreference.com</span></em></a></p>
  <p class=MsoBodyText>&nbsp;</p>
  <p class=MsoBodyText><b><span style='font-size:16.0pt'>Policy for use of
  cookies </span></b></p>
  <p class=MsoBodyText>Here you can find information about the cookies and
  similar technologies used for storage of information related to the provided
  services (“Services”) on the website <em><span lang=EN-US style='color:#333333;
  font-style:normal'>www.jobpreference.com</span></em> and/or the mobile
  versions and applications (“the Website”).<br>
  <br>
  POLICY FOR USE OF COOKIES<br>
  <br>
  Cookies allow information to be stored in your browser (or mobile
  application). They are used to store settings and identifiers required for
  some of the services provided on the Website.<br>
  <br>
  <em><span lang=EN-US style='color:#333333;font-style:normal'>Jobpreference.com</span></em>
  applies policy in line with the EU requirements.<br>
  <br>
  Cookies are used for the following purposes: </p>
  <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
   style='border-collapse:collapse'>
   <tr>
    <td width=213 valign=top style='width:159.7pt;border:solid black 1.0pt;
    border-right:none;padding:2.75pt 2.75pt 2.75pt 2.75pt'>
    <p class=->Type </p>
    </td>
    <td width=213 valign=top style='width:159.7pt;border:solid black 1.0pt;
    border-right:none;padding:2.75pt 2.75pt 2.75pt 2.75pt'>
    <p class=->Storage period </p>
    </td>
    <td width=213 valign=top style='width:159.8pt;border:solid black 1.0pt;
    padding:2.75pt 2.75pt 2.75pt 2.75pt'>
    <p class=->Description </p>
    </td>
   </tr>
   <tr>
    <td width=213 valign=top style='width:159.7pt;border-top:none;border-left:
    solid black 1.0pt;border-bottom:solid black 1.0pt;border-right:none;
    padding:2.75pt 2.75pt 2.75pt 2.75pt'>
    <p class=->Session and navigation management (session identifier, security
    protection, previous steps for the ‘back button’, etc.) </p>
    </td>
    <td width=213 valign=top style='width:159.7pt;border-top:none;border-left:
    solid black 1.0pt;border-bottom:solid black 1.0pt;border-right:none;
    padding:2.75pt 2.75pt 2.75pt 2.75pt'>
    <p class=->Temporarily (for the duration of your visit on the Website) </p>
    </td>
    <td width=213 valign=top style='width:159.8pt;border:solid black 1.0pt;
    border-top:none;padding:2.75pt 2.75pt 2.75pt 2.75pt'>
    <p class=-><em>These cookies are technically required to login, navigate
    and use the Website. The Website cannot function properly and reliably
    without them.</em> </p>
    </td>
   </tr>
   <tr>
    <td width=213 valign=top style='width:159.7pt;border-top:none;border-left:
    solid black 1.0pt;border-bottom:solid black 1.0pt;border-right:none;
    padding:2.75pt 2.75pt 2.75pt 2.75pt'>
    <p class=->Security (protection against access from unauthorised devices, etc.)
    </p>
    </td>
    <td width=213 valign=top style='width:159.7pt;border-top:none;border-left:
    solid black 1.0pt;border-bottom:solid black 1.0pt;border-right:none;
    padding:2.75pt 2.75pt 2.75pt 2.75pt'>
    <p class=->Up to 6 /six/ months from last login </p>
    </td>
    <td width=213 valign=top style='width:159.8pt;border:solid black 1.0pt;
    border-top:none;padding:2.75pt 2.75pt 2.75pt 2.75pt'>
    <p class=-><em>These are cookies used to protect the account against
    unauthorised access.</em> </p>
    </td>
   </tr>
   <tr>
    <td width=213 valign=top style='width:159.7pt;border-top:none;border-left:
    solid black 1.0pt;border-bottom:solid black 1.0pt;border-right:none;
    padding:2.75pt 2.75pt 2.75pt 2.75pt'>
    <p class=->Settings (Website language) </p>
    </td>
    <td width=213 valign=top style='width:159.7pt;border-top:none;border-left:
    solid black 1.0pt;border-bottom:solid black 1.0pt;border-right:none;
    padding:2.75pt 2.75pt 2.75pt 2.75pt'>
    <p class=->Up to 6 / six / months from last use </p>
    </td>
    <td width=213 valign=top style='width:159.8pt;border:solid black 1.0pt;
    border-top:none;padding:2.75pt 2.75pt 2.75pt 2.75pt'>
    <p class=-><em>These are cookies used to save your settings; </em></p>
    </td>
   </tr>
  </table>
  <p class=-></p>
  </td>
 </tr>
</table>

<p class=MsoNormal>&nbsp;</p>

<p class=MsoNormal><b>Deleting cookies from your browser</b><br>
A special functionality is provided on <em><span lang=EN-US style='color:#333333;
font-style:normal'>Jobpreference.com</span></em>, allowing you to delete all
cookies from <em><span lang=EN-US style='color:#333333;font-style:normal'>Jobpreference.com</span></em>.
In case you would like to do this, use this link:  <span style='color:black;
background:transparent'> </span><span lang=EN-US style='color:black;background:transparent'>Delete
all cookies from this browser</span><br>
<br>
<b>Control of cookies in the browser</b><br>
If you want, you can also use your browser settings to delete and/or disable
cookies from specific or all websites. More information about this can be found
on aboutcookies.org. </p>

</div>
<br>
<br>
<div class=WordSection1>

<p class=MsoNormal>&nbsp;</p>

<div style='border:none;border-bottom:solid silver 1.0pt;padding:0cm 0cm 1.0pt 0cm'>

<p class=- style='line-height:150%;border:none;padding:0cm'><b><span
style='font-size:14.0pt;line-height:150%;color:black'>P</span></b><b><span
lang=EN-US style='font-size:14.0pt;line-height:150%;color:black'>RIVACY</span></b><b><span
style='font-size:14.0pt;line-height:150%;color:black'> POLICY FOR PROTECTION OF
PERSONAL DATA OF N</span></b><b><span lang=EN-US style='font-size:14.0pt;
line-height:150%;color:black'>ATURAL</span></b><span lang=EN-US
style='color:black'> </span><b><span lang=EN-US style='font-size:14.0pt;
line-height:150%;color:black'>PERSONS</span></b><b><span style='font-size:14.0pt;
line-height:150%;color:black'> - END USERS</span></b></p>

<p class=- style='line-height:150%;border:none;padding:0cm'><span
style='color:black'>Please read carefully this document. It contains the
Privacy Policy for </span><span lang=EN-US style='color:black'>natural persons-end
users</span><span style='color:black'> (“Policy”) and is an integral part of
the Terms of Service for </span><span lang=EN-US style='color:black'>natural
persons-end users</span><span style='color:black'> (“the Terms”). If You do not
accept the Terms, it is not possible to use the Services. In case of amendments
in the Policy, the changes will be published here.</span></p>

<p class=- style='line-height:150%;border:none;padding:0cm'>&nbsp;</p>

<p class=- style='line-height:150%;border:none;padding:0cm'><b><span
style='font-size:14.0pt;line-height:150%;color:black'>P</span></b><b><span
lang=EN-US style='font-size:14.0pt;line-height:150%;color:black'>RIVACY</span></b><b><span
style='font-size:14.0pt;line-height:150%;color:black'> POLICY FOR PROTECTION OF
PERSONAL DATA OF N</span></b><b><span lang=EN-US style='font-size:14.0pt;
line-height:150%;color:black'>ATURAL</span></b><span lang=EN-US
style='color:black'> </span><b><span lang=EN-US style='font-size:14.0pt;
line-height:150%;color:black'>PERSONS-END USERS</span></b></p>

<p class=- style='line-height:150%;border:none;padding:0cm'>&nbsp;</p>

<p class=- style='line-height:150%;border:none;padding:0cm'><span
style='color:black'>For the purpose of providing the services (“Services”) on
the website </span><span lang=EN-US style='color:black'>www.jobpreference.com</span><span
style='color:black'> (“Website”), </span><span lang=EN-US style='color:black'>Sun
Land Capital</span><span style='color:black'> OOD (“</span><em><span
lang=EN-US style='color:black;font-style:normal'>J</span></em><em><span
lang=EN-US style='color:#333333;font-style:normal'>obpreference.com</span></em><span
style='color:black'>”/”We”), with registered address: </span><span lang=EN-US
style='color:black'>4</span><span lang=EN-US style='color:black'> </span><span
lang=EN-US style='color:black'>Georgi Boev</span><span style='color:black'>
Str., </span><span lang=EN-US style='color:black'>Varna</span><span
style='color:black'>, UIC: </span><span style='color:#333333'>206166575</span><span
style='color:black'>, processes personal data of  natural persons specified
below (“Data Subjects”/”You”), in compliance with this Policy.</span></p>

<p class=- style='line-height:150%;border:none;padding:0cm'><span
style='color:black'>Upon processing of personal data, </span><em><span
lang=EN-US style='color:black;font-style:normal'>J</span></em><em><span
lang=EN-US style='color:#333333;font-style:normal'>obpreference.com</span></em><span
style='color:black'> complies with all applicable data protection legislative
acts, including, but not limited to, the Regulation (EU) 2016/679
(“Regulation”).<br>
<br>
According to the Regulation, personal data means any information relating to
You which makes You identifiable. Personal data processing (“Processing”) means
any operation or set of operations which is performed on personal data by
automated or other means. </span></p>

<p class=- style='line-height:150%;border:none;padding:0cm'>&nbsp;</p>

<p class=MsoNormal style='line-height:150%;border:none;padding:0cm'><b><span
style='color:black'>1. Data Subjects</span></b></p>

<p class=MsoNormal style='line-height:150%;border:none;padding:0cm'><span
style='color:black'>In connection with the provision of the Services, </span><em><span
lang=EN-US style='color:black;font-style:normal'>J</span></em><em><span
lang=EN-US style='color:#333333;font-style:normal'>obpreference.com</span></em><span
style='color:black'> processes information about individuals, users of the Site
with registration.</span></p>

<p class=MsoNormal style='line-height:150%;border:none;padding:0cm'>&nbsp;</p>

<p class=MsoNormal style='line-height:150%;border:none;padding:0cm'><b><span
style='color:black'>2. Data processed in connection with the provision of the
Services</span></b><span style='color:black'><br>
<br>
<b>2.1. Information provided by You</b><br>
You are free to decide whether and how to use the Services provided in the
Website. We clearly indicate in the forms for entry of personal data whether
the data is mandatory or voluntary. Mandatory data is data without which We
would be unable to provide the relevant Service or part of it.</span></p>

<p class=MsoNormal style='line-height:150%;border:none;padding:0cm'><b><span
lang=EN-US style='color:black'>(</span></b><b><span style='color:black'>a)
Registration / Account creation</span></b><span style='color:black'>. The use
of part of the Services is possible after registration. To create your own
account, you need to enter: first name, last name, email address and password.
In addition, information on registration and compliance with the Terms (date,
time and IP address) is stored.</span></p>

<p class=MsoNormal style='line-height:150%;border:none;padding:0cm'><b><span
style='color:black'>(b) Data stored in your account.</span></b><span
style='color:black'> You have the opportunity to create and / or store in your
account information such as photos, CVs, other files and accordingly use them
when publishing a preference and send them to Employers </span><span
lang=EN-US style='color:black'>and other users </span><span style='color:black'>at
your discretion. You decide for yourself whether and what data relating to you
to store in your account on the Site.</span></p>

<p class=MsoNormal style='line-height:150%;border:none;padding:0cm'><u><span
style='color:black'>Photo</span></u><span style='color:black'><br>
Including a photo in your account is entirely your choice. The current photo is
visible to Employers or other users after unlocking your preference. Curricula
vitae (CVs) with a photo are saved in the form in which they are at the time of
application.</span></p>

<p class=MsoNormal style='line-height:150%;border:none;padding:0cm'><u><span
style='color:black'>Creating a CV</span></u><span style='color:black'><br>
The site allows you to create and save one or more CVs to your preferences. You
decide for yourself whether and what data concerning you to include in the
relevant CVs.<br>
When creating a CV, you have the opportunity, at your discretion, to include
the following information: name, email, phone, education and qualifications;
professional experience and information on positions held; skills; language
skills; possession of a driving license and other additional information filled
in by you.</span></p>

<p class=MsoNormal style='line-height:150%;border:none;padding:0cm'><u><span
style='color:black'>Sav</span></u><u><span lang=EN-US style='color:black'>ing</span></u><u><span
style='color:black'> your files</span></u><span style='color:black'><br>
You have the option to save your files to use when posting a preference. You
decide for yourself whether and what data concerning you to store in your
account and send to Employers or other users.The Services provided by </span><em><span
lang=EN-US style='color:black;font-style:normal'>J</span></em><em><span
lang=EN-US style='color:#333333;font-style:normal'>obpreference.com</span></em><span
style='color:black'> and the functionalities available on the Website are not
intended for collection, storage and processing of special categories of data
within the meaning of Art. 9 and Art. 10 of the Regulation. You </span><span
lang=EN-US style='color:black'>undertake</span><span style='color:black'> not
to store or send through the Site to Employers or other users, in any form,
information containing special categories of data and / or sensitive data.</span></p>

<p class=MsoNormal style='line-height:150%;border:none;padding:0cm'><u><span
style='color:black'>Chat with Employers and other users</span></u><span
style='color:black'><br>
Employers and other users who have unlocked your preferences have the opportunity
to send you messages that you may respond to at your discretion. When you
reply, your messages contain free text entered by you. You decide for yourself
whether and what data concerning you to include in them.</span></p>

<p class=MsoBodyText style='line-height:150%;border:none;padding:0cm'><u><span
style='color:black'>Correspondence, complaints and claims</span></u></p>

<p class=MsoBodyText style='line-height:150%;border:none;padding:0cm'><span
style='color:black'>For the purpose of handling complaints, claims, disputes,
inquiries, requests and other issues communicated to us via electronic forms on
the Website, by phone calls to </span><em><span lang=EN-US style='color:black;
font-style:normal'>J</span></em><em><span lang=EN-US style='color:#333333;
font-style:normal'>obpreference.com</span></em><span style='color:black'>’s
PBX, by post or by email, We store and process such information as well as the
outcome of the processing.</span></p>

<p class=MsoBodyText style='line-height:150%;border:none;padding:0cm'><u><span
style='color:black'>Special categories of data (sensitive data)</span></u></p>

<p class=MsoBodyText style='line-height:150%;border:none;padding:0cm'><span
style='color:black'>The Services provided by </span><em><span lang=EN-US
style='color:black;font-style:normal'>J</span></em><em><span lang=EN-US
style='color:#333333;font-style:normal'>obpreference.com</span></em><span
style='color:black'> and the functionalities available on the Website are not
intended for collection, storage and processing of special categories of data
within the meaning of Art. 9 and Art. 10 of the Regulation. You agree not to
store or send through the Site to Employers and other users, in any form,
information containing special categories of data and sensitive data such as:<br>
- data revealing your racial or ethnic origin, political views, religious or
philosophical beliefs, membership in trade unions, or your genetic data,
biometric data, data on your health, sexual life or sexual orientation, or data
related to with your sentences and violations.<br>
- identity documents, as well as P</span><span lang=EN-US style='color:black'>IN</span><span
style='color:black'>, passport / ID card number, etc. similar.<br>
Jobpreference.com has no obligation and does not monitor the availability of
the data provided here on the Site. The provision of such information is
entirely at the risk and responsibility of the person providing it.</span></p>

<p class=MsoBodyText style='line-height:150%;border:none;padding:0cm'><u><span
style='color:black'>Other data</span></u></p>

<p class=MsoBodyText style='line-height:150%;border:none;padding:0cm'><em><span
lang=EN-US style='color:black;font-style:normal'>J</span></em><em><span
lang=EN-US style='color:#333333;font-style:normal'>obpreference.com</span></em><span
style='color:black'> may as well process other data related to You in cases You
voluntarily provide such data by filling in the respective electronic forms on
the Website, adding preferences, settings, etc.</span></p>

<p class=MsoBodyText style='line-height:150%;border:none;padding:0cm'><b><span
style='color:black'>2.2. Data related to the process of using the Services</span></b></p>

<p class=MsoNormal style='line-height:150%;border:none;padding:0cm'><span
style='color:black'>You give the right to </span><span lang=EN-US
style='color:black'>J</span><span lang=EN-US style='color:#333333'>obpreference.com</span><span
style='color:black'> to make publicly accessible (both in Bulgaria and abroad)
the </span><span lang=EN-US style='color:black'>preferences</span><span
style='color:black'> You publish and other public content for the purposes of
providing the Services, and </span><span lang=EN-US style='color:black'>J</span><span
lang=EN-US style='color:#333333'>obpreference.com</span><span style='color:
black'> shall owe no remuneration therefor.<b><br>
<br>
</b>By publishing content on the Website You grant its </span><span lang=EN-US
style='color:black'>users-employers and natural persons-end users</span><span
style='color:black'> the right to access and use it in compliance with the
Terms of service and the law.</span></p>

<p class=MsoNormal style='line-height:150%;border:none;padding:0cm'>&nbsp;</p>

<p class=MsoBodyText style='line-height:150%;border:none;padding:0cm'><span
style='color:black'>Upon unlocking, your preference becomes automatically
visible in the account of the Employer or the individual who paid. Unlocked
preferences are visible for a period of 1 / one / month from the date of
payment.</span></p>

</div>

<p class=MsoNormal><u>Logs related to security, technical maintenance,
development, etc.</u></p>

<p class=MsoNormal>The Website uses logs for the following purposes: </p>

<p class=MsoBodyText style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:35.35pt;text-indent:-14.15pt'><span style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span>To
ensure the reliable functioning of the Services and to detect technical
problems; </p>

<p class=MsoBodyText style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:35.35pt;text-indent:-14.15pt'><span style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span>To
ensure the security of the Services and to detect malicious activities; </p>

<p class=MsoBodyText style='margin-left:35.35pt;text-indent:-14.15pt'><em><span
style='font-family:Symbol;color:black;font-style:normal'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></em>Logs
required by law (such as logs of electronic statements). </p>

<div style='border:none;border-bottom:solid silver 1.0pt;padding:0cm 0cm 1.0pt 0cm'>

<p class=MsoBodyText style='line-height:150%;border:none;padding:0cm'><em><span
style='color:black'>Server logs, logs of Web Application Firewalls and other
devices falling in this category.</span></em><span style='color:black'> Such
logs are necessary to detect technical problems, malicious activities, etc., as
described above. The logs are stored for a period of up to 1 /one/ year. They
may contain the following information: date and time, IP address, URL, browser
and device information. Some devices may use security technologies based on
cookies.<br>
<br>
<u>Cookies</u></span></p>

<p class=MsoBodyText style='line-height:150%;border:none;padding:0cm'><span
style='color:black'>The normal functioning of the Website requires the use of
cookies. </span></p>

</div>

<p class=MsoBodyText><b>3. Purposes of personal data processing</b><br>
<br>
<em><span lang=EN-US style='font-style:normal'>J<span style='color:#333333'>obpreference.com</span></span></em>
collects, uses, and processes the information described earlier for the
purposes provided for in this Policy and in the Terms of Service. These
include: </p>

<p class=MsoBodyText style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:35.35pt;text-indent:-14.15pt'><span style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span>Purposes
necessary for the conclusion and performance of the contract for provision of
the Services between You and <em><span lang=EN-US style='font-style:normal'>J<span
style='color:#333333'>obpreference.com</span></span></em>; </p>

<p class=MsoBodyText style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:35.35pt;text-indent:-14.15pt'><span style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span>Purposes
necessary for protecting and exercising the legitimate interests of other users
of the Services, third parties and <em><span lang=EN-US style='font-style:normal'>J<span
style='color:#333333'>obpreference.com</span></span></em>; </p>

<p class=MsoBodyText style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:35.35pt;text-indent:-14.15pt'><span style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span>Purposes
for which You have granted explicit consent; </p>

<p class=MsoBodyText style='margin-left:35.35pt;text-indent:-14.15pt'><span
style='font-family:Symbol;color:black'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>Purposes necessary for the fulfilment of <em><span lang=EN-US
style='font-style:normal'>J<span style='color:#333333'>obpreference.com</span></span></em>’s
legal obligations. </p>

<div style='border:none;border-bottom:solid silver 1.0pt;padding:0cm 0cm 1.0pt 0cm'>

<p class=MsoBodyText style='line-height:150%;border:none;padding:0cm'><b><span
style='color:black'>Contract conclusion and performance</span></b></p>

<p class=MsoBodyText style='line-height:150%;border:none;padding:0cm'><span
style='color:black'>These are the purposes necessary for the conclusion and
performance of the contract between You and </span><em><span lang=EN-US
style='color:black;font-style:normal'>J</span></em><em><span lang=EN-US
style='color:#333333;font-style:normal'>obpreference.com</span></em><span
style='color:black'>, including the required data processing upon registration,
creating an account, and the activities related to the provision of the
Services available on the Website. The purposes include communication with You,
including by email, necessary for the provision of the Services and/or for
notifying You of changes in the Services. For this purpose, processing of some
or all of the above categories of data might be necessary.</span></p>

</div>

<p class=MsoNormal><b>Legitimate interest</b></p>

<p class=MsoNormal>The purposes of legitimate interests of <em><span
lang=EN-US style='font-style:normal'>J<span style='color:#333333'>obpreference.com</span></span></em>
or of third parties (other users of the Website (Applicants, Employers,
Employer Representatives, administrative contact persons), <em><span
lang=EN-US style='font-style:normal'>J<span style='color:#333333'>obpreference.com</span></span></em>’s
employees and subcontractors or service providers) and include: </p>

<p class=MsoBodyText style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:35.35pt;text-indent:-14.15pt'><span style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span>Ensuring
the Website’s normal operation and use by You and by the other users,
maintenance and administration of the Services, dispute resolution, detection
and prevention of malicious activities; </p>

<p class=MsoBodyText style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:35.35pt;text-indent:-14.15pt'><span style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span>Detection
and repair of technical or functionality related issues; </p>

<p class=MsoBodyText style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:35.35pt;text-indent:-14.15pt'><span style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span>Communication
with You, including by email, on important issues related to the Services; </p>

<p class=MsoBodyText style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:35.35pt;text-indent:-14.15pt'><span style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span>Receipt
and processing of claims, complaints, requests and other correspondence; </p>

<p class=MsoBodyText style='margin-left:35.35pt;text-indent:-14.15pt'><span
style='font-family:Symbol;color:black'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>Exercise and protect <em><span lang=EN-US style='font-style:normal'>J<span
style='color:#333333'>obpreference.com</span></span></em>’s rights and legal
interests, including by legal proceedings, and rendering assistance to other
users of the Website and/or affected third persons in exercising and protecting
their rights and legal interests. </p>

<div style='border:none;border-bottom:solid silver 1.0pt;padding:0cm 0cm 1.0pt 0cm'>

<p class=MsoBodyText style='line-height:150%;border:none;padding:0cm'><span
style='color:black'>For these purposes, processing of some or all of the above
categories of data might be necessary.</span></p>

</div>

<p class=MsoNormal><b>Legal obligations</b></p>

<p class=MsoNormal>These are purposes such as: </p>

<p class=MsoBodyText style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:35.35pt;text-indent:-14.15pt'><span style='font-family:Symbol;
color:black'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>Activities related to the performance of statutory obligations
for the retention or provision of information upon receipt of a relevant order
issued by competent state or court authorities; to facilitate the competent
state authorities in exercising their control powers; </p>

<div style='border:none;border-bottom:solid silver 1.0pt;padding:0cm 0cm 1.0pt 0cm'>

<p class=MsoBodyText style='line-height:150%;border:none;padding:0cm'><span
style='color:black'>For these purposes, processing of some or all of the above
categories of data might be necessary.<br>
<b>Explicit consent.</b> Your data may be processed on the basis of your
explicit consent. In such case, the processing is specific, to the extent and
within the scope provided for in the respective consent.</span></p>

<p class=MsoBodyText style='line-height:150%;border:none;padding:0cm'><b><span
style='color:black'>4. Storage period</span></b><span style='color:black'><br>
<br>
Below You can find information about the maximum storage periods of the
different types of data. We apply the general principle for storing data in
minimal volume and for a period not longer than necessary for the provision of
the Services, ensuring their security and reliability, and meeting the legal
requirements. </span></p>

</div>

<table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
 style='margin-left:-.4pt;border-collapse:collapse'>
 <tr>
  <td width=321 valign=top style='width:240.95pt;border:solid black 1.0pt;
  border-right:none;padding:2.75pt 2.75pt 2.75pt 2.75pt'>
  <p class=-><b>Type of data</b> </p>
  </td>
  <td width=321 valign=top style='width:240.95pt;border:solid black 1.0pt;
  padding:2.75pt 2.75pt 2.75pt 2.75pt'>
  <p class=-><b><span lang=EN-US>Storage period</span></b></p>
  </td>
 </tr>
 <tr>
  <td width=321 valign=top style='width:240.95pt;border-top:none;border-left:
  solid black 1.0pt;border-bottom:solid black 1.0pt;border-right:none;
  padding:2.75pt 2.75pt 2.75pt 2.75pt'>
  <p class=->Data relating to the registration of a<span lang=EN-US>n</span>
  account, contact details, and information on acceptance of the Terms (date,
  time, IP address, etc.); contracts, declarations, etc. </p>
  </td>
  <td width=321 valign=top style='width:240.95pt;border:solid black 1.0pt;
  border-top:none;padding:2.75pt 2.75pt 2.75pt 2.75pt'>
  <p class=->For the period of maintaining an account in the Website and up to <span
  lang=EN-US>1</span> /<span lang=EN-US>one</span>/ year from termination of
  the registration </p>
  </td>
 </tr>
 <tr>
  <td width=321 valign=top style='width:240.95pt;border-top:none;border-left:
  solid black 1.0pt;border-bottom:solid black 1.0pt;border-right:none;
  padding:2.75pt 2.75pt 2.75pt 2.75pt'>
  <p class=->CVs, files, photos or other documents and information stored in
  your account</p>
  </td>
  <td width=321 valign=top style='width:240.95pt;border:solid black 1.0pt;
  border-top:none;padding:2.75pt 2.75pt 2.75pt 2.75pt'>
  <p class=->Until they are deleted by you or until your registration is
  terminated</p>
  </td>
 </tr>
 <tr>
  <td width=321 valign=top style='width:240.95pt;border-top:none;border-left:
  solid black 1.0pt;border-bottom:solid black 1.0pt;border-right:none;
  padding:2.75pt 2.75pt 2.75pt 2.75pt'>
  <p class=->System logs. Security, technical maintenance, development, etc.
  (they may contain information such as: date and time, IP address, URL,
  browser versions and device) </p>
  </td>
  <td width=321 valign=top style='width:240.95pt;border:solid black 1.0pt;
  border-top:none;padding:2.75pt 2.75pt 2.75pt 2.75pt'>
  <p class=->For a period of up to 1 /one/ year </p>
  </td>
 </tr>
 <tr>
  <td width=321 valign=top style='width:240.95pt;border-top:none;border-left:
  solid black 1.0pt;border-bottom:solid black 1.0pt;border-right:none;
  padding:2.75pt 2.75pt 2.75pt 2.75pt'>
  <p class=->Cookies</p>
  </td>
  <td width=321 valign=top style='width:240.95pt;border:solid black 1.0pt;
  border-top:none;padding:2.75pt 2.75pt 2.75pt 2.75pt'>
  <p class=->Description of the used cookies is available in the “Policy for
  use of cookies” </p>
  </td>
 </tr>
</table>

<div style='border:none;border-bottom:solid silver 1.0pt;padding:0cm 0cm 1.0pt 0cm'>

<p class=MsoBodyText style='line-height:150%;border:none;padding:0cm'><span
style='color:black'>&nbsp;</span></p>

<p class=MsoBodyText style='line-height:150%;border:none;padding:0cm'><span
style='color:black'>In case of a legal dispute or proceedings requiring data
retention and/or at the request of a competent state authority, data may be
stored for longer than the duration specified above until the final settlement
of the dispute or the proceedings before all instances. The storage duration
may be changed according to the applicable legislation.</span></p>

<p class=MsoBodyText style='line-height:150%;border:none;padding:0cm'><b><span
style='color:black;background:transparent'>Backup</span></b><span style='color:black;
background:transparent'>. For the purpose of ensuring the reliability of the
Services and for prevention of data loss in case of technical issues, a backup
policy is applied. The maximum period for updating (erasure of data) from all
backup copies is 30 days. </span></p>

<p class=MsoBodyText style='line-height:150%;border:none;padding:0cm'><b><span
style='color:black'>5. Provision of information</span></b><span
style='color:black'><br>
<br>
</span><em><span lang=EN-US style='color:black;font-style:normal'>J</span></em><em><span
lang=EN-US style='color:#333333;font-style:normal'>obpreference.com</span></em><span
style='color:black'> shall not provide your personal data to third parties in
any way other than those set out in this Policy, the Terms and as prescribed by
law. </span></p>

<p class=MsoBodyText style='line-height:150%;border:none;padding:0cm'><b><span
lang=EN-US style='color:black'>5.1.</span></b><b><span style='color:black'>Suppliers
and subcontractors</span></b></p>

<p class=MsoBodyText style='line-height:150%;border:none;padding:0cm'><em><span
lang=EN-US style='color:black;font-style:normal'>J</span></em><em><span
lang=EN-US style='color:#333333;font-style:normal'>obpreference.com</span></em><span
style='color:black'> works with subcontractors and service providers such as
dedicated data centers for reliable and secure colocation of servers and
network equipment, accounting services, etc. </span></p>

<p class=MsoBodyText style='line-height:150%;border:none;padding:0cm'><b><span
lang=EN-US style='color:black'>5.2.</span></b><b>Third parties</b></p>

<p class=MsoBodyText style='line-height:150%;border:none;padding:0cm'>Your
personal data may only be provided to third parties in the following cases:
when this is prescribed for by law; if requested by a competent state or
judicial authority; with your explicit consent; if necessary to protect the
rights and legitimate interests of <em><span lang=EN-US style='color:black;
font-style:normal'>J</span></em><em><span lang=EN-US style='color:#333333;
font-style:normal'>obpreference.com</span></em> and/or other users of the
Services. </p>

<p class=MsoBodyText style='line-height:150%;border:none;padding:0cm'><b><span
lang=EN-US>5.3.</span></b><b>Change of ownership</b></p>

<p class=MsoBodyText style='line-height:150%;border:none;padding:0cm'>In the
event of a merger, acquisition or sale of assets affecting personal data
processing, You will be given an advance notice. </p>

<p class=MsoBodyText style='line-height:150%;border:none;padding:0cm'><b>6.
Rights with regard to personal data</b><br>
<br>
The Regulation provides for the following rights :<br>
<br>
<b>6.1. Right of information.</b><br>
This Policy aims to inform You in detail about the processing of your personal
data with regard to the Services provided.<br>
<br>
<b>6.2. Right of access.</b> <em><span style='font-style:normal'>You are
entitled to receive confirmation whether your personal data is being processed,
to receive access to it, as well as information about its processing and your
rights.</span></em> </p>

<p class=MsoBodyText style='line-height:150%;border:none;padding:0cm'><b>6.3.
Right of rectification.</b> <em><span style='font-style:normal'>You are
entitled to have your data rectified in case it is incomplete or inaccurate.</span></em>
</p>

<p class=MsoBodyText style='line-height:150%;border:none;padding:0cm'><b>6.4.
Right of erasure.</b> <em><span style='font-style:normal'>You have the right to
ask for data to be erased unless there is a material basis and/or legal
obligation for its processing.</span></em></p>

<p class=MsoBodyText style='line-height:150%;border:none;padding:0cm'><b>7.
Accuracy of the data</b><br>
<span lang=EN-US style='color:black'>J</span><span lang=EN-US style='color:
#333333'>obpreference.com</span> shall have no liability for the accuracy of
the data provided by You, does not perform checks in this respect and does not
guarantee the actual identity of the data subjects. In any case of doubt,
identified fraud and/or misuse, please notify us immediately. When providing
any data on the Website, You undertake not to violate other people’s rights in
relation to the protection of their personal data or their other rights.</p>

<p class=MsoBodyText style='line-height:150%;border:none;padding:0cm'><b><span
lang=EN-US>8</span>.</b><b> Data Protection Officer</b><br>
Questions and requests related to exercising your personal data protection
rights can be addressed to the Data Protection Officer of <em><span lang=EN-US
style='font-style:normal'>J<span style='color:#333333'>obpreference.com</span></span></em>:<br>
<br>
Email address: <a href="mailto:privacy@jobpreference.com">privacy@<span
lang=EN-US>j</span><em><span lang=EN-US style='color:#333333;font-style:normal;
text-decoration:none'>obpreference.com</span></em></a></p>

</div>

<p class=MsoBodyText><b><span style='font-size:16.0pt'>Policy for use of
cookies </span></b></p>

<div style='border:none;border-bottom:solid silver 1.0pt;padding:0cm 0cm 1.0pt 0cm'>

<p class=MsoBodyText style='line-height:150%;border:none;padding:0cm'><em><span
lang=EN-US style='color:#333333;font-style:normal'>Here you can find
information about the cookies and similar technologies used for storage of
information related to the provided services (“Services”) on the website
www.jobpreference.com and/or the mobile versions and applications (“the
Website”).<br>
<br>
<b>POLICY FOR USE OF COOKIES</b><br>
<br>
Cookies allow information to be stored in your browser (or mobile application).
They are used to store settings and identifiers required for some of the
services provided on the Website.<br>
<br>
Jobpreference.com applies policy in line with the EU requirements.<br>
Cookies are used for the following purposes: </span></em></p>

</div>

<table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
 style='margin-left:-.4pt;border-collapse:collapse'>
 <tr>
  <td width=214 valign=top style='width:160.6pt;border:solid black 1.0pt;
  border-right:none;padding:2.75pt 2.75pt 2.75pt 2.75pt'>
  <p class=->Type</p>
  </td>
  <td width=214 valign=top style='width:160.65pt;border:solid black 1.0pt;
  border-right:none;padding:2.75pt 2.75pt 2.75pt 2.75pt'>
  <p class=->Storage period </p>
  </td>
  <td width=214 valign=top style='width:160.65pt;border:solid black 1.0pt;
  padding:2.75pt 2.75pt 2.75pt 2.75pt'>
  <p class=->Description</p>
  </td>
 </tr>
 <tr>
  <td width=214 valign=top style='width:160.6pt;border-top:none;border-left:
  solid black 1.0pt;border-bottom:solid black 1.0pt;border-right:none;
  padding:2.75pt 2.75pt 2.75pt 2.75pt'>
  <p class=->Session and navigation management (session identifier, security
  protection, previous steps for the ‘back button’, etc.)</p>
  </td>
  <td width=214 valign=top style='width:160.65pt;border-top:none;border-left:
  solid black 1.0pt;border-bottom:solid black 1.0pt;border-right:none;
  padding:2.75pt 2.75pt 2.75pt 2.75pt'>
  <p class=->Temporarily (for the duration of your visit on the Website) </p>
  </td>
  <td width=214 valign=top style='width:160.65pt;border:solid black 1.0pt;
  border-top:none;padding:2.75pt 2.75pt 2.75pt 2.75pt'>
  <p class=-><em>These cookies are technically required to login, navigate and
  use the Website. The Website cannot function properly and reliably without
  them.</em> </p>
  </td>
 </tr>
 <tr>
  <td width=214 valign=top style='width:160.6pt;border-top:none;border-left:
  solid black 1.0pt;border-bottom:solid black 1.0pt;border-right:none;
  padding:2.75pt 2.75pt 2.75pt 2.75pt'>
  <p class=->Security (protection against access from unauthorised devices,
  etc.) </p>
  </td>
  <td width=214 valign=top style='width:160.65pt;border-top:none;border-left:
  solid black 1.0pt;border-bottom:solid black 1.0pt;border-right:none;
  padding:2.75pt 2.75pt 2.75pt 2.75pt'>
  <p class=->Up to 6 /six/ months from last login </p>
  </td>
  <td width=214 valign=top style='width:160.65pt;border:solid black 1.0pt;
  border-top:none;padding:2.75pt 2.75pt 2.75pt 2.75pt'>
  <p class=-><em>These are cookies used to protect the account against
  unauthorised access.</em> </p>
  </td>
 </tr>
 <tr>
  <td width=214 valign=top style='width:160.6pt;border-top:none;border-left:
  solid black 1.0pt;border-bottom:solid black 1.0pt;border-right:none;
  padding:2.75pt 2.75pt 2.75pt 2.75pt'>
  <p class=->Settings (Website language) </p>
  </td>
  <td width=214 valign=top style='width:160.65pt;border-top:none;border-left:
  solid black 1.0pt;border-bottom:solid black 1.0pt;border-right:none;
  padding:2.75pt 2.75pt 2.75pt 2.75pt'>
  <p class=->Up to 6 / six / months from last use </p>
  </td>
  <td width=214 valign=top style='width:160.65pt;border:solid black 1.0pt;
  border-top:none;padding:2.75pt 2.75pt 2.75pt 2.75pt'>
  <p class=-><em>These are cookies used to save your settings;</em></p>
  </td>
 </tr>
</table>

</div>

</body>

</html>
`;
