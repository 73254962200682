import React, { useContext, useEffect } from "react";
import DreamDynamicGridComponent from "../../ui_components/DreamDynamicGrid/DreamDynamicGridComponent";
import LanguagesSingleItemComponent from "./LanguagesSingleItemComponent/LanguagesSingleItemComponent";
import MyCvStateContext from "../../Pages/MyCvPage/Context/MyCvStateContext";
import * as LanguagesReducer from "./LanguagesReducer";
import { useImmerReducer } from "use-immer";
import { makeStyles } from "@material-ui/core/styles";

import JobPreferenceStateContext from "../../Pages/CreateJobPreferencePage/Context/JobPreferenceStateContext";
import JobPreferenceDispatchContext from "../../Pages/CreateJobPreferencePage/Context/JobPreferenceDispatchContext";

function LanguagesComponent(props) {
  const myCvContext = useContext(MyCvStateContext);
  const [state, dispatch] = useImmerReducer(
    LanguagesReducer.reducer,
    LanguagesReducer.originalState
  );

  const jobPreferenceStateContext = useContext(JobPreferenceStateContext);
  const jobPreferenceDispatchContext = useContext(JobPreferenceDispatchContext);

  const stateContext = props.stateContextName
    ? eval(props.stateContextName)
    : myCvContext;

  const initialDataLoaded =
    props.stateContextName === "jobPreferenceStateContext"
      ? stateContext.data.cv.initialDataLoaded
      : stateContext.initialDataLoaded;

  const levelIsLoaded =
    props.stateContextName === "jobPreferenceStateContext"
      ? stateContext.data.cv.selectsData.languages.level.isLoaded
      : stateContext.selectsData.languages.level.isLoaded;

  const langIsLoaded =
    props.stateContextName === "jobPreferenceStateContext"
      ? stateContext.data.cv.selectsData.languages.lang.isLoaded
      : stateContext.selectsData.languages.lang.isLoaded;

  const level =
    props.stateContextName === "jobPreferenceStateContext"
      ? stateContext.data.cv.selectsData.languages.level
      : stateContext.selectsData.languages.level;

  const lang =
    props.stateContextName === "jobPreferenceStateContext"
      ? stateContext.data.cv.selectsData.languages.lang
      : stateContext.selectsData.languages.lang;

  const formData =
    props.stateContextName === "jobPreferenceStateContext"
      ? stateContext.formData.cv
      : stateContext.formData;

  useEffect(() => {
    if (initialDataLoaded && langIsLoaded && levelIsLoaded) {
      let formattedData = [];
      formData.languages.forEach((language) => {
        formattedData.push({
          id: language.id,
          singleItem: (
            <LanguagesSingleItemComponent
              optionsData={{
                lang,
                level,
              }}
              languageData={language}
              stateContextName={props.stateContextName}
              dispatchContextName={props.dispatchContextName}
            />
          ),
        });
      });
      dispatch({
        type: "setFormattedData",
        value: formattedData,
      });
    }
  }, [initialDataLoaded, langIsLoaded, levelIsLoaded]);

  return (
    <DreamDynamicGridComponent
      singleItem={
        <LanguagesSingleItemComponent
          optionsData={{
            lang,
            level,
          }}
          stateContextName={props.stateContextName}
          dispatchContextName={props.dispatchContextName}
        />
      }
      initialDataLoaded={state.isDataFormatted}
      initialData={state.formattedData}
    />
  );
}

export default LanguagesComponent;
