import React from "react";
import DreamPage from "../../ui_components/DreamPage";
import * as RouteConstants from "../../AppComponent/AppRoutes";

const ContactUsPage = (props) => {
  return (
    <DreamPage title={"footer.contact"} route={RouteConstants.ROUTE_CONTACT_US}>
      <p style={{ fontSize: 20 }}>
        Email:{" "}
        <a href="mailto:contact@jobpreference.com">contact@jobpreference.com</a>
      </p>
    </DreamPage>
  );
};

export default ContactUsPage;
