import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import { useImmerReducer } from "use-immer";
import { useTranslation } from "react-i18next";
import * as Constants from "../../helpers/Constants";
import * as MsReducer from "./DreamMultiselectComponentReducer";
import Chip from "@material-ui/core/Chip";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function DreamMultipleSelect(props) {
  const classes = useStyles();
  const [t] = useTranslation(Constants.TRANSLATION_COMMON);

  const dispatchContext = props.dispatchContext;
  const [state, dispatch] = useImmerReducer(
    MsReducer.reducer,
    MsReducer.originalState
  );

  const handleChange = (event) => {
    let length = event.target.value.length;

    dispatchContext({
      type: "additionalSkillsSkillChanged",
      value: event.target.value[length - 1],
    });
  };

  useEffect(() => {
    if (props.value) {
      dispatch({
        type: "setData",
        value: props.value,
      });
    }
  }, [props.value]);

  return (
    <FormControl className={classes.formControl} fullWidth>
      <InputLabel>{props.groupName}</InputLabel>
      <Select
        multiple={true}
        value={state.data}
        onChange={
          props.manualyChangeHandler ? props.manualyChangeHandler : handleChange
        }
        input={<Input key={props.groupName} />}
        renderValue={(selected) => (
          <div className={classes.chips}>
            {selected.map((value) => {
              return value.isChecked ? (
                <Chip
                  key={value.id}
                  label={t(value.label)}
                  className={classes.chip}
                />
              ) : (
                ""
              );
            })}
          </div>
        )}
        MenuProps={MenuProps}
      >
        {state.data.map((checkbox) => (
          <MenuItem
            key={checkbox.id}
            value={checkbox.id}
            style={{
              background: checkbox.isChecked ? "lightGrey" : "white",
            }}
          >
            <ListItemText
              disableTypography={true}
              style={{
                fontWeight: checkbox.isChecked ? "bold" : "normal",
              }}
              primary={t(checkbox.label)}
            />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
