export const originalState = {
  debitTransactions: [],
  downloadId: "",
  downloadCounter: 0,
  paginationDebit: {
    currentPage: 0,
    currentPageSize: 10,
    hasMoreData: false,
    cursor: null,
    totalPageCount: 0,
    totalCount: 0,
    isLoadingDebitData: false,
  },
  creditTransactions: [],
  paginationCredit: {
    currentPage: 0,
    currentPageSize: 10,
    hasMoreData: false,
    cursor: null,
    totalPageCount: 0,
    totalCount: 0,
    isLoadingCreditData: false,
  },
  lastDeletedId: null,
  isModalOpen: false,
  balance: 0,
};

export function reducer(draft, action) {
  switch (action.type) {
    case "debitTransactionsDataLoaded":
      draft.debitTransactions = action.value;
      break;
    case "creditTransactionsDataLoaded":
      draft.creditTransactions = action.value;
      break;

    // Start Debit Pagination  -----
    case "setDebitPage":
      draft.paginationDebit.currentPage = action.value;
      break;
    case "setDebitRowsPerPage":
      draft.paginationDebit.currentPageSize = action.value;
      break;
    case "setDebitPaginationTotalCount":
      draft.paginationDebit.totalCount = action.value;
      break;
    case "setDebitPaginationTotalPageCount":
      draft.paginationDebit.totalPageCount = action.value;
      break;
    case "setIsLoadingDebitData":
      draft.paginationDebit.isLoadingDebitData = action.value;
      break;
    // End Debit Pagination  -----

    // Start Credit Pagination  -----
    case "setCreditPage":
      draft.paginationCredit.currentPage = action.value;
      break;
    case "setCreditRowsPerPage":
      draft.paginationCredit.currentPageSize = action.value;
      break;
    case "setCreditPaginationTotalCount":
      draft.paginationCredit.totalCount = action.value;
      break;
    case "setCreditPaginationTotalPageCount":
      draft.paginationCredit.totalPageCount = action.value;
      break;
    case "setIsLoadingCreditData":
      draft.paginationCredit.isLoadingCreditData = action.value;
      break;
    // End Credit Pagination  -----

    // New
    case "balanceDataLoaded":
      draft.balance = action.value;
      break;
    case "setDownloadCounter":
      draft.downloadCounter += 1;
      break;
    case "setDownloadTransaction":
      draft.downloadTransaction = action.value;
      break;
  }
}
