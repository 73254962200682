import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar";
import DreamFileUploader from "../../ui_components/DreamFileUploaderMultiple/DreamFileUploader/DreamFileUploader";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginBottom: 20,
    position: "relative",
  },
  paper: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    borderRadius: 15,
    // position: "relative",
    boxShadow: "0px 10px 10px rgba(0, 0, 0, 0.25)",
  },
  control: {
    padding: theme.spacing(0),
  },
  innerGridItem: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  large: {
    maxWidth: theme.spacing(12),
    height: theme.spacing(12),
  },
}));

export default function SpacingGrid(props) {
  const [spacing, setSpacing] = React.useState(2);
  const classes = useStyles();

  const handleChange = (event) => {
    setSpacing(Number(event.target.value));
  };

  const theme = useTheme();

  return (
    <Grid container justify="center" spacing={spacing} className={classes.root}>
      <Grid item className={classes.innerGridItem}>
        <Paper
          className={classes.paper}
          elevation={3}
          style={{
            maxWidth: props.maxWidth,
            minHeight: props.minHeight,
            height: props.height,
            padding: props.padding,
          }}
        >
          <DreamFileUploader
            previewAvatar
            // imageSource={props.imageSource}
            actionUrl={props.actionUrl}
            upDispatch={props.upDispatch}
            maxFiles={props.maxFiles}
            accept={props.accept}
            initialFiles={props.initialFiles}
            loadedDataBe={props.loadedDataBe}
            useDescription={props.useDescription}
          />
          {props.children}
        </Paper>
      </Grid>
    </Grid>
  );
}
