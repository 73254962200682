import FormValidator from "../../helpers/FormValidator";
import * as Constants from "../../helpers/Constants";
import * as DreamAutocompleteHelper from "../../ui_components/DreamAutocomplete/DreamAutocompleteHelper";

export const allInputs = {
  companyName: {
    value: "",
    isValid: true,
    errorMessage: "",
  },
  companyWebsite: {
    value: "",
    isValid: true,
    errorMessage: "",
  },
  companyDescription: {
    value: "",
    isValid: true,
    errorMessage: "",
  },
};

export const originalState = {
  fields: { ...allInputs },
  location: {},
  locationType: DreamAutocompleteHelper.SEARCH_TYPE_CITY,
  industryId: "",
  hasValidationErrs: false,
  formSubmittedCount: 0,
  uploadedFiles: {},
  initalFileUploadUrl: "",
  initialFileUploads: [],
  loadedDataBe: false,
  formSuccessfullySubmittedCount: 0,
};

export function reducer(draft, action) {
  switch (action.type) {
    case "companyNameChange":
      draft.fields.companyName.value = action.value.value;
      draft.fields.companyName.errorMessage = FormValidator.validateField(
        action.value.value,
        { minLength: 3, maxLength: 50, required: true },
        action.value.t
      );
      draft.fields.companyName.isValid = !Boolean(
        draft.fields.companyName.errorMessage
      );

      // Check if Form has validation errors
      draft.hasValidationErrs = FormValidator.hasFormValidationErrors(
        draft,
        allInputs
      );
      return;
    case "locationTypeChanged":
      draft.locationType = action.value;
      return;

    case "setLocation":
      draft.location = action.value;
      return;

    case "setIndustryId":
      draft.industryId = action.value;
      return;

    case "companyWebsiteChange":
      draft.fields.companyWebsite.value = action.value.value;
      draft.fields.companyWebsite.errorMessage = FormValidator.validateField(
        action.value.value,
        { minLength: 3, maxLength: 50 },
        action.value.t
      );
      draft.fields.companyWebsite.isValid = !Boolean(
        draft.fields.companyWebsite.errorMessage
      );

      // Check if Form has validation errors
      draft.hasValidationErrs = FormValidator.hasFormValidationErrors(
        draft,
        allInputs
      );
      return;

    case "companyDescriptionChange":
      draft.fields.companyDescription.value = action.value.value;
      draft.fields.companyDescription.errorMessage =
        FormValidator.validateField(
          action.value.value,
          { minLength: 5 },
          action.value.t
        );
      draft.fields.companyDescription.isValid = !Boolean(
        draft.fields.companyDescription.errorMessage
      );

      // Check if Form has validation errors
      draft.hasValidationErrs = FormValidator.hasFormValidationErrors(
        draft,
        allInputs
      );
      return;

    case "userDataLoaded":
      const userData = action.value;

      if (userData.logo) {
        draft.initialFileUploads.push({
          fileUrl: `${Constants.BASE_URL}upload/${userData.logo}`,
          fileId: userData.logo,
          isImg: true,
          isInitialUpload: true,
        });
      }

      draft.fields.companyName.value =
        userData.oname === null ? "" : userData.oname;
      draft.locationType = userData.location_type ? userData.location_type : 3;

      const location = {
        id: userData.location_id,
        location_type: userData.location_type,
        name: userData.location,
      };
      draft.location = location ? location : {};
      draft.industryId = userData.indusrty;
      draft.fields.companyWebsite.value =
        userData.url === null ? "" : userData.url;
      draft.fields.companyDescription.value = userData.description || "";

      draft.loadedDataBe = true;

      return;

    case "formSubmitted":
      draft.hasValidationErrs = FormValidator.hasFormValidationErrors(
        draft,
        allInputs
      );
      if (!draft.hasValidationErrs) {
        draft.formSubmittedCount++;
      }
      return;
    case "formSuccessfullySubmitted":
      draft.initialFileUploads = [];
      draft.loadedDataBe = false;
      draft.formSuccessfullySubmittedCount++;
    case "disableSubmitButton":
      draft.hasValidationErrs = action.value;
      return;
    case "uploadedFiles":
      draft.uploadedFiles = action.value;
      return;
  }
}
