import React, { useContext } from "react";
import StateContext from "../../../Context/StateContext";
import DispatchContext from "../../../Context/DispatchContext";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import CloseIcon from "@material-ui/icons/Close";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as FileUploaderHelper from "../DreamFileUploader/FileUploaderHelper";
import Tooltip from "@material-ui/core/Tooltip";
import {
  CardHeaderWrapper,
  CardHeaderHeadingsWrapper,
  CloseBtn,
  Heading,
  SubHeading,
  HeadingWrapper,
  InputWrapper,
  CloseBtnHidden,
} from "./SingleItemFileUploaderComponent.module.scss";
import * as Colors from "../../../styles/01.settings/01-settings-colors.module.scss";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Axios from "axios";
import AssignmentIcon from "@material-ui/icons/Assignment";
import DeleteIcon from "@material-ui/icons/Delete";

const axios = Axios;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexFlow: "row nowrap",
    width: "100%",
    border: "1px solid rgba(0, 0, 0, 0.1)",
    boxShadow: "0px 5px 13px rgba(0, 0, 0, 0.25)",
    borderRadius: 15,
  },
  iconWrapper: {
    backgroundColor: Colors.text,
    width: "15%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 20,
    paddingRight: 20,
    borderTopLeftRadius: 15,
    borderBottomLeftRadius: 15,
  },
  file: {
    display: "flex",
    width: "100%",
    padding: 20,
  },
}));

const NO_IMAGE_URL =
  "https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/300px-No_image_available.svg.png";

export default function SingleItemFileUploaderComponent(props) {
  const classes = useStyles();
  const appContext = useContext(StateContext);
  const appDispatch = useContext(DispatchContext);
  const token = appContext.appState.user_token;
  const t = appContext.context.translateService.t;

  function getImg() {
    switch (props.data.status) {
      case FileUploaderHelper.UPLOAD_STATUS_DONE:
        let tokenParam = props.data.isInitialUpload
          ? `?access_token=${token}`
          : "";
        return (
          <CardMedia
            className={classes.media}
            image={
              props.data.previewUrl
                ? props.data.previewUrl + tokenParam
                : NO_IMAGE_URL
            }
            title={props.data.name}
          />
        );

      default:
        return <CircularProgress />;
    }
  }

  const CloseButton = ({ className }) => {
    return (
      <IconButton
        className={className}
        onClick={(e) => {
          props.handleDelete(
            props.data,
            props.dispatchFileUpload,
            axios,
            appDispatch
          );
          if (props.somethingManualyChangedHandler) {
            props.somethingManualyChangedHandler();
          }
        }}
        aria-label="close"
      >
        <DeleteIcon />
      </IconButton>
    );
  };

  return (
    <Grid container className={classes.root}>
      <Grid item className={classes.iconWrapper}>
        <AssignmentIcon htmlColor={Colors.white} />
      </Grid>
      {getImg()}
      {props.useDescription ? (
        <Grid item className={classes.file}>
          <TextField
            autoFocus
            value={props.data.fileDescription ? props.data.fileDescription : ""}
            fullWidth
            id={props.data.id}
            label={t("basic.fileDescription") + "*"}
            onChange={(e) => props.handleDescriptionChange(e)}
          />
          <CloseButton className={CloseBtn} />
        </Grid>
      ) : (
        ""
      )}
    </Grid>
  );
}
