import React, { useRef, useEffect, useContext } from "react";
import { Editor } from "@tinymce/tinymce-react";
import DispatchContext from "../../Context/DispatchContext";
import { IconButton, Tooltip, makeStyles } from "@material-ui/core";
import FeedbackIcon from "@material-ui/icons/Feedback";
import DreamButton from "../DreamButton/DreamButton";
import TextField from "@material-ui/core/TextField";
import { useTranslation } from "react-i18next";
import * as Constants from "../../helpers/Constants";
import { useImmerReducer } from "use-immer";
import { useLocation } from "react-router-dom";
import Axios from "axios";
import * as FeedbackReducer from "./FeedbackComponentReducer";
import * as FeedbackHelper from "./FeedbackComponentHelper";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";

const styles = (theme) => ({
  root: {
    margin: 0,
    paddingTop: 0,
    paddingBottom: 0,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    paddingTop: 0,
    paddingLeft: 10,
    paddingRight: 10,
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FeedbackComponent(props) {
  const [t] = useTranslation(Constants.TRANSLATION_COMMON);
  const appDispatch = useContext(DispatchContext);

  const [state, dispatch] = useImmerReducer(
    FeedbackReducer.reducer,
    FeedbackReducer.originalState
  );

  const useStyles = makeStyles({
    dialogPaper: {
      margin: 0,
      border: "1px solid rgba(0, 0, 0, 0.1)",
      boxShadow: "0px 10px 10px rgba(0, 0, 0, 0.25)",
      borderRadius: 15,
      position: "fixed",
      bottom: 65,
      right: 0,
    },
    appBar: {
      position: "relative",
    },
  });
  const classes = useStyles();

  const location = useLocation();

  useEffect(() => {
    clearForm();
    dispatch({ type: "setUrl", value: location.pathname });
  }, []);

  useEffect(() => {
    clearForm();
    dispatch({ type: "setUrl", value: location.pathname });
  }, [location]);

  const editorRef = useRef(null);

  const clearForm = () => {
    dispatch({ type: "setSubject", value: "" });
    dispatch({ type: "setContent", value: "" });
    if (editorRef.current) {
      editorRef.current.setContent("");
    }
  };

  const handleChange = (content, editor) => {
    dispatch({ type: "setContent", value: content });
  };

  const handleClose = () => {
    dispatch({ type: "setOpen", value: false });
    dispatch({ type: "setSubject", value: "" });
    if (editorRef.current) {
      editorRef.current.setContent("");
    }
  };

  return (
    <div style={{ position: "fixed", bottom: 60, right: 0 }}>
      <Tooltip title="Send Feedback" arrow>
        <IconButton
          onClick={() => dispatch({ type: "setOpen", value: true })}
          size="medium"
        >
          <FeedbackIcon fontSize="large" />
        </IconButton>
      </Tooltip>
      <Dialog
        onClose={handleClose}
        open={state.dialogOpen}
        TransitionComponent={Transition}
        keepMounted
        classes={{
          paper: classes.dialogPaper,
        }}
      >
        <DialogTitle disableTypography onClose={handleClose}>
          <h2>{t("feedback.giveUs")}</h2>
        </DialogTitle>
        <DialogContent dividers>
          <div>
            <TextField
              autoComplete="off"
              margin="dense"
              id="subject"
              name="subject"
              label={t("feedback.subject")}
              fullWidth
              value={state.formData.subject ? state.formData.subject : ""}
              onChange={(e) =>
                dispatch({
                  type: "setSubject",
                  value: e.target.value,
                })
              }
            />
          </div>
          <Editor
            apiKey={"th4ubkyrue1tnvdyxmfwkau15b4tpfueeiartz17bikjkhxu"}
            onInit={(evt, editor) => (editorRef.current = editor)}
            initialValue={state.content}
            init={{
              height: 300,
              menubar: false,
              skin: "fabric",
              plugins: [
                "advlist autolink lists link image charmap print preview anchor",
                "searchreplace visualblocks code fullscreen",
                "insertdatetime media table paste code help wordcount",
                "image code",
              ],
              toolbar: "undo redo | image | formatselect |" + "bullist",
              content_style:
                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
              statusbar: false,
              paste_data_images: true,
              image_dimensions: false,
              image_class_list: [
                { title: "Responsive", value: "img-responsive" },
              ],
              /* enable title field in the Image dialog*/
              image_title: true,
              /* enable automatic uploads of images represented by blob or data URIs*/
              automatic_uploads: true,
              /*
    URL of our upload handler (for more details check: https://www.tiny.cloud/docs/configure/file-image-upload/#images_upload_url)
    images_upload_url: 'postAcceptor.php',
    here we add custom filepicker only to Image dialog
  */
              file_picker_types: "image",
              /* and here's our custom image picker*/
              file_picker_callback: function (cb, value, meta) {
                var input = document.createElement("input");
                input.setAttribute("type", "file");
                input.setAttribute("accept", "image/*");

                /*
      Note: In modern browsers input[type="file"] is functional without
      even adding it to the DOM, but that might not be the case in some older
      or quirky browsers like IE, so you might want to add it to the DOM
      just in case, and visually hide it. And do not forget do remove it
      once you do not need it anymore.
    */

                input.onchange = function () {
                  var file = this.files[0];

                  var reader = new FileReader();
                  reader.onload = function () {
                    /*
          Note: Now we need to register the blob in TinyMCEs image blob
          registry. In the next release this part hopefully won't be
          necessary, as we are looking to handle it internally.
        */
                    var id = "blobid" + new Date().getTime();
                    var blobCache =
                      window.tinymce.activeEditor.editorUpload.blobCache;
                    var base64 = reader.result.split(",")[1];
                    var blobInfo = blobCache.create(id, file, base64);
                    blobCache.add(blobInfo);

                    /* call the callback and populate the Title field with the file name */
                    cb(blobInfo.blobUri(), { title: file.name });
                  };
                  reader.readAsDataURL(file);
                };

                input.click();
              },
            }}
            value={state.content}
            onEditorChange={handleChange}
          />
          <div style={{ width: "100%" }}>
            <p>You can drop an image within the area</p>
          </div>
          <DialogActions
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 10,
            }}
          >
            <DreamButton
              kind={"contained"}
              buttonLabel={"basic.send"}
              color={"primary"}
              handleClick={() =>
                FeedbackHelper.handleSubmit(
                  state,
                  dispatch,
                  appDispatch,
                  Axios,
                  clearForm
                )
              }
            />
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
}
