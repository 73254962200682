import React, { useContext, useEffect } from "react";
import StateContext from "../../Context/StateContext";
import DreamDynamicGridComponent from "../../ui_components/DreamDynamicGrid/DreamDynamicGridComponent";
import ExperienceSingleItemComponent from "./ExperinceSingleItem/ExperinceSingleItemComponent";
import MyCvStateContext from "../../Pages/MyCvPage/Context/MyCvStateContext";
import * as ExperienceReducer from "./ExperienceReducer";
import { useImmerReducer } from "use-immer";

import JobPreferenceStateContext from "../../Pages/CreateJobPreferencePage/Context/JobPreferenceStateContext";
import JobPreferenceDispatchContext from "../../Pages/CreateJobPreferencePage/Context/JobPreferenceDispatchContext";

function ExperienceComponent(props) {
  const myCvContext = useContext(MyCvStateContext);
  const [state, dispatch] = useImmerReducer(
    ExperienceReducer.reducer,
    ExperienceReducer.originalState
  );

  const jobPreferenceStateContext = useContext(JobPreferenceStateContext);
  const jobPreferenceDispatchContext = useContext(JobPreferenceDispatchContext);

  const appContext = useContext(StateContext);

  const stateContext = props.stateContextName
    ? eval(props.stateContextName)
    : myCvContext;

  const initialDataLoaded =
    props.stateContextName === "jobPreferenceStateContext"
      ? stateContext.data.cv.initialDataLoaded
      : stateContext.initialDataLoaded;

  const formData =
    props.stateContextName === "jobPreferenceStateContext"
      ? stateContext.formData.cv
      : stateContext.formData;

  useEffect(() => {
    if (initialDataLoaded) {
      let formattedData = [];
      dispatch({
        type: "newDataLoaded",
      });
      formData.experience.forEach((experience) => {
        formattedData.push({
          id: experience.id,
          singleItem: (
            <ExperienceSingleItemComponent
              experienceData={experience}
              stateContextName={props.stateContextName}
              dispatchContextName={props.dispatchContextName}
              newDataLoaded={state.dataLoadedTimes}
            />
          ),
        });
      });
      dispatch({
        type: "setFormattedData",
        value: formattedData,
      });
    }
  }, [initialDataLoaded, stateContext.newApiCount]);

  return (
    <DreamDynamicGridComponent
      singleItem={
        <ExperienceSingleItemComponent
          stateContextName={props.stateContextName}
          dispatchContextName={props.dispatchContextName}
        />
      }
      initialDataLoaded={state.isDataFormatted}
      initialData={state.formattedData}
    />
  );
}

export default ExperienceComponent;
