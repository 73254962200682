import React, { useEffect, useContext } from "react";
import DreamRadioButtonsComponent from "../../ui_components/DreamRadioButtons/DreamRadioButtonsComponent";
import JobPreferenceDispatchContext from "../../Pages/CreateJobPreferencePage/Context/JobPreferenceDispatchContext";
import JobPreferenceStateContext from "../../Pages/CreateJobPreferencePage/Context/JobPreferenceStateContext";
import * as WorkScheduleHelper from "./WorkScheduleHelper";

function WorkScheduleComponent() {
  const jobPreferenceDispatchContext = useContext(JobPreferenceDispatchContext);
  const jobPreferenceStateContext = useContext(JobPreferenceStateContext);

  function workScheduleChanged(value) {
    jobPreferenceDispatchContext({
      type: "workScheduleChanged",
      value: parseInt(value),
    });
  }

  return (
    <DreamRadioButtonsComponent
      name={"jp.work"}
      value={
        jobPreferenceStateContext.formData.workScheduleType.workScheduleType
      }
      data={WorkScheduleHelper.workScheduleRadioButtons}
      changeHandler={workScheduleChanged}
    />
  );
}

export default WorkScheduleComponent;
