import React, { useContext } from "react";
import DreamPage from "../../ui_components/DreamPage";
import * as RouteConstants from "../../AppComponent/AppRoutes";
import StateContext from "../../Context/StateContext";

const CreditsProgramPage = () => {
  const appContext = useContext(StateContext);
  let lang = appContext.appState.lang;

  let container = require(`./referal_${lang}.html.js`);

  return (
    <DreamPage
      title={"footer.referralProgram"}
      route={RouteConstants.ROUTE_CREDITS_PROGRAM}
    >
      <div
        style={{ maxWidth: 900, marginLeft: "3%", marginRight: "3%" }}
        className="terms"
        dangerouslySetInnerHTML={{
          __html: container,
        }}
      ></div>
    </DreamPage>
  );
};

export default CreditsProgramPage;
