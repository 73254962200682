import React, { useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import { useImmerReducer } from "use-immer";
import { useTranslation } from "react-i18next";
import * as Constants from "../../helpers/Constants";
import * as Colors from "../../styles/01.settings/01-settings-colors.module.scss";
import * as EwmReducer from "./EmployersWithMessagesViewPageReducer";
import * as EwmHelper from "./EmployersWithMessagesViewPageHelper";
import DreamPage from "../../ui_components/DreamPage";
import Badge from "@material-ui/core/Badge";
import Axios from "axios";
import DispatchContext from "../../Context/DispatchContext";

import { Link as RouterLink, useParams } from "react-router-dom";
import TablePagination from "@material-ui/core/TablePagination";
import DreamCardMedia from "../../ui_components/DreamCardMediaComponent/DreamCardMedia";
import MailIcon from "@material-ui/icons/Mail";
import DreamButton from "../../ui_components/DreamButton/DreamButton";
import DreamAvatar from "../../ui_components/DreamAvatarComponent/DreamAvatarComponent";

const CONVERSATION = 1;

const useStyles = makeStyles({
  table: {
    maxWidth: 770,
  },
  title: {
    fontSize: 24,
    fontWeight: "bold",
  },
  tableCell: {
    textAlign: "center",
    borderBottom: "none",
    padding: 0,
  },
  tblContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    overflowX: "visible",
  },
  tblRow: {},
  actionsButton: {
    minWidth: "9em",
    alignSelf: "center",
  },
  mailIcon: {
    marginRight: 5,
  },
  menuButton: {
    width: "100%",
  },
  cardHeaderAction: {
    alignSelf: "center",
    marginTop: 0,
    marginRight: 0,
  },
  subHeader: {
    color: Colors.success,
  },
  subHeaderDanger: {
    color: Colors.danger,
  },
  // PAGINATION
  paginationToolbar: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
  },
});

const pageTitle = "jp.jpewmPageTitle";

const EmployersWithMessagesViewPage = () => {
  const classes = useStyles();
  const [t] = useTranslation(Constants.TRANSLATION_COMMON);
  const appDispatch = useContext(DispatchContext);

  const { jpID } = useParams();

  const [state, dispatch] = useImmerReducer(
    EwmReducer.reducer,
    EwmReducer.originalState
  );

  const handleChangePage = (event, newPage) => {
    dispatch({ type: "setPage", value: newPage });
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch({
      type: "setRowsPerPage",
      value: parseInt(event.target.value, 10),
    });
    dispatch({ type: "setPage", value: 0 });
  };

  const emptyRows =
    state.pagination.page_size -
    Math.min(
      state.pagination.page_size,
      parseInt(state.pagination.totalCount, 10).length -
        state.pagination.page * state.pagination.page_size
    );

  // On mount
  useEffect(() => {
    const ourRequest = Axios.CancelToken.source();
    EwmHelper.getEmployers(
      jpID,
      state.pagination.page_size,
      state.pagination.page,
      CONVERSATION,
      Axios,
      ourRequest,
      appDispatch,
      dispatch
    );

    return () => {
      ourRequest.cancel();
    };
  }, []);

  // On page_size or page change
  useEffect(() => {
    const ourRequest = Axios.CancelToken.source();
    EwmHelper.getEmployers(
      jpID,
      state.pagination.page_size,
      state.pagination.page,
      CONVERSATION,
      Axios,
      ourRequest,
      appDispatch,
      dispatch
    );
    return () => {
      ourRequest.cancel();
    };
  }, [state.pagination.page_size, state.pagination.page]);

  return (
    <DreamPage
      title={t(pageTitle)}
      backButton
      backButtonDisabled={!state.employersDataLoaded}
      backButtonTo={"/job-preferences"}
    >
      <TableContainer className={classes.tblContainer}>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          aria-label="enhanced table"
        >
          <TableBody>
            {state.employers.map((employer) => (
              <TableRow key={employer.id}>
                <TableCell className={classes.tableCell}>
                  <DreamCardMedia
                    cardHeader
                    avatar={
                      <DreamAvatar
                        style={{ height: "50px", width: "50px" }}
                        imageSource={`${Constants.BASE_URL}upload/${employer.picture}`}
                      />
                    }
                    cardHeaderStyle={{ padding: "10px 16px 10px 16px" }}
                    cardHeaderAction={classes.cardHeaderAction}
                    subheader={`${employer.uname}${employer.oname ? "," : ""} ${
                      employer.oname
                    }`}
                    buttonAction={
                      <DreamButton
                        className={classes.actionsButton}
                        kind="contained"
                        buttonLabel={t("jos.messages")}
                        color={"secondary"}
                        btnComponent={RouterLink}
                        to={`/job-preference-employer-with-messages/${employer.id}/${jpID}`}
                        icon={
                          parseInt(employer.unread_messages) > 0 ? (
                            <Badge
                              badgeContent={employer.unread_messages}
                              color="primary"
                            >
                              <MailIcon className={classes.mailIcon} />
                            </Badge>
                          ) : (
                            <MailIcon className={classes.mailIcon} />
                          )
                        }
                      />
                    }
                  />
                </TableCell>
              </TableRow>
            ))}
            {emptyRows > 0 && (
              <TableRow>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        classes={{ toolbar: classes.paginationToolbar }}
        rowsPerPageOptions={EwmHelper.rowsPerPageOptions}
        labelRowsPerPage={t("tablePagination.rowsPerPage")}
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to} ${t("tablePagination.of")} ${
            count !== -1 ? count : `${t("tablePagination.moreThan")} ${to}`
          }`
        }
        component="div"
        count={parseInt(state.pagination.totalCount, 10)}
        rowsPerPage={state.pagination.page_size}
        page={parseInt(state.pagination.page, 10)}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </DreamPage>
  );
};

export default EmployersWithMessagesViewPage;
