import React, { useEffect, useContext } from "react";
import Axios from "axios";
import { useHistory, withRouter, Link as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";
import StateContext from "../../../Context/StateContext";
import DispatchContext from "../../../Context/DispatchContext";
import DreamPage from "../../../ui_components/DreamPage";
import { useImmerReducer } from "use-immer";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import * as Constants from "../../../helpers/Constants";
import Aux from "../../../ui_components/AuxComponent/AuxComponent";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import DreamRadioButtons from "../../../ui_components/DreamRadioButtons/DreamRadioButtonsComponent";
import DreamTextfield from "../../../ui_components/DreamTextfield/DreamTextfieldComponent";
import DreamAutocomplete from "../../../ui_components/DreamAutocomplete/DreamAutocomplete";
import DreamButton from "../../../ui_components/DreamButton/DreamButton";
import * as CreateInvoiceHelper from "./CreateInvoiceHelper";
import * as CreateInvoiceReducer from "./CreateInvoiceReducer";
import * as InvoicesHelper from "../Invoices/InvoicesHelper";
import * as getProfileData from "../../../helpers/Functions/GetProfileData/GetProfileData";
import * as DreamAutocompleteHelper from "../../../ui_components/DreamAutocomplete/DreamAutocompleteHelper";
import * as AppRoutes from "../../../AppComponent/AppRoutes";

const useStyles = makeStyles({
  paper: {
    maxWidth: 400,
    width: "100%",
    borderRadius: 15,
  },
  form: {
    padding: "20px 30px 30px 30px",
    display: "flex",
    flexDirection: "column",
  },
  textField: {
    marginTop: 8,
    marginBottom: 4,
  },
  location: {
    marginTop: 12,
  },
});

function CreateInvoice() {
  const [state, dispatch] = useImmerReducer(
    CreateInvoiceReducer.reducer,
    CreateInvoiceReducer.originalState
  );

  const [t] = useTranslation(Constants.TRANSLATION_COMMON);
  const appContext = useContext(StateContext);
  const appDispatch = useContext(DispatchContext);
  const history = useHistory();
  const userId = appContext.appState.user_id;
  const classes = useStyles();

  function typeChanged(value) {
    dispatch({
      type: "typeChanged",
      value: parseInt(value),
    });
  }

  function countryChangeHandler(value) {
    dispatch({
      type: "countryChanged",
      value,
    });
  }

  function cityChangeHandler(value) {
    dispatch({
      type: "cityChanged",
      value,
    });
  }

  function addressChangeHandler(value) {
    dispatch({
      type: "addressChanged",
      value,
    });
  }

  function zipChangeHandler(value) {
    dispatch({
      type: "zipChanged",
      value,
    });
  }

  function responsibleChangeHandler(value) {
    dispatch({
      type: "responsibleChanged",
      value,
    });
  }

  function idnChangeHandler(value) {
    dispatch({
      type: "idnChanged",
      value,
    });
  }

  function uicChangeHandler(value) {
    dispatch({
      type: "uicChanged",
      value,
    });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch({ type: "setSubmitCounter" });
  };

  useEffect(() => {
    const ourRequest = Axios.CancelToken.source();

    InvoicesHelper.getInvoiceDetails(Axios, ourRequest, appDispatch, dispatch);
    return () => {
      ourRequest.cancel();
    };
  }, []);

  useEffect(() => {
    if (state.invoiceDetailsLoaded && !state.hasInvoiceDetails) {
      const ourRequest = Axios.CancelToken.source();

      getProfileData.getProfileData(
        userId,
        Axios,
        ourRequest,
        appDispatch,
        dispatch,
        t
      );
      return () => {
        ourRequest.cancel();
      };
    }
  }, [state.invoiceDetailsLoaded, state.hasInvoiceDetails]);

  useEffect(() => {
    if (state.submitCounter) {
      const ourRequest = Axios.CancelToken.source();

      CreateInvoiceHelper.save(state, Axios, ourRequest, appDispatch, dispatch);

      return () => {
        ourRequest.cancel();
      };
    }
  }, [state.submitCounter]);

  useEffect(() => {
    if (state.formSuccessfullySubmitted) {
      history.push("/invoices");
    }
  }, [state.formSuccessfullySubmitted]);

  console.log("CREATE INVOICE", state);
  return (
    <DreamPage title="invoices.invoiceDetails">
      <Paper elevation={6} className={classes.paper}>
        <form className={classes.form} noValidate autoComplete="off">
          <DreamRadioButtons
            name={"createInvoice.type"}
            value={state.formData.type}
            data={CreateInvoiceHelper.typeRadioButtons}
            changeHandler={typeChanged}
          />
          {state.formData.type === 1 && (
            <Aux>
              {!state.formData.firstName ? (
                <Box
                  component="span"
                  style={{ marginTop: 16, marginBottom: 8 }}
                >
                  <Link
                    component={RouterLink}
                    to={`${AppRoutes.ROUTE_USER_PROFILE}`}
                    variant="body2"
                    underline="none"
                    style={{ color: "red" }}
                  >
                    {t("invoices.noFirstName")}
                  </Link>
                </Box>
              ) : (
                <DreamTextfield
                  label={t("createInvoice.firstName")}
                  className={classes.textField}
                  value={state.formData.firstName}
                  placeholder="Placeholder FN"
                  InputProps={{
                    readOnly: true,
                  }}
                />
              )}
              {!state.formData.lastName ? (
                <Box component="span" style={{ marginTop: 8 }}>
                  <Link
                    component={RouterLink}
                    to={`${AppRoutes.ROUTE_USER_PROFILE}`}
                    variant="body2"
                    underline="none"
                    style={{ color: "red" }}
                  >
                    {t("invoices.noLastName")}
                  </Link>
                </Box>
              ) : (
                <DreamTextfield
                  label={t("createInvoice.lastName")}
                  className={classes.textField}
                  value={state.formData.lastName}
                  placeholder="Placeholder LN"
                  InputProps={{
                    readOnly: true,
                  }}
                />
              )}
            </Aux>
          )}
          {state.formData.type === 2 && (
            <Aux>
              {!state.formData.company ? (
                <Box component="span" style={{ marginTop: 16 }}>
                  <Link
                    component={RouterLink}
                    to={`${AppRoutes.ROUTE_COMPANY_PROFILE}`}
                    variant="body2"
                    underline="none"
                    style={{ color: "red" }}
                  >
                    {t("invoices.noCompanyName")}
                  </Link>
                </Box>
              ) : (
                <DreamTextfield
                  label={t("createInvoice.company")}
                  className={classes.textField}
                  value={state.formData.company}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              )}
            </Aux>
          )}
          <DreamAutocomplete
            classes={{ root: classes.location }}
            fullWidth
            label={t("createInvoice.country")}
            usage={"location"}
            searchType={DreamAutocompleteHelper.SEARCH_TYPE_COUNTRY}
            apiUrl={`${Constants.BASE_URL}location`}
            searchParamName={"search_string"}
            additionalApiParams={{
              type: DreamAutocompleteHelper.SEARCH_TYPE_COUNTRY,
            }}
            changeHandler={countryChangeHandler}
            preselectedValue={state.formData.country}
          />
          <DreamAutocomplete
            classes={{ root: classes.location }}
            fullWidth
            label={t("createInvoice.city")}
            usage={"location"}
            searchType={DreamAutocompleteHelper.SEARCH_TYPE_CITY}
            apiUrl={`${Constants.BASE_URL}location`}
            searchParamName={"search_string"}
            additionalApiParams={{
              type: DreamAutocompleteHelper.SEARCH_TYPE_CITY,
            }}
            changeHandler={cityChangeHandler}
            preselectedValue={state.formData.city}
          />
          <DreamTextfield
            label={t("createInvoice.address")}
            className={classes.textField}
            value={state.formData.address}
            dispatchChangeHandler={addressChangeHandler}
          />
          <DreamTextfield
            label={t("createInvoice.zip")}
            className={classes.textField}
            value={state.formData.zip}
            dispatchChangeHandler={zipChangeHandler}
          />
          {state.formData.type === 2 ? (
            <Aux>
              <DreamTextfield
                label={t("createInvoice.official")}
                className={classes.textField}
                value={state.formData.responsible}
                dispatchChangeHandler={responsibleChangeHandler}
              />
              <DreamTextfield
                label={t("createInvoice.bulstat")}
                className={classes.textField}
                value={state.formData.idn}
                dispatchChangeHandler={idnChangeHandler}
              />
              <DreamTextfield
                label={t("createInvoice.uic")}
                className={classes.textField}
                value={state.formData.uic}
                dispatchChangeHandler={uicChangeHandler}
              />
            </Aux>
          ) : (
            ""
          )}
        </form>
      </Paper>
      <DreamButton
        handleClick={handleSubmit}
        buttonLabel="basic.save"
        kind="contained"
        color="primary"
        dreamButtonStyle={{ minWidth: 130, marginTop: 20 }}
      />
    </DreamPage>
  );
}

export default withRouter(CreateInvoice);
