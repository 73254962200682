import * as DreamJobOfferHelper from "../../Pages/CreateJobOfferPage/CreateJobOfferHelper";

export const workScheduleRadioButtons = [
  {
    label: "jo.anyWorkSchedule",
    value: DreamJobOfferHelper.SEARCH_TYPE_ANY,
  },
  {
    label: "jo.dayShift",
    value: DreamJobOfferHelper.SEARCH_TYPE_WORKSCHEDULE_DAY_SHIFT,
  },
  {
    label: "jo.nightShift",
    value: DreamJobOfferHelper.SEARCH_TYPE_WORKSCHEDULE_NIGHT_SHIFT,
  },
];
