import React, { useContext } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppContext from "../../Context/StateContext";
import AppDispatch from "../../Context/DispatchContext";
import { useImmerReducer } from "use-immer";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";
import { useTranslation } from "react-i18next";
import * as Constants from "../../helpers/Constants";
import { Link } from "react-router-dom";
import HeaderComponent from "../HeaderComponent/HeaderComponent";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import clsx from "clsx";
import * as Colors from "../../styles/01.settings/01-settings-colors.module.scss";
import AppBarLogo from "../DreamAppBarLogoComponent/DreamAppBarLogo";
import Grid from "@material-ui/core/Grid";

const drawerWidth = 240;

export default function MixedDrawerLeft(props) {
  const appContext = useContext(AppContext);
  const appDispatch = useContext(AppDispatch);
  const [t] = useTranslation(Constants.TRANSLATION_COMMON);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const matchesMDup = useMediaQuery(theme.breakpoints.up("md"));

  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      flexDirection: !matches ? "column" : "",
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
      backgroundColor: Colors.primaryLighten,
      color: Colors.white,
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.default,
      minHeight: "100vh",
      display: "flex",
      flexDirection: "column",
    },
    // Temp
    list: {
      width: drawerWidth,
    },
    fullList: {
      width: "auto",
    },
    divider: {
      // Theme Color, or use css color in quote
      background: Colors.white,
    },
    drawerList: {
      paddingTop: 50,
    },
    listItemIcon: {
      // Theme Color, or use css color in quote
      // minWidth: "min-content",
      // paddingRight: 10,
    },
  }));

  const classes = useStyles();

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    appDispatch({
      type: "setDrawerOpen",
      value: open,
    });
    // setState({ ...state, [anchor]: open });
  };

  const listItems = (
    <List className={classes.drawerList}>
      {props.drawerItems.map((item, index) => (
        <React.Fragment key={index}>
          {item.dividerTop ? (
            <Divider classes={{ root: classes.divider }} />
          ) : (
            ""
          )}
          <ListItem
            button
            component={Link}
            to={item.link}
            onClick={item.handleClick}
            className={item.className}
          >
            <ListItemIcon classes={{ root: classes.listItemIcon }}>
              {item.icon}
            </ListItemIcon>
            <ListItemText primary={t(item.text)} />
          </ListItem>
          {item.dividerBottom ? (
            <Divider classes={{ root: classes.divider }} />
          ) : (
            ""
          )}
        </React.Fragment>
      ))}
    </List>
  );

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      {listItems}
    </div>
  );

  return (
    <Grid className={classes.root} style={props.mixedDrawerRootStyle}>
      <CssBaseline />
      <HeaderComponent
        toggleDrawer={toggleDrawer("left", true)}
        anchor={"left"}
      />
      {appContext.appState.loggedIn ? (
        <Drawer
          className={classes.drawer}
          anchor={"left"}
          // Temp
          open={appContext.appState.toggleDrawer["left"]}
          onClose={toggleDrawer("left", false)}
          // End Temp
          variant={matchesMDup ? "permanent" : "temporary"}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div
            className={classes.toolbar}
            style={{ backgroundColor: Colors.primary }}
          >
            <AppBarLogo
              burgerHidden
              toggleDrawer={props.toggleDrawer}
              anchor={props.anchor}
            />
          </div>
          <Divider />

          {matches ? (
            <React.Fragment>{listItems}</React.Fragment>
          ) : (
            list("left")
          )}
        </Drawer>
      ) : (
        ""
      )}
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {props.children}
      </main>
    </Grid>
  );
}
