import React, { useEffect, useContext, useRef } from "react";
import { useImmerReducer } from "use-immer";
import uuid from "react-uuid";
import DreamRadioButtonsComponent from "../../../ui_components/DreamRadioButtons/DreamRadioButtonsComponent";
import * as LocationsHelper from "../LocationsHelperJo";
import * as DreamAutocompleteHelper from "../../../ui_components/DreamAutocomplete/DreamAutocompleteHelper";
import JobOfferDispatchContext from "../../../Pages/CreateJobOfferPage/Context/JobOfferDispatchContext";
import JobOfferStateContext from "../../../Pages/CreateJobOfferPage/Context/JobOfferStateContext";
import * as LocationsSingleItemReducer from "./LocationsSingleItemReducerJo";
import DreamAutocomplete from "../../../ui_components/DreamAutocomplete/DreamAutocomplete";
import * as Constants from "../../../helpers/Constants";
import DreamOnClickTooltip from "../../../ui_components/DreamOnClickTooltip/DreamOnClickTooltip";

import * as BaseHelper from "../../../helpers/BaseHelper";

function LocationsSingleItemComponentJo(props) {
  const rel = useRef();
  const [state, dispatch] = useImmerReducer(
    LocationsSingleItemReducer.reducer,
    LocationsSingleItemReducer.originalState
  );

  const jobOfferDispatchContext = useContext(JobOfferDispatchContext);
  const jobOfferStateContext = useContext(JobOfferStateContext);

  function locationTypeChanged(value) {
    dispatch({
      type: "setLocationType",
      value,
    });
    jobOfferDispatchContext({
      type: "locationsLocationTypeChanged",
      value: {
        locationID: state.id,
        value: value,
      },
    });
    jobOfferDispatchContext({
      type: "locationsLocationPlaceChanged",
      value: {
        locationID: state.id,
        value: {},
      },
    });
    dispatch({
      type: "setLocation",
      value: {},
    });
  }

  function locationPlaceChanged(value) {
    jobOfferDispatchContext({
      type: "locationsLocationPlaceChanged",
      value: {
        locationID: state.id,
        value: value,
      },
    });
    dispatch({
      type: "setLocation",
      value,
    });
  }

  useEffect(() => {
    let myUuid;
    if (props.locationData && props.locationData.id != null) {
      myUuid = props.locationData.id;
      dispatch({
        type: "setLocationType",
        value: props.locationData.value.location_type,
      });
      dispatch({
        type: "setLocation",
        value: {
          type: props.locationData.value.location_type,
          id: props.locationData.value.id,
          name: props.locationData.value.name,
        },
      });
    } else {
      myUuid = uuid();
      jobOfferDispatchContext({
        type: "locationsLocationAdded",
        value: myUuid,
      });
    }
    rel.current = myUuid;

    dispatch({
      type: "setId",
      value: myUuid,
    });

    return () => {
      // on Unmount let's dispatch event
      jobOfferDispatchContext({
        type: "locationsLocationRemoved",
        value: rel.current,
      });
    };
  }, []);

  const autocompleteComponent = (
    <div style={{ display: "flex", flexWrap: "noWrap", width: "100%" }}>
      <DreamAutocomplete
        fullWidth
        label={"cv.location"}
        usage={"location"}
        searchType={state.locationType}
        apiUrl={`${Constants.BASE_URL}location`}
        searchParamName={"search_string"}
        additionalApiParams={{ type: state.locationType }}
        changeHandler={locationPlaceChanged}
        isMandatory={true}
        preselectedValue={state.location}
      />
      {jobOfferStateContext.tooltipVisible ? (
        <DreamOnClickTooltip tooltipContent={BaseHelper.locationTooltipBG} />
      ) : (
        ""
      )}
    </div>
  );

  return (
    <React.Fragment>
      <DreamRadioButtonsComponent
        name={"jp.locations"}
        value={state.locationType}
        data={LocationsHelper.locationsRadioButtons}
        changeHandler={locationTypeChanged}
      />
      {/* 
        The easiest way to reset Autocomplete value is
        just to unmount and mount it when the location type is changed
       */}
      {state.locationType == DreamAutocompleteHelper.SEARCH_TYPE_CITY
        ? autocompleteComponent
        : ""}
      {state.locationType == DreamAutocompleteHelper.SEARCH_TYPE_STATE
        ? autocompleteComponent
        : ""}
      {state.locationType == DreamAutocompleteHelper.SEARCH_TYPE_COUNTRY
        ? autocompleteComponent
        : ""}
    </React.Fragment>
  );
}

export default LocationsSingleItemComponentJo;
