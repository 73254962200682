import React, { useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { useImmerReducer } from "use-immer";
import { useTranslation } from "react-i18next";
import * as Constants from "../../helpers/Constants";
import * as WalletReducer from "./WalletPageReducer";
import * as WalletHelper from "./WalletPageHelper";
import DreamPage from "../../ui_components/DreamPage";
import DreamButton from "../../ui_components/DreamButton/DreamButton";
import Axios from "axios";
import DispatchContext from "../../Context/DispatchContext";
import { Link as RouterLink } from "react-router-dom";
import TablePagination from "@material-ui/core/TablePagination";
import DreamSimpleTabs from "../../ui_components/DreamTabPanel/DreamTabPanel";
import DreamCardMedia from "../../ui_components/DreamCardMediaComponent/DreamCardMedia";
import EventOutlinedIcon from "@material-ui/icons/EventOutlined";
import MoneyIcon from "@material-ui/icons/Money";
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";
import CreditCardOutlinedIcon from "@material-ui/icons/CreditCardOutlined";
import FontDownloadOutlinedIcon from "@material-ui/icons/FontDownloadOutlined";
import MergeTypeOutlinedIcon from "@material-ui/icons/MergeTypeOutlined";
import PermIdentityOutlinedIcon from "@material-ui/icons/PermIdentityOutlined";

const useStyles = makeStyles({
  balanceWrapper: {
    maxWidth: 770,
    display: "flex",
    flexGrow: 1,
    flexFlow: "row nowrap",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: 40,
  },
  balance: {
    flexFlow: "row nowrap",
  },
  balanceLabel: {
    fontSize: 20,
    fontWeight: 500,
  },
  balanceValue: {
    fontSize: 20,
    fontWeight: 500,
  },
  tabsWrapper: {
    maxWidth: "55em",
  },
  title: {
    fontSize: 24,
    fontWeight: "bold",
  },
  tableCell: {
    textAlign: "center",
    padding: 8,
    borderBottom: "none",
  },
  tblContainer: {},
  tblRow: {},
});

function Balance(props) {
  const [t] = useTranslation(Constants.TRANSLATION_COMMON);
  const classes = useStyles();
  return (
    <Grid container item xs={12} className={classes.balanceWrapper}>
      <Grid item className={classes.balance}>
        <label className={classes.balanceLabel}>{t("wallet.balance")}</label>
        <label className={classes.balanceValue}>{props.balance}</label>
      </Grid>

      <Grid item>
        <DreamButton
          buttonDisabled={props.buttonDisabled}
          btnComponent={RouterLink}
          color="primary"
          kind="contained"
          buttonLabel={t("wallet.buy")}
          to={"/buy-credits"}
        ></DreamButton>
      </Grid>
    </Grid>
  );
}

const Wallet = () => {
  const classes = useStyles();
  const [t] = useTranslation(Constants.TRANSLATION_COMMON);
  const appDispatch = useContext(DispatchContext);
  const pageTitle = t("drawer.wallet");
  const [state, dispatch] = useImmerReducer(
    WalletReducer.reducer,
    WalletReducer.originalState
  );

  const handleChangeDebitPage = (event, newPage) => {
    dispatch({ type: "setDebitPage", value: newPage });
  };

  const handleChangeCreditPage = (event, newPage) => {
    dispatch({ type: "setCreditPage", value: newPage });
  };

  const handleChangeDebitRowsPerPage = (event) => {
    dispatch({
      type: "setDebitRowsPerPage",
      value: parseInt(event.target.value, 10),
    });
    dispatch({ type: "setDebitPage", value: 0 });
  };

  const handleChangeCreditRowsPerPage = (event) => {
    dispatch({
      type: "setCreditRowsPerPage",
      value: parseInt(event.target.value, 10),
    });
    dispatch({ type: "setCreditPage", value: 0 });
  };

  // Balance
  useEffect(() => {
    const ourRequest = Axios.CancelToken.source();
    WalletHelper.getBalance(Axios, ourRequest, appDispatch, dispatch);
    return () => {
      ourRequest.cancel();
    };
  }, []);

  // DEBIT
  // Empty rows debit
  const emptyRowsDebit =
    state.paginationDebit.currentPageSize -
    Math.min(
      parseInt(state.paginationDebit.currentPageSize, 10),
      parseInt(state.paginationDebit.totalCount, 10).length -
        state.paginationDebit.currentPage *
          parseInt(state.paginationDebit.currentPageSize, 10)
    );

  // On page_size or page change (Debit)
  useEffect(() => {
    const ourRequest = Axios.CancelToken.source();

    WalletHelper.getDebitTransactions(
      state.paginationDebit.currentPageSize,
      state.paginationDebit.currentPage + 1,
      Axios,
      ourRequest,
      appDispatch,
      dispatch
    );

    return () => {
      ourRequest.cancel();
    };
  }, [
    state.paginationDebit.currentPageSize,
    state.paginationDebit.currentPage,
  ]);

  // END DEBIT

  // CREDIT
  // Empty rows credit
  const emptyRowsCredit =
    state.paginationCredit.currentPageSize -
    Math.min(
      parseInt(state.paginationCredit.currentPageSize, 10),
      parseInt(state.paginationCredit.totalCount, 10).length -
        state.paginationCredit.currentPage *
          parseInt(state.paginationCredit.currentPageSize, 10)
    );

  // On page_size or page change (Credit)
  useEffect(() => {
    const ourRequest = Axios.CancelToken.source();

    WalletHelper.getCreditTransactions(
      state.paginationCredit.currentPageSize,
      state.paginationCredit.currentPage + 1,
      Axios,
      ourRequest,
      appDispatch,
      dispatch
    );

    return () => {
      ourRequest.cancel();
    };
  }, [
    state.paginationCredit.currentPageSize,
    state.paginationCredit.currentPage,
  ]);
  // END CREDIT

  const handleDownload = (transaction) => {
    dispatch({ type: "setDownloadTransaction", value: transaction });
    dispatch({ type: "setDownloadCounter" });
  };

  useEffect(() => {
    if (state.downloadCounter > 0) {
      const ourRequest = Axios.CancelToken.source();
      const downloadTransaction = state.downloadTransaction;
      WalletHelper.downloadInvoice(
        downloadTransaction,
        Axios,
        ourRequest,
        appDispatch,
        dispatch
      );

      return () => {
        ourRequest.cancel();
      };
    }
  }, [state.downloadCounter]);

  const debitTable = (
    <Grid item xs={12}>
      <TableContainer className={classes.tblContainer} component={Paper}>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          aria-label="enhanced table"
        >
          <TableBody>
            {state.debitTransactions.map((transaction, index) => (
              <TableRow key={index}>
                <TableCell className={classes.tableCell}>
                  <DreamCardMedia
                    invNumber={transaction.inv_number}
                    handleDownload={() => handleDownload(transaction)}
                    cardContentDebitStyle={{ padding: 12 }}
                    cardContentDebitObject={[
                      {
                        dateIcon: <EventOutlinedIcon />,
                        dateLabel: t("wallet.date"),
                        date: transaction.createdOn,
                      },
                      {
                        amountIcon: <MoneyIcon />,
                        amountLabel: t("wallet.amount"),
                        amount: transaction.currency
                          ? `${transaction.amount / 100} ${
                              transaction.currency
                            }`
                          : t("wallet.referBonus"),
                      },
                      {
                        creditsIcon: <AddShoppingCartIcon />,
                        creditsLabel: t("wallet.creditsPurchased"),
                        credits: transaction.credits,
                      },
                    ]}
                  />
                </TableCell>
              </TableRow>
            ))}
            {emptyRowsDebit > 0 && (
              <TableRow>
                <TableCell className={classes.tableCell} colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        backIconButtonProps={{
          disabled:
            state.paginationDebit.isLoadingDebitData ||
            state.paginationDebit.currentPage == 0,
        }}
        nextIconButtonProps={{
          disabled:
            state.paginationDebit.isLoadingDebitData ||
            state.paginationDebit.currentPage ==
              state.paginationDebit.totalPageCount - 1,
        }}
        labelRowsPerPage={t("tablePagination.rowsPerPage")}
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to} ${t("tablePagination.of")} ${
            count !== -1 ? count : `${t("tablePagination.moreThan")} ${to}`
          }`
        }
        rowsPerPageOptions={WalletHelper.rowsPerPageDebitOptions}
        component="div"
        count={parseInt(state.paginationDebit.totalCount, 10)}
        rowsPerPage={state.paginationDebit.currentPageSize}
        page={parseInt(state.paginationDebit.currentPage, 10)}
        onChangePage={handleChangeDebitPage}
        onChangeRowsPerPage={handleChangeDebitRowsPerPage}
      />{" "}
    </Grid>
  );

  const creditTable = (
    <Grid item xs={12}>
      <TableContainer className={classes.tblContainer} component={Paper}>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          aria-label="enhanced table"
        >
          <TableBody>
            {state.creditTransactions.map((transaction, index) => (
              <TableRow key={index}>
                <TableCell className={classes.tableCell}>
                  <DreamCardMedia
                    cardContentCreditStyle={{ padding: 12 }}
                    cardContentCreditObject={[
                      {
                        typeIcon: <MergeTypeOutlinedIcon />,
                        typeLabel: t("wallet.type"),
                        type:
                          transaction.type === "Unlock"
                            ? t("wallet.unlock")
                            : t("wallet.promote"),
                      },
                      {
                        nameIcon: <FontDownloadOutlinedIcon />,
                        nameLabel: t("wallet.name"),
                        name: transaction.name,
                      },
                      {
                        positionIcon: <PermIdentityOutlinedIcon />,
                        positionLabel: t("wallet.position"),
                        position: transaction.position,
                      },
                      {
                        dateIcon: <EventOutlinedIcon />,
                        dateLabel: t("wallet.date"),
                        date: transaction.createdOn,
                      },
                      {
                        creditsIcon: <CreditCardOutlinedIcon />,
                        creditsLabel: t("wallet.creditsSpent"),
                        credits: transaction.amount,
                      },
                    ]}
                  />
                </TableCell>
              </TableRow>
            ))}
            {emptyRowsCredit > 0 && (
              <TableRow>
                <TableCell className={classes.tableCell} colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        backIconButtonProps={{
          disabled:
            state.paginationCredit.isLoadingCreditData ||
            state.paginationCredit.currentPage == 0,
        }}
        nextIconButtonProps={{
          disabled:
            state.paginationCredit.isLoadingCreditData ||
            state.paginationCredit.currentPage ==
              state.paginationCredit.totalPageCount - 1,
        }}
        labelRowsPerPage={t("tablePagination.rowsPerPage")}
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to} ${t("tablePagination.of")} ${
            count !== -1 ? count : `${t("tablePagination.moreThan")} ${to}`
          }`
        }
        rowsPerPageOptions={WalletHelper.rowsPerPageCreditOptions}
        component="div"
        count={parseInt(state.paginationCredit.totalCount, 10)}
        rowsPerPage={state.paginationCredit.currentPageSize}
        page={parseInt(state.paginationCredit.currentPage, 10)}
        onChangePage={handleChangeCreditPage}
        onChangeRowsPerPage={handleChangeCreditRowsPerPage}
      />{" "}
    </Grid>
  );

  const tabPanelItems = [
    { label: t("wallet.creditsPurchased"), content: debitTable },
    { label: t("wallet.creditsSpent"), content: creditTable },
  ];

  return (
    <DreamPage title={t(pageTitle)}>
      <Balance
        balance={state.balance}
        buttonDisabled={
          state.paginationDebit.isLoadingDebitData ||
          state.paginationCredit.isLoadingCreditData
        }
      />
      <DreamSimpleTabs tabPanelItems={tabPanelItems} />
    </DreamPage>
  );
};

export default Wallet;
