import React, { useContext } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as Constants from "../../helpers/Constants";
import StateContext from "../../Context/StateContext";
import Colors from "../../styles/01.settings/01-settings-colors.module.scss";
import { fade } from "@material-ui/core/styles/colorManipulator";

const useStyles = makeStyles((theme) => ({
  menuItem: {
    "&:hover": {
      backgroundColor: fade(Colors.primary, 0.1),
    },
  },
  link: {
    color: Colors.primary,
  },
}));

const DreamSettingsMenuListItems = (props) => {
  const { handleClose, menuItems } = props;
  const appContext = useContext(StateContext);
  const [t] = useTranslation(Constants.TRANSLATION_COMMON);

  const classes = useStyles();

  return menuItems.map((menuItem, index) => (
    <MenuItem
      className={classes.menuItem}
      onClick={menuItem.handleClick ? menuItem.handleClick : handleClose}
      key={index}
    >
      {menuItem.child ? (
        menuItem.child
      ) : (
        <Link className={classes.link} to={menuItem.link ? menuItem.link : "#"}>
          {t(menuItem.translation)}
        </Link>
      )}
    </MenuItem>
  ));
};

export default DreamSettingsMenuListItems;
