import * as CreateJobOfferHelper from "../../Pages/CreateJobOfferPage/CreateJobOfferHelper";

export const workplaceRadioButtons = [
  {
    label: "jo.anyWorkplace",
    value: CreateJobOfferHelper.SEARCH_TYPE_ANY,
  },
  {
    label: "jo.stationary",
    value: CreateJobOfferHelper.SEARCH_TYPE_STATIONARY_WORKPLACE,
  },
  {
    label: "jo.remote",
    value: CreateJobOfferHelper.SEARCH_TYPE_REMOTE_WORKPLACE,
  },
];
