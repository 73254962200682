import * as AdditionalBenefitsHelper from "../../containers_components/AdditionalBenefitsComponentJo/AdditionalBenefitsHelperJo";
import * as CreateJobOfferHelper from "../CreateJobOfferPage/CreateJobOfferHelper";

export const originalState = {
  formData: {
    offerName: "",
    locations: {
      any: { id: "1", label: "jp.anyLocation", isChecked: true },
      locations: [],
    },
    position: {
      industryId: "",
      positionId: "",
      positionName: "",
      industryChangedCounter: 0,
    },
    workplaceType: CreateJobOfferHelper.SEARCH_TYPE_ANY,
    employmentType: CreateJobOfferHelper.SEARCH_TYPE_ANY,
    workScheduleType: CreateJobOfferHelper.SEARCH_TYPE_ANY,
    preferredStart: CreateJobOfferHelper.SEARCH_TYPE_PREFERRED_START_ANY,
    benefits: AdditionalBenefitsHelper.additionalBenefits,
    salaryRangeType: CreateJobOfferHelper.SEARCH_TYPE_ANY,
    selectedCurrency: "",
    monthlySalaryRange: [
      CreateJobOfferHelper.INITIAL_MONTHLY_MIN_SALARY,
      CreateJobOfferHelper.INITIAL_MONTHLY_MAX_SALARY,
    ],
    hourlySalaryRange: [
      CreateJobOfferHelper.INITIAL_HOURLY_MIN_SALARY,
      CreateJobOfferHelper.INITIAL_HOURLY_MAX_SALARY,
    ],
  },
  expandedAccordion: "panel2",
  disabledSaveButton: false,
  initialDataLoaded: false,
  formSuccessfullySubmittedCount: 0,
  isDataFormatted: false,
};

export function reducer(draft, action) {
  let foundKey;

  switch (action.type) {
    /**
     *
     * OfferName related events
     *
     */
    case "changeOfferName":
      draft.formData.offerName = action.value;
      return;

    /**
     *
     * Locations related events
     *
     */
    case "locationsLocationAdded":
      draft.formData.locations.locations.push({
        id: action.value,
      });
      return;

    case "locationsLocationRemoved":
      foundKey = draft.formData.locations.locations.findIndex(
        (x) => x.id == action.value
      );
      if (foundKey >= 0) {
        draft.formData.locations.locations.splice(foundKey, 1);
      }
      return;

    case "locationsAnyChanged":
      draft.formData.locations.any.isChecked = action.value;
      if (action.value) {
        draft.formData.locations.locations = [];
      }
      return;

    case "locationsLocationPlaceChanged":
      foundKey = draft.formData.locations.locations.findIndex(
        (x) => x.id == action.value.locationID
      );
      // Change the Location Place
      if (foundKey >= 0) {
        draft.formData.locations.locations[foundKey].value = action.value.value;
      }
      return;

    /**
     *
     * Position related events
     *
     */
    case "positionIndustryChanged":
      draft.formData.position.industryId = action.value;
      return;
    case "setIndustryChangedCounter":
      draft.formData.position.industryChangedCounter++;
      return;
    case "positionPositionChanged":
      draft.formData.position.positionId = action.value.id;
      draft.formData.position.positionName = action.value.name;
      return;

    /**
     *
     * Workplace type related events
     *
     */
    case "workplaceTypeChanged":
      draft.formData.workplaceType = action.value;
      return;

    /**
     *
     * Employment type related events
     *
     */
    case "employmentTypeChanged":
      draft.formData.employmentType = action.value;
      return;

    /**
     *
     * WorkSchedule type related events
     *
     */
    case "workScheduleTypeChanged":
      draft.formData.workScheduleType = action.value;
      return;

    /**
     *
     * WorkSchedule type related events
     *
     */
    case "preferredStartTypeChanged":
      draft.formData.preferredStart = action.value;
      return;

    /**
     *
     * Additional Benefits  related events
     *
     */
    case "benefitsChanged":
      foundKey = draft.formData.benefits.findIndex(
        (x) => x.id == action.value.id
      );

      // Change the Benefit checked / not checked
      if (foundKey >= 0) {
        draft.formData.benefits[foundKey].isChecked = action.value.isChecked;
      }

      return;

    /**
     *
     * Salary range related events
     *
     */
    case "currencyChanged":
      draft.formData.selectedCurrency = action.value;
      return;

    case "salaryRangeTypeChanged":
      draft.formData.salaryRangeType = action.value;
      return;

    case "salaryRangeChanged":
      !draft.formData.salaryRangeType
        ? (draft.formData.monthlySalaryRange = action.value)
        : (draft.formData.hourlySalaryRange = action.value);
      return;

    case "salaryRangeMinChanged":
      !draft.formData.salaryRangeType
        ? (draft.formData.monthlySalaryRange[0] = action.value)
        : (draft.formData.hourlySalaryRange[0] = action.value);
      return;

    case "salaryRangeMaxChanged":
      !draft.formData.salaryRangeType
        ? (draft.formData.monthlySalaryRange[1] = action.value)
        : (draft.formData.hourlySalaryRange[1] = action.value);
      return;

    case "formSuccessfullySubmitted":
      draft.formSuccessfullySubmittedCount++;
      draft.initialDataLoaded = false;
      return;

    case "setLocationsData":
      draft.formData.locations.locations = action.value;
      return;

    // Data loaded
    case "offerDataLoaded":
      draft.initialDataLoaded = true;
      const offerData = action.value;

      draft.formData.offerName = offerData.name;

      if (offerData.locations.length < 1) {
        draft.formData.locations.any.isChecked = true;
      } else {
        draft.formData.locations.any.isChecked = false;
        let formattedBeLocations = [];

        offerData.locations.forEach((location, index) => {
          let formattedLocation = {
            id: index,
            value: {
              id: location.location_id,
              name: location.location_name,
              location_type: location.type.toString(),
            },
          };

          formattedBeLocations.push(formattedLocation);
        });

        draft.formData.locations.locations = formattedBeLocations;
      }

      draft.formData.position.industryId = offerData.industry;

      draft.formData.position.positionId = offerData.position;
      draft.formData.position.positionName = offerData.position_name;

      draft.formData.workplaceType = offerData.wp_type;

      draft.formData.employmentType = offerData.e_type;

      draft.formData.workScheduleType = offerData.work_schedule;

      draft.formData.preferredStart = offerData.start;

      draft.formData.workplaceType = offerData.wp_type;

      // Salary
      if (offerData.salary_from !== null) {
        draft.formData.salaryRangeType = 0;
        draft.formData.monthlySalaryRange[0] = offerData.salary_from;
        draft.formData.monthlySalaryRange[1] = offerData.salary_to;
      } else {
        draft.formData.salaryRangeType = 1;
        draft.formData.hourlySalaryRange[0] = offerData.salary_hourly_from;
        draft.formData.hourlySalaryRange[1] = offerData.salary_hourly_to;
      }

      draft.formData.selectedCurrency = offerData.salary_currency;

      // benefits

      const benefitsIds = CreateJobOfferHelper.benefitsIds;

      for (let i = 0; i < benefitsIds.length; i++) {
        if (
          draft.formData.benefits[i].id == benefitsIds[i] &&
          offerData[benefitsIds[i]] == 1
        )
          draft.formData.benefits[i].isChecked = true;
      }
      return;
    case "getExpandedPanel":
      draft.expandedAccordion = action.value;
      return;
    case "setTooltipVisible":
      draft.tooltipVisible = action.value;
      return;
  }
}
