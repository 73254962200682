import React from "react";
import * as Constants from "../../helpers/Constants";
import FontDownloadOutlinedIcon from "@material-ui/icons/FontDownloadOutlined";
import BusinessCenterOutlinedIcon from "@material-ui/icons/BusinessCenterOutlined";
import PermIdentityOutlinedIcon from "@material-ui/icons/PermIdentityOutlined";
import LocalAtmOutlinedIcon from "@material-ui/icons/LocalAtmOutlined";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import BuildOutlinedIcon from "@material-ui/icons/BuildOutlined";

export const rowsPerPageOptions = [5, 10, 20];

export async function getJobOffers(
  currentPageSize,
  currentPage,
  Axios,
  ourRequest,
  appDispatch,
  dispatch
) {
  appDispatch({ type: "showBackdrop", value: true });

  dispatch({ type: "setIsLoading", value: true });

  let errMsgs;
  let authenticated = true;

  const params = {
    page_size: currentPageSize,
    page: currentPage,
  };

  try {
    const response = await Axios.get(`/dreamjob/jo`, {
      cancelToken: ourRequest.token,
      params: params,
    }).catch(function (error) {
      // Errors to be sent to snanckbar
      errMsgs = "basic.serverError";
      if (
        error.response &&
        error.response.status &&
        error.response.status === Constants.ERROR_RESPONSE_STATUS
      ) {
        authenticated = false;
        errMsgs = "basic.unauthenticated";
      }
      dispatch({ type: "setIsLoading", value: false });
      appDispatch({ type: "showBackdrop", value: false });

      throw errMsgs;
    });

    const responseData = response.data;

    handleSuccessfulGetData(responseData, appDispatch, dispatch);
  } catch (error) {
    dispatch({ type: "setIsLoading", value: false });
    appDispatch({ type: "showBackdrop", value: true });

    appDispatch({
      type: "showMsg",
      value: { status: Constants.SNACKBAR_ERROR, msg: error },
    });
    if (!authenticated) {
      appDispatch({
        type: "logout",
      });
      appDispatch({ type: "openLoginModal", value: true });
    } else {
      dispatch({ type: "disableSubmitButton", value: false });
    }
  }
}

export function handleSuccessfulGetData(data, appDispatch, dispatch) {
  dispatch({ type: "getJobOffers", value: data.data.items });
  dispatch({ type: "setPaginationCursor", value: data.data.cursor });
  dispatch({
    type: "setPaginationHasMoreData",
    value: data.data.has_more_data,
  });
  dispatch({ type: "setPaginationTotalCount", value: data.data.total_count });
  dispatch({
    type: "setPaginationTotalPageCount",
    value: data.data.total_page_count,
  });
  dispatch({ type: "setIsLoading", value: false });
  appDispatch({ type: "showBackdrop", value: false });
}

// DELETE jo
export function handleDelete(dataId, Axios, appDispatch, dispatch) {
  dispatch({ type: "setModalOpen", value: false });
  dispatch({ type: "confirmDelete" });
  let deleteJo = deleteRequest(dataId, Axios);

  if (!deleteJo) {
    appDispatch({
      type: "showMsg",
      value: {
        status: Constants.SNACKBAR_ERROR,
        msg: "jos.notDeletedJo",
      },
    });
  }
}

export async function deleteRequest(joId, Axios) {
  let success = true;
  try {
    const response = await Axios.delete(
      `${Constants.BASE_URL}dreamjob/jo/${joId}`
    ).catch(function (error) {
      // Errors to be sent to scanckbar
      let errMsgs = "Server error";
      if (error.response) {
        // Request made and server responded
        if (error.response.data.message) {
          errMsgs = JSON.parse(error.response.data.message);
        }
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
      throw errMsgs;
    });
  } catch (error) {
    console.log("Error delete Jo:", error);
    success = false;
  }

  return success;
}

export const headCells = [
  {
    id: "offerName",
    numeric: false,
    disablePadding: false,
    label: "jos.name",
    icon: <FontDownloadOutlinedIcon />,
  },
  {
    id: "industry",
    numeric: false,
    disablePadding: false,
    label: "jos.industry",
    icon: <BusinessCenterOutlinedIcon />,
  },
  {
    id: "position",
    numeric: false,
    disablePadding: false,
    label: "jos.position",
    icon: <PermIdentityOutlinedIcon />,
  },
  {
    id: "salary",
    numeric: false,
    disablePadding: false,
    label: "jos.salary",
    icon: <LocalAtmOutlinedIcon />,
  },
];

export const locationCell = {
  id: "location",
  numeric: false,
  disablePadding: false,
  label: "jos.location",
  icon: <LocationOnOutlinedIcon />,
};

export const actionsCell = {
  id: "actions",
  numeric: false,
  disablePadding: false,
  label: "jos.actions",
  icon: <BuildOutlinedIcon />,
};

export function formatSalaryData(offer) {
  let currencySign = "";
  switch (offer.salary_currency) {
    case "EUR":
      currencySign = "€";
      break;
    case "USD":
      currencySign = "$";
      break;
    case "BGN":
      currencySign = "BGN";
      break;
  }
  let monthlyHourly = `${offer.salary_type === "Monthly" ? "Mth" : "Hr"}`;
  let formattedData = `${currencySign} ${offer.salary_from} - ${offer.salary_to} ${monthlyHourly}`;
  return formattedData;
}

// Checks if user has filled out Company Profile data

export async function checkCreate(
  token,
  Axios,
  ourRequest,
  appDispatch,
  dispatch
) {
  let errMsgs;
  let authenticated = true;
  try {
    const response = await Axios.get(`/dreamjob/jo/check_create`, {
      cancelToken: ourRequest.token,
    }).catch(function (error) {
      // Errors to be sent to snackbar
      errMsgs = "basic.serverError";
      if (
        error.response &&
        error.response.status &&
        error.response.status === Constants.ERROR_RESPONSE_STATUS
      ) {
        authenticated = false;
        errMsgs = "basic.unauthenticated";
      }
      throw errMsgs;
    });

    let errsArr = [];

    const responseData = response.data.data;

    if (!responseData.success && responseData.messages.length) {
      responseData.messages.forEach((msg) => {
        errsArr.push(`serverErrors.${msg.code}`);
      });
      dispatch({ type: "setModalOpen", value: false });
      throw errsArr;
    }

    handleSuccessfulGetCheckCreate(responseData, dispatch);
  } catch (error) {
    appDispatch({
      type: "showMsg",
      value: { status: Constants.SNACKBAR_ERROR, msg: error },
    });
    if (!authenticated) {
      appDispatch({
        type: "logout",
      });
      appDispatch({ type: "openLoginModal", value: true });
    } else {
      dispatch({ type: "disableSubmitButton", value: false });
    }
  }
}

function handleSuccessfulGetCheckCreate(data, dispatch) {
  dispatch({ type: "setCheckCreate", value: data.success });
}
