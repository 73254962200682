import _ from "lodash";
import * as CreateInvoiceHelper from "./CreateInvoiceHelper";

export const originalState = {
  formData: {
    type: CreateInvoiceHelper.NATURAL_PERSON,
    firstName: "",
    lastName: "",
    company: "",
    country: "",
    city: "",
    address: "",
    zip: "",
    responsible: "",
    idn: "",
    uic: "",
  },
  invoiceDetailsLoaded: false,
  hasInvoiceDetails: false,
  submitCounter: 0,
  saveButtonDisabled: false,
  formSuccessfullySubmitted: false,
};

export const reducer = (draft, action) => {
  switch (action.type) {
    case "typeChanged":
      draft.formData.type = action.value;
      break;
    case "countryChanged":
      draft.formData.country = action.value;
      break;
    case "cityChanged":
      draft.formData.city = action.value;
      break;
    case "addressChanged":
      draft.formData.address = action.value;
      break;
    case "zipChanged":
      draft.formData.zip = action.value;
      break;
    case "responsibleChanged":
      draft.formData.responsible = action.value;
      break;
    case "idnChanged":
      draft.formData.idn = action.value;
      break;
    case "uicChanged":
      draft.formData.uic = action.value;
      break;
    case "setSubmitCounter":
      draft.submitCounter++;
      break;
    case "setHasInvoiceDetails":
      draft.hasInvoiceDetails = action.value;
      break;
    case "setDisableSaveButton":
      draft.saveButtonDisabled = action.value;
      break;
    case "setFormSuccessfullySubmitted":
      draft.formSuccessfullySubmitted = action.value;
      break;
    case "invoiceDetailsLoaded":
      draft.invoiceDetailsLoaded = true;
      if (!_.isEmpty(action.value)) {
        draft.hasInvoiceDetails = true;
        draft.formData.type = action.value.type;
        draft.formData.firstName = action.value.first_name;
        draft.formData.lastName = action.value.last_name;
        draft.formData.company = action.value.company_name;
        draft.formData.country = action.value.country;
        draft.formData.city = action.value.city;
        draft.formData.address =
          action.value.address === null ? "" : action.value.address;
        draft.formData.zip = action.value.zip === null ? "" : action.value.zip;
        draft.formData.responsible =
          action.value.responsible === null ? "" : action.value.responsible;
        draft.formData.idn = action.value.idn === null ? "" : action.value.idn;
        draft.formData.uic = action.value.uic === null ? "" : action.value.uic;
      }
      break;
    case "profileDataLoaded":
      draft.formData.firstName = action.value.fname;
      draft.formData.lastName = action.value.lname;
      draft.formData.company = action.value.oname;
      break;
  }
};
