import * as Constants from "../../helpers/Constants";

export async function handleSubmit(
  e,
  fpHash,
  state,
  Axios,
  appDispatch,
  dispatch,
  t
) {
  appDispatch({ type: "showBackdrop", value: true });

  let errMsgs;

  let params = {
    fphash: fpHash,
    password: state.fields.password.value,
    password2: state.fields.passwordConfirm.value,
  };

  try {
    const response = await Axios.post(`/user/chpwd`, params, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
      },
    }).catch(function (error) {
      // Errors to be sent to snackbar
      errMsgs = "basic.serverError";
      // if (
      //   error.response &&
      //   error.response.status &&
      //   error.response.status === Constants.ERROR_RESPONSE_STATUS
      // ) {
      //   authenticated = false;
      //   errMsgs = "basic.unauthenticated";
      // }
      throw errMsgs;
    });

    const responseData = response.data.data;

    let errsArr = [];
    if (!responseData.success && responseData.messages.length) {
      responseData.messages.forEach((msg) => {
        errsArr.push(`serverErrors.${msg.code}`);
      });
      dispatch({ type: "setServerResponsedCounter" });
      throw errsArr;
    }

    handleSuccessfullyChanged(responseData, appDispatch, dispatch, t);
  } catch (error) {
    appDispatch({ type: "showBackdrop", value: false });
    appDispatch({
      type: "showMsg",
      value: { status: Constants.SNACKBAR_ERROR, msg: error },
    });
  }
}

export function handleSuccessfullyChanged(data, appDispatch, dispatch, t) {
  dispatch({ type: "setServerResponsedCounter" });
  appDispatch({ type: "showBackdrop", value: false });
  appDispatch({
    type: "showMsg",
    value: {
      status: Constants.SNACKBAR_SUCCESS,
      msg: t("forgottenPassword.passwordReset"),
    },
  });
}
