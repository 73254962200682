import React, { useEffect, useContext, useRef } from "react";
import { useImmerReducer } from "use-immer";
import uuid from "react-uuid";
import * as Constants from "../../../helpers/Constants";
import DreamSelectComponent from "../../../ui_components/DreamSelect/DreamSelectComponent";
import Grid from "@material-ui/core/Grid";
import MyCvDispatchContext from "../../../Pages/MyCvPage/Context/MyCvDispatchContext";
import DreamCustomCheckbox from "../../../ui_components/DreamCustomCheckboxComponent/DreamCustomCheckboxComponent";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { useTranslation } from "react-i18next";
import * as EducationSingleItemReducer from "./EducationSingleItemReducer";
import DreamTextfieldComponent from "../../../ui_components/DreamTextfield/DreamTextfieldComponent";
import DreamDatepickerComponent from "../../../ui_components/DreamDatepicker/DreamDatepickerComponent";
import {
  EducationRoot,
  MajorTextField,
} from "./EducationSingleItemComponent.module.scss";
import * as DreamSelectHelper from "../../../ui_components/DreamSelect/DreamSelectHelper";
import * as EducationSingleItemHelper from "./EducationSingleItemHelper";
import JobPreferenceDispatchContext from "../../../Pages/CreateJobPreferencePage/Context/JobPreferenceDispatchContext";
import { isNull } from "lodash";

function EducationSingleItemComponent(props) {
  const myCvDispatch = useContext(MyCvDispatchContext);
  const [t] = useTranslation(Constants.TRANSLATION_COMMON);

  const jobPreferenceDispatchContext = useContext(JobPreferenceDispatchContext);

  const dispatchContext =
    props.dispatchContextName === "jobPreferenceDispatchContext"
      ? jobPreferenceDispatchContext //eval(props.dispatchContextName)
      : myCvDispatch;

  const rel = useRef();

  const [state, dispatch] = useImmerReducer(
    EducationSingleItemReducer.reducer,
    EducationSingleItemReducer.originalState
  );

  function levelChanged(value) {
    dispatchContext({
      type: "educationLevelChanged",
      value: {
        educationID: state.id,
        educationLevel: value,
      },
    });

    dispatch({
      type: "setLevel",
      value,
    });

    if (parseInt(value) === 1) {
      dispatchContext({
        type: "educationBroadTypeChanged",
        value: {
          educationID: state.id,
          broadEducationType: "",
        },
      });

      dispatch({
        type: "setBroadType",
        value: "",
      });

      dispatchContext({
        type: "educationDetailedTypeChanged",
        value: {
          educationID: state.id,
          detailedEducationType: "",
        },
      });
      dispatch({
        type: "setDetailedType",
        value: "",
      });

      dispatchContext({
        type: "educationMajorChanged",
        value: {
          educationID: state.id,
          major: "",
        },
      });
      dispatch({
        type: "setMajor",
        value: "",
      });
      dispatchContext({
        type: "educationFromChanged",
        value: {
          educationID: state.id,
          from: null,
        },
      });
      dispatch({
        type: "setFrom",
        value: null,
      });
      dispatchContext({
        type: "educationToChanged",
        value: {
          educationID: state.id,
          to: null,
        },
      });
      dispatch({
        type: "setTo",
        value: null,
      });
      dispatchContext({
        type: "educationChangePresentEducation",
        value: {
          educationID: state.id,
          presentEducation: false,
        },
      });
      dispatch({
        type: "changePresentEducation",
        value: false,
      });
    }
  }

  function broadEducationChanged(value) {
    dispatchContext({
      type: "educationBroadTypeChanged",
      value: {
        educationID: state.id,
        broadEducationType: value,
      },
    });
    dispatchContext({
      type: "educationDetailedTypeChanged",
      value: {
        educationID: state.id,
        detailedEducationType: "",
      },
    });
    dispatch({
      type: "setBroadType",
      value,
    });
  }

  function detailedEducationChanged(value) {
    dispatchContext({
      type: "educationDetailedTypeChanged",
      value: {
        educationID: state.id,
        detailedEducationType: value,
      },
    });
    dispatch({
      type: "setDetailedType",
      value,
    });
  }

  function majorChanged(value) {
    dispatchContext({
      type: "educationMajorChanged",
      value: {
        educationID: state.id,
        major: value,
      },
    });
    dispatch({
      type: "setMajor",
      value,
    });
  }

  function fromChanged(value) {
    dispatchContext({
      type: "educationFromChanged",
      value: {
        educationID: state.id,
        from: value,
      },
    });
    dispatch({
      type: "setFrom",
      value,
    });
  }

  function toChanged(value) {
    dispatchContext({
      type: "educationToChanged",
      value: {
        educationID: state.id,
        to: value,
      },
    });
    dispatch({
      type: "setTo",
      value,
    });
  }

  function educationManualyChanged(value) {
    dispatchContext({
      type: "cvEducationManualyChanged",
    });
  }

  useEffect(() => {
    let myUuid;
    if (props.educationData && props.educationData.id != null) {
      // In this case, education is loaded from the DB,
      // so we don't need to generate ID and trigger an event for creation
      myUuid = props.educationData.id;
      dispatch({
        type: "setMajor",
        value: props.educationData.major,
      });
      dispatch({
        type: "setLevel",
        value: props.educationData.educationLevel,
      });
      dispatch({
        type: "setBroadType",
        value: props.educationData.broadEducation,
      });
      dispatch({
        type: "setDetailedType",
        value: props.educationData.detailedEducation,
      });
      dispatch({
        type: "setFrom",
        value: props.educationData.from,
      });
      dispatch({
        type: "setTo",
        value: props.educationData.to,
      });
      dispatch({
        type: "changePresentEducation",
        value: props.educationData.presentEducation,
      });
    } else {
      myUuid = uuid();
      dispatchContext({
        type: "educationAdded",
        value: myUuid,
      });
    }

    dispatch({
      type: "setId",
      value: myUuid,
    });

    rel.current = myUuid;

    return () => {
      // on Unmount let's dispatch event
      dispatchContext({
        type: "educationRemoved",
        value: rel.current,
      });
    };
  }, []);

  return (
    <div
      style={{
        padding: 10,
      }}
    >
      <DreamSelectComponent
        fullWidth
        optionsData={props.optionsData.level}
        label={"cv.level"}
        dispatchChangeHandler={levelChanged}
        isMandatory={true}
        value={state.level}
        manualyChangedHandler={educationManualyChanged}
      />
      {parseInt(state.level) !== 1 ? (
        <>
          <DreamSelectComponent
            fullWidth
            dataType={DreamSelectHelper.DATA_TYPE_BROAD_EDUCATION}
            optionsData={props.optionsData.broadType}
            label={"cv.broadEducation"}
            dispatchChangeHandler={broadEducationChanged}
            isMandatory={true}
            value={state.broadType}
            manualyChangedHandler={educationManualyChanged}
          />
          {state.broadType ? (
            <DreamSelectComponent
              fullWidth
              dataType={DreamSelectHelper.DATA_TYPE_DETAILED_EDUCATION}
              urlAPI={`dreamjob/etype/${state.broadType}`}
              label={"cv.detailedEducation"}
              dispatchChangeHandler={detailedEducationChanged}
              isMandatory={true}
              value={state.detailedType}
              manualyChangedHandler={educationManualyChanged}
            />
          ) : (
            ""
          )}
          <DreamTextfieldComponent
            fullWidth
            className={MajorTextField}
            label={"cv.major"}
            dispatchChangeHandler={majorChanged}
            isMandatory={Boolean(state.level > 4 && state.level < 8)}
            value={state.major}
          />
          <Grid container style={{ justifyContent: "space-between" }}>
            <DreamDatepickerComponent
              label={"basic.from"}
              dispatchChangeHandler={fromChanged}
              disableFuture
              isMandatory={true}
              value={state.from}
            />
            {!state.presentEducation && (
              <DreamDatepickerComponent
                label={"basic.to"}
                dispatchChangeHandler={toChanged}
                disableFuture
                isMandatory={true}
                value={state.to}
              />
            )}
          </Grid>
          <FormGroup row>
            <FormControlLabel
              control={
                <DreamCustomCheckbox
                  checked={state.presentEducation}
                  onChange={(e) =>
                    EducationSingleItemHelper.handleChange(
                      e.target.checked,
                      state.id,
                      dispatch,
                      dispatchContext
                    )
                  }
                  name={"presentEducation"}
                />
              }
              label={t("cv.presentEducation")}
            />
          </FormGroup>{" "}
        </>
      ) : (
        ""
      )}
    </div>
  );
}

export default EducationSingleItemComponent;
