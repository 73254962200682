import uuid from "react-uuid";

export function addRow(singleItem, dispatch) {
  let myUuid = uuid();
  let element = {
    id: myUuid,
    singleItem,
  };
  dispatch({
    type: "addRow",
    value: element,
  });
}

export function removeRow(id, dispatch) {
  dispatch({
    type: "removeRow",
    value: id,
  });
}
