import * as Constants from "../../helpers/Constants";

export async function handleSubmit(
  data,
  dispatch,
  appDispatch,
  Axios,
  clearForm
) {
  let errMsgs;
  let authenticated = true;
  dispatch({ type: "disableSubmitButton", value: true });

  try {
    let formData = new FormData();

    formData.append("subject", data.formData.subject);
    formData.append("body", data.formData.content);
    formData.append("url", data.formData.url);

    const response = await Axios.post(`/feedback`, formData, {
      headers: {
        "content-type": `application/json;`,
      },
    }).catch(function (error) {
      // Errors to be sent to snackbar
      errMsgs = "basic.serverError";
      if (
        error.response &&
        error.response.status &&
        error.response.status === Constants.ERROR_RESPONSE_STATUS
      ) {
        authenticated = false;
        errMsgs = "basic.unauthenticated";
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
      throw errMsgs;
    });

    const responseData = response.data.data;
    let errsArr = [];
    if (!responseData.success && responseData.messages.length) {
      responseData.messages.forEach((msg) => {
        errsArr.push(`serverErrors.${msg.code}`);
      });
      throw errsArr;
    }
    dispatch({ type: "disableSubmitButton", value: false });
    if (typeof clearForm === typeof Function) clearForm();

    dispatch({ type: "setOpen", value: false });
    // dispatch({ type: "formSuccessfullySubmitted" });
    appDispatch({
      type: "showMsg",
      value: { status: Constants.SNACKBAR_SUCCESS, msg: "basic.saved" },
    });
  } catch (error) {
    appDispatch({
      type: "showMsg",
      value: { status: Constants.SNACKBAR_ERROR, msg: error },
    });
    dispatch({ type: "disableSubmitButton", value: false });
    if (typeof clearForm === typeof Function) clearForm();

    dispatch({ type: "setOpen", value: false });

    if (!authenticated) {
      appDispatch({
        type: "logout",
      });

      appDispatch({ type: "openLoginModal", value: true });
    } else {
      dispatch({ type: "disableSubmitButton", value: false });
      if (typeof clearForm === typeof Function) clearForm();
      dispatch({ type: "setOpen", value: false });
    }
  }
}
