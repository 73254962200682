import React from "react";
import * as Constants from "../../helpers/Constants";
import EventOutlinedIcon from "@material-ui/icons/EventOutlined";
import MoneyOutlinedIcon from "@material-ui/icons/MoneyOutlined";
import AttachMoneyOutlinedIcon from "@material-ui/icons/AttachMoneyOutlined";
import CreditCardOutlinedIcon from "@material-ui/icons/CreditCardOutlined";
import FontDownloadOutlinedIcon from "@material-ui/icons/FontDownloadOutlined";
import MergeTypeOutlinedIcon from "@material-ui/icons/MergeTypeOutlined";
import PermIdentityOutlinedIcon from "@material-ui/icons/PermIdentityOutlined";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import BuildOutlinedIcon from "@material-ui/icons/BuildOutlined";

export const rowsPerPageDebitOptions = [5, 10, 20];
export const rowsPerPageCreditOptions = [5, 10, 20];

const BASE_INVOICE_NUMBER = 7000000000;

// User Balance
export async function getBalance(Axios, ourRequest, appDispatch, dispatch) {
  appDispatch({ type: "showBackdrop", value: true });

  let errMsgs;
  let authenticated = true;
  try {
    const response = await Axios.get(`/user/balance`, {
      headers: {
        "content-type": `application/json;`,
      },
      cancelToken: ourRequest.token,
    }).catch(function (error) {
      // Errors to be sent to snackbar
      errMsgs = "basic.serverError";
      if (
        error.response &&
        error.response.status &&
        error.response.status === Constants.ERROR_RESPONSE_STATUS
      ) {
        authenticated = false;
        errMsgs = "basic.unauthenticated";
      }
      throw errMsgs;
    });

    const responseData = response.data.data;

    handleSuccessfulGetBalanceData(responseData, appDispatch, dispatch);
  } catch (error) {
    appDispatch({ type: "showBackdrop", value: false });
    appDispatch({
      type: "showMsg",
      value: { status: Constants.SNACKBAR_ERROR, msg: error },
    });
    if (!authenticated) {
      appDispatch({
        type: "logout",
      });
      appDispatch({ type: "openLoginModal", value: true });
    } else {
      dispatch({ type: "disableSubmitButton", value: false });
    }
  }
}

export function handleSuccessfulGetBalanceData(data, appDispatch, dispatch) {
  appDispatch({ type: "showBackdrop", value: false });
  dispatch({
    type: "balanceDataLoaded",
    value: data.success ? data.balance : null,
  });
}

// End Balance

// Debit transactions
export async function getDebitTransactions(
  currentPageSize,
  currentPage,
  Axios,
  ourRequest,
  appDispatch,
  dispatch
) {
  dispatch({ type: "setIsLoadingDebitData", value: true });
  let errMsgs;
  let authenticated = true;

  const params = {
    page_size: currentPageSize,
    page: currentPage,
  };

  try {
    const response = await Axios.get(`/itransactions/debit`, {
      cancelToken: ourRequest.token,
      params: params,
    }).catch(function (error) {
      // Errors to be sent to snanckbar
      errMsgs = "basic.serverError";
      if (
        error.response &&
        error.response.status &&
        error.response.status === Constants.ERROR_RESPONSE_STATUS
      ) {
        authenticated = false;
        errMsgs = "basic.unauthenticated";
      }
      dispatch({ type: "setIsLoadingDebitData", value: false });
      throw errMsgs;
    });

    const responseData = response.data;

    handleSuccessfulGetDebitTransactionsData(responseData, dispatch);
  } catch (error) {
    appDispatch({
      type: "showMsg",
      value: { status: Constants.SNACKBAR_ERROR, msg: error },
    });
    if (!authenticated) {
      appDispatch({
        type: "logout",
      });
      appDispatch({ type: "openLoginModal", value: true });
    } else {
      dispatch({ type: "disableSubmitButton", value: false });
    }
  }
}

export function handleSuccessfulGetDebitTransactionsData(data, dispatch) {
  dispatch({ type: "debitTransactionsDataLoaded", value: data.data.items });
  dispatch({
    type: "setDebitPaginationTotalCount",
    value: data.data.total_count,
  });
  dispatch({
    type: "setDebitPaginationTotalPageCount",
    value: data.data.total_page_count,
  });
  dispatch({ type: "setIsLoadingDebitData", value: false });
}

// Credit transactions
export async function getCreditTransactions(
  currentPageSize,
  currentPage,
  Axios,
  ourRequest,
  appDispatch,
  dispatch
) {
  dispatch({ type: "setIsLoadingCreditData", value: true });
  let errMsgs;
  let authenticated = true;

  const params = {
    page_size: currentPageSize,
    page: currentPage,
  };

  try {
    const response = await Axios.get(`/itransactions/credit`, {
      cancelToken: ourRequest.token,
      params: params,
    }).catch(function (error) {
      // Errors to be sent to snanckbar
      errMsgs = "basic.serverError";
      if (
        error.response &&
        error.response.status &&
        error.response.status === Constants.ERROR_RESPONSE_STATUS
      ) {
        authenticated = false;
        errMsgs = "basic.unauthenticated";
      }
      dispatch({ type: "setIsLoadingCreditData", value: false });

      throw errMsgs;
    });

    const responseData = response.data;

    handleSuccessfulGetCreditTransactionsData(responseData, dispatch);
  } catch (error) {
    appDispatch({
      type: "showMsg",
      value: { status: Constants.SNACKBAR_ERROR, msg: error },
    });
    if (!authenticated) {
      appDispatch({
        type: "logout",
      });
      appDispatch({ type: "openLoginModal", value: true });
    } else {
      dispatch({ type: "disableSubmitButton", value: false });
    }
  }
}

export function handleSuccessfulGetCreditTransactionsData(data, dispatch) {
  dispatch({ type: "creditTransactionsDataLoaded", value: data.data.items });
  dispatch({
    type: "setCreditPaginationTotalCount",
    value: data.data.total_count,
  });
  dispatch({
    type: "setCreditPaginationTotalPageCount",
    value: data.data.total_page_count,
  });
  dispatch({ type: "setIsLoadingCreditData", value: false });
}

// Download
export async function downloadInvoice(
  transaction,
  Axios,
  ourRequest,
  appDispatch,
  dispatch
) {
  appDispatch({ type: "showBackdrop", value: true });

  let errMsgs;
  let authenticated = true;
  try {
    const response = await Axios.get(
      `/transactions/invoice/${transaction.id}`,
      {
        headers: {
          "Content-Type": `application/pdf`,
        },
        responseType: "blob",
        cancelToken: ourRequest.token,
      }
    ).catch(function (error) {
      // Errors to be sent to snackbar
      errMsgs = "basic.serverError";
      if (
        error.response &&
        error.response.status &&
        error.response.status === Constants.ERROR_RESPONSE_STATUS
      ) {
        authenticated = false;
        errMsgs = "basic.unauthenticated";
      }
      throw errMsgs;
    });

    const blob = response.data;

    if (blob.type === "application/json") {
      const blobData = JSON.parse(await blob.text());
      if (blobData.data && !blobData.success) {
        errMsgs = `serverErrors.${blobData.data.messages?.code}`;
        throw errMsgs;
      }
    }

    handleSuccessfulDownload(transaction, blob, appDispatch);
  } catch (error) {
    appDispatch({ type: "showBackdrop", value: false });
    appDispatch({
      type: "showMsg",
      value: { status: Constants.SNACKBAR_ERROR, msg: error },
    });
    if (!authenticated) {
      appDispatch({
        type: "logout",
      });
      appDispatch({ type: "openLoginModal", value: true });
    } else {
      dispatch({ type: "disableSubmitButton", value: false });
    }
  }
}

export function handleSuccessfulDownload(transaction, blob, appDispatch) {
  appDispatch({ type: "showBackdrop", value: false });
  const url = window.URL.createObjectURL(new Blob([blob]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute(
    "download",
    `invoice_${transaction.createdOn}_${transaction.inv_number}.pdf`
  );
  // Append to html link element page
  document.body.appendChild(link);

  // Start download
  link.click();

  // Clean up and remove the link
  link.parentNode.removeChild(link);
}

export const headDebitCells = [
  {
    id: "datePurchased",
    numeric: false,
    disablePadding: false,
    label: "wallet.datePurchased",
    icon: <EventOutlinedIcon />,
  },
  {
    id: "amount",
    numeric: true,
    disablePadding: false,
    label: "wallet.amount",
    icon: <MoneyOutlinedIcon />,
  },
  {
    id: "currency",
    numeric: false,
    disablePadding: false,
    label: "wallet.currency",
    icon: <AttachMoneyOutlinedIcon />,
  },
  {
    id: "creditsPurchased",
    numeric: true,
    disablePadding: false,
    label: "wallet.creditsPurchased",
    icon: <CreditCardOutlinedIcon />,
  },
];

export const headCreditCells = [
  {
    id: "type",
    numeric: false,
    disablePadding: false,
    label: "wallet.type",
    icon: <MergeTypeOutlinedIcon />,
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "wallet.name",
    icon: <FontDownloadOutlinedIcon />,
  },
  {
    id: "position",
    numeric: false,
    disablePadding: false,
    label: "wallet.position",
    icon: <PermIdentityOutlinedIcon />,
  },
  {
    id: "actionDate",
    numeric: false,
    disablePadding: false,
    label: "wallet.actionDate",
    icon: <EventOutlinedIcon />,
  },
  {
    id: "creditsSpent",
    numeric: true,
    disablePadding: false,
    label: "wallet.creditsSpent",
    icon: <CreditCardOutlinedIcon />,
  },
];

export const locationCell = {
  id: "location",
  numeric: false,
  disablePadding: false,
  label: "jos.location",
  icon: <LocationOnOutlinedIcon />,
};

export const actionsCell = {
  id: "actions",
  numeric: false,
  disablePadding: false,
  label: "jos.actions",
  icon: <BuildOutlinedIcon />,
};
