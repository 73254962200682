export const originalState = {
  isDataFormatted: false,
  formattedData: [],
};

export function reducer(draft, action) {
  switch (action.type) {
    case "setFormattedData":
      draft.formattedData = action.value;
      draft.isDataFormatted = true;
      return;
  }
}
