export const FULL_TIME = 1;
export const PART_TIME = 2;
export const ANY_TIME = 0;

export const employmentTypeRadioButtons = [
  {
    label: "workPlace.any",
    value: ANY_TIME,
  },
  {
    label: "employment.fullTime",
    value: FULL_TIME,
  },
  {
    label: "employment.partTime",
    value: PART_TIME,
  },
];
