import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import * as Constants from "../../helpers/Constants";

const useStyles = makeStyles({
  root: {
    marginBottom: 10,
  },
  cardContent: {
    padding: "6px !important",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 6,
    fontSize: "0.875rem",
  },
});

export default function DreamSimpleCard(props) {
  const classes = useStyles();
  const bull = <span className={classes.bullet}>•</span>;
  const [t] = useTranslation(Constants.TRANSLATION_COMMON);

  return (
    <Card className={classes.root}>
      <CardContent className={classes.cardContent}>
        <Typography
          className={classes.title}
          color="textSecondary"
          gutterBottom
        ></Typography>
        {props.company ? (
          <Typography className={classes.pos} color="textPrimary">
            {<strong>{t("cv.company")}</strong>}
            {`: ${props.company}`}
          </Typography>
        ) : (
          ""
        )}
        {props.industry ? (
          <Typography className={classes.pos} color="textPrimary">
            {<strong>{t("profile.industry")}</strong>}
            {`: ${props.industry}`}
          </Typography>
        ) : (
          ""
        )}
        {props.position ? (
          <Typography className={classes.pos} color="textPrimary">
            {<strong>{t("cv.position")}</strong>}
            {`: ${props.position}`}
          </Typography>
        ) : (
          ""
        )}
        {props.educationLevel ? (
          <Typography className={classes.pos} color="textPrimary">
            {<strong>{`${t("cv.level")}`}</strong>}
            {`: ${props.educationLevel}`}
          </Typography>
        ) : (
          ""
        )}
        {props.broadEducation ? (
          <Typography className={classes.pos} color="textPrimary">
            {<strong>{t("cv.broadEducation")}</strong>}
            {`: ${props.broadEducation}`}
          </Typography>
        ) : (
          ""
        )}
        {props.detailedEducation ? (
          <Typography className={classes.pos} color="textPrimary">
            {<strong>{t("cv.detailedEducation")}</strong>}
            {`: ${props.detailedEducation}`}
          </Typography>
        ) : (
          ""
        )}
        {props.major ? (
          <Typography className={classes.pos} color="textPrimary">
            {<strong>{t("cv.major")}</strong>}
            {`: ${props.major}`}
          </Typography>
        ) : (
          ""
        )}
        {props.language ? (
          <Typography className={classes.pos} color="textPrimary">
            {<strong>{t("cv.language")}</strong>}
            {`: ${props.language}`}
          </Typography>
        ) : (
          ""
        )}
        {props.level ? (
          <Typography className={classes.pos} color="textPrimary">
            {<strong>{t("cv.level")}</strong>}
            {`: ${props.level}`}
          </Typography>
        ) : (
          ""
        )}
        {props.from ? (
          <Typography className={classes.pos} color="textPrimary">
            {<strong>{t("basic.from")}</strong>}
            {`: ${props.from}`}
            <br />
            {<strong>{t("basic.to")}</strong>}
            {`: ${props.to}`}
          </Typography>
        ) : (
          ""
        )}
      </CardContent>
    </Card>
  );
}
