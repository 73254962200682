import * as DreamCheckboxGroupHelper from "./DreamCheckboxGroupedHelper";

export const originalState = {
  checkboxes: [],
};

export function reducer(draft, action) {
  switch (action.type) {
    case "gotCheckboxesData":
      draft.checkboxes = DreamCheckboxGroupHelper.formatCheckboxes(
        action.value
      );

      return;

    case "changeChecked":
      let id = action.value.id;
      let isChecked = action.value.isChecked;

      for (const group in draft.checkboxes) {
        let foundKey = draft.checkboxes[group].findIndex((x) => x.id == id);
        if (foundKey >= 0) {
          draft.checkboxes[group][foundKey].isChecked = isChecked;
        }
      }

      return;
  }
}
