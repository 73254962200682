import React, { useContext } from "react";
import DreamRadioButtonsComponent from "../../ui_components/DreamRadioButtons/DreamRadioButtonsComponent";
import JobOfferStateContext from "../../Pages/CreateJobOfferPage/Context/JobOfferStateContext";
import JobOfferDispatchContext from "../../Pages/CreateJobOfferPage/Context/JobOfferDispatchContext";
import * as PreferredStartHelper from "./PreferredStartHelperJo";

function PreferredStartComponentJo() {
  const jobOfferDispatchContext = useContext(JobOfferDispatchContext);
  const jobOfferStateContext = useContext(JobOfferStateContext);

  function preferredStartTypeChanged(value) {
    jobOfferDispatchContext({
      type: "preferredStartTypeChanged",
      value: value,
    });
  }

  return (
    <React.Fragment>
      <DreamRadioButtonsComponent
        name={"jp.prefferedStart"}
        value={jobOfferStateContext.formData.preferredStart}
        data={PreferredStartHelper.preferredStartRadioButtons}
        changeHandler={preferredStartTypeChanged}
      />
    </React.Fragment>
  );
}

export default PreferredStartComponentJo;
