import * as CreateJobOfferHelper from "../../Pages/CreateJobOfferPage/CreateJobOfferHelper";

export const employmentRadioButtons = [
  {
    label: "jo.anyEmployment",
    value: CreateJobOfferHelper.SEARCH_TYPE_ANY,
  },
  {
    label: "jo.fullTime",
    value: CreateJobOfferHelper.SEARCH_TYPE_EMPLOYMENT_FULL_TIME,
  },
  {
    label: "jo.partTime",
    value: CreateJobOfferHelper.SEARCH_TYPE_EMPLOYMENT_PART_TIME,
  },
];
