import FormValidator from "../../helpers/FormValidator";

export const allInputs = {
  email: {
    value: "",
    isValid: true,
    errorMessage: "",
  },
  password: {
    value: "",
    isValid: false,
    errorMessage: "",
  },
  passwordConfirm: {
    value: "",
    isValid: false,
    errorMessage: "",
  },
  // phone: {
  //   value: "",
  //   errorMessage: "",
  // },
};

export const originalState = {
  fields: { ...allInputs },
  agreed_tc: false,
  opt_in: true,
  hasValidationErrs: true,
  formSubmittedCount: 0,
};

export function reducer(draft, action) {
  switch (action.type) {
    case "emailChange":
      draft.fields.email.value = action.value.value;
      draft.fields.email.errorMessage = FormValidator.validateField(
        action.value.value,
        { required: true, email: true },
        action.value.t
      );
      draft.fields.email.isValid = !Boolean(draft.fields.email.errorMessage);

      // Check if Form has validation errors
      draft.hasValidationErrs = FormValidator.hasFormValidationErrors(
        draft,
        allInputs
      );
      return;

    case "passwordChange":
      draft.fields.password.value = action.value.value;
      draft.fields.password.errorMessage = FormValidator.validateField(
        action.value.value,
        { required: true, minLength: 8, maxLength: 50, password: true },
        action.value.t
      );
      draft.fields.password.isValid = !Boolean(
        draft.fields.password.errorMessage
      );

      // Check if Form has validation errors
      draft.hasValidationErrs = FormValidator.hasFormValidationErrors(
        draft,
        allInputs
      );
      return;

    case "passwordConfirmChange":
      draft.fields.passwordConfirm.value = action.value.value;
      draft.fields.passwordConfirm.errorMessage = FormValidator.validateField(
        action.value.value,
        {
          required: true,
          minLength: 8,
          maxLength: 50,
          password: true,
          confirmPassword: true,
        },
        action.value.t,
        draft.fields.password.value
      );
      // draft.fields.passwordConfirm.value != draft.fields.password.value
      //   ? action.value.t("registerForm.differentPasswords")
      //   : "";
      draft.fields.passwordConfirm.isValid = !Boolean(
        draft.fields.passwordConfirm.errorMessage
      );

      // Check if Form has validation errors
      draft.hasValidationErrs = FormValidator.hasFormValidationErrors(
        draft,
        allInputs
      );
      return;

    // case "phoneChange":
    //   draft.fields.phone.value = action.value.value;
    //   draft.fields.phone.errorMessage = FormValidator.validateField(
    //     action.value.value,
    //     { required: true },
    //     action.value.t
    //   );
    //   draft.fields.phone.isValid = !Boolean(draft.fields.phone.errorMessage);

    //   // Check if Form has validation errors
    //   draft.hasValidationErrs = FormValidator.hasFormValidationErrors(
    //     draft,
    //     allInputs
    //   );
    //   return;

    case "resetForm":
      draft.hasValidationErrs = true;
      Object.keys(allInputs).forEach(function (item) {
        draft.fields[item].value = "";
        draft.fields[item].errorMessage = "";
      });
      draft.agreed_tc = false;
      return;

    case "formSubmitted":
      draft.hasValidationErrs = FormValidator.hasFormValidationErrors(
        draft,
        allInputs
      );
      if (!draft.hasValidationErrs) {
        draft.formSubmittedCount++;
      }
      return;
    case "setTermsCheckbox":
      draft.agreed_tc = action.value;
      return;
    case "setMarketingCheckbox":
      draft.opt_in = action.value;
      return;
    case "disableSubmitButton":
      draft.hasValidationErrs = action.value;
      return;
  }
}
