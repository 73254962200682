import * as Constants from "../../Constants";

const TOKEN_EXPIRES_IN = `${Constants.LOCAL_STORAGE_SITE_NAME}TokenExpiresIn`;
const TOKEN_EXPIRES_AT = `${Constants.LOCAL_STORAGE_SITE_NAME}TokenExpiresAt`;

export const getStorageItem = (key, defaultValue = null) => {
  const item = localStorage.getItem(key);
  if (!item) {
    return defaultValue;
  }
  return item;
};

export const checkTokenLifetime = () => {
  const tokenExpiresAt = getStorageItem(TOKEN_EXPIRES_AT);

  if (tokenExpiresAt) {
    const date = new Date();
    const currDate = date.getTime();

    if (currDate > tokenExpiresAt) {
      return false;
    }
  }
  return true;
};

export const updateTokenLifetime = () => {
  const tokenExpiresIn = getStorageItem(TOKEN_EXPIRES_IN);
  if (!tokenExpiresIn) {
    return;
  }

  const date = new Date();
  const currDate = date.getTime();
  const tokenExpiresAt = currDate + parseInt(tokenExpiresIn, 10) * 1000;

  localStorage.setItem(TOKEN_EXPIRES_AT, JSON.stringify(tokenExpiresAt));
};
