import React, { useContext, useEffect } from "react";
import { useImmerReducer } from "use-immer";
import DreamDynamicGridComponent from "../../ui_components/DreamDynamicGrid/DreamDynamicGridComponent";
import LocationsSingleItemComponentJo from "./LocationsSingleItemComponentJo/LocationsSingleItemComponentJo";
import * as LocationsReducer from "./LocationsReducerJo";
import JobOfferStateContext from "../../Pages/CreateJobOfferPage/Context/JobOfferStateContext";
import JobOfferDispatchContext from "../../Pages/CreateJobOfferPage/Context/JobOfferDispatchContext";
import DreamCheckboxComponent from "../../ui_components/DreamCheckbox/DreamCheckboxComponent";
import Grid from "@material-ui/core/Grid";

function LocationsComponentJo() {
  const [state, dispatch] = useImmerReducer(
    LocationsReducer.reducer,
    LocationsReducer.originalState
  );

  const jobOfferStateContext = useContext(JobOfferStateContext);

  const jobOfferDispatchContext = useContext(JobOfferDispatchContext);

  function checkboxChangeHandler(checkbox) {
    jobOfferDispatchContext({
      type: "locationsAnyChanged",
      value: checkbox.isChecked,
    });
  }

  useEffect(() => {
    if (jobOfferStateContext.initialDataLoaded) {
      let formattedData = [];

      jobOfferStateContext.formData.locations.locations.forEach((loc) => {
        formattedData.push({
          id: loc.id,
          singleItem: <LocationsSingleItemComponentJo locationData={loc} />,
        });
      });
      dispatch({
        type: "setFormattedData",
        value: formattedData,
      });
    }
  }, [jobOfferStateContext.initialDataLoaded]);

  return (
    <Grid container>
      <Grid item item xs={12}>
        <DreamCheckboxComponent
          data={[jobOfferStateContext.formData.locations.any]}
          changeHandler={checkboxChangeHandler}
        />
      </Grid>
      <Grid item item xs={12}>
        {!jobOfferStateContext.formData.locations.any.isChecked && (
          <DreamDynamicGridComponent
            singleItem={<LocationsSingleItemComponentJo />}
            maxItems="3"
            initialDataLoaded={state.isDataFormatted}
            initialData={state.formattedData}
          />
        )}
      </Grid>
    </Grid>
  );
}

export default LocationsComponentJo;
