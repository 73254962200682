import React, { Suspense, useEffect } from "react";
import { Switch, Route, withRouter, useHistory } from "react-router-dom";
import "./App.scss";
import { useTranslation } from "react-i18next";
import FooterComponent from "../containers_components/FooterComponent/FooterComponent";
import SignUpComponent from "../containers_components/SignUpComponent/SignUpComponent";
import HomeComponent from "../containers_components/HomeComponent/HomeComponent";
import DreamSnackbar from "../ui_components/DreamSnackbar/DreamSnackbar";
import DreamBackdrop from "../ui_components/DreamBackdrop/DreamBackdrop";
import CookiesDialog from "../containers_components/CookiesDialog/CookiesDialog";
import StateContext from "../Context/StateContext";
import DispatchContext from "../Context/DispatchContext";
import { useImmerReducer } from "use-immer";
import * as Constants from "../helpers/Constants";
import AppReducer from "./AppReducer";
import * as AppHelper from "./AppHelper";
import MixedDrawer from "../containers_components/DreamMixedDrawer/DreamMixedDrawer";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import * as RouteConstants from "./AppRoutes";
import Axios from "axios";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Joyride, { ACTIONS, EVENTS, STATUS } from "react-joyride";
import NotFound from "../Pages/NotFound/NotFound";
import AppendHead from "react-append-head";
import * as Storage from "../helpers/Functions/Storage/Storage";

Axios.defaults.baseURL = Constants.BASE_URL;

function App() {
  const [t, i18n] = useTranslation(Constants.TRANSLATION_COMMON);
  const theme = useTheme();
  const history = useHistory();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));
  const AppReducerClass = new AppReducer();
  const initialState = {
    context: {
      translateService: {
        t,
        i18n,
      },
    },
    appState: AppHelper.appState,
  };
  const [state, dispatch] = useImmerReducer(
    AppReducerClass.reducer,
    initialState
  );
  Axios.defaults.headers.common = {
    Authorization: `Bearer ${state.appState.user_token}`,
  };
  Axios.defaults.params = {};
  Axios.defaults.params["lang"] = state.appState.lang
    .split("-")
    .join("_")
    .toLowerCase();

  Axios.interceptors.response.use((response) => {
    if (response?.status === 200) {
      Storage.updateTokenLifetime();
    }
    return response;
  });

  const checkTokenInterval = setInterval(() => {
    if (!Storage.checkTokenLifetime()) {
      clearInterval(checkTokenInterval);
      dispatch({
        type: "showMsg",
        value: {
          status: Constants.SNACKBAR_ERROR,
          msg: "basic.unauthenticated",
        },
      });
      dispatch({ type: "logout" });
      history.push("/");
    }
  }, 1000 * 60);

  // Store data(token, etc.)  to local storage
  // and redirect to Home page on logout
  useEffect(() => {
    const ourRequest = Axios.CancelToken.source();
    AppHelper.manageLocalStorageData(state, Axios, ourRequest);
  }, [state.appState.loggedIn]);

  useEffect(() => {
    if (state.appState.loggedIn && !state.appState.user_AgreedCookies) {
      dispatch({ type: "setCookiesDialogOpen", value: true });
    }
  }, [state.appState.loggedIn, state.appState.user_AgreedCookies]);

  // Checks if user has filled profile data and cv
  useEffect(() => {
    if (state.appState.loggedIn) {
      const ourRequest = Axios.CancelToken.source();
      AppHelper.checkCreateJP(
        state.appState.user_id,
        Axios,
        ourRequest,
        dispatch
      );
      AppHelper.checkCreateJO(
        state.appState.user_id,
        Axios,
        ourRequest,
        dispatch
      );
      return () => {
        ourRequest.cancel();
      };
    }
  }, [state.appState.loggedIn]);

  // Set the Lang on init
  useEffect(() => {
    i18n.changeLanguage();
  }, []);

  // Change the Lang
  useEffect(() => {
    i18n.changeLanguage(state.appState.lang);
  }, [state.appState.lang]);

  const routerItems = AppHelper.routerItems;
  const routerItemsAlways = AppHelper.routerItemsAlways;
  const routerItemsDislogged = AppHelper.routerItemsDislogged;

  const handleJoyrideCallback = (data) => {
    const { action, index, status, type } = data;

    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      // Update state to advance the tour
      dispatch({
        type: "setTourIndex",
        value: index + (action === ACTIONS.PREV ? -1 : 1),
      });
      if (!matches) dispatch({ type: "setDrawerOpen", value: true });
    } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      dispatch({ type: "stopTour" });
      if (!matches) dispatch({ type: "setDrawerOpen", value: false });
    }
  };

  // Make sure to call `loadStripe` outside of a component’s render to avoid
  // recreating the `Stripe` object on every render.
  const stripePromise = loadStripe(
    "pk_test_51Is6XoBRbZnr1nPhHgQ6WtgbXoEfTUyZoEGvSnGuoR3XCjpaMjrJUPw1EbB4HS1KzJDGKwAVacVCx7YyKeHEs4ws00JHbzXQok",
    {
      apiVersion: "2020-08-27",
    }
  );

  let components = (
    <React.Fragment>
      <Joyride
        steps={state.appState.joyride.steps(t)}
        run={state.appState.joyride.run} // or some other boolean for when you want to start it
        // debug={true}
        callback={handleJoyrideCallback}
        continuous
        showProgress
        showSkipButton
        disableBeacon
        styles={{
          options: {
            width: 300,
            zIndex: 10000,
          },
        }}
      />
      <CookiesDialog />
      <DreamSnackbar />
      <SignUpComponent modal signUpButtonStyle={{ minWidth: 130 }} />
      <Switch>
        {routerItems.map(
          (item, index) =>
            state.appState.loggedIn && (
              <Route key={index} path={item.path}>
                {item.component}
              </Route>
            )
        )}
        {routerItemsAlways.map((item, index) => (
          <Route key={index} path={item.path}>
            {item.component}
          </Route>
        ))}
        {!state.appState.loggedIn &&
          routerItemsDislogged.map((item, index) => (
            <Route key={index} path={item.path}>
              {item.component}
            </Route>
          ))}
        <Route path={RouteConstants.ROUTE_HOME}>
          <HomeComponent />
        </Route>
        {state.appState.loggedIn && (
          <Route>
            <NotFound />
          </Route>
        )}
      </Switch>
      <FooterComponent naviItems={AppHelper.naviItems} />
    </React.Fragment>
  );

  const injectGA = () => {
    if (typeof window == "undefined") {
      return;
    }
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag("js", new Date());

    gtag("config", "G-5HK035S15N");
  };

  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>
        {process.env.REACT_APP_PRODUCTION === "true" && (
          <AppendHead>
            <script
              async
              src="https://www.googletagmanager.com/gtag/js?id=G-5HK035S15N"
            ></script>
            <script type="text/javascript">{injectGA()}</script>
          </AppendHead>
        )}
        <Elements
          options={{ locale: state.appState.lang }}
          stripe={stripePromise}
        >
          {/* <BrowserRouter> */}
          <Suspense fallback="loading">
            <div
              className="App"
              style={{
                top: 0,
                position: "relative",
                minHeight: "100vh",
              }}
            >
              <DreamBackdrop />
              <MixedDrawer
                drawerItems={
                  !state.appState.loggedIn ? [] : AppHelper.drawerItems
                }
              >
                {components}
              </MixedDrawer>
            </div>
          </Suspense>
        </Elements>
      </DispatchContext.Provider>
    </StateContext.Provider>
  );
}

export default withRouter(App);
