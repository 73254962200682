import * as Constants from "../../helpers/Constants";

export const INITIAL_MONTHLY_MIN_SALARY = 1;
export const INITIAL_MONTHLY_MAX_SALARY = 50000;
export const INITIAL_HOURLY_MIN_SALARY = 1;
export const INITIAL_HOURLY_MAX_SALARY = 500;

export const MONTHLY_SALARY_RANGE_STEP = 50;
export const HOURLY_SALARY_RANGE_STEP = 1;

export const SEARCH_TYPE_ANY = 0;

export const SEARCH_TYPE_STATIONARY_WORKPLACE = 1;
export const SEARCH_TYPE_REMOTE_WORKPLACE = 2;

export const SEARCH_TYPE_EMPLOYMENT_FULL_TIME = 1;
export const SEARCH_TYPE_EMPLOYMENT_PART_TIME = 2;

export const SEARCH_TYPE_WORKSCHEDULE_DAY_SHIFT = 1;
export const SEARCH_TYPE_WORKSCHEDULE_NIGHT_SHIFT = 2;

export const SEARCH_TYPE_PREFERRED_START_ANY = 0;
export const SEARCH_TYPE_PREFERRED_START_IMMEDIATE = 1;
export const SEARCH_TYPE_PREFERRED_START_AFTER_THIRTY_DAYS = 2;
export const SEARCH_TYPE_PREFERRED_START_MORE_THAN_THIRTY_DAYS = 3;

export const SEARCH_TYPE_MONTHLY_RENUMENATION = 0;
export const SEARCH_TYPE_HOURLY_RENUMENATION = 1;

export const benefitsIds = [
  "paid_leave",
  "food_vouchers",
  "housing",
  "car",
  "phone",
];

export async function handleSave(data, dispatch, appDispatch, Axios) {
  if (!validateForm(data, appDispatch)) {
    return false;
  }
  let errMsgs;
  let authenticated = true;
  dispatch({ type: "disableSaveButton", value: true });
  appDispatch({ type: "showBackdrop", value: true });

  try {
    let formData = new FormData();

    formData.append("name", data.offerName);

    if (data.locations.locations) {
      let loc = formatLocationsData(data.locations.locations);
      for (let i = 0; i < loc.length; i++) {
        formData.append("locations[" + i + "][type]", loc[i].type);
        formData.append(
          "locations[" + i + "][location_id]",
          loc[i].location_id
        );
      }
    }

    formData.append("industry", data.position.industryId);

    formData.append("position", data.position.positionId);

    formData.append("wp_type", data.workplaceType);
    formData.append("e_type", data.employmentType);

    formData.append("salary_currency", data.selectedCurrency);

    formData.append(
      data.salaryRangeType < 1 ? "salary_from" : "salary_hourly_from",
      data.salaryRangeType < 1
        ? data.monthlySalaryRange[0]
        : data.hourlySalaryRange[0]
    );
    formData.append(
      data.salaryRangeType < 1 ? "salary_to" : "salary_hourly_to",
      data.salaryRangeType < 1
        ? data.monthlySalaryRange[1] > INITIAL_MONTHLY_MAX_SALARY
          ? INITIAL_MONTHLY_MAX_SALARY
          : data.monthlySalaryRange[1]
        : data.hourlySalaryRange[1] > INITIAL_HOURLY_MAX_SALARY
        ? INITIAL_HOURLY_MAX_SALARY
        : data.hourlySalaryRange[1]
    );

    formData.append("work_schedule", data.workScheduleType);

    formData.append("start", data.preferredStart);

    if (data.benefits[0].isChecked) {
      formData.append("paid_leave", 1);
    }
    if (data.benefits[1].isChecked) {
      formData.append("food_vouchers", 1);
    }
    if (data.benefits[2].isChecked) {
      formData.append("housing", 1);
    }
    if (data.benefits[3].isChecked) {
      formData.append("car", 1);
    }
    if (data.benefits[4].isChecked) {
      formData.append("phone", 1);
    }

    const response = await Axios.post(
      `${Constants.BASE_URL}dreamjob/jo`,
      formData,
      {
        headers: {
          "content-type": `application/json;`,
        },
      }
    ).catch(function (error) {
      // Errors to be sent to snackbar
      errMsgs = "basic.serverError";
      if (
        error.response &&
        error.response.status &&
        error.response.status == Constants.ERROR_RESPONSE_STATUS
      ) {
        authenticated = false;
        errMsgs = "basic.unauthenticated";
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
      throw errMsgs;
    });

    const responseData = response.data.data;
    let errsArr = [];
    if (!responseData.success && responseData.messages.length) {
      responseData.messages.forEach((msg) => {
        errsArr.push(`serverErrors.${msg.code}`);
      });
      throw errsArr;
    }
    dispatch({ type: "disableSaveButton", value: false });
    dispatch({ type: "formSuccessfullySubmitted" });
    appDispatch({ type: "showBackdrop", value: false });
    appDispatch({
      type: "showMsg",
      value: { status: Constants.SNACKBAR_SUCCESS, msg: "basic.saved" },
    });
  } catch (error) {
    appDispatch({ type: "showBackdrop", value: false });
    appDispatch({
      type: "showMsg",
      value: { status: Constants.SNACKBAR_ERROR, msg: error },
    });
    dispatch({ type: "disableSaveButton", value: false });

    if (!authenticated) {
      appDispatch({
        type: "logout",
      });

      appDispatch({ type: "openLoginModal", value: true });
    } else {
      dispatch({ type: "disableSaveButton", value: false });
    }
  }
}

function validateForm(data, appDispatch) {
  let errors = [];

  if (!isValidOfferName(data.offerName)) {
    errors.push("jo.invalidOfferName");
  }

  if (!isValidLocations(data.locations)) {
    errors.push("jo.invalidLocations");
  }

  if (!isValidCurrency(data.selectedCurrency)) {
    errors.push("jo.invalidCurrency");
  }

  if (Boolean(errors.length)) {
    appDispatch({
      type: "showMsg",
      value: { status: Constants.SNACKBAR_ERROR, msg: errors },
    });
    return false;
  }

  return true;
}

function isValidOfferName(name) {
  if (name.trim() === "") {
    return false;
  }
  return true;
}

function isValidLocations(locations) {
  if (locations.any.isChecked === false && locations.locations.length === 0) {
    return false;
  }

  // Check if location field is filled -> City: Name of the city or State: name.....
  if (locations.locations.length) {
    for (let i = 0; i < locations.locations.length; i++) {
      if (!locations.locations[i].hasOwnProperty("value")) {
        return false;
      }
    }
  }

  return true;
}

function isValidCurrency(currency) {
  if (currency === "") {
    return false;
  }

  return true;
}

function formatLocationsData(data) {
  let formattedData = [];
  data.forEach((item) => {
    let location = {
      type: item.value.location_type,
      location_id: item.value.id,
    };

    formattedData.push(location);
  });

  return formattedData;
}

export function getOnlyChecked(data) {
  let checked = [];
  data.forEach((element) => {
    if (element.isChecked) {
      checked.push(element.id);
    }
  });

  return checked;
}

export async function getOfferData(
  offerId,
  Axios,
  ourRequest,
  appDispatch,
  dispatch
) {
  appDispatch({ type: "showBackdrop", value: true });

  let errMsgs;
  let authenticated = true;
  try {
    const response = await Axios.get(`/dreamjob/jo/${offerId}`, {
      cancelToken: ourRequest.token,
    }).catch(function (error) {
      // Errors to be sent to snackbar
      errMsgs = "basic.serverError";
      if (
        error.response &&
        error.response.status &&
        error.response.status === Constants.ERROR_RESPONSE_STATUS
      ) {
        authenticated = false;
        errMsgs = "basic.unauthenticated";
      }
      throw errMsgs;
    });

    const responseData = response.data.data;

    handleSuccessfulGetData(responseData, appDispatch, dispatch);
  } catch (error) {
    appDispatch({ type: "showBackdrop", value: false });
    appDispatch({
      type: "showMsg",
      value: { status: Constants.SNACKBAR_ERROR, msg: error },
    });
    if (!authenticated) {
      appDispatch({
        type: "logout",
      });
      appDispatch({ type: "openLoginModal", value: true });
    } else {
      dispatch({ type: "disableSubmitButton", value: false });
    }
  }
}

/**
 * Successful got a data from the API
 * @param {Object} data
 * @param {appDispatch} appDispatch
 * @param {dispatch} dispatch
 */
export function handleSuccessfulGetData(data, appDispatch, dispatch) {
  appDispatch({ type: "showBackdrop", value: false });
  dispatch({ type: "offerDataLoaded", value: data });
}

// UPDATE
export async function handleUpdate(
  offerId,
  data,
  dispatch,
  appDispatch,
  Axios
) {
  if (!validateForm(data, appDispatch)) {
    return false;
  }
  let errMsgs;
  let authenticated = true;
  dispatch({ type: "disableSaveButton", value: true });
  appDispatch({ type: "showBackdrop", value: true });

  try {
    let formData = new FormData();

    formData.append("name", data.offerName);

    if (data.locations.locations) {
      formData.append("locations", data.locations.locations.length);
    }

    formData.append("industry", data.position.industryId);

    formData.append("position", data.position.positionId);

    formData.append("wp_type", data.workplaceType);
    formData.append("e_type", data.employmentType);

    formData.append("salary_currency", data.selectedCurrency);

    formData.append(
      data.salaryRangeType < 1 ? "salary_from" : "salary_hourly_from",
      data.salaryRangeType < 1
        ? data.monthlySalaryRange[0] > INITIAL_MONTHLY_MAX_SALARY
          ? INITIAL_MONTHLY_MAX_SALARY
          : data.monthlySalaryRange[0]
        : data.hourlySalaryRange[0] > INITIAL_HOURLY_MAX_SALARY
        ? INITIAL_HOURLY_MAX_SALARY
        : data.hourlySalaryRange[0]
    );

    formData.append(
      data.salaryRangeType < 1 ? "salary_to" : "salary_hourly_to",
      data.salaryRangeType < 1
        ? data.monthlySalaryRange[1] > INITIAL_MONTHLY_MAX_SALARY
          ? INITIAL_MONTHLY_MAX_SALARY
          : data.monthlySalaryRange[1]
        : data.hourlySalaryRange[1] > INITIAL_HOURLY_MAX_SALARY
        ? INITIAL_HOURLY_MAX_SALARY
        : data.hourlySalaryRange[1]
    );

    formData.append("work_schedule", data.workScheduleType);

    formData.append("start", data.preferredStart);

    if (data.benefits[0].isChecked) {
      formData.append("paid_leave", 1);
    }
    if (data.benefits[1].isChecked) {
      formData.append("food_vouchers", 1);
    }
    if (data.benefits[2].isChecked) {
      formData.append("housing", 1);
    }
    if (data.benefits[3].isChecked) {
      formData.append("car", 1);
    }
    if (data.benefits[4].isChecked) {
      formData.append("phone", 1);
    }

    let object = {};
    formData.forEach(function (value, key) {
      if (key !== "locations") {
        object[key] = value;
      } else {
        object["locations"] = [];

        if (value > 0) {
          data.locations.locations.forEach(function (locvalue) {
            let obj = {
              type: locvalue.value.location_type,
              location_id: locvalue.value.id,
            };
            object["locations"].push(obj);
          });
        }
      }
    });
    let json = JSON.stringify(object);
    const response = await Axios.put(
      `${Constants.BASE_URL}dreamjob/jo/${offerId}`,
      json,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    ).catch(function (error) {
      // Errors to be sent to snackbar
      errMsgs = "basic.serverError";
      if (
        error.response &&
        error.response.status &&
        error.response.status == Constants.ERROR_RESPONSE_STATUS
      ) {
        authenticated = false;
        errMsgs = "basic.unauthenticated";
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
      throw errMsgs;
    });

    const responseData = response.data.data;
    let errsArr = [];
    if (!responseData.success && responseData.messages.length) {
      responseData.messages.forEach((msg) => {
        errsArr.push(`serverErrors.${msg.code}`);
      });
      throw errsArr;
    }
    dispatch({ type: "disableSaveButton", value: false });
    dispatch({ type: "formSuccessfullySubmitted" });
    appDispatch({ type: "showBackdrop", value: false });
    appDispatch({
      type: "showMsg",
      value: { status: Constants.SNACKBAR_SUCCESS, msg: "basic.saved" },
    });
  } catch (error) {
    appDispatch({ type: "showBackdrop", value: false });
    appDispatch({
      type: "showMsg",
      value: { status: Constants.SNACKBAR_ERROR, msg: error },
    });
    dispatch({ type: "disableSaveButton", value: false });

    if (!authenticated) {
      appDispatch({
        type: "logout",
      });

      appDispatch({ type: "openLoginModal", value: true });
    } else {
      dispatch({ type: "disableSaveButton", value: false });
    }
  }
}
