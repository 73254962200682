import * as Constants from "../../helpers/Constants";

export function formatResponse(data, skipDbDeletion) {
  data.map((element, index) => {
    element.fileUrl = `${Constants.BASE_URL}upload/${element.id}`;
    element.fileId = element.id.toString();
    element.isImg = element["mime-type"].indexOf("image") >= 0;
    if (!element.isImg) {
      element.downloadUrl = element.fileUrl;
    }
    element.isInitialUpload = true;
    element.fileDescription = element.desc;
    if (skipDbDeletion) {
      element.skipDbDeletion = true;
    }
  });

  return data;
}
