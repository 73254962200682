import React, { useEffect } from "react";
import { useImmer, useImmerReducer } from "use-immer";
import SingleAvatarFileUploaderComponent from "../SingleItemFileUploaderComponent/SingleAvatarFileUploaderComponent";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import CloseIcon from "@material-ui/icons/Close";
import { IconBtn } from "./PreviewFileComponent.module.scss";
import Avatar from "@material-ui/core/Avatar";
import Badge from "@material-ui/core/Badge";
import * as PreviewFileHelper from "./PreviewFileHelper";
import { makeStyles } from "@material-ui/core/styles";
import * as Colors from "../../../styles/01.settings/01-settings-colors.module.scss";

import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
  large: {
    width: theme.spacing(14),
    height: theme.spacing(14),
    border: `4px solid ${Colors.primary}`,
  },
  anchorOriginBottomRightRectangle: {
    right: 13,
  },
}));

function PreviewAvatarComponent(props) {
  const initialState = { fieldValue: [] };

  function reducer(draft, action) {
    switch (action.type) {
      case "filedChanged":
        draft.fieldValue = { value: action.value.value, id: action.value.id };
        break;
    }
  }

  const classes = useStyles();

  const [state, dispatch] = useImmerReducer(reducer, initialState);

  function handleDescriptionChange(e) {
    dispatch({
      type: "filedChanged",
      value: { value: e.target.value, id: e.target.id },
    });
    props.dispatchFileUpload({
      type: "descriptionChanged",
      value: { value: e.target.value, id: e.target.id },
    });

    if (props.somethingManualyChangedHandler) {
      props.somethingManualyChangedHandler();
    }
  }

  return props.uploadedFiles.length > 0 ? (
    props.uploadedFiles.map((value, index) => (
      <SingleAvatarFileUploaderComponent
        // singleAvatarStyle={{ top: -70, right: -205 }}
        key={index}
        data={value}
        state={state}
        handleDescriptionChange={handleDescriptionChange}
        handleDelete={PreviewFileHelper.handleDelete}
        useDescription={props.useDescription}
        dispatchFileUpload={props.dispatchFileUpload}
        isInitialUpload={props.isInitialUpload}
        somethingManualyChangedHandler={props.somethingManualyChangedHandler}
      />
    ))
  ) : (
    <Badge
      //   style={{ top: -70, right: -205 }}
      badgeContent={props.badgeContent}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      classes={{
        anchorOriginBottomRightRectangle:
          classes.anchorOriginBottomRightRectangle,
      }}
    >
      <Avatar className={classes.large} />
    </Badge>
  );
}

export default PreviewAvatarComponent;
