import React from "react";
import * as Constants from "../helpers/Constants";
import * as Colors from "../styles/01.settings/01-settings-colors.module.scss";
import ShareIcon from "@material-ui/icons/Share";
import SearchIcon from "@material-ui/icons/Search";
import AccountBalanceWalletOutlinedIcon from "@material-ui/icons/AccountBalanceWalletOutlined";
import DescriptionIcon from "@material-ui/icons/Description";
import EventNoteIcon from "@material-ui/icons/EventNote";
import * as RouteConstants from "./AppRoutes";
import UserProfilePage from "../Pages/UserProfilePage/UserProfilePage";
import CompanyProfilePage from "../Pages/CompanyProfilePage/CompanyProfilePage";
import MyCvComponent from "../Pages/MyCvPage/MyCvComponent";
import MyMessages from "../Pages/MyMessagesPage/MyMessagesPage";
import JobPreferences from "../Pages/JobPreferencesPage/JobPreferencesPage";
import JobOffers from "../Pages/JobOffersPage/JobOffersPage";
import TermsPage from "../Pages/TermsOfUsePage/TermsOfUsePage";
import PrivacyPage from "../Pages/PrivacyPolicyPage/PrivacyPolicyPage";
import CreditsProgramPage from "../Pages/CreditsProgramPage/CreditsProgramPage";
import ContactUsPage from "../Pages/ContactUsPage/ContactUsPage";
import CreateJobOfferPage from "../Pages/CreateJobOfferPage/CreateJobOfferPage";
import UpdateJobOfferPage from "../Pages/CreateJobOfferPage/UpdateJobOfferPage";
import CreateJobPreferencePage from "../Pages/CreateJobPreferencePage/CreateJobPreferencePage";
import JobOfferViewPage from "../Pages/JobOfferView/JobOfferViewPage";
import JobOfferCandidateView from "../Pages/JobOfferCandidateView/JobOfferCandidateView";
import CandidatesWithMessagesViewPage from "../Pages/CandidatesWithMessagesViewPage/CandidatesWithMessagesViewPage";
import EmployersWithMessagesViewPage from "../Pages/EmployersWithMessagesViewPage/EmployersWithMessagesViewPage";
import JobPreferenceEmployerView from "../Pages/JobPreferenceEmployerView/JobPreferenceEmployerView";
import ForgottenPasswordPage from "../Pages/ForgottenPassword/ForgottenPassword";
import ChangePasswordPage from "../Pages/ChangePasswordPage/ChangePassword";
import WalletPage from "../Pages/WalletPage/WalletPage";
import BuyCredits from "../Pages/BuyCreditsPage/BuyCreditsPage";
import { supportedLanguages } from "../helpers/BaseHelper";
import ReferalPage from "../Pages/ReferalPage/ReferalPage";
import ConfirmEmailPage from "../Pages/ConfirmEmailPage/ConfirmEmailPage";
import AccountSettings from "../Pages/AccountSettings/AccountSettings";
import AsChangeEmail from "../Pages/AccountSettings/ChangeEmail/ChangeEmail";
import AsChangePassword from "../Pages/AccountSettings/ChangePassword/ChangePassword";
import AsDeleteAccount from "../Pages/AccountSettings/DeleteAccount/DeleteAccount";
import Invoices from "../Pages/Invoices/Invoices/Invoices";
import CreateInvoice from "../Pages/Invoices/CreateInvoice/CreateInvoice";

const pStyle = { marginTop: 0, marginBottom: 0, textAlign: "left" };

const joyrideSteps = (t) => {
  return [
    {
      content: <p style={pStyle}>{t("tour.ready")}</p>,
      target: "body",
      placement: "center",
    },
    {
      target: ".step1",
      content: (
        <div>
          <p style={pStyle}>
            <strong>{t("tour.lfJob")}</strong>
          </p>
          <p style={pStyle}>{t("tour.fillDetails")}</p>
          <p style={pStyle}>
            <strong>{t("tour.lfEmployees")}</strong>
          </p>
          <p style={pStyle}>{t("tour.fillCompany")}</p>
        </div>
      ),
    },

    {
      target: ".step2",
      content: (
        <div>
          <p style={pStyle}>
            <strong>{t("tour.lfJob")}</strong>
          </p>
          <p style={pStyle}>{t("tour.buildResume")}</p>
        </div>
      ),
      placement: "right",
    },
    {
      target: ".step3",
      content: (
        <div>
          <p style={pStyle}>
            <strong>{t("tour.lfJob")}</strong>
          </p>
          <p style={pStyle}>{t("tour.choosePosition")}</p>
          <p style={pStyle}>{t("tour.setPrefs")}</p>
          <p style={pStyle}>{t("tour.commEmployers")}</p>
        </div>
      ),
      placement: "right",
    },
    {
      target: ".step4",
      content: (
        <div>
          <p style={pStyle}>
            <strong>{t("tour.lfEmployees")}</strong>
          </p>
          <p style={pStyle}>{t("tour.setCriteria")}</p>
          <p style={pStyle}>{t("tour.matchedEmployees")}</p>
          <p style={pStyle}>{t("tour.unlockProfiles")}</p>
          <p style={pStyle}>{t("tour.commEmployees")}</p>
        </div>
      ),
      placement: "right",
    },
    {
      target: ".step5",
      content: (
        <div>
          <p style={pStyle}>{t("tour.buyCredits")}</p>
          <p style={pStyle}>{t("tour.viewTransactions")}</p>
        </div>
      ),
      placement: "right",
    },
    {
      target: ".step6",
      content: <p style={pStyle}>{t("tour.sendInvite")}</p>,
      placement: "right",
    },
  ];
};

export const appState = {
  loggedIn: Boolean(
    localStorage.getItem(`${Constants.LOCAL_STORAGE_SITE_NAME}Token`)
  ),

  user_token: localStorage.getItem(`${Constants.LOCAL_STORAGE_SITE_NAME}Token`),
  user_username: localStorage.getItem(
    `${Constants.LOCAL_STORAGE_SITE_NAME}Username`
  ),
  user_id: localStorage.getItem(`${Constants.LOCAL_STORAGE_SITE_NAME}UserId`),
  user_avatarId: localStorage.getItem(
    `${Constants.LOCAL_STORAGE_SITE_NAME}UserAvatarId`
  ),
  user_CvId: localStorage.getItem(
    `${Constants.LOCAL_STORAGE_SITE_NAME}UserCvId`
  ),
  user_Reghash: localStorage.getItem(
    `${Constants.LOCAL_STORAGE_SITE_NAME}UserReghash`
  ),
  user_AgreedCookies: localStorage.getItem(
    `${Constants.LOCAL_STORAGE_SITE_NAME}UserAgreedCookies`
  ),
  tokenExpiresIn: localStorage.getItem(
    `${Constants.LOCAL_STORAGE_SITE_NAME}TokenExpiresIn`
  ),
  lang: setLanguage(supportedLanguages),
  openLoginModal: false,
  openSignUpModal: false,
  showMsgCounter: 0,
  snackbar: {
    open: false,
    status: Constants.SNACKBAR_SUCCESS,
    msg: "",
  },
  isBackdropOpen: false,
  isCookiesDialogOpen: false,
  pageSelected: null,
  joyride: {
    run: false,
    steps: joyrideSteps,
    stepIndex: 0, // a controlled tour
  },
  location: "",
  toggleDrawer: { left: false },
  // Candidate
  isCvFilled: false,
  isProfileDataFilled: false,
  // Employer
  isCompanyProfileDataFilled: false,
};

export function manageLocalStorageData(state, Axios, ourRequest) {
  if (state.appState.loggedIn) {
    if (state.appState.user_token) {
      localStorage.setItem(
        `${Constants.LOCAL_STORAGE_SITE_NAME}Token`,
        `${state.appState.user_token}`
      );
      localStorage.setItem(
        `${Constants.LOCAL_STORAGE_SITE_NAME}UserId`,
        state.appState.user_id
      );
      localStorage.setItem(
        `${Constants.LOCAL_STORAGE_SITE_NAME}UserAvatarId`,
        state.appState.user_avatarId
      );
      localStorage.setItem(
        `${Constants.LOCAL_STORAGE_SITE_NAME}UserCvId`,
        state.appState.user_CvId
      );
      localStorage.setItem(
        `${Constants.LOCAL_STORAGE_SITE_NAME}UserReghash`,
        state.appState.user_Reghash
      );
      localStorage.setItem(
        `${Constants.LOCAL_STORAGE_SITE_NAME}UserAgreedCookies`,
        state.appState.user_AgreedCookies ? true : false
      );
      // token expire
      localStorage.setItem(
        `${Constants.LOCAL_STORAGE_SITE_NAME}TokenExpiresIn`,
        state.appState.tokenExpiresIn
      );
    }
  } else {
    logout(state, Axios, ourRequest);
  }
}

export async function logout(state, Axios, ourRequest) {
  if (!state.appState.loggedIn) {
    let errMsgs;

    try {
      const response = await Axios.post(
        "/user/logout",
        {},
        { cancelToken: ourRequest.token }
      ).catch(function (error) {
        if (error.response) {
          // Request made and server responded
          errMsgs = error.response;
        } else if (error.request) {
          // The request was made but no response was received
          errMsgs = error.request;
        } else {
          // Something happened in setting up the request that triggered an Error
          errMsgs = error.message;
        }
        throw errMsgs;
      });
    } catch (error) {
      console.log("logout:::Error", error);
    }

    localStorage.removeItem(`${Constants.LOCAL_STORAGE_SITE_NAME}Token`);
    localStorage.removeItem(`${Constants.LOCAL_STORAGE_SITE_NAME}UserId`);
    localStorage.removeItem(`${Constants.LOCAL_STORAGE_SITE_NAME}UserAvatarId`);
    localStorage.removeItem(`${Constants.LOCAL_STORAGE_SITE_NAME}UserCvId`);
    localStorage.removeItem(`${Constants.LOCAL_STORAGE_SITE_NAME}UserReghash`);
    localStorage.removeItem(
      `${Constants.LOCAL_STORAGE_SITE_NAME}UserAgreedCookies`
    );
    localStorage.removeItem(
      `${Constants.LOCAL_STORAGE_SITE_NAME}UserPictureId`
    );
    localStorage.removeItem(
      `${Constants.LOCAL_STORAGE_SITE_NAME}TokenExpiresIn`
    );
    localStorage.removeItem(
      `${Constants.LOCAL_STORAGE_SITE_NAME}TokenExpiresAt`
    );
    localStorage.removeItem(`UserProfileDataFilled`);
    localStorage.removeItem(`CompanyProfileDataFilled`);
  }
}

// Drawer items
export const drawerItems = [
  {
    icon: <DescriptionIcon htmlColor={Colors.white} />,
    text: "cv.myCV",
    link: "/my-cv",
    className: "step2",
  },
  {
    icon: <EventNoteIcon htmlColor={Colors.white} />,
    text: "drawer.jobPreferences",
    link: "/job-preferences",
    className: "step3",
  },
  {
    dividerTop: true,
    icon: <SearchIcon htmlColor={Colors.white} />,
    text: "drawer.jobOffers",
    link: "/job-offers",
    className: "step4",
  },
  {
    dividerTop: true,
    icon: <AccountBalanceWalletOutlinedIcon htmlColor={Colors.white} />,
    text: "drawer.wallet",
    link: "/wallet",
    className: "step5",
  },
  {
    dividerTop: true,
    icon: <ShareIcon htmlColor={Colors.white} />,
    text: "referal.refer",
    link: "/referal",
    className: "step6",
  },
];

// Footer items
export const naviItems = [
  {
    label: "basic.home",
    showLabel: true,
    link: "/",
  },
  {
    label: "footer.terms",
    showLabel: true,
    link: "/terms-of-use",
  },
  {
    label: "footer.privacy",
    showLabel: true,
    link: "/privacy-policy",
  },
  {
    label: "footer.referralProgram",
    showLabel: true,
    link: "/credits-program",
  },
  {
    label: "footer.contact",
    showLabel: true,
    link: "/contact-us",
  },
];

export const routerItems = [
  {
    path: RouteConstants.ROUTE_USER_PROFILE,
    component: <UserProfilePage />,
  },
  {
    path: RouteConstants.ROUTE_COMPANY_PROFILE,
    component: <CompanyProfilePage />,
  },
  {
    path: RouteConstants.ROUTE_MY_CV,
    component: <MyCvComponent />,
  },
  {
    path: RouteConstants.ROUTE_JOB_PREFERENCES,
    component: <JobPreferences />,
  },
  {
    path: RouteConstants.ROUTE_CREATE_JOB_PREFERENCE,
    component: <CreateJobPreferencePage />,
  },
  {
    path: RouteConstants.ROUTE_VIEW_JOB_PREFERENCE_EMPLOYERS_WITH_MESSAGES,
    component: <EmployersWithMessagesViewPage />,
  },
  {
    path: RouteConstants.ROUTE_VIEW_JOB_PREFERENCE_EMPLOYER,
    component: <JobPreferenceEmployerView />,
  },
  {
    path: RouteConstants.ROUTE_JOB_OFFERS,
    component: <JobOffers />,
  },
  {
    path: RouteConstants.ROUTE_CREATE_JOB_OFFER,
    component: <CreateJobOfferPage />,
  },
  {
    path: RouteConstants.ROUTE_MY_MESSAGES,
    component: <MyMessages />,
  },
  {
    path: RouteConstants.ROUTE_UPDATE_JOB_OFFER,
    component: <UpdateJobOfferPage />,
  },
  {
    path: RouteConstants.ROUTE_VIEW_JOB_OFFER,
    component: <JobOfferViewPage />,
  },
  {
    path: RouteConstants.ROUTE_VIEW_JOB_OFFER_CANDIDATE,
    component: <JobOfferCandidateView />,
  },
  {
    path: RouteConstants.ROUTE_VIEW_JOB_OFFER_CANDIDATES_WITH_MESSAGES,
    component: <CandidatesWithMessagesViewPage />,
  },
  {
    path: RouteConstants.ROUTE_VIEW_JOB_PREFERENCE,
    component: <CreateJobPreferencePage />,
  },
  {
    path: RouteConstants.ROUTE_WALLET,
    component: <WalletPage />,
  },
  {
    path: RouteConstants.ROUTE_BUY_CREDITS,
    component: <BuyCredits />,
  },
  {
    path: RouteConstants.ROUTE_REFERAL,
    component: <ReferalPage />,
  },
  {
    path: RouteConstants.ROUTE_ACCOUNT_SETTINGS,
    component: <AccountSettings />,
  },
  {
    path: RouteConstants.ROUTE_ACCOUNT_SETTINGS_CHANGE_EMAIL,
    component: <AsChangeEmail />,
  },
  {
    path: RouteConstants.ROUTE_ACCOUNT_SETTINGS_CHANGE_PASSWORD,
    component: <AsChangePassword />,
  },
  {
    path: RouteConstants.ROUTE_ACCOUNT_SETTINGS_DELETE_ACCOUNT,
    component: <AsDeleteAccount />,
  },
  {
    path: RouteConstants.ROUTE_INVOICES,
    component: <Invoices />,
  },
  {
    path: RouteConstants.ROUTE_CREATE_INVOICE,
    component: <CreateInvoice />,
  },
];

export const routerItemsAlways = [
  {
    path: RouteConstants.ROUTE_TERMS_OF_USE,
    component: <TermsPage />,
  },
  {
    path: RouteConstants.ROUTE_PRIVACY_POLICY,
    component: <PrivacyPage />,
  },
  {
    path: RouteConstants.ROUTE_CREDITS_PROGRAM,
    component: <CreditsProgramPage />,
  },
  {
    path: RouteConstants.ROUTE_CONTACT_US,
    component: <ContactUsPage />,
  },
];

export const routerItemsDislogged = [
  {
    path: RouteConstants.ROUTE_FORGOTTEN_PASSWORD,
    component: <ForgottenPasswordPage />,
  },
  {
    path: RouteConstants.ROUTE_CHANGE_PASSWORD,
    component: <ChangePasswordPage />,
  },
  {
    path: RouteConstants.ROUTE_CONFIRM_EMAIL,
    component: <ConfirmEmailPage />,
  },
];

function setLanguage(supportedLngs) {
  const detectedLng = localStorage.getItem("i18nextLng");
  let lngToSet = "en";
  supportedLngs.forEach((lng) => {
    if (lng === detectedLng) {
      lngToSet = detectedLng;
    }
  });
  return lngToSet;
}

// Checks if user has filled out their First name,  Last name , Birth date and CV

export async function checkCreateJP(userId, Axios, ourRequest, dispatch) {
  let errMsgs;
  let authenticated = true;

  const params = { userID: userId };
  try {
    const response = await Axios.get(`/dreamjob/jp/check_create`, params, {
      cancelToken: ourRequest.token,
    }).catch(function (error) {
      // Errors to be sent to snackbar
      errMsgs = "basic.serverError";
      if (
        error.response &&
        error.response.status &&
        error.response.status === Constants.ERROR_RESPONSE_STATUS
      ) {
        authenticated = false;
        errMsgs = "basic.unauthenticated";
      }
      throw errMsgs;
    });

    const responseData = response.data.data;

    const checkProfile = responseData.messages.some(
      (msg) => msg.code === (3022 || 3023 || 3024)
    );
    if (!checkProfile) {
      dispatch({
        type: "setIsProfileDataFilled",
        value: true,
      });
      localStorage.setItem("UserProfileDataFilled", true);
    }

    // const checkCV = responseData.messages.some((msg) => msg.code === 3021);
    // if (!checkCV) {
    //   dispatch({
    //     type: "setIsCvFilled",
    //     value: true,
    //   });
    // }
  } catch (error) {
    dispatch({
      type: "showMsg",
      value: { status: Constants.SNACKBAR_ERROR, msg: error },
    });
    if (!authenticated) {
      dispatch({
        type: "logout",
      });
    }
  }
}

// Checks if user has filled out Company Profile data

export async function checkCreateJO(userId, Axios, ourRequest, dispatch) {
  let errMsgs;
  let authenticated = true;
  const params = { userID: userId };
  try {
    const response = await Axios.get(`/dreamjob/jo/check_create`, params, {
      cancelToken: ourRequest.token,
    }).catch(function (error) {
      // Errors to be sent to snackbar
      errMsgs = "basic.serverError";
      if (
        error.response &&
        error.response.status &&
        error.response.status === Constants.ERROR_RESPONSE_STATUS
      ) {
        authenticated = false;
        errMsgs = "basic.unauthenticated";
      }
      throw errMsgs;
    });

    const responseData = response.data.data;

    const checkCompanyProfile = responseData.messages.some(
      (msg) => msg.code === (4026 || 4028)
    );
    if (!checkCompanyProfile) {
      dispatch({
        type: "setIsCompanyProfileDataFilled",
        value: true,
      });
      localStorage.setItem("CompanyProfileDataFilled", true);
    }
  } catch (error) {
    dispatch({
      type: "showMsg",
      value: { status: Constants.SNACKBAR_ERROR, msg: error },
    });
    if (!authenticated) {
      dispatch({
        type: "logout",
      });
    }
  }
}
