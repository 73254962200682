module.exports = `
<html>

<head>
<meta http-equiv=Content-Type content="text/html; charset=windows-1251">
<meta name=Generator content="Microsoft Word 15 (filtered)">
<style>
<!--
 /* Font Definitions */
 @font-face
	{font-family:SimSun;
	panose-1:2 1 6 0 3 1 1 1 1 1;}
@font-face
	{font-family:"Cambria Math";
	panose-1:2 4 5 3 5 4 6 3 2 4;}
@font-face
	{font-family:OpenSymbol;}
@font-face
	{font-family:"Microsoft YaHei";
	panose-1:2 11 5 3 2 2 4 2 2 4;}
@font-face
	{font-family:"\@SimSun";
	panose-1:2 1 6 0 3 1 1 1 1 1;}
@font-face
	{font-family:"\@Microsoft YaHei";}
@font-face
	{font-family:"\@OpenSymbol";}
 /* Style Definitions */
 p.MsoNormal, li.MsoNormal, div.MsoNormal
	{margin:0cm;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;}
p.MsoBodyText, li.MsoBodyText, div.MsoBodyText
	{margin-top:0cm;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:0cm;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;}
.terms a:link, span.MsoHyperlink
	{color:navy;
	text-decoration:underline;}
p.-, li.-, div.-
	{mso-style-name:"Таблица - съдържание";
	margin:0cm;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;}
@page WordSection1
	{size:595.3pt 841.9pt;
	margin:2.0cm 2.0cm 2.0cm 2.0cm;}
div.WordSection1
	{page:WordSection1;}
 /* List Definitions */
 ol
	{margin-bottom:0cm;}
ul
	{margin-bottom:0cm;}
-->
</style>

</head>

<body lang=BG link=navy vlink="#954F72" style='word-wrap:break-word;line-break:
strict'>

<div class=WordSection1>

  <b>ПОЛИТИКА ЗА ЗАЩИТА НА ЛИЧНИТЕ ДАННИ ЗА РАБОТОДАТЕЛИ</b><br>
  <br>
  С цел предоставяне на услугите (“Услуги”) в сайта <span lang=EN-US>www.jobpreference.com</span>
  (“Сайта”), „<span style='color:#333333'>Сън Ленд Кепитъл</span>” ООД („<em><span
  lang=EN-US style='font-style:normal'>J<span style='color:#333333'>obpreference.com</span></span></em>“/
  „Ние“) с адрес на управление: ул. Кдп Георги Боев №4, Варна , ЕИК: <span
  style='color:#333333'>206166575</span> обработва лични данни на Представители
  на Работодатели и физически лица - Работодатели или други физически лица,
  посочени по-долу („Субекти на данни“/„Вие“/„Вас), в съответствие с настоящата
  Политика.<br>
  <br>
  При обработването на лични данни <em><span lang=EN-US style='font-style:normal'>J<span
  style='color:#333333'>obpreference.com</span></span></em> спазва всички
  приложими към дейността му нормативни актове по защита на личните данни,
  включително, но не само, Регламент (ЕС) 2016/679 („Регламент“).<br>
  <br>
  Съгласно Регламента, лични данни е всяка информация, която се отнася до Вас и
  чрез която можете да бъдете идентифицирани. Обработване на лични данни
  (”Обработване”) е всяко действие или съвкупност от действия, които могат да
  се извършат по отношение на личните данни с автоматични или други средства. </p>
  <p class=- style='line-height:150%;border:none;padding:0cm'>&nbsp;</p>
  <p class=MsoNormal style='line-height:150%;border:none;padding:0cm'><b>1.
  Групи лица, за които се обработват данни</b><br>
  <br>
  Във връзка с предоставените Услуги <em><span lang=EN-US style='font-style:
  normal'>J<span style='color:#333333'>obpreference.com</span></span></em>
  обработва информация относно следните Субекти на данни:<br>
  (а) Представители на Работодатели („Представители“) – физически лица;<br>
  (б) Работодатели - физически лица, регистрирани в Сайта;<br>
  (в) Физически лица, посочени от Работодателя, като лица за контакт (административен
  контакт, финансов контакт, длъжностно лице по защита на данните и др.)<br>
  <br>
  <b>2. Обработвани данни във връзка с предоставяне на Услугите</b><br>
  <br>
  <b>2.1. Информация, която Вие предоставяте</b><br>
  <br>
  Вие сами решавате дали и как да използвате Услугите, предоставяни чрез Сайта.
  Във формите, чрез които се въвеждат лични данни, ясно обозначаваме
  задължителния или доброволния характер на предоставяне на данните. Данните,
  попълването на които е задължително, са такива, без които е невъзможно да
  предоставим съответната Услуга или част от нея.<br>
  <br>
  <b><span style='color:black;background:transparent'>(а) Регистрация на фирмен
  акаунт, потребители и контакти.</span></b><span style='color:black;
  background:transparent'> При създаването и при ползването на фирмен акаунт в Сайта
  се изисква информация за Работодателя; Представители на Работодателя; Лица за
  контакт; Длъжностно лице по защита на данните по смисъла на Регламента (ако
  има такова). В допълнение се съхранява информация за извършването на
  регистрацията и приемането на Условията (дата, час и IP адрес).<br>
  <br>
  Данните, които се предоставят от Вас при регистрацията на акаунт, добавяне на
  Представители и лица за контакт, могат да бъдат: </span></p>
  </div>
  <p class=MsoBodyText style='margin-top:0cm;margin-right:0cm;margin-bottom:
  0cm;margin-left:35.35pt;text-indent:-14.15pt'><span style='font-family:Symbol'>·<span
  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
  style='color:black;background:transparent'>Работодатели - физически лица: име,
  адрес на управление, други данни за контакт (адрес за кореспонденция;
  телефон; имейл адрес); ЕИК/ БУЛСТАТ; ДДС номер и др. </span></p>
  <p class=MsoBodyText style='margin-top:0cm;margin-right:0cm;margin-bottom:
  0cm;margin-left:35.35pt;text-indent:-14.15pt'><span style='font-family:Symbol'>·<span
  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
  style='color:black;background:transparent'>Представители на Работодатели
  („Представители“) – физически лица: име, длъжност, служебен телефон, служебен
  имейл адрес и др. </span></p>
  <p class=MsoBodyText style='margin-left:35.35pt;text-indent:-14.15pt'><span
  style='font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  </span></span><span style='color:black;background:transparent'>Лица за контакт –
  физически лица: име, длъжност, служебен телефон, служебен имейл адрес и др. </span></p>
  <p class=MsoBodyText><br>
  <b>(б) Договори, декларации и др.</b> Обменени между Вас и <em><span
  lang=EN-US style='font-style:normal'>J<span style='color:#333333'>obpreference.com</span></span></em>
  документи, свързани със сключването и изпълнението на договора (допълнителни
  споразумения; пълномощни; декларации; фактури и др. под.). Тези документи е
  възможно да съдържат име, длъжност, информация за контакт,
  саморъчен/електронен подпис и др.<br>
  <br>
  <b>(в) Кореспонденция, жалби и сигнали.</b> С цел разрешаване на подадени
  жалби, сигнали, спорове, запитвания, искания или други въпроси, отправени в
  комуникация към Нас, постъпила чрез електронни форми в Сайта, чрез обаждания
  към телефонната централа на <em><span lang=EN-US style='font-style:normal'>J<span
  style='color:#333333'>obpreference.com</span></span></em>, чрез изпращане по
  регулярна или електронна поща, Ние съхраняваме и обработваме тази информация,
  както и резултата от това обработване.<br>
  <br>
  <b>(г) Специални категории (чувствителни) данни.</b> Услугите на <em><span
  lang=EN-US style='font-style:normal'>J<span style='color:#333333'>obpreference.com</span></span></em>
  и предоставените в Сайта функционалности не са предназначени за събиране,
  съхранение и обработване на специални категории данни по смисъла на чл. 9 и
  чл. 10 от Регламента. Вие се задължавате да не съхранявате такива данни във
  Вашия фирмен акаунт. В допълнение, Вие не следва да изисквате от Кандидатите 
  да изпращат такива данни посредством Сайта. <em><span lang=EN-US
  style='font-style:normal'>J<span style='color:#333333'>obpreference.com</span></span></em>
  не е длъжен и не следи за наличието на посочените тук данни в Сайта.<br>
  <br>
  <b>(д) Други данни.</b> <em><span lang=EN-US style='font-style:normal'>J<span
  style='color:#333333'>obpreference.com</span></span></em> може да обработва и
  други данни, отнасящи се до Вас, в случаите, когато Вие предоставите
  доброволно такива данни чрез попълване на съответни електронни форми в Сайта,
  добавяне на предпочитания, настройки и др.<br>
  <br>
  <b>2.2. Данни, свързани с процеса на ползване на Услугите</b><br>
  <br>
  <b>(а) Договорна и финансова информация.</b> Във връзка със сключването и
  изпълнението на Договора за предоставяне на Услугите между Вас и <em><span
  lang=EN-US style='font-style:normal'>J<span style='color:#333333'>obpreference.com</span></span></em>,
  е възможно обработването на следните данни: </p>
  <p class=MsoBodyText style='margin-top:0cm;margin-right:0cm;margin-bottom:
  0cm;margin-left:35.35pt;text-indent:-14.15pt'><span style='font-family:Symbol'>·<span
  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span>Информация
  относно договорните уговорки; </p>
  <p class=MsoBodyText style='margin-top:0cm;margin-right:0cm;margin-bottom:
  0cm;margin-left:35.35pt;text-indent:-14.15pt'><span style='font-family:Symbol'>·<span
  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span>Информация,
  свързана с извършените плащания за Услугите; </p>
  <p class=MsoBodyText style='margin-top:0cm;margin-right:0cm;margin-bottom:
  0cm;margin-left:35.35pt;text-indent:-14.15pt'><span style='font-family:Symbol'>·<span
  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span>Информация,
  свързана с администрирането и предоставянето на Услугите; </p>
  <p class=MsoBodyText style='margin-top:0cm;margin-right:0cm;margin-bottom:
  0cm;margin-left:35.35pt;text-indent:-14.15pt'><span style='font-family:Symbol'>·<span
  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span>История
  и информация за заявени Услуги; </p>
  <p class=MsoBodyText style='margin-top:0cm;margin-right:0cm;margin-bottom:
  0cm;margin-left:35.35pt;text-indent:-14.15pt'><span style='font-family:Symbol'>·<span
  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span>История
  и информация за използването на Услугите; </p>
  <p class=MsoBodyText style='margin-left:35.35pt;text-indent:-14.15pt'><span
  style='font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  </span></span>Друга информация, създадена или предоставена в процеса на
  използване на Услугите. </p>
  <p class=MsoBodyText><b>(б) Настройки и известия.</b> При някои от Услугите
  са налични настройки и известия (вкл. по електронна поща), улесняващи Вашата
  работа със Сайта. <em><span lang=EN-US style='font-style:normal'>J<span
  style='color:#333333'>obpreference.com</span></span></em> обработва
  съответната информация за тази цел.<br>
  <br>
  <b>(в) Инструкции и лог на действията по обработване на лични данни.</b> <em><span
  lang=EN-US style='font-style:normal'>J<span style='color:#333333'>obpreference.com</span></span></em>
  предоставя технологична възможност за отключване на предпочитания и за
  съхранението им във Вашия акаунт.<br>
  <br>
  <b>(г) Логове, свързани със сигурност, техническа поддръжка, развитие и др.</b>
  В Сайта се използват логове със следните цели: </p>
  <p class=MsoBodyText style='margin-top:0cm;margin-right:0cm;margin-bottom:
  0cm;margin-left:35.35pt;text-indent:-14.15pt'><span style='font-family:Symbol'>·<span
  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span>За
  подсигуряване на надеждното функциониране на Услугите и установяване на
  технически проблеми; </p>
  <p class=MsoBodyText style='margin-top:0cm;margin-right:0cm;margin-bottom:
  0cm;margin-left:35.35pt;text-indent:-14.15pt'><span style='font-family:Symbol'>·<span
  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span>За
  подсигуряване сигурността на Услугите и откриване на злонамерени действия; </p>
  <p class=MsoBodyText style='margin-left:35.35pt;text-indent:-14.15pt'><em><span
  style='font-family:Symbol;font-style:normal'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  </span></span></em>Логове в случаи, в които това се изисква от закона (като
  логове на електронни волеизявления). </p>
  <p class=MsoBodyText><em>Сървърни логове, логове на устройства за защита на
  сигурността (Web Application Firewalls) и др. устройства, попадащи в тази
  категория.</em> Тези логове са необходими за установяване на технически
  проблеми, откриване на злонамерени действия и др. от посочените по-горе цели.
  Те се съхраняват за период до 1 /една/ година. Логовете могат да съдържат
  следната информация: дата и час, IP адрес, URL, информация за браузър и
  устройство. В допълнение някои от устройствата е възможно да използват
  технология за сигурност, базирана на бисквити.<br>
  <br>
  <b>(д) Бисквити.</b> За функционирането на Сайта е необходимо ползването на
  бисквити. </p>
  <p class=MsoBodyText>&nbsp;</p>
  <p class=MsoNormal><b>3. Цели на обработване на лични данни</b><br>
  <br>
  <em><span lang=EN-US style='font-style:normal'>J<span style='color:#333333'>obpreference.com</span></span></em>
  събира, използва и обработва информацията, описана по-горе, за целите,
  предвидени в настоящата Политика и в Условията за ползване на Услугите, които
  могат да бъдат: </p>
  <p class=MsoBodyText style='margin-top:0cm;margin-right:0cm;margin-bottom:
  0cm;margin-left:35.35pt;text-indent:-14.15pt'><span style='font-family:Symbol'>·<span
  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span>Цели,
  необходими за сключването и изпълнението на договора за предоставяне на
  Услугите между Вас и <em><span lang=EN-US style='font-style:normal'>J<span
  style='color:#333333'>obpreference.com</span></span></em>; </p>
  <p class=MsoBodyText style='margin-top:0cm;margin-right:0cm;margin-bottom:
  0cm;margin-left:35.35pt;text-indent:-14.15pt'><span style='font-family:Symbol'>·<span
  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span>Цели,
  необходими за защита и прилагане на легитимните интереси на други ползватели
  на Услугите, трети лица и <em><span lang=EN-US style='font-style:normal'>J<span
  style='color:#333333'>obpreference.com</span></span></em>; </p>
  <p class=MsoBodyText style='margin-top:0cm;margin-right:0cm;margin-bottom:
  0cm;margin-left:35.35pt;text-indent:-14.15pt'><span style='font-family:Symbol'>·<span
  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span>Цели,
  за които Вие сте дали своето изрично съгласие; </p>
  <p class=MsoBodyText style='margin-left:35.35pt;text-indent:-14.15pt'><span
  style='font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  </span></span>Цели, необходими за изпълнението на законови задължения на <em><span
  lang=EN-US style='font-style:normal'>J<span style='color:#333333'>obpreference.com</span></span></em>.
  </p>
  <p class=MsoBodyText><b>(а) Сключване и изпълнение на договор.</b> Това са
  целите, необходими за стъпките по сключването и изпълнението на договора
  между Вас и <em><span lang=EN-US style='font-style:normal'>J<span
  style='color:#333333'>obpreference.com</span></span></em>, включително
  необходимото за това обработване на данни при извършване на регистрация,
  създаване на акаунт и дейностите по предоставяне на Услугите, достъпни чрез
  Сайта. В допълнение тези цели включват комуникацията с Вас, включително по
  електронна поща, необходима във връзка с предоставяне на Услугите и/или
  уведомяването Ви за промени в предоставяните Услуги. За целта може да е
  необходимо обработване на част или на всички посочени по-горе категории данни.<br>
  <br>
  <b>(б) Легитимен интерес.</b> Целите на легитимните интереси на <em><span
  lang=EN-US style='font-style:normal'>J<span style='color:#333333'>obpreference.com</span></span></em>
  или на трети лица (други ползватели на Сайта (Кандидати, Работодатели,
  Представители на Работодатели, лица за административен контакт), служители на
  <em><span lang=EN-US style='font-style:normal'>J<span style='color:#333333'>obpreference.com</span></span></em>
  и негови подизпълнители или доставчици на услуги), включват: </p>
  <p class=MsoBodyText style='margin-top:0cm;margin-right:0cm;margin-bottom:
  0cm;margin-left:35.35pt;text-indent:-14.15pt'><span style='font-family:Symbol'>·<span
  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span>Осигуряване
  на нормалното функциониране и използване на Сайта от Ваша страна и от страна
  на другите потребители, поддръжка и администриране на Услугите, разрешаване
  на възникнали спорове, установяване и предотвратяване на злонамерени
  действия; </p>
  <p class=MsoBodyText style='margin-top:0cm;margin-right:0cm;margin-bottom:
  0cm;margin-left:35.35pt;text-indent:-14.15pt'><span style='font-family:Symbol'>·<span
  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span>Откриване
  и разрешаване на технически или проблеми с функционалността; </p>
  <p class=MsoBodyText style='margin-top:0cm;margin-right:0cm;margin-bottom:
  0cm;margin-left:35.35pt;text-indent:-14.15pt'><span style='font-family:Symbol'>·<span
  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span>Осъществяване
  на комуникация с Вас, включително по електронен път по важни въпроси,
  свързани с Услугите; </p>
  <p class=MsoBodyText style='margin-top:0cm;margin-right:0cm;margin-bottom:
  0cm;margin-left:35.35pt;text-indent:-14.15pt'><span style='font-family:Symbol'>·<span
  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span>Приемане
  и обработване на получени сигнали, жалби, искания и друга кореспонденция; </p>
  <p class=MsoBodyText style='margin-left:35.35pt;text-indent:-14.15pt'><span
  style='font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  </span></span>Осъществяване и закрила на правата и законните интереси на <em><span
  lang=EN-US style='font-style:normal'>J<span style='color:#333333'>obpreference.com</span></span></em>,
  включително и по съдебен ред, и оказване на съдействие при осъществяване и
  закрила на правата и законните интереси на другите ползватели на сайта и/или
  засегнати трети страни. </p>
  <p class=MsoBodyText>За тези цели е възможно да е необходимо обработване на
  част или на всички посочени по-горе категории.<br>
  <br>
  <b>(в) Законови задължения.</b> Това са цели като: </p>
  <p class=MsoBodyText style='margin-top:0cm;margin-right:0cm;margin-bottom:
  0cm;margin-left:35.35pt;text-indent:-14.15pt'><span style='font-family:Symbol'>·<span
  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span>Финансово-счетоводни
  дейности, при които е необходимо обработването и съхранението на информация,
  която законът изисква с оглед изпълнението на данъчните, счетоводни и други
  отчетни задължения; </p>
  <p class=MsoBodyText style='margin-top:0cm;margin-right:0cm;margin-bottom:
  0cm;margin-left:35.35pt;text-indent:-14.15pt'><span style='font-family:Symbol'>·<span
  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span>Дейности
  по изпълнение на предвидени в закона задължения за запазване или предоставяне
  на информация при постъпване на съответно разпореждане от компетентни
  държавни или съдебни органи, при осигуряване на възможност за осъществяване
  на контролните правомощия на компетентните държавни органи, при изпълнение на
  законовите задължения на <em><span lang=EN-US style='font-style:normal'>J<span
  style='color:#333333'>obpreference.com</span></span></em> да Ви уведомява за
  различни обстоятелства, свързани с Вашите права, с предоставяните Услуги или
  със защитата на данните Ви и др. под.; </p>
  <p class=MsoBodyText style='margin-left:35.35pt;text-indent:-14.15pt'><span
  style='font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  </span></span>Дейности по изпълнение на задължения за документиране, отчетни
  задължения и други задължения, свързани с изпълнение на изискванията на
  Регламент (ЕС) 2016/679 и действащото законодателство, включително във връзка
  с възложените със Споразумението за обработване на лични данни задължения и
  други приложими задължения. </p>
  <p class=MsoBodyText>За тези цели е възможно да е необходимо обработване на
  част или на всички посочени по-горе категории.<br>
  <br>
  <b>(г) Изрично съгласие.</b> Ваши данни могат да бъдат обработвани на базата
  на Ваше изрично съгласие, като обработването в този случай е конкретно и в
  степента и обхвата, предвидени в съответното съгласие. </p>
  <p class=MsoBodyText>&nbsp;</p>
  <p class=MsoBodyText><b>4. Срок на съхранение</b> </p>
  <p class=MsoBodyText><span style='color:black'>Тук можете да намерите
  информация за максималните срокове на съхранение на различните видове данни.
  При съхранението на данни, Ние прилагаме генералния принцип за съхранение на
  данни в минимален обем и за срок не по-дълъг от необходимото за предоставяне
  на Услугите, подсигуряване на тяхната сигурност и надеждност и изискванията
  на закона.</span> </p>
  <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
   style='border-collapse:collapse'>
   <tr>
    <td width=319 valign=top style='width:239.55pt;border:solid black 1.0pt;
    border-right:none;padding:2.75pt 2.75pt 2.75pt 2.75pt'>
    <p class=->Вид данни </p>
    </td>
    <td width=320 valign=top style='width:239.65pt;border:solid black 1.0pt;
    padding:2.75pt 2.75pt 2.75pt 2.75pt'>
    <p class=->Период на съхранение </p>
    </td>
   </tr>
   <tr>
    <td width=319 valign=top style='width:239.55pt;border-top:none;border-left:
    solid black 1.0pt;border-bottom:solid black 1.0pt;border-right:none;
    padding:2.75pt 2.75pt 2.75pt 2.75pt'>
    <p class=->Данни във връзка с регистрация на фирмен акаунт, потребители,
    допълнителни потребители, контакти и информация за приемане на Условията
    (дата, час, IP адрес и др.); договори, декларации и др. </p>
    </td>
    <td width=320 valign=top style='width:239.65pt;border:solid black 1.0pt;
    border-top:none;padding:2.75pt 2.75pt 2.75pt 2.75pt'>
    <p class=->За периода на поддържане на акаунта в Сайта и до 5 /пет/ години
    от прекратяване на регистрацията </p>
    </td>
   </tr>
   <tr>
    <td width=319 valign=top style='width:239.55pt;border-top:none;border-left:
    solid black 1.0pt;border-bottom:solid black 1.0pt;border-right:none;
    padding:2.75pt 2.75pt 2.75pt 2.75pt'>
    <p class=->Настройки (език на Сайта, скриване на информационни кутии и др.)
    </p>
    </td>
    <td width=320 valign=top style='width:239.65pt;border:solid black 1.0pt;
    border-top:none;padding:2.75pt 2.75pt 2.75pt 2.75pt'>
    <p class=->До изтриването им от Вас или до прекратяване на регистрацията
    Ви. В случай че се съхраняват в бисквита, до 6 /шест/ месеца от последното
    ползване </p>
    </td>
   </tr>
   <tr>
    <td width=319 valign=top style='width:239.55pt;border-top:none;border-left:
    solid black 1.0pt;border-bottom:solid black 1.0pt;border-right:none;
    padding:2.75pt 2.75pt 2.75pt 2.75pt'>
    <p class=->Фактури и друга финансова информация (вкл. данни за Работодател
    и Представители на Работодател) </p>
    </td>
    <td width=320 valign=top style='width:239.65pt;border:solid black 1.0pt;
    border-top:none;padding:2.75pt 2.75pt 2.75pt 2.75pt'>
    <p class=->За периода на поддържане на акаунта в Сайта и до 5 /пет/ години
    от прекратяване на регистрацията </p>
    </td>
   </tr>
   <tr>
    <td width=319 valign=top style='width:239.55pt;border-top:none;border-left:
    solid black 1.0pt;border-bottom:solid black 1.0pt;border-right:none;
    padding:2.75pt 2.75pt 2.75pt 2.75pt'>
    <p class=->Кореспонденция, искания, жалби и сигнали </p>
    </td>
    <td width=320 valign=top style='width:239.65pt;border:solid black 1.0pt;
    border-top:none;padding:2.75pt 2.75pt 2.75pt 2.75pt'>
    <p class=->За периода на поддържане на акаунт в Сайта и до 5 /пет/ години
    от прекратяване на регистрацията </p>
    </td>
   </tr>
   <tr>
    <td width=319 valign=top style='width:239.55pt;border-top:none;border-left:
    solid black 1.0pt;border-bottom:solid black 1.0pt;border-right:none;
    padding:2.75pt 2.75pt 2.75pt 2.75pt'>
    <p class=->Инструкции и лог на действията по обработване на лични данни,
    лог за достъп до акаунта (съхранява се информация като: действие,
    Представителя, извършил действието, дата и час, IP адрес и др. </p>
    </td>
    <td width=320 valign=top style='width:239.65pt;border:solid black 1.0pt;
    border-top:none;padding:2.75pt 2.75pt 2.75pt 2.75pt'>
    <p class=->За периода на поддържане на акаунт в Сайта и до 5 /пет/ години
    от прекратяване на регистрацията </p>
    </td>
   </tr>
   <tr>
    <td width=319 valign=top style='width:239.55pt;border-top:none;border-left:
    solid black 1.0pt;border-bottom:solid black 1.0pt;border-right:none;
    padding:2.75pt 2.75pt 2.75pt 2.75pt'>
    <p class=->Системни логове. Логове, свързани със сигурност, техническа
    поддръжка, развитие и др. (могат да съдържат информация като: дата и час,
    IP адрес, URL, информация за версия на браузър и устройство) </p>
    </td>
    <td width=320 valign=top style='width:239.65pt;border:solid black 1.0pt;
    border-top:none;padding:2.75pt 2.75pt 2.75pt 2.75pt'>
    <p class=->За период до 1 /една/ година </p>
    </td>
   </tr>
   <tr>
    <td width=319 valign=top style='width:239.55pt;border-top:none;border-left:
    solid black 1.0pt;border-bottom:solid black 1.0pt;border-right:none;
    padding:2.75pt 2.75pt 2.75pt 2.75pt'>
    <p class=->Бисквити </p>
    </td>
    <td width=320 valign=top style='width:239.65pt;border:solid black 1.0pt;
    border-top:none;padding:2.75pt 2.75pt 2.75pt 2.75pt'>
    <p class=->За описание на ползваните бисквити виж “Политика за ползване на
    Бисквити” </p>
    </td>
   </tr>
  </table>
  <p class=MsoBodyText>&nbsp;</p>
  <p class=MsoBodyText>В случай на възникнал правен спор или производство,
  налагащо запазване на данни и/или искане от компетентен държавен орган, е
  възможно запазване на данни за по-дълъг от посочените срокове до окончателно
  приключване на възникналия спор или производство пред всички инстанции.
  Посочените срокове могат да бъдат променяни, в случай че бъде установено
  различно изискване за запазване на информацията съгласно действащото
  законодателство.<br>
  <br>
  Промяна в или прекратяване на взаимоотношенията между Работодател и негови
  Представители не води до промяна в посочените по-горе срокове, поради това че
  информацията за направените поръчки, инструкции и действия на конкретните
  Представители е необходима за доказване и отчетност във връзка с договорните
  и законови задължения.<br>
  <br>
  <b><span style='color:black;background:transparent'>Резервираност (Backup).</span></b><span
  style='color:black;background:transparent'> С цел подсигуряване на надеждността на
  Услугите и предпазване от загуба на данни по технически причини, в Сайта се
  прилага политика на резервираност на данните. Максималният период за
  актуализация (изтриване на данни) от всички резервни копия е 30 дни. </span></p>
  <p class=MsoBodyText>&nbsp;</p>
  <p class=MsoBodyText><b><span lang=EN-US>5</span>. Точност на информацията</b><br>
  <em><span lang=EN-US style='font-style:normal'>J<span style='color:#333333'>obpreference.com</span></span></em>
  не носи отговорност за верността на предоставяните от Вас данни, не извършва
  проверки в този смисъл и не гарантира действителната самоличност на
  физическите лица, предоставили данните. Във всички случаи на съмнения от Ваша
  страна, на установена измама и/или злоупотреба, молим да ни уведомите
  незабавно. Вие се задължавате при предоставяне на каквато и да е информация в
  Сайта да не нарушавате правата на други лица във връзка със защитата на
  техните лични данни или други техни права.<br>
  <br>
  <b><span lang=EN-US>6</span></b><b>. Длъжностно лице по защита на данните</b><br>
  Въпроси и искания, свързани с упражняване на правата по защита на Вашите
  лични данни, можете да отправяте към Длъжностното лице по защита на данните
  на <em><span lang=EN-US style='font-style:normal'>J<span style='color:#333333'>obpreference.com</span></span></em>:<br>
  <br>
  Имейл: <a href="mailto:privacy@jobpreference.com">privacy@<span lang=EN-US>j</span><em><span
  lang=EN-US style='color:#333333;font-style:normal;text-decoration:none'>obpreference.com</span></em></a></p>
  <p class=MsoBodyText>&nbsp;</p>
  <p class=MsoBodyText><em><b><span lang=EN-US style='font-size:16.0pt;
  color:#333333;font-style:normal'>Политика за ползване на бисквити </span></b></em></p>
  <p class=MsoBodyText>&nbsp;</p>
  <p class=MsoBodyText><em><span lang=EN-US style='color:#333333;font-style:
  normal'>Тук можете да се запознаете с ползваните бисквити и други технологии
  за съхранение на информация във връзка с предоставяните услуги (“Услугите”) в
  сайта                                www.jobpreference.com и/или мобилните
  версии и приложения (“Сайта”). <br>
  <br>
  <br>
  </span></em><strong><span lang=EN-US style='color:#333333;font-weight:normal'>ПОЛИТИКА
  ЗА ПОЛЗВАНЕ НА БИСКВИТИ</span></strong><em><span lang=EN-US style='color:
  #333333;font-style:normal'><br>
  <br>
  Бисквитите са информация, съхранена във Вашия браузър (или мобилно
  приложение). Те се използват за съхранение на настройки и идентификатори,
  необходими за някои от предоставяните в Сайта услуги.<br>
  <br>
  Jobpreference.com прилага политика в съответствие с изисквания на ЕС.<br>
  <br>
  Бисквити се използват за следните цели:</span></em><em><b><span lang=EN-US
  style='font-size:16.0pt;color:#333333;font-style:normal'><br>
  <br>
  </span></b></em></p>
  <p class=MsoBodyText>&nbsp;</p>
  <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
   style='border-collapse:collapse'>
   <tr>
    <td width=213 valign=top style='width:159.7pt;border:solid black 1.0pt;
    border-right:none;padding:2.75pt 2.75pt 2.75pt 2.75pt'>
    <p class=->Вид </p>
    </td>
    <td width=213 valign=top style='width:159.7pt;border:solid black 1.0pt;
    border-right:none;padding:2.75pt 2.75pt 2.75pt 2.75pt'>
    <p class=->Срок на съхранение </p>
    </td>
    <td width=213 valign=top style='width:159.8pt;border:solid black 1.0pt;
    padding:2.75pt 2.75pt 2.75pt 2.75pt'>
    <p class=->Описание</p>
    </td>
   </tr>
   <tr>
    <td width=213 valign=top style='width:159.7pt;border-top:none;border-left:
    solid black 1.0pt;border-bottom:solid black 1.0pt;border-right:none;
    padding:2.75pt 2.75pt 2.75pt 2.75pt'>
    <p class=->Сесия и управление на навигацията (идентификатор на сесията,
    защита на сигурността, предишна стъпка за бутона “обратно” и др.) </p>
    </td>
    <td width=213 valign=top style='width:159.7pt;border-top:none;border-left:
    solid black 1.0pt;border-bottom:solid black 1.0pt;border-right:none;
    padding:2.75pt 2.75pt 2.75pt 2.75pt'>
    <p class=->Временно (за времето на престоя Ви в Сайта) </p>
    </td>
    <td width=213 valign=top style='width:159.8pt;border:solid black 1.0pt;
    border-top:none;padding:2.75pt 2.75pt 2.75pt 2.75pt'>
    <p class=-><em>Тези бисквити са технически необходими за вход, управление
    на навигацията и ползването на Сайта. Сайтът не може да функционира
    надеждно и нормално без тях.</em><i> </i></p>
    </td>
   </tr>
   <tr>
    <td width=213 valign=top style='width:159.7pt;border-top:none;border-left:
    solid black 1.0pt;border-bottom:solid black 1.0pt;border-right:none;
    padding:2.75pt 2.75pt 2.75pt 2.75pt'>
    <p class=->Сигурност (защита против влизане от неoторизирани устройства и
    др.) </p>
    </td>
    <td width=213 valign=top style='width:159.7pt;border-top:none;border-left:
    solid black 1.0pt;border-bottom:solid black 1.0pt;border-right:none;
    padding:2.75pt 2.75pt 2.75pt 2.75pt'>
    <p class=->До 6 /шест/ месеца от последното влизане </p>
    </td>
    <td width=213 valign=top style='width:159.8pt;border:solid black 1.0pt;
    border-top:none;padding:2.75pt 2.75pt 2.75pt 2.75pt'>
    <p class=-><em>Това са бисквити, които се ползват с цел подсигуряване
    защита на акаунта от неоторизиран достъп.</em> </p>
    </td>
   </tr>
   <tr>
    <td width=213 valign=top style='width:159.7pt;border-top:none;border-left:
    solid black 1.0pt;border-bottom:solid black 1.0pt;border-right:none;
    padding:2.75pt 2.75pt 2.75pt 2.75pt'>
    <p class=->Настройки (език на Сайта<span lang=EN-US>)</span> </p>
    </td>
    <td width=213 valign=top style='width:159.7pt;border-top:none;border-left:
    solid black 1.0pt;border-bottom:solid black 1.0pt;border-right:none;
    padding:2.75pt 2.75pt 2.75pt 2.75pt'>
    <p class=->До 6 /шест/ месеца от последното ползване </p>
    </td>
    <td width=213 valign=top style='width:159.8pt;border:solid black 1.0pt;
    border-top:none;padding:2.75pt 2.75pt 2.75pt 2.75pt'>
    <p class=-><em>Това са бисквити, които се използват за запазване на Вашите
    настройки;</em> </p>
    </td>
   </tr>
  </table>
  <p class=MsoBodyText>&nbsp;</p>
  
  </td>
 </tr>
</table>

<p class=MsoNormal>&nbsp;</p>

</div>

<div class=WordSection1>

<p class=MsoNormal>&nbsp;</p>

<p class=MsoNormal><b><span style='font-size:16.0pt'>ПОЛИТИКА ЗА ЗАЩИТА НА
ЛИЧНИТЕ ДАННИ НА ФИЗИЧЕСКИ ЛИЦА – КРАЙНИ ПОТРЕБИТЕЛИ</span></b> </p>

<p class=MsoNormal>&nbsp;</p>

<p class=MsoNormal>Моля, прочетете този документ внимателно. Той съдържа
Политиката за защита на личните данни на физически лица – крайни потребители
(“Политика”) и е неразделна част от Условията за ползване от физически лица –
крайни потребители („Условията“). В случай че не сте съгласни с Условията, няма
възможност да използвате Сайта. При промяна на Политиката, промените ще бъдат
публикувани тук. </p>

<p class=MsoNormal>&nbsp;</p>

<p class=MsoNormal><b>ПОЛИТИКА ЗА ЗАЩИТА НА ЛИЧНИТЕ ДАННИ НА ФИЗИЧЕСКИ ЛИЦА –
КРАЙНИ ПОТРЕБИТЕЛИ</b> </p>

<p class=MsoNormal>&nbsp;</p>

<div style='border:none;border-bottom:solid silver 1.0pt;padding:0cm 0cm 1.0pt 0cm'>

<p class=- style='line-height:150%;border:none;padding:0cm'>С цел предоставяне
на услугите (“Услуги”) в сайта <span lang=EN-US>www.jobpreference.com</span>
(“Сайта”), „<span style='color:#333333'>Сън Ленд Кепитъл</span>” ООД („<em><span
lang=EN-US style='font-style:normal'>J<span style='color:#333333'>obpreference.com</span></span></em>“/
„Ние“) с адрес на управление: ул. Кдп Георги Боев №4, Варна , ЕИК: <span
style='color:#333333'>206166575</span> обработва лични данни на физически лица
(„Субекти на данни“/„Вие“/„Вас“), в съответствие с настоящата Политика.<br>
При обработването на лични данни <em><span lang=EN-US style='font-style:normal'>J<span
style='color:#333333'>obpreference.com</span></span></em> спазва всички
приложими към дейността му нормативни актове по защита на личните данни,
включително, но не само, Регламент (ЕС) 2016/679 („Регламент“).<br>
<br>
Съгласно Регламента, лични данни е всяка информация, която се отнася до Вас и
чрез която можете да бъдете идентифицирани. Обработване на лични данни
(”Обработване”) е всяко действие или съвкупност от действия, които могат да се
извършат по отношение на личните данни с автоматични или други средства. </p>

<p class=- style='line-height:150%;border:none;padding:0cm'>&nbsp;</p>

<p class=- style='line-height:150%;border:none;padding:0cm'><b>1. Групи лица, за
които се обработват данни</b> </p>

<p class=MsoNormal style='line-height:150%;border:none;padding:0cm'>Във връзка
с предоставените Услуги <em><span lang=EN-US style='font-style:normal'>J<span
style='color:#333333'>obpreference.com</span></span></em> обработва информация
относно физически лица, ползватели на Сайта с регистрация.</p>

<p class=MsoNormal style='line-height:150%;border:none;padding:0cm'>&nbsp;</p>

<p class=MsoNormal style='line-height:150%;border:none;padding:0cm'><b>2.
Обработвани данни във връзка с предоставяне на Услугите</b><br>
<br>
<b>2.1. Информация, която Вие предоставяте</b><br>
Вие сами решавате дали и как да използвате Услугите, предоставяни чрез Сайта.
Във формите, чрез които се въвеждат лични данни, ясно обозначаваме
задължителния или доброволния характер на предоставяне на данните. Данните,
попълването на които е задължително, са такива, без които е невъзможно да
предоставим съответната Услуга или част от нея.</p>

<p class=MsoNormal style='line-height:150%;border:none;padding:0cm'><b><span
lang=EN-US>(</span></b><b>а) Регистрация на акаунт.</b> За да създадете
собствен акаунт, е необходимо да въведете: име, фамилия, имейл адрес и парола.
В допълнение се съхранява информация за извършването на регистрацията и
съгласяването с Условията (дата, час и IP адрес). </p>

<p class=MsoNormal style='line-height:150%;border:none;padding:0cm'><b>(б)
Данни, съхранявани във Вашия акаунт.</b> Вие имате възможност да създавате
и/или съхранявате във Вашия акаунт информация като снимка, автобиографии
(CV-та), други файлове и съответно да ги използвате при публикуване на
преференция и изпращате към Работодатели и други потребители по Ваша преценка.
Вие преценявате самостоятелно дали и какви данни, отнасящи се до Вас, да
съхранявате във Вашия акаунт в Сайта. </p>

<p class=MsoNormal style='line-height:150%;border:none;padding:0cm'><u>Снимка</u><br>
Включването на снимка във Вашия акаунт е изцяло по Ваш избор. Актуалната снимка
е видима за Работодателите или други потребители след отключване на ваша
преференция. Автобиографиите (CV-та) със снимка се запазват във вида, в който
са към момента на кандидатстване. </p>

<p class=MsoNormal style='line-height:150%;border:none;padding:0cm'><u>Създаване
на  автобиография (CV)</u><br>
Сайтът Ви дава възможност да създадете и съхраните една или повече
автобиографии (CV-та),  към  публикувани от вас преференции. Вие преценявате
самостоятелно дали и какви данни, отнасящи се до Вас, да включите в съответните
автобиографии (CV-та).<br>
При създаване на автобиография (CV) Вие имате възможност, по Ваша преценка, да
включите следната информация: име, имейл, телефон, образование и квалификации;
професионален опит и информация за заемани длъжности; умения; владеене на езици;
притежаване на свидетелство за управление на МПС и друга допълнителна
информация, попълнена от Вас.</p>

<p class=MsoNormal style='line-height:150%;border:none;padding:0cm'><u>Съхраняване
на Ваши файлове</u><br>
Вие имате възможност да съхранявате Ваши файлове , които да използвате при
публикуване на преференция. Вие преценявате самостоятелно дали и какви данни,
отнасящи се до Вас, да съхранявате във Вашия акаунт и да изпращате към
Работодателите или други потребители. Услугите на <em><span lang=EN-US
style='font-style:normal'>J<span style='color:#333333'>obpreference.com</span></span></em>
и предоставените в Сайта функционалности не са предназначени за съхранение и
обработване на специални категории данни по смисъла на чл. 9 и чл. 10 от
Регламента. Вие се задължавате да не съхранявате и да не изпращате чрез Сайта
към Работодатели или други потребители , под каквато и да е форма, информация,
съдържаща специални категории данни и/или чувствителни данни. <br>
<u>Чат с Работодатели и други потребители</u><br>
Работодатели и други потребители, които са отключили ваши преференции, имат
възможност да Ви изпращат съобщения, на които по Ваша преценка можете да
отговорите. При отговори от Ваша страна, Вашите съобщения съдържат свободен
текст, въведен от Вас. Вие преценявате самостоятелно дали и какви данни,
отнасящи се до Вас, да включите в тях. </p>

<p class=MsoNormal style='line-height:150%;border:none;padding:0cm'><u>Кореспонденция,
жалби и сигнали</u></p>

<p class=MsoNormal style='line-height:150%;border:none;padding:0cm'>С цел
разрешаване на подадени жалби, сигнали, спорове, запитвания, искания или други
въпроси, отправени в комуникация към Нас, постъпила чрез електронни форми в
Сайта, чрез обаждания към  <em><span lang=EN-US style='font-style:normal'>J<span
style='color:#333333'>obpreference.com</span></span></em>, чрез изпращане по
регулярна или електронна поща, Ние съхраняваме и обработваме тази информация,
както и резултата от това обработване.</p>

<p class=MsoNormal style='line-height:150%;border:none;padding:0cm'><u>Специални
категории (чувствителни) данни</u><br>
Услугите на <em><span lang=EN-US style='font-style:normal'>J<span
style='color:#333333'>obpreference.com</span></span></em> и предоставените в
Сайта функционалности не са предназначени за съхранение и обработване на
специални категории данни по смисъла на чл. 9 и чл. 10 от Регламента.<br>
Вие се задължавате да не съхранявате и да не изпращате чрез Сайта към
Работодатели и други потребители, под каквато и да е форма, информация,
съдържаща специални категории данни и чувствителни данни като:<br>
- данни, разкриващи Вашия расов или етнически произход, политически възгледи,
религиозни или философски убеждения, членство в синдикални организации, нито
Ваши генетични данни, биометрични данни, данни за здравословното Ви състояние,
за сексуалния Ви живот или сексуалната Ви ориентация, или данни, свързани с
Ваши присъди и нарушения.<br>
- документи за самоличност, както и ЕГН, номер на паспорт/лична карта и др.
подобни.<br>
<em><span lang=EN-US style='font-style:normal'>J<span style='color:#333333'>obpreference.com</span></span></em>
няма задължение и не следи за наличието на посочените тук данни в Сайта.
Предоставянето на такава информация е изцяло на риск и отговорност на
предоставящото я лице.<br>
<u>Други данни</u></p>

<p class=MsoNormal style='line-height:150%;border:none;padding:0cm'><em><span
lang=EN-US style='font-style:normal'>J<span style='color:#333333'>obpreference.com</span></span></em>
може да обработва и други данни, отнасящи се до Вас, в случаите, когато Вие
предоставите доброволно такива данни чрез попълване на съответни електронни
форми в Сайта, добавяне на предпочитания, настройки и др.<br>
<br>
</p>

<p class=MsoNormal style='line-height:150%;border:none;padding:0cm'><b>2.2.
Данни, свързани с процеса на ползване на Услугите</b></p>

<p class=MsoNormal style='line-height:150%;border:none;padding:0cm'>Вие давате
право на <span lang=EN-US>J<span style='color:#333333'>obpreference.com</span></span>
да направи достъпни (както за България, така и извън България) публикуваните от
Вас преференции и друго публично съдържание, като това е с цел предоставяне на
Услугите и <span lang=EN-US>J<span style='color:#333333'>obpreference.com</span></span>
не дължи възнаграждение.<br>
<br>
С публикуването на съдържание в Сайта Вие предоставяте на неговите
потребители-Работодатели и физически лица-крайни потребители право да
осъществяват достъп до него и да го ползват съгласно Условията за ползване и
съгласно закона.</p>

<p class=MsoNormal style='line-height:150%;border:none;padding:0cm'>&nbsp;</p>

<p class=MsoNormal style='line-height:150%;border:none;padding:0cm'>При
отключване вашата преференция става автоматично видима в акаунта на
Работодателя или физическото лице, което е заплатило. Отключени преференции са
видими  за период от 1 /един/ месец от датата на плащане. </p>

</div>

<p class=MsoNormal><u>Логове, свързани със сигурност, техническа поддръжка,
развитие и др.</u></p>

<p class=MsoNormal>В Сайта се използват логове със следните цели: </p>

<p class=MsoBodyText style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:35.35pt;text-indent:-14.15pt'><span style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span>За
подсигуряване на надеждното функциониране на Услугите и установяване на
технически проблеми; </p>

<p class=MsoBodyText style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:35.35pt;text-indent:-14.15pt'><span style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span>За
подсигуряване сигурността на Услугите и откриване на злонамерени действия; </p>

<p class=MsoBodyText style='margin-left:35.35pt;text-indent:-14.15pt'><em><span
style='font-family:Symbol;font-style:normal'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></em>Логове в случаи, в които това се изисква от закона (като
логове на електронни волеизявления). </p>

<div style='border:none;border-bottom:solid silver 1.0pt;padding:0cm 0cm 1.0pt 0cm'>

<p class=MsoBodyText style='line-height:150%;border:none;padding:0cm'><em>Сървърни
логове, логове на устройства за защита на сигурността (Web Application
Firewalls) и др. устройства, попадащи в тази категория.</em> Тези логове са
необходими за установяване на технически проблеми, откриване на злонамерени
действия и др. от посочените по-горе цели. Те се съхраняват за период до 1
/една/ година. Логовете могат да съдържат следната информация: дата и час, IP
адрес, URL, информация за браузър и устройство. В допълнение някои от
устройствата е възможно да използват технология за сигурност, базирана на
бисквити.<br>
<br>
<u>Бисквити</u></p>

<p class=MsoBodyText style='line-height:150%;border:none;padding:0cm'>За
функционирането на Сайта е необходимо ползването на бисквити. </p>

</div>

<p class=MsoNormal><b>3. Цели на обработване на лични данни</b><br>
<br>
<em><span lang=EN-US style='font-style:normal'>J<span style='color:#333333'>obpreference.com</span></span></em>
събира, използва и обработва информацията, описана по-горе, за целите,
предвидени в настоящата Политика и в Условията за ползване на Услугите, които
могат да бъдат: </p>

<p class=MsoBodyText style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:35.35pt;text-indent:-14.15pt'><span style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span>Цели,
необходими за сключването и изпълнението на договора за предоставяне на
Услугите между Вас и <em><span lang=EN-US style='font-style:normal'>J<span
style='color:#333333'>obpreference.com</span></span></em>; </p>

<p class=MsoBodyText style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:35.35pt;text-indent:-14.15pt'><span style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span>Цели,
необходими за защита и прилагане на легитимните интереси на други ползватели на
Услугите, трети лица и <em><span lang=EN-US style='font-style:normal'>J<span
style='color:#333333'>obpreference.com</span></span></em>; </p>

<p class=MsoBodyText style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:35.35pt;text-indent:-14.15pt'><span style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span>Цели,
за които Вие сте дали своето изрично съгласие; </p>

<p class=MsoBodyText style='margin-left:35.35pt;text-indent:-14.15pt'><span
style='font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>Цели, необходими за изпълнението на законови задължения на <em><span
lang=EN-US style='font-style:normal'>J<span style='color:#333333'>obpreference.com</span></span></em>.
</p>

<div style='border:none;border-bottom:solid silver 1.0pt;padding:0cm 0cm 1.0pt 0cm'>

<p class=MsoBodyText style='line-height:150%;border:none;padding:0cm'><b>Сключване
и изпълнение на договор</b></p>

<p class=MsoBodyText style='line-height:150%;border:none;padding:0cm'>Това са
целите, необходими за стъпките по сключването и изпълнението на договора между
Вас и <em><span lang=EN-US style='font-style:normal'>J<span style='color:#333333'>obpreference.com</span></span></em>,
включително необходимото за това обработване на данни при извършване на
регистрация, създаване на акаунт и дейностите по предоставяне на Услугите,
достъпни чрез Сайта. В допълнение тези цели включват комуникацията с Вас,
включително по електронна поща, необходима във връзка с предоставяне на
Услугите и/или уведомяването Ви за промени в предоставяните Услуги. За целта
може да е необходимо обработване на част или на всички посочени по-горе
категории данни.</p>

</div>

<p class=MsoNormal><b>Легитимен интерес</b></p>

<p class=MsoNormal>Целите на легитимните интереси на <em><span lang=EN-US
style='font-style:normal'>J<span style='color:#333333'>obpreference.com</span></span></em>
или на трети лица (други ползватели на Сайта (Кандидати, Работодатели,
Представители на Работодатели, лица за административен контакт), служители на <em><span
lang=EN-US style='font-style:normal'>J<span style='color:#333333'>obpreference.com</span></span></em>
и негови подизпълнители или доставчици на услуги), включват: </p>

<p class=MsoBodyText style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:35.35pt;text-indent:-14.15pt'><span style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span>Осигуряване
на нормалното функциониране и използване на Сайта от Ваша страна и от страна на
другите потребители, поддръжка и администриране на Услугите, разрешаване на
възникнали спорове, установяване и предотвратяване на злонамерени действия; </p>

<p class=MsoBodyText style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:35.35pt;text-indent:-14.15pt'><span style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span>Откриване
и разрешаване на технически или проблеми с функционалността; </p>

<p class=MsoBodyText style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:35.35pt;text-indent:-14.15pt'><span style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span>Осъществяване
на комуникация с Вас, включително по електронен път по важни въпроси, свързани
с Услугите; </p>

<p class=MsoBodyText style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:35.35pt;text-indent:-14.15pt'><span style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span>Приемане
и обработване на получени сигнали, жалби, искания и друга кореспонденция; </p>

<p class=MsoBodyText style='margin-left:35.35pt;text-indent:-14.15pt'><span
style='font-family:Symbol'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>Осъществяване и закрила на правата и законните интереси на <em><span
lang=EN-US style='font-style:normal'>J<span style='color:#333333'>obpreference.com</span></span></em>,
включително и по съдебен ред, и оказване на съдействие при осъществяване и
закрила на правата и законните интереси на другите ползватели на сайта и/или
засегнати трети страни. </p>

<div style='border:none;border-bottom:solid silver 1.0pt;padding:0cm 0cm 1.0pt 0cm'>

<p class=MsoBodyText style='line-height:150%;border:none;padding:0cm'>За тези
цели е възможно да е необходимо обработване на част или на всички посочени
по-горе категории.</p>

</div>

<p class=MsoNormal><b>Законови задължения</b></p>

<p class=MsoNormal>Това са цели като: </p>

<p class=MsoBodyText style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:35.35pt;text-indent:-14.15pt'><span style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span>Дейности
по изпълнение на предвидени в закона задължения за запазване или предоставяне
на информация при постъпване на съответно разпореждане от компетентни държавни
или съдебни органи, при осигуряване на възможност за осъществяване на
контролните правомощия на компетентните държавни органи, при изпълнение на
законовите задължения на <em><span lang=EN-US style='font-style:normal'>J<span
style='color:#333333'>obpreference.com</span></span></em> да Ви уведомява за
различни обстоятелства, свързани с Вашите права, с предоставяните Услуги или
със защитата на данните Ви и др. под.; </p>

<div style='border:none;border-bottom:solid silver 1.0pt;padding:0cm 0cm 1.0pt 0cm'>

<p class=MsoBodyText style='line-height:150%;border:none;padding:0cm'>За тези
цели е възможно да е необходимо обработване на част или на всички посочени
по-горе категории.<br>
<br>
<b>Изрично съгласие</b></p>

<p class=MsoBodyText style='line-height:150%;border:none;padding:0cm'>Ваши
данни могат да бъдат обработвани на базата на Ваше изрично съгласие, като обработването
в този случай е конкретно и в степента и обхвата, предвидени в съответното
съгласие. </p>

<p class=MsoBodyText style='line-height:150%;border:none;padding:0cm'><b>4.
Срок на съхранение</b> </p>

<p class=MsoBodyText style='line-height:150%;border:none;padding:0cm'><span
style='color:black'>Тук можете да намерите информация за максималните срокове
на съхранение на различните видове данни. При съхранението на данни, Ние прилагаме
генералния принцип за съхранение на данни в минимален обем и за срок не
по-дълъг от необходимото за предоставяне на Услугите, подсигуряване на тяхната
сигурност и надеждност и изискванията на закона.</span> </p>

<p class=MsoBodyText style='line-height:150%;border:none;padding:0cm'>&nbsp;</p>

</div>

<table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
 style='margin-left:-.4pt;border-collapse:collapse'>
 <tr>
  <td width=321 valign=top style='width:240.95pt;border:solid black 1.0pt;
  border-right:none;padding:2.75pt 2.75pt 2.75pt 2.75pt'>
  <p class=->Вид данни </p>
  </td>
  <td width=321 valign=top style='width:240.95pt;border:solid black 1.0pt;
  padding:2.75pt 2.75pt 2.75pt 2.75pt'>
  <p class=->Период на съхранение </p>
  </td>
 </tr>
 <tr>
  <td width=321 valign=top style='width:240.95pt;border-top:none;border-left:
  solid black 1.0pt;border-bottom:solid black 1.0pt;border-right:none;
  padding:2.75pt 2.75pt 2.75pt 2.75pt'>
  <p class=->Данни във връзка с регистрация на акаунт, информация за приемане
  на Условията (дата, час, IP адрес и др.); договори, декларации и др.</p>
  </td>
  <td width=321 valign=top style='width:240.95pt;border:solid black 1.0pt;
  border-top:none;padding:2.75pt 2.75pt 2.75pt 2.75pt'>
  <p class=->За периода на поддържане на акаунта в Сайта и до 1 /една/ година
  от прекратяване на регистрацията </p>
  </td>
 </tr>
 <tr>
  <td width=321 valign=top style='width:240.95pt;border-top:none;border-left:
  solid black 1.0pt;border-bottom:solid black 1.0pt;border-right:none;
  padding:2.75pt 2.75pt 2.75pt 2.75pt'>
  <p class=->&nbsp;</p>
  </td>
  <td width=321 valign=top style='width:240.95pt;border:solid black 1.0pt;
  border-top:none;padding:2.75pt 2.75pt 2.75pt 2.75pt'>
  <p class=->&nbsp;</p>
  </td>
 </tr>
 <tr>
  <td width=321 valign=top style='width:240.95pt;border-top:none;border-left:
  solid black 1.0pt;border-bottom:solid black 1.0pt;border-right:none;
  padding:2.75pt 2.75pt 2.75pt 2.75pt'>
  <p class=->Автобиографии, файлове, снимки или други документи и информация,
  съхранени във Вашия акаунт </p>
  </td>
  <td width=321 valign=top style='width:240.95pt;border:solid black 1.0pt;
  border-top:none;padding:2.75pt 2.75pt 2.75pt 2.75pt'>
  <p class=->До изтриването им от Вас или до прекратяване на регистрацията Ви </p>
  </td>
 </tr>
 <tr>
  <td width=321 valign=top style='width:240.95pt;border-top:none;border-left:
  solid black 1.0pt;border-bottom:solid black 1.0pt;border-right:none;
  padding:2.75pt 2.75pt 2.75pt 2.75pt'>
  <p class=->Системни логове. Логове, свързани със сигурност, техническа
  поддръжка, развитие и др. (могат да съдържат информация като: дата и час, IP
  адрес, URL, информация за версия на браузър и устройство) </p>
  </td>
  <td width=321 valign=top style='width:240.95pt;border:solid black 1.0pt;
  border-top:none;padding:2.75pt 2.75pt 2.75pt 2.75pt'>
  <p class=->За период до 1 /една/ година </p>
  </td>
 </tr>
 <tr>
  <td width=321 valign=top style='width:240.95pt;border-top:none;border-left:
  solid black 1.0pt;border-bottom:solid black 1.0pt;border-right:none;
  padding:2.75pt 2.75pt 2.75pt 2.75pt'>
  <p class=->Бисквити </p>
  </td>
  <td width=321 valign=top style='width:240.95pt;border:solid black 1.0pt;
  border-top:none;padding:2.75pt 2.75pt 2.75pt 2.75pt'>
  <p class=->За описание на ползваните бисквити виж “Политика за ползване на
  Бисквити”</p>
  </td>
 </tr>
</table>

<div style='border:none;border-bottom:solid silver 1.0pt;padding:0cm 0cm 1.0pt 0cm'>

<p class=- style='margin-bottom:6.0pt;line-height:150%;border:none;padding:
0cm'>&nbsp;</p>

<p class=MsoBodyText style='line-height:150%;border:none;padding:0cm'>В случай
на възникнал правен спор или производство, налагащо запазване на данни и/или
искане от компетентен държавен орган, е възможно запазване на данни за по-дълъг
от посочените срокове до окончателно приключване на възникналия спор или
производство пред всички инстанции. Посочените срокове могат да бъдат
променяни, в случай че бъде установено различно изискване за запазване на
информацията съгласно действащото законодателство.</p>

<p class=MsoBodyText style='line-height:150%;border:none;padding:0cm'><b><span
style='color:black;background:transparent'>Резервираност (Backup).</span></b><span
style='color:black;background:transparent'> С цел подсигуряване на надеждността на
Услугите и предпазване от загуба на данни по технически причини, в Сайта се
прилага политика на резервираност на данните. Максималният период за
актуализация (изтриване на данни) от всички резервни копия е 30 дни. </span></p>

<p class=MsoBodyText style='line-height:150%;border:none;padding:0cm'><b>5.
ПРЕДОСТАВЯНЕ НА ИНФОРМАЦИЯ</b><br>
<br>
<em><span lang=EN-US style='font-style:normal'>J<span style='color:#333333'>obpreference.com</span></span></em>
не предоставя Ваши лични данни на трети лица, по никакъв друг начин освен в описаните
в тази Политика, Условията и предвиденитe в закона случаи. </p>

<p class=MsoBodyText style='line-height:150%;border:none;padding:0cm'><b>5.1.
Доставчици и подизпълнители</b><br>
<em><span lang=EN-US style='font-style:normal'>J<span style='color:#333333'>obpreference.com</span></span></em>
използва подизпълнители и доставчици на услуги като специализирани дата
центрове за надеждна и сигурна колокация на сървърното и мрежово оборудванe и
др. </p>

<p class=MsoBodyText style='line-height:150%;border:none;padding:0cm'><b>5.2.
Трети лица</b><br>
Ваши лични данни могат да бъдат предоставени на трети лица само в следните
случаи: когато това е предвидено в закон; ако това бъде поискано по надлежен
начин от компетентен държавен или съдебен орган; когато сме получили Вашето
изрично съгласие за това; когато това се налага за защитата на права и законни
интереси на <em><span lang=EN-US style='font-style:normal'>J<span
style='color:#333333'>obpreference.com</span></span></em> и/или други
потребители на Услугите. </p>

<p class=MsoBodyText style='line-height:150%;border:none;padding:0cm'><b>5.3.
Промяна на собственост</b><br>
В случай на сливане, придобиване или продажба на активи, засягащи обработването
на лични данни, Вие ще бъдете уведомени предварително. </p>

<p class=MsoBodyText style='line-height:150%;border:none;padding:0cm'><b>6.
Права по отношение на личните данни</b><br>
<br>
Регламентът предвижда следните права:<br>
<br>
<b>6.1. Право на информираност.</b><br>
Настоящата Политика има за цел да Ви информира подробно за обработването на
Вашите лични данни във връзка с предоставяните Услуги.<br>
<br>
<b>6.2. Право на достъп.</b> Вие имате право да получите потвърждение дали се
обработват Ваши лични данни, достъп до тях и информация относно обработването
им и правата Ви във връзка с това. </p>

<p class=MsoBodyText style='line-height:150%;border:none;padding:0cm'><b>6.3.
Право на коригиране.</b> Вие имате право да коригирате Ваши лични данни, в
случай че те са непълни или неточни. </p>

<p class=MsoBodyText style='line-height:150%;border:none;padding:0cm'><b>6.4.
Право на изтриване.</b> Вие имате право да поискате изтриване на данни, освен в
случаите, в които за тяхното обработване е налице съществено основание и/или
законово задължение. </p>

<p class=MsoBodyText style='line-height:150%;border:none;padding:0cm'><b>7.
ТОЧНОСТ НА ИНФОРМАЦИЯТА</b><br>
<em><span lang=EN-US style='font-style:normal'>J<span style='color:#333333'>obpreference.com</span></span></em>
не носи отговорност за верността на предоставяните от Вас данни, не извършва
проверки в този смисъл и не гарантира действителната самоличност на физическите
лица, предоставили данните. Във всички случаи на съмнения от Ваша страна, на
установена измама и/или злоупотреба, молим да ни уведомите незабавно. Вие се
задължавате при предоставяне на каквато и да е информация в Сайта да не
нарушавате правата на други лица във връзка със защитата на техните лични данни
или други техни права. </p>

<p class=MsoBodyText style='line-height:150%;border:none;padding:0cm'><b><span
lang=EN-US>8.</span></b><b><span lang=EN-US>Длъжностно лице по защита на
данните</span></b><span lang=EN-US><br>
Въпроси и искания, свързани с упражняване на правата по защита на Вашите лични
данни, можете да отправяте към Длъжностното лице по защита на данните на <em><span
style='font-style:normal'>J<span style='color:#333333'>obpreference.com</span></span></em>:<br>
<br>
Имейл: </span><a href="mailto:privacy@jobpreference.com">privacy@<span
lang=EN-US>j</span><em><span lang=EN-US style='color:#333333;font-style:normal;
text-decoration:none'>obpreference.com</span></em></a></p>

</div>

<p class=MsoBodyText><em><b><span lang=EN-US style='font-size:16.0pt;
color:#333333;font-style:normal'>Политика за ползване на бисквити </span></b></em></p>

<p class=MsoBodyText>&nbsp;</p>

<div style='border:none;border-bottom:solid silver 1.0pt;padding:0cm 0cm 1.0pt 0cm'>

<p class=MsoBodyText style='line-height:150%;border:none;padding:0cm'><em><span
lang=EN-US style='color:#333333;font-style:normal'>Тук можете да се запознаете
с ползваните бисквити и други технологии за съхранение на информация във връзка
с предоставяните услуги (“Услугите”) в сайта                               
www.jobpreference.com и/или мобилните версии и приложения (“Сайта”). <br>
<br>
</span></em><strong><span lang=EN-US style='color:#333333'>ПОЛИТИКА ЗА ПОЛЗВАНЕ
НА БИСКВИТИ</span></strong><em><span lang=EN-US style='color:#333333;
font-style:normal'><br>
<br>
Бисквитите са информация, съхранена във Вашия браузър (или мобилно приложение).
Те се използват за съхранение на настройки и идентификатори, необходими за
някои от предоставяните в Сайта услуги.<br>
<br>
Jobpreference.com прилага политика в съответствие с изисквания на ЕС.<br>
Бисквити се използват за следните цели:</span></em></p>

</div>

<table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
 style='margin-left:-.4pt;border-collapse:collapse'>
 <tr>
  <td width=214 valign=top style='width:160.6pt;border:solid black 1.0pt;
  border-right:none;padding:2.75pt 2.75pt 2.75pt 2.75pt'>
  <p class=->Вид</p>
  </td>
  <td width=214 valign=top style='width:160.65pt;border:solid black 1.0pt;
  border-right:none;padding:2.75pt 2.75pt 2.75pt 2.75pt'>
  <p class=->Срок на съхранение</p>
  </td>
  <td width=214 valign=top style='width:160.65pt;border:solid black 1.0pt;
  padding:2.75pt 2.75pt 2.75pt 2.75pt'>
  <p class=->Описание</p>
  </td>
 </tr>
 <tr>
  <td width=214 valign=top style='width:160.6pt;border-top:none;border-left:
  solid black 1.0pt;border-bottom:solid black 1.0pt;border-right:none;
  padding:2.75pt 2.75pt 2.75pt 2.75pt'>
  <p class=->Сесия и управление на навигацията (идентификатор на сесията,
  защита на сигурността, предишна стъпка за бутона “обратно” и др.) </p>
  </td>
  <td width=214 valign=top style='width:160.65pt;border-top:none;border-left:
  solid black 1.0pt;border-bottom:solid black 1.0pt;border-right:none;
  padding:2.75pt 2.75pt 2.75pt 2.75pt'>
  <p class=->Временно (за времето на престоя Ви в Сайта) </p>
  </td>
  <td width=214 valign=top style='width:160.65pt;border:solid black 1.0pt;
  border-top:none;padding:2.75pt 2.75pt 2.75pt 2.75pt'>
  <p class=-><em>Тези бисквити са технически необходими за вход, управление на
  навигацията и ползването на Сайта. Сайтът не може да функционира надеждно и
  нормално без тях.</em><i> </i></p>
  </td>
 </tr>
 <tr>
  <td width=214 valign=top style='width:160.6pt;border-top:none;border-left:
  solid black 1.0pt;border-bottom:solid black 1.0pt;border-right:none;
  padding:2.75pt 2.75pt 2.75pt 2.75pt'>
  <p class=->Сигурност (защита против влизане от неoторизирани устройства и
  др.) </p>
  </td>
  <td width=214 valign=top style='width:160.65pt;border-top:none;border-left:
  solid black 1.0pt;border-bottom:solid black 1.0pt;border-right:none;
  padding:2.75pt 2.75pt 2.75pt 2.75pt'>
  <p class=->До 6 /шест/ месеца от последното влизане </p>
  </td>
  <td width=214 valign=top style='width:160.65pt;border:solid black 1.0pt;
  border-top:none;padding:2.75pt 2.75pt 2.75pt 2.75pt'>
  <p class=-><em>Това са бисквити, които се ползват с цел подсигуряване защита
  на акаунта от неоторизиран достъп.</em></p>
  </td>
 </tr>
 <tr>
  <td width=214 valign=top style='width:160.6pt;border-top:none;border-left:
  solid black 1.0pt;border-bottom:solid black 1.0pt;border-right:none;
  padding:2.75pt 2.75pt 2.75pt 2.75pt'>
  <p class=->Настройки (език на Сайта<span lang=EN-US>)</span></p>
  </td>
  <td width=214 valign=top style='width:160.65pt;border-top:none;border-left:
  solid black 1.0pt;border-bottom:solid black 1.0pt;border-right:none;
  padding:2.75pt 2.75pt 2.75pt 2.75pt'>
  <p class=->До 6 /шест/ месеца от последното ползване </p>
  </td>
  <td width=214 valign=top style='width:160.65pt;border:solid black 1.0pt;
  border-top:none;padding:2.75pt 2.75pt 2.75pt 2.75pt'>
  <p class=-><em>Това са бисквити, които се използват за запазване на Вашите
  настройки;</em></p>
  </td>
 </tr>
</table>



</div>


</body>

</html>
`;
