import * as SalaryRangeHelper from "./SalaryRangeHelperJo";

export const originalState = {
  salaryRangeType: SalaryRangeHelper.MONTHLY_SALARY_RANGE_TYPE,
  monthlySalaryRange: [0, 50000],
  monthlySliderDisabled: false,
  hourlySalaryRange: [0, 500],
  hourlySliderDisabled: true,
  currencyOptions: SalaryRangeHelper.currencyOptions,
  selectedCurrency: "",
};

export function reducer(draft, action) {
  switch (action.type) {
    case "setSalaryRangeType":
      draft.salaryRangeType = action.value;
      return;
    case "setSalaryRange":
      if (draft.salaryRangeType == 0) {
        draft.monthlySalaryRange = action.value;
      } else {
        draft.hourlySalaryRange = action.value;
      }
      return;
    // case "disableSlider":
    //   draft.monthlySliderDisabled = !draft.monthlySliderDisabled;
    //   draft.hourlySliderDisabled = !draft.hourlySliderDisabled;
    // return;
    case "setCurrency":
      draft.selectedCurrency = action.value;
      return;
  }
}
