import React, { useContext } from "react";
import { Link } from "react-router-dom";
import StateContext from "../../Context/StateContext";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import DreamAvatar from "../../ui_components/DreamAvatarComponent/DreamAvatarComponent";
import * as Constants from "../../helpers/Constants";
import * as Colors from "../../styles/01.settings/01-settings-colors.module.scss";
import useMediaQuery from "@material-ui/core/useMediaQuery";

export default function AppBarLogo(props) {
  const appContext = useContext(StateContext);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));
  const matchesXSdown = useMediaQuery(theme.breakpoints.down("xs"));

  const useStyles = makeStyles((theme) => ({
    appBarLogoWrapper: {
      display: "flex",
      flexDirection: "row nowrap",
      justifyContent: "space-between",
      alignItems: "center",
      paddingRight: 3,
      paddingLeft: appContext.appState.loggedIn && !matchesXSdown ? 10 : 0,
    },
    link: {
      display: "flex",
      justifyContent: "center",
      fontSize: matchesXSdown ? 16 : 20,
      marginLeft: 10,
      color: Colors.white,
    },
    allWrapper: {
      display: "flex",
      jusitfyContent: "center",
      alignItems: "center",
      paddingLeft: props.burgerHidden && !matchesXSdown ? 10 : 0,

      height: "100%",
    },
    // Avatar
    small: {
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
    large: {
      width: theme.spacing(5),
      height: theme.spacing(5),
    },
  }));

  const classes = useStyles();
  return (
    <div className={classes.allWrapper}>
      {appContext.appState.loggedIn && !matches && !props.burgerHidden ? (
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={props.toggleDrawer}
          anchor={props.anchor}
          edge="start"
        >
          <MenuIcon />
        </IconButton>
      ) : (
        ""
      )}

      <div className={classes.appBarLogoWrapper}>
        <DreamAvatar
          className={matchesXSdown ? classes.small : classes.large}
          imageSource={`${process.env.PUBLIC_URL}/logo_white.png`}
        />
        <p>
          <Link className={classes.link} to="/">
            {Constants.SITE_NAME}
            <span style={{ fontSize: 10, color: Colors.hpTitleOrange }}>
              {Constants.BETA}
            </span>
          </Link>
        </p>
      </div>
    </div>
  );
}
