import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import * as AppRoutes from "../../AppComponent/AppRoutes";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { useImmerReducer } from "use-immer";
import { useTranslation } from "react-i18next";
import * as Constants from "../../helpers/Constants";
import Axios from "axios";
import * as BcReducer from "./BuyCreditsPageReducer";
import * as BcHelper from "./BuyCreditsPageHelper";
import DreamPage from "../../ui_components/DreamPage";
import StateContext from "../../Context/StateContext";
import DispatchContext from "../../Context/DispatchContext";
import DreamRadioButtonsComponent from "../../ui_components/DreamRadioButtons/DreamRadioButtonsComponent";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Paper from "@material-ui/core/Paper";
import SplitCard from "../../ui_components/SplitCard/SplitCard";
import BcDispatchContext from "./Context/DispatchContext";
import BcStateContext from "./Context/StateContext";
import { useStripe } from "@stripe/react-stripe-js";

const pageTitle = "bc.buyCredits";

const useStyles = makeStyles((theme) => ({
  gridWrapper: {
    maxWidth: 350,
  },
  formControlGrid: {
    display: "flex",
    justifyContent: "center",
  },
  formControl: {
    marginBottom: theme.spacing(2),
    width: "80%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  radioButtonsGrid: {
    display: "flex",
    justifyContent: "center",
    height: 230,
    border: "1px solid rgba(0, 0, 0, 0.1)",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: 10,
    marginBottom: 20,
  },
}));

const BuyCredits = () => {
  const classes = useStyles();
  const [t] = useTranslation(Constants.TRANSLATION_COMMON);
  const appDispatch = useContext(DispatchContext);
  const appContext = useContext(StateContext);
  const history = useHistory();

  const [state, dispatch] = useImmerReducer(
    BcReducer.reducer,
    BcReducer.originalState
  );
  const stripe = useStripe();

  // Handle payment
  async function handlePayment(data, dispatch, appDispatch, Axios) {
    let errMsgs;
    let authenticated = true;
    appDispatch({ type: "showBackdrop", value: true });

    try {
      let formData = new FormData();

      formData.append("method_id", data.method_id);

      formData.append("currency", data.currency);

      formData.append("volume", 1);

      formData.append("product", data.packageType);

      const response = await Axios.post(`/transactions`, formData, {
        headers: {
          "content-type": `application/json;`,
        },
      }).catch(function (error) {
        // Errors to be sent to snackbar
        errMsgs = "basic.serverError";
        if (
          error.response &&
          error.response.status &&
          error.response.status == Constants.ERROR_RESPONSE_STATUS
        ) {
          authenticated = false;
          errMsgs = "basic.unauthenticated";
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }

        throw errMsgs;
      });

      const responseData = response.data.data;

      let errsArr = [];
      if (!responseData.success && responseData.messages.length) {
        responseData.messages.forEach((msg) => {
          errsArr.push(`serverErrors.${msg.code}`);
        });
        dispatch({ type: "setIsProcessing", value: false });
        throw errsArr;
      }

      handleServerResponse(responseData);
      // 3D
      // if (responseData.success && responseData.requires_action) {
      //   // stripe.handleCardAction(responseData.client_secret);
      // }
    } catch (error) {
      appDispatch({ type: "showBackdrop", value: false });
      appDispatch({
        type: "showMsg",
        value: { status: Constants.SNACKBAR_ERROR, msg: error },
      });
      dispatch({ type: "disableSaveButton", value: false });

      if (!authenticated) {
        appDispatch({
          type: "logout",
        });

        appDispatch({ type: "openLoginModal", value: true });
      } else {
        dispatch({ type: "disableSaveButton", value: false });
      }
    }
  }

  // 3D
  function handleServerResponse(response) {
    if (response != undefined) {
      if (!response.success) {
        // Show error from server on payment form
        appDispatch({ type: "showBackdrop", value: false });
        appDispatch({
          type: "showMsg",
          value: {
            status: Constants.SNACKBAR_ERROR,
            msg: response.messages.message,
          },
        });
      } else if (response.requires_action) {
        // Use Stripe.js to handle required card action
        stripe
          .handleCardAction(response.client_secret)
          .then(handleStripeJsResult);
      } else {
        // Show success message
        appDispatch({ type: "showBackdrop", value: false });
        appDispatch({
          type: "showMsg",
          value: { status: Constants.SNACKBAR_SUCCESS, msg: "bc.successful" },
        });
        dispatch({ type: "setTransactionSuccessful" });
        dispatch({ type: "setIsProcessing", value: false });
      }
    }
  }

  async function handleStripeJsResult(result) {
    if (result.error) {
      // Show error in payment form
      appDispatch({ type: "showBackdrop", value: false });
      appDispatch({
        type: "showMsg",
        value: { status: Constants.SNACKBAR_ERROR, msg: result.error.message },
      });
      dispatch({ type: "setIsProcessing", value: false });
    } else {
      // The card action has been handled
      // The PaymentIntent can be confirmed again on the server
      // fetch(`${Constants.BASE_URL}transactions`, {
      //   method: "POST",
      //   headers: { "Content-Type": "application/json" },
      //   body: JSON.stringify({ payment_intent_id: result.paymentIntent.id }),
      // })
      //   .then(function (confirmResult) {
      //     return confirmResult.json();
      //   })
      //   .then(handleServerResponse);

      await Axios.post(
        `/transactions`,
        JSON.stringify({ payment_intent_id: result.paymentIntent.id }),
        {
          headers: {
            "content-type": `application/json;`,
          },
        }
      )
        .then(function (confirmResult) {
          if (confirmResult != undefined) {
            console.log(confirmResult);
            if (!confirmResult.data.data.success) {
              // Show error from server on payment form
              appDispatch({ type: "showBackdrop", value: false });
              appDispatch({
                type: "showMsg",
                value: {
                  status: Constants.SNACKBAR_ERROR,
                  msg: confirmResult.data.data.messages.message,
                },
              });
            } else {
              // Show success message
              appDispatch({ type: "showBackdrop", value: false });
              appDispatch({
                type: "showMsg",
                value: {
                  status: Constants.SNACKBAR_SUCCESS,
                  msg: "bc.successful",
                },
              });
              dispatch({ type: "setTransactionSuccessful" });
              dispatch({ type: "setIsProcessing", value: false });
            }
          }
        })
        .then(handleServerResponse);
    }
  }

  function currencyChanged(event) {
    dispatch({
      type: "currencyChanged",
      value: event.target.value,
    });

    dispatch({
      type: "radioButtonsChanged",
      value: BcHelper.formatPackagesButtons(
        event.target.value,
        state.packages,
        t
      ),
    });
  }

  function packageChanged(value) {
    dispatch({
      type: "packageChanged",
      value: parseInt(value),
    });
    let price = state.radioButtons.filter((x) => x.value == parseInt(value));
    dispatch({
      type: "sumChanged",
      value: BcHelper.calculateSum(state.formData.quantity, price[0].price),
    });
  }

  useEffect(() => {
    const ourRequest = Axios.CancelToken.source();
    BcHelper.getPackages(Axios, ourRequest, appDispatch, dispatch, t);

    return () => {
      ourRequest.cancel();
    };
  }, []);

  useEffect(() => {
    const ourRequest = Axios.CancelToken.source();
    BcHelper.getBalance(Axios, ourRequest, appDispatch, dispatch);
    return () => {
      ourRequest.cancel();
    };
  }, []);

  useEffect(() => {
    if (state.packagesDataSuccessfullyLoaded) {
      let price = state.radioButtons.filter(
        (x) => x.value == state.formData.packageType
      );
      dispatch({
        type: "sumChanged",
        value: BcHelper.calculateSum(state.formData.quantity, price[0].price),
      });
    }
  }, [state.formData.currency]);

  useEffect(() => {
    if (state.packagesDataSuccessfullyLoaded) {
      dispatch({
        type: "languageChanged",
        value: {
          currency: state.formData.currency,
          packages: state.packages,
          t: t,
        },
      });
    }
  }, [appContext.appState.lang]);

  useEffect(() => {
    if (state.succesfullyGotMethodIdCounter > 0) {
      handlePayment(state.formData, dispatch, appDispatch, Axios, stripe);
    }
  }, [state.succesfullyGotMethodIdCounter]);

  useEffect(() => {
    if (state.isTransactionSuccessful) {
      history.push(AppRoutes.ROUTE_WALLET);
    }
  }, [state.isTransactionSuccessful]);

  return (
    <BcStateContext.Provider value={state}>
      <BcDispatchContext.Provider value={dispatch}>
        <DreamPage title={t(pageTitle)} backButton backButtonTo={"/wallet"}>
          <Grid className={classes.gridWrapper} container>
            <Grid item xs={12} className={classes.formControlGrid}>
              <FormControl className={classes.formControl}>
                <InputLabel id="bc.currency">{t("bc.currency")}</InputLabel>
                <Select
                  fullWidth
                  labelId="bc.currency"
                  id="bc.currency"
                  value={state.formData.currency}
                  onChange={currencyChanged}
                >
                  {state.currencies.map((currency, index) => (
                    <MenuItem key={currency} value={currency}>
                      {currency}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              component={Paper}
              className={classes.radioButtonsGrid}
            >
              <DreamRadioButtonsComponent
                specialLabel
                formControlStyle={{ width: "90%" }}
                radioButtonsFormControlLabel={{
                  marginRight: 0,
                  paddingTop: 8,
                  paddingBottom: 7,
                }}
                radioButtonsStyle={{ fontWeight: "bold" }}
                name={"bc.package"}
                value={state.formData.packageType}
                data={state.radioButtons}
                changeHandler={packageChanged}
              />
            </Grid>
            <Grid item xs={12}>
              <SplitCard />
            </Grid>
          </Grid>
          <br></br>
        </DreamPage>
      </BcDispatchContext.Provider>
    </BcStateContext.Provider>
  );
};

export default BuyCredits;
