import FormValidator from "../../helpers/FormValidator";
import * as Constants from "../../helpers/Constants";
import * as ProfileHelper from "./ProfileHelper";
import localeEnUS from "date-fns/locale/en-US";

export const allInputs = {
  firstname: {
    value: "",
    isValid: true,
    errorMessage: "",
  },
  lastname: {
    value: "",
    isValid: true,
    errorMessage: "",
  },
  birthDate: {
    value: null,
    isValid: true,
    errorMessage: "",
  },
  phone: {
    value: "",
    isValid: true,
    errorMessage: "",
  },
};

export const originalState = {
  fields: { ...allInputs },
  locale: localeEnUS,
  hasValidationErrs: false,
  formSubmittedCount: 0,
  uploadedFiles: {},
  initalFileUploadUrl: "",
  initialFileUploads: [],
  loadedDataBe: false,
  formSuccessfullySubmittedCount: 0,
};

export function reducer(draft, action) {
  switch (action.type) {
    case "firstnameChange":
      draft.fields.firstname.value = action.value.value;
      draft.fields.firstname.errorMessage = FormValidator.validateField(
        action.value.value,
        { minLength: 3, maxLength: 50, required: true },
        action.value.t
      );
      draft.fields.firstname.isValid = !Boolean(
        draft.fields.firstname.errorMessage
      );

      // Check if Form has validation errors
      draft.hasValidationErrs = FormValidator.hasFormValidationErrors(
        draft,
        allInputs
      );
      return;

    case "lastnameChange":
      draft.fields.lastname.value = action.value.value;
      draft.fields.lastname.errorMessage = FormValidator.validateField(
        action.value.value,
        { minLength: 3, maxLength: 50, required: true },
        action.value.t
      );
      draft.fields.lastname.isValid = !Boolean(
        draft.fields.lastname.errorMessage
      );

      // Check if Form has validation errors
      draft.hasValidationErrs = FormValidator.hasFormValidationErrors(
        draft,
        allInputs
      );
      return;

    case "birthDateChange":
      draft.fields.birthDate.value = action.value.value
        ? new Date(action.value.value)
        : null;
      draft.fields.birthDate.isValid =
        draft.fields.birthDate.value < new Date() &&
        ProfileHelper.dobValidate(action.value.value);
      draft.fields.birthDate.errorMessage = draft.fields.birthDate.isValid
        ? ""
        : action.value.t("validationError.requiredField");
      // Check if Form has validation errors
      draft.hasValidationErrs = FormValidator.hasFormValidationErrors(
        draft,
        allInputs
      );
      return;

    case "phoneChange":
      draft.fields.phone.value = action.value.value;
      draft.fields.phone.errorMessage = FormValidator.validateField(
        action.value.value,
        { phone: true },
        action.value.t
      );
      draft.fields.phone.isValid = !Boolean(draft.fields.phone.errorMessage);

      // Check if Form has validation errors
      draft.hasValidationErrs = FormValidator.hasFormValidationErrors(
        draft,
        allInputs
      );
      return;
    case "userDataLoaded":
      const userData = action.value;
      draft.fields.firstname.value =
        userData.fname === null ? "" : userData.fname;
      draft.fields.lastname.value =
        userData.lname === null ? "" : userData.lname;
      draft.fields.phone.value = userData.phone === null ? "" : userData.phone;
      draft.fields.birthDate.value = userData.bdate
        ? new Date(userData.bdate)
        : "";
      draft.initalFileUploadUrl = userData.picture
        ? `${Constants.BASE_URL}upload/${userData.picture}`
        : "";

      if (userData.picture) {
        draft.initialFileUploads.push({
          fileId: userData.picture,
          fileUrl: `${Constants.BASE_URL}upload/${userData.picture}`,
          isImg: true,
          isInitialUpload: true,
        });
        localStorage.setItem(
          `${Constants.LOCAL_STORAGE_SITE_NAME}UserAvatarId`,
          userData.logo ? userData.logo : userData.picture
        );
      } else {
        draft.initialFileUploads = [];
      }

      draft.loadedDataBe = true;

      return;

    case "formSubmitted":
      draft.hasValidationErrs = FormValidator.hasFormValidationErrors(
        draft,
        allInputs
      );
      if (!draft.hasValidationErrs) {
        draft.formSubmittedCount++;
      }
      return;
    case "formSuccessfullySubmitted":
      draft.initialFileUploads = [];
      draft.loadedDataBe = false;
      draft.formSuccessfullySubmittedCount++;
    case "disableSubmitButton":
      draft.hasValidationErrs = action.value;
      return;
    case "uploadedFiles":
      draft.uploadedFiles = action.value;
      return;
    case "localeChange":
      draft.locale = action.value;
      return;
  }
}
