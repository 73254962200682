import React, { useEffect } from "react";
import { useImmerReducer } from "use-immer";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { withStyles } from "@material-ui/core/styles";
import Colors from "../../styles/01.settings/01-settings-colors.module.scss";
import { useTranslation } from "react-i18next";
import * as Constants from "../../helpers/Constants";
import * as DreamRadioButtonsReducer from "./DreamRadioButtonsReducer";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";

// import classes from "*.module.css";

const CustomRadio = withStyles({
  root: {
    color: Colors.primary,
    "&$checked": {
      color: Colors.primary,
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const useStyles = makeStyles((theme) => ({
  label: {
    flexGrow: 1,
  },
}));

export default function DreamRadioButtonsComponent(props) {
  const [t] = useTranslation(Constants.TRANSLATION_COMMON);
  const [state, dispatch] = useImmerReducer(
    DreamRadioButtonsReducer.reducer,
    DreamRadioButtonsReducer.originalState
  );
  const classes = useStyles();

  const handleChange = (event) => {
    dispatch({
      type: "setValue",
      value: event.target.value,
    });
    if (props.changeHandler) {
      props.changeHandler(event.target.value);
    }
  };

  useEffect(() => {
    dispatch({
      type: "setValue",
      value: props.value,
    });
  }, [props.value]);

  useEffect(() => {
    if (props.data) {
      dispatch({
        type: "gotRadioButtonsData",
        value: props.data,
      });
    }
  }, [props.data]);

  function specialLabel(label) {
    let labelArr = label.split(" ");
    let arr = [];
    arr.push(
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          color: "black",
        }}
        key={labelArr[0]}
      >
        <div>
          <span style={{ paddingRight: 5 }}>{labelArr[0]}</span>
          <span>{t(labelArr[1])}</span>
        </div>
        <div>
          <span style={{ paddingRight: 5 }}>{labelArr[2]}</span>
          <span>{labelArr[3]}</span>
        </div>
      </div>
    );
    return arr;
  }

  return (
    <FormControl
      component="fieldset"
      fullWidth={props.fullWidth}
      style={props.formControlStyle}
    >
      {props.formLabel && (
        <FormLabel
          component="legend"
          style={{
            color: props.formLabelColor,
            fontWeight: props.formLabelFontWeight,
            fontSize: props.formLabelFontSize,
            textAlign: props.formLabelTextAlign,
          }}
        >
          {t(props.formLabel)}
        </FormLabel>
      )}
      <RadioGroup
        className={props.RadioGroupClassName}
        style={props.radioButtonsFormGroup}
        aria-label={t(props.name)}
        name={t(props.name)}
        value={state.value}
        onChange={handleChange}
      >
        {state.radioButtons.map((item, index) => (
          <FormControlLabel
            style={props.radioButtonsFormControlLabel}
            classes={{ label: classes.label }}
            key={index}
            value={item.value}
            control={<CustomRadio />}
            label={
              <Box
                style={props.radioButtonsStyle}
                component="div"
                fontSize={
                  props.radioButtonFontSize ? props.radioButtonFontSize : 16
                }
              >
                {props.specialLabel ? specialLabel(item.label) : t(item.label)}
              </Box>
            }
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}
