module.exports = `
<html><head>
<meta http-equiv="Content-Type" content="text/html; charset=windows-1251">
<meta name="Generator" content="Microsoft Word 15 (filtered)">
<style>
<!--
 /* Font Definitions */
 @font-face
	{font-family:"Cambria Math";
	panose-1:2 4 5 3 5 4 6 3 2 4;}
@font-face
	{font-family:Calibri;
	panose-1:2 15 5 2 2 2 4 3 2 4;}
@font-face
	{font-family:NSimSun;
	panose-1:2 1 6 9 3 1 1 1 1 1;}
@font-face
	{font-family:"\@NSimSun";}
@font-face
	{font-family:Roboto;}
 /* Style Definitions */
 p.MsoNormal, li.MsoNormal, div.MsoNormal
	{margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	line-height:107%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.a, li.a, div.a
	{mso-style-name:"Предварително форматиран текст";
	margin:0cm;
	font-size:10.0pt;
	font-family:"Courier New";}
.MsoChpDefault
	{font-family:"Calibri",sans-serif;}
.MsoPapDefault
	{margin-bottom:8.0pt;
	line-height:107%;}
@page WordSection1
	{size:612.0pt 792.0pt;
	margin:72.0pt 72.0pt 72.0pt 72.0pt;}
div.WordSection1
	{page:WordSection1;}
-->
</style>

</head>

<body lang="BG" style="word-wrap:break-word">

<div class="WordSection1">

<p class="a" style="line-height:27.0pt;background:#F8F9FA"><b><span style="font-size:12.0pt;font-family:Roboto;color:#222222">Общи условия на
програма за препоръки към w</span></b><b><span lang="EN-US" style="font-size:
12.0pt;font-family:Roboto;color:#222222">ww.jobpreference.com</span></b></p>

<p class="a" style="line-height:27.0pt;background:#F8F9FA"><span style="font-size:12.0pt;font-family:Roboto;color:#222222">1.Програмата за
препоръки към </span><span lang="EN-US" style="font-size:12.0pt;font-family:
Roboto;color:#222222">Jobpreference.com</span><span style="font-size:12.0pt;
font-family:Roboto;color:#222222"> позволява на съществуващите членове на </span><span lang="EN-US" style="font-size:12.0pt;font-family:Roboto;color:#222222">Jobpreference.com</span><span style="font-size:12.0pt;font-family:Roboto;color:#222222"> („Насочващи
членове“) да насочат личен контакт, който преди това не е имал регистрация за </span><span lang="EN-US" style="font-size:12.0pt;font-family:Roboto;color:#222222">Jobpreference.com</span><span style="font-size:12.0pt;font-family:Roboto;color:#222222"> („Насочващ контакт“)
към услугата </span><span lang="EN-US" style="font-size:12.0pt;font-family:Roboto;
color:#222222">Jobpreference.com</span><span style="font-size:12.0pt;
font-family:Roboto;color:#222222">.</span></p>

<p class="a" style="line-height:27.0pt;background:#F8F9FA"><span style="font-size:12.0pt;font-family:Roboto;color:#222222">2.За всяко успешно
насочване, насочващият член&nbsp; може да спечели кредит , който може да бъдат
използван за плащане на платени услуги на&nbsp; </span><span lang="EN-US" style="font-size:12.0pt;font-family:Roboto;color:#222222">Jobpreference.com</span><span style="font-size:12.0pt;font-family:Roboto;color:#222222">. Сумата на кредита
(ите), който може да бъде спечелена, ще бъде посочена на страницата с препоръки
(за „Насочващи членове“) . Препращането се счита за успешно, когато
Препоръчаният контакт се регистрира в услугата </span><span lang="EN-US" style="font-size:12.0pt;font-family:Roboto;color:#222222">Jobpreference.com</span><span style="font-size:12.0pt;font-family:Roboto;color:#222222">, използвайки
препращащата връзка, изпратена им от Насочващ член по време на една сесия на браузъра. Наградите са непрехвърляеми
и не могат да бъдат възстановени за парични средства или някакъв паричен
еквивалент, включително, без ограничение, карти за подаръци или възстановяване
на суми. Има ограничение от двадесет пет (25) успешни насочвания за календарна
година.</span></p>

<p class="a" style="line-height:27.0pt;background:#F8F9FA"><span style="font-size:12.0pt;font-family:Roboto;color:#222222">3.Препращащата
програма на </span><span lang="EN-US" style="font-size:12.0pt;font-family:Roboto;
color:#222222">Jobpreference.com</span><span style="font-size:12.0pt;
font-family:Roboto;color:#222222"> е предназначена единствено за лични и
нетърговски цели и препращащата връзка може да бъде изпратена само до вашите
лични контакти, а не към широката общественост. Спам препратки или привличане
на потенциални препоръчани контакти чрез маркетинг на търсачки, други рекламни
канали или чрез изграждане на уебсайтове за генериране на трафик към насочваща
връзка е строго забранено.</span></p>

<p class="a" style="line-height:27.0pt;background:#F8F9FA"><span style="font-size:12.0pt;font-family:Roboto;color:#222222">4.Ние си запазваме
правото да дисквалифицираме всеки член и да отнемем награди, получени чрез
измама или злоупотреба с програма за препоръки или по друг начин в нарушение на
тези условия.</span></p>

<p class="a" style="line-height:27.0pt;background:#F8F9FA"><span style="font-size:12.0pt;font-family:Roboto;color:#222222">5.Също така си
запазваме правото да анулираме тази програма за препоръки или да променим
критериите за допустимост по всяко време и по каквато и да е причина по наша
преценка. Ако анулираме програмата за насочване, предварително спечелените
награди ще бъдат валидни.</span></p>

<p class="MsoNormal"><span lang="EN-US" style="font-family:Roboto">&nbsp;</span></p>

</div>




</body></html>

`;
