export const originalState = {
  options: [],
  selected: "",
  inputID: "",
  loadCounter: 0,
};

export function reducer(draft, action) {
  switch (action.type) {
    case "optionsDataLoaded":
      draft.options = [];
      for (const property in action.value.data) {
        draft.options.push({
          id: property,
          text: action.value.data[property],
        });
      }
      return;

    case "selectedChanged":
      draft.selected = action.value;

      return;

    case "setInputID":
      draft.inputID = action.value;

      return;

    case "setLoadCounter":
      draft.loadCounter++;
      return;
  }
}
