import React, { useMemo, useContext } from "react";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";

import useResponsiveFontSize from "./useResponsiveFontSize";
import "./SplitCard.scss";
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";
import * as Constants from "../../helpers/Constants";
import DispatchContext from "../../Context/DispatchContext";
import BcDispatchContext from "../../Pages/BuyCreditsPage/Context/DispatchContext";
import { useTranslation } from "react-i18next";

const useOptions = () => {
  const fontSize = useResponsiveFontSize();
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize,
          color: "#424770",
          letterSpacing: "0.025em",
          fontFamily: "Source Code Pro, monospace",
          "::placeholder": {
            color: "#aab7c4",
          },
        },
        invalid: {
          color: "#9e2146",
        },
      },
    }),
    [fontSize]
  );

  return options;
};

const SplitForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();
  const appDispatch = useContext(DispatchContext);
  const bcDispatch = useContext(BcDispatchContext);

  const [t] = useTranslation(Constants.TRANSLATION_COMMON);

  const handleSubmit = async (event) => {
    event.preventDefault();
    bcDispatch({ type: "setIsProcessing", value: true });

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    const payload = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardNumberElement),
    });

    if (payload.error !== undefined) {
      appDispatch({
        type: "showMsg",
        value: {
          status: Constants.SNACKBAR_ERROR,
          msg: payload.error.message,
        },
      });
      bcDispatch({ type: "setIsProcessing", value: false });
    } else {
      bcDispatch({
        type: "gotMethodId",
        value: payload.paymentMethod.id,
      });
      bcDispatch({
        type: "succesfullyGotMethodIdCounter",
      });
      bcDispatch({ type: "setIsProcessing", value: false });
    }
  };

  const handleChange = async (event) => {
    if (event.error !== undefined) {
      appDispatch({
        type: "showMsg",
        value: {
          status: Constants.SNACKBAR_ERROR,
          msg: event.error.message,
        },
      });
    }
  };

  const production = process.env.REACT_APP_PRODUCTION === "true";

  let buttonBuyStyle = {
    display: "flex",
    fontSize: 14,
    alignItems: "center",
  };

  let transactionsStopped = null;
  if (production) {
    buttonBuyStyle = {
      ...buttonBuyStyle,
      color: "rgb(150, 150, 150)",
      backgroundColor: "lightgrey",
      cursor: "not-allowed",
    };
    transactionsStopped = (
      <p style={{ fontSize: 16, color: "red" }}>
        {t("bc.transactionsDisabled")}
      </p>
    );
  }

  return (
    <article>
      <form onSubmit={handleSubmit}>
        <label>
          {t("bc.cardNumber")}
          <CardNumberElement
            options={options}
            onChange={(event) => handleChange(event)}
          />
        </label>
        <label>
          {t("bc.expDate")}
          <CardExpiryElement
            options={options}
            onChange={(event) => handleChange(event)}
          />
        </label>
        <label>
          {t("bc.cvc")}
          <CardCvcElement
            options={options}
            onChange={(event) => handleChange(event)}
          />
        </label>
        <button
          type={production ? "button" : "submit"}
          disabled={production}
          style={buttonBuyStyle}
        >
          <AddShoppingCartIcon style={{ marginRight: 5 }} />
          {t("bc.buyCredits")}
        </button>
        {transactionsStopped}
      </form>
    </article>
  );
};

export default SplitForm;
