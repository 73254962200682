import * as Constants from "../../helpers/Constants";

export function formatResponse(data) {
  data.map((element, index) => {
    element.id = index;
  });

  return data;
}

export async function getLanguages(
  Axios,
  ourRequest,
  dispatch,
  appDispatch,
  t
) {
  let errMsgs;
  let authenticated = true;
  try {
    const response = await Axios.get(`${Constants.BASE_URL}lang`, {
      cancelToken: ourRequest.token,
    }).catch(function (error) {
      // Errors to be sent to snackbar
      errMsgs = "basic.serverError";
      if (
        error.response &&
        error.response.status &&
        error.response.status === Constants.ERROR_RESPONSE_STATUS
      ) {
        authenticated = false;
        errMsgs = "basic.unauthenticated";
      }
      throw errMsgs;
    });

    const responseData = response.data;

    dispatch({
      type: "setSelectsDataLanguagesLang",
      value: formatLanguages(responseData),
    });
    dispatch({
      type: "setSelectsDataLanguagesLevel",
      value: formatLanguagesLevels(responseData),
    });
  } catch (error) {
    appDispatch({
      type: "showMsg",
      value: { status: Constants.SNACKBAR_ERROR, msg: error },
    });
    if (!authenticated) {
      appDispatch({
        type: "logout",
      });
      appDispatch({ type: "openLoginModal", value: true });
    } else {
      dispatch({ type: "disableSubmitButton", value: false });
    }
  }
}

export const formatLanguages = (responseData) => {
  // const data = Object.entries(responseData.data).map(([key, value]) => ({
  //   [key]: `languages.${value}`,
  // }));
  let data = responseData.data;

  for (let key in data) {
    data[key] = `languages.${data[key]}`;
  }

  return { data };
};

export const formatLanguagesLevels = (responseData) => {
  let extra_data = responseData.extra_data;

  for (let key in extra_data) {
    extra_data[key] = `languagesLevels.${extra_data[key]}`;
  }

  return { extra_data };
};
