export const originalState = {
  range: [],
};

export function reducer(draft, action) {
  switch (action.type) {
    case "setRange":
      draft.range = action.value;
      break;
  }
}
