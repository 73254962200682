export const STATIONARY_WORK = 1;
export const REMOTE_WORK = 2;
export const ANY_WORK = 0;

export const workPlaceRadioButtons = [
  {
    label: "workPlace.any",
    value: ANY_WORK,
  },
  {
    label: "workPlace.stationary",
    value: STATIONARY_WORK,
  },
  {
    label: "workPlace.remote",
    value: REMOTE_WORK,
  },
];
