import React, { useContext } from "react";
import { useImmerReducer } from "use-immer";
import DreamRadioButtonsComponent from "../../ui_components/DreamRadioButtons/DreamRadioButtonsComponent";
import JobOfferStateContext from "../../Pages/CreateJobOfferPage/Context/JobOfferStateContext";
import JobOfferDispatchContext from "../../Pages/CreateJobOfferPage/Context/JobOfferDispatchContext";
import * as JoHelper from "../../Pages/CreateJobOfferPage/CreateJobOfferHelper";
import * as SalaryRangeReducer from "./SalaryRangeReducerJo";
import * as SalaryRangeHelper from "./SalaryRangeHelperJo";
import DreamSelectComponent from "../../ui_components/DreamSelect/DreamSelectComponent";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {
  Wrapper,
  Inner,
  sliderWrapper,
  CurrencyLabel,
} from "./SalaryRangeJo.module.scss";
import { useTranslation } from "react-i18next";
import * as Constants from "../../helpers/Constants";
import RangeSlider from "../../ui_components/DreamRangeSlider/DreamRangeSlider";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

function SalaryRangeComponentJo() {
  const classes = useStyles();
  const [t] = useTranslation(Constants.TRANSLATION_COMMON);
  const [state, dispatch] = useImmerReducer(
    SalaryRangeReducer.reducer,
    SalaryRangeReducer.originalState
  );

  const jobOfferDispatchContext = useContext(JobOfferDispatchContext);
  const jobOfferStateContext = useContext(JobOfferStateContext);

  function salaryRangeTypeChanged(value) {
    dispatch({
      type: "setSalaryRangeType",
      value: parseInt(value),
    });
    // dispatch({
    //   type: "disableSlider",
    // });
  }

  const valuetext = (value) => `${value}`;

  const handleSliderChange = (newValue) =>
    jobOfferDispatchContext({
      type: "salaryRangeChanged",
      value: newValue,
    });

  function salaryRangeTypeChanged(value) {
    jobOfferDispatchContext({
      type: "salaryRangeTypeChanged",
      value: parseInt(value),
    });
  }

  const handleCurrencyChange = (value) =>
    jobOfferDispatchContext({ type: "currencyChanged", value });

  let minSalaryMaxValue = !jobOfferStateContext.formData.salaryRangeType
    ? JoHelper.INITIAL_MONTHLY_MAX_SALARY
    : JoHelper.INITIAL_HOURLY_MAX_SALARY;

  let monthlyHourly = (
    <Grid>
      <Grid item xs={12} style={{ paddingTop: 10, paddingBottom: 10 }}>
        <RangeSlider
          label={""}
          maxValue={
            !jobOfferStateContext.formData.salaryRangeType
              ? JoHelper.INITIAL_MONTHLY_MAX_SALARY
              : JoHelper.INITIAL_HOURLY_MAX_SALARY
          }
          rangeValue={
            !jobOfferStateContext.formData.salaryRangeType
              ? [...jobOfferStateContext.formData.monthlySalaryRange]
              : [...jobOfferStateContext.formData.hourlySalaryRange]
          }
          getValueText={valuetext}
          onSlideChange={handleSliderChange}
          sliderStep={
            jobOfferStateContext.formData.salaryRangeType ==
            SalaryRangeHelper.MONTHLY_SALARY_RANGE_TYPE
              ? JoHelper.MONTHLY_SALARY_RANGE_STEP
              : JoHelper.HOURLY_SALARY_RANGE_STEP
          }
        />
      </Grid>
      <Grid style={{ display: "flex", justifyContent: "space-between" }}>
        <TextField
          label={t("jo.minSalary")}
          variant={"standard"}
          style={{ width: "25%" }}
          // helperText={
          //   !jobOfferStateContext.formData.salaryRangeType
          //     ? `${t("jo.exceed")} ${JoHelper.INITIAL_MONTHLY_MAX_SALARY} ${t(
          //         "jo.willBeSet"
          //       )} ${JoHelper.INITIAL_MONTHLY_MAX_SALARY}`
          //     : `${t("jo.exceed")} ${JoHelper.INITIAL_HOURLY_MAX_SALARY} ${t(
          //         "jo.willBeSet"
          //       )} ${JoHelper.INITIAL_HOURLY_MAX_SALARY}`
          // }
          error={
            (Boolean(!jobOfferStateContext.formData.salaryRangeType) &&
              Boolean(
                [...jobOfferStateContext.formData.monthlySalaryRange][0] >
                  [...jobOfferStateContext.formData.monthlySalaryRange][1]
              )) ||
            (Boolean(jobOfferStateContext.formData.salaryRangeType === 1) &&
              Boolean(
                [...jobOfferStateContext.formData.hourlySalaryRange][0] >
                  [...jobOfferStateContext.formData.hourlySalaryRange][1]
              ))
          }
          inputProps={{ maxLength: 9 }}
          value={
            !jobOfferStateContext.formData.salaryRangeType
              ? [...jobOfferStateContext.formData.monthlySalaryRange][0]
              : [...jobOfferStateContext.formData.hourlySalaryRange][0]
          }
          onChange={(e) =>
            jobOfferDispatchContext({
              type: "salaryRangeMinChanged",
              value: !isNaN(parseInt(e.target.value))
                ? parseInt(e.target.value)
                : 0,
            })
          }
        />

        <TextField
          label={t("jo.maxSalary")}
          variant={"standard"}
          style={{ width: "25%" }}
          // helperText={
          //   !jobOfferStateContext.formData.salaryRangeType
          //     ? `${t("jo.exceed")} ${JoHelper.INITIAL_MONTHLY_MAX_SALARY} ${t(
          //         "jo.willBeSet"
          //       )} ${JoHelper.INITIAL_MONTHLY_MAX_SALARY}`
          //     : `${t("jo.exceed")} ${JoHelper.INITIAL_HOURLY_MAX_SALARY} ${t(
          //         "jo.willBeSet"
          //       )} ${JoHelper.INITIAL_HOURLY_MAX_SALARY}`
          // }
          error={
            (Boolean(!jobOfferStateContext.formData.salaryRangeType) &&
              Boolean(
                [...jobOfferStateContext.formData.monthlySalaryRange][1] <
                  [...jobOfferStateContext.formData.monthlySalaryRange][0]
              )) ||
            (Boolean(jobOfferStateContext.formData.salaryRangeType === 1) &&
              Boolean(
                [...jobOfferStateContext.formData.hourlySalaryRange][1] <
                  [...jobOfferStateContext.formData.hourlySalaryRange][0]
              ))
          }
          inputProps={{ maxLength: 9 }}
          value={
            !jobOfferStateContext.formData.salaryRangeType
              ? [...jobOfferStateContext.formData.monthlySalaryRange][1]
              : [...jobOfferStateContext.formData.hourlySalaryRange][1]
          }
          onChange={(e) =>
            jobOfferDispatchContext({
              type: "salaryRangeMaxChanged",
              value: !isNaN(parseInt(e.target.value))
                ? parseInt(e.target.value)
                : !jobOfferStateContext.formData.salaryRangeType
                ? JoHelper.INITIAL_MONTHLY_MIN_SALARY
                : JoHelper.INITIAL_HOURLY_MIN_SALARY,
            })
          }
        />
        <DreamSelectComponent
          style={{ width: "30%" }}
          optionsData={{ data: SalaryRangeHelper.currencyOptions }}
          label={t("jo.currency")}
          dispatchChangeHandler={handleCurrencyChange}
          isMandatory={true}
          value={jobOfferStateContext.formData.selectedCurrency}
        />
      </Grid>
    </Grid>
  );

  return (
    <Grid container style={{ paddingLeft: 10, paddingTop: 10, width: "90%" }}>
      <Grid item xs={12} style={{ paddingLeft: 30 }}>
        <DreamRadioButtonsComponent
          name="jo.salary"
          value={jobOfferStateContext.formData.salaryRangeType}
          data={SalaryRangeHelper.salaryRangeRadioButtons}
          changeHandler={salaryRangeTypeChanged}
        />
        {monthlyHourly}
      </Grid>
    </Grid>
  );
}

export default SalaryRangeComponentJo;
