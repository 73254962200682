import React, { useContext } from "react";
import DreamRadioButtonsComponent from "../../ui_components/DreamRadioButtons/DreamRadioButtonsComponent";
import JobOfferStateContext from "../../Pages/CreateJobOfferPage/Context/JobOfferStateContext";
import JobOfferDispatchContext from "../../Pages/CreateJobOfferPage/Context/JobOfferDispatchContext";
import * as WorkplaceHelper from "./WorkplaceHelperJo";

function WorkplaceComponentJo() {
  const jobOfferDispatchContext = useContext(JobOfferDispatchContext);
  const jobOfferStateContext = useContext(JobOfferStateContext);

  function workplaceTypeChanged(value) {
    jobOfferDispatchContext({
      type: "workplaceTypeChanged",
      value: parseInt(value),
    });
  }

  return (
    <DreamRadioButtonsComponent
      name={"jp.workplace"}
      value={jobOfferStateContext.formData.workplaceType}
      data={WorkplaceHelper.workplaceRadioButtons}
      changeHandler={workplaceTypeChanged}
    />
  );
}

export default WorkplaceComponentJo;
