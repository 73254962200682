module.exports = `
<html><head>
<meta http-equiv="Content-Type" content="text/html; charset=windows-1251">
<meta name="Generator" content="Microsoft Word 15 (filtered)">
<style>
<!--
 /* Font Definitions */
 @font-face
	{font-family:"Cambria Math";
	panose-1:2 4 5 3 5 4 6 3 2 4;}
@font-face
	{font-family:Calibri;
	panose-1:2 15 5 2 2 2 4 3 2 4;}
@font-face
	{font-family:Roboto;}
 /* Style Definitions */
 p.MsoNormal, li.MsoNormal, div.MsoNormal
	{margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	line-height:107%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
.MsoChpDefault
	{font-family:"Calibri",sans-serif;}
.MsoPapDefault
	{margin-bottom:8.0pt;
	line-height:107%;}
@page WordSection1
	{size:612.0pt 792.0pt;
	margin:72.0pt 72.0pt 72.0pt 72.0pt;}
div.WordSection1
	{page:WordSection1;}
 /* List Definitions */
 ol
	{margin-bottom:0cm;}
ul
	{margin-bottom:0cm;}
-->
</style>

</head>

<body lang="BG" style="word-wrap:break-word">

<div class="WordSection1">

<p class="MsoNormal" style="margin-left:35.35pt;text-indent:-14.15pt;line-height:
150%"><span style="font-size:12.0pt;line-height:150%;font-family:Roboto">1.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp; </span></span><span style="font-size:12.0pt;line-height:150%;font-family:Roboto">The </span><span lang="EN-US" style="font-size:12.0pt;line-height:150%;font-family:Roboto">Jobpreference.com</span><span style="font-size:12.0pt;line-height:150%;font-family:Roboto"> Referral Program
allows existing </span><span lang="EN-US" style="font-size:12.0pt;line-height:
150%;font-family:Roboto">Jobpreference</span><span style="font-size:12.0pt;
line-height:150%;font-family:Roboto"> members (‘Referring Members’) to refer a
personal contact who has not previously had a </span><span lang="EN-US" style="font-size:12.0pt;line-height:150%;font-family:Roboto">Jobpreference.com</span><span style="font-size:12.0pt;line-height:150%;font-family:Roboto"> subscription
(‘Referred Contact’) to the </span><span lang="EN-US" style="font-size:12.0pt;
line-height:150%;font-family:Roboto">Jobpreference.com</span><span style="font-size:12.0pt;line-height:150%;font-family:Roboto"> service.</span></p>

<p class="MsoNormal" style="margin-left:35.35pt;text-indent:-14.15pt;line-height:
150%"><span style="font-size:12.0pt;line-height:150%;font-family:Roboto">2.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp; </span></span><span style="font-size:12.0pt;line-height:150%;font-family:Roboto">For each successful
referral, the Referring Member&nbsp; may earn credit that can be used towards their </span><span lang="EN-US" style="font-size:12.0pt;line-height:150%;font-family:Roboto">Jobpreference.com</span><span style="font-size:12.0pt;line-height:150%;font-family:Roboto"> subscription
(‘Reward’)/</span><span lang="EN-US" style="font-size:12.0pt;line-height:150%;
font-family:Roboto">for payable services</span><span style="font-size:12.0pt;
line-height:150%;font-family:Roboto">. The amount of credit(s) that may be
earned will be specified on the referrals page (for Referring Members) and/or
the referral invitation (for the Referred Contact). A referral is deemed
successful when the Referred Contact signs up to the </span><span lang="EN-US" style="font-size:12.0pt;line-height:150%;font-family:Roboto">Jobpreference.com</span><span style="font-size:12.0pt;line-height:150%;font-family:Roboto"> service using the
referral link sent to them by the Referring Member during a single browser
session. Rewards are non-transferable and cannot be redeemed for cash or any
cash equivalent, including, without limitation, gift cards or refunds. There is
a limit of </span><span lang="EN-US" style="font-size:12.0pt;line-height:150%;
font-family:Roboto">twenty </span><span style="font-size:12.0pt;line-height:
150%;font-family:Roboto">five (</span><span lang="EN-US" style="font-size:12.0pt;
line-height:150%;font-family:Roboto">2</span><span style="font-size:12.0pt;
line-height:150%;font-family:Roboto">5) successful referrals per calendar year.</span></p>

<p class="MsoNormal" style="margin-left:35.35pt;text-indent:-14.15pt;line-height:
150%"><span style="font-size:12.0pt;line-height:150%;font-family:Roboto">3.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp; </span></span><span style="font-size:12.0pt;line-height:150%;font-family:Roboto">The </span><span lang="EN-US" style="font-size:12.0pt;line-height:150%;font-family:Roboto">Jobpreference.com</span><span style="font-size:12.0pt;line-height:150%;font-family:Roboto"> Referral Program
is solely intended for personal and non-commercial purposes and the referral
link may only be sent to your personal contacts and not the public at large.
Spamming referral links or soliciting potential referral contacts through
search engine marketing, other advertising channels or by building websites to
generate traffic to the referral link is strictly prohibited.</span></p>

<p class="MsoNormal" style="margin-left:35.35pt;text-indent:-14.15pt;line-height:
150%"><span style="font-size:12.0pt;line-height:150%;font-family:Roboto">4.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp; </span></span><span style="font-size:12.0pt;line-height:150%;font-family:Roboto">We reserve the
right to disqualify any member and revoke Rewards that were obtained through
fraud or abuse of the Referral Program or otherwise in violation of these
terms.</span></p>

<p class="MsoNormal" style="margin-left:35.35pt;text-indent:-14.15pt;line-height:
150%"><span style="font-size:12.0pt;line-height:150%;font-family:Roboto">5.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp; </span></span><span style="font-size:12.0pt;line-height:150%;font-family:Roboto">We also reserve
the right to cancel this Referral Program or alter the eligibility criteria at
any time and for any reason at our own discretion. If we cancel the Referral
Program, prior earned Rewards will be honored.</span></p>

<p class="MsoNormal"><span lang="EN-US" style="font-size:12.0pt;line-height:107%;
font-family:Roboto">&nbsp;</span></p>

</div>




</body></html>
`;
