export const ANY = 0;
export const IMMEDIATE = 1;
export const AFTER_30_DAYS = 2;
export const MORE_THAN_30_DAYS = 3;

export const preferredStartRadioButtons = [
  {
    label: "preferredStart.any",
    value: ANY,
  },
  {
    label: "preferredStart.immediate",
    value: IMMEDIATE,
  },
  {
    label: "preferredStart.after30Days",
    value: AFTER_30_DAYS,
  },
  {
    label: "preferredStart.moreThan30Days",
    value: MORE_THAN_30_DAYS,
  },
];
