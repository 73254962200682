export const MONTHLY_SALARY_RANGE_TYPE = 0;
export const HOURLY_SALARY_RANGE_TYPE = 1;

export const MONTHLY_SALARY_RANGE_MAX_VALUE = 50000;
export const HOURLY_SALARY_RANGE_MAX_VALUE = 500;

export const MONTHLY_SALARY_RANGE_STEP = 50;
export const HOURLY_SALARY_RANGE_STEP = 1;

export const salaryRangeRadioButtons = [
  {
    label: "jo.monthly",
    value: MONTHLY_SALARY_RANGE_TYPE,
  },
  {
    label: "jo.hourly",
    value: HOURLY_SALARY_RANGE_TYPE,
  },
];

export const currencyOptions = {
  USD: "USD",
  EUR: "EUR",
  BGN: "BGN",
};
