import React from "react";
import * as Constants from "../../helpers/Constants";
import FontDownloadOutlinedIcon from "@material-ui/icons/FontDownloadOutlined";
import BusinessCenterOutlinedIcon from "@material-ui/icons/BusinessCenterOutlined";

export const rowsPerPageOptions = [5, 10, 20];

// Get get employers matching current preference

export async function getEmployers(
  preferenceId,
  page_size,
  page,
  conversation,
  Axios,
  ourRequest,
  appDispatch,
  dispatch
) {
  appDispatch({ type: "showBackdrop", value: true });

  let errMsgs;
  let authenticated = true;
  const params = {
    page_size: page_size,
    page: page + 1,
    conversation: conversation,
  };
  try {
    const response = await Axios.get(
      `/dreamjob/jp/conversation/${preferenceId}`,
      {
        cancelToken: ourRequest.token,
        params: params,
      }
    ).catch(function (error) {
      // Errors to be sent to snackbar
      errMsgs = "basic.serverError";
      if (
        error.response &&
        error.response.status &&
        error.response.status === Constants.ERROR_RESPONSE_STATUS
      ) {
        authenticated = false;
        errMsgs = "basic.unauthenticated";
      }
      throw errMsgs;
    });

    const responseData = response.data.data;

    handleSuccessfulGetEmployersData(responseData, appDispatch, dispatch);
  } catch (error) {
    appDispatch({ type: "showBackdrop", value: false });
    appDispatch({
      type: "showMsg",
      value: { status: Constants.SNACKBAR_ERROR, msg: error },
    });
    if (!authenticated) {
      appDispatch({
        type: "logout",
      });
      appDispatch({ type: "openLoginModal", value: true });
    } else {
      dispatch({ type: "disableSubmitButton", value: false });
    }
  }
}

export function handleSuccessfulGetEmployersData(data, appDispatch, dispatch) {
  appDispatch({ type: "showBackdrop", value: false });
  dispatch({ type: "employersDataLoaded", value: data });
}

export const headCells = [
  {
    id: "avatar",
    numeric: false,
    disablePadding: false,
    label: "jov.avatar",
    icon: <FontDownloadOutlinedIcon />,
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "jov.name",
    icon: <FontDownloadOutlinedIcon />,
  },
  {
    id: "company",
    numeric: false,
    disablePadding: false,
    label: "jov.company",
    icon: <BusinessCenterOutlinedIcon />,
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    label: "jov.actions",
    icon: <FontDownloadOutlinedIcon />,
  },
];
