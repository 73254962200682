export const originalState = {
  data: [],
  checkboxes: [],
};

export function reducer(draft, action) {
  switch (action.type) {
    case "setData":
      draft.data = action.value;
      return;
    case "setCheckboxesGroup":
      draft.checkboxes = action.value;
      return;
  }
}
