import React, { useContext, useEffect } from "react";
import uuid from "react-uuid";
import StateContext from "../../Context/StateContext";
import { useImmerReducer } from "use-immer";

import * as DreamDatepickerHelper from "./DreamDatepickerHelper";
import * as DreamDatepickerReducer from "./DreamDatepickerReducer";

import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import localeBg from "date-fns/locale/bg";
import localeEnUS from "date-fns/locale/en-US";

function DreamDatepickerComponent(props) {
  const appContext = useContext(StateContext);
  const t = appContext.context.translateService.t;
  const [state, dispatch] = useImmerReducer(
    DreamDatepickerReducer.reducer,
    DreamDatepickerReducer.originalState
  );

  const mandatorySign = props.isMandatory ? "*" : "";
  const label = props.label
    ? `${t(props.label)}${mandatorySign}`
    : `datepicker${mandatorySign}`;

  useEffect(() => {
    if (props.value) {
      dispatch({
        type: "valueChange",
        value: props.value,
      });
    }
  }, [props.value]);

  useEffect(() => {
    switch (appContext.appState.lang) {
      case "en":
        dispatch({ type: "localeChange", value: localeEnUS });
        break;
      case "bg":
        dispatch({ type: "localeChange", value: localeBg });
        break;
    }
  }, [appContext.appState.lang]);

  return (
    <MuiPickersUtilsProvider locale={state.locale} utils={DateFnsUtils}>
      <KeyboardDatePicker
        autoComplete="off"
        fullWidth={Boolean(props.fullWidth)}
        autoOk
        disableFuture={Boolean(props.disableFuture)}
        minDate={props.minDate ? props.minDate : "1900-01-01"}
        variant={props.variant ? props.variant : "inline"}
        format={props.fromat ? props.format : "yyyy-MM-dd"}
        margin={props.margin ? props.margin : "normal"}
        id={props.id ? props.id : uuid()}
        label={label}
        value={state.value}
        onChange={(e) =>
          DreamDatepickerHelper.changeHandler(
            e,
            dispatch,
            props.dispatchChangeHandler
          )
        }
        KeyboardButtonProps={{
          "aria-label": label,
        }}
      />
    </MuiPickersUtilsProvider>
  );
}

export default DreamDatepickerComponent;
