export const originalState = {
  formData: {
    subject: "",
    content: "",
    url: "",
  },
  submitButtonDisabled: false,
  dialogOpen: false,
};

export function reducer(draft, action) {
  switch (action.type) {
    case "setSubject":
      draft.formData.subject = action.value;
      return;
    case "setContent":
      draft.formData.content = action.value;
      return;
    case "setUrl":
      draft.formData.url = action.value;
      return;
    case "disableSubmitButton":
      draft.submitButtonDisabled = action.value;
      break;
    case "setOpen":
      draft.dialogOpen = action.value;
      break;
    default:
  }
}
