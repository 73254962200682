class FormValidator {
  /**
   * Validate given field
   * @param {*} fieldValue
   * @param {Object} validationTypes e.g. { required: true, minLength: 3, maxLength: 5, email: true }
   * @param {Translation function} t
   */
  static validateField(fieldValue, validationTypes, t, comparisonValue) {
    let error;
    fieldValue = fieldValue.trim();
    for (const prop in validationTypes) {
      const validationVal = validationTypes[prop];
      switch (prop) {
        case "required":
          if (!fieldValue && validationVal) {
            error = t("validationError.requiredField");
          }
          break;

        case "minLength":
          if (fieldValue && fieldValue.length < validationVal) {
            error = t("validationError.minLength", {
              param: validationVal,
            });
          }
          break;

        case "maxLength":
          if (fieldValue && fieldValue.length > validationVal) {
            error = t("validationError.maxLength", {
              param: validationVal,
            });
          }
          break;
        case "phone":
          if (fieldValue.length < 2 && validationVal) {
            error = t("validationError.requiredField");
          }
          break;

        case "email":
          if (fieldValue && validationVal && !this.isValidEmail(fieldValue)) {
            error = t("validationError.invalidEmail");
          }
          break;
        case "password":
          if (
            fieldValue &&
            validationVal &&
            !this.isValidPassword(fieldValue)
          ) {
            error = t("validationError.passwordRequirements");
          }
          break;
        case "confirmPassword":
          if (
            fieldValue &&
            validationVal &&
            !this.arePasswordsIdentical(fieldValue, comparisonValue)
          ) {
            error = t("registerForm.differentPasswords");
          }
          break;
        default:
          break;
      }
    }

    return error;
  }

  /**
   * Check if it's valid email address
   *
   */
  static isValidEmail(email) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      return true;
    }
    return false;
  }

  static isValidPassword(password) {
    // at least one digit,at least one special character, at least one lower case
    if (
      /^(?=.*\d)(?=.*[!@#$%^&*()+=-\?;,./{}|\":<React.Fragment>\[\]\\\' ~_])(?=.*[a-z]).{8,50}$/.test(
        password
      )
    ) {
      return true;
    }

    // at least one digit,at least one special character, at least one upper case
    if (
      /^(?=.*\d)(?=.*[!@#$%^&*()+=-\?;,./{}|\":<React.Fragment>\[\]\\\' ~_])(?=.*[A-Z]).{8,50}$/.test(
        password
      )
    ) {
      return true;
    }

    // at least one digit, at least one lower case, at least one upper case
    if (/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,50}$/.test(password)) {
      return true;
    }

    // at least one special character, at least one lower case, at least one upper case
    if (
      /^(?=.*[!@#$%^&*()+=-\?;,./{}|\?":<React.Fragment>\[\]\\\' ~_])(?=.*[a-z])(?=.*[A-Z]).{8,50}$/.test(
        password
      )
    ) {
      return true;
    }

    return false;
  }

  static arePasswordsIdentical(password, confirmPassword) {
    if (password === confirmPassword) {
      return true;
    }

    return false;
  }

  /**
   * Check if the form has some error. It's pretty simple function it just chechs if all fields in state have 'isValid' == true
   * @param {Object} state
   * @param {Object} allFields
   */
  static hasFormValidationErrors(state, allFields) {
    let isFormValid = true;
    for (const prop in allFields) {
      if (!state.fields[prop].isValid) {
        isFormValid = false;
        break;
      }
    }

    return !isFormValid;
  }
}

export default FormValidator;
