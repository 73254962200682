import React, { useContext, useEffect } from "react";
import Axios from "axios";
import * as AppRoutes from "../../../AppComponent/AppRoutes";
import DispatchContext from "../../../Context/DispatchContext";
import { Link as RouterLink } from "react-router-dom";
import DreamPage from "../../../ui_components/DreamPage";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import { useImmerReducer } from "use-immer";
import { useTranslation } from "react-i18next";
import * as Constants from "../../../helpers/Constants";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";

import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import * as Colors from "../../../styles/01.settings/01-settings-colors.module.scss";
import * as InvoicesReducer from "./InvoicesReducer";
import * as InvoicesHelper from "./InvoicesHelper";
import DreamCardMedia from "../../../ui_components/DreamCardMediaComponent/DreamCardMedia";
import DreamButton from "../../../ui_components/DreamButton/DreamButton";

function InvoicesPage() {
  const [t] = useTranslation(Constants.TRANSLATION_COMMON);
  const appDispatch = useContext(DispatchContext);

  const [state, dispatch] = useImmerReducer(
    InvoicesReducer.reducer,
    InvoicesReducer.originalState
  );

  const useStyles = makeStyles({
    table: {
      maxWidth: 475,
    },
    title: {
      fontSize: 24,
      fontWeight: "bold",
    },
    tableCell: {
      borderBottom: "none",
      padding: 8,
    },
    tblContainer: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      overflowX: "hidden",
    },
    tblRow: { width: "100%" },
    actionsButton: {},
    addButton: {
      width: "10em",
    },
    menuButton: {
      width: "100%",
    },
    subHeader: {
      color: Colors.success,
    },
    subHeaderDanger: {
      color: Colors.danger,
    },
    detailWrapper: {
      display: "inline-flex",
      width: "100%",
    },
    detail: {
      fontWeight: "bold",
      color: "black",
      width: "35%",
    },
  });
  const classes = useStyles();

  useEffect(() => {
    const ourRequest = Axios.CancelToken.source();
    InvoicesHelper.getInvoiceDetails(Axios, ourRequest, appDispatch, dispatch);
    return () => {
      ourRequest.cancel();
    };
  }, []);

  return (
    <DreamPage title="invoices.invoiceDetails">
      <Grid container>
        <Grid item xs={12}>
          <TableContainer className={classes.tblContainer}>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              aria-label="enhanced table"
            >
              <TableBody>
                {state.invoiceDetails.map((invoice, index) => (
                  <TableRow key={invoice.type} className={classes.tblRow}>
                    <TableCell className={classes.tableCell}>
                      <DreamCardMedia
                        cardHeader
                        cardHeaderTitle={t("invoices.title")}
                        headerAction
                        cardActions
                        cardContent
                        cardContentStyle={{ paddingTop: 8, paddingBottom: 8 }}
                        cardContent={[
                          <span className={classes.detailWrapper}>
                            <span className={classes.detail}>
                              {t("invoices.type")}
                            </span>
                            <span>
                              {invoice.type === 1
                                ? t("createInvoice.natural")
                                : t("createInvoice.legal")}
                            </span>
                          </span>,
                          invoice.type === 1 && (
                            <span className={classes.detailWrapper}>
                              <span className={classes.detail}>
                                {t("invoices.firstName")}
                              </span>
                              <span>{invoice.firstName}</span>
                            </span>
                          ),
                          invoice.type === 1 && (
                            <span className={classes.detailWrapper}>
                              <span className={classes.detail}>
                                {t("invoices.lastName")}
                              </span>
                              <span>{invoice.lastName}</span>
                            </span>
                          ),
                          invoice.type === 2 && (
                            <span className={classes.detailWrapper}>
                              <span className={classes.detail}>
                                {t("invoices.company")}
                              </span>
                              <span>{invoice.company}</span>
                            </span>
                          ),
                          <span className={classes.detailWrapper}>
                            <span className={classes.detail}>
                              {t("invoices.country")}
                            </span>
                            <span>{invoice.countryName}</span>
                          </span>,
                          <span className={classes.detailWrapper}>
                            <span className={classes.detail}>
                              {t("invoices.address")}
                            </span>
                            <span>{invoice.address}</span>
                          </span>,
                          <span className={classes.detailWrapper}>
                            <span className={classes.detail}>
                              {t("invoices.city")}
                            </span>
                            <span>{invoice.cityName}</span>
                          </span>,
                          <span className={classes.detailWrapper}>
                            <span className={classes.detail}>
                              {t("invoices.zip")}
                            </span>
                            <span>{invoice.zip}</span>
                          </span>,
                          invoice.type === 2 && (
                            <span className={classes.detailWrapper}>
                              <span className={classes.detail}>
                                {t("invoices.official")}
                              </span>
                              <span>{invoice.responsible}</span>
                            </span>
                          ),
                          invoice.type === 2 && (
                            <span className={classes.detailWrapper}>
                              <span className={classes.detail}>
                                {t("invoices.bulstat")}
                              </span>
                              <span>{invoice.idn}</span>
                            </span>
                          ),
                          invoice.type === 2 && (
                            <span className={classes.detailWrapper}>
                              <span className={classes.detail}>
                                {t("invoices.uic")}
                              </span>
                              <span>{invoice.uic}</span>
                            </span>
                          ),
                        ]}
                        menuStyle={{
                          position: "absolute",
                          right: 0,
                        }}
                        menuItems={[
                          {
                            child: (
                              <DreamButton
                                className={classes.menuButton}
                                kind="contained"
                                buttonLabel={t("jos.edit")}
                                color={"secondary"}
                                btnComponent={RouterLink}
                                to={`/invdetails`}
                                icon={
                                  <CreateOutlinedIcon
                                    htmlColor={Colors.warning}
                                  />
                                }
                              />
                            ),
                          },
                        ]}
                      />
                    </TableCell>
                  </TableRow>
                ))}
                {!state.hasInvoiceDetails ? (
                  <TableRow className={classes.tblRow}>
                    <TableCell
                      align="center"
                      colSpan={12}
                      style={{ borderBottom: "none" }}
                    >
                      <DreamButton
                        className={classes.addButton}
                        kind="contained"
                        buttonLabel={t("jos.add")}
                        color={"primary"}
                        buttonLabelColor={Colors.white}
                        btnComponent={RouterLink}
                        to={`${AppRoutes.ROUTE_CREATE_INVOICE}`}
                        icon={<AddCircleOutlineOutlinedIcon />}
                      />
                    </TableCell>
                  </TableRow>
                ) : null}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </DreamPage>
  );
}

export default InvoicesPage;
