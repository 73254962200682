import React, { useContext, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { useImmerReducer } from "use-immer";
import { useTranslation } from "react-i18next";
import * as Constants from "../../helpers/Constants";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import * as JoHelper from "./JobOfferCandidateViewHelper";
import * as JoReducer from "./JobOfferCandidateViewReducer";
import DreamButton from "../../ui_components/DreamButton/DreamButton";
import DreamConfirmModal from "../../ui_components/DreamConfirmModalComponent/DreamConfirmModalComponent";
import DreamPage from "../../ui_components/DreamPage";
import DreamAccordion from "../../ui_components/DreamAccordionComponent/DreamAccordionComponent";
import * as ProfileHelper from "../../helpers/Functions/GetProfileData/GetProfileData";
import Axios from "axios";
import DispatchContext from "../../Context/DispatchContext";
import DreamChat from "../../ui_components/DreamChat/DreamChat";
import { useParams, useHistory } from "react-router-dom";
import DreamCard from "../../ui_components/DreamSimpleCard/DreamSimpleCard";
import JocvDispatchContext from "./Context/DispatchContext";
import InfoIcon from "@material-ui/icons/Info";
import LockIcon from "@material-ui/icons/Lock";
import * as Colors from "../../styles/01.settings/01-settings-colors.module.scss";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Paper from "@material-ui/core/Paper";

const pageTitle = "jocv.pageTitle";

export default function JobOfferCandidateView() {
  const [t] = useTranslation(Constants.TRANSLATION_COMMON);
  const appDispatch = useContext(DispatchContext);

  const { cID, jpID } = useParams();
  const history = useHistory();

  const theme = useTheme();
  const matchesXSdown = useMediaQuery(theme.breakpoints.down("xs"));

  const [state, dispatch] = useImmerReducer(
    JoReducer.reducer,
    JoReducer.originalState
  );

  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    table: {
      tableLayout: "fixed",
    },
    contactPaper: {
      padding: theme.spacing(1),
    },
    contactTable: {
      height: 86,
    },
    contactTitle: {
      marginTop: 0,
      marginBottom: 0,
    },
    tableContactRow: {
      display: "flex",
      flexWrap: "wrap",
      height: 25,
      paddingTop: 0,
      paddingBottom: 0,
    },
    tableContactCellLabel: {
      textAlign: "left",
      borderBottom: "none",
      fontWeight: "bold",
      padding: 8,
      minWidth: 60,
    },
    title: {
      fontSize: 18,
      fontWeight: "bold",
      flexGrow: 1,
    },
    tableCellLabel: {
      textAlign: "right",
      borderBottom: "none",
      fontWeight: "bold",
      padding: 8,
    },
    tableCell: {
      textAlign: "left",
      borderBottom: "none",
      padding: 8,
    },
    tblContainer: {
      maxWidth: state.isCandidateUnlocked ? 450 : "100%",
      width: "100%",
    },
    tableRow: {
      display: "flex",
      flexWrap: "wrap",
    },
    joLabel: {},
    buttonUnlockWrapper: {
      paddingTop: state.isCandidateUnlocked ? 0 : 50,
      paddingBottom: state.isCandidateUnlocked ? 15 : 50,
    },
    wrapper: {
      width: "100%",
      display: "flex",
      flexFlow: "row wrap",
      justifyContent: "space-around",
    },
  }));

  const classes = useStyles();

  const contactItems = [
    {
      label: "jocv.name",
      value: `${state.candidateProfile.firstName} ${state.candidateProfile.lastName}`,
    },
    {
      label: "jocv.email",
      value: state.candidateProfile.email,
    },
    {
      label: "jocv.tel",
      value: state.candidateProfile.phone,
    },
  ];

  const cvItems = [
    { label: "cv.location", value: state.cv.location.name },
    {
      label: "cv.education",
      value: state.cv.education.educationNames,
    },
    {
      label: "cv.languages",
      value: state.cv.languages.languagesNames,
    },
    {
      label: "cv.experience",
      value: state.cv.experience,
    },
    {
      label: "cv.generalSkills",
      value: state.cv.generalSkills.generalSkillsNames,
    },
    {
      label: "cv.additionalSkills",
      value: state.cv.additionalSkills.additionalSkillsNames,
    },
    {
      label: "cv.hobbies",
      value: state.cv.hobbies.hobbiesNames,
    },
    {
      label: "cv.certificates",
      value: state.cv.initialFileUploads,
    },
  ];

  const accordionItems = [
    {
      heading: "jocv.preference",
      secondaryHeading: "",
      mainIcon: <InfoIcon />,
      mainContent: <PreferencesComponent />,
    },
    {
      heading: "jocv.cv",
      secondaryHeading: "",
      mainIcon: <VisibilityOutlinedIcon />,
      mainContent: <CvComponent />,
      hidden: !state.isCandidateUnlocked,
      itemExpanded: Boolean(state.expandedAccordion === "panel2"),
    },
  ];

  function PreferencesComponent() {
    return (
      <Grid
        className={classes.root}
        container
        spacing={1}
        justify="center"
        direction="column"
      >
        {state.candidatePreferences.map((preference, index) => (
          <TableContainer key={index} className={classes.tblContainer}>
            <DreamConfirmModal
              isOpen={state.isModalOpen}
              handleClose={handleModalClose}
              title={"jocv.confirmUnlock"}
              firstRow={"jocv.unlockModalText"}
              handleCancel={handleModalClose}
              buttonCancelLabel={"basic.cancel"}
              handleConfirm={(e) =>
                JoHelper.handleUnlock(
                  JoHelper.UNLOCK_CANDIDATE,
                  preference.preferenceId,
                  Axios,
                  appDispatch,
                  dispatch
                )
              }
              buttonConfirmLabel={"basic.confirm"}
            />
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              aria-label="enhanced table"
            >
              <TableHead>
                <TableRow className={classes.tableRow}>
                  <TableCell
                    className={classes.title}
                    align="center"
                    colSpan={12}
                  >
                    {t(`positions.${preference.preference[2].id}`)}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {JoHelper.preferenceLabels.map((label, index) => (
                  <TableRow key={index} className={classes.tableRow}>
                    <TableCell colSpan={12} className={classes.tableCellLabel}>
                      <span className={classes.joLabel}>{t(label)}</span>
                    </TableCell>
                    <TableCell colSpan={12} className={classes.tableCell}>
                      {JoHelper.populatePreferenceData(
                        index,
                        preference.preference,
                        t
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ))}
      </Grid>
    );
  }

  function CvComponent() {
    return (
      <Grid
        className={classes.root}
        container
        spacing={1}
        justify="center"
        direction="column"
      >
        {
          <Paper className={classes.contactPaper}>
            <h3 className={classes.contactTitle}>{t("jocv.contact")}</h3>
            <TableContainer className={classes.tblContainer}>
              <Table
                className={classes.contactTable}
                aria-labelledby="tableTitle"
                aria-label="enhanced table"
                style={{ tableLayout: "fixed" }}
              >
                <TableBody>
                  {contactItems.map((item, index) => (
                    <TableRow
                      key={item + index}
                      className={classes.tableContactRow}
                    >
                      <TableCell
                        colSpan={12}
                        className={classes.tableContactCellLabel}
                      >
                        {t(item.label)}
                      </TableCell>
                      <TableCell colSpan={12} className={classes.tableCell}>
                        {item.value}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        }
        {
          <TableContainer className={classes.tblContainer}>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              aria-label="enhanced table"
              style={{ tableLayout: "fixed" }}
            >
              <TableBody>
                {cvItems.map((item, index) => (
                  <TableRow key={index} className={classes.tableRow}>
                    <TableCell colSpan={12} className={classes.tableCellLabel}>
                      {t(item.label)}
                    </TableCell>
                    <TableCell colSpan={12} className={classes.tableCell}>
                      {switchCvItems(item, index)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        }
      </Grid>
    );
  }

  function formatExperience(data, t) {
    return data.map((experience, index) => (
      <DreamCard
        key={index}
        company={experience.company ? experience.company : ""}
        industry={experience.industry_name ? t(experience.industry_name) : ""}
        position={
          experience.position.name != null ? t(experience.position.name) : ""
        }
        from={experience.from != null ? experience.from : t("jocv.begining")} // ????
        to={experience.to != null ? experience.to : t("jocv.presentJob")}
      />
    ));
  }

  function formatEducation(data, t) {
    return data.map((education, index) => (
      <DreamCard
        key={index}
        educationLevel={t(education.educationLevel)}
        broadEducation={t(education.broadEducation)}
        detailedEducation={t(education.detailedEducation)}
        major={education.major != null ? education.major : ""}
        from={education.from} // ????
        to={education.to != null ? education.to : t("cv.presentEducation")}
      />
    ));
  }

  function formatLanguages(data, t) {
    return data.map((language, index) => (
      <DreamCard
        key={index}
        language={t(language.language)}
        level={t(language.level)}
      />
    ));
  }

  const switchCvItems = function (item, index) {
    let result = "";
    switch (index) {
      case 0:
        result = item.value;
        break;
      case 1:
        result = item.value.length > 0 ? formatEducation(item.value, t) : "";
        break;
      case 2:
        result = item.value.length > 0 ? formatLanguages(item.value, t) : "";
        break;
      case 3:
        result = item.value.length > 0 ? formatExperience(item.value, t) : "";
        break;
      case 4:
      case 5:
      case 6:
        result = item.value.map((name) => t(name)).join(", ");
        break;
      case 7:
        result = item.value.length
          ? JoHelper.formatCertificates(item.value, t)
          : t("jocv.nofiles");
        break;
    }
    return result;
  };

  const handleClickModalOpen = (preference) => {
    dispatch({ type: "setModalOpen", value: true });
  };

  const handleModalClose = () => {
    dispatch({ type: "setModalOpen", value: false });
  };

  useEffect(() => {
    const ourRequest = Axios.CancelToken.source();
    JoHelper.checkCandidateVisibility(
      cID,
      Axios,
      ourRequest,
      appDispatch,
      dispatch
    );

    return () => {
      ourRequest.cancel();
    };
  }, []);

  useEffect(() => {
    const ourRequest = Axios.CancelToken.source();

    JoHelper.getCandidatePreference(
      jpID,
      Axios,
      ourRequest,
      appDispatch,
      dispatch,
      t
    );

    return () => {
      ourRequest.cancel();
    };
  }, []);

  useEffect(() => {
    const ourRequest = Axios.CancelToken.source();
    if (
      state.preferenceDataLoaded &&
      (state.isCandidateUnlocked || state.isCandidateNewlyUnlocked)
    ) {
      JoHelper.getCandidateCV(
        cID,
        jpID,
        Axios,
        ourRequest,
        appDispatch,
        dispatch
      );
    }
    return () => {
      ourRequest.cancel();
    };
  }, [
    state.preferenceDataLoaded,
    state.isCandidateUnlocked,
    state.isCandidateNewlyUnlocked,
  ]);

  useEffect(() => {
    if (state.isCandidateUnlocked) {
      const ourRequest = Axios.CancelToken.source();

      ProfileHelper.getProfileData(
        cID,
        Axios,
        ourRequest,
        appDispatch,
        dispatch,
        t
      );

      // window.scrollTo(0, 0);

      return () => {
        ourRequest.cancel();
      };
    }
  }, [state.isCandidateUnlocked]);

  console.log("JOCV", state);

  return (
    <JocvDispatchContext.Provider value={dispatch}>
      <DreamPage
        title={t(pageTitle)}
        backButton
        backButtonDisabled={
          state.isCandidateUnlocked
            ? !(state.preferenceDataLoaded && state.cv.cvDataLoaded)
            : !state.preferenceDataLoaded
        }
        handleClick={() => history.goBack()}
      >
        <Grid className={classes.wrapper}>
          <DreamAccordion
            accordionWrapperStyle={{
              maxWidth: state.isCandidateUnlocked ? 450 : 770,
              height: "fit-content",
              paddingBottom: 10,
            }}
            dispatchContexName={"jocvDispatch"}
            accordionItems={accordionItems}
            accordionSummaryStyle={{ height: matchesXSdown ? 40 : 70 }}
          />
          {!state.isCandidateUnlocked ? (
            ""
          ) : (
            <DreamChat
              dataLoaded={state.preferenceDataLoaded}
              jpId={state.preferenceId}
              image={state.candidateProfile.pictureUrl}
              name={`${state.candidateProfile.firstName} ${state.candidateProfile.lastName}`}
            />
          )}
        </Grid>
        <Grid className={classes.buttonUnlockWrapper}>
          {state.isCandidateUnlocked ? (
            ""
          ) : (
            <DreamButton
              className={classes.actionsButton}
              kind="contained"
              buttonLabel={t("jocv.unlock")}
              color={"primary"}
              buttonLabelColor={Colors.white}
              handleClick={(e) =>
                handleClickModalOpen(state.candidatePreferences[0])
              }
              icon={<LockIcon />}
            />
          )}
        </Grid>
      </DreamPage>
    </JocvDispatchContext.Provider>
  );
}
