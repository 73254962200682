import * as Constants from "../../helpers/Constants";

export async function login(
  state,
  Axios,
  ourRequest,
  appDispatch,
  dispatch,
  t,
  history
) {
  try {
    const response = await Axios.post(
      "/user/login",
      {
        username: state.fields.email.value,
        password: state.fields.password.value,
      },
      {
        cancelToken: ourRequest.token,
        headers: {
          Authorization: null,
        },
      }
    ).catch(function (error) {
      // Errors to be sent to scanckbar
      let errMsgs = t("basic.serverError");
      if (error.response) {
        // Request made and server responded
        if (error.response.data.message) {
          errMsgs = JSON.parse(error.response.data.message);
        }
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
      throw errMsgs;
    });

    handleSuccessfulLogged(
      response.data.data,
      appDispatch,
      dispatch,
      t,
      history
    );
  } catch (error) {
    appDispatch({ type: "showBackdrop", value: false });
    appDispatch({
      type: "showMsg",
      value: { status: Constants.SNACKBAR_ERROR, msg: error },
    });
    console.log("Error login catch", error);
  }
}

function handleSuccessfulLogged(data, appDispatch, dispatch, t, history) {
  history.push("/");
  appDispatch({ type: "setSelectedFooterPage", value: "/" });
  appDispatch({ type: "showBackdrop", value: false });
  dispatch({ type: "resetForm" });
  appDispatch({ type: "openLoginModal", value: false });
  appDispatch({ type: "login", value: data });
  appDispatch({
    type: "showMsg",
    value: {
      status: Constants.SNACKBAR_SUCCESS,
      msg: t("loginForm.successfulLogged"),
    },
  });
}

export function handleSubmit(e, state, dispatch) {
  e.preventDefault();
  dispatch({ type: "usernameRules", value: state.fields.email.value });
  dispatch({ type: "passwordRules", value: state.fields.password.value });
  dispatch({ type: "formSubmitted" });
}

export function handleClose(dispatch, appDispatch) {
  dispatch({ type: "resetForm" });
  appDispatch({ type: "openLoginModal", value: false });
}
