export function handleChange(isChecked, experienceID, dispatch, myCvDispatch) {
  dispatch({
    type: "changePresentJob",
    value: isChecked,
  });
  dispatch({
    type: "setTo",
    value: "",
  });
  myCvDispatch({
    type: "experienceChangePresentJob",
    value: {
      experienceID: experienceID,
      presentJob: isChecked,
    },
  });
}
