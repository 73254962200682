import * as Constants from "../../../helpers/Constants";
export const NATURAL_PERSON = 1;
export const LEGAL_ENTITY = 2;

export const typeRadioButtons = [
  {
    label: "createInvoice.natural",
    value: NATURAL_PERSON,
  },
  {
    label: "createInvoice.legal",
    value: LEGAL_ENTITY,
  },
];

export const save = async (state, Axios, ourRequest, appDispatch, dispatch) => {
  let errMsgs = [];
  let authenticated = true;

  let formData = new FormData();

  formData.append("type", state.formData.type);
  formData.append("country", state.formData.country.id);
  formData.append("city", state.formData.city.id);
  formData.append("address", state.formData.address);
  formData.append("zip", state.formData.zip);
  formData.append("responsible", state.formData.responsible);
  formData.append("idn", state.formData.idn);
  formData.append("uic", state.formData.uic);

  dispatch({ type: "setDisableSaveButton", value: true });
  appDispatch({ type: "showBackdrop", value: true });

  try {
    const response = await Axios.post("/dreamjob/invdetails", formData, {
      cancelToken: ourRequest.token,
    }).catch(function (error) {
      // Errors to be sent to snanckbar
      errMsgs = "basic.serverError";
      if (
        error.response &&
        error.response.status &&
        error.response.status === Constants.ERROR_RESPONSE_STATUS
      ) {
        authenticated = false;
        errMsgs = "basic.unauthenticated";
      }
      dispatch({ type: "setDisableSaveButton", value: true });
      appDispatch({ type: "showBackdrop", value: false });

      throw errMsgs;
    });

    const responseData = response.data.data;
    let errsArr = [];
    if (!responseData.success && responseData.messages.length) {
      responseData.messages.forEach((msg) => {
        errsArr.push(`serverErrors.${msg.code}`);
      });
      throw errsArr;
    }
    dispatch({ type: "setFormSuccessfullySubmitted", value: true });
    dispatch({ type: "setDisableSaveButton", value: false });
    appDispatch({ type: "showBackdrop", value: false });
    appDispatch({
      type: "showMsg",
      value: { status: Constants.SNACKBAR_SUCCESS, msg: "basic.saved" },
    });
  } catch (error) {
    appDispatch({ type: "showBackdrop", value: false });
    appDispatch({
      type: "showMsg",
      value: { status: Constants.SNACKBAR_ERROR, msg: error },
    });
    if (!authenticated) {
      appDispatch({
        type: "logout",
      });
    } else {
      dispatch({ type: "setDisableSaveButton", value: false });
    }
  }
};
