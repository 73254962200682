import React, { useEffect } from "react";
import { useImmerReducer } from "use-immer";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import * as Constants from "../../helpers/Constants";
import * as DreamCheckboxGroupedReducer from "./DreamCheckboxGroupedReducer";
import DreamMultipleSelect from "../../ui_components/DreamMultiselectComponent/DreamMultiselectComponent";

import { Root } from "./DreamCheckboxGroupedComponent.module.scss";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  formControl: {
    margin: theme.spacing(1),
  },
}));

function DreamCheckboxGroupedComponent(props) {
  const [state, dispatch] = useImmerReducer(
    DreamCheckboxGroupedReducer.reducer,
    DreamCheckboxGroupedReducer.originalState
  );
  const classes = useStyles();

  const [t] = useTranslation(Constants.TRANSLATION_COMMON);

  useEffect(() => {
    if (props.data) {
      dispatch({
        type: "gotCheckboxesData",
        value: props.data,
      });
    }
  }, [props.data]);

  function getFormGroups(checkboxes) {
    const formGroups = [];
    let i = 0;
    for (const formGroup in checkboxes) {
      let group = (
        <DreamMultipleSelect
          key={i}
          groupData={checkboxes[formGroup]}
          value={checkboxes[formGroup]}
          groupName={t(formGroup)}
          dispatchContext={props.dispatchContext}
          stateContext={props.stateContext}
        />
      );
      formGroups.push(group);
      i++;
    }

    return formGroups;
  }

  return <div className={Root}>{getFormGroups(state.checkboxes)}</div>;
}

export default DreamCheckboxGroupedComponent;
