import React, { useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import DreamPage from "../../ui_components/DreamPage";
import StateContext from "../../Context/StateContext";
import DispatchContext from "../../Context/DispatchContext";
import { useImmerReducer } from "use-immer";
import Axios from "axios";
import { useTranslation } from "react-i18next";
import * as Constants from "../../helpers/Constants";
import * as MyCvHelper from "./MyCvHelper";
import * as MyCvReducer from "./MyCvReducer";
import * as GeneralSkillsHelper from "../../containers_components/GeneralSkillsComponent/GeneralSkillsHelper";
import * as AdditionalSkillsHelper from "../../containers_components/AdditionalSkillsComponent/AdditionalSkillsHelper";
import * as HobbiesHelper from "../../containers_components/HobbiesComponent/HobbiesHelper";
import * as EducationHelper from "../../containers_components/EducationComponent/EducationHelper";
import * as LanguagesHelper from "../../containers_components/LanguagesComponent/LanguagesHelper";
import * as JobPreferencesHelper from "../../Pages/JobPreferencesPage/JobPreferencesPageHelper";
import DreamAccordionComponent from "../../ui_components/DreamAccordionComponent/DreamAccordionComponent";
import DreamButton from "../../ui_components/DreamButton/DreamButton";
import MyCvStateContext from "./Context/MyCvStateContext";
import MyCvDispatchContext from "./Context/MyCvDispatchContext";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import SchoolIcon from "@material-ui/icons/School";
import BusinessCenterOutlinedIcon from "@material-ui/icons/BusinessCenterOutlined";
import BuildOutlinedIcon from "@material-ui/icons/BuildOutlined";
import EmojiObjectsOutlinedIcon from "@material-ui/icons/EmojiObjectsOutlined";
import FavoriteBorderOutlinedIcon from "@material-ui/icons/FavoriteBorderOutlined";
import LanguageIcon from "@material-ui/icons/Language";
import TurnedInIcon from "@material-ui/icons/TurnedIn";
import EducationComponent from "../../containers_components/EducationComponent/EducationComponent";
import ExperienceComponent from "../../containers_components/ExperienceComponent/ExperienceComponent";
import LanguagesComponent from "../../containers_components/LanguagesComponent/LanguagesComponent";
import GeneralSkillsComponent from "../../containers_components/GeneralSkillsComponent/GeneralSkillsComponent";
import LocationComponent from "../../containers_components/LocationComponent/LocationComponent";
import HobbiesComponent from "../../containers_components/HobbiesComponent/HobbiesComponent";
import AdditionalSkillsComponent from "../../containers_components/AdditionalSkillsComponent/AdditionalSkillsComponent";
import CertificatesComponent from "../../containers_components/CertificatesComponent/CertificatesComponent";

function MyCvComponent() {
  const [state, dispatch] = useImmerReducer(
    MyCvReducer.reducer,
    MyCvReducer.originalState
  );

  const appContext = useContext(StateContext);
  const appDispatch = useContext(DispatchContext);
  const [t] = useTranslation(Constants.TRANSLATION_COMMON);
  const history = useHistory();

  const currentLang = appContext.appState.lang;

  const accordionItems = [
    {
      heading: "cv.location",
      secondaryHeading: "",
      mainIcon: <LocationOnOutlinedIcon />,
      mainContent: <LocationComponent tooltipVisible={currentLang === "bg"} />,
      isMandatory: true,
    },
    {
      heading: "cv.education",
      secondaryHeading: "",
      mainIcon: <SchoolIcon />,
      mainContent: <EducationComponent />,
      isMandatory: true,
    },
    {
      heading: "cv.languages",
      secondaryHeading: "",
      mainIcon: <LanguageIcon />,
      mainContent: <LanguagesComponent />,
      isMandatory: true,
    },
    {
      heading: "cv.experience",
      secondaryHeading: "",
      mainIcon: <BusinessCenterOutlinedIcon />,
      mainContent: <ExperienceComponent />,
    },
    {
      heading: "cv.generalSkills",
      secondaryHeading: "",
      mainIcon: <BuildOutlinedIcon />,
      mainContent: <GeneralSkillsComponent />,
      isMandatory: false,
    },
    {
      heading: "cv.additionalSkills",
      secondaryHeading: "",
      mainIcon: <EmojiObjectsOutlinedIcon />,
      mainContent: <AdditionalSkillsComponent />,
    },
    {
      heading: "cv.hobbies",
      secondaryHeading: "",
      mainIcon: <FavoriteBorderOutlinedIcon />,
      mainContent: <HobbiesComponent />,
    },
    {
      heading: "cv.certificates",
      secondaryHeading: "",
      mainIcon: <TurnedInIcon />,
      mainContent: <CertificatesComponent />,
    },
  ];

  useEffect(() => {
    if (
      !appContext.appState.isCvFilled &&
      history.location.state &&
      history.location.state.fromJPs
    ) {
      appDispatch({
        type: "showMsg",
        value: {
          status: Constants.SNACKBAR_INFO,
          msg: "jps.noCvMessage",
        },
      });
    }
  }, []);

  useEffect(() => {
    const ourRequest = Axios.CancelToken.source();

    MyCvHelper.getCvData(Axios, ourRequest, appDispatch, dispatch);

    GeneralSkillsHelper.getGeneralSkills(
      Axios,
      ourRequest,
      dispatch,
      appDispatch
    );

    AdditionalSkillsHelper.getAdditionalSkills(
      Axios,
      ourRequest,
      dispatch,
      appDispatch
    );

    HobbiesHelper.getHobbies(Axios, ourRequest, dispatch, appDispatch);

    // Get JobPreferences data
    JobPreferencesHelper.getJobPreferences(
      Axios,
      ourRequest,
      appDispatch,
      dispatch
    );

    return () => {
      ourRequest.cancel();
    };
  }, []);

  /**
   * The Form has been successfully submitted (updated)
   */
  useEffect(() => {
    if (state.formSuccessfullySubmittedCount) {
      const ourRequest = Axios.CancelToken.source();
      MyCvHelper.getCvData(Axios, ourRequest, appDispatch, dispatch);

      return () => {
        ourRequest.cancel();
      };
    }
  }, [state.formSuccessfullySubmittedCount]);

  useEffect(() => {
    if (state.generalSkillsGotSkills && state.initialDataLoaded) {
      dispatch({
        type: "preselectGeneralSkills",
      });
    }
  }, [state.generalSkillsGotSkills, state.initialDataLoaded]);

  useEffect(() => {
    if (state.additionalSkillsGotSkills && state.initialDataLoaded) {
      dispatch({
        type: "preselectAdditionalSkills",
      });
    }
  }, [state.additionalSkillsGotSkills, state.initialDataLoaded]);

  useEffect(() => {
    if (state.hobbiesGotHobbies && state.initialDataLoaded) {
      dispatch({
        type: "preselectHobbies",
      });
    }
  }, [state.hobbiesGotHobbies, state.initialDataLoaded]);

  // Get Education levels
  useEffect(() => {
    const ourRequest = Axios.CancelToken.source();
    EducationHelper.getEducationLevels(
      Axios,
      ourRequest,
      dispatch,
      appDispatch,
      t
    );

    return () => {
      ourRequest.cancel();
    };
  }, []);

  // Get Education Broad Types
  useEffect(() => {
    const ourRequest = Axios.CancelToken.source();
    EducationHelper.getEducationBroadTypes(
      Axios,
      ourRequest,
      dispatch,
      appDispatch
    );

    return () => {
      ourRequest.cancel();
    };
  }, []);

  // Get Languages data
  useEffect(() => {
    const ourRequest = Axios.CancelToken.source();
    LanguagesHelper.getLanguages(Axios, ourRequest, dispatch, appDispatch, t);

    return () => {
      ourRequest.cancel();
    };
  }, []);

  let windowHeight = document.documentElement.offsetHeight;

  return (
    <MyCvStateContext.Provider value={state}>
      <MyCvDispatchContext.Provider value={dispatch}>
        <DreamPage title={t("cv.myCV")}>
          <DreamAccordionComponent
            accordionItems={accordionItems}
            accordionSummaryStyle={{ maxHeight: windowHeight < 850 ? 50 : 60 }}
          />
          <br />
          <DreamButton
            buttonDisabled={
              state.disabledSaveButton ||
              (state.formData.certificates.uploadingFiles &&
                state.formData.certificates.uploadingFiles.length > 0)
            }
            handleClick={(e) =>
              MyCvHelper.handleSave(
                state.jobPreferencesCount,
                state.formData,
                dispatch,
                appDispatch,
                Axios
              )
            }
            color="primary"
            kind="contained"
            buttonLabel={t("cv.saveCV")}
          ></DreamButton>
        </DreamPage>
      </MyCvDispatchContext.Provider>
    </MyCvStateContext.Provider>
  );
}

export default MyCvComponent;
