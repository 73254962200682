import React, { useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { useImmerReducer } from "use-immer";
import { useTranslation } from "react-i18next";
import * as Constants from "../../helpers/Constants";
import * as Colors from "../../styles/01.settings/01-settings-colors.module.scss";
import * as JoReducer from "./JobOfferViewPageReducer";
import * as JoHelper from "./JobOfferViewPageHelper";
import DreamAccordion from "../../ui_components/DreamAccordionComponent/DreamAccordionComponent";
import InfoIcon from "@material-ui/icons/Info";
import DreamPage from "../../ui_components/DreamPage";
import DreamAvatar from "../../ui_components/DreamAvatarComponent/DreamAvatarComponent";
import { JoLabel } from "./JobOfferViewPage.module.scss";
import Axios from "axios";
import DispatchContext from "../../Context/DispatchContext";
import StateContext from "../../Context/StateContext";
import DreamCardMedia from "../../ui_components/DreamCardMediaComponent/DreamCardMedia";
import DreamButton from "../../ui_components/DreamButton/DreamButton";
import { Link as RouterLink, useParams } from "react-router-dom";
import TablePagination from "@material-ui/core/TablePagination";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    borderBottom: "none" + "!important",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  table: {
    tableLayout: "fixed",
  },
  tableContainer: {
    maxWidth: 450,
    width: "100%",
  },
  title: {
    fontSize: 18,
    fontWeight: "bold",
    flexGrow: 1,
  },
  tableCellLabel: {
    textAlign: "right",
    borderBottom: "none",
    fontWeight: "bold",
    padding: 8,
  },
  tableCell: {
    textAlign: "left",
    borderBottom: "none",
    padding: 8,
  },
  tblContainer: {
    maxWidth: 450,
  },
  tableRow: {
    display: "flex",
    flexWrap: "wrap",
  },
  actionsButton: {
    width: "10em",
  },
  menuButton: {
    width: "100%",
  },
  subHeader: {
    color: Colors.dark,
    fontSize: 16,
  },
  subHeaderDanger: {
    color: Colors.danger,
  },
  paginationToolbar: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
  },
  //Select filter
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  imageBlur: {
    filter: "blur(8px)",
  },
}));

const pageTitle = "jov.pageTitle";

const JobOfferView = () => {
  const classes = useStyles();
  const [t] = useTranslation(Constants.TRANSLATION_COMMON);
  const appDispatch = useContext(DispatchContext);
  const appContext = useContext(StateContext);

  const { joID } = useParams();

  const [state, dispatch] = useImmerReducer(
    JoReducer.reducer,
    JoReducer.originalState
  );

  const accordionItems = [
    {
      heading: "jocv.summary",
      secondaryHeading: "",
      mainIcon: <InfoIcon />,
      mainContent: <OfferComponent />,
    },
  ];

  function OfferComponent() {
    return (
      <TableContainer className={classes.tableContainer}>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          aria-label="enhanced table"
        >
          <TableHead>
            <TableRow className={classes.tableRow}>
              <TableCell className={classes.title} align="center" colSpan={12}>
                {state.jobOfferTitle}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {JoHelper.offerLabels.map((label, index) => (
              <TableRow key={index} className={classes.tableRow}>
                <TableCell
                  align="right"
                  colSpan={12}
                  className={classes.tableCellLabel}
                >
                  <span className={JoLabel}>{t(label)}</span>
                </TableCell>
                <TableCell
                  align="left"
                  colSpan={12}
                  className={classes.tableCell}
                >
                  {JoHelper.populateOfferData(index, state.jobOffer, t)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  const handleFilterChange = (event) => {
    dispatch({ type: "setFilterSelected", value: event.target.value });
  };

  const handleChangePage = (event, newPage) => {
    dispatch({ type: "setPage", value: newPage });
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch({
      type: "setRowsPerPage",
      value: parseInt(event.target.value, 10),
    });
    dispatch({ type: "setPage", value: 0 });
  };

  const emptyRows =
    state.pagination.page_size -
    Math.min(
      state.pagination.page_size,
      parseInt(state.pagination.totalCount, 10).length -
        state.pagination.page * state.pagination.page_size
    );

  // On mount
  useEffect(() => {
    const ourRequest = Axios.CancelToken.source();
    JoHelper.getOfferData(joID, Axios, ourRequest, appDispatch, dispatch, t);

    return () => {
      ourRequest.cancel();
    };
  }, []);

  // On page_size or page change
  useEffect(() => {
    const ourRequest = Axios.CancelToken.source();
    JoHelper.getCandidates(
      joID,
      state.pagination.page_size,
      state.pagination.page,
      state.filter.selected,
      Axios,
      ourRequest,
      appDispatch,
      dispatch
    );

    return () => {
      ourRequest.cancel();
    };
  }, [
    state.pagination.page_size,
    state.pagination.page,
    state.filter.selected,
  ]);

  return (
    <DreamPage
      title={pageTitle}
      style={{ width: "100%" }}
      backButton
      backButtonTo={`/job-offers`}
      backButtonDisabled={
        !(state.offerDataLoaded && state.candidatesDataLoaded)
      }
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          flexFlow: "row wrap",
          justifyContent: "space-evenly",
          paddingBottom: 10,
        }}
      >
        <DreamAccordion
          accordionWrapperStyle={{
            maxWidth: 450,
            width: "100%",
            display: "flex",
            flexFlow: "column",
            padding: 8,
          }}
          accordionItems={accordionItems}
        />
        <div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: 10,
            }}
          >
            <FormControl className={classes.formControl}>
              <InputLabel id="filter-by">{t("jov.filter")}</InputLabel>
              <Select
                labelId="filter-by"
                id="filter-by"
                value={state.filter.selected}
                onChange={handleFilterChange}
              >
                {state.filter.options.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {t(item.name)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <Grid
            item
            xs={12}
            style={{
              width: "100%",
              maxWidth: 450,
              display: "flex",
              flexFlow: "column",
              alignItems: "center",
              paddingBottom: 10,
            }}
          >
            <TableContainer className={classes.tblContainer}>
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                aria-label="enhanced table"
              >
                <TableBody>
                  {state.candidates.map((candidate) => (
                    <TableRow key={candidate.jpID}>
                      <TableCell className={classes.tableCell}>
                        <DreamCardMedia
                          cardHeader
                          avatar={
                            <div
                              style={{
                                display: "flex",
                                flexFlow: "column",
                                fontSize: 12,
                                fontWeight: "bold",
                              }}
                            >
                              <DreamAvatar
                                classes={
                                  candidate.locked
                                    ? { img: classes.imageBlur }
                                    : {}
                                }
                                imageSource={
                                  candidate.picture
                                    ? `${Constants.BASE_URL}upload/${candidate.picture}`
                                    : ""
                                }
                              />
                              <span style={{ color: Colors.red }}>
                                {candidate.promoted ? t("jov.promoted") : ""}
                              </span>
                            </div>
                          }
                          subHeaderStyle={classes.subHeader}
                          subheader={`${t("jov.name")}: ${
                            candidate.locked ? t("jov.locked") : candidate.name
                          }`}
                          cardActions
                          cardContentObjectStyle={{
                            display: "flex",
                            flexFlow: "column",
                            alignItems: "center",
                            margin: "0 auto",
                          }}
                          cardContentObject={[
                            [
                              `${t("profile.industry")}: `,
                              `${t(`industries.${candidate.industryId}`)}`,
                            ],
                            [
                              `${t("jov.positionName")}: `,
                              `${t(`positions.${candidate.positionId}`)}`,
                            ],
                            [`${t("jov.salary")}: `, `${t("jocv.inRange")}`],
                            [`${t("jov.location")}: `, candidate.location],
                            [
                              `${t("jov.education")}: `,
                              `${t(`educationLevel.${candidate.education}`)}`,
                            ],
                            [
                              `${t("jov.experience")}: `,
                              JoHelper.populateCandidateExperience(
                                candidate.experience,
                                t
                              ),
                            ],
                          ]}
                          cardActionsStyle={{ justifyContent: "flex-end" }}
                          cardActionsChildren={
                            <DreamButton
                              className={classes.actionsButton}
                              kind="contained"
                              buttonLabel={t("jov.view")}
                              color={"primary"}
                              buttonLabelColor={Colors.white}
                              btnComponent={RouterLink}
                              to={`/job-offer-candidate-view/${candidate.id}/${joID}/${candidate.jpID}`}
                            />
                          }
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                  {emptyRows > 0 && (
                    <TableRow>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            <TablePagination
              classes={{ toolbar: classes.paginationToolbar }}
              rowsPerPageOptions={JoHelper.rowsPerPageOptions}
              labelRowsPerPage={t("tablePagination.rowsPerPage")}
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to} ${t("tablePagination.of")} ${
                  count !== -1
                    ? count
                    : `${t("tablePagination.moreThan")} ${to}`
                }`
              }
              component="div"
              count={parseInt(state.pagination.totalCount, 10)}
              rowsPerPage={state.pagination.page_size}
              page={parseInt(state.pagination.page, 10)}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Grid>
        </div>
      </div>
    </DreamPage>
  );
};

export default JobOfferView;
