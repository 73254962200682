import * as Constants from "../../helpers/Constants";
import _ from "lodash";

export async function confirm(
  token,
  Axios,
  ourRequest,
  appDispatch,
  t,
  history
) {
  let params = {
    params: { token: token.token, reghash: token.reghash },
  };

  let errMsgs;
  try {
    const response = await Axios.get("/user/confirm", params, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      cancelToken: ourRequest.token,
    }).catch(function (error) {
      // Errors to be sent to scanckbar
      errMsgs = t("basic.serverError");
      if (error.response) {
        // Request made and server responded
        if (error.response.data.message) {
          errMsgs = JSON.parse(error.response.data.message);
        }
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
      throw errMsgs;
    });

    const responseData = response.data.data;

    let errsArr = [];
    if (!responseData.success) {
      errsArr.push("confirm.unsuccessful");
      throw errsArr;
    }

    handleSuccessfulConfirmed(appDispatch, t, history);
  } catch (error) {
    appDispatch({ type: "showBackdrop", value: false });
    appDispatch({
      type: "showMsg",
      value: { status: Constants.SNACKBAR_ERROR, msg: error },
    });
  }
}

export function handleSuccessfulConfirmed(appDispatch, t, history) {
  appDispatch({ type: "showBackdrop", value: false });
  appDispatch({
    type: "showMsg",
    value: {
      status: Constants.SNACKBAR_SUCCESS,
      msg: t("confirm.successfullyConfirmed"),
    },
  });

  history.push("/");
}
