import * as Constants from "../../helpers/Constants";

export function formatResponse(data) {
  data.map((element, index) => {
    element.from = element.from ? new Date(element.from) : null;
    element.to = element.to ? new Date(element.to) : null;
    element.presentEducation = !Boolean(element.to);
    element.id = index;
  });

  return data;
}

export async function getEducationLevels(
  Axios,
  ourRequest,
  dispatch,
  appDispatch,
  t
) {
  let errMsgs;
  let authenticated = true;
  try {
    const response = await Axios.get(`${Constants.BASE_URL}dreamjob/elevel`, {
      cancelToken: ourRequest.token,
    }).catch(function (error) {
      // Errors to be sent to snackbar
      errMsgs = "basic.serverError";
      if (
        error.response &&
        error.response.status &&
        error.response.status === Constants.ERROR_RESPONSE_STATUS
      ) {
        authenticated = false;
        errMsgs = "basic.unauthenticated";
      }
      throw errMsgs;
    });

    const responseData = response.data;

    dispatch({
      type: "setSelectsDataEducationLevel",
      value: formatEducationLevel(responseData.data, t),
    });
  } catch (error) {
    appDispatch({
      type: "showMsg",
      value: { status: Constants.SNACKBAR_ERROR, msg: error },
    });
    if (!authenticated) {
      appDispatch({
        type: "logout",
      });
      appDispatch({ type: "openLoginModal", value: true });
    } else {
      dispatch({ type: "disableSubmitButton", value: false });
    }
  }
}

export async function getEducationBroadTypes(
  Axios,
  ourRequest,
  dispatch,
  appDispatch
) {
  let errMsgs;
  let authenticated = true;
  try {
    const response = await Axios.get(`${Constants.BASE_URL}dreamjob/etype`, {
      cancelToken: ourRequest.token,
    }).catch(function (error) {
      // Errors to be sent to snackbar
      errMsgs = "basic.serverError";
      if (
        error.response &&
        error.response.status &&
        error.response.status === Constants.ERROR_RESPONSE_STATUS
      ) {
        authenticated = false;
        errMsgs = "basic.unauthenticated";
      }
      throw errMsgs;
    });

    const responseData = response.data;

    dispatch({
      type: "setSelectsDataEducationBroadType",
      value: formatEducationBroadType(responseData),
    });
  } catch (error) {
    appDispatch({
      type: "showMsg",
      value: { status: Constants.SNACKBAR_ERROR, msg: error },
    });
    if (!authenticated) {
      appDispatch({
        type: "logout",
      });
      appDispatch({ type: "openLoginModal", value: true });
    } else {
      dispatch({ type: "disableSubmitButton", value: false });
    }
  }
}

export const formatEducationLevel = function (data) {
  Object.keys(data).forEach(function (key) {
    data[key] = `educationLevel.${data[key]}`;
  });
  return { data };
};

export const formatEducationBroadType = (responseData) => {
  let data = [];
  responseData.data.forEach((obj, index) => {
    let formattedObj = {};
    Object.keys(obj).map((key) => {
      formattedObj = {
        id: index + 1,
        name: `educationBroad.${obj[key]}`,
      };
    });
    data.push(formattedObj);
  });

  return { data };
};
