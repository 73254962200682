import React from "react";
import { Link } from "react-router-dom";

export default function NotFound() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div style={{ height: 300, marginBottom: 50 }}>
        <h1 style={{ fontSize: 50, fontFamily: "sans-serif" }}>
          404 NOT FOUND
        </h1>
      </div>

      <Link to="/">Go Home</Link>
    </div>
  );
}
