import * as Constants from "../../helpers/Constants";

export async function getHobbies(Axios, ourRequest, dispatch, appDispatch) {
  try {
    const response = await Axios.get(`dreamjob/hobbies`, {
      cancelToken: ourRequest.token,
    }).catch(function (error) {
      throw error;
    });

    let formattedHobbies = formatHobbies(response.data.data);

    dispatch({
      type: "hobbiesGotHobbies",
      value: formattedHobbies,
    });
  } catch (error) {
    appDispatch({
      type: "showMsg",
      value: {
        status: Constants.SNACKBAR_ERROR,
        msg: "basic.errorHobbies",
      },
    });
  }
}

function formatHobbies(data) {
  let formattedHobbies = [];
  for (const property in data) {
    formattedHobbies.push({
      id: property,
      label: `hobbies.${data[property]}`,
      isChecked: false,
    });
  }

  return formattedHobbies;
}
