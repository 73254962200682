import React, { useEffect, useContext } from "react";

import StateContext from "../../Context/StateContext";
import DispatchContext from "../../Context/DispatchContext";
import { useImmerReducer } from "use-immer";
import { useTranslation } from "react-i18next";
import * as Constants from "../../helpers/Constants";
import Axios from "axios";
import { useHistory } from "react-router-dom";
import DreamButton from "../../ui_components/DreamButton/DreamButton";
import TextField from "@material-ui/core/TextField";
import Alert from "@material-ui/lab/Alert";
import DreamPage from "../../ui_components/DreamPage";
import * as ForgottenHelper from "./ForgottenPasswordHelper";
import * as ForgottenReducer from "./ForgottenPasswordReducer";
import ForgottenPasswordDispatchContext from "./Context/ForgottenPasswordDispatchContext";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { Root } from "./ForgottenPassword.module.scss";

const useStyles = makeStyles(() => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: 380,
    width: "100%",
    marginTop: "4em",
    boxShadow: "0px 10px 10px rgba(0, 0, 0, 0.25)",
    borderRadius: 15,
  },
  dialogTitle: {
    width: "100%",
    paddingLeft: 50,
    paddingTop: 20,
    paddingBottom: 20,
    textAlign: "left",
    fontSize: 18,
    fontWeight: "bold",
    borderBottom: "2px solid lightgrey",
  },
  sendEmailMsg: {
    marginBottom: "3em",
  },
  contentWrapper: {
    padding: "2em",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

function ForgottenPasswordPage() {
  const appDispatch = useContext(DispatchContext);
  const classes = useStyles();
  let history = useHistory();
  const [t] = useTranslation(Constants.TRANSLATION_COMMON);
  const [state, dispatch] = useImmerReducer(
    ForgottenReducer.reducer,
    ForgottenReducer.originalState
  );

  /**
   * The Form has been submitted
   */
  // useEffect(() => {
  //   if (state.successfullySent) {
  //     appDispatch({ type: "showBackdrop", value: true });
  //     const ourRequest = Axios.CancelToken.source();
  //     LoginHelper.login(state, Axios, ourRequest, appDispatch, dispatch, t);
  //     return () => {
  //       ourRequest.cancel();
  //     };
  //   }
  // }, [state.successfullySent]);

  useEffect(() => {
    return () => {
      history.push("/");
    };
  }, [state.successfullySentCounter > 0]);

  const content = (
    <Paper className={classes.paper}>
      <span className={classes.dialogTitle}>
        {t("forgottenPassword.reset")}
      </span>
      <div className={classes.contentWrapper}>
        <p className={classes.sendEmailMsg}>
          {t("forgottenPassword.sendEmailMsg")}
        </p>
        <TextField
          autoComplete="off"
          margin="dense"
          id="email"
          name="email"
          fullWidth
          label={t("registerForm.email")}
          value={state.fields.email.value ? state.fields.email.value : ""}
          onChange={(e) =>
            dispatch({
              type: "emailChange",
              value: { value: e.target.value, t },
            })
          }
        />
        {state.fields.email.errorMessage && (
          <Alert severity="error">{state.fields.email.errorMessage}</Alert>
        )}
        <DreamButton
          dreamButtonStyle={{
            width: 80,
            marginTop: "2em",
          }}
          buttonType="submit"
          buttonDisabled={Boolean(state.hasValidationErrs)}
          handleClick={(e) =>
            ForgottenHelper.getLink(
              e,
              state.fields.email.value,
              Axios,
              appDispatch,
              dispatch,
              t
            )
          }
          color="primary"
          kind="contained"
          buttonLabel="basic.send"
        ></DreamButton>
      </div>
    </Paper>
  );

  return (
    <ForgottenPasswordDispatchContext.Provider value={dispatch}>
      <DreamPage className={Root} title={t("forgottenPassword.reset")}>
        {content}
      </DreamPage>
    </ForgottenPasswordDispatchContext.Provider>
  );
}

export default ForgottenPasswordPage;
