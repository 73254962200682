import React, { useContext } from "react";
import DreamPage from "../../ui_components/DreamPage";
import * as RouteConstants from "../../AppComponent/AppRoutes";
import StateContext from "../../Context/StateContext";

const PrivacyPage = () => {
  const appContext = useContext(StateContext);
  let lang = appContext.appState.lang;
  let container = require("./privacy_" + lang + ".html.js");

  return (
    <DreamPage
      title={"footer.privacy"}
      route={RouteConstants.ROUTE_PRIVACY_POLICY}
    >
      <div
        style={{ maxWidth: 900, marginLeft: "3%", marginRight: "3%" }}
        className="terms"
        dangerouslySetInnerHTML={{
          __html: container,
        }}
      ></div>
    </DreamPage>
  );
};

export default PrivacyPage;
