export const originalState = {
  id: "",
  level: "",
  language: "",
};

export function reducer(draft, action) {
  switch (action.type) {
    case "setId":
      draft.id = action.value;
      return;
    case "setLevel":
      draft.level = action.value;
      return;
    case "setLanguage":
      draft.language = action.value;
      return;
  }
}
