import React, { useState, useContext, useEffect } from "react";
import Axios from "axios";
import Grid from "@material-ui/core/Grid";
import DispatchContext from "../../../Context/DispatchContext";
import StateContext from "../../../Context/StateContext";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import * as AppRoutes from "../../../AppComponent/AppRoutes";
import { useTranslation } from "react-i18next";
import { useImmerReducer } from "use-immer";
import * as Constants from "../../../helpers/Constants";
import DreamPage from "../../../ui_components/DreamPage";
import DreamButton from "../../../ui_components/DreamButton/DreamButton";
import Alert from "@material-ui/lab/Alert";
import * as ChangePasswordHelper from "./ChangePasswordHelper";
import * as ChangePasswordReducer from "./ChangePasswordReducer";
import * as Colors from "../../../styles/01.settings/01-settings-colors.module.scss";

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    marginTop: "5em",
  },
  gridItem: {
    minWidth: 450,
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: 450,
    width: "100%",
    minHeight: 135,
    padding: 30,
    boxShadow: "0px 10px 10px rgba(0, 0, 0, 0.25)",
    borderRadius: 15,
  },
  inputField: {
    maxWidth: 330,
    width: "100%",
  },
}));

const ChangePassword = () => {
  const classes = useStyles();
  const appDispatch = useContext(DispatchContext);
  const appContext = useContext(StateContext);
  const [t] = useTranslation(Constants.TRANSLATION_COMMON);
  const userId = appContext.appState.user_id;

  const [state, dispatch] = useImmerReducer(
    ChangePasswordReducer.reducer,
    ChangePasswordReducer.originalState
  );

  useEffect(() => {
    if (state.fields.password.value === "") {
      dispatch({ type: "resetPassword" });
      dispatch({ type: "resetPasswordConfirm" });
    }
  }, [state.fields.password.value]);

  /**
   * The Form has been submitted
   */
  useEffect(() => {
    if (state.formSubmittedCount) {
      appDispatch({ type: "showBackdrop", value: true });
      const ourRequest = Axios.CancelToken.source();

      ChangePasswordHelper.updateProfilePassword(
        userId,
        state,
        ChangePasswordHelper.USER_PROFILE_PICTURE, // image type 1 (profile picture)
        Axios,
        ourRequest,
        appDispatch,
        dispatch,
        t
      );

      return () => {
        ourRequest.cancel();
      };
    }
  }, [state.formSubmittedCount]);

  return (
    <DreamPage
      title={t("accountSettings.changePassword")}
      backButton
      backButtonTo={AppRoutes.ROUTE_ACCOUNT_SETTINGS}
    >
      <Grid
        container
        spacing={3}
        direction="column"
        classes={{ root: classes.gridContainer }}
      >
        <Grid item classes={{ root: classes.gridItem }}>
          <Paper elevation={3} className={classes.paper}>
            <TextField
              autoComplete="off"
              margin="dense"
              id="password"
              name="password"
              label={t("registerForm.password")}
              type="password"
              fullWidth
              value={
                state.fields.password.value ? state.fields.password.value : ""
              }
              onChange={(e) =>
                dispatch({
                  type: "passwordChange",
                  value: { value: e.target.value, t },
                })
              }
            />
            {state.fields.password.errorMessage && (
              <Alert severity="error">
                {state.fields.password.errorMessage}
              </Alert>
            )}
            <TextField
              autoComplete="off"
              margin="dense"
              id="passwordConfirm"
              name="passwordConfirm"
              label={t("registerForm.passwordConfirm")}
              type="password"
              fullWidth
              value={
                state.fields.passwordConfirm.value
                  ? state.fields.passwordConfirm.value
                  : ""
              }
              onChange={(e) =>
                dispatch({
                  type: "passwordConfirmChange",
                  value: { value: e.target.value, t },
                })
              }
            />
            {state.fields.passwordConfirm.errorMessage && (
              <Alert severity="error">
                {state.fields.passwordConfirm.errorMessage}
              </Alert>
            )}
          </Paper>
        </Grid>
        <Grid item classes={{ root: classes.gridItem }}>
          <DreamButton
            buttonType="button"
            dreamButtonStyle={{
              minWidth: 130,
              marginTop: "2em",
              boxShadow: "0px 10px 10px rgba(0, 0, 0, 0.25)",
            }}
            disabled={Boolean(state.hasValidationErrs)}
            handleClick={(e) => ChangePasswordHelper.handleSubmit(e, dispatch)}
            color="primary"
            kind="contained"
            buttonLabel="basic.save"
          ></DreamButton>
        </Grid>
      </Grid>
    </DreamPage>
  );
};

export default ChangePassword;
