import * as Constants from "../../../helpers/Constants";

export const USER_PROFILE_PICTURE = 1;
export const COMPANY_PROFILE_LOGO = 2;

export async function updateProfilePassword(
  userId,
  state,
  imageType,
  Axios,
  ourRequest,
  appDispatch,
  dispatch,
  t
) {
  dispatch({ type: "disableSubmitButton", value: true });
  let errMsgs;
  let authenticated = true;

  try {
    let formData = new FormData();

    switch (imageType) {
      case USER_PROFILE_PICTURE:
        if (
          state.fields.password.value !== "" &&
          state.fields.password.value === state.fields.passwordConfirm.value
        ) {
          formData.append("password", state.fields.password.value);
        }
        break;
      default:
        break;
    }

    const response = await Axios.post(`/user/${userId}`, formData, {
      headers: {
        "content-type": `multipart/form-data; boundary=${formData._boundary}`,
      },
      cancelToken: ourRequest.token,
    }).catch(function (error) {
      // Errors to be sent to scanckbar
      errMsgs = t("basic.serverError");
      if (
        error.response &&
        error.response.status &&
        error.response.status === Constants.ERROR_RESPONSE_STATUS
      ) {
        authenticated = false;
        errMsgs = t("basic.unauthenticated");
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
      throw errMsgs;
    });

    const responseData = response.data.data;

    let errsArr = [];
    if (!responseData.success && responseData.messages.length) {
      responseData.messages.forEach((msg) => {
        errsArr.push(`serverErrors.${msg.code}`);
      });
      throw errsArr;
    }
    dispatch({ type: "disableSubmitButton", value: false });
    handleSuccessfulUpdated(appDispatch, dispatch);
    dispatch({ type: "formSuccessfullySubmitted" });
  } catch (error) {
    appDispatch({ type: "showBackdrop", value: false });
    appDispatch({
      type: "showMsg",
      value: { status: Constants.SNACKBAR_ERROR, msg: error },
    });
    dispatch({ type: "disableSubmitButton", value: false });

    if (!authenticated) {
      appDispatch({
        type: "logout",
      });

      appDispatch({ type: "openLoginModal", value: true });
    } else {
      dispatch({ type: "disableSubmitButton", value: false });
    }
  }
}

/**
 * Successfuly updated
 * @param {appDispatch} appDispatch
 */
function handleSuccessfulUpdated(appDispatch, dispatch) {
  dispatch({ type: "resetPassword" });
  dispatch({ type: "resetPasswordConfirm" });
  appDispatch({ type: "showBackdrop", value: false });
  appDispatch({
    type: "showMsg",
    value: {
      status: Constants.SNACKBAR_SUCCESS,
      msg: "basic.saved",
    },
  });
}

/**
 * Handle Submit
 * @param {e} e
 * @param {dispatch} dispatch
 */
export function handleSubmit(e, dispatch) {
  e.preventDefault();
  dispatch({ type: "formSubmitted" });
}
