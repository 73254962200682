import React, { useContext, useEffect, useRef } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useImmerReducer } from "use-immer";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import Fab from "@material-ui/core/Fab";
import SendIcon from "@material-ui/icons/Send";
import { useTranslation } from "react-i18next";
import * as Constants from "../../helpers/Constants";
import * as DreamChatReducer from "./DreamChatReducer";
import Axios from "axios";
import * as DcHelper from "./DreamChatHelper";
import * as DcReducer from "./DreamChatReducer";
import DispatchContext from "../../Context/DispatchContext";
import AppContext from "../../Context/StateContext";
import uuid from "react-uuid";
import * as PollingHook from "../../helpers/Functions/PollingHook/PollingHook";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
  Sender,
  Recipient,
  SenderContainer,
  RecipientContainer,
} from "./DreamChat.module.scss";

const Chat = (props) => {
  const [state, dispatch] = useImmerReducer(
    DreamChatReducer.reducer,
    DreamChatReducer.originalState
  );
  const appDispatch = useContext(DispatchContext);
  const appContext = useContext(AppContext);

  const [t] = useTranslation(Constants.TRANSLATION_COMMON);
  const theme = useTheme();
  const matchesXSdown = useMediaQuery(theme.breakpoints.down("xs"));
  const matchesMDup = useMediaQuery(theme.breakpoints.up("md"));

  let uuId = uuid();

  const useStyles = makeStyles({
    root: {
      maxWidth: 450,
      width: "100%",
      borderRadius: 15,
      paddingBottom: 10,
    },
    chatSection: {
      maxWidth: 450,
      borderRadius: 15,
      height: matchesXSdown ? 360 : 600,
      border: "1px solid rgba(0, 0, 0, 0.1)",
      boxShadow: "0px 5px 13px rgba(0, 0, 0, 0.25)",
    },
    headBG: {
      backgroundColor: "#e0e0e0",
    },
    borderRight500: {
      height: matchesXSdown ? 60 : 70,
      borderBottom: "1px solid #e0e0e0",
    },
    messagesWrapper: {
      height: matchesXSdown ? 230 : 430,
      borderBottom: "1px solid #e0e0e0",
    },
    messageArea: {
      height: "100%",
      overflowY: "auto",
    },
    inputWrapper: {
      flexGrow: 1,
      display: "flex",
      flexWrap: "nowrap",
      justifyContent: "space-between",
      alignItems: "center",
      height: matchesXSdown ? 70 : 100,
      paddingLeft: "20px",
      paddingRight: "20px",
    },
    chatInput: {
      width: "100%",
      maxWidth: matchesXSdown ? 200 : "100%",
    },
    chatSendBtnWrapper: {
      maxWidth: "100%",
    },
    avatarSmall: {
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
    avatarMedium: {
      width: theme.spacing(5),
      height: theme.spacing(5),
    },
  });
  const classes = useStyles();

  useEffect(() => {
    if (props.dataLoaded) {
      DcHelper.getMessages(props.jpId, props.eId, Axios, appDispatch, dispatch);
    }
  }, [props.dataLoaded, appContext.appState.user_id]);

  PollingHook.useInterval(async () => {
    await DcHelper.getMessages(
      props.jpId,
      props.eId,
      Axios,
      appDispatch,
      dispatch
    );
  }, DcHelper.REFRESH_INTERVAL);

  useEffect(() => {}, [state.messages.length]);

  const Messages = ({ messages }) => {
    const messagesEndRef = useRef(null);

    const scrollToBottom = () => {
      messagesEndRef.current.scrollIntoView({ behavior: "auto" });
    };

    return (
      <List className={classes.messageArea}>
        {state.messages.map((message) => (
          <ListItem key={message.id}>
            <Grid
              container
              className={
                appContext.appState.user_id == message.sender
                  ? SenderContainer
                  : RecipientContainer
              }
            >
              <div
                className={
                  appContext.appState.user_id == message.sender
                    ? Sender
                    : Recipient
                }
              >
                <Grid item xs={12}>
                  <ListItemText primary={message.body}></ListItemText>
                </Grid>
                <Grid item xs={12}>
                  <ListItemText
                    secondary={message.createdOn.toString()}
                  ></ListItemText>
                </Grid>
              </div>
            </Grid>
          </ListItem>
        ))}
      </List>
    );
  };

  return (
    <Grid
      container
      justify="center"
      className={classes.root}
      style={props.chatContainerStyle}
    >
      <Grid
        container
        item
        xs={12}
        component={Paper}
        className={classes.chatSection}
      >
        <Grid item xs={12} className={classes.borderRight500}>
          <List>
            <ListItem button key="info">
              <ListItemIcon>
                <Avatar
                  alt={props.name}
                  src={props.image}
                  className={
                    matchesXSdown ? classes.avatarSmall : classes.avatarMedium
                  }
                />
              </ListItemIcon>
              <ListItemText primary={props.name}>{props.name}</ListItemText>
            </ListItem>
          </List>
          {/* <Divider /> */}
        </Grid>
        <Grid item xs={12} className={classes.messagesWrapper}>
          <Messages />
          {/* <Divider /> */}
        </Grid>
        <Grid container className={classes.inputWrapper}>
          <Grid item xs={10}>
            <TextField
              classes={{ root: classes.chatInput }}
              id="outlined-basic-email"
              fullWidth
              autoComplete="off"
              margin="dense"
              name="offerName"
              label={t("chat.typeSomething")}
              value={state.formData.messageText}
              onChange={(e) =>
                dispatch({
                  type: "setMessage",
                  value: e.target.value,
                })
              }
            />
          </Grid>
          <Grid
            item
            xs={1}
            align="right"
            className={classes.chatSendBtnWrapper}
          >
            <Fab
              size={matchesXSdown ? "medium" : "large"}
              type="submit"
              color="primary"
              aria-label="send"
              onClick={(e) =>
                DcHelper.handleSend(
                  state.formData,
                  props.jpId,
                  props.eId ? props.eId : "",
                  dispatch,
                  appDispatch,
                  Axios,
                  uuId,
                  appContext.appState.user_id
                )
              }
            >
              <SendIcon />
            </Fab>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Chat;
