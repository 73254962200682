import React, { useEffect, useContext } from "react";
import DreamPage from "../../ui_components/DreamPage";
import StateContext from "../../Context/StateContext";
import DispatchContext from "../../Context/DispatchContext";
import { useImmerReducer } from "use-immer";
import Axios from "axios";
import { useParams } from "react-router-dom";

import * as MyCvHelper from "../MyCvPage/MyCvHelper";
import * as JobPreferenceReducer from "./CreateJobPreferenceReducer";
import * as GeneralSkillsHelper from "../../containers_components/GeneralSkillsComponent/GeneralSkillsHelper";
import * as AdditionalSkillsHelper from "../../containers_components/AdditionalSkillsComponent/AdditionalSkillsHelper";
import * as HobbiesHelper from "../../containers_components/HobbiesComponent/HobbiesHelper";
import * as EducationHelper from "../../containers_components/EducationComponent/EducationHelper";
import * as LanguagesHelper from "../../containers_components/LanguagesComponent/LanguagesHelper";

import DreamAccordionComponent from "../../ui_components/DreamAccordionComponent/DreamAccordionComponent";
import DreamButton from "../../ui_components/DreamButton/DreamButton";
import { useTranslation } from "react-i18next";
import JobPreferenceDispatchContext from "./Context/JobPreferenceDispatchContext";
import JobPreferenceStateContext from "./Context/JobPreferenceStateContext";
import * as CreateJobPreferenceHelper from "./CreateJobPreferenceHelper";
import { useHistory } from "react-router-dom";
import * as AppRoutes from "../../AppComponent/AppRoutes";
import * as Constants from "../../helpers/Constants";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import ImportContactsTwoToneIcon from "@material-ui/icons/ImportContactsTwoTone";
import ChatBubbleOutlineTwoToneIcon from "@material-ui/icons/ChatBubbleOutlineTwoTone";
import BusinessCenterOutlinedIcon from "@material-ui/icons/BusinessCenterOutlined";
import BuildOutlinedIcon from "@material-ui/icons/BuildOutlined";
import EmojiObjectsOutlinedIcon from "@material-ui/icons/EmojiObjectsOutlined";
import FavoriteBorderOutlinedIcon from "@material-ui/icons/FavoriteBorderOutlined";
import CardMembershipIcon from "@material-ui/icons/CardMembership";
import LocationsComponent from "../../containers_components/LocationsComponent/LocationsComponent";
import PositionComponent from "../../containers_components/PositionComponent/PositionComponent";
import WorkPlaceComponent from "../../containers_components/WorkPlaceComponent/WorkPlaceComponent";
import EmploymentTypeComponent from "../../containers_components/EmploymentTypeComponent/EmploymentTypeComponent";
import WorkScheduleComponent from "../../containers_components/WorkScheduleComponent/WorkScheduleComponent";
import PreferredStartComponent from "../../containers_components/PreferredStartComponent/PreferredStartComponent";
import AdditionalBenefitsComponent from "../../containers_components/AdditionalBenefitsComponent/AdditionalBenefitsComponent";
import SalaryRangeComponent from "../../containers_components/SalaryRangeComponent/SalaryRangeComponent";

import LocationComponent from "../../containers_components/LocationComponent/LocationComponent";
import EducationComponent from "../../containers_components/EducationComponent/EducationComponent";
import LanguagesComponent from "../../containers_components/LanguagesComponent/LanguagesComponent";
import ExperienceComponent from "../../containers_components/ExperienceComponent/ExperienceComponent";
import GeneralSkillsComponent from "../../containers_components/GeneralSkillsComponent/GeneralSkillsComponent";
import AdditionalSkillsComponent from "../../containers_components/AdditionalSkillsComponent/AdditionalSkillsComponent";
import HobbiesComponent from "../../containers_components/HobbiesComponent/HobbiesComponent";
import CertificatesComponent from "../../containers_components/CertificatesComponent/CertificatesComponent";

import * as WorkPlaceHelper from "../../containers_components/WorkPlaceComponent/WorkPlaceHelper";
import * as EmpoymentTypeHelper from "../../containers_components/EmploymentTypeComponent/EmploymentTypeHelper";
import * as WorkScheduleHelper from "../../containers_components/WorkScheduleComponent/WorkScheduleHelper";
import * as PreferedStartHelper from "../../containers_components/PreferredStartComponent/PreferredStartHelper";
import * as SalaryRangeHelper from "../../containers_components/SalaryRangeComponent/SalaryRangeHelper";

/**
 * This component is used for Crete & Update of Job Preference
 * @param {*} props
 */
const CreateJobPreferencePage = () => {
  const history = useHistory();
  const [t] = useTranslation(Constants.TRANSLATION_COMMON);
  // State
  const originalState = {
    jpId: "", // If this page is used to Update JP, we need JP ID
    formData: {
      locations: {
        any: { id: "1", label: "jp.anyLocation", isChecked: true },
        locations: [],
      },
      position: {
        industryId: "",
        positionId: "",
        positionName: "",
        industryChangedCounter: 0,
      },
      workPlaceType: {
        workPlaceType: WorkPlaceHelper.ANY_WORK,
      },
      employmentType: {
        employmentType: EmpoymentTypeHelper.ANY_TIME,
      },
      salaryRange: {
        type: SalaryRangeHelper.MONTHLY,
        salary: 1,
        currency: "",
      },
      workScheduleType: {
        workScheduleType: WorkScheduleHelper.ANY_SHIFT,
      },
      preferredStartType: {
        preferredStartType: PreferedStartHelper.ANY,
      },
      additionalBenefits: [
        {
          id: "paid_leave",
          label: "jp.additionalBenefits.additionalPaidLeave",
          isChecked: false,
        },
        {
          id: "food_vouchers",
          label: "jp.additionalBenefits.foodVouchers",
          isChecked: false,
        },
        {
          id: "housing",
          label: "jp.additionalBenefits.housing",
          isChecked: false,
        },
        {
          id: "car",
          label: "jp.additionalBenefits.car",
          isChecked: false,
        },
        {
          id: "phone",
          label: "jp.additionalBenefits.phone",
          isChecked: false,
        },
      ],
      cv: {
        location: {},
        education: [],
        languages: [],
        experience: [],
        generalSkills: [],
        additionalSkills: [],
        hobbies: [],
        certificates: [],
        isCvChanged: false, // indicates if new CV should be created or not
      },
    },
    data: {
      cv: {
        hasCV: false,
        initialDataLoaded: false,
        generalSkillsGotSkills: false,
        generalSkillsInitiallyChecked: [],
        additionalSkillsGotSkills: false,
        additionalSkillsInitiallyChecked: [],
        hobbiesGotHobbies: false,
        hobbiesInitiallyChecked: [],
        initialFileUploads: [],
        selectsData: {
          education: {
            level: {
              data: {},
              isLoaded: false,
            },
            broadType: {
              data: {},
              isLoaded: false,
            },
          },
          languages: {
            lang: {
              data: {},
              isLoaded: false,
            },
            level: {
              extra_data: {},
              isLoaded: false,
            },
          },
        },
      },
    },
    disabledSaveButton: false,
    initialDataLoaded: false,
    formSuccessfullySubmittedCount: 0,
  };
  //
  const [state, dispatch] = useImmerReducer(
    JobPreferenceReducer.reducer,
    originalState
  );

  const appContext = useContext(StateContext);
  const appDispatch = useContext(DispatchContext);

  // If this page is used to Update JP, we need JP ID
  const { jpId } = useParams();

  /**
   * Get CV Data
   */
  useEffect(() => {
    const ourRequest = Axios.CancelToken.source();

    // If this page is used to Update JP, we need JP ID
    if (jpId) {
      dispatch({
        type: "setJPid",
        value: jpId,
      });
      CreateJobPreferenceHelper.getJPdata(
        jpId,
        Axios,
        ourRequest,
        appDispatch,
        dispatch
      );
    }

    MyCvHelper.getCvData(Axios, ourRequest, appDispatch, dispatch, jpId);

    GeneralSkillsHelper.getGeneralSkills(
      Axios,
      ourRequest,
      dispatch,
      appDispatch
    );

    AdditionalSkillsHelper.getAdditionalSkills(
      Axios,
      ourRequest,
      dispatch,
      appDispatch
    );

    HobbiesHelper.getHobbies(Axios, ourRequest, dispatch, appDispatch);

    return () => {
      ourRequest.cancel();
    };
  }, []);

  /**
   * Get CV preselected General Skills
   */
  useEffect(() => {
    if (
      state.data.cv.generalSkillsGotSkills &&
      state.data.cv.initialDataLoaded
    ) {
      dispatch({
        type: "preselectGeneralSkills",
      });
    }
  }, [state.data.cv.generalSkillsGotSkills, state.data.cv.initialDataLoaded]);

  /**
   * Get CV preselected Additional Skills
   */
  useEffect(() => {
    if (
      state.data.cv.additionalSkillsGotSkills &&
      state.data.cv.initialDataLoaded
    ) {
      dispatch({
        type: "preselectAdditionalSkills",
      });
    }
  }, [
    state.data.cv.additionalSkillsGotSkills,
    state.data.cv.initialDataLoaded,
  ]);

  /**
   * CV preselected Hobbies
   */
  useEffect(() => {
    if (state.data.cv.hobbiesGotHobbies && state.data.cv.initialDataLoaded) {
      dispatch({
        type: "preselectHobbies",
      });
    }
  }, [state.data.cv.hobbiesGotHobbies, state.data.cv.initialDataLoaded]);

  /**
   * Get CV -> Education levels
   */
  useEffect(() => {
    const ourRequest = Axios.CancelToken.source();
    EducationHelper.getEducationLevels(
      Axios,
      ourRequest,
      dispatch,
      appDispatch
    );

    return () => {
      ourRequest.cancel();
    };
  }, []);

  /**
   * Get CV -> Education Broad Types
   */
  useEffect(() => {
    const ourRequest = Axios.CancelToken.source();
    EducationHelper.getEducationBroadTypes(
      Axios,
      ourRequest,
      dispatch,
      appDispatch
    );

    return () => {
      ourRequest.cancel();
    };
  }, []);

  /**
   * Get Get CV -> Languages data
   */
  useEffect(() => {
    const ourRequest = Axios.CancelToken.source();
    LanguagesHelper.getLanguages(Axios, ourRequest, dispatch, appDispatch);

    return () => {
      ourRequest.cancel();
    };
  }, []);

  const lang = appContext.appState.lang;

  useEffect(() => {
    dispatch({
      type: "setTooltipVisible",
      value: Boolean(appContext.appState.lang === "bg"),
    });
  }, [appContext.appState.lang]);

  useEffect(() => {
    let selectedCurrency = "";
    switch (lang) {
      case "en-US":
        selectedCurrency = "USD";
        break;
      case "en":
        selectedCurrency = "EUR";
        break;
      case "bg":
        selectedCurrency = "BGN";
        break;
    }

    dispatch({ type: "salaryRangeCurrencyChanged", value: selectedCurrency });
  }, []);

  const cvAccordionItems = [
    {
      heading: "cv.location",
      secondaryHeading: "",
      mainIcon: <LocationOnOutlinedIcon />,
      mainContent: (
        <LocationComponent
          tooltipVisible={state.tooltipVisible}
          stateContextName={"jobPreferenceStateContext"}
          dispatchContextName={"jobPreferenceDispatchContext"}
        />
      ),
      isMandatory: true,
    },
    {
      heading: "cv.education",
      secondaryHeading: "",
      mainIcon: <ImportContactsTwoToneIcon />,
      mainContent: (
        <EducationComponent
          stateContextName={"jobPreferenceStateContext"}
          dispatchContextName={"jobPreferenceDispatchContext"}
        />
      ),
      isMandatory: true,
    },
    {
      heading: "cv.languages",
      secondaryHeading: "",
      mainIcon: <ChatBubbleOutlineTwoToneIcon />,
      mainContent: (
        <LanguagesComponent
          stateContextName={"jobPreferenceStateContext"}
          dispatchContextName={"jobPreferenceDispatchContext"}
        />
      ),
      isMandatory: true,
    },
    {
      heading: "cv.experience",
      secondaryHeading: "",
      mainIcon: <BusinessCenterOutlinedIcon />,
      mainContent: (
        <ExperienceComponent
          stateContextName={"jobPreferenceStateContext"}
          dispatchContextName={"jobPreferenceDispatchContext"}
        />
      ),
    },
    {
      heading: "cv.generalSkills",
      secondaryHeading: "",
      mainIcon: <BuildOutlinedIcon />,
      mainContent: (
        <GeneralSkillsComponent
          stateContextName={"jobPreferenceStateContext"}
          dispatchContextName={"jobPreferenceDispatchContext"}
        />
      ),
      isMandatory: false,
    },
    {
      heading: "cv.additionalSkills",
      secondaryHeading: "",
      mainIcon: <EmojiObjectsOutlinedIcon />,
      mainContent: (
        <AdditionalSkillsComponent
          stateContextName={"jobPreferenceStateContext"}
          dispatchContextName={"jobPreferenceDispatchContext"}
        />
      ),
    },
    {
      heading: "cv.hobbies",
      secondaryHeading: "",
      mainIcon: <FavoriteBorderOutlinedIcon />,
      mainContent: (
        <HobbiesComponent
          stateContextName={"jobPreferenceStateContext"}
          dispatchContextName={"jobPreferenceDispatchContext"}
        />
      ),
    },
    {
      heading: "cv.certificates",
      secondaryHeading: "",
      mainIcon: <CardMembershipIcon />,
      mainContent: (
        <CertificatesComponent
          stateContextName={"jobPreferenceStateContext"}
          dispatchContextName={"jobPreferenceDispatchContext"}
        />
      ),
    },
  ];

  let accordionItemsJP = [
    {
      heading: "jp.locations",
      secondaryHeading: "",
      mainIcon: <LocationOnOutlinedIcon />,
      mainContent: <LocationsComponent />,
      isMandatory: true,
    },
    {
      heading: "cv.jobTitle",
      secondaryHeading: "",
      mainIcon: <ImportContactsTwoToneIcon />,
      mainContent: (
        <PositionComponent
          isPositionMandatory={true}
          isIndustryMandatory={true}
        />
      ),
      isMandatory: true,
    },
    {
      heading: "jp.workplace",
      secondaryHeading: "",
      mainIcon: <ChatBubbleOutlineTwoToneIcon />,
      mainContent: <WorkPlaceComponent />,
      isMandatory: true,
    },
    {
      heading: "jp.employment",
      secondaryHeading: "",
      mainIcon: <BusinessCenterOutlinedIcon />,
      mainContent: <EmploymentTypeComponent />,
      isMandatory: true,
    },
    {
      heading: "jp.salary",
      secondaryHeading: "",
      mainIcon: <BuildOutlinedIcon />,
      mainContent: <SalaryRangeComponent />,
      isMandatory: true,
    },
    {
      heading: "jp.work",
      secondaryHeading: "",
      mainIcon: <EmojiObjectsOutlinedIcon />,
      mainContent: <WorkScheduleComponent />,
      isMandatory: true,
    },
    {
      heading: "jp.start",
      secondaryHeading: "",
      mainIcon: <FavoriteBorderOutlinedIcon />,
      mainContent: <PreferredStartComponent />,
      isMandatory: true,
    },
    {
      heading: "jp.benefits",
      secondaryHeading: "",
      mainIcon: <CardMembershipIcon />,
      mainContent: <AdditionalBenefitsComponent />,
    },
    {
      heading: "jp.cv",
      secondaryHeading: "",
      mainIcon: <CardMembershipIcon />,
      mainContent: (
        <DreamAccordionComponent accordionItems={cvAccordionItems} />
      ),
    },
  ];

  useEffect(() => {
    if (state.formData.position.industryChangedCounter > 1) {
      dispatch({
        type: "positionPositionChanged",
        value: { id: "", name: "" },
      });
    }
  }, [state.formData.position.industryChangedCounter]);

  /**
   * On successfully submited form
   */
  useEffect(() => {
    if (state.formSuccessfullySubmittedCount > 0) {
      history.push(AppRoutes.ROUTE_JOB_PREFERENCES);
    }
  }, [state.formSuccessfullySubmittedCount]);

  return (
    <JobPreferenceStateContext.Provider value={state}>
      <JobPreferenceDispatchContext.Provider value={dispatch}>
        <DreamPage
          title={!jpId ? t("jp.createJP") : t("jp.editJP")}
          backButton
          backButtonTo={`/job-preferences/${jpId}`}
        >
          <DreamAccordionComponent accordionItems={accordionItemsJP} />
          <br />
          <DreamButton
            dreamButtonStyle={{ minWidth: 130 }}
            buttonDisabled={
              state.disabledSaveButton ||
              (state.formData.cv.certificates.uploadingFiles &&
                state.formData.cv.certificates.uploadingFiles.length > 0)
            }
            handleClick={(e) =>
              CreateJobPreferenceHelper.handleSave(
                state.formData,
                dispatch,
                appDispatch,
                Axios,
                jpId
              )
            }
            color="primary"
            kind="contained"
            buttonLabel="jp.save"
          ></DreamButton>
        </DreamPage>
      </JobPreferenceDispatchContext.Provider>
    </JobPreferenceStateContext.Provider>
  );
};

export default CreateJobPreferencePage;
