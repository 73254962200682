import * as Constants from "../../../helpers/Constants";

//Get invoice details
export async function getInvoiceDetails(
  Axios,
  ourRequest,
  appDispatch,
  dispatch
) {
  appDispatch({ type: "showBackdrop", value: true });

  dispatch({ type: "setIsLoading", value: true });

  let errMsgs;
  let authenticated = true;

  try {
    const response = await Axios.get(`/dreamjob/invdetails`, {
      cancelToken: ourRequest.token,
    }).catch(function (error) {
      // Errors to be sent to snanckbar
      errMsgs = "basic.serverError";
      if (
        error.response &&
        error.response.status &&
        error.response.status === Constants.ERROR_RESPONSE_STATUS
      ) {
        authenticated = false;
        errMsgs = "basic.unauthenticated";
      }
      dispatch({ type: "setIsLoading", value: false });
      appDispatch({ type: "showBackdrop", value: false });

      throw errMsgs;
    });

    const responseData = response.data.data;
    console.log("Inv::::::::", responseData);
    handleSuccessfulGetData(responseData, appDispatch, dispatch);
  } catch (error) {
    dispatch({ type: "setIsLoading", value: false });
    appDispatch({ type: "showBackdrop", value: true });

    appDispatch({
      type: "showMsg",
      value: { status: Constants.SNACKBAR_ERROR, msg: error },
    });
    if (!authenticated) {
      appDispatch({
        type: "logout",
      });
    } else {
      dispatch({ type: "disableSubmitButton", value: false });
    }
  }
}

export function handleSuccessfulGetData(data, appDispatch, dispatch) {
  dispatch({ type: "invoiceDetailsLoaded", value: data });
  dispatch({ type: "setIsLoading", value: false });
  appDispatch({ type: "showBackdrop", value: false });
}

// DELETE invoice
export function handleDelete(dataId, Axios, appDispatch, dispatch) {
  dispatch({ type: "setModalOpen", value: false });
  dispatch({ type: "confirmDelete" });
  let deleteInvoice = deleteRequest(dataId, Axios);

  if (!deleteInvoice) {
    appDispatch({
      type: "showMsg",
      value: {
        status: Constants.SNACKBAR_ERROR,
        msg: "invoices.notDeletedInvoice",
      },
    });
  }
}

export async function deleteRequest(invId, Axios) {
  let success = true;
  try {
    const response = await Axios.delete(`/dreamjob/invoices/${invId}`).catch(
      function (error) {
        // Errors to be sent to scanckbar
        let errMsgs = "Server error";
        if (error.response) {
          // Request made and server responded
          if (error.response.data.message) {
            errMsgs = JSON.parse(error.response.data.message);
          }
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        throw errMsgs;
      }
    );
  } catch (error) {
    console.log("Error delete Invoice:", error);
    success = false;
  }

  return success;
}
