import * as EducationHelper from "../../containers_components/EducationComponent/EducationHelper";
import * as LanguagesHelper from "../../containers_components/LanguagesComponent/LanguagesHelper";
import * as ExperienceHelper from "../../containers_components/ExperienceComponent/ExperienceHelper";
import * as CertificatesHelper from "../../containers_components/CertificatesComponent/CertificatesHelper";
import { isArray } from "lodash";

export function reducer(draft, action) {
  let foundKey;

  switch (action.type) {
    case "setJPid":
      draft.jpId = action.value;
      return;
    case "setInitialDataLoaded":
      draft.initialDataLoaded = true;
      return;
    /**
     *
     * Locations related events
     *
     */
    case "locationsAddFullData": // used to preload data for update og JP
      draft.formData.locations.locations = action.value;
      draft.locationsLoaded = true;
      return;

    case "locationsLocationAdded":
      draft.formData.locations.locations.push({
        id: action.value,
      });
      return;

    case "locationsLocationRemoved":
      foundKey = draft.formData.locations.locations.findIndex(
        (x) => x.id == action.value
      );
      if (foundKey >= 0) {
        draft.formData.locations.locations.splice(foundKey, 1);
      }
      return;

    case "locationsAnyChanged":
      draft.formData.locations.any.isChecked = action.value;
      if (action.value) {
        draft.formData.locations.locations = [];
      }
      return;

    case "locationsLocationPlaceChanged":
      foundKey = draft.formData.locations.locations.findIndex(
        (x) => x.id == action.value.locationID
      );
      // Change the Location Place
      if (foundKey >= 0) {
        draft.formData.locations.locations[foundKey].value = action.value.value;
      }
      return;

    case "locationsLocationTypeChanged":
      foundKey = draft.formData.locations.locations.findIndex(
        (x) => x.id == action.value.locationID
      );
      // Change the Location Type
      if (
        foundKey >= 0 &&
        draft.formData.locations.locations[foundKey].value != undefined
      ) {
        draft.formData.locations.locations[foundKey].value.location_type =
          action.value.value;
        draft.formData.locations.locations[foundKey].value.name = "";
        draft.formData.locations.locations[foundKey].value.id = "";
      }
      return;

    /**
     *
     * Position related events
     *
     */
    case "positionIndustryChanged":
      draft.formData.position.industryId = action.value;
      return;
    case "setIndustryChangedCounter":
      draft.formData.position.industryChangedCounter++;
      return;

    case "positionPositionChanged":
      draft.formData.position.positionId = action.value.id;
      draft.formData.position.positionName = action.value.name;
      return;

    /**
     *
     * Workplace type related events
     *
     */
    case "workPlaceTypeChanged":
      draft.formData.workPlaceType.workPlaceType = action.value;
      return;

    /**
     *
     * Employment type related events
     *
     */
    case "employmentTypeChanged":
      draft.formData.employmentType.employmentType = action.value;
      return;

    /**
     *
     * Salary Range related events
     *
     */
    case "salaryRangeTypeChanged":
      draft.formData.salaryRange.type = action.value;
      draft.formData.salaryRange.salary = 0;
      return;

    case "salaryRangeValueChanged":
      draft.formData.salaryRange.salary = action.value;
      return;

    case "salaryRangeCurrencyChanged":
      draft.formData.salaryRange.currency = action.value;
      return;

    /**
     *
     * Work Schedule type related events
     *
     */
    case "workScheduleChanged":
      draft.formData.workScheduleType.workScheduleType = action.value;
      return;

    /**
     *
     * Preferred Start type related events
     *
     */
    case "preferredStartChanged":
      draft.formData.preferredStartType.preferredStartType = action.value;
      return;

    /**
     *
     * Additional benefits type related events
     *
     */
    case "additionalBenefitsChanged":
      foundKey = draft.formData.additionalBenefits.findIndex(
        (x) => x.id == action.value
      );

      // Change the Additional benefit checked / not checked
      if (foundKey >= 0) {
        draft.formData.additionalBenefits[foundKey].isChecked =
          !draft.formData.additionalBenefits[foundKey].isChecked;
      }
      return;

    /**
     *
     * CV related events **** START *****
     *
     */
    case "setSelectsDataLanguagesLang":
      draft.data.cv.selectsData.languages.lang.data = action.value.data;
      draft.data.cv.selectsData.languages.lang.isLoaded = true;
      return;

    case "setSelectsDataLanguagesLevel":
      draft.data.cv.selectsData.languages.level.extra_data =
        action.value.extra_data;
      draft.data.cv.selectsData.languages.level.isLoaded = true;
      return;

    case "setSelectsDataEducationLevel":
      draft.data.cv.selectsData.education.level.data = action.value.data;
      draft.data.cv.selectsData.education.level.isLoaded = true;
      return;

    case "setSelectsDataEducationBroadType":
      draft.data.cv.selectsData.education.broadType.data = action.value.data;
      draft.data.cv.selectsData.education.broadType.isLoaded = true;
      return;

    case "educationAdded":
      draft.formData.cv.education.push({
        id: action.value,
      });
      draft.formData.cv.isCvChanged = true;
      return;

    case "educationRemoved":
      foundKey = draft.formData.cv.education.findIndex(
        (x) => x.id == action.value
      );
      if (foundKey >= 0) {
        draft.formData.cv.education.splice(foundKey, 1);
        draft.formData.cv.isCvChanged = true;
      }
      return;

    case "cvEducationManualyChanged":
    case "cvExperienceManualyChanged":
    case "cvLanguagesManualyChanged":
    case "cvCertificateManualyChanged":
      draft.formData.cv.isCvChanged = true;
      return;

    case "educationLevelChanged":
      foundKey = draft.formData.cv.education.findIndex(
        (x) => x.id == action.value.educationID
      );
      // Change the education level
      if (foundKey >= 0) {
        draft.formData.cv.education[foundKey].educationLevel =
          action.value.educationLevel;
      }
      return;

    case "educationBroadTypeChanged":
      foundKey = draft.formData.cv.education.findIndex(
        (x) => x.id == action.value.educationID
      );
      // Change the Broad education type
      if (foundKey >= 0) {
        draft.formData.cv.education[foundKey].broadEducation =
          action.value.broadEducationType;
      }
      return;

    case "educationDetailedTypeChanged":
      foundKey = draft.formData.cv.education.findIndex(
        (x) => x.id == action.value.educationID
      );
      // Change the detailed education type
      if (foundKey >= 0) {
        draft.formData.cv.education[foundKey].detailedEducation =
          action.value.detailedEducationType;
      }
      return;

    case "educationMajorChanged":
      foundKey = draft.formData.cv.education.findIndex(
        (x) => x.id == action.value.educationID
      );
      // Change the major
      if (foundKey >= 0) {
        draft.formData.cv.education[foundKey].major = action.value.major;
        draft.formData.cv.isCvChanged = true;
      }
      return;

    case "educationFromChanged":
      foundKey = draft.formData.cv.education.findIndex(
        (x) => x.id == action.value.educationID
      );
      // Change the From
      if (foundKey >= 0) {
        draft.formData.cv.education[foundKey].from = action.value.from;
        draft.formData.cv.isCvChanged = true;
      }
      return;

    case "educationToChanged":
      foundKey = draft.formData.cv.education.findIndex(
        (x) => x.id == action.value.educationID
      );
      // Change the To
      if (foundKey >= 0) {
        draft.formData.cv.education[foundKey].to = action.value.to;
        draft.formData.cv.isCvChanged = true;
      }
      return;

    case "educationChangePresentEducation":
      foundKey = draft.formData.cv.education.findIndex(
        (x) => x.id == action.value.educationID
      );
      // Change the Experience presentJob
      if (foundKey >= 0) {
        draft.formData.cv.education[foundKey].presentEducation =
          action.value.presentEducation;
        if (action.value.presentEducation) {
          draft.formData.cv.education[foundKey].to = null;
        }
        draft.formData.cv.isCvChanged = true;
      }
      return;

    case "experienceAdded":
      draft.formData.cv.experience.push({
        id: action.value,
      });
      draft.formData.cv.isCvChanged = true;
      return;

    case "experienceRemoved":
      foundKey = draft.formData.cv.experience.findIndex(
        (x) => x.id == action.value
      );
      if (foundKey >= 0) {
        draft.formData.cv.experience.splice(foundKey, 1);
        draft.formData.cv.isCvChanged = true;
      }
      return;

    case "experienceIndustryChanged":
      foundKey = draft.formData.cv.experience.findIndex(
        (x) => x.id == action.value.experienceID
      );
      // Change the Experience Industry
      if (foundKey >= 0) {
        draft.formData.cv.experience[foundKey].industry = action.value.industry;
      }
      return;

    case "experiencePositionChanged":
      foundKey = draft.formData.cv.experience.findIndex(
        (x) => x.id == action.value.experienceID
      );
      // Change the Experience Position
      if (foundKey >= 0) {
        draft.formData.cv.experience[foundKey].position = action.value.position;
        draft.formData.cv.isCvChanged = true;
      }
      return;

    case "experienceFromChanged":
      foundKey = draft.formData.cv.experience.findIndex(
        (x) => x.id == action.value.experienceID
      );
      // Change the Experience From
      if (foundKey >= 0) {
        draft.formData.cv.experience[foundKey].from = action.value.from;
        draft.formData.cv.isCvChanged = true;
      }
      return;

    case "experienceToChanged":
      foundKey = draft.formData.cv.experience.findIndex(
        (x) => x.id == action.value.experienceID
      );
      // Change the Experience To
      if (foundKey >= 0) {
        draft.formData.cv.experience[foundKey].to = action.value.to;
        draft.formData.cv.isCvChanged = true;
      }
      return;

    case "experienceCompanyChanged":
      foundKey = draft.formData.cv.experience.findIndex(
        (x) => x.id == action.value.experienceID
      );
      // Change the Experience Company
      if (foundKey >= 0) {
        draft.formData.cv.experience[foundKey].company = action.value.company;
        draft.formData.cv.isCvChanged = true;
      }
      return;

    case "experienceChangePresentJob":
      foundKey = draft.formData.cv.experience.findIndex(
        (x) => x.id == action.value.experienceID
      );
      // Change the Experience presentJob
      if (foundKey >= 0) {
        draft.formData.cv.experience[foundKey].presentJob =
          action.value.presentJob;
        if (action.value.presentJob) {
          draft.formData.cv.experience[foundKey].to = null;
        }
        draft.formData.cv.isCvChanged = true;
      }
      return;

    case "languageAdded":
      draft.formData.cv.languages.push({
        id: action.value,
      });
      draft.formData.cv.isCvChanged = true;
      return;

    case "languageRemoved":
      foundKey = draft.formData.cv.languages.findIndex(
        (x) => x.id == action.value
      );
      if (foundKey >= 0) {
        draft.formData.cv.languages.splice(foundKey, 1);
        draft.formData.cv.isCvChanged = true;
      }
      return;

    case "languagesLanguageChanged":
      let language = action.value.language;
      foundKey = draft.formData.cv.languages.findIndex(
        (x) => x.id == action.value.languageID
      );
      // Change the Languages Language
      if (foundKey >= 0) {
        draft.formData.cv.languages[foundKey].language = language;
      }
      return;

    case "languagesLevelChanged":
      foundKey = draft.formData.cv.languages.findIndex(
        (x) => x.id == action.value.languageID
      );
      // Change the Languages Language
      if (foundKey >= 0) {
        draft.formData.cv.languages[foundKey].level = action.value.level;
      }
      return;

    case "generalSkillsSkillChanged":
      foundKey = draft.formData.cv.generalSkills.findIndex(
        (x) => x.id == action.value.id
      );
      // Change the Skill checked / not checked
      if (foundKey >= 0) {
        draft.formData.cv.generalSkills[foundKey].isChecked =
          action.value.isChecked;
        draft.formData.cv.isCvChanged = true;
      }
      return;

    case "generalSkillsGotSkills":
      draft.formData.cv.generalSkills = action.value;
      draft.data.cv.generalSkillsGotSkills = true;
      return;

    case "preselectGeneralSkills":
      draft.data.cv.generalSkillsInitiallyChecked.forEach((id) => {
        foundKey = draft.formData.cv.generalSkills.findIndex((x) => x.id == id);
        // Make a General Skill checked
        if (foundKey >= 0) {
          draft.formData.cv.generalSkills[foundKey].isChecked = true;
        }
      });
      return;

    case "preselectAdditionalSkills":
      for (const industry in draft.formData.cv.additionalSkills) {
        draft.formData.cv.additionalSkills[industry].forEach(
          (element, index) => {
            draft.data.cv.additionalSkillsInitiallyChecked.forEach((id) => {
              if (id == element.id) {
                draft.formData.cv.additionalSkills[industry][
                  index
                ].isChecked = true;
              }
            });
          }
        );
      }
      return;

    case "additionalSkillsGotSkills":
      draft.formData.cv.additionalSkills = action.value;
      draft.data.cv.additionalSkillsGotSkills = true;
      return;

    case "additionalSkillsSkillChanged":
      for (const industry in draft.formData.cv.additionalSkills) {
        draft.formData.cv.additionalSkills[industry].forEach(
          (element, index) => {
            if (element.id == parseInt(action.value)) {
              draft.formData.cv.additionalSkills[industry][index].isChecked =
                !draft.formData.cv.additionalSkills[industry][index].isChecked;
              draft.formData.cv.isCvChanged = true;
            }
          }
        );
      }
      return;

    case "locationLocationChanged":
      draft.formData.cv.location = action.value;
      draft.formData.cv.isCvChanged = true;
      return;

    case "hobbiesGotHobbies":
      draft.formData.cv.hobbies = action.value;
      draft.data.cv.hobbiesGotHobbies = true;
      return;

    case "preselectHobbies":
      draft.data.cv.hobbiesInitiallyChecked.forEach((id) => {
        foundKey = draft.formData.cv.hobbies.findIndex((x) => x.id == id);
        // Make a Hobby checked
        if (foundKey >= 0) {
          draft.formData.cv.hobbies[foundKey].isChecked = true;
        }
      });
      return;

    case "hobbiesChanged":
      foundKey = draft.formData.cv.hobbies.findIndex(
        (x) => x.id == action.value.id
      );
      // Change the Hobby checked / not checked
      if (foundKey >= 0) {
        draft.formData.cv.hobbies[foundKey].isChecked = action.value.isChecked;
        draft.formData.cv.isCvChanged = true;
      }
      return;

    case "certificatesCertificatesChanged":
      draft.formData.cv.certificates = action.value;
      return;

    case "cvDataLoaded":
      draft.data.cv.initialDataLoaded = true;
      const cvData = action.value;

      if (!isArray(cvData)) draft.data.cv.hasCV = true;

      draft.data.cv.generalSkillsInitiallyChecked = cvData.general_skills
        ? cvData.general_skills
        : [];
      draft.data.cv.additionalSkillsInitiallyChecked = cvData.skills
        ? cvData.skills
        : [];
      draft.data.cv.hobbiesInitiallyChecked = cvData.hobbies
        ? cvData.hobbies
        : [];

      let location = cvData.location ? cvData.location : {};
      if (location.name) {
        location.name = `${location.name}, ${location.state_name}, ${location.country_name}`;
      }
      draft.formData.cv.location = location;

      draft.formData.cv.languages = cvData.languages
        ? LanguagesHelper.formatResponse(cvData.languages)
        : [];

      draft.formData.cv.education = cvData.education
        ? EducationHelper.formatResponse(cvData.education)
        : [];

      draft.formData.cv.experience = cvData.experience
        ? ExperienceHelper.formatResponse(cvData.experience)
        : [];

      draft.data.cv.initialFileUploads = cvData.attachements
        ? CertificatesHelper.formatResponse(cvData.attachements, true)
        : [];

      return;

    /**
     *
     * CV related events **** END *****
     *
     */

    case "disableSaveButton":
      draft.disabledSaveButton = action.value;
      return;
    case "formSuccessfullySubmitted":
      draft.data.cv.initialFileUploads = [];
      draft.formData.cv.certificates = [];
      draft.data.cvinitialDataLoaded = false;
      draft.formSuccessfullySubmittedCount++;
      return;
    case "setTooltipVisible":
      draft.tooltipVisible = action.value;
      return;
  }
}
