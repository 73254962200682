import React, { useEffect, useContext } from "react";
import StateContext from "../../Context/StateContext";
import DispatchContext from "../../Context/DispatchContext";
import { useImmerReducer } from "use-immer";
import Axios from "axios";
import * as AppRoutes from "../../AppComponent/AppRoutes";
import { Link as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";
import TextField from "@material-ui/core/TextField";
import Alert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import DreamButton from "../../ui_components/DreamButton/DreamButton";
import DreamDialog from "../../ui_components/DreamDialog/DreamDialog";
import DialogContent from "@material-ui/core/DialogContent";
import { useTranslation } from "react-i18next";
import * as Constants from "../../helpers/Constants";
import * as SignUpReducer from "./SignUpReducer";
import * as SignUpHelper from "./SignUpHelper";
import DreamCheckbox from "../../ui_components/DreamCheckbox/DreamCheckboxComponent";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

function SignUpComponent(props) {
  const appContext = useContext(StateContext);
  const appDispatch = useContext(DispatchContext);
  const [t] = useTranslation(Constants.TRANSLATION_COMMON);

  const theme = useTheme();
  const matchesXSdown = useMediaQuery(theme.breakpoints.down("xs"));
  const matchesMDup = useMediaQuery(theme.breakpoints.up("md"));

  const useStyles = makeStyles((theme) => ({
    paper: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      maxWidth: 380,
      width: "100%",
      minHeight: 380,
      boxShadow: "0px 10px 10px rgba(0, 0, 0, 0.25)",
      borderRadius: 15,
    },
    // dialogRoot: {
    //   paddingTop: 0,
    //   paddingBottom: 0,
    // },
    dialogPaper: {
      right: matchesMDup && "-28%",
      maxWidth: 400,
      minWidth: 300,
      borderRadius: 15,
      // margin: 0,
      padding: 10,
    },
    dialogTitle: {
      width: "100%",
      paddingLeft: 50,
      paddingTop: 20,
      paddingBottom: 20,
      textAlign: "left",
      fontSize: 18,
      fontWeight: "bold",
      borderBottom: "2px solid lightgrey",
    },
    dialogContent: {
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 10,
      paddingRight: 10,
    },
    loginWrapper: {
      padding: 20,
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      maxWidth: 380,
      width: "100%",
      minHeight: 380,
    },
    loginTextWrapper: {
      marginTop: 24,
      display: "flex",
      fontWeight: "bold",
      alignSelf: "center",
    },
    termsWrapper: {
      marginTop: 10,
      marginBottom: 10,
      display: "flex",
    },
    termsFormControlLabel: {
      marginLeft: -10,
      marginRight: 0,
    },
    termsTextWrapper: {
      display: "flex",
      alignSelf: "center",
      flexWrap: matchesXSdown && "wrap",
    },
    termsAgreeTo: {
      paddingRight: 3,
      fontSize: 12,
    },
    termsAgree: {
      fontSize: 12,
    },
    haveAccount: {
      paddingRight: 3,
      fontSize: 12,
    },
    login: {
      fontSize: 12,
    },
  }));

  const classes = useStyles();

  // Get users location (URL)
  const location = useLocation();

  // const parsedUrl = queryString.parse(window.location.search);
  const parsedUrl = queryString.parse(location.search);
  const token = parsedUrl;

  const [state, dispatch] = useImmerReducer(
    SignUpReducer.reducer,
    SignUpReducer.originalState
  );

  const handleChange = (event) => {
    dispatch({ type: "setTermsCheckbox", value: event.target.checked });
  };

  const handleMarketingChange = (event) => {
    dispatch({ type: "setMarketingCheckbox", value: event.target.checked });
  };

  const content = (
    <>
      {/* Email */}
      <TextField
        autoComplete="off"
        margin="dense"
        id="email"
        name="email"
        label={t("registerForm.email")}
        fullWidth
        value={state.fields.email.value ? state.fields.email.value : ""}
        onChange={(e) =>
          dispatch({
            type: "emailChange",
            value: { value: e.target.value, t },
          })
        }
      />
      {/* {state.fields.email.errorMessage && (
        <Alert severity="error">{state.fields.email.errorMessage}</Alert>
      )} */}
      {/*Password */}
      <TextField
        type="password"
        autoComplete="off"
        margin="dense"
        id="password"
        name="password"
        label={t("registerForm.password")}
        fullWidth
        value={state.fields.password.value}
        onChange={(e) =>
          dispatch({
            type: "passwordChange",
            value: { value: e.target.value, t },
          })
        }
      />
      {state.fields.password.errorMessage && (
        <Alert style={{ width: "100%" }} severity="error">
          {state.fields.password.errorMessage}
        </Alert>
      )}

      {/*Password Confirmation*/}
      <TextField
        // error={false}
        // helperText="Incorrect entry."
        type="password"
        autoComplete="off"
        margin="dense"
        id="passwordConfirm"
        name="passwordConfirm"
        label={t("registerForm.passwordConfirm")}
        fullWidth
        value={state.fields.passwordConfirm.value}
        onChange={(e) =>
          dispatch({
            type: "passwordConfirmChange",
            value: { value: e.target.value, t },
          })
        }
      />
      {state.fields.passwordConfirm.errorMessage && (
        <Alert severity="error">
          {state.fields.passwordConfirm.errorMessage}
        </Alert>
      )}
      {/* {state.fields.passwordConfirm.value == "" && (
        <Alert severity="error">{t("validationError.requiredField")}</Alert>
      )} */}

      {/* Phone */}
      {/* <TextField
        autoComplete="off"
        margin="dense"
        id="phone"
        name="phone"
        label={t("registerForm.phone")}
        fullWidth
        value={state.fields.phone.value}
        onChange={(e) =>
          dispatch({ type: "phoneChange", value: { value: e.target.value, t } })
        }
      />
      {state.fields.phone.errorMessage && (
        <Alert severity="error">{state.fields.phone.errorMessage}</Alert>
      )} */}
      <div>
        <div className={classes.termsWrapper}>
          <FormControlLabel
            classes={{ root: classes.termsFormControlLabel }}
            value="end"
            control={
              <Checkbox
                color="primary"
                checked={state.agreed_tc}
                onChange={handleChange}
              />
            }
            labelPlacement="end"
          />
          <div className={classes.termsTextWrapper}>
            <span className={classes.termsAgreeTo}>
              {t("registerForm.agreeTo")}
            </span>

            <Link
              className={classes.termsAgree}
              component={RouterLink}
              variant="body2"
              to={AppRoutes.ROUTE_TERMS_OF_USE}
              onClick={(e) => {
                SignUpHelper.handleClose(dispatch, appDispatch);
              }}
            >
              {t("registerForm.termsOfService")}
            </Link>
          </div>
        </div>
        <div className={classes.termsWrapper}>
          <FormControlLabel
            classes={{ root: classes.termsFormControlLabel }}
            value="end"
            control={
              <Checkbox
                color="primary"
                checked={state.opt_in}
                onChange={handleMarketingChange}
              />
            }
            labelPlacement="end"
          />
          <div className={classes.termsTextWrapper}>
            <span className={classes.termsAgreeTo}>
              {t("registerForm.receiveEmails")}
            </span>
          </div>
        </div>
      </div>
    </>
  );

  let buttons = (
    <React.Fragment>
      {props.modal ? (
        <DreamButton
          handleClick={() => SignUpHelper.handleClose(dispatch, appDispatch)}
          color="secondary"
          kind="text"
          buttonLabel="basic.cancel"
        ></DreamButton>
      ) : (
        ""
      )}
      <DreamButton
        dreamButtonStyle={props.signUpButtonStyle}
        buttonType="submit"
        buttonDisabled={Boolean(state.hasValidationErrs) || !state.agreed_tc}
        handleClick={(e) => SignUpHelper.handleSubmit(e, dispatch)}
        color="primary"
        buttonType=""
        kind="contained"
        buttonLabel="header.signUp"
      />
      {/* <div className={classes.loginTextWrapper}>
        <span className={classes.haveAccount}>
          {t("loginForm.haveAccount")}
        </span>

        <Link
          className={classes.login}
          component="button"
          variant="body2"
          onClick={(e) => appDispatch({ type: "openLoginModal", value: true })}
        >
          {t("loginForm.login")}
        </Link>
      </div> */}
    </React.Fragment>
  );

  /**
   * The Form has been submitted
   */
  useEffect(() => {
    if (state.formSubmittedCount) {
      appDispatch({ type: "showBackdrop", value: true });
      const ourRequest = Axios.CancelToken.source();

      SignUpHelper.register(
        state,
        Axios,
        ourRequest,
        appDispatch,
        dispatch,
        t,
        token
      );

      return () => {
        ourRequest.cancel();
      };
    }
  }, [state.formSubmittedCount]);

  if (!props.modal) {
    return (
      <Paper className={classes.paper}>
        <span className={classes.dialogTitle}>{t("header.signUp")}</span>
        <div className={classes.loginWrapper}>
          <div style={props.contentWrapperStyle}>{content}</div>
          <div style={props.buttonWrapperStyle}>{buttons}</div>
        </div>
      </Paper>
    );
  } else {
    return (
      <DreamDialog
        classes={{ root: classes.dialogRoot, paper: classes.dialogPaper }}
        open={appContext.appState.openSignUpModal}
        closeHandler={() => SignUpHelper.handleClose(dispatch, appDispatch)}
        title={t("header.signUp")}
        content={content}
        buttons={buttons}
        submitHandler={(e) => SignUpHelper.handleSubmit(e, dispatch)}
      ></DreamDialog>
    );
  }
}
export default SignUpComponent;
