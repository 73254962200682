export const ROUTE_HOME = "/";
export const ROUTE_PROFILE = "/profile";
export const ROUTE_USER_PROFILE = "/user-profile";
export const ROUTE_COMPANY_PROFILE = "/company-profile";
export const ROUTE_MY_CV = "/my-cv";
export const ROUTE_JOB_PREFERENCES = "/job-preferences";
export const ROUTE_VIEW_JOB_PREFERENCE = "/job-preference/:jpId";
export const ROUTE_CREATE_JOB_PREFERENCE = "/create-job-preference";
export const ROUTE_UPDATE_JOB_PREFERENCE = "/update-job-preference/:jpID";
export const ROUTE_VIEW_JOB_PREFERENCE_EMPLOYERS_WITH_MESSAGES =
  "/job-preference-employers-with-messages/:jpID";
export const ROUTE_VIEW_JOB_PREFERENCE_EMPLOYER =
  "/job-preference-employer-with-messages/:eID/:jpID";

export const ROUTE_JOB_OFFERS = "/job-offers";
export const ROUTE_CREATE_JOB_OFFER = "/job-offer";
export const ROUTE_UPDATE_JOB_OFFER = "/job-offer-update/:joID";
export const ROUTE_VIEW_JOB_OFFER = "/job-offer-view/:joID";
export const ROUTE_VIEW_JOB_OFFER_CANDIDATE =
  "/job-offer-candidate-view/:cID/:joID/:jpID";
export const ROUTE_VIEW_JOB_OFFER_CANDIDATE_CV = "/dreamjob/cv/:cvID";
export const ROUTE_VIEW_JOB_OFFER_CANDIDATES_WITH_MESSAGES =
  "/job-offer-candidates-with-messages/:joID";
export const ROUTE_MY_MESSAGES = "/my-messages";
export const ROUTE_TERMS_OF_USE = "/terms-of-use";
export const ROUTE_CREDITS_PROGRAM = "/credits-program";
export const ROUTE_PRIVACY_POLICY = "/privacy-policy";
export const ROUTE_CONTACT_US = "/contact-us";
export const ROUTE_FORGOTTEN_PASSWORD = "/forgotten-password";
export const ROUTE_CHANGE_PASSWORD = "/change-password/:fpHash";
export const ROUTE_WALLET = "/wallet";
export const ROUTE_BUY_CREDITS = "/buy-credits";
export const ROUTE_REFERAL = "/referal";
export const ROUTE_CONFIRM_EMAIL = "/confirm";
export const ROUTE_ACCOUNT_SETTINGS = "/account-settings";
export const ROUTE_ACCOUNT_SETTINGS_CHANGE_EMAIL = "/as-change-email";
export const ROUTE_ACCOUNT_SETTINGS_CHANGE_PASSWORD = "/as-change-password";
export const ROUTE_ACCOUNT_SETTINGS_DELETE_ACCOUNT = "/as-delete-account";
export const ROUTE_INVOICES = "/invoices";
export const ROUTE_CREATE_INVOICE = "/invdetails";
