import localeEnUS from "date-fns/locale/en-US";

export const originalState = {
  value: null,
  locale: localeEnUS,
};

export function reducer(draft, action) {
  switch (action.type) {
    case "valueChange":
      draft.value = action.value ? new Date(action.value) : null;
      return;
    case "localeChange":
      draft.locale = action.value;
      return;
  }
}
