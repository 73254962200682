import * as FileUploaderHelper from "../../ui_components/DreamFileUploaderMultiple/DreamFileUploader/FileUploaderHelper";
import * as Constants from "../../helpers/Constants";
import * as BaseHelper from "../../helpers/BaseHelper";
import * as AdditionalSkillsHelper from "../../containers_components/AdditionalSkillsComponent/AdditionalSkillsHelper";

export async function handleSave(joCount, data, dispatch, appDispatch, Axios) {
  if (!validateForm(data, appDispatch)) {
    return false;
  }
  let errMsgs;
  let authenticated = true;
  dispatch({ type: "disableSaveButton", value: true });
  appDispatch({ type: "showBackdrop", value: true });

  try {
    let formData = new FormData();

    formData.append("location", JSON.stringify(data.location));
    formData.append(
      "education",
      JSON.stringify(formatEducationData(data.education))
    );
    formData.append("languages", JSON.stringify(data.languages));
    formData.append(
      "experience",
      JSON.stringify(formatExperienceData(data.experience))
    );
    formData.append(
      "generalSkills",
      JSON.stringify(getOnlyChecked(data.generalSkills))
    );
    formData.append(
      "additionalSkills",
      JSON.stringify(
        AdditionalSkillsHelper.getOnlyChecked(data.additionalSkills)
      )
    );
    formData.append("hobbies", JSON.stringify(getOnlyChecked(data.hobbies)));

    FileUploaderHelper.formatRequestData(
      data.certificates.uploadedFiles,
      formData
    );

    const response = await Axios.post(`/dreamjob/cv`, formData, {
      headers: {
        "content-type": `multipart/form-data; boundary=${formData._boundary}`,
      },
    }).catch(function (error) {
      // Errors to be sent to snackbar
      errMsgs = "basic.serverError";
      if (
        error.response &&
        error.response.status &&
        error.response.status == Constants.ERROR_RESPONSE_STATUS
      ) {
        authenticated = false;
        errMsgs = "basic.unauthenticated";
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
      throw errMsgs;
    });

    const responseData = response.data.data;
    let errsArr = [];
    if (!responseData.success && responseData.messages.length) {
      responseData.messages.forEach((msg) => {
        errsArr.push(`serverErrors.${msg.code}`);
      });
      throw errsArr;
    }
    dispatch({ type: "disableSaveButton", value: false });
    dispatch({ type: "formSuccessfullySubmitted" });

    if (joCount < 1) {
      appDispatch({ type: "setUserDefaultCvId", value: responseData.id });
    }
    appDispatch({ type: "showBackdrop", value: false });
    appDispatch({ type: "setIsCvFilled", value: true });
    appDispatch({
      type: "showMsg",
      value: { status: Constants.SNACKBAR_SUCCESS, msg: "basic.saved" },
    });
  } catch (error) {
    appDispatch({ type: "showBackdrop", value: false });
    appDispatch({
      type: "showMsg",
      value: { status: Constants.SNACKBAR_ERROR, msg: error },
    });
    dispatch({ type: "disableSaveButton", value: false });

    if (!authenticated) {
      appDispatch({
        type: "logout",
      });

      appDispatch({ type: "openLoginModal", value: true });
    } else {
      dispatch({ type: "disableSaveButton", value: false });
    }
  }
}

export function validateForm(data, appDispatch) {
  let errors = [];

  if (!isValidLocation(data.location)) {
    errors.push("cv.invalidLocation");
  }

  if (!isValidEducation(data.education)) {
    errors.push("cv.invalidEducation");
  }

  if (!isValidLanguages(data.languages)) {
    errors.push("cv.invalidLanguages");
  }

  if (!isValidExperience(data.experience)) {
    errors.push("cv.invalidExperience");
  }

  if (!isValidCertificates(data.certificates.uploadedFiles)) {
    errors.push("cv.invalidCertificates");
  }

  if (Boolean(errors.length)) {
    appDispatch({
      type: "showMsg",
      value: { status: Constants.SNACKBAR_ERROR, msg: errors },
    });
    return false;
  }

  return true;
}

function isValidLocation(location) {
  if (location === null || !Boolean(Object.keys(location).length)) {
    return false;
  }

  return true;
}

function isValidEducation(educations) {
  let isValid = true;
  if (!Boolean(educations.length)) {
    return false;
  }

  educations.forEach((element) => {
    if (
      !element.educationLevel ||
      (parseInt(element.educationLevel) !== 1 &&
        (!element.detailedEducation ||
          (parseInt(element.educationLevel) > 4 &&
            parseInt(element.educationLevel) < 8 &&
            (element.major == null || element.major.trim() == "")) ||
          !element.from ||
          (!element.to && !element.presentEducation)))
    ) {
      isValid = false;
      return;
    }
  });

  return isValid;
}

function isValidLanguages(languages) {
  let isValid = true;
  if (!Boolean(languages.length)) {
    return false;
  }

  languages.every(function (element, index) {
    if (!element.language || !element.level) {
      isValid = false;
      return false;
    } else {
      return true;
    }
  });

  return isValid;
}

function isValidExperience(experience) {
  let isValid = true;

  experience.every(function (element, index) {
    if (
      !element.industry ||
      !element.position ||
      !element.from ||
      (!element.to && !element.presentJob)
    ) {
      isValid = false;
      return false;
    } else {
      return true;
    }
  });

  return isValid;
}

function isValidCertificates(certificates) {
  let isValid = true;

  certificates.every(function (element, index) {
    if (!element.fileDescription || !element.fileDescription.trim()) {
      isValid = false;
      return false;
    } else {
      return true;
    }
  });

  return isValid;
}

export function formatExperienceData(data) {
  let formattedData = [];
  data.forEach((element) => {
    let experience = {
      industry: element.industry,
      position: element.position.id,
    };
    if (element.from) {
      experience.from = BaseHelper.getDateFromDateObject(element.from);
    }
    if (element.presentJob) {
      experience.presentJob = element.presentJob;
    }
    if (element.to && !element.presentJob) {
      experience.to = BaseHelper.getDateFromDateObject(element.to);
    }
    if (element.company) {
      experience.company = element.company;
    }

    formattedData.push(experience);
  });

  return formattedData;
}

export function formatEducationData(data) {
  let formattedData = [];
  data.forEach((element) => {
    let education = {
      educationLevel: element.educationLevel,
      educationType: element.detailedEducation,
      major: element.major,
    };
    if (element.from) {
      education.from = BaseHelper.getDateFromDateObject(element.from);
    }
    if (element.to) {
      education.to = BaseHelper.getDateFromDateObject(element.to);
    }
    formattedData.push(education);
  });

  return formattedData;
}

export function getOnlyChecked(data) {
  let checked = [];
  data.forEach((element) => {
    if (element.isChecked) {
      checked.push(element.id);
    }
  });

  return checked;
}

export async function getCvData(
  Axios,
  ourRequest,
  appDispatch,
  dispatch,
  jpId
) {
  appDispatch({ type: "showBackdrop", value: true });
  let errMsgs;
  let authenticated = true;
  let cvUrl = "/dreamjob/cv";
  if (jpId) {
    cvUrl += `?jpID=${jpId}`;
  }
  try {
    const response = await Axios.get(cvUrl, {
      cancelToken: ourRequest.token,
    }).catch(function (error) {
      // Errors to be sent to snackbar
      errMsgs = "basic.serverError";
      if (
        error.response &&
        error.response.status &&
        error.response.status === Constants.ERROR_RESPONSE_STATUS
      ) {
        authenticated = false;
        errMsgs = "basic.unauthenticated";
      }
      throw errMsgs;
    });

    const responseData = response.data.data;

    handleSuccessfulGetData(responseData, appDispatch, dispatch);
  } catch (error) {
    appDispatch({ type: "showBackdrop", value: false });
    appDispatch({
      type: "showMsg",
      value: { status: Constants.SNACKBAR_ERROR, msg: error },
    });
    if (!authenticated) {
      appDispatch({
        type: "logout",
      });
      appDispatch({ type: "openLoginModal", value: true });
    } else {
      dispatch({ type: "disableSubmitButton", value: false });
    }
  }
}

/**
 * Successful got a data from the API
 * @param {Object} data
 * @param {appDispatch} appDispatch
 * @param {dispatch} dispatch
 */
export function handleSuccessfulGetData(data, appDispatch, dispatch) {
  appDispatch({ type: "showBackdrop", value: false });
  dispatch({ type: "cvDataLoaded", value: data });
}
