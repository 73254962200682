export const originalState = {
  jobOffer: [],
  jobOfferTitle: "",
  offerDataLoaded: false,
  candidates: [],
  candidatesDataLoaded: false,
  pagination: {
    currentPage: 0,
    currentPageSize: 10,
    hasMoreData: false,
    cursor: null,
    totalPageCount: 0,
    totalCount: 0,
    isLoading: false,
  },
  sorting: {
    byNameDesc: false,
    byIndustryDesc: false,
    byPositionDesc: false,
    bySalaryDesc: false,
  },
};

export function reducer(draft, action) {
  switch (action.type) {
    case "getCandidates":
      draft.candidates = action.value;
      break;

    case "getJobOffer":
      draft.jobOffer = action.value;
      break;

    // Start Pagination  -----
    case "setPage":
      draft.pagination.currentPage = action.value;
      break;
    case "setRowsPerPage":
      draft.pagination.currentPageSize = action.value;
      break;
    case "setPaginationTotalCount":
      draft.pagination.totalCount = action.value;
      break;
    case "setPaginationTotalPageCount":
      draft.pagination.totalPageCount = action.value;
      break;
    case "setIsLoading":
      draft.pagination.isLoading = action.value;
      break;
    // End Pagination  -----

    case "offerDataLoaded":
      draft.offerDataLoaded = true;
      const offerData = action.value;

      draft.jobOfferTitle = offerData.name;

      let formattedOfferData = [];

      draft.jobOffer = formattedOfferData;
      break;

    case "candidatesDataLoaded":
      draft.candidatesDataLoaded = true;
      const candidatesData = action.value;
      draft.candidates = candidatesData.items;
      break;
  }
}
