export const originalState = {
  industryId: "",
  position: "",
};

export function reducer(draft, action) {
  switch (action.type) {
    case "setIndustryId":
      draft.industryId = action.value;
      return;

    case "setPosition":
      draft.position = action.value;
      return;
  }
}
