export function handleChange(id, isChecked, dispatch, changeHandler) {
  dispatch({
    type: "changeChecked",
    value: {
      id,
      isChecked,
    },
  });
  if (changeHandler) {
    changeHandler({ id, isChecked });
  }
}

export const formatCheckboxes = (checkboxes) => {
  let formatted = {};
  for (const group in checkboxes) {
    let groupElements = [...checkboxes[group]].map((el) => {
      let newElement = { ...el };
      newElement.label = `additionalSkillsElements.${el.label}`;
      return newElement;
    });
    formatted[`additionalSkillsGroups.${group}`] = groupElements;
  }
  return formatted;
};
