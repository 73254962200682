import React from "react";
import DreamButton from "../DreamButton/DreamButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Link as RouterLink, useParams, useHistory } from "react-router-dom";

const BackButton = (props) => {
  let history = useHistory();
  return (
    <DreamButton
      handleClick={props.handleClick}
      kind="text"
      buttonDisabled={props.backButtonDisabled}
      icon={<ArrowBackIcon fontSize="large" />}
      dreamButtonStyle={props.backButtonStyle}
      btnComponent={props.handleClick ? "button" : RouterLink}
      to={props.to}
    >
      Back
    </DreamButton>
  );
};

export default BackButton;
