import * as Constants from "../../helpers/Constants";

export const UNLOCK_CANDIDATE = 1;
export const PROMOTE_SELF = 2;

export async function getCandidatePreference(
  preferenceId,
  Axios,
  ourRequest,
  appDispatch,
  dispatch
) {
  appDispatch({ type: "showBackdrop", value: true });

  let errMsgs;
  let authenticated = true;

  try {
    const response = await Axios.get(`/dreamjob/jp/${preferenceId}`, {
      headers: {
        "content-type": `application/json;`,
      },
      cancelToken: ourRequest.token,
    }).catch(function (error) {
      // Errors to be sent to snackbar
      errMsgs = "basic.serverError";
      if (
        error.response &&
        error.response.status &&
        error.response.status === Constants.ERROR_RESPONSE_STATUS
      ) {
        authenticated = false;
        errMsgs = "basic.unauthenticated";
      }
      throw errMsgs;
    });

    const responseData = response.data.data;

    handleSuccessfulGetPreferenceData(responseData, appDispatch, dispatch);
  } catch (error) {
    appDispatch({ type: "showBackdrop", value: false });
    appDispatch({
      type: "showMsg",
      value: { status: Constants.SNACKBAR_ERROR, msg: error },
    });
    if (!authenticated) {
      appDispatch({
        type: "logout",
      });
      appDispatch({ type: "openLoginModal", value: true });
    } else {
      dispatch({ type: "disableSubmitButton", value: false });
    }
  }
}

export function handleSuccessfulGetPreferenceData(data, appDispatch, dispatch) {
  appDispatch({ type: "showBackdrop", value: false });
  dispatch({ type: "candidatePreferenceDataLoaded", value: data });
  dispatch({ type: "preferencesDataLoaded" });
}

export const preferenceLabels = [
  "jocv.locations",
  "jocv.industry",
  "jocv.position",
  "jocv.workplace",
  "jocv.employment",
  "jocv.salary",
  "jocv.workSchedule",
  "jocv.start",
  "jocv.benefits",
];

export function formatLocations(locations) {
  let locationNames = [];
  locations.forEach((loc) => {
    locationNames.push(loc.location_name);
  });
  return locationNames.join("; ");
}

export function formatSalary(data) {
  let formatted = "";
  formatted = `${data.salary_currency == "USD" ? "$" : "€"}${
    data.salary_from != null ? data.salary_from : data.salary_hourly_from
  }-${data.salary_to != null ? data.salary_to : data.salary_hourly_to} ${
    data.salary_from != null ? "Mth" : "Hou"
  }`;
  return formatted;
}

export function setTypeLabel(types, type) {
  let label = "";
  for (let i = 0; i < types.length; i++) {
    if (types[i].value === type) {
      label = types[i].label;
      break;
    }
  }
  return label;
}

export function formatBenefits(benefits, preferenceData) {
  let formatted = [];
  if (preferenceData.paid_leave == 1) {
    formatted.push(benefits[0].label);
  }
  if (preferenceData.food_vouchers == 1) {
    formatted.push(benefits[1].label);
  }
  if (preferenceData.housing == 1) {
    formatted.push(benefits[2].label);
  }
  if (preferenceData.car == 1) {
    formatted.push(benefits[3].label);
  }
  if (preferenceData.phone == 1) {
    formatted.push(benefits[4].label);
  }
  return formatted;
}

export function populateBenefits(benefits, t) {
  let populated = [];
  benefits.forEach((benefit) => {
    populated.push(t(benefit));
  });
  return populated.join(", ");
}

// Candidates table
export function formatSalaryData(preference) {
  let currencySign = `${preference.salary_currency === "EUR" ? "€" : "$"}`;
  let monthlyHourly =
    preference.salary_type === "monthly"
      ? `${preference.salary_monthly} Mth`
      : `${preference.salary_hourly} Hr`;
  let formattedData = `${currencySign}${monthlyHourly}`;
  return formattedData;
}

export function populatePreferenceData(index, data, t) {
  let result = "";
  switch (index) {
    case 0:
      data[index] ? (result = data[index]) : (result = t("jov.any"));
      return result;
    case 1:
    case 2:
    case 3:
    case 4:
    case 5:
    case 6:
    case 7:
      result = t(data[index]);
      return result;
    case 8:
      data[index] ? (result = populateBenefits(data[index], t)) : (result = "");
      return result;
  }
}
