import * as Constants from "../../helpers/Constants";

export const REFRESH_INTERVAL = 30000;

export async function getMessages(
  jpId,
  eId,
  Axios,
  // ourRequest,
  appDispatch,
  dispatch
) {
  let errMsgs;
  let authenticated = true;

  try {
    const response = await Axios.get(`dreamjob/jp/messages/${jpId}`, {
      headers: {
        "content-type": `application/json;`,
      },
      params: {
        recipient: eId,
      },
      // cancelToken: ourRequest.token,
    }).catch(function (error) {
      // Errors to be sent to snackbar
      errMsgs = "basic.serverError";
      if (
        error.response &&
        error.response.status &&
        error.response.status === Constants.ERROR_RESPONSE_STATUS
      ) {
        authenticated = false;
        errMsgs = "basic.unauthenticated";
      }
      throw errMsgs;
    });

    const responseData = response.data.data;

    let errsArr = [];
    handleSuccessfulGetMessagesData(responseData, appDispatch, dispatch);
  } catch (error) {
    appDispatch({
      type: "showMsg",
      value: { status: Constants.SNACKBAR_ERROR, msg: error },
    });
    if (!authenticated) {
      appDispatch({
        type: "logout",
      });
      appDispatch({ type: "openLoginModal", value: true });
    } else {
      dispatch({ type: "disableSubmitButton", value: false });
    }
  }
}

export function handleSuccessfulGetMessagesData(data, appDispatch, dispatch) {
  appDispatch({ type: "showBackdrop", value: false });
  dispatch({ type: "setLastMessageId", value: getLastMessageId(data) });
  dispatch({ type: "setMessages", value: data });
}

function getLastMessageId(data) {
  return data.length ? data[data.length - 1].id : 0;
}

// Send message

export async function handleSend(
  data,
  jpId,
  eId,
  dispatch,
  appDispatch,
  Axios,
  uuid,
  appUserId
) {
  if (!isValidMessage(data.messageText, appDispatch)) {
    return false;
  }
  let errMsgs;
  let authenticated = true;

  try {
    let formData = new FormData();

    formData.append("jp_id", jpId);
    formData.append("body", data.messageText);
    formData.append("recipient", eId);

    const response = await Axios.post(`dreamjob/djmessages`, formData, {
      headers: {
        "content-type": `application/json;`,
      },
    }).catch(function (error) {
      // Errors to be sent to snackbar
      errMsgs = "basic.serverError";
      if (
        error.response &&
        error.response.status &&
        error.response.status == Constants.ERROR_RESPONSE_STATUS
      ) {
        authenticated = false;
        errMsgs = "basic.unauthenticated";
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
      throw errMsgs;
    });

    const responseData = response.data.data;

    let errsArr = [];

    if (!responseData.success && responseData.messages.length) {
      responseData.messages.forEach((msg) => {
        errsArr.push(`serverErrors.${msg.code}`);
      });
      throw errsArr;
    }

    dispatch({ type: "disableSaveButton", value: false });
    dispatch({
      type: "sendMessage",
      value: createMessage(data.messageText, uuid, appUserId),
    });
    dispatch({ type: "setMessage", value: "" });
    dispatch({ type: "setLastMessageId", value: responseData.id });
    appDispatch({
      type: "showMsg",
      value: { status: Constants.SNACKBAR_SUCCESS, msg: "basic.messageSent" },
    });
  } catch (error) {
    appDispatch({ type: "showBackdrop", value: false });
    appDispatch({
      type: "showMsg",
      value: { status: Constants.SNACKBAR_ERROR, msg: error },
    });
    dispatch({ type: "disableSaveButton", value: false });

    if (!authenticated) {
      appDispatch({
        type: "logout",
      });

      appDispatch({ type: "openLoginModal", value: true });
    } else {
      dispatch({ type: "disableSaveButton", value: false });
    }
  }
}

function isValidMessage(messageText) {
  if (messageText.trim() === "") {
    return false;
  }
  return true;
}

function createMessage(data, uuid, appUserId) {
  let formatted = {};
  formatted.id = uuid;
  formatted.body = data;
  formatted.sender = appUserId;
  formatted.createdOn = getFormattedDate();
  return formatted;
}

function getFormattedDate() {
  var d = new Date();

  d =
    d.getFullYear() +
    "-" +
    ("0" + (d.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + d.getDate()).slice(-2) +
    " " +
    ("0" + d.getHours()).slice(-2) +
    ":" +
    ("0" + d.getMinutes()).slice(-2) +
    ":" +
    ("0" + d.getSeconds()).slice(-2);

  return d;
}
