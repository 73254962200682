import React, { useEffect, useContext, useRef } from "react";
import { useImmerReducer } from "use-immer";
import uuid from "react-uuid";
import StateContext from "../../../Context/StateContext";
import DreamRadioButtonsComponent from "../../../ui_components/DreamRadioButtons/DreamRadioButtonsComponent";
import * as LocationsHelper from "../LocationsHelper";
import * as DreamAutocompleteHelper from "../../../ui_components/DreamAutocomplete/DreamAutocompleteHelper";
import JobPreferenceDispatchContext from "../../../Pages/CreateJobPreferencePage/Context/JobPreferenceDispatchContext";
import JobPreferenceStateContext from "../../../Pages/CreateJobPreferencePage/Context/JobPreferenceStateContext";

import * as LocationsSingleItemReducer from "./LocationsSingleItemReducer";
import DreamAutocomplete from "../../../ui_components/DreamAutocomplete/DreamAutocomplete";
import * as Constants from "../../../helpers/Constants";
import DreamOnClickTooltip from "../../../ui_components/DreamOnClickTooltip/DreamOnClickTooltip";
import * as BaseHelper from "../../../helpers/BaseHelper";

function LocationsSingleItemComponent(props) {
  const rel = useRef();

  const [state, dispatch] = useImmerReducer(
    LocationsSingleItemReducer.reducer,
    LocationsSingleItemReducer.originalState
  );

  const appContext = useContext(StateContext);
  const jobPreferenceDispatchContext = useContext(JobPreferenceDispatchContext);
  const jobPreferenceStateContext = useContext(JobPreferenceStateContext);

  function locationTypeChanged(value) {
    dispatch({
      type: "setLocationType",
      value,
    });
    jobPreferenceDispatchContext({
      type: "locationsLocationTypeChanged",
      value: {
        locationID: state.id,
        value: value,
      },
    });
    dispatch({
      type: "setLocation",
      value: {},
    });
  }

  function locationPlaceChanged(value) {
    jobPreferenceDispatchContext({
      type: "locationsLocationPlaceChanged",
      value: {
        locationID: state.id,
        value: value,
      },
    });
    dispatch({
      type: "setLocation",
      value,
    });
  }

  useEffect(() => {
    let myUuid;

    if (
      props.locationData &&
      props.locationData.id != null &&
      props.locationData.value != null &&
      props.locationData.value.location_type != null
    ) {
      // In this case, Location is loaded from the DB,
      // so we don't need to generate ID and trigger an event for creation
      myUuid = props.locationData.id;

      dispatch({
        type: "setLocationType",
        value: props.locationData.value.location_type,
      });
      dispatch({
        type: "setLocation",
        value: props.locationData.value,
      });
    } else {
      myUuid = uuid();
      jobPreferenceDispatchContext({
        type: "locationsLocationAdded",
        value: myUuid,
      });
    }

    rel.current = myUuid;

    dispatch({
      type: "setId",
      value: myUuid,
    });

    return () => {
      // on Unmount let's dispatch event
      jobPreferenceDispatchContext({
        type: "locationsLocationRemoved",
        value: rel.current,
      });
    };
  }, []);

  useEffect(() => {}, [appContext.appState.lang]);

  const autocompleteComponent = (
    <div style={{ display: "flex", flexWrap: "noWrap", width: "100%" }}>
      <DreamAutocomplete
        fullWidth
        label={"cv.location"}
        usage={"location"}
        searchType={state.locationType}
        apiUrl={`${Constants.BASE_URL}location`}
        searchParamName={"search_string"}
        additionalApiParams={{
          type: state.locationType,
          lang: appContext.appState.lang,
        }}
        changeHandler={locationPlaceChanged}
        isMandatory={true}
        // autoComplete
        preselectedValue={state.location}
      />
      {jobPreferenceStateContext.tooltipVisible ? (
        <DreamOnClickTooltip tooltipContent={BaseHelper.locationTooltipBG} />
      ) : (
        ""
      )}
    </div>
  );

  return (
    <React.Fragment>
      <DreamRadioButtonsComponent
        formControlStyle={{ marginBottom: 0 }}
        name={"jp.locations"}
        value={state.locationType}
        data={LocationsHelper.locationsRadioButtons}
        changeHandler={locationTypeChanged}
      />
      {/* 
        The easiest way to reset Autocomplete value is
        just to unmount and mount it when the location type is changed
       */}
      {state.locationType == DreamAutocompleteHelper.SEARCH_TYPE_CITY
        ? autocompleteComponent
        : ""}
      {state.locationType == DreamAutocompleteHelper.SEARCH_TYPE_STATE
        ? autocompleteComponent
        : ""}
      {state.locationType == DreamAutocompleteHelper.SEARCH_TYPE_COUNTRY
        ? autocompleteComponent
        : ""}
    </React.Fragment>
  );
}

export default LocationsSingleItemComponent;
