import React, { useEffect } from "react";
import { useImmerReducer } from "use-immer";
import { useTranslation } from "react-i18next";
import * as Constants from "../../helpers/Constants";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import * as DreamCheckboxReducer from "./DreamCheckboxReducer";
import * as DreamCheckboxHelper from "./DreamCheckboxHelper";
import DreamCustomCheckbox from "../DreamCustomCheckboxComponent/DreamCustomCheckboxComponent";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  checkboxComponentWrapper: {
    display: "flex",
    justifyContent: "left",
  },

  formControlRoot: {
    marginLeft: 3,
  },
}));

function DreamCheckboxComponent(props) {
  const [t] = useTranslation(Constants.TRANSLATION_COMMON);
  const [state, dispatch] = useImmerReducer(
    DreamCheckboxReducer.reducer,
    DreamCheckboxReducer.originalState
  );

  const classes = useStyles();

  useEffect(() => {
    if (props.data) {
      dispatch({
        type: "gotCheckboxesData",
        value: props.data,
      });
    }
  }, [props.data]);

  return (
    <FormGroup row className={classes.checkboxComponentWrapper}>
      {state.checkboxes.map((item, index) => (
        <FormControlLabel
          classes={{
            root: classes.formControlRoot,
          }}
          key={item.id}
          control={
            <DreamCustomCheckbox
              checked={item.isChecked}
              onChange={(e) =>
                DreamCheckboxHelper.handleChange(
                  e.target.name,
                  e.target.checked,
                  dispatch,
                  props.changeHandler
                )
              }
              name={item.id}
            />
          }
          label={t(item.label)}
        />
      ))}
    </FormGroup>
  );
}

export default DreamCheckboxComponent;
