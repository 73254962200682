export const originalState = {
  id: "",
  industryId: "",
  presentJob: false,
  position: "",
  from: null,
  to: null,
  company: "",
  industryChangedCounter: 0,
};

export function reducer(draft, action) {
  switch (action.type) {
    case "setId":
      draft.id = action.value;
      return;

    case "setIndustryId":
      draft.industryId = action.value;
      return;

    case "changePresentJob":
      draft.presentJob = action.value;
      return;
    case "setPosition":
      draft.position = action.value;
      return;
    case "setFrom":
      draft.from = action.value;
      return;
    case "setTo":
      draft.to = action.value;
      return;
    case "setCompany":
      draft.company = action.value;
      return;
    case "setIndustryChangedCounter":
      draft.industryChangedCounter++;
      return;
  }
}
