import React from "react";
import * as Constants from "../../helpers/Constants";
import FontDownloadOutlinedIcon from "@material-ui/icons/FontDownloadOutlined";
import LocalAtmOutlinedIcon from "@material-ui/icons/LocalAtmOutlined";
import BuildOutlinedIcon from "@material-ui/icons/BuildOutlined";

export const rowsPerPageOptions = [5, 10, 20];

// Get Offer Data
export async function getOfferData(
  offerId,
  Axios,
  ourRequest,
  appDispatch,
  dispatch
) {
  appDispatch({ type: "showBackdrop", value: true });

  let errMsgs;
  let authenticated = true;
  try {
    const response = await Axios.get(`/dreamjob/jo/${offerId}`, {
      cancelToken: ourRequest.token,
    }).catch(function (error) {
      // Errors to be sent to snackbar
      errMsgs = "basic.serverError";
      if (
        error.response &&
        error.response.status &&
        error.response.status === Constants.ERROR_RESPONSE_STATUS
      ) {
        authenticated = false;
        errMsgs = "basic.unauthenticated";
      }
      throw errMsgs;
    });

    const responseData = response.data;

    handleSuccessfulGetOfferData(responseData, appDispatch, dispatch);
  } catch (error) {
    appDispatch({ type: "showBackdrop", value: false });
    appDispatch({
      type: "showMsg",
      value: { status: Constants.SNACKBAR_ERROR, msg: error },
    });
    if (!authenticated) {
      appDispatch({
        type: "logout",
      });
      appDispatch({ type: "openLoginModal", value: true });
    } else {
      dispatch({ type: "disableSubmitButton", value: false });
    }
  }
}

export function handleSuccessfulGetOfferData(data, appDispatch, dispatch) {
  appDispatch({ type: "showBackdrop", value: false });
  dispatch({ type: "offerDataLoaded", value: data.data });
}

// Get candidates matching current offer

export async function getCandidates(
  offerId,
  page_size,
  page,
  conversation,
  Axios,
  ourRequest,
  appDispatch,
  dispatch
) {
  dispatch({ type: "setIsLoading", value: true });
  appDispatch({ type: "showBackdrop", value: true });

  let errMsgs;
  let authenticated = true;
  const params = {
    page_size: page_size,
    page: page,
    conversation: conversation,
  };
  try {
    const response = await Axios.get(`/dreamjob/jo/candidates/${offerId}`, {
      cancelToken: ourRequest.token,
      params: params,
    }).catch(function (error) {
      // Errors to be sent to snackbar
      errMsgs = "basic.serverError";
      if (
        error.response &&
        error.response.status &&
        error.response.status === Constants.ERROR_RESPONSE_STATUS
      ) {
        authenticated = false;
        errMsgs = "basic.unauthenticated";
      }
      dispatch({ type: "setIsLoading", value: false });
      throw errMsgs;
    });

    const responseData = response.data.data;

    handleSuccessfulGetCandidatesData(responseData, appDispatch, dispatch);
  } catch (error) {
    dispatch({ type: "setIsLoading", value: false });

    appDispatch({ type: "showBackdrop", value: false });
    appDispatch({
      type: "showMsg",
      value: { status: Constants.SNACKBAR_ERROR, msg: error },
    });
    if (!authenticated) {
      appDispatch({
        type: "logout",
      });
      appDispatch({ type: "openLoginModal", value: true });
    } else {
      dispatch({ type: "disableSubmitButton", value: false });
    }
  }
}

export function handleSuccessfulGetCandidatesData(data, appDispatch, dispatch) {
  appDispatch({ type: "showBackdrop", value: false });
  dispatch({ type: "candidatesDataLoaded", value: data });
  dispatch({ type: "setPaginationTotalCount", value: data.total_count });
  dispatch({
    type: "setPaginationTotalPageCount",
    value: data.total_page_count,
  });
  dispatch({ type: "setIsLoading", value: false });
}

export const offerLabels = [
  "jov.locations",
  "jov.industryPosition",
  "jov.workplace",
  "jov.employment",
  "jov.salary",
  "jov.workSchedule",
  "jov.start",
  "jov.benefits",
];

export const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "jov.name",
    icon: <FontDownloadOutlinedIcon />,
  },

  {
    id: "messages",
    numeric: false,
    disablePadding: false,
    label: "jov.messages",
    icon: <LocalAtmOutlinedIcon />,
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    label: "jov.actions",
    icon: <BuildOutlinedIcon />,
  },
];
