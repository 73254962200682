import React, { useContext, useEffect } from "react";

import TextField from "@material-ui/core/TextField";
import uuid from "react-uuid";
import { useTranslation } from "react-i18next";
import * as Constants from "../../helpers/Constants";
import StateContext from "../../Context/StateContext";
import { useImmerReducer } from "use-immer";

import * as DreamTextfiledHelper from "./DreamTextfieldHelper";
import * as DreamTextfieldReducer from "./DreamTextfieldReducer";

function DreamTextfieldComponent(props) {
  const appContext = useContext(StateContext);
  const [t] = useTranslation(Constants.TRANSLATION_COMMON);
  const [state, dispatch] = useImmerReducer(
    DreamTextfieldReducer.reducer,
    DreamTextfieldReducer.originalState
  );

  useEffect(() => {
    if (props.value) {
      dispatch({
        type: "valueChange",
        value: props.value,
      });
    }
  }, [props.value]);

  const mandatorySign = props.isMandatory ? "*" : "";

  return (
    <TextField
      className={props.className}
      margin="normal"
      id={props.id ? props.id : uuid()}
      label={
        props.label
          ? `${t(props.label)}${mandatorySign}`
          : `textfiled${mandatorySign}`
      }
      fullWidth={Boolean(props.fullWidth)}
      value={state.value}
      placeholder={props.placeholder}
      InputProps={props.InputProps}
      onChange={(e) =>
        DreamTextfiledHelper.changeHandler(
          e.target.value,
          dispatch,
          props.dispatchChangeHandler
        )
      }
    />
  );
}

export default DreamTextfieldComponent;
