import React, { useEffect, useContext } from "react";
import DreamPage from "../../ui_components/DreamPage";
import DispatchContext from "../../Context/DispatchContext";
import StateContext from "../../Context/StateContext";
import { useImmerReducer } from "use-immer";
import { useHistory } from "react-router-dom";
import Axios from "axios";
import { useTranslation } from "react-i18next";
import * as Constants from "../../helpers/Constants";
import DreamAccordionComponent from "../../ui_components/DreamAccordionComponent/DreamAccordionComponent";
import DreamButton from "../../ui_components/DreamButton/DreamButton";
import TextField from "@material-ui/core/TextField";

import JobOfferDispatchContext from "./Context/JobOfferDispatchContext";
import JobOfferStateContext from "./Context/JobOfferStateContext";
import * as CreateJobOfferHelper from "./CreateJobOfferHelper";
import * as JobOfferReducer from "./CreateJobOfferReducer";
import * as AppRoutes from "../../AppComponent/AppRoutes";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import BusinessCenterOutlinedIcon from "@material-ui/icons/BusinessCenterOutlined";
import BuildOutlinedIcon from "@material-ui/icons/BuildOutlined";
import DateRangeOutlinedIcon from "@material-ui/icons/DateRangeOutlined";
import MonetizationOnOutlinedIcon from "@material-ui/icons/MonetizationOnOutlined";
import ScheduleOutlinedIcon from "@material-ui/icons/ScheduleOutlined";
import AssignmentTurnedInOutlinedIcon from "@material-ui/icons/AssignmentTurnedInOutlined";
import CardGiftcardOutlinedIcon from "@material-ui/icons/CardGiftcardOutlined";

import LocationsComponentJo from "../../containers_components/LocationsComponentJo/LocationsComponentJo";
import PositionComponentJo from "../../containers_components/PositionComponentJo/PositionComponentJo";
import WorkplaceComponentJo from "../../containers_components/WorkplaceComponentJo/WorkplaceComponentJo";
import EmploymentComponentJo from "../../containers_components/EmploymentComponentJo/EmploymentComponentJo";
import WorkScheduleComponentJo from "../../containers_components/WorkScheduleComponentJo/WorkScheduleComponentJo";
import PreferredStartComponentJo from "../../containers_components/PreferredStartComponentJo/PreferredStartComponentJo";
import AdditionalBenefitsComponentJo from "../../containers_components/AdditionalBenefitsComponentJo/AdditionalBenefitsComponentJo";
import SalaryRangeComponentJo from "../../containers_components/SalaryRangeComponentJo/SalaryRangeComponentJo";

const CreateJobOfferPage = () => {
  const [state, dispatch] = useImmerReducer(
    JobOfferReducer.reducer,
    JobOfferReducer.originalState
  );

  const [t] = useTranslation(Constants.TRANSLATION_COMMON);
  const appDispatch = useContext(DispatchContext);
  const appContext = useContext(StateContext);

  const joAccordionItems = [
    {
      heading: "jp.locations",
      secondaryHeading: "",
      mainIcon: <LocationOnOutlinedIcon />,
      mainContent: <LocationsComponentJo />,
      isMandatory: true,
    },
    {
      heading: "cv.position",
      secondaryHeading: "",
      mainIcon: <BusinessCenterOutlinedIcon />,
      mainContent: <PositionComponentJo />,
      itemExpanded: Boolean(state.expandedAccordion === "panel2"),
      isMandatory: true,
    },
    {
      heading: "jp.workplace",
      secondaryHeading: "",
      mainIcon: <BuildOutlinedIcon />,
      mainContent: <WorkplaceComponentJo />,
      isMandatory: true,
    },
    {
      heading: "jp.employment",
      secondaryHeading: "",
      mainIcon: <DateRangeOutlinedIcon />,
      mainContent: <EmploymentComponentJo />,
      isMandatory: true,
    },
    {
      heading: "jo.salary",
      secondaryHeading: "",
      mainIcon: <MonetizationOnOutlinedIcon />,
      mainContent: <SalaryRangeComponentJo />,
      isMandatory: true,
    },
    {
      heading: "jp.work",
      secondaryHeading: "",
      mainIcon: <ScheduleOutlinedIcon />,
      mainContent: <WorkScheduleComponentJo />,
      isMandatory: true,
    },
    {
      heading: "jp.start",
      secondaryHeading: "",
      mainIcon: <AssignmentTurnedInOutlinedIcon />,
      mainContent: <PreferredStartComponentJo />,
      isMandatory: true,
    },
    {
      heading: "jp.benefits",
      secondaryHeading: "",
      mainIcon: <CardGiftcardOutlinedIcon />,
      mainContent: <AdditionalBenefitsComponentJo />,
    },
  ];

  useEffect(() => {
    dispatch({
      type: "setTooltipVisible",
      value: Boolean(appContext.appState.lang === "bg"),
    });
  }, [appContext.appState.lang]);

  const lang = appContext.appState.lang;

  useEffect(() => {
    let selectedCurrency = "";
    switch (lang) {
      case "en-US":
        selectedCurrency = "USD";
        break;
      case "en":
        selectedCurrency = "EUR";
        break;
      case "bg":
        selectedCurrency = "BGN";
        break;
    }

    dispatch({ type: "currencyChanged", value: selectedCurrency });
  }, []);

  let history = useHistory();

  useEffect(() => {
    if (state.formData.position.industryChangedCounter > 1) {
      dispatch({
        type: "positionPositionChanged",
        value: { id: "", name: "" },
      });
    }
  }, [state.formData.position.industryChangedCounter]);

  /**
   * On successfully submited form
   */
  useEffect(() => {
    if (state.formSuccessfullySubmittedCount > 0) {
      history.push(AppRoutes.ROUTE_JOB_OFFERS);
    }
  }, [state.formSuccessfullySubmittedCount]);

  return (
    <JobOfferStateContext.Provider value={state}>
      <JobOfferDispatchContext.Provider value={dispatch}>
        <DreamPage title={"jo.createJO"}>
          <TextField
            autoComplete="off"
            autoFocus
            margin="dense"
            id="offerName"
            name="offerName"
            label={t("jo.offerName")}
            value={state.formData.offerName ? state.formData.offerName : ""}
            onChange={(e) =>
              dispatch({
                type: "changeOfferName",
                value: e.target.value,
              })
            }
          />
          <DreamAccordionComponent
            dispatchContexName="cjoDispatch"
            accordionItems={joAccordionItems}
          />
          <br />
          <DreamButton
            dreamButtonStyle={{ minWidth: 130 }}
            buttonDisabled={state.disabledSaveButton}
            handleClick={(e) =>
              CreateJobOfferHelper.handleSave(
                state.formData,
                dispatch,
                appDispatch,
                Axios
              )
            }
            color="primary"
            kind="contained"
            buttonLabel="jo.save"
          ></DreamButton>
        </DreamPage>
      </JobOfferDispatchContext.Provider>
    </JobOfferStateContext.Provider>
  );
};

export default CreateJobOfferPage;
