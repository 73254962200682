import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";

import {
  DreamDialogRoot,
  CloseButton,
  CloseButton__Icon,
} from "./DreamDialog.module.scss";
import { grey } from "@material-ui/core/colors";

const DialogTitle = (props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={DreamDialogRoot} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={CloseButton}
          onClick={onClose}
        >
          <CloseIcon className={CloseButton__Icon} />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    boxShadow: "0px 10px 10px rgba(0, 0, 0, 0.25)",
    borderRadius: 15,
  },
}));

function DreamDialog(props) {
  const classes = useStyles();
  let dialogContent = (
    <React.Fragment>
      <DialogContent dividers>{props.content}</DialogContent>
      <DialogActions>{props.buttons}</DialogActions>
    </React.Fragment>
  );

  if (Boolean(props.submitHandler)) {
    dialogContent = (
      <form onSubmit={(e) => props.submitHandler(e)}>{dialogContent}</form>
    );
  }

  return (
    <Dialog
      classes={props.classes}
      fullWidth
      maxWidth={"xs"}
      onClose={props.closeHandler}
      aria-labelledby="customized-dialog-title"
      open={Boolean(props.open)}
    >
      <DialogTitle
        id="customized-dialog-title"
        onClose={props.closeHandler}
        style={{
          paddingTop: 10,
          paddingBottom: 10,
          paddingLeft: 15,
          paddingRight: 0,
        }}
      >
        {props.title}
      </DialogTitle>
      {dialogContent}
    </Dialog>
  );
}

export default DreamDialog;
