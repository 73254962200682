import React, { useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import { useImmerReducer } from "use-immer";
import { useTranslation } from "react-i18next";
import * as Constants from "../../helpers/Constants";
import * as Colors from "../../styles/01.settings/01-settings-colors.module.scss";
import * as JoReducer from "./CandidatesWithMessagesViewPageReducer";
import * as JoHelper from "./CandidatesWithMessagesViewPageHelper";
import DreamPage from "../../ui_components/DreamPage";
import Axios from "axios";
import DispatchContext from "../../Context/DispatchContext";
import DreamCardMedia from "../../ui_components/DreamCardMediaComponent/DreamCardMedia";
import MailIcon from "@material-ui/icons/Mail";
import Badge from "@material-ui/core/Badge";
import DreamButton from "../../ui_components/DreamButton/DreamButton";
import DreamAvatar from "../../ui_components/DreamAvatarComponent/DreamAvatarComponent";
import { Link as RouterLink, useParams } from "react-router-dom";
import TablePagination from "@material-ui/core/TablePagination";
import * as PollingHook from "../../helpers/Functions/PollingHook/PollingHook";

const CONVERSATION = 1;
const REFRESH_INTERVAL = 20000;

const useStyles = makeStyles({
  table: {
    maxWidth: 770,
  },
  title: {
    fontSize: 24,
    fontWeight: "bold",
  },
  tableCell: {
    textAlign: "center",
    borderBottom: "none",
    padding: 2,
  },
  tblContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    overflowX: "visible",
  },
  tblRow: {},
  cardHeaderAction: {
    alignSelf: "center",
    marginTop: 0,
    marginRight: 0,
  },
  actionsButton: {
    minWidth: "9em",
    alignSelf: "center",
  },
  mailIcon: {
    marginRight: 5,
  },
  menuButton: {
    width: "100%",
  },
  subHeader: {
    color: Colors.success,
  },
  subHeaderDanger: {
    color: Colors.danger,
  },
  //Pagination
  paginationToolbar: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
  },
});

const pageTitle = "jov.jocwmPageTitle";

const CandidatesWithMessagesViewPage = () => {
  const classes = useStyles();
  const [t] = useTranslation(Constants.TRANSLATION_COMMON);
  const appDispatch = useContext(DispatchContext);

  const { joID } = useParams();

  const [state, dispatch] = useImmerReducer(
    JoReducer.reducer,
    JoReducer.originalState
  );

  const handleChangePage = (newPage) => {
    dispatch({ type: "setPage", value: newPage });
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch({
      type: "setRowsPerPage",
      value: parseInt(event.target.value, 10),
    });
    dispatch({ type: "setPage", value: 0 });
  };

  const emptyRows =
    state.pagination.currentPageSize -
    Math.min(
      state.pagination.currentPageSize,
      parseInt(state.pagination.totalCount, 10).length -
        state.pagination.currentPage * state.pagination.currentPageSize
    );

  // On mount
  useEffect(() => {
    const ourRequest = Axios.CancelToken.source();
    JoHelper.getOfferData(joID, Axios, ourRequest, appDispatch, dispatch);

    return () => {
      ourRequest.cancel();
    };
  }, []);

  // On page_size or page change
  useEffect(() => {
    const ourRequest = Axios.CancelToken.source();
    JoHelper.getCandidates(
      joID,
      state.pagination.currentPageSize,
      state.pagination.currentPage + 1,
      CONVERSATION,
      Axios,
      ourRequest,
      appDispatch,
      dispatch
    );

    return () => {
      ourRequest.cancel();
    };
  }, [state.pagination.currentPageSize, state.pagination.currentPage]);

  PollingHook.useInterval(async () => {
    const ourRequest = Axios.CancelToken.source();
    JoHelper.getCandidates(
      joID,
      state.pagination.currentPageSize,
      state.pagination.currentPage + 1,
      CONVERSATION,
      Axios,
      ourRequest,
      appDispatch,
      dispatch
    );

    return () => {
      ourRequest.cancel();
    };
  }, REFRESH_INTERVAL);

  return (
    <DreamPage
      title={t(pageTitle)}
      backButton
      backButtonDisabled={
        !(state.offerDataLoaded && state.candidatesDataLoaded)
      }
      backButtonTo={`/job-offers`}
    >
      <TableContainer className={classes.tblContainer}>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          aria-label="enhanced table"
        >
          <TableBody>
            {state.candidates.map((candidate) => (
              <TableRow key={candidate.jpID}>
                <TableCell className={classes.tableCell}>
                  <DreamCardMedia
                    cardHeader
                    avatar={
                      <DreamAvatar
                        style={{ height: "50px", width: "50px" }}
                        imageSource={`${Constants.BASE_URL}upload/${candidate.picture}`}
                      />
                    }
                    cardHeaderStyle={{ padding: "10px 16px 10px 16px" }}
                    cardHeaderAction={classes.cardHeaderAction}
                    subheader={candidate.name}
                    buttonAction={
                      <DreamButton
                        className={classes.actionsButton}
                        kind="contained"
                        buttonLabel={t("jos.messages")}
                        color={"secondary"}
                        btnComponent={RouterLink}
                        to={`/job-offer-candidate-view/${candidate.id}/${joID}/${candidate.jpID}`}
                        icon={
                          parseInt(candidate.unread_messages) > 0 ? (
                            <Badge
                              badgeContent={candidate.unread_messages}
                              color="primary"
                            >
                              <MailIcon className={classes.mailIcon} />
                            </Badge>
                          ) : (
                            <MailIcon className={classes.mailIcon} />
                          )
                        }
                      />
                    }
                  />
                </TableCell>
              </TableRow>
            ))}
            {emptyRows > 0 && (
              <TableRow>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        classes={{ toolbar: classes.paginationToolbar }}
        backIconButtonProps={{
          disabled:
            state.pagination.isLoading || state.pagination.currentPage === 0,
        }}
        nextIconButtonProps={{
          disabled:
            state.pagination.isLoading ||
            state.pagination.currentPage ===
              state.pagination.totalPageCount - 1,
        }}
        labelRowsPerPage={t("tablePagination.rowsPerPage")}
        rowsPerPageOptions={JoHelper.rowsPerPageOptions}
        component="div"
        count={parseInt(state.pagination.totalCount, 10)}
        rowsPerPage={state.pagination.currentPageSize}
        page={parseInt(state.pagination.currentPage, 10)}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </DreamPage>
  );
};

export default CandidatesWithMessagesViewPage;
