import React from "react";
import * as Constants from "../../helpers/Constants";
import FontDownloadOutlinedIcon from "@material-ui/icons/FontDownloadOutlined";
import BusinessCenterOutlinedIcon from "@material-ui/icons/BusinessCenterOutlined";
import PermIdentityOutlinedIcon from "@material-ui/icons/PermIdentityOutlined";

export const PROMOTE_PREFERENCE = 2;

export async function getJobPreferences(
  Axios,
  ourRequest,
  appDispatch,
  dispatch
) {
  let errMsgs;
  let authenticated = true;
  appDispatch({ type: "showBackdrop", value: true });

  try {
    const response = await Axios.get(`/dreamjob/jp`, {
      cancelToken: ourRequest.token,
    }).catch(function (error) {
      // Errors to be sent to scanckbar
      errMsgs = "basic.serverError";
      if (
        error.response &&
        error.response.status &&
        error.response.status === Constants.ERROR_RESPONSE_STATUS
      ) {
        authenticated = false;
        errMsgs = "basic.unauthenticated";
      }
      throw errMsgs;
    });

    const responseData = response.data;
    handleSuccessfulGetData(responseData, dispatch, appDispatch);
  } catch (error) {
    appDispatch({ type: "showBackdrop", value: false });

    appDispatch({
      type: "showMsg",
      value: { status: Constants.SNACKBAR_ERROR, msg: error },
    });
    if (!authenticated) {
      appDispatch({
        type: "logout",
      });
      appDispatch({ type: "openLoginModal", value: true });
    } else {
      dispatch({ type: "disableSubmitButton", value: false });
    }
  }
}

export function handleSuccessfulGetData(data, dispatch, appDispatch) {
  appDispatch({ type: "showBackdrop", value: false });
  dispatch({ type: "getJobPreferences", value: data.data });
}

// Update status
export async function changeStatus(data, Axios, appDispatch, dispatch, t) {
  let errMsgs;
  let authenticated = true;

  try {
    const response = await Axios.put(
      `${Constants.BASE_URL}dreamjob/jp/status/${data.id}`,
      {
        headers: {
          "content-type": `application/json`,
        },
      }
    ).catch(function (error) {
      // Errors to be sent to scanckbar
      errMsgs = t("basic.serverError");
      if (
        error.response &&
        error.response.status &&
        error.response.status === Constants.ERROR_RESPONSE_STATUS
      ) {
        authenticated = false;
        errMsgs = "basic.unauthenticated";
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
      throw errMsgs;
    });

    const responseData = response.data.data;

    let errsArr = [];
    if (!responseData.success && responseData.messages.length) {
      responseData.messages.forEach((msg) => {
        errsArr.push(`registerForm.serverMsgs.${msg.code}`);
      });
      throw errsArr;
    }
    handleSuccessfulUpdated(responseData, dispatch);
  } catch (error) {
    appDispatch({ type: "showBackdrop", value: false });
    appDispatch({
      type: "showMsg",
      value: { status: Constants.SNACKBAR_ERROR, msg: error },
    });

    if (!authenticated) {
      appDispatch({
        type: "logout",
      });

      appDispatch({ type: "openLoginModal", value: true });
    }
  }
}

/**
 * Successfuly updated
 * @param {dispatch} dispatch
 */
function handleSuccessfulUpdated(responseData, dispatch) {
  dispatch({
    type: "changeStatus",
    value: { id: responseData.id, disabled: responseData.disabled },
  });
}

// DELETE jpreference
export function handleDelete(dataId, Axios, appDispatch, dispatch) {
  dispatch({ type: "setModalOpen", value: false });
  dispatch({ type: "confirmDelete" });
  let deleteJP = deleteRequest(dataId, Axios);

  if (!deleteJP) {
    appDispatch({
      type: "showMsg",
      value: {
        status: Constants.SNACKBAR_ERROR,
        msg: "jps.notDeletedJP",
      },
    });
  }
}

export async function deleteRequest(jpId, Axios) {
  let success = true;
  try {
    const response = await Axios.delete(
      `${Constants.BASE_URL}dreamjob/jp/${jpId}`
    ).catch(function (error) {
      // Errors to be sent to scanckbar
      let errMsgs = "Server error";
      if (error.response) {
        // Request made and server responded
        if (error.response.data.message) {
          errMsgs = JSON.parse(error.response.data.message);
        }
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
      throw errMsgs;
    });
  } catch (error) {
    console.log("Error delete JP:", error);
    success = false;
  }

  return success;
}

// Checks if user has filled out their First name,  Last name , Birth date and CV

export async function checkCreate(Axios, ourRequest, appDispatch) {
  let errMsgs;
  let authenticated = true;
  try {
    const response = await Axios.get(`/dreamjob/jp/check_create`, {
      cancelToken: ourRequest.token,
    }).catch(function (error) {
      // Errors to be sent to snackbar
      console.log("ERR::::", error.message);
      errMsgs = "basic.serverError";
      if (
        error.response &&
        error.response.status &&
        error.response.status === Constants.ERROR_RESPONSE_STATUS
      ) {
        authenticated = false;
        errMsgs = "basic.unauthenticated";
      }
      throw errMsgs;
    });

    let errsArr = [];

    const responseData = response.data.data;

    if (!responseData.success && responseData.messages.length) {
      const checkProfile = responseData.messages.some(
        (msg) => msg.code === (3022 || 3023 || 3024)
      );
      if (checkProfile) {
        errsArr.push(
          "You cant create a Job Preference before profile data is filled out"
        );
        responseData.messages.forEach((msg) => {
          if (msg.code !== 3021) {
            errsArr.push(`serverErrors.${msg.code}`);
          }
        });
        throw errsArr;
      } else {
        appDispatch({
          type: "setIsProfileDataFilled",
          value: true,
        });
      }

      const checkCV = responseData.messages.some((msg) => msg.code === 3021);
      if (checkCV) {
        errsArr.push(
          "You cant create a Job Preference before your CV is filled out"
        );
        responseData.messages.forEach((msg) => {
          if (msg.code === 3021) {
            errsArr.push(`serverErrors.${msg.code}`);
          }
        });
        throw errsArr;
      } else {
        appDispatch({
          type: "setIsCvFilled",
          value: true,
        });
      }
    }

    handleSuccessfulGetCheckCreate(responseData, appDispatch);
  } catch (error) {
    appDispatch({
      type: "showMsg",
      value: { status: Constants.SNACKBAR_ERROR, msg: error },
    });
    if (!authenticated) {
      appDispatch({
        type: "logout",
      });
    }
  }
}

function handleSuccessfulGetCheckCreate(data, appDispatch) {
  appDispatch({
    type: "setIsProfileDataFilled",
    value: true,
  });
  appDispatch({
    type: "setIsCvFilled",
    value: true,
  });
  appDispatch({ type: "setCheckJPCreate", value: data.success });
}

// Promote preference

export async function handlePromote(
  actionType, // actionType => 1 for unlock candidate, 2 for promote preference
  preferenceId,
  Axios,
  appDispatch,
  dispatch
) {
  appDispatch({ type: "showBackdrop", value: true });

  let errMsgs;
  let authenticated = true;
  let postParams = {
    type: actionType,
    target: preferenceId,
  };

  try {
    const response = await Axios.post(`/itransactions`, postParams, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
      },
    }).catch(function (error) {
      // Errors to be sent to snackbar
      errMsgs = "basic.serverError";
      if (
        error.response &&
        error.response.status &&
        error.response.status === Constants.ERROR_RESPONSE_STATUS
      ) {
        authenticated = false;
        errMsgs = "basic.unauthenticated";
      }
      throw errMsgs;
    });

    const responseData = response.data.data;

    let errsArr = [];
    if (!responseData.success && responseData.messages.length) {
      responseData.messages.forEach((msg) => {
        errsArr.push(`serverErrors.${msg.code}`);
      });
      dispatch({ type: "setPromoteModalOpen", value: false });
      throw errsArr;
    }

    handleSuccessfulPromote(responseData, appDispatch, dispatch);
  } catch (error) {
    appDispatch({ type: "showBackdrop", value: false });
    appDispatch({
      type: "showMsg",
      value: { status: Constants.SNACKBAR_ERROR, msg: error },
    });
    if (!authenticated) {
      appDispatch({
        type: "logout",
      });
      appDispatch({ type: "openLoginModal", value: true });
    } else {
      dispatch({ type: "disableSubmitButton", value: false });
    }
  }
}

export function handleSuccessfulPromote(responseData, appDispatch, dispatch) {
  appDispatch({ type: "showBackdrop", value: false });
  dispatch({ type: "setPromoteModalOpen", value: false });
  dispatch({
    type: "confirmPromote",
    value: {
      id: responseData.id,
      promotionEndDate: responseData.promotionEndDate,
    },
  });
}

export const headCells = [
  {
    id: "promote",
    numeric: false,
    disablePadding: false,
    label: "jps.promote",
    icon: <FontDownloadOutlinedIcon />,
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    label: "jps.actions",
    icon: <BusinessCenterOutlinedIcon />,
  },
  {
    id: "jpName",
    numeric: false,
    disablePadding: false,
    label: "jps.jpName",
    icon: <PermIdentityOutlinedIcon />,
  },
  {
    id: "activeInactive",
    numeric: false,
    disablePadding: false,
    label: "jps.activeInactive",
    icon: <FontDownloadOutlinedIcon />,
  },
];
