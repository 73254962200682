import React, { useContext } from "react";
import Axios from "axios";
import DispatchContext from "../../Context/DispatchContext";
import { useLocation, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as Constants from "../../helpers/Constants";
import DreamPage from "../../ui_components/DreamPage";
import * as RouteConstants from "../../AppComponent/AppRoutes";
import { makeStyles } from "@material-ui/core/styles";
import AppContext from "../../Context/StateContext";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import DreamButton from "../../ui_components/DreamButton/DreamButton";
import * as Colors from "../../styles/01.settings/01-settings-colors.module.scss";
import queryString from "query-string";
import * as CpHelper from "./ConfirmPageHelper";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const ConfirmEmail = () => {
  const appContext = useContext(AppContext);
  const appDispatch = useContext(DispatchContext);

  const [t] = useTranslation(Constants.TRANSLATION_COMMON);
  let history = useHistory();
  const theme = useTheme();
  const matchesXSdown = useMediaQuery(theme.breakpoints.down("xs"));

  // Get users location (URL)
  const location = useLocation();

  // const parsedUrl = queryString.parse(window.location.search);
  const parsedUrl = queryString.parse(location.search);
  const token = parsedUrl;

  const useStyles = makeStyles((theme) => ({
    page: {
      height: "100%",
      display: "flex",
      alignItems: "center",
    },
    contentContainer: {
      width: "100%",
      height: "max-content",
      display: "flex",
      justifyContent: matchesXSdown ? "center" : "space-around",
      paddingLeft: 10,
      paddingRight: 10,
    },
    contentItem: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      paddingLeft: 10,
      paddingRight: 10,
    },
    contentTitle: {
      width: "100%",
      fontSize: matchesXSdown ? 24 : 36,
      color: Colors.hpTitleOrange,
      textAlign: "left",
    },
    mainContent: {
      display: "flex",
      fontSize: matchesXSdown ? 36 : 48,
      color: Colors.primary,
    },
    formItem: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    paper: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      boxShadow: "0px 10px 10px rgba(0, 0, 0, 0.25)",
      borderRadius: 15,
    },
    wrapper: {
      padding: "50px 30px 50px 30px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      width: "100%",
    },
    confirmButton: {
      width: "10em",
      alignSelf: "center",
      marginTop: 30,
    },
    confirmationText: {
      marginTop: 2,
      marginBottom: 2,
    },
  }));

  const classes = useStyles();

  return (
    <DreamPage
      route={RouteConstants.ROUTE_HOME}
      className={classes.page}
      dreamPageWrapperStyle={{ height: "100%" }}
    >
      <Grid container className={classes.contentContainer}>
        <Grid item className={classes.contentItem}>
          <h2 className={classes.contentTitle}>JobPreference.com</h2>
          <h1 className={classes.mainContent}>{t("mainContent.text")}</h1>
        </Grid>
        <Grid item classes={{ root: classes.formItem }}>
          <div
            style={{
              maxWidth: 450,
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <h2>{t("confirm.verify")}</h2>
            {!appContext.appState.loggedIn ? (
              <Paper elevation={3} className={classes.paper}>
                <div className={classes.wrapper}>
                  <p className={classes.confirmationText}>
                    {t("confirm.welcome")}
                  </p>
                  <p className={classes.confirmationText}>
                    {t("confirm.click")}
                  </p>

                  <DreamButton
                    className={classes.confirmButton}
                    kind="contained"
                    buttonLabel={"basic.confirm"}
                    color={"primary"}
                    buttonLabelColor={Colors.white}
                    handleClick={(e) => {
                      e.preventDefault();
                      const ourRequest = Axios.CancelToken.source();
                      CpHelper.confirm(
                        token,
                        Axios,
                        ourRequest,
                        appDispatch,
                        t,
                        history
                      );
                    }}
                  />
                </div>
              </Paper>
            ) : null}
          </div>
        </Grid>
      </Grid>
    </DreamPage>
  );
};

export default ConfirmEmail;
