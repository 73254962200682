import React, { useEffect, useContext, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import * as Colors from "../../styles/01.settings/01-settings-colors.module.scss";
import StateContext from "../../Context/StateContext";
import { useImmerReducer } from "use-immer";
import Grid from "@material-ui/core/Grid";
import DreamSelectComponent from "../../ui_components/DreamSelect/DreamSelectComponent";
import * as DreamSelectHelper from "../../ui_components/DreamSelect/DreamSelectHelper";
import * as Constants from "../../helpers/Constants";
import DreamAutocomplete from "../../ui_components/DreamAutocomplete/DreamAutocomplete";
import * as DreamAutocompleteHelper from "../../ui_components/DreamAutocomplete/DreamAutocompleteHelper";
import * as PositionReducer from "./PositionReducerJo";
import JobOfferDispatchContext from "../../Pages/CreateJobOfferPage/Context/JobOfferDispatchContext";
import JobOfferStateContext from "../../Pages/CreateJobOfferPage/Context/JobOfferStateContext";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  checkboxRoot: {
    color: Colors.primary,
    "&$checked": {
      color: Colors.primary,
    },
  },
  checkboxChecked: {
    backgroundColor: Colors.primary,
  },
});

function PositionComponentJo(props) {
  const jobOfferDispatchContext = useContext(JobOfferDispatchContext);
  const jobOfferStateContext = useContext(JobOfferStateContext);
  const classes = useStyles();
  const selectInputRef = useRef(null);
  const appContext = useContext(StateContext);
  const currentLang = appContext.appState.lang;
  const [t] = useTranslation(Constants.TRANSLATION_COMMON);
  const [state, dispatch] = useImmerReducer(
    PositionReducer.reducer,
    PositionReducer.originalState
  );

  function getAdditionalParams() {
    let additionalParams = {};
    additionalParams.lang = appContext.appState.lang
      .split("-")
      .join("_")
      .toLowerCase();
    if (state.industryId) {
      additionalParams.industry = state.industryId;
    }
    // if (state.position) {
    //   additionalParams.position = state.position;
    // }
    return additionalParams;
  }

  function industryChanged(value) {
    jobOfferDispatchContext({
      type: "positionIndustryChanged",
      value: value ? value : "",
    });

    dispatch({
      type: "setIndustryId",
      value: value ? value : "",
    });
    jobOfferDispatchContext({
      type: "setIndustryChangedCounter",
    });
  }

  function positionChanged(value) {
    jobOfferDispatchContext({
      type: "positionPositionChanged",
      value: value ? value : { id: "", name: "" },
    });
  }

  return (
    <Grid container style={{ marginTop: 10 }}>
      <DreamSelectComponent
        zeroElementTypeAll
        fullWidth
        shrink
        displayEmpty
        dataType={DreamSelectHelper.DATA_TYPE_INDUSTRY}
        urlAPI={`dreamjob/industry`}
        label={"profile.industry"}
        dispatchChangeHandler={industryChanged}
        isMandatory={false}
        value={jobOfferStateContext.formData.position.industryId}
      />
      {jobOfferStateContext.formData.position.industryId > 0 && (
        <DreamAutocomplete
          zeroElementTypeAll
          fullWidth
          label={"cv.position"}
          searchType={DreamAutocompleteHelper.SEARCH_TYPE_POSITION}
          apiUrl={`${Constants.BASE_URL}dreamjob/position`}
          searchParamName={"search_string"}
          changeHandler={positionChanged}
          // lang={currentLang}
          additionalApiParams={getAdditionalParams()}
          isMandatory={false}
          preselectedValue={{
            id: jobOfferStateContext.formData.position.positionId,
            name: jobOfferStateContext.formData.position.positionName,
          }}
        />
      )}
    </Grid>
  );
}

export default PositionComponentJo;
