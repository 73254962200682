import React from "react";
import * as Constants from "../../helpers/Constants";
import DreamButton from "../../ui_components/DreamButton/DreamButton";
import {
  CertificateWrapper,
  Description,
} from "./JobOfferCandidateView.module.scss";

export const UNLOCK_CANDIDATE = 1;
export const PROMOTE_SELF = 2;

export async function getCandidatePreferencesIds(
  candidateId,
  offerId,
  Axios,
  ourRequest,
  appDispatch,
  dispatch
) {
  appDispatch({ type: "showBackdrop", value: true });

  let errMsgs;
  let authenticated = true;
  try {
    const response = await Axios.get(
      `/dreamjob/jp/user/${candidateId}/${offerId}`,
      {
        headers: {
          "content-type": `application/json;`,
        },
        cancelToken: ourRequest.token,
      }
    ).catch(function (error) {
      // Errors to be sent to snackbar
      errMsgs = "basic.serverError";
      if (
        error.response &&
        error.response.status &&
        error.response.status === Constants.ERROR_RESPONSE_STATUS
      ) {
        authenticated = false;
        errMsgs = "basic.unauthenticated";
      }
      throw errMsgs;
    });

    const responseData = response.data.data;

    handleSuccessfulGetIdsData(responseData, appDispatch, dispatch);
  } catch (error) {
    appDispatch({ type: "showBackdrop", value: false });
    appDispatch({
      type: "showMsg",
      value: { status: Constants.SNACKBAR_ERROR, msg: error },
    });
    if (!authenticated) {
      appDispatch({
        type: "logout",
      });
      appDispatch({ type: "openLoginModal", value: true });
    } else {
      dispatch({ type: "disableSubmitButton", value: false });
    }
  }
}

export function handleSuccessfulGetIdsData(data, appDispatch, dispatch) {
  appDispatch({ type: "showBackdrop", value: false });
  dispatch({ type: "candidatePreferencesIdsLoaded", value: formatIds(data) });
}

function formatIds(data) {
  let objArr = Object.values(data);
  let ids = [];
  objArr.forEach((obj) => {
    ids.push(parseInt(obj.id));
  });
  return ids;
}

export async function getCandidatePreference(
  preferenceId,
  Axios,
  ourRequest,
  appDispatch,
  dispatch,
  t
) {
  appDispatch({ type: "showBackdrop", value: true });

  let errMsgs;
  let authenticated = true;

  try {
    const response = await Axios.get(`/dreamjob/jp/${preferenceId}`, {
      headers: {
        "content-type": `application/json;`,
      },
      cancelToken: ourRequest.token,
    }).catch(function (error) {
      // Errors to be sent to snackbar
      errMsgs = "basic.serverError";
      if (
        error.response &&
        error.response.status &&
        error.response.status === Constants.ERROR_RESPONSE_STATUS
      ) {
        authenticated = false;
        errMsgs = "basic.unauthenticated";
      }
      throw errMsgs;
    });

    const responseData = response.data.data;

    handleSuccessfulGetPreferenceData(responseData, appDispatch, dispatch, t);
  } catch (error) {
    appDispatch({ type: "showBackdrop", value: false });
    appDispatch({
      type: "showMsg",
      value: { status: Constants.SNACKBAR_ERROR, msg: error },
    });
    if (!authenticated) {
      appDispatch({
        type: "logout",
      });
      appDispatch({ type: "openLoginModal", value: true });
    } else {
      dispatch({ type: "disableSubmitButton", value: false });
    }
  }
}

export function handleSuccessfulGetPreferenceData(
  data,
  appDispatch,
  dispatch,
  t
) {
  appDispatch({ type: "showBackdrop", value: false });
  dispatch({
    type: "candidatePreferenceDataLoaded",
    value: { data: data, t: t },
  });
}

export const preferenceLabels = [
  "jocv.locations",
  "jocv.industry",
  "jocv.position",
  "jocv.workplace",
  "jocv.employment",
  "jocv.salary",
  "jocv.workSchedule",
  "jocv.start",
  "jocv.benefits",
];

export function formatLocations(locations) {
  let locationNames = [];
  locations.forEach((loc) => {
    locationNames.push(loc.location_name);
  });
  return locationNames.join("; ");
}

export function formatSalary(data) {
  let formatted = "";
  formatted = `${data.salary_currency == "USD" ? "$" : "€"}${
    data.salary_from != null ? data.salary_from : data.salary_hourly_from
  }-${data.salary_to != null ? data.salary_to : data.salary_hourly_to} ${
    data.salary_from != null ? "Mth" : "Hou"
  }`;
  return formatted;
}

export function setTypeLabel(types, type) {
  let label = "";
  for (let i = 0; i < types.length; i++) {
    if (types[i].value === type) {
      label = types[i].label;
      break;
    }
  }
  return label;
}

export function formatBenefits(benefits, preferenceData) {
  let formatted = [];
  if (preferenceData.paid_leave == 1) {
    formatted.push(benefits[0].label);
  }
  if (preferenceData.food_vouchers == 1) {
    formatted.push(benefits[1].label);
  }
  if (preferenceData.housing == 1) {
    formatted.push(benefits[2].label);
  }
  if (preferenceData.car == 1) {
    formatted.push(benefits[3].label);
  }
  if (preferenceData.phone == 1) {
    formatted.push(benefits[4].label);
  }
  return formatted;
}

export function populateBenefits(benefits, t) {
  let populated = [];
  benefits.forEach((benefit) => {
    populated.push(t(benefit));
  });
  return populated.join(", ");
}

// Candidates table
export function formatSalaryData(preference) {
  let formattedData = "jocv.inRange";
  let currencySign = "";
  switch (preference.salary_currency) {
    case "EUR":
      currencySign = "€";
      break;
    case "USD":
      currencySign = "$";
      break;
    case "BGN":
      currencySign = "BGN";
      break;
  }
  if (!isNaN(preference.salary_monthly) || !isNaN(preference.salary_hourly)) {
    let monthlyHourly =
      preference.salary_type === "monthly"
        ? `${preference.salary_monthly} Mth`
        : `${preference.salary_hourly} Hr`;
    formattedData = `${currencySign} ${monthlyHourly}`;
  }
  return formattedData;
}

export function populatePreferenceData(index, data, t) {
  let result = "";
  switch (index) {
    case 0:
      data[index] ? (result = data[index]) : (result = t("jov.any"));
      return result;
    case 1:
      result = t(`industries.${data[index]}`);
      return result;
    case 2:
      result = t(`positions.${data[index].id}`);
      return result;
    case 3:
    case 4:
      result = t(data[index]);
      return result;
    case 5:
      result = t(data[index]);
      return result;
    case 6:
    case 7:
      result = t(data[index]);
      return result;
    case 8:
      data[index] ? (result = populateBenefits(data[index], t)) : (result = "");
      return result;
  }
}

// Check candidate visibility
export async function checkCandidateVisibility(
  candidateId,
  Axios,
  ourRequest,
  appDispatch,
  dispatch
) {
  appDispatch({ type: "showBackdrop", value: true });

  let errMsgs;
  let authenticated = true;
  try {
    const response = await Axios.get(`/user/visibility/${candidateId}`, {
      headers: {
        "content-type": `application/json;`,
      },
      cancelToken: ourRequest.token,
    }).catch(function (error) {
      // Errors to be sent to snackbar
      errMsgs = "basic.serverError";
      if (
        error.response &&
        error.response.status &&
        error.response.status === Constants.ERROR_RESPONSE_STATUS
      ) {
        authenticated = false;
        errMsgs = "basic.unauthenticated";
      }
      throw errMsgs;
    });

    const responseData = response.data.data;

    handleSuccessfulGetVisibilityData(responseData, appDispatch, dispatch);
  } catch (error) {
    appDispatch({ type: "showBackdrop", value: false });
    appDispatch({
      type: "showMsg",
      value: { status: Constants.SNACKBAR_ERROR, msg: error },
    });
    if (!authenticated) {
      appDispatch({
        type: "logout",
      });
      appDispatch({ type: "openLoginModal", value: true });
    } else {
      dispatch({ type: "disableSubmitButton", value: false });
    }
  }
}

export function handleSuccessfulGetVisibilityData(data, appDispatch, dispatch) {
  appDispatch({ type: "showBackdrop", value: false });
  dispatch({ type: "setCandidateVisibility", value: data.result });
}

// Unlock user

export async function handleUnlock(
  actionType, // actionType => 1 for unlock candidate, 2 for promote self
  preferenceId,
  Axios,
  appDispatch,
  dispatch
) {
  appDispatch({ type: "showBackdrop", value: true });

  let errMsgs;
  let authenticated = true;
  let postParams = {
    type: actionType,
    target: preferenceId,
  };

  try {
    const response = await Axios.post(`/itransactions`, postParams, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
      },
    }).catch(function (error) {
      // Errors to be sent to snackbar
      errMsgs = "basic.serverError";
      if (
        error.response &&
        error.response.status &&
        error.response.status === Constants.ERROR_RESPONSE_STATUS
      ) {
        authenticated = false;
        errMsgs = "basic.unauthenticated";
      }
      throw errMsgs;
    });

    const responseData = response.data.data;

    let errsArr = [];
    if (!responseData.success && responseData.messages.length) {
      responseData.messages.forEach((msg) => {
        errsArr.push(`serverErrors.${msg.code}`);
      });
      dispatch({ type: "setModalOpen", value: false });
      throw errsArr;
    }

    handleSuccessfulUnlock(responseData, appDispatch, dispatch);
  } catch (error) {
    appDispatch({ type: "showBackdrop", value: false });
    appDispatch({
      type: "showMsg",
      value: { status: Constants.SNACKBAR_ERROR, msg: error },
    });
    if (!authenticated) {
      appDispatch({
        type: "logout",
      });
      appDispatch({ type: "openLoginModal", value: true });
    } else {
      dispatch({ type: "disableSubmitButton", value: false });
    }
  }
}

export function handleSuccessfulUnlock(data, appDispatch, dispatch) {
  appDispatch({ type: "showBackdrop", value: false });
  dispatch({ type: "setCandidateVisibility", value: data.success });
  dispatch({ type: "setModalOpen", value: false });
}

export async function getCandidateCV(
  cId,
  jpId,
  Axios,
  ourRequest,
  appDispatch,
  dispatch
) {
  appDispatch({ type: "showBackdrop", value: true });

  let errMsgs;
  let authenticated = true;

  let params = { params: { userID: cId, jpID: jpId } };
  try {
    const response = await Axios.get(`/dreamjob/cv`, params, {
      headers: {
        "content-type": `application/json;`,
      },
      cancelToken: ourRequest.token,
    }).catch(function (error) {
      // Errors to be sent to snackbar
      errMsgs = "basic.serverError";
      if (
        error.response &&
        error.response.status &&
        error.response.status === Constants.ERROR_RESPONSE_STATUS
      ) {
        authenticated = false;
        errMsgs = "basic.unauthenticated";
      }
      throw errMsgs;
    });

    const responseData = response.data.data;

    handleSuccessfulGetCVData(responseData, appDispatch, dispatch);
  } catch (error) {
    appDispatch({ type: "showBackdrop", value: false });
    appDispatch({
      type: "showMsg",
      value: { status: Constants.SNACKBAR_ERROR, msg: error },
    });
    if (!authenticated) {
      appDispatch({
        type: "logout",
      });
      appDispatch({ type: "openLoginModal", value: true });
    } else {
      dispatch({ type: "disableSubmitButton", value: false });
    }
  }
}

export function handleSuccessfulGetCVData(data, appDispatch, dispatch) {
  appDispatch({ type: "showBackdrop", value: false });
  dispatch({ type: "cvDataLoaded", value: data });
}

export function formatCertificates(data, t) {
  return data.map((item, index) => (
    <div className={CertificateWrapper} key={index}>
      <span className={Description}>{t(item.desc)}</span>
      <DreamButton
        buttonLabel={t("basic.download")}
        btnHref={item.fileUrl}
      ></DreamButton>
    </div>
  ));
}
