import React, { useEffect, useContext } from "react";
import Paper from "@material-ui/core/Paper";
import StateContext from "../../Context/StateContext";
import DispatchContext from "../../Context/DispatchContext";
import ForgottenPasswordDispatchContext from "../../Pages/ForgottenPassword/Context/ForgottenPasswordDispatchContext";
import { useImmerReducer } from "use-immer";
import { useTranslation } from "react-i18next";
import * as Constants from "../../helpers/Constants";
import Axios from "axios";
import { Link as RouterLink, Redirect, useHistory } from "react-router-dom";
import * as AppRoutes from "../../AppComponent/AppRoutes";
import Link from "@material-ui/core/Link";
import DreamButton from "../../ui_components/DreamButton/DreamButton";
import TextField from "@material-ui/core/TextField";
import DialogContent from "@material-ui/core/DialogContent";
import { makeStyles } from "@material-ui/core/styles";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
// import Checkbox from "@material-ui/core/Checkbox";
// import Alert from "@material-ui/lab/Alert";
// import IconButton from "@material-ui/core/IconButton";
import DreamDialog from "../../ui_components/DreamDialog/DreamDialog";
import * as LoginReducer from "./LoginReducer";
import * as LoginHelper from "./LoginHelper";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: 380,
    width: "100%",
    minHeight: 320,
    boxShadow: "0px 10px 10px rgba(0, 0, 0, 0.25)",
    borderRadius: 15,
  },
  dialogTitle: {
    width: "100%",
    paddingLeft: 50,
    paddingTop: 20,
    paddingBottom: 20,
    textAlign: "left",
    fontSize: 18,
    fontWeight: "bold",
    borderBottom: "2px solid lightgrey",
  },
  loginWrapper: {
    padding: 20,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    maxWidth: 380,
    width: "100%",
    minHeight: 320,
  },
  loginTextWrapper: {
    marginTop: 24,
    display: "flex",
    fontWeight: "bold",
    alignSelf: "center",
  },
  termsWrapper: {
    marginTop: 10,
    marginBottom: 10,
    display: "flex",
  },
  termsFormControlLabel: {
    marginLeft: -10,
    marginRight: 0,
  },
  termsTextWrapper: {
    display: "flex",
    alignSelf: "center",
  },
  termsAgreeTo: {
    paddingRight: 3,
    fontSize: 10,
  },
  termsAgree: {
    fontSize: 10,
  },
  haveAccount: {
    paddingRight: 3,
    fontSize: 12,
  },
  login: {
    fontSize: 12,
  },
}));

function LoginModalComponent(props) {
  const classes = useStyles();

  const appContext = useContext(StateContext);
  const appDispatch = useContext(DispatchContext);
  const forgottenPasswordDispatchContext = useContext(
    ForgottenPasswordDispatchContext
  );

  const [t] = useTranslation(Constants.TRANSLATION_COMMON);
  const [state, dispatch] = useImmerReducer(
    LoginReducer.reducer,
    LoginReducer.originalState
  );

  /**
   * The Form has been submitted
   */

  const history = useHistory();

  useEffect(() => {
    if (state.formSubmittedCount) {
      appDispatch({ type: "showBackdrop", value: true });
      const ourRequest = Axios.CancelToken.source();
      LoginHelper.login(
        state,
        Axios,
        ourRequest,
        appDispatch,
        dispatch,
        t,
        history
      );
      return () => {
        ourRequest.cancel();
      };
    }
  }, [state.formSubmittedCount]);

  function handleForgottenPasswordClick() {
    // forgottenPasswordDispatchContext({ type: "sent", value: false });
    appDispatch({ type: "openLoginModal", value: false });
    appDispatch({ type: "showBackdrop", value: false });
  }

  const content = (
    <DialogContent>
      {/* Email */}
      <TextField
        autoComplete="off"
        margin="dense"
        id="email"
        name="email"
        label={t("registerForm.email")}
        fullWidth
        value={state.fields.email.value ? state.fields.email.value : ""}
        onChange={(e) =>
          dispatch({
            type: "emailChange",
            value: { value: e.target.value, t },
          })
        }
      />
      {/* {state.fields.email.errorMessage && (
        <Alert severity="error">{state.fields.email.errorMessage}</Alert>
      )} */}
      <TextField
        margin="dense"
        id="password"
        label={t("loginForm.password")}
        type="password"
        fullWidth
        value={state.fields.password.value}
        onChange={(e) =>
          dispatch({
            type: "passwordChange",
            value: { value: e.target.value, t },
          })
        }
      />
      {/* {state.fields.password.errorMessage && (
        <Alert severity="error">{state.fields.password.errorMessage}</Alert>
      )} */}
      {/* <FormControlLabel
        control={
          <Checkbox
            checked={Boolean(state.fields.rememberMe.value)}
            onChange={(e) =>
              dispatch({
                type: "rememberMeChange",
                value: e.target.checked,
              })
            }
            name="rememberMe"
            color="primary"
          />
        }
        label={t("loginForm.rememberMe")}
      /> */}
      <p style={{ marginTop: 10, marginBottom: 10 }}>
        <Link
          component={RouterLink}
          to={`${AppRoutes.ROUTE_FORGOTTEN_PASSWORD}`}
          variant="body2"
          underline="none"
          onClick={handleForgottenPasswordClick}
        >
          {t("loginForm.forgottenPwd")}
        </Link>
        {/* <IconButton
          component={RouterLink}
          to={`${AppRoutes.ROUTE_FORGOTTEN_PASSWORD}`}
        >
          {" "}
          {t("loginForm.forgottenPwd")}
        </IconButton> */}
      </p>
    </DialogContent>
  );

  let buttons = (
    <React.Fragment>
      {props.noModal ? (
        <DreamButton
          handleClick={() => LoginHelper.handleClose(dispatch, appDispatch)}
          color="secondary"
          kind="text"
          buttonLabel="basic.cancel"
        ></DreamButton>
      ) : (
        ""
      )}
      <DreamButton
        dreamButtonStyle={props.loginButtonStyle}
        buttonType="submit"
        buttonDisabled={Boolean(state.hasValidationErrs)}
        handleClick={(e) => LoginHelper.handleSubmit(e, state, dispatch)}
        color="primary"
        kind="contained"
        buttonLabel="loginForm.login"
      />
      <DreamButton
        dreamButtonStyle={props.signUpButtonStyle}
        buttonType="button"
        handleClick={(e) =>
          appDispatch({ type: "openSignUpModal", value: true })
        }
        color="success"
        kind="contained"
        buttonLabel="header.signUp"
      />
    </React.Fragment>
  );

  if (props.noModal) {
    return (
      <DreamDialog
        open={appContext.appState.openLoginModal}
        closeHandler={() => LoginHelper.handleClose(dispatch, appDispatch)}
        title={t("header.login")}
        content={content}
        buttons={buttons}
        submitHandler={(e) => LoginHelper.handleSubmit(e, state, dispatch)}
      ></DreamDialog>
    );
  } else {
    return (
      <Paper className={classes.paper}>
        <span className={classes.dialogTitle}>{t("header.login")}</span>
        <div className={classes.loginWrapper}>
          <div style={props.contentWrapperStyle}>{content}</div>
          <div style={props.buttonWrapperStyle}>{buttons}</div>
        </div>
      </Paper>
    );
  }
}

export default LoginModalComponent;
