import React, { useContext } from "react";
import StateContext from "../../../Context/StateContext";
import DispatchContext from "../../../Context/DispatchContext";

import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import CloseIcon from "@material-ui/icons/Close";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as FileUploaderHelper from "../DreamFileUploader/FileUploaderHelper";
import Tooltip from "@material-ui/core/Tooltip";
import Avatar from "@material-ui/core/Avatar";
import Badge from "@material-ui/core/Badge";
import Grid from "@material-ui/core/Grid";
import {
  CardHeaderWrapper,
  CardHeaderHeadingsWrapper,
  CloseBtn,
  Heading,
  SubHeading,
  HeadingWrapper,
  InputWrapper,
  CloseBtnHidden,
} from "./SingleItemFileUploaderComponent.module.scss";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Axios from "axios";
import * as Colors from "../../../styles/01.settings/01-settings-colors.module.scss";

const axios = Axios;
const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 300,
    overflow: "visible",
  },
  media: {
    height: 0,
    paddingTop: "100%",
    color: "#8965e0",
  },
  customWidth: {
    maxWidth: 200,
  },
  large: {
    width: theme.spacing(14),
    height: theme.spacing(14),
    border: `4px solid ${Colors.primary}`,
  },
  anchorOriginTopRightRectangle: {
    right: 20,
  },
}));

const NO_IMAGE_URL =
  "https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/300px-No_image_available.svg.png";

export default function SingleAvatarFileUploaderComponent(props) {
  const classes = useStyles();
  const appContext = useContext(StateContext);
  const appDispatch = useContext(DispatchContext);
  const token = appContext.appState.user_token;
  const t = appContext.context.translateService.t;

  function getImg() {
    switch (props.data.status) {
      case FileUploaderHelper.UPLOAD_STATUS_DONE:
        let tokenParam = props.data.isInitialUpload
          ? `?access_token=${token}`
          : "";
        return (
          <Badge
            badgeContent={<CloseButton />}
            classes={{
              anchorOriginTopRightRectangle:
                classes.anchorOriginTopRightRectangle,
            }}
          >
            <Avatar
              className={classes.large}
              src={
                props.data.previewUrl ? props.data.previewUrl + tokenParam : ""
              }
            />
          </Badge>
        );
      default:
        return <CircularProgress />;
    }
  }

  const CloseButton = ({ className }) => {
    return (
      <IconButton
        className={className}
        onClick={(e) => {
          props.handleDelete(
            props.data,
            props.dispatchFileUpload,
            axios,
            appDispatch,
            "avatar"
          );
          if (props.somethingManualyChangedHandler) {
            props.somethingManualyChangedHandler();
          }
        }}
        aria-label="close"
      >
        <CloseIcon />
      </IconButton>
    );
  };

  const DreamCardHeader = () => {
    return (
      <div className={CardHeaderWrapper}>
        <div className={CardHeaderHeadingsWrapper}>
          <Tooltip
            classes={{ tooltip: classes.customWidth }}
            title={
              props.data.fileDescription
                ? props.data.fileDescription.toLowerCase()
                : ""
            }
            placement="top"
          >
            <div className={Heading} readOnly>
              {props.data.fileDescription ? props.data.fileDescription : ""}
            </div>
          </Tooltip>
          <TextareaAutosize
            className={SubHeading}
            rowsMax={2}
            aria-label="minimum height"
            value={
              props.data.name && props.data.name !== "undefined"
                ? FormatTitle(props.data.name, 19, 16)
                : ""
            }
            readOnly
          />
          {props.data.downloadUrl && (
            <a href={`${props.data.downloadUrl}?access_token=${token}`}>
              {t("basic.downloadLink")}
            </a>
          )}
        </div>
        <CloseButton className={CloseBtn} />
      </div>
    );
  };

  const FormatTitle = (title, maxLength, cutSize) => {
    return title.length <= maxLength
      ? title
      : [...title].splice(0, cutSize).join("").toLowerCase() + "...";
  };

  return <React.Fragment>{getImg()}</React.Fragment>;

  //   (
  // <Card className={classes.root}>
  //   {props.useDescription ? (
  //     <DreamCardHeader />
  //   ) : (
  //     <div className={HeadingWrapper}>
  //       <CloseButton className={CloseBtnHidden} />
  //     </div>
  //   )}
  //   {getImg()}
  //   {props.useDescription ? (
  //     <CardContent>
  //       <TextField
  //         autoFocus
  //         value={props.data.fileDescription ? props.data.fileDescription : ""}
  //         fullWidth
  //         id={props.data.id}
  //         label={t("basic.fileDescription") + "*"}
  //         onChange={(e) => props.handleDescriptionChange(e)}
  //       />
  //     </CardContent>
  //   ) : (
  //     <div className={InputWrapper}></div>
  //   )}
  // </Card>
  //   );
}
